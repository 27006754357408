/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	EventEmitter,
	Output
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	FileDirective
} from '@shared/directives/file.directive';
import {
	FileProgressType
} from '@shared/constants/enums/file-progress-type.enum';
import {
	FileService
} from '@shared/services/files/file.service';
import {
	FormControl,
	Validators
} from '@angular/forms';
import {
	IFileEntity
} from '@shared/interfaces/files/file-entity.interface';
import {
	IFileProgress
} from '@shared/interfaces/files/file-progress.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';

@Component({
	selector: 'app-delete-file',
	templateUrl: './delete-file.component.html',
	styleUrls: [
		'./delete-file.component.scss'
	]
})

/**
 * A class representing a component logic for deleting files.
 *
 * @export
 * @class DeleteFileComponent
 * @extends {FileDirective}
 */
export class DeleteFileComponent
	extends FileDirective
{
	/**
	 * Initializes the delete file component class.
	 *
	 * @param {FileService} fileService
	 * The file service for this component.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to get the entity type data.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The api service used to get entity instance data.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof DeleteFileComponent
	 */
	public constructor(
		private readonly fileService: FileService,
		private readonly activityService: ActivityService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public resolver: ResolverService)
	{
		super(
			entityTypeApiService,
			entityInstanceApiService,
			resolver);
	}

	/**
	 * Gets or sets the file has been removed event emitter.
	 *
	 * @type {EventEmitter}
	 * @memberof DeleteFileComponent
	 */
	@Output()
	public removed: EventEmitter<IFileEntity> =
		new EventEmitter<IFileEntity>();

	/**
	 * Gets or sets the from control that handles the required
	 * reason the file is being removed.
	 *
	 * @type {FormControl}
	 * @memberof DeleteFileComponent
	 */
	public reasonControl: FormControl =
		new FormControl('', Validators.maxLength(1024));

	/**
	 * Gets or sets a value indictaing whether the
	 * remove document form should be visible.
	 *
	 * @type {boolean}
	 * @memberof DeleteFileComponent
	 */
	public removeFormVisible: boolean = true;

	/**
	 * Gets os sets the progress message.
	 *
	 * @type {string}
	 * @memberof DeleteFileComponent
	 */
	public progressMessage: string = 'Deleting file';

	/**
	 * Handles the remove button click event.
	 *
	 * @memberof DeleteFileComponent
	 */
	public removeClicked(): void
	{
		const entityToRemove =
			JSON.parse(
				JSON.stringify(
					this.fileEntity));

		delete entityToRemove.data.userName;
		delete entityToRemove.data.changeDateTime;

		this.removeFormVisible = false;
		this.progressVisible = true;

		const promise: Promise<void> =
			new Promise<void>((resolve, reject) =>
			{
				this.fileService
					.remove(
						<IFileEntity>
						entityToRemove,
						this.reasonControl.value,
						this.fileTypeGroup)
					.subscribe({
						next:
							(event: IFileProgress) =>
							{
								switch (event.type)
								{
									case FileProgressType.Complete:
										this.progressMode = 'done';
										this.progressMessage = 'File deleted.';
										this.removed.emit(this.fileEntity);
										resolve();
										setTimeout(() =>
										{
											this.changeDisplayMode.emit(
												this.viewModes.list);
										},
										AppConstants.time.twoSeconds);
										break;

									case FileProgressType.Error:
										this.progressMode = 'error';
										this.progressMessage = event.message;
										reject(event.message);
										break;
								}
							},
						error:
							(error: any) =>
							{
								this.progressMessage = 'Error deleting file!';
								this.progressMode = 'error';
								reject(error);
							}
					});
			});

		this.activityService.handleActivity<void>(
			new Activity<void>(
				promise,
				'<strong>Deleting file</strong> '
					+ this.fileEntity.data.name,
				'<strong>Deleting file</strong> '
					+ this.fileEntity.data.name,
				`${this.fileEntity.data.name} was successfully deleted.`,
				`Error deleting ${this.fileEntity.data.name}.`));
	}
}