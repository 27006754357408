<div class="ui-g-12 text-center"
	*ngIf="loading">
	<span class="center-spinner loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
	</span>
</div>

<div *ngIf="!loading"
	class="table-content-container">
	<div class="ui-g-12 no-padding table-expand-section">
		<div class="ui-g-12 assigned-to-text">
			Assignee: {{assigneeName}}
		</div>
		<div class="ui-g-6 no-padding split-section">
			<app-dynamic-formly
				[dataSet]="selectedItem"
				[layoutSchema]="formlyEntityLayout"
				[context]="context">
			</app-dynamic-formly>
			<div class="ui-g-12 created-on-text">
				Created on {{ createdOn }}
			</div>
		</div>
		<div class="ui-g-6 no-padding split-section parent-section">
			<div *ngIf="parentFormlyEntityLayout != null
				&& parentEntityInstance != null">
				<custom-section-title
					[field]="{
						templateOptions: {
							label: ('Work Item ' + getDisplayName(parentEntityInstance.entityType))
						}
					}">
				</custom-section-title>
				<app-dynamic-formly
					[dataSet]="parentEntityInstance"
					[layoutSchema]="parentFormlyEntityLayout"
					[context]="context">
				</app-dynamic-formly>
			</div>
		</div>
	</div>

	<div class="ui-g-12 button-bar">
		<operation-button-bar
			[initialModel]="context.source.expandActions"
			[pageContext]="context"
			(loadingOperations)="context.source.operationButtonClicked($event)">
		</operation-button-bar>
	</div>
</div>