/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BaseEntityApiService
} from '@api/services/base/base-entity.api.service';
import {
	CacheService
} from '@shared/services/cache.service';
import {
	HttpClient
} from '@angular/common/http';
import {
	IDisplayComponentInstanceDto
} from '@api/interfaces/display-components/display-component-instance.dto.interface';
import {
	Inject,
	Injectable
} from '@angular/core';
import {
	lastValueFrom
} from 'rxjs';

/* eslint-enable max-len */

/**
 * A class representing the logic and services of the display component
 * instance controller.
 *
 * @export
 * @class DisplayComponentInstanceApiService
 * @extends {BaseEntityApiService<IDisplayComponentInstanceDto>}
 */
@Injectable()
export class DisplayComponentInstanceApiService
	extends BaseEntityApiService<IDisplayComponentInstanceDto>
{
	/**
	 * Creates an instance of a DisplayComponentInstanceApiService.
	 *
	 * @param {HttpClient} HttpClient
	 * The injected http client to use in the base api service.
	 * @param {CacheService} cache
	 * The injected cache service to use in the base api service.
	 * @memberof DisplayComponentInstanceApiService
	 */
	public constructor(
		@Inject(HttpClient) http: HttpClient,
		@Inject(CacheService) cache: CacheService)
	{
		super();
		this.httpClient = http;
		this.cacheService = cache;
		this.endpoint =
			AppConstants.apiControllers.displayComponentInstances;
	}

	/**
	 * Gets the associated security groups of the display component instance
	 * with the specified identifier.
	 *
	 * @param {number} id
	 * The identifier of the display component instance to get the security
	 * groups of.
	 * @returns {Promise<ISecurityGroupDto[]>}
	 * The security groups found via this get method.
	 * @memberof DisplayComponentInstanceApiService
	 */
	public async getSecurityGroups(
		id: number): Promise<number[]>
	{
		const url = this.getNestedUrl(
			id,
			AppConstants.nestedRouteTypes.securityGroups);

		return lastValueFrom(this.httpClient.get<number[]>(url));
	}

	/**
	 * Creates a security group to display component instance association
	 * with the specified information, this allows access to
	 * the display component instance for the specified security group.
	 *
	 * @param {number} id
	 * The identifier of the display component instance to set the security
	 * group assocation.
	 * @param {number} securityGroupId
	 * The identifier of the security group to create the display component
	 * instance assocation to.
	 * @returns {Promise<number>}
	 * The display component instance identifier holding the new association.
	 * @memberof DisplayComponentInstanceApiService
	 */
	public async createSecurityGroupMap(
		id: number,
		securityGroupId: number): Promise<number>
	{
		const nestedUrl: string =
			this.getNestedUrl(
				id,
				AppConstants.nestedRouteTypes.securityGroups);
		const url =
			this.formUrlParam(
				nestedUrl,
				{
					securityGroupId: securityGroupId
				});
		await this.resetAssociatedCache(nestedUrl);

		const response =
			await lastValueFrom(this.httpClient.post(
				url,
				{},
				{
					observe: 'response',
					headers: this.queryOnlyGetHeaders
				}));

		const createdItemId: number =
			this.getCreatedAtRouteIdentifier(
				response.headers.get('location'));

		return Promise.resolve(createdItemId);
	}

	/**
	 * deletes a security group that allows access to the display
	 * component instance.
	 *
	 * @param {number} id
	 * The identifier of the display component instance to delete the security
	 * group assocation for.
	 * @param {number} securityGroupId
	 * The identifier of the security group to delete the display component
	 * instance assocation to.
	 * @returns {Promise<object>}
	 * An observable of the delete no-content response.
	 * @memberof DisplayComponentInstanceApiService
	 */
	public async deleteSecurityGroupMap(
		id: number,
		securityGroupId: number): Promise<object>
	{
		const nestedUrl: string =
			this.getNestedUrl(
				id,
				AppConstants.nestedRouteTypes.securityGroups);
		const url =
			`${nestedUrl}/${securityGroupId}`;
		await this.resetAssociatedCache(nestedUrl);

		return lastValueFrom(
			this.httpClient.delete(
				url));
	}
}