/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConfig
} from 'src/app/app.config';
import {
	BaseApiService
} from '@api/services/base/base.api.service';
import {
	HttpClient
} from '@angular/common/http';
import {
	IAggregateDto
} from '@api/interfaces/common/aggregatedto.interface';
import {
	ILogEntryDto
} from '@api/interfaces/logs/log-entry.dto.interface';
import {
	ILogMessageDto
} from '@api/interfaces/logs/log-message.dto.interface';
import {
	Injectable
} from '@angular/core';
import {
	lastValueFrom
} from 'rxjs';

/**
 * A class representing the logic and services of the log controller.
 *
 * @export
 * @class LogApiService
 * @extends {BaseApiService}
 */
@Injectable()
export class LogApiService
	extends BaseApiService
{
	/**
	 * Creates an instance of LogApiService.
	 *
	 * @param {HttpClient} http
	 * The http client for log service requests.
	 * @memberof LogApiService
	 */
	public constructor(
		private readonly http: HttpClient)
	{
		super();
	}

	/**
	 * Performs an async log entry request to the API.
	 *
	 * @param {ILogEntryDto} logEntry
	 * @returns {Promise<void>}
	 * @memberof LogApiService
	 */
	public async addLogEntry(
		logEntry: ILogEntryDto): Promise<void>
	{
		if (AnyHelper.isNullOrEmpty(
			AppConfig.settings.logging.serverLogUrl))
		{
			return null;
		}

		return lastValueFrom(
			this.http.post<void>(
				AppConfig.settings.logging.serverLogUrl,
				logEntry,
				{
					headers: this.queryOnlyGetHeaders
				}));
	}

	/**
	 * Gets a collection of log messages with the specified filters.
	 *
	 * @param {string} filter A string representing the filters for the query.
	 * @param {string} orderBy A string representing the order by for the query.
	 * @param {number} [offset] A number representing the skip offset.
	 * @param {number} [limit] A number representing the top limit count.
	 * @param {number} [last] A number representing the last count.
	 * @returns {Promise<ILogMessageDto[]>}
	 * @memberof LogApiService
	 */
	public async getLogMessages(
		filter: string,
		orderBy: string,
		offset?: number,
		limit?: number,
		last?: number): Promise<ILogMessageDto[]>
	{
		const url =
			this.formUrlParam(
				AppConfig.settings.webApi.rootUrl
				+ AppConfig.settings.webApi.version
				+ 'Logs',
				{
					filter: filter,
					orderBy: orderBy,
					offset: offset,
					limit: limit,
					last: last
				});

		return lastValueFrom(
			this.http.get<ILogMessageDto[]>(url));
	}

	/**
	 * Gets a collection of log messages with the specified filters, this is
	 * used to have a common query interface for this non BaseEntity service.
	 *
	 * @param {string} filter A string representing the filters for the query.
	 * @param {string} orderBy A string representing the order by for the query.
	 * @param {number} [offset] A number representing the skip offset.
	 * @param {number} [limit] A number representing the top limit count.
	 * @returns {Promise<ILogMessageDto[]>}
	 * @memberof LogApiService
	 */
	public async query(
		filter: string,
		orderBy: string,
		offset?: number,
		limit?: number): Promise<ILogMessageDto[]>
	{
		return this.getLogMessages(
			filter,
			orderBy,
			offset,
			limit);
	}

	/**
	 * Gets a an aggregate of log messages with the specified filters, this is
	 * used to have a common aggregate interface for this non BaseEntity
	 * service.
	 *
	 * @param {string} method
	 * The aggregate method name to use.
	 * @param {string} property
	 * The property to aggregate. Pass null if calling count.
	 * @param {string} filter
	 * The filter or "where clause."
	 * @param {string} groupBy
	 * The properties to group by.
	 * @returns {Promise<IAggregateDto[]>}
	 * An array of aggregate results.
	 * @memberof LogApiService
	 */
	public async aggregate(
		method: string,
		property?: string,
		filter?: string,
		groupBy?: string): Promise<IAggregateDto[]>
	{
		const url =
			this.formUrlParam(
				AppConfig.settings.webApi.rootUrl
				+ AppConfig.settings.webApi.version
				+ 'Logs/Aggregate',
				{
					method: method,
					property: property,
					filter: filter,
					groupBy: groupBy
				});

		return lastValueFrom(this.http.get<IAggregateDto[]>(url));
	}
}