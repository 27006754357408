/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	Injectable
} from '@angular/core';
import {
	ISettingPreference
} from '@shared/interfaces/settings/setting-preference.interface';
import {
	SettingPreferenceType
} from '@shared/constants/enums/setting-preference-type.enum';
import {
	StorageMap
} from '@ngx-pwa/local-storage';

/**
 * A class representing a settings service for both local and
 * api valued site and user settings.
 *
 * @export
 * @class SettingsService
 * @typeparam TSettingPreference
 * The setting preference to be stored and loaded with this service.
 */
@Injectable()
export class SettingsService<TSettingPreference extends ISettingPreference>
{
	/**
	 * Creates an instance of a settings service.
	 *
	 * @param {StorageMap} storageMap
	 * The local storage map used for the settings cache.
	 * @memberof SettingsService
	 */
	public constructor(
		private readonly storageMap: StorageMap)
	{
	}

	/**
	 * Gets or sets the unique key for this storage service
	 * implementation. This is used to give the local storage
	 * a unique suffix for this settings service instance.
	 *
	 * @type {SettingPreferenceType}
	 * @memberof SettingsService
	 */
	public settingKey: SettingPreferenceType;

	/**
	 * Gets or sets the local storage settings object.
	 *
	 * @type {TSettingPreference[]}
	 * @memberof SettingsService
	 */
	private settings: TSettingPreference[] = <TSettingPreference[]>[];

	/**
	 * Gets the identifier used for the session based setting list storage.
	 *
	 * @type {string}
	 * @memberof SettingsService
	 */
	private readonly localStorageKey: string = 'NautixSettings';

	/**
	 * Loads and returns the current settings for this session.
	 *
	 * @async
	 * @returns {Promise<TSettingPreference[]>}
	 * An awaitable promise holding all current session storage values.
	 * @memberof SettingsService
	 */
	private get storageKey(): string
	{
		return `${this.localStorageKey}_${this.settingKey}`;
	}

	/**
	 * Loads and returns the current settings for this session.
	 *
	 * @async
	 * @returns {Promise<TSettingPreference[]>}
	 * An awaitable promise holding all current session storage values.
	 * @memberof SettingsService
	 */
	public async loadSettings(): Promise<TSettingPreference[]>
	{
		return new Promise(
			(resolve) =>
			{
				this.storageMap.get(this.storageKey)
					.subscribe(
						(settings: TSettingPreference[]) =>
						{
							this.settings =
								AnyHelper.isNull(settings)
									? []
									: settings;

							resolve(this.settings);
						});
			});
	}

	/**
	 * Saves the current settings for this session.
	 *
	 * @async
	 * @param {TSettingPreference[]} settings
	 * The current setting values to store.
	 * @memberof SettingsService
	 */
	public async saveSettings(
		settings: TSettingPreference[]): Promise<void>
	{
		return new Promise(
			(resolve) =>
			{
				this.settings = settings;

				this.storageMap.set(
					this.storageKey,
					this.settings)
					.subscribe(
						() =>
						{
							resolve();
						});
			});
	}

	/**
	 * Adds or updates a current setting for this session.
	 *
	 * @async
	 * @param {TSettingPreference} setting
	 * The current setting value to add or update.
	 * @memberof SettingsService
	 */
	public async addOrUpdateSetting(
		setting: TSettingPreference): Promise<void>
	{
		const filteredSettings: TSettingPreference[] =
			this.settings.filter(
				(filterSetting: TSettingPreference) =>
					filterSetting.key !== setting.key);
		filteredSettings.push(setting);

		return this.saveSettings(
			<TSettingPreference[]>
			[
				...filteredSettings
			]);
	}
}