/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BaseEntityApiService
} from '@api/services/base/base-entity.api.service';
import {
	CacheService
} from '@shared/services/cache.service';
import {
	HttpClient
} from '@angular/common/http';
import {
	Inject,
	Injectable
} from '@angular/core';
import {
	ISecurityAccessPolicyMappingDto
} from '@api/interfaces/security/security-access-policy-mapping.dto.interface';

/**
 * A class representing the logic and services of the security
 * access policy mapping controller.
 *
 * @export
 * @class SecurityAccessPolicyMappingApiService
 * @extends {BaseEntityApiService<ISecurityAccessPolicyMappingDto>}
 */
@Injectable()
export class SecurityAccessPolicyMappingApiService
	extends BaseEntityApiService<ISecurityAccessPolicyMappingDto>
{
	/**
	 * Creates an instance of a SecurityAccessPolicyMappingApiService.
	 *
	 * @param {HttpClient} HttpClient
	 * The injected http client to use in the base api service.
	 * @param {CacheService} cache
	 * The injected cache service to use in the base api service.
	 * @memberof SecurityAccessPolicyMappingApiService
	 */
	public constructor(
		@Inject(HttpClient) http: HttpClient,
		@Inject(CacheService) cache: CacheService)
	{
		super();
		this.httpClient = http;
		this.cacheService = cache;
		this.endpoint =
			AppConstants.apiControllers.securityAccessPolicyMappings;
	}

	/**
	 * Updates an ISecurityAccessPolicyMappingDto with the specified
	 * identifier and information.
	 * This method is not implemented in the API.
	 *
	 * @param {number} _id
	 * The identifier of the type T to update.
	 * @param {IBaseEntity} _entity
	 * The entity of type T to update.
	 * @returns {Promise<object>}
	 * An observable of the put no-content response.
	 * @memberof SecurityAccessPolicyMappingApiService
	 */
	public async update(
		_id: number,
		_entity: ISecurityAccessPolicyMappingDto): Promise<object>
	{
		throw new Error(
			this.getNotImplementedMessage(
				AppConstants.apiMethods.update,
				[
					AppConstants.apiMethods.create,
					AppConstants.apiMethods.delete
				]));
	}
}