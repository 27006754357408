/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	CanDeactivate
} from '@angular/router';
import {
	DeactivateGuardComponent
} from '@shared/interfaces/guards/deactivate-guard-component.interface';
import {
	Injectable
} from '@angular/core';
import {
	Observable
} from 'rxjs';

/**
 * A class containing logic for whether a component can deactivate based
 * on the can deactivate implementation in the component.
 *
 * @export
 * @class AppCanDeactivateGuard
 * @implements {CanDeactivate<DeactivateGuardComponent>}
 */
@Injectable()
export class AppCanDeactivateGuard
implements CanDeactivate<DeactivateGuardComponent>
{
	/**
	 * Fires an alert if the can deactivate implementation in the component
	 * returns false. Otherwise this will allow a page change without showing
	 * an alert to the user. The implementing component will define if
	 * deactivation is allowed.
	 *
	 * @memberof AppCanDeactivateGuard
	 */
	public canDeactivate(
		component: DeactivateGuardComponent): boolean | Observable<boolean>
	{
		return component.canDeactivate()
			? true
			: confirm('You have unsaved changes. '
				+ 'Press Cancel to go back and complete these changes '
				+ 'or OK to discard these changes.');
	}
}