
<ng-template
	[ngIf]="loading === true && (displayComponent == null || componentReference == null)">
	<div class="ui-g-12 no-padding text-center">
		<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
		</i>
	</div>
</ng-template>

<ng-template #dynamicElement>
</ng-template>