/**
 * @copyright WaterStreet. All rights reserved.
*/

import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppCanDeactivateGuard
} from '@shared/guards/app-can-deactivate.guard';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BaseOperationAction
} from '@operation/actions/base/base-operation-action';
import {
	DisplayComponentService
} from '@shared/services/display-component.service';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	IActionResponse
} from '@shared/interfaces/workflow/action-response.interface';
import {
	Injectable
} from '@angular/core';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	OperationExecutionService
} from '@operation/services/operation-execution.service';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	RuleService
} from '@shared/services/rule.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/**
 * A class representing the execute workflow action.
 *
 * @export
 * @class ExecuteWorkflowAction
 * @extends {BaseOperationAction}
 */
@Injectable()
export class ExecuteWorkflowAction
	extends BaseOperationAction
{
	/**
	 * Creates an instance of a ExecuteWorkflowAction.
	 *
	 * @param {ActivityService} activityService
	 * The activity service used for this action.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used for this action.
	 * @param {OperationExecutionService} operationExecutionService
	 * The operation execution service used for this action.
	 * @param {OperationService} operationService
	 * The operation service used for this action.
	 * @param {OperationDefinitionApiService} operationDefinitionApiService
	 * The operation definition api service used for this action.
	 * @param {RuleService} ruleService
	 * The rule service used for business rules based on executed actions.
	 * @param {EntityService} entityService
	 * The entity service used for business rules based on executed actions.
	 * @param {DisplayComponentService} displayComponentService
	 * The display component service used for business rules based on
	 * executed actions.
	 * @param {AppCanDeactivateGuard} appCanDeactivateGuard
	 * The app can deactivate guard.
	 * @memberof ExecuteWorkflowAction
	 */
	public constructor(
		protected activityService: ActivityService,
		protected entityInstanceApiService: EntityInstanceApiService,
		protected operationExecutionService: OperationExecutionService,
		protected operationService: OperationService,
		protected operationDefinitionApiService: OperationDefinitionApiService,
		protected ruleService: RuleService,
		protected entityService: EntityService,
		protected displayComponentService: DisplayComponentService,
		protected appCanDeactivateGuard: AppCanDeactivateGuard)
	{
		super(
			activityService,
			operationExecutionService,
			operationService,
			operationDefinitionApiService,
			appCanDeactivateGuard);
	}

	/**
	 * Gets or sets the action names that perform business rules.
	 *
	 * @type {object}
	 * @memberof ExecuteWorkflowAction
	 */
	public businessRuleActions:
		{
			clearServerCache: string;
		} =
		{
			clearServerCache: 'ClearMemoryCaches'
		};

	/**
	 * Gets or sets the action name.
	 *
	 * @type {string}
	 * @memberof ExecuteWorkflowAction
	 */
	public actionName: string = AppConstants.empty;

	/**
	 * Gets or sets the body parameter.
	 *
	 * @type {string}
	 * @memberof ExecuteWorkflowAction
	 */
	public bodyParameter: string = AppConstants.empty;

	/**
	 * Gets or sets the id.
	 *
	 * @type {string}
	 * @memberof ExecuteWorkflowAction
	 */
	public id: string = AppConstants.empty;

	/**
	 * Gets or sets the query string.
	 *
	 * @type {string}
	 * @memberof ExecuteWorkflowAction
	 */
	 public queryString: string = AppConstants.empty;

	/**
	 * Gets or sets the type group.
	 *
	 * @type {string}
	 * @memberof ExecuteWorkflowAction
	 */
	public typeGroup: string = AppConstants.empty;

	/**
	 * Gets or sets the success message.
	 *
	 * @type {string}
	 * @memberof ExecuteWorkflowAction
	 */
	public successMessage: string = AppConstants.empty;

	/**
	 * Gets or sets the operation name.
	 *
	 * @type {string}
	 * @memberof ExecuteWorkflowAction
	 */
	public get operationName(): string
	{
		return StringHelper
			.normalizeCamelcase(this.actionName);
	}

	/**
	 * Gets the message to be displayed when this operation
	 * has completed successfully.
	 *
	 * @type {string}
	 * @memberof ExecuteWorkflowAction
	 */
	public get operationSuccessMessage(): string
	{
		return AnyHelper.isNullOrEmpty(this.successMessage)
			? `Action Complete: ${this.operationName}.`
			: this.successMessage;
	}

	/**
	 * Gets the message to be displayed when this operation
	 * has failed.
	 *
	 * @type {string}
	 * @memberof ExecuteWorkflowAction
	 */
	public get operationFailureMessage(): string
	{
		return `Unable to perform the action ${this.operationName}.`;
	}

	/**
	 * Executes the defined action.
	 *
	 * @async
	 * @memberof ExecuteWorkflowAction
	 */
	public async execute(): Promise<IActionResponse>
	{
		this.validateWorkflowParameters();

		this.typeGroup = !AnyHelper.isNullOrWhitespace(this.typeGroup)
			? StringHelper.interpolate(
				this.typeGroup,
				this.pageContext)
			: this.typeGroup;

		this.id = !AnyHelper.isNullOrWhitespace(this.id)
			? StringHelper.interpolate(
				this.id,
				this.pageContext)
			: this.id;

		this.bodyParameter = !AnyHelper.isNullOrWhitespace(this.bodyParameter)
			? StringHelper.interpolate(
				this.bodyParameter,
				this.pageContext)
			: this.bodyParameter;

		this.queryString = !AnyHelper.isNullOrWhitespace(this.queryString)
			? await StringHelper.transformToDataPromise(
				StringHelper.interpolate(
					this.queryString,
					this.pageContext),
				this.pageContext)
			: this.queryString;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			this.typeGroup;

		const actionResponse: IActionResponse =
			await this.entityInstanceApiService.executeAction(
				Number(this.id),
				this.actionName,
				(AnyHelper.isNullOrWhitespace(this.bodyParameter)
					? null
					: JSON.parse(this.bodyParameter)),
				this.queryString);

		await this.entityInstanceApiService
			.resetAssociatedCache(
				`${this.entityInstanceApiService.getBaseUrl()}/${this.id}`);

		return actionResponse;
	}

	/**
	 * Executes post operation actions by handling all post actions and then
	 * performing client side business rules.
	 *
	 * @async
	 * @memberof IOperationAction
	 */
	public async executePostOperationActions(): Promise<void>
	{
		await super.executePostOperationActions();

		this.performClientSideBusinessRules();
	}

	/**
	 * Performs specific actions on the client side based on system level
	 * actions.
	 *
	 * @memberof ExecuteWorkflowAction
	 */
	public performClientSideBusinessRules(): void
	{
		switch (this.actionName)
		{
			case this.businessRuleActions.clearServerCache:
				this.displayComponentService.clearStoredVariables();
				this.operationService.clearStoredVariables();
				this.ruleService.clearStoredVariables();
				this.entityService.clearStoredVariables();

				break;
		}
	}

	/**
	 * Validates the supplied action parameters
	 * were entered correctly.
	 *
	 * @memberof ExecuteWorkflowAction
	 */
	private validateWorkflowParameters(): void
	{
		const operationName: string = 'workflow';

		this.validateNonEmptyParameter(
			this.typeGroup,
			operationName,
			'entity type group'
		);

		this.validateNonEmptyParameter(
			this.id,
			operationName,
			'entity id'
		);

		this.validateNonEmptyParameter(
			this.actionName,
			operationName,
			'entity action name'
		);
	}
}