/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	cloneDeep
} from 'lodash-es';
import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import {
	DocumentHelper
} from '@shared/helpers/document.helper';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	FormControl
} from '@angular/forms';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	IProductSetting
} from '@shared/interfaces/application-objects/product-setting.interface';
import {
	Observable
} from 'rxjs';
import {
	ProductSettingDirective
} from '@shared/directives/product-setting.directive';

/* eslint-enable max-len */

@Component({
	selector: 'app-product-setting-action',
	templateUrl: './product-setting-action.component.html',
	styleUrls: [
		'./product-setting-action.component.scss'
	]
})

/**
 * A component representing context level product setting action
 * based on the display mode.
 *
 * @export
 * @class ProductSettingActionComponent
 * @extends {ProductSettingDirective}
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {IDynamicComponent<Component, any>}
 */
export class ProductSettingActionComponent
	extends ProductSettingDirective
	implements OnInit, OnDestroy, IDynamicComponent<Component, any>
{
	/**
	 * Initializes a new instance of the product setting action component.
	 *
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance API service.
	 * @param {ActivityService} activityService
	 * The activity service.
	 * @memberof ProductSettingActionComponent
	 */
	public constructor(
		public entityInstanceApiService: EntityInstanceApiService,
		public entityService: EntityService,
		public activityService: ActivityService)
	{
		super();
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof ProductSettingActionComponent
	 */
	@Input() public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the entity instance event
	 *
	 * @type {EventEmitter<IEntityInstance>}
	 * @memberof ProductSettingActionComponent
	 */
	@Output() public updateEntityInstance: EventEmitter<IEntityInstance> =
		new EventEmitter<IEntityInstance>();

	/**
	 * Gets or sets a value indicating whether the component is loading.
	 *
	 * @type {boolean}
	 * @memberof ProductSettingActionComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets a value indicating whether the component is updating.
	 *
	 * @type {boolean}
	 * @memberof ProductSettingActionComponent
	 */
	public updating: boolean = false;

	/**
	 * Gets or sets a collection of FormlyFieldConfig representing the
	 * formly note layout of the selected note type layout.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof ProductSettingActionComponent
	 */
	public productSettingFormlyLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the current validity of the displayed dynamic form.
	 *
	 * @type {boolean}
	 * @memberof ProductSettingActionComponent
	 */
	public isValid: boolean = false;

	/**
	 * Gets or sets executable action.
	 *
	 * @type {() => Promise<void>}
	 * @memberof ProductSettingActionComponent
	 */
	public executableAction: () => Promise<void>;

	/**
	 * Gets or sets the entity instance component.
	 *
	 * @type {EntityInstanceComponent}
	 * @memberof ProductSettingActionComponent
	 */
	public entityInstanceComponent: EntityInstanceComponent;

	/**
	 * Gets or sets the selected product.
	 *
	 * @type {string}
	 * @memberof ProductSettingActionComponent
	 */
	public selectedProduct: string;

	/**
	 * Gets or sets the selected product.
	 *
	 * @type {string}
	 * @memberof ProductSettingActionComponent
	 */
	 public selectedState: string;

	/**
	 * Gets or sets the product instances by state.
	 *
	 * @type {IEntityInstance[]}
	 * @memberof ProductSettingActionComponent
	 */
	public productsByState: IEntityInstance[] = [];

	/**
	 * Gets or sets the product setting index.
	 *
	 * @type {number}
	 * @memberof ProductSettingActionComponent
	 */
	public productSettingIndex: number;

	/**
	 * Gets or sets the create view.
	 *
	 * @type {boolean}
	 * @memberof ProductSettingActionComponent
	 */
	public createView: boolean;

	/**
	 * Gets or sets the commission dropdown options.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof ProductSettingActionComponent
	 */
	public commissionOptions: IDropdownOption[] = [];

	/**
	 * Gets or sets the authority options.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof ProductSettingActionComponent
	 */
	 public authorityOptions: IDropdownOption[] = [];

	/**
	 * Gets or sets display sub title.
	 *
	 * @type {string}
	 * @memberof ProductSettingActionComponent
	 */
	public displaySubTitle: string;

	/**
	 * Gets or sets action button label.
	 *
	 * @type {string}
	 * @memberof ProductSettingActionComponent
	 */
	public actionButtonLabel: string;

	/**
	 * Gets or sets product settings data set.
	 *
	 * @type {any}
	 * @memberof ProductSettingActionComponent
	 */
	public productSettingsDataSet: any;

	/**
	 * Gets the state names.
	 *
	 * @type {string[]}
	 * @memberof ProductSettingActionComponent
	 */
	private readonly states: string[] =
		[
			'AL',
			'AK',
			'AS',
			'AZ',
			'AR',
			'CA',
			'CO',
			'CT',
			'DE',
			'DC',
			'FL',
			'GA',
			'GU',
			'HI',
			'ID',
			'IL',
			'IN',
			'IA',
			'KS',
			'KY',
			'LA',
			'ME',
			'MD',
			'MA',
			'MI',
			'MN',
			'MP',
			'MS',
			'MO',
			'MT',
			'NE',
			'NV',
			'NH',
			'NJ',
			'NM',
			'NY',
			'NC',
			'ND',
			'OH',
			'OK',
			'OR',
			'PA',
			'PR',
			'RI',
			'SC',
			'SD',
			'TN',
			'TX',
			'UM',
			'UT',
			'VI',
			'VT',
			'VA',
			'WA',
			'WV',
			'WI',
			'WY'
		];

	/**
	 * Gets the product authorities.
	 *
	 * @type {string[]}
	 * @memberof ProductSettingActionComponent
	 */
	private readonly productAuthorities: string[] =
		[
			this.productAuthorityTypes.active,
			this.productAuthorityTypes.inactive,
			this.productAuthorityTypes.serviceExisting
		];

	/**
	 * Gets the create display subTitle.
	 *
	 * @type {string}
	 * @memberof ProductSettingActionComponent
	 */
	private readonly createDisplaySubTitle: string =
		`${AppConstants.drawerActionLabels.add}: Product`;

	/**
	 * Gets the products type group.
	 *
	 * @type {string}
	 * @memberof ProductSettingActionComponent
	 */
	private readonly productsTypeGroup: string =
		`${InsuranceConstants.insuranceEntityTypeGroups.products}`;

	/**
	 * Gets the products type group.
	 *
	 * @type {string}
	 * @memberof ProductSettingActionComponent
	 */
	private readonly commissionsTypeGroup: string =
		`${InsuranceConstants.insuranceEntityTypeGroups.commissionsSchedules}`;

	/**
	 * Handles the on initialization event.
	 * This will gather and display associated entities based on the given
	 * context parameters.
	 *
	 * @async
	 * @returns {Promise<void>}
	 * @memberof ProductSettingActionComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.entityInstanceComponent =
			(<EntityInstanceComponent>this.context.source);

		this.productSettingsDataSet =
			<any>
			{
				data: {
					productSettings:
						cloneDeep(this.entityInstanceComponent.entityInstance
							.data.productSettings)
				}
			};

		this.createView =
			this.context.data.drawerComponent.displayMode
				=== AppConstants.displayMode.create;

		await this.setDynamicContent();
		this.setProductSettingFormlyLayout();
	}

	/**
	 * Handles on destroy to load a fresh entity instance.
	 *
	 * @returns {Promise<void>}
	 * @memberof ProductSettingActionComponent
	 */
	public async ngOnDestroy(): Promise<void>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			this.entityInstanceComponent.entityTypeGroup;
		this.entityInstanceComponent.entityInstance =
			await this.entityInstanceApiService
				.get(this.entityInstanceComponent.entityInstance.id);
	}

	/**
	 * Sets the dynamic content based on the create view.
	 *
	 * @async
	 * @returns {Promise<void>}
	 * @memberof ProductSettingActionComponent
	 */
	public async setDynamicContent(): Promise<void>
	{
		if (this.createView === true)
		{
			this.displaySubTitle = this.createDisplaySubTitle;
			this.actionButtonLabel = `${AppConstants.drawerActionLabels.add}`;
			this.productSettingIndex =
				this.entityInstanceComponent.entityInstance
					.data.productSettings.length;

			this.executableAction =
				this.createProductSetting;

			this.commissionOptions = [];
		}
		else
		{
			const selectedItem: any =
				this.context.data.drawerComponent.selectedItem.item;

			this.productSettingIndex =
				selectedItem.itemIndex;

			const productInstance: IEntityInstance =
				selectedItem.productInstance;

			this.displaySubTitle =
				`${this.context.data.drawerComponent.displayMode
					=== AppConstants.displayMode.update
					? 'Edit'
					: 'View'}: `
					+ `${productInstance.data.name}`;

			this.actionButtonLabel =
				`${AppConstants.drawerActionLabels.save}`;

			this.executableAction =
				this.updateProductSetting;

			this.entityInstanceApiService.entityInstanceTypeGroup =
				this.productsTypeGroup;
			this.commissionOptions =
				await this.getCommissionOptions(productInstance.id);
		}
	}

	/**
	 * Sets the product setting formly layout.
	 *
	 * @returns {void}
	 * @memberof ProductSettingActionComponent
	 */
	public setProductSettingFormlyLayout(): void
	{
		this.productSettingFormlyLayout =
			[
				{
					key: `data.productSettings[${
						this.productSettingIndex}].state`,
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'State',
						required: true,
						placeholder: AppConstants.placeholders.selectAnOption,
						showClear: true,
						options: this.getDropdownOptions(this.states),
						gridColumns: 12,
						change: async (field: FormlyFieldConfig) =>
							this.handleStateOnChange(field),
					},
					asyncValidators: {
						productValidator: {
							expression: (
								_formControl: FormControl,
								field: FormlyFieldConfig) =>
								this.validProductSetting(field),
							message:
								AppConstants.empty
						}
					},
					hideExpression:
						'field.templateOptions.context.data?'
							+ '.drawerComponent.displayMode !== '
							+ `"${AppConstants.displayMode.create}"`
				},
				{
					key: `data.productSettings[${
						this.productSettingIndex}].name`,
					type: this.createView === true
						? FormlyConstants.customControls.customSelect
						: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						showClear: true,
						required: true,
						placeholder: AppConstants.placeholders.selectAnOption,
						label: 'Product',
						options: [],
						gridColumns: 12,
						change: async (field: FormlyFieldConfig) =>
							this.handleProductOnChange(field)
					},
					validators: {
						commissionValidator: {
							expression: () =>
								this.commissionOptions.length > 1
									|| this.commissionOptions.length === 0,
							message:
								'No available commission schedule.'
						}
					},
					hideExpression:
						'field.templateOptions.context.data?'
							+ '.drawerComponent.displayMode !== '
							+ `"${AppConstants.displayMode.create}"`
				},
				{
					key: `data.productSettings[${
						this.productSettingIndex}].authority`,
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Authority',
						disabled: this.context.data.drawerComponent.displayMode
							=== AppConstants.displayMode.view,
						tooltip: 'Active - Ability to create new and service '
							+ 'policies for a product. Inactive - Inability to '
							+ 'create new and service existing policies for a '
							+ 'product. Service Existing - service existing '
							+ 'policies for a product.',
						placeholder: AppConstants.placeholders.selectAnOption,
						required: true,
						showClear: true,
						options:
							this.getDropdownOptions(this.productAuthorities),
						default: 'Active',
						gridColumns: 12
					}
				},
				{
					key: `data.productSettings[${
						this.productSettingIndex}].commissionScheduleName`,
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Commission Schedule',
						required: true,
						disabled: this.context.data.drawerComponent.displayMode
							=== AppConstants.displayMode.view,
						placeholder: AppConstants.placeholders.selectAnOption,
						default: null,
						showClear: true,
						options: this.commissionOptions,
						gridColumns: 12
					}
				},
				{
					key: `data.productSettings[${
						this.productSettingIndex}].subCommissionScheduleName`,
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Sub Commission Schedule',
						tooltip: 'Schedule applies to commissionable amounts '
							+ 'related to child agencies.',
						disabled: this.context.data.drawerComponent.displayMode
							=== AppConstants.displayMode.view,
						placeholder: AppConstants.placeholders.selectAnOption,
						default: null,
						showClear: true,
						options: this.commissionOptions,
						gridColumns: 12
					}
				}
			];

		this.loading = false;
	}

	/**
	 * Validates the product setting.
	 *
	 * @return {boolean}
	 * The product setting validity
	 * @memberof ProductSettingActionComponent
	 */
	public async validProductSetting(field: FormlyFieldConfig): Promise<boolean>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			this.productsTypeGroup;
		this.productsByState =
			!AnyHelper.isNullOrEmpty(field.formControl.value)
				? await this.entityInstanceApiService
					.query(
						`state eq "${field.formControl.value}"`,
						AppConstants.empty)
				: [];

		const productField = field.parent.fieldGroup[1];
		productField.templateOptions.required = true;

		const filteredProductOptions =
			this.productsByState
				.filter((product) =>
					!this.entityInstanceComponent.entityInstance.data
						.productSettings.some(
							(productSetting: IProductSetting) =>
								productSetting.name
								=== product.data.name))
				.map((item: any) =>
				<IDropdownOption>
				{
					label: item.data.name,
					value: item.data.name
				});

		this.updateValueAndDropdownOptions(
			productField,
			filteredProductOptions,
			true,
			this.selectedProduct);

		if (this.productsByState.length === 0)
		{
			field.asyncValidators.productValidator.message =
				'No products exist for this state.';
		}
		else if (filteredProductOptions.length === 0)
		{
			field.asyncValidators.productValidator.message =
				'No more products to add for this state.';
		}

		return Promise.resolve(this.productsByState.length > 0
			&& filteredProductOptions.length > 0);
	}

	/**
	 * Handle the state field on change.
	 *
	 * @async
	 * @param {FormlyFieldConfig} field
	 * The state field.
	 * @return {Promise<void>}
	 * @memberof ProductSettingActionComponent
	 */
	public async handleStateOnChange(field: FormlyFieldConfig): Promise<void>
	{
		if (this.createView !== true
			|| this.selectedState === field.formControl.value)
		{
			return;
		}

		this.selectedState = field.formControl.value;
		this.authorityOptions = [];
		this.commissionOptions = [];

		this.updateDynamicFields(field);

		if (AnyHelper.isNullOrEmpty(field.formControl.value))
		{
			this.updateValueAndDropdownOptions(
				field.parent.fieldGroup[1],
				[]);
		}

		field.formControl.updateValueAndValidity();
	}

	/**
	 * Handle the product field on change.
	 *
	 * @async
	 * @param {FormlyFieldConfig} field
	 * The product field.
	 * @return {Promise<void>}
	 * @memberof ProductSettingActionComponent
	 */
	public async handleProductOnChange(field: FormlyFieldConfig): Promise<void>
	{
		if (this.createView !== true
			|| field.formControl.value
				=== this.selectedProduct)
		{
			return;
		}

		this.selectedProduct =
			field.formControl.value;

		if (AnyHelper.isNullOrEmpty(this.selectedProduct))
		{
			this.updateDynamicFields(field);

			return;
		}

		this.updating = true;
		this.entityInstanceApiService.entityInstanceTypeGroup =
				this.productsTypeGroup;
		const productInstance: IEntityInstance =
			await this.entityInstanceApiService
				.getSingleQueryResult(
					`name eq "${this.selectedProduct}"`,
					AppConstants.empty);

		this.authorityOptions =
			this.getDropdownOptions(this.productAuthorities);
		this.commissionOptions =
			await this.getCommissionOptions(productInstance.id);

		this.updateDynamicFields(field);
	}

	/**
	 * Updates the dynamic field at this components.
	 *
	 * @param {FormlyFieldConfig} field
	 * The root formly field.
	 * @memberof ProductSettingActionComponent
	 */
	public updateDynamicFields(
		field: FormlyFieldConfig): void
	{
		const fieldGroup: FormlyFieldConfig[] =
			field.parent.fieldGroup;

		let dynamicCommissionOptions: IDropdownOption[] = [];
		let dynamicAuthorityOptions: IDropdownOption[] = [];

		if (!AnyHelper.isNull(fieldGroup[1].formControl.value))
		{
			dynamicCommissionOptions = this.commissionOptions;
			dynamicAuthorityOptions = this.authorityOptions;
		}

		// Update authority field options.
		this.updateValueAndDropdownOptions(
			fieldGroup[2],
			dynamicAuthorityOptions,
			true,
			fieldGroup[2].templateOptions.default);
		// Update commissions schedule field options.
		this.updateValueAndDropdownOptions(
			fieldGroup[3],
			dynamicCommissionOptions);
		// Update subCommission schedule field options.
		this.updateValueAndDropdownOptions(
			fieldGroup[4],
			dynamicCommissionOptions,
			false);

		this.updating = false;
	}

	/**
	 * Gets the dropdown options.
	 *
	 * @param {FormlyFieldConfig} field
	 * The field to update.
	 * @param {any[] | Observable<any[]>} options
	 * The options to be set.
	 * @param {boolean} requiredField
	 * Determines if field is required by design.
	 * Default is true.
	 * @param {any} value
	 * The new value. Null if not sent
	 * @memberof ProductSettingActionComponent
	 */
	public updateValueAndDropdownOptions(
		field: FormlyFieldConfig,
		options: any[] | Observable<any[]>,
		requiredField: boolean = true,
		value: any = null): void
	{
		if (!AnyHelper.isFunction(field.templateOptions.updateDataOptions))
		{
			return;
		}

		field.templateOptions.required =
			(<any[]>options).length > 0 && requiredField === true;
		field.templateOptions.options = options;
		field.templateOptions.updateDataOptions();
		field.formControl.setValue(value);
		field.formControl.updateValueAndValidity();
	}

	/**
	 * Gets the dropdown options.
	 *
	 * @return {IDropdownOption[]}
	 * @memberof ProductSettingActionComponent
	 */
	public getDropdownOptions(
		optionItems: any[],
		labelKey: string = null,
		valueKey: string = null): IDropdownOption[]
	{
		return optionItems
			.map((optionItem: string) =>
				({
					label: optionItem[labelKey] || optionItem,
					value: optionItem[valueKey] || optionItem
				}));
	}

	/**
	 * Handles the validity changed event of the contained dynamic form.
	 *
	 * @param {boolean} isValid
	 * The sent validity value of the dynamic form.
	 * @memberof ProductSettingActionComponent
	 */
	public validDataChanged(
		isValid: boolean): void
	{
		this.isValid = isValid;
	}

	/**
	 * Creates a product setting.
	 *
	 * @async
	 * @memberof ProductSettingActionComponent
	 */
	public async createProductSetting(): Promise<void>
	{
		this.updating = true;
		const createData: IProductSetting =
			this.productSettingsDataSet.data.productSettings[
				this.productSettingIndex];

		this.entityInstanceComponent.entityInstance.data.productSettings
			.push(
				<IProductSetting>
				{
					name:
						createData.name,
					authority:
						createData.authority,
					commissionScheduleName:
						createData.commissionScheduleName,
					subCommissionScheduleName:
						createData.subCommissionScheduleName
				});

		await this.executeCommonAction(
			'<strong>Adding</strong> Product Setting',
			'<strong>Added</strong> Product Setting',
			`Product Setting ${createData.name} was added to ${
				this.entityInstanceComponent.entityIdentifier} entity.`,
			`Product Setting ${createData.name} was not added to ${
				this.entityInstanceComponent.entityIdentifier} entity.`);
	}

	/**
	 * Updates a product setting.
	 *
	 * @async
	 * @memberof ProductSettingActionComponent
	 */
	public async updateProductSetting(): Promise<void>
	{
		this.updating = true;
		const updateData: IProductSetting =
			this.productSettingsDataSet.data.productSettings[
				this.productSettingIndex];

		const contextProductSetting: IProductSetting =
			this.entityInstanceComponent
				.entityInstance.data.productSettings[
					this.productSettingIndex];

		contextProductSetting.authority =
			updateData.authority;
		contextProductSetting.commissionScheduleName =
			updateData.commissionScheduleName;
		contextProductSetting.subCommissionScheduleName =
			updateData.subCommissionScheduleName;

		await this.executeCommonAction(
			'<strong>Updating</strong> Product Setting',
			'<strong>Updated</strong> Product Setting',
			`Product Setting ${
				contextProductSetting.name} was updated.`,
			`Product Setting ${
				contextProductSetting.name} was not updated.`);
	}

	/**
	 * Executes the common action.
	 *
	 * @async
	 * @param {string} pendingMessage
	 * The activity pending message.
	 * @param {string} completedMessage
	 * The activity completed message.
	 * @param {string} successDetails
	 * The activity success details.
	 * @param {string} failureDetails
	 * The activity failure details.
	 * @memberof ProductSettingActionComponent
	 */
	public async executeCommonAction(
		pendingMessage: string,
		completedMessage: string,
		successDetails: string,
		failureDetails: string): Promise<void>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			this.entityInstanceComponent.entityTypeGroup;

		const actionPromise =
			new Promise (
				async(resolve: any, reject: any) =>
				{
					try
					{
						DocumentHelper.setElementDisplay(
							AppConstants.cssClasses.entityLayoutMask,
							true);

						await this.entityInstanceApiService.update(
							this.entityInstanceComponent.entityInstance.id,
							this.entityInstanceComponent.entityInstance);
						resolve();
					}
					catch (exception)
					{
						this.entityInstanceApiService.entityInstanceTypeGroup =
							this.entityInstanceComponent.entityTypeGroup;
						const databaseEntityInstance: IEntityInstance =
							await this.entityInstanceApiService
								.get(this.entityInstanceComponent
									.entityInstance.id);

						this.entityInstanceComponent
							.entityInstance.data.productSettings =
							databaseEntityInstance.data.productSettings;
						reject(exception);
					}
					finally
					{
						this.changeDisplayMode.emit(
							AppConstants.displayMode.list);

						this.entityInstanceComponent.reloadEntity();
					}
				});

		await this.activityService.handleActivity(
			new Activity(
				actionPromise,
				pendingMessage,
				completedMessage,
				successDetails,
				failureDetails));
	}

	/**
	 * Gets the commission options.
	 *
	 * @async
	 * @param {number} productInstanceId
	 * The product instance id.
	 * @memberof ProductSettingActionComponent
	 */
	private async getCommissionOptions(
		productInstanceId: number): Promise<IDropdownOption[]>
	{
		return [
			...(await this.entityInstanceApiService
				.getChildren(
					productInstanceId,
					AppConstants.empty,
					AppConstants.empty,
					null,
					null,
					this.commissionsTypeGroup))
				.map((item: any) =>
					<IDropdownOption>
					{
						label: item.data.name,
						value: item.data.name
					}),
			...[
				<IDropdownOption>
				{
					value: null,
					label: 'Not Set'
				}
			]
		];
	}
}