/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Directive
} from '@angular/core';
import {
	DisplayComponentDefinitionApiService
} from '@api/services/display-components/display-component-definition.api.service';
import {
	DisplayComponentInstance
} from '@shared/implementations/display-components/display-component-instance';
import {
	DisplayComponentInstanceApiService
} from '@api/services/display-components/display-component-instance.api.service';
import {
	DisplayComponentTypeApiService
} from '@api/services/display-components/display-component-type.api.service';
import {
	FormControl
} from '@angular/forms';
import {
	IPowerBiCommandArguments
} from '@shared/interfaces/reports/power-bi/power-bi-command-arguments.interface';
import {
	IPowerBiDataset
} from '@shared/interfaces/reports/power-bi/power-bi-dataset.interface';
import {
	IPowerBiReport
} from '@shared/interfaces/reports/power-bi/power-bi-report.interface';
import {
	IPowerBiReportDefinition
} from '@shared/interfaces/reports/power-bi/power-bi-report-definition.interface';
import {
	PowerBiApiService
} from '@shared/services/power-bi-api.service';
import {
	PowerBiService
} from '@shared/services/power-bi.service';
import {
	PowerBiWizardStepDirective
} from '@bi/directives/power-bi-wizard-step.directive';
import {
	Router
} from '@angular/router';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/* eslint-enable max-len */

@Directive({
	selector: '[biReportSettings]'
})

/**
 * A directive representing shared logic for a component interacting
 * with bi report settings.
 *
 * @export
 * @extends {PowerBiWizardStepDirective<IPowerBiDataset>}
 * @class BIReportSettingDirective
 */
export class BIReportSettingDirective
	extends PowerBiWizardStepDirective<IPowerBiDataset>
{
	/**
	 * Initializes a new instance of the clone power bi report component.
	 *
	 * @param {PowerBiService} powerBiService
	 * The power bi display service used for external power bi reports.
	 * @param {PowerBiApiService} powerBiApiService
	 * The power bi api service used for external power bi reports.
	 * @param {DisplayComponentDefinitionApiService}
	 * displayComponentDefinitionApiService
	 * @param {DisplayComponentTypeApiService}
	 * displayComponentTypeApiService
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this component.
	 *
	 * Initializes a new instance of bi report settings directive.
	 * @memberof BIReportSettingDirective
	 */
	public constructor(
		public powerBiService: PowerBiService,
		public powerBiApiService: PowerBiApiService,
		public displayComponentDefinitionApiService:
			DisplayComponentDefinitionApiService,
		public displayComponentTypeApiService:
			DisplayComponentTypeApiService,
		public siteLayoutService: SiteLayoutService
	)
	{
		super(
			powerBiService,
			powerBiApiService,
			displayComponentDefinitionApiService,
			displayComponentTypeApiService,
			siteLayoutService);
	}

	/**
	 * icon action id constants.
	 *
	 * @type {{navigateToEditReport: string;
	*	navigateToViewReport: string;
	*	deleteReport: string;}}
	*
	* @memberof BIReportSettingDirective
	*/
	public iconActionIds:
	{
		navigateToEditReport: string;
		navigateToViewReport: string;
		deleteReport: string;
	} = {
		navigateToEditReport: 'NavigateToEditReport',
		navigateToViewReport: 'NavigateToViewReport',
		deleteReport: 'DeleteReport'
	};

	/**
	* A method that get the delete
	* statement.
	*
	* @returns {string}
	* @memberof BIReportSettingDirective
	*/
	public getDeleteStatement(): string
	{
		return '\nAre you sure you want to delete this Report?';
	}

	/**
	* A method that performs the navigate
	* to display action.
	*
	* @param {Router} router
	* Provided router.
	* @param {IPowerBiCommandArguments} commandArguments
	* Provided Command Arguments.
	* @memberof BIReportSettingDirective
	*/
	public navigateToDisplay(
		router: Router,
		commandArguments: IPowerBiCommandArguments): void
	{
		const basePageReportDisplayComponentName: string =
			`${AppConstants.displayComponentTypes.basePage}.`
				+ commandArguments.rowData.displayComponentInstanceName;

		const displayMode: string =
			commandArguments.item.id === this.iconActionIds.navigateToViewReport
				? AppConstants.viewTypes.view
				: AppConstants.viewTypes.edit;

		// Store this value for back functionality.
		history.pushState(
			null,
			AppConstants.empty,
			router.url);

		router.navigate(
			[
				AppConstants.moduleNames.bi,
				AppConstants.route.display,
				basePageReportDisplayComponentName,
				displayMode
			],
			{
				replaceUrl: true
			});
	}

	/**
	* Validates that new report names do not begin with an underscore
	* which would set them as not searchable due to our underscore
	* name filters on reports and datasets.
	*
	*
	* @param {FormControl} control
	* The control to be validated.
	* @returns {boolean}
	* A value representing that the value of this control starts with
	* and underscore.
	* @memberof BIReportSettingDirective
	*/
	public validateNoUnderscoreStart(
		control: FormControl): boolean
	{
		return control.value.indexOf(
			AppConstants.characters.underscore) !== 0;
	}

	/**
	* A method that performs the rename Power BI
	* report action.
	*
	* @param {IPowerBiReportDefinition} reportDefinition
	* Provided Report Definition.
	* @returns {IPowerBiReport}
	* @memberof BIReportSettingDirective
	*/
	public powerBIReportMapping(
		reportDefinition: IPowerBiReportDefinition): IPowerBiReport
	{
		const powerBiReport: IPowerBiReport = {
			id: reportDefinition.reportId,
			datasetId: reportDefinition.datasetId,
			embedUrl: AppConstants.empty,
			isFromPbix: false,
			isOwnedByMe: false,
			name: reportDefinition.reportName,
			reportType: reportDefinition.reportType,
			web: AppConstants.empty
		};

		return powerBiReport;
	}

	/**
	* Get matching report names.
	*
	* @param {PowerBiApiService} powerBiApiService
	* Provided power BI Api service.
	* @param {FormControl} control
	* Provided report name.
	* @param {string} selectedGroupId
	* Provided report name.
	* @returns {IPowerBiReport[]}
	* @async
	* @memberof BIReportSettingDirective
	*/
	public async getMatchingReportNames(
		powerBiApiService: PowerBiApiService,
		control: FormControl,
		selectedGroupId: string): Promise<IPowerBiReport[]>
	{
		const groupLevelData: any =
			await powerBiApiService
				.groupLevelData<IPowerBiReport>(
					selectedGroupId);

		return groupLevelData
			.filter((report: IPowerBiReport) =>
				report.name ===
					StringHelper.trim(
						control.value));
	}

	/**
	* Get matching display names.
	*
	* @param {DisplayComponentInstanceApiService} displayComponentService
	* Provided display component service object.
	* @param {string} displayComponentName
	* Provided report name.
	* @returns {Promise<DisplayComponentInstance[]>}
	* @async
	* @memberof BIReportSettingDirective
	*/
	public async getmatchingDisplayNames(
		displayComponentService:
			DisplayComponentInstanceApiService,
		displayComponentName: string): Promise<DisplayComponentInstance[]>
	{
		const matchingDisplayNames: any =
			await displayComponentService
				.query(
					`${AppConstants.commonProperties.name} eq `
						+ `'${displayComponentName}'`,
					`${AppConstants.commonProperties.id}`);

		return matchingDisplayNames;
	}

	/**
	* Sets the row Selected Info data.
	*
	* @param {string} displayComponentInstanceName
	* The display component instance name.
	* @param {string} navigateAction
	* Provided navigation action, null as default value
	* Whether or not the current form is valid.
	* @returns {IPowerBiCommandArguments}
	* @memberof BIReportSettingDirective
	*/
	public getRowSelectedInfo(
		displayComponentInstanceName: string,
		navigateAction: string = null): IPowerBiCommandArguments
	{
		return {
			rowData:
				{
					displayComponentInstanceName: displayComponentInstanceName
				},
			item:
				{
					id: AnyHelper.isNullOrEmpty(navigateAction)
						? this.iconActionIds.navigateToViewReport
						: navigateAction
				}
		};
	}
}