/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonFormlyFieldConstants
} from '@shared/constants/common-formly-field-constants';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	Component
} from '@angular/core';
import {
	DateTime
} from 'luxon';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	FormControl
} from '@angular/forms';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	ISecurityApplication
} from '@shared/interfaces/security/security-application.interface';
import {
	OptionsFactory
} from '@shared/factories/options-factory';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SecurityApplicationApiService
} from '@api/services/security/security-application.api.service';
import {
	SecuritySessionApiService
} from '@api/services/security/security-session.api.service';
import {
	TableHelper
} from '@shared/helpers/table.helper';

/* eslint-enable max-len */
@Component({
	selector: 'app-applications',
	templateUrl: './applications.component.html',
	styleUrls: [
		'./applications.component.scss'
	]
})

/**
 * A component representing an instance of the security applications
 * component.
 *
 * @export
 * @class ApplicationsComponent
 * @extends {CommonTablePageDirective}
 */
export class ApplicationsComponent
	extends CommonTablePageDirective
{
	/**
	 * Initializes a new instance of the ApplicationsComponent class.
	 *
	 * @param {SecurityApplicationApiService} securityApplicationApiService
	 * The api service used to load security application data.
	 * @param {SecuritySessionApiService} securitySessionApiService
	 * The api service used to load security session data.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {OptionsFactory} optionsFactory
	 * The options factory used for common dropdown options.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof ApplicationsComponent
	 */
	public constructor(
		public securityApplicationApiService: SecurityApplicationApiService,
		public securitySessionsApiService: SecuritySessionApiService,
		public activityService: ActivityService,
		public optionsFactory: OptionsFactory,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the table definitions for the common table view.
	 *
	 * @type {ICommonTable}
	 * @memberof ApplicationsComponent
	 */
	public securityApplicationsTableDefinitions: ICommonTable;

	/**
	 * Sets up variables used in this admin page based filter.
	 *
	 * @memberof ApplicationsComponent
	 */
	public setupPageVariables(): void
	{
		let displayOrder: number = 1;
		this.availableColumns =
			[
				{
					dataKey: 'id',
					columnHeader: 'Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'apiKey',
					columnHeader: 'Api Key',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'enabled',
					columnHeader: 'Enabled',
					displayOrder: displayOrder
				}
			];
		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Sets up the table definitions for a standard table
	 *
	 * @async
	 * @memberof ApplicationsComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		const securityGroupOptions: IDropdownOption[] =
			await this.optionsFactory.getSecurityGroupOptions();

		this.securityApplicationsTableDefinitions = {
			actions: {
				create: {
					layout: [
						{
							key: 'data.name',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Name',
								disabled: false,
								required: true
							},
							asyncValidators: {
								uniqueName: {
									expression: (
										control: FormControl) =>
										this.uniqueNameValidator(control),
									message: 'Application Name not available.'
								}
							}
						},
						{
							key: 'data.apiKey',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Api Key',
								disabled: false,
								required: true
							},
							asyncValidators: {
								uniqueKey: {
									expression: async (
										control: FormControl) =>
										this.uniqueKeyValidator(control),
									message: 'Application Key not available.'
								}
							}
						},
						{
							key: 'data.enabled',
							type: FormlyConstants.customControls
								.customInputSwitch,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Enabled'
							}
						},
						CommonFormlyFieldConstants.publicField,
						{
							...CommonFormlyFieldConstants
								.ownershipSecurityGroupField,
							templateOptions: {
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField
									.templateOptions,
								options: securityGroupOptions
							}
						}
					],
					items: [
						{
							label: 'Create',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: () => this.createApplication()

						}]
				},
				filter: {
					quickFilters:
						[
							{
								label: 'All Applications',
								value: AppConstants.empty
							},
							{
								label: 'Enabled Applications',
								value: 'Enabled eq true'
							}
							,
							{
								label: 'Disabled Applications',
								value: 'Enabled eq false'
							}
						],
					selectedFilterValue: this.tableFilterQuery
				},
				view: {
					component: null,
					layout: [
						{
							key: 'data.name',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Name',
								disabled: true
							}
						},
						{
							key: 'data.apiKey',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Api Key',
								disabled: true
							}
						},
						{
							key: 'data.enabled',
							type: FormlyConstants.customControls
								.customInputSwitch,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Enabled',
								disabled: true
							}
						},
						{
							...CommonFormlyFieldConstants
								.publicField,
							templateOptions: {
								...CommonFormlyFieldConstants
									.publicField
									.templateOptions,
								disabled: true
							}
						},
						{
							...CommonFormlyFieldConstants
								.ownershipSecurityGroupField,
							templateOptions: {
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField
									.templateOptions,
								options: securityGroupOptions,
								disabled: true
							}
						}
					],
					items: []
				},
				update: {
					layout: [
						{
							key: 'data.name',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Name',
								required: true
							},
							asyncValidators: {
								uniqueName: {
									expression: (control: FormControl) =>
										this.updateNameValidator(control),
									message: 'Application Name not available.'
								}
							}
						},
						{
							key: 'data.enabled',
							type: FormlyConstants.customControls
								.customInputSwitch,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Enabled'
							}
						},
						CommonFormlyFieldConstants.publicField,
						{
							...CommonFormlyFieldConstants
								.ownershipSecurityGroupField,
							templateOptions: {
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField
									.templateOptions,
								options: securityGroupOptions
							}
						}
					],
					definition: null,
					items: [
						{
							label: 'Save',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: () => this.updateApplication()
						}]
				},
				delete: {
					items: [
						{
							label: 'Confirm',
							styleClass: AppConstants.cssClasses.pButtonDanger,
							command: () => this.deleteApplication(),
						}],
					deleteStatement: () => this.deleteStatement(),
				}
			},
			tableTitle: 'Applications',
			objectSearch: {
				filter: this.tableFilterQuery,
				orderBy: `Id ${AppConstants.sortDirections.descending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: this.tableRowCount
			},
			apiPromise: (objectSearch: IObjectSearch) =>
				this.securityApplicationApiService
					.query(
						objectSearch.filter,
						objectSearch.orderBy,
						objectSearch.offset,
						objectSearch.limit),
			availableColumns: this.availableColumns,
			selectedColumns: this.selectedColumns,
			columnSelectionMode: this.columnSelectionMode,
			expandTitle: () =>
				TableHelper.getExpandTitle(
					this.commonTableContext,
					'Application'),
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			filterCriteriaChanged: (filterCriteria: string) =>
			{
				this.tableFilterQuery = filterCriteria;
				this.restoreTableDefinition();
			},
			rowCountChanged: (rowCount: number) =>
			{
				this.tableRowCount = rowCount;
				this.restoreTableDefinition();
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.securityApplicationsTableDefinitions.selectedColumns =
					selectedColumns;
				this.selectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.securityApplicationsTableDefinitions.columnSelectionMode =
					columnSelectionMode;
				this.columnSelectionMode = columnSelectionMode;
			}
		};
		this.loadingTableDefinitions = false;
	}

	/**
	 * Updates an existing application.
	 *
	 * @async
	 * @memberof ApplicationComponent
	 */
	public async updateApplication(): Promise<void>
	{
		await this.activityService.handleActivity(
			new Activity(
				this.securityApplicationApiService
					.update(
						this.commonTableContext.source.rowData.id,
						this.commonTableContext.source.rowData),
				'<strong>Updating Application</strong>',
				'<strong>Updated Application</strong>',
				`Application ${this.commonTableContext.source.rowData.id} `
					+ 'was updated.',
				`Application ${this.commonTableContext.source.rowData.id}
					was not updated.`),
			AppConstants.activityStatus.complete,
			true);
	}

	/**
	 * Validates if the the entered name is unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The Formly form control.
	 * @return {Promise<any>}
	 * The validity to display or not a formly error and
	 * disable or enable the action.
	 * @memberof ApplicationComponent
	 */
	private async uniqueNameValidator(control: FormControl): Promise<any>
	{
		const applicationName:
			ISecurityApplication[] =
			await this.securityApplicationApiService
				.query(
					`Name eq '${control.value}'`,
					AppConstants.empty);

		return Promise.resolve(AnyHelper.isNullOrEmpty(applicationName[0]));
	}

	/**
	 * Validates if the the entered key is unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The Formly form control.
	 * @return {Promise<any>}
	 * The validity to display or not a formly error and
	 * disable or enable the action.
	 * @memberof ApplicationComponent
	 */
	private async uniqueKeyValidator(control: FormControl): Promise<any>
	{
		const applicationKey:
			ISecurityApplication[] =
			await this.securityApplicationApiService
				.query(
					`ApiKey eq '${control.value}'`,
					AppConstants.empty);

		return Promise.resolve(AnyHelper.isNullOrEmpty(applicationKey[0]));
	}

	/**
	 * Validates if the name is allowed to be saved.
	 *
	 * @async
	 * @param {FormControl} control
	 * The Formly form control.
	 * @return {Promise<any>}
	 * The validity to display or not a formly error and
	 * disable or enable the action.
	 * @memberof ApplicationComponent
	 */
	private async updateNameValidator(control: FormControl): Promise<any>
	{
		const originalApplication:
			ISecurityApplication =
			await this.securityApplicationApiService
				.get(this.commonTableContext
					.source.rowData.id);

		const applicationName:
			ISecurityApplication[] =
			await this.securityApplicationApiService
				.query(
					`Name eq '${control.value}'`,
					AppConstants.empty);

		return Promise.resolve(
			control.value === originalApplication.name
				|| AnyHelper.isNullOrEmpty(applicationName[0]));
	}

	/**
	 * Create a new application.
	 *
	 * @async
	 * @memberof ApplicationComponent
	 */
	private async createApplication(): Promise<void>
	{
		this.commonTableContext.source.rowData.id = 0;

		await this.activityService.handleActivity(
			new Activity(
				this.securityApplicationApiService
					.create(this.commonTableContext
						.source.rowData),
				'<strong>Creating Application</strong>',
				'<strong>Created Application</strong>',
				'Application was created.',
				'Application was not created.'),
			AppConstants.activityStatus.complete,
			true);

		this.restoreTableDefinition();
	}

	/**
	 * Deletes an existing application.
	 *
	 * @async
	 * @memberof ApplicationComponent
	 */
	private async deleteApplication(): Promise<void>
	{
		await this.activityService.handleActivity(
			new Activity(
				this.securityApplicationApiService
					.delete(this.commonTableContext.source.rowData.id),
				'<strong>Deleting Application</strong>',
				'<strong>Deleted Application</strong>',
				`Application ${this.commonTableContext.source.rowData.id} `
					+ 'was deleted.',
				`Application ${this.commonTableContext.source.rowData.id}
					was not deleted.`));

		this.restoreTableDefinition();
	}

	/**
	 * Gets the delete statement.
	 *
	 * @async
	 * @return {Promise<string>}
	 * The delete statement string.
	 * @memberof ApplicationComponent
	 */
	private async deleteStatement(): Promise<string>
	{
		const currentIsoDateTime: string =
			DateTime.now()
				.toUTC()
				.toISO();

		const aMonthFromCurrentIsoDateTime: string =
			DateTime.now()
				.minus({ month: 1 })
				.toUTC()
				.toISO();

		const activeSessions =
			await this.securitySessionsApiService
				.aggregate(
					'Count',
					AppConstants.empty,
					'ApplicationId eq '
						+ `${this.commonTableContext.source.rowData?.id} `
						+ `AND ExpireDate < '${currentIsoDateTime}' AND `
						+ `ExpireDate > '${aMonthFromCurrentIsoDateTime}'`);

		EventHelper.dispatchTableExpansionPanelLoadedEvent();

		return `Confirm you are about to delete Application
			${this.commonTableContext.source.rowData?.id}
			${(await this.securityApplicationApiService
		.get(this.commonTableContext
			.source.rowData?.id)).name}
			which had ${activeSessions[0].value}
			active sessions in the last 30 days.`;
	}
}