/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input
} from '@angular/core';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/* eslint-enable max-len */

@Component({
	selector: 'operation-icon-bar',
	templateUrl: './operation-icon-bar.component.html',
	styleUrls: [
		'./operation-icon-bar.component.scss'
	]
})

/**
 * A component representing an instance an icon operation bar
 *
 * @export
 * @class OperationIconBarComponent
 */
export class OperationIconBarComponent
{
	/**
	 * Gets or sets the row data.
	 *
	 * @type {any}
	 * @memberof OperationIconBarComponent
	 */
	@Input()
	public rowData: any;

	/**
	 * Gets or sets the dynamic component context.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof OperationIconBarComponent
	 */
	@Input()
	public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the custom context.
	 *
	 * @type {any}
	 * @memberof OperationIconBarComponent
	 */
	@Input()
	public customContext: any;

	/**
	 * Handles the click event attached to the menu item.
	 *
	 * @param {any} event
	 * The click event.
	 * @param {any} item
	 * The menu item that sent this click event.
	 * @param {number} index
	 * The menu item's index.
	 * @memberof OperationIconBarComponent
	 */
	public async iconClick(
		event: any,
		item: any): Promise<void>
	{
		// Avoid processing disabled items.
		if (item.disabled)
		{
			return;
		}

		// Perform item level commands.
		if (item.command)
		{
			if (item.useCommandPromise === true)
			{
				await StringHelper.transformToDataPromise(
					StringHelper.interpolate(
						<string>item.command,
						this.context ?? this.customContext),
					this.context);
			}
			else
			{
				item.command(
					{
						originalEvent: event,
						item: item,
						rowData: this.rowData
					});
			}
		}
	}
}