<div class="ui-g-12 no-padding">
	<div class="ui-g-6 content-section"
		[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView,
			'no-padding': siteLayoutService.displayTabletView}">
		<div class="ellipsis-text user-section">
			<strong class="data-format">
				Username {{context.data.data.userName}}
			</strong>
		</div>
		<div class="section-margin">
			<app-dynamic-formly
				[dataSet]="context.data"
				[layoutSchema]="context.source.formlyDefinitions.layout"
				(validityChanged)="context.source.validExpandComponentChanged($event)">
			</app-dynamic-formly>
		</div>
	</div>

	<div class="ui-g-6"
		[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView,
			'standard-top-padding': siteLayoutService.displayTabletView}">
		<div *ngIf="context.source.displayView && !loadingTableDefinitions">
			<app-common-table
				[tableDefinitions]="manageUserTableDefinitionsView">
			</app-common-table>
		</div>
		<div *ngIf="context.source.displayUpdate && !loadingTableDefinitions">
			<app-common-table
				[tableDefinitions]="manageUserTableDefinitionsUpdate">
			</app-common-table>
		</div>
		<div *ngIf="loadingTableDefinitions"
			class="text-center ui-g-12">
			<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
			</span>
		</div>
	</div>

	<div class="ui-g-12">
		<operation-button-bar
			[initialModel]="context.source.expandActions"
			(loadingOperations)="context.source.operationButtonClicked($event)">
		</operation-button-bar>
	</div>
</div>