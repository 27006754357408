/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component
} from '@angular/core';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IInformationMenuItem
} from '@shared/interfaces/application-objects/information-menu-item.interface';

/* eslint-enable max-len */

@Component({
	selector: 'app-dynamic-summary-card',
	templateUrl: './dynamic-summary-card.component.html'
})

/**
 * A component representing a wrapper to pass context to a summary card display.
 *
 * @export
 * @class DynamicSummaryCardComponent
 * @implements {IDynamicComponent<SessionComponent, IInformationMenuItem<any>>}
 */
export class DynamicSummaryCardComponent
implements IDynamicComponent<Component, IInformationMenuItem<any>>
{
	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, IInformationMenuItem<any>>}
	 * @memberof DynamicSummaryCardComponent
	 */
	public context:
		IDynamicComponentContext<Component, IInformationMenuItem<any>>;
}
