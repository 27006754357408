/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstanceDto
} from '@api/interfaces/entities/entity-instance.dto.interface';
import {
	NoteDirective
} from '@shared/directives/note.directive';

/* eslint-enable max-len */

@Component({
	selector: 'app-note-view',
	templateUrl: './note-view.component.html'
})

/**
 * A component representing context level notes view.
 *
 * @export
 * @class NoteViewComponent
 * @extends {NoteDirective}
 * @implements {OnInit}
 * @implements {IDynamicComponent<Component, any>}
 */
export class NoteViewComponent
	extends NoteDirective
	implements OnInit, IDynamicComponent<Component, any>
{
	/**
	 * Initializes a new instance of the common list component.
	 *
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance API service, used to get children.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type API service, used to get entity types.
	 * @memberof NoteViewComponent
	 */
	public constructor(
		public entityInstanceApiService: EntityInstanceApiService,
		public entityTypeApiService: EntityTypeApiService)
	{
		super(entityTypeApiService);
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof NoteCreateComponent
	 */
	@Input() public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the selected item to be displayed within the note view.
	 *
	 * @type {IEntityInstanceDto}
	 * @memberof NoteViewComponent
	 */
	@Input() public selectedItem: IEntityInstanceDto;

	/**
	 * Gets or sets the type group of the selected item to view.
	 *
	 * @type {string}
	 * @memberof NoteViewComponent
	 */
	@Input() public typeGroup: string;

	/**
	 * Gets or sets the identifier of the selected item to view.
	 *
	 * @type {number}
	 * @memberof NoteViewComponent
	 */
	@Input() public id: number;

	/**
	 * Gets or sets a value indicating whether data is loading.
	 *
	 * @type {boolean}
	 * @memberof NoteViewComponent
	 */
	public loading: boolean = true;

	/**
	 * Handles the on initialization event.
	 * This will gather and display associated entities based on the given
	 * context parameters.
	 *
	 * @async
	 * @returns {Promise<void>}
	 * @memberof NoteViewComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.typeGroup = this.typeGroup
			|| (await this.getEntityTypesFromNameList(
				[this.selectedItem.entityType])).pop()?.group;
		this.id = this.id || this.selectedItem.id;

		this.entityInstanceApiService
			.entityInstanceTypeGroup = this.typeGroup;

		this.selectedItem
			= this.selectedItem
				|| await this.entityInstanceApiService.get(this.id);

		this.loading = false;
	}
}