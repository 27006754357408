/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppEventParameterConstants
} from '@shared/constants/app-event-parameter.constants';
import {
	ChangeDetectorRef,
	Component,
	HostListener,
	OnInit
} from '@angular/core';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	DateTime
} from 'luxon';
import {
	ExtendedCustomControlDirective
} from '@entity/directives/extended-custom-control.directive';
import {
	StringHelper
} from '@shared/helpers/string.helper';

@Component({
	selector: 'custom-calendar',
	templateUrl: './custom-calendar.component.html',
	styleUrls: [
		'./custom-calendar.component.scss'
	]
})

/**
 * A component representing an instance of a Custom Calendar.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomCalendarComponent
 * @extends {ExtendedCustomControlDirective}
 * @implements {OnInit}
 */
export class CustomCalendarComponent
	extends ExtendedCustomControlDirective
	implements OnInit
{
	/** Initializes a new instance of the CustomCalendarComponent.
	 *
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof CustomCalendarComponent
	 */
	public constructor(
		public changeDetector: ChangeDetectorRef)
	{
		super(changeDetector);
	}

	/**
	 * Gets or sets the display date.
	 *
	 * @type {Date}
	 * @memberof CustomCalendarComponent
	 */
	public displayDate: Date;

	/**
	 * Gets or sets the refesh calendar value. This is used to ensure a bug in
	 * primeNg layout change closed calendars no longer reopen on next click.
	 *
	 * @type {boolean}
	 * @memberof CustomCalendarComponent
	 */
	public refreshCalendar: boolean = false;

	/**
	 * Handles the site layout change event which is called
	 * when the site layout service has altered it's variables.
	 *
	 * @memberof CustomCalendarComponent
	 */
	@HostListener(
		AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		// This timeout chain is used to redraw the calendar without flashing.
		// This method can be removed when PrimeNG corrects their calendar.
		setTimeout(() => {
			this.refreshCalendar = true;
		});
		setTimeout(() => {
			this.refreshCalendar = false;
		});
	}

	/**
	 * Handles the hide associated menus event.
	 * This is used to close this calendar when an associated menu is
	 * opened via a non bubbled event, such as a sibling navigation menu.
	 *
	 * @memberof AppProfileComponent
	 */
	@HostListener(
		AppEventConstants.hideAssociatedMenusEvent,
		[AppEventParameterConstants.id])
	public hideAssociatedMenus(
		_controlIdentifer: string): void
	{
		this.siteLayoutChanged();
	}

	/**
	 * Handles the on initialization interface.
	 * This will capture the saved date and time, and translate
	 * this into a date and locale time for use in this component.
	 *
	 * @memberof CustomCalendarComponent
	 */
	public ngOnInit(): void
	{
		this.field.templateOptions.setDisplayDate =
			this.setDisplayDate.bind(this);

		this.setDisplayDate(
			AnyHelper.isNullOrWhitespace(this.field.formControl.value)
				&& !AnyHelper.isNullOrWhitespace(
					this.field.templateOptions.default)
				? this.getDefaultDate()
				: this.field.formControl.value);

		super.ngOnInit();
	}

	/**
	 * Sets a new calendar display date.
	 *
	 * @param {string} displayDate
	 * The date string on UTC ISO.
	 * @memberof CustomCalendarComponent
	 */
	public setDisplayDate(displayDate: string = null): void
	{
		if (DateHelper.fromSimulatedLocalDateToUtcSystemIso(
			DateTime.fromJSDate(this.displayDate)
				.toISO()) === displayDate)
		{
			return;
		}

		this.displayDate =
			!AnyHelper.isNullOrWhitespace(displayDate)
				? DateHelper.fromUtcSystemIsoToSimulatedLocalDate(
					displayDate)
				: null;

		this.dateChange(null);
	}

	/**
	 * Handles the calendar input or select date event.
	 *
	 * @param {Event} event
	 * The event that sent this action.
	 * @memberof CustomCalendarComponent
	 */
	public dateChange(
		event: Event): void
	{
		const inputDate: string = (<any>event?.target)?.value;

		if (this.field.templateOptions?.view === DateHelper.timeUnits.year
			&& !AnyHelper.isNullOrWhitespace(inputDate))
		{
			const inputYear: number = parseInt(
				inputDate,
				AppConstants.parseRadix);

			if (inputYear >= 1000 && inputYear <= 9999)
			{
				const yearDateTime: DateTime =
					DateHelper.startOf(
						DateHelper.fromUtcIso(
							`${inputYear}-06-01T00:00:00.000`),
						DateHelper.timeUnits.year);

				this.displayDate = DateHelper
					.fromUtcSystemIsoToSimulatedLocalDate(
						yearDateTime.toISO());
			}
		}

		const newDateIso: string =
			!AnyHelper.isNull(this.displayDate)
				? DateHelper.fromSimulatedLocalDateToUtcSystemIso(
					DateTime.fromJSDate(
						this.displayDate)
						.toISO())
				: null;

		this.field.formControl.setValue(newDateIso);
		this.validateControl();

		if (!AnyHelper.isNull(this.field.templateOptions.change))
		{
			this.field.templateOptions.change(
				this.field,
				event);
		}
	}

	/**
	 * Resolves the default date string.
	 *
	 * @memberof CustomCalendarComponent
	 */
	private getDefaultDate(): string
	{
		return this.field.templateOptions.useDefaultFunction === true
			? StringHelper.transformToFunction(
				this.field.templateOptions.default,
				this.field.templateOptions.context)()
			: this.field.templateOptions.default;
	}
}