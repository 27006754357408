<div class="ui-g-12 no-padding">
	<ng-template
		[ngIf]="context.source.wizardStepLoading === true || dynamicFormlyLayout == null"
		[ngIfElse]="Content">
		<div class="ui-g-12 no-horizontal-padding text-center">
			<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
			</i>
		</div>
	</ng-template>

	<ng-template #Content>
		<div class="ui-g-12 no-padding">
			<div class="ui-g-12 no-padding dynamic-form-content {{siteLayoutService.contentCssClass}}"
				[ngClass]="{
					'report-selected-dropdown' : (reportContext != null)
				}">
				<app-dynamic-formly
					[dataSet]="context.source.activeMenuItem.currentData"
					[layoutSchema]="dynamicFormlyLayout"
					[context]="context"
					(validityChanged)="validityChanged($event)">
				</app-dynamic-formly>

				<div class="button-set ui-g-12 text-right preview-report-button-wrapper">
					<ng-template
						[ngIf]="reportContext != null">
						<a
							class="text-link theme-color secondary-action-button preview-report-button"
							(click)="previewReport()"
							[ngClass]="{
								'previewing-report-button' : (displayReport === true)
							}">
							Preview Report
						</a>
					</ng-template>
				</div>
			</div>
		</div>

		<ng-template [ngIf]="displayReport === true">
			<div class="ui-g-12 no-padding report-container">
				<app-external-report
					[context]="reportContext">
				</app-external-report>
			</div>
		</ng-template>
	</ng-template>
</div>