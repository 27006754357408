/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiError
} from '@api/errors/api.error';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ErrorHandler,
	Injectable,
	Injector
} from '@angular/core';
import {
	ErrorHelper
} from '@shared/helpers/error.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	HttpErrorResponse
} from '@angular/common/http';
import {
	ILogEntry
} from '@shared/interfaces/logs/log-entry.interface';
import {
	LoggerService
} from '@shared/services/logger.service';
import {
	ReportConstants
} from '@shared/constants/report.constants';
import {
	WindowEventConstants
} from '@shared/constants/window-event.constants';

/**
 * A class representing an instance of the application
 * global error handler.
 *
 * @export
 * @class AppErrorHandler
 * @implements {ErrorHandler}
 */
@Injectable()
export class AppErrorHandler
implements ErrorHandler
{
	/**
	 * Creates an instance of an AppErrorHandler.
	 *
	 * @param {Injector} injector
	 * The injection service used in this component.
	 */
	public constructor(
		private readonly injector: Injector)
	{
		// Window must be used here to ensure this event
		// is captured prior to the element handler.
		window.addEventListener(
			WindowEventConstants.message,
			this.handlePowerBiMessageEvent,
			false);
	}

	/**
	 * Gets or sets the message displayed to the user
	 * for application errors caught in this error handler.
	 *
	 * @type {string}
	 * @memberof AppErrorHandler
	 */
	private readonly userMessage: string = 'Please contact support.';

	/**
	 * Handles errors via the angular routing system for errors
	 * and gives a single point of contact for any error thrown
	 * in our system.
	 *
	 * @async
	 * @param {ApiError | Error | HttpErrorResponse} error
	 * The error being thrown.
	 * @memberof AppErrorHandler
	 */
	public async handleError(
		error: ApiError | Error | HttpErrorResponse): Promise<void>
	{
		console.error(error);

		const logger = this.injector.get(LoggerService);

		try
		{
			const logEntry: ILogEntry =
				await ErrorHelper.getGenericErrorLogEntry(error);

			EventHelper.dispatchBannerEvent(
				AppConstants.messages.genericErrorMessage,
				this.userMessage,
				AppConstants.activityStatus.error,
				error);

			logger.logError(logEntry);
		}
		catch (handlerError)
		{
			try
			{
				const errorMessage =
					`Error handler exception: ${handlerError.message}.`;

				EventHelper.dispatchBannerEvent(
					errorMessage,
					this.userMessage,
					AppConstants.activityStatus.error,
					handlerError);

				logger.logError(errorMessage);
			}
			catch (loggerError)
			{
				console.error(
					'Logger exception in the error handler: '
						+ `${handlerError.message}, ${loggerError.message}.`);
			}
		}
	}

	/**
	 * Handles the message event that can be sent from power bi
	 * to a no longer existing source element. This is found
	 * on page navigation and on rapid redraws on occasion.
	 * @note This cannot be called with Angular's host listener
	 * functionality as this occurs too late in the workflow.
	 * @note As newer versions of PowerBi-Javascript are brought in
	 * we will want to confirm this code is still required.
	 *
	 * @param {MessageEvent} event
	 * The message event sent to the window message event handler.
	 * @memberof AppErrorHandler
	 */
	public handlePowerBiMessageEvent(
		event: MessageEvent): boolean
	{
		if (event.origin === ReportConstants.powerBiEmbedEndpoint
			&& AnyHelper.isNull(event.source))
		{
			event.preventDefault();
			event.stopImmediatePropagation();
			event.stopPropagation();

			console.log(
				'Expected Exception: Rapid PowerBI embeds can create '
					+ 'out of sync messages to a redrawn report.');

			return false;
		}

		return true;
	}
}