/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 * A class representing static constant and read only values
 * for insurance based business logic.
 *
 * @export
 * @class InsuranceConstants
 */
export class InsuranceConstants
{
	/**
	 * Gets or sets the policy term transaction prefix.
	 *
	 * @type {string}
	 * @memberof InsuranceConstants
	 */
	public static readonly policyTermTransactionPrefix: string =
		'PolicyTermTransaction.';

	/**
	 * Gets or sets the available accounting types.
	 *
	 * @type {any}
	 * @memberof InsuranceConstants
	 */
	public static readonly accountingTypes:
	{
		fee: string;
		premium: string;
		tax: string;
	} =
	{
		fee: 'Fee',
		premium: 'Premium',
		tax: 'Tax'
	};

	/**
	 * Gets or sets the set of common properties found in the insurance module.
	 *
	 * @type {any}
	 * @memberof InsuranceConstants
	 */
	public static readonly commonProperties:
	{
		accounting: string;
		basedOnTransactionNumber: string;
		effectiveDate: string;
		policyNumber: string;
		reasons: string;
		transactionNumber: string;
	} =
	{
		accounting: 'accounting',
		basedOnTransactionNumber: 'basedOnTransactionNumber',
		effectiveDate: 'effectiveDate',
		policyNumber: 'policyNumber',
		reasons: 'reasons',
		transactionNumber: 'transactionNumber'
	};

	/**
	 * Gets or sets the set of insurance entity types available in this module.
	 *
	 * @type {any}
	 * @memberof InsuranceConstants
	 */
	public static readonly insuranceEntityTypeGroups:
	{
		ledger: string;
		ledgerTransaction: string;
		policies: string;
		policyTerms: string;
		products: string;
		commissionsSchedules: string;
		users: string;
	} =
	{
		ledger: 'Ledgers',
		ledgerTransaction: 'LedgerTransactions',
		policies: 'Policies',
		policyTerms: 'PolicyTerms',
		products: 'Products',
		commissionsSchedules: 'CommissionsSchedules',
		users: 'Users'
	};

	/**
	 * Gets or sets the available insurance specific layout types.
	 *
	 * @type {any}
	 * @memberof InsuranceConstants
	 */
	public static readonly layoutTypes:
	{
		cancelSummary: string;
	} = {
		cancelSummary: 'CancelSummary'
	};

	/**
	 * Gets or sets the available ledger transaction categories.
	 *
	 * @type {any}
	 * @memberof InsuranceConstants
	 */
	public static readonly ledgerTransactionCategories:
	{
		transfer: string;
	} =
	{
		transfer: 'Transfer'
	};

	/**
	 * Gets or sets the available ledger transaction adjustment types.
	 *
	 * @type {any}
	 * @memberof InsuranceConstants
	 */
	public static readonly ledgerTransactionAdjustmentTypes:
	{
		refund: string;
		reversal: string;
		writeOff: string;
	} =
	{
		refund: 'Refund',
		reversal: 'Reversal',
		writeOff: 'WriteOff'
	};

	/**
	 * Gets or sets the available ledger transaction types.
	 *
	 * @type {any}
	 * @memberof InsuranceConstants
	 */
	public static readonly ledgerTransactionTypes:
	{
		fee: string;
		payment: string;
		premium: string;
		tax: string;
	} =
	{
		fee: 'Fee',
		payment: 'Payment',
		premium: 'Premium',
		tax: 'Tax'
	};

	/**
	 * Gets or sets the available policy status types.
	 *
	 * @type {any}
	 * @memberof InsuranceConstants
	 */
	public static readonly policyStatusTypes:
	{
		active: string;
		inactive: string;
		pending: string;
	} =
	{
		active: 'Active',
		inactive: 'Inactive',
		pending: 'Pending'
	};

	/**
	 * Gets or sets the available transaction status types.
	 *
	 * @type {any}
	 * @memberof InsuranceConstants
	 */
	public static readonly policyTermStatusTypes:
	{
		active: string;
		cancelled: string;
		pending: string;
	} =
	{
		active: 'Active',
		cancelled: 'Cancelled',
		pending: 'Pending'
	};

	/**
	 * Gets or sets the set of insurance service provider types.
	 *
	 * @type {any}
	 * @memberof InsuranceConstants
	 */
	public static readonly serviceProviderTypes:
	{
		agency: string;
		producer: string;
	} =
	{
		agency: 'Agency',
		producer: 'Producer'
	};

	/**
	 * Gets or sets the set of insurance service provider entity type groups.
	 *
	 * @type {any}
	 * @memberof InsuranceConstants
	 */
	public static readonly serviceProviderEntityTypeGroups:
	{
		agency: string;
		producer: string;
	} =
	{
		agency: 'Organization.Agencies',
		producer: 'Users'
	};

	/**
	 * Gets or sets the available product status reason types.
	 *
	 * @type {any}
	 * @memberof InsuranceConstants
	 */
	public static readonly statusReasonTypes:
	{
		endorsement: string;
		rejection: string;
	} =
	{
		endorsement: 'Endorsement',
		rejection: 'Rejection',
	};

	/**
	 * Gets or sets the available cancel reason types.
	 *
	 * @type {any}
	 * @memberof InsuranceConstants
	 */
	public static readonly cancelReasonTypes:
	{
		underwriting: string;
		nonPayment: string;
		insuredRequest: string;
	} =
	{
		underwriting: 'Underwriting',
		nonPayment: 'NonPayment',
		insuredRequest: 'InsuredRequest'
	};

	/**
	 * Gets or sets the available transaction status types.
	 *
	 * @type {any}
	 * @memberof InsuranceConstants
	 */
	public static readonly transactionStatusTypes:
	{
		application: string;
		archived: string;
		declined: string;
		issued: string;
		obsolete: string;
		pending: string;
		submitted: string;
	} =
	{
		application: 'Application',
		archived: 'Archived',
		declined: 'Declined',
		issued: 'Issued',
		obsolete: 'Obsolete',
		pending: 'Pending',
		submitted: 'Submitted'
	};

	/**
	 * Gets or sets the available transaction types.
	 *
	 * @type {any}
	 * @memberof InsuranceConstants
	 */
	public static readonly transactionTypes:
	{
		newBusiness: string;
		endorsement: string;
		cancellation: string;
		renewal: string;
		reinstatement: string;
	} =
	{
		newBusiness: 'NewBusiness',
		endorsement: 'Endorsement',
		cancellation: 'Cancellation',
		renewal: 'Renewal',
		reinstatement: 'Reinstatement'
	};

	/**
	 * Gets or sets the available insurance specific workflow actions.
	 *
	 * @type {any}
	 * @memberof InsuranceConstants
	 */
	public static readonly workflowActions:
	{
		policyNonRenew: string;
		transactionEndorse: string;
		termCancelReinstate: string;
		termRescindCancel: string;
		termRescindEndorse: string;
		transactionDecline: string;
	} =
	{
		policyNonRenew: 'PolicyNonRenew',
		transactionEndorse: 'TransactionEndorse',
		termCancelReinstate: 'TermCancelReinstate',
		termRescindCancel: 'TermRescindCancel',
		termRescindEndorse: 'TermRescindEndorse',
		transactionDecline: 'TransactionDecline'
	};

	/**
	 * Gets the declinable transaction types.
	 *
	 * @type {string[]}
	 * @memberof InsuranceConstants
	 */
	public static readonly declinableTransactionTypes: string[] =
	[
		this.transactionTypes.endorsement,
		this.transactionTypes.newBusiness
	];

	/**
	 * Gets the approvable transaction types.
	 *
	 * @type {string[]}
	 * @memberof InsuranceConstants
	 */
	public static readonly approvableTransactionTypes: string[] =
	[	this.transactionTypes.endorsement,
		this.transactionTypes.newBusiness
	];

	/**
	 * Gets or sets the available insurance specific query parameters.
	 *
	 * @type {any}
	 * @memberof InsuranceConstants
	 */
	public static readonly queryParameters:
	{
		paymentPlanId: string;
		paymentPlanResourceId: string;
			updateTermOnly: string;
		invoiceTo: string;
		invoicingMethod: string;
		enableAutoPayments: string;
		electronicDocuments: string;
		targetDate: string;
		rebase: string;
	} =
	{
		paymentPlanId: 'paymentPlanId',
		paymentPlanResourceId: 'paymentPlanResourceId',
		updateTermOnly: 'updateTermOnly',
		invoiceTo: 'invoiceTo',
		invoicingMethod: 'invoicingMethod',
		enableAutoPayments: 'enableAutoPayments',
		electronicDocuments: 'electronicDocuments',
		targetDate: 'targetDate',
		rebase: 'rebase'
	};

	/**
	 * Enumarates the interest types.
	 *
	 * @static
	 * @type {any}
	 * @memberof InsuranceConstants
	 */
	public static readonly interestTypes:
	{
		namedInsured: string;
	} =
	{
		namedInsured: 'NamedInsured'
	};

	/**
	 * Enumarates the interest sub-types.
	 *
	 * @static
	 * @type {any}
	 * @memberof InsuranceConstants
	 */
	public static readonly interestSubTypes:
	{
		primary: string;
	} =
	{
		primary: 'Primary'
	};
}