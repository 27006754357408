<app-base-page pageTitle="{{dashboardTitle}}"
	[pageContext]="pageContext"
	[reserveHeaderBottomRight]="reserveHeaderBottomRight"
	utilityMenuOperationGroupName="">

	<div class="entity-header"
		#HeaderContextContent
		header-context-content
		*ngIf="!headerContextContent?.loading && headerContextContent?.sectionItems != null && headerContextContent?.sectionItems.length > 1">
		<div class="multi-level-tab-shadow ui-g-12 no-padding"
			*ngIf="headerContextContent?.tabItems != null && headerContextContent?.tabItems.length > 1 && headerContextContent?.displayNavigationDropdown === false">
		</div>
		<div [ngClass]="{
				'shortened-dropdown-navigation': (headerContextContent?.displayParameterFilter === true
					&& headerContextContent?.displayNavigationDropdown === true
					&& siteLayoutService.contentWidth <= twoColumnHeaderBreakpoint),
				'tab-navigation': (headerContextContent?.displayParameterFilter === true
					&& headerContextContent?.displayNavigationDropdown === false)
			}"
			[@contentAnimation]="state">
			<ng-template [ngIf]="headerContextContent?.displayNavigationDropdown === true"
				[ngIfElse]="BasePageTabMenuContainer">
				<div class="button-menu-navigation ui-g-12 no-padding"
					[ngClass]="{
						'ui-lg-6': (headerContextContent?.displayParameterFilter === true
							&& siteLayoutService.contentWidth > twoColumnHeaderBreakpoint)
					}">
					<operation-button-menu
						[operationGroup]="headerContextContent?.buttonMenuNavigationGroup">
					</operation-button-menu>
				</div>
			</ng-template>
			<ng-template #BasePageTabMenuContainer>
				<div #BasePageTabMenu
					class="tabmenu-container ui-g-12 no-padding"
					[ngClass]="{
						'ui-lg-6': (headerContextContent?.displayParameterFilter === true
							&& siteLayoutService.contentWidth > twoColumnHeaderBreakpoint)
					}">
					<app-base-page-tab-menu
						[sectionItems]="headerContextContent?.sectionItems"
						[tabItems]="headerContextContent?.tabItems"
						[activeSectionItem]="headerContextContent?.activeSectionItem"
						[activeTabItem]="headerContextContent?.activeTabItem"
						(tabSelectedEvent)="tabSelected($event)">
					</app-base-page-tab-menu>
				</div>
			</ng-template>
		</div>
		<div class="base-page-filter-container"
			[ngClass]="{
				'slim-page-header': (headerContextContent?.sectionItems == null || headerContextContent?.sectionItems.length <= 1),
				'shortened-filter': (headerContextContent?.displayNavigationDropdown === true
					&& siteLayoutService.displayTabletView === false)
			}"
			*ngIf="headerContextContent?.loading === false">
			<app-display-component-parameter-header
				[parameterLayoutData]="headerContextContent?.parameterLayoutData"
				[parameterLayoutSchema]="headerContextContent?.parameterLayoutSchema"
				[displayParameterOption]="headerContextContent?.displayParameterFilter"
				[displaySecondaryOption]="false"
				[displayEditOption]="false"
				[display]="headerContextContent?.dashboardDisplayComplete"
				[displayingSettings]="settingsActive"
				(displaySettingsClick)="settingsClicked()">
			</app-display-component-parameter-header>

			<div class="header-parameter-container"
				[ngClass]="{
					'display-none': settingsActive === false,
					'offset-filter-panel': (headerContextContent?.sectionItems != null && headerContextContent?.sectionItems.length > 1)
				}">
				<app-display-component-parameter
					[displayComponentInstance]="headerContextContent?.displayComponentInstance"
					[parameterLayoutData]="headerContextContent?.parameterLayoutData"
					[parameterLayoutSchema]="headerContextContent?.parameterLayoutSchema"
					[pageContext]="pageContext"
					[settingsActive]="settingsActive"
					[pageHeaderParameters]="true"
					(appliedParameters)="applyParameters()"
					(cancelClicked)="cancelClicked()">
				</app-display-component-parameter>
			</div>
		</div>
	</div>

	<app-common-dashboard
		[dashboardDisplayComponentInstanceName] = "dashboardDisplayComponentInstanceName"
		[routeParameters]="routeParameters"
		[fullPageDashboard]="true"
		(headerContextContent)="setHeaderContextContent($event)"
		(updateRouteData)="updateRouteData()">
	</app-common-dashboard>

</app-base-page>