/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IInsurancePaymentSummary
} from '@insurance/interfaces/insurance-payment-summary.interface';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	InsuranceHelper
} from '@insurance/helpers/insurance.helper';
import {
	InsuranceService
} from '@insurance/services/insurance.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-next-payment-installment-details',
	templateUrl: './next-payment-installment-details.component.html',
	styleUrls: [
		'./next-payment-installment-details.component.scss']
})

/**
 * A component representing a next payment installment details componet.
 *
 * @export
 * @class NextPaymentInstallmentDetailsComponent
 * @implements {IDynamicComponent<Component, any>}
 * @implements {OnInit}
 */
export class NextPaymentInstallmentDetailsComponent
implements IDynamicComponent<Component, any>, OnInit
{
	/**
	 * Creates an instance of an the next payment installment details
	 * component.
	 *
	 * @param {InsuranceService} insuranceService
	 * The insurance service for this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service for this component.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service for this component.
	 * @memberof NextPaymentInstallmentDetailsComponent
	 */
	public constructor(
		public insuranceService: InsuranceService,
		public entityInstanceApiService: EntityInstanceApiService,
		public siteLayoutService: SiteLayoutService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof NextPaymentInstallmentDetailsComponent
	 */
	@Input() public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the value signifying whether or not this component is
	 * loading.
	 *
	 * @type {boolean}
	 * @memberof NextPaymentInstallmentDetailsComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets the next payment that will be displayed.
	 *
	 * @type {any}
	 * @memberof NextPaymentInstallmentDetailsComponent
	 */
	public nextPayment: any;

	/**
	 * Gets or sets the operational fees for this payment that have
	 * a refernce id.
	 *
	 * @type {number}
	 * @memberof NextPaymentInstallmentDetailsComponent
	 */
	public invoicedOperationalFees: number = 0;

	/**
	 * Gets or sets the sum of operational fees for this payment that have
	 * a refernce id.
	 *
	 * @type {number}
	 * @memberof NextPaymentInstallmentDetailsComponent
	 */
	public summedOperationalFees: number = 0;

	/**
	 * Gets or sets the total transfer balance for this payment schedule
	 * and payment.
	 *
	 * @type {number}
	 * @memberof NextPaymentInstallmentDetailsComponent
	 */
	public totalTransferBalance: number = 0;

	/**
	 * Gets or sets the transfer balance from the previous term.
	 *
	 * @type {number}
	 * @memberof NextPaymentInstallmentDetailsComponent
	 */
	public transferBalance: number = 0;

	/**
	 * Gets or sets the sum of ledger payments for this payment schedule.
	 *
	 * @type {number}
	 * @memberof NextPaymentInstallmentDetailsComponent
	 */
	public summedLedgerPayments: number = 0;

	/**
	 * Gets or sets the sum of adjustments for this payment schedule.
	 *
	 * @type {number}
	 * @memberof NextPaymentInstallmentDetailsComponent
	 */
	public summedAdjustments: number = 0;

	/**
	 * Gets or sets the sum of other credits for this payment schedule.
	 *
	 * @type {number}
	 * @memberof NextPaymentInstallmentDetailsComponent
	 */
	public summedOtherCredits: number = 0;

	/**
	 * Gets or sets the remaining balance of payments for this payment schedule.
	 *
	 * @type {number}
	 * @memberof NextPaymentInstallmentDetailsComponent
	 */
	public remainingBalance: number = 0;

	/**
	 * Initiates the component to get the data object.
	 *
	 * @async
	 * @memberof NextPaymentInstallmentDetailsComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		const entityInstanceId: number =
			(<any>this.context.source)
				.entityContext?.source.entityInstance.id
				?? (<any>this.context.source)
					.activeMenuItem.currentData.data.id;
		const entityTypeGroup: string =
			(<any>this.context.source)
				.entityContext?.source.entityTypeGroup
				?? InsuranceConstants.insuranceEntityTypeGroups.policyTerms;

		let policyTerm: IEntityInstance;
		let ledger: IEntityInstance;

		if (entityTypeGroup !==
			InsuranceConstants.insuranceEntityTypeGroups.policyTerms)
		{
			policyTerm =
				await this.insuranceService.getPolicyTermByTransaction(
					entityInstanceId,
					entityTypeGroup);
			ledger =
				await this.insuranceService.getLedger(
					policyTerm.id);
		}
		else
		{
			this.entityInstanceApiService.entityInstanceTypeGroup =
				entityTypeGroup;
			policyTerm =
				await this.entityInstanceApiService.get(
					entityInstanceId);
			ledger =
				await this.insuranceService.getLedger(
					entityInstanceId);
		}

		const policyTermTransaction: IEntityInstance =
			await this.insuranceService
				.getLatestPolicyTermTransactionByPolicyTerm(
					policyTerm.id);
		const ledgerTransactions: IEntityInstance[] =
			await this.insuranceService.getLedgerTransactions(
				ledger.id);

		const futureIssuedTransactions: IEntityInstance[] =
				await this.insuranceService.getFutureIssuedTransactions(
					policyTerm);
		const wizardPayments: any[] =
			(<any>this.context.source)
				.activeMenuItem?.currentData.data.generatedPayments
				?? (<any>this.context.source)
					.activeMenuItem?.currentData.data
					.policyTerm.data.accounting.paymentSchedule.payments;

		// If we are past bind, only use the draft payment schedule when
		// future issued transactions exist.
		let accountingPayments: any[] =
			(<any>this.context.source).entityContext
				?.source.entityInstance
				?.data.accounting?.paymentSchedule?.payments;

		if (futureIssuedTransactions.length > 0
			&& !AnyHelper.isNull(
				accountingPayments))
		{
			const actionResponse: any =
				await this.insuranceService.generateDraftPaymentPlanSchedule(
					policyTerm.id,
					InsuranceConstants.insuranceEntityTypeGroups.policyTerms);

			accountingPayments =
				actionResponse?.body?.value.payments;
		}

		const remainingPayments: IInsurancePaymentSummary[] =
			await this.insuranceService.getRemainingPaymentSummaries(
				entityInstanceId,
				entityTypeGroup,
				accountingPayments
					?? wizardPayments,
				!AnyHelper.isNull(accountingPayments));
		this.nextPayment =
			await this.insuranceService.getNextInstallmentPayment(
				entityInstanceId,
				entityTypeGroup,
				remainingPayments,
				false);

		this.invoicedOperationalFees =
			this.nextPayment?.operationalFees
				.filter((operationalFee: any) =>
					!AnyHelper.isNullOrWhitespace(
						operationalFee.referenceId)) ?? [];
		this.summedOperationalFees =
			this.nextPayment?.operationalFees
				.reduce(
					(sum: number,
						operationalFee: any) =>
						!AnyHelper.isNullOrWhitespace(
							operationalFee.referenceId)
							? sum + operationalFee.amount
							: sum,
					0) ?? 0;

		this.transferBalance =
			(InsuranceHelper.getPriorTermTransferBalance(
				ledgerTransactions) ?? 0);
		const previousBalance: number =
			Math.max(
				this.nextPayment?.paymentBalanceForward ?? 0,
				this.nextPayment?.realizedPaymentBalanceForward ?? 0);

		this.totalTransferBalance =
			this.transferBalance + previousBalance;
		this.summedAdjustments =
			InsuranceHelper.getPolicyTermWrittenOffBalance(
				ledgerTransactions);
		this.summedLedgerPayments =
			InsuranceHelper.getPaymentsBalance(
				ledgerTransactions);

		this.summedOtherCredits = 0;

		const accountingTotal: number =
			policyTerm.data.status ===
				InsuranceConstants.policyTermStatusTypes.active
				? policyTerm.data.accounting.writtenTotal
				: policyTermTransaction.data.accounting.directWrittenTotal;
		this.remainingBalance =
			policyTerm.data.status ===
				InsuranceConstants.policyTermStatusTypes.active
				? ledger.data.balance
				: accountingTotal
					+ this.transferBalance
					+ this.summedAdjustments
					+ this.summedLedgerPayments;

		this.loading = false;
		(<any>this.context.source).finishedLoading?.emit();
	}
}
