/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 * A class representing static constant and read only values
 * for shared type based business logic.
 *
 * @export
 * @class SharedTypeConstants
 */
export class SharedTypeConstants
{
	/**
	 * Gets or sets the address types.
	 *
	 * @type {string[]}
	 * @memberof SharedTypeConstants
	 */
	public static readonly addressTypes: string[] =
		[
			'Mailing',
			'Physical',
			'Employer'
		];

	/**
	 * Gets the address type.
	 *
	 * @type {object}
	 * @memberof SharedTypeConstants
	 */
	public static readonly addressType:
	{
		employer: string;
		mailing: string;
		physical: string;
	} = {
		employer: 'Employer',
		mailing: 'Mailing',
		physical: 'Physical'
	};

	/**
	 * Gets or sets the email types.
	 *
	 * @type {string[]}
	 * @memberof SharedTypeConstants
	 */
	public static readonly emailTypes: string[] =
		[
			'Personal',
			'Work'
		];

	/**
	 * Gets or sets the individual types.
	 *
	 * @type {string[]}
	 * @memberof SharedTypeConstants
	 */
	public static readonly individualTypes: string[] =
		[
			'Individual'
		];

	/**
	 * Gets or sets the organization types.
	 *
	 * @type {string[]}
	 * @memberof SharedTypeConstants
	 */
	public static readonly organizationTypes: string[] =
		[
			'Organization'
		];

	/**
	 * Gets or sets the phone types.
	 *
	 * @type {string[]}
	 * @memberof SharedTypeConstants
	 */
	public static readonly phoneTypes: string[] =
		[
			'Mobile',
			'Home',
			'Work',
			'Fax'
		];

	/**
	 * Gets or sets the tax information types.
	 *
	 * @type {string[]}
	 * @memberof SharedTypeConstants
	 */
	public static readonly taxInformationTypes: string[] =
		[
			'SocialSecurityNumber',
			'FederalEmployerIdentificationNumber'
		];
}