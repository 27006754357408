<div class="quick-filters"
	clickOutside
	(clickOutside)="closeList()"
	[contentDisplayed]="filterListActive">

	<a class="quick-filter-button"
		(click)="toggleFilterListDisplay()">
		<i class="double-font-size fa fa-filter"
			[ngClass]="{
				'border-selected-theme': filterListActive,
				'active-icon-color': filterListActive
			}">
		</i>
	</a>

	<div class="quick-filter-list"
		[@easeAnimation]="filterListActive ? 'visible' : 'hidden'"
		[@displayAnimation]="filterListActive ? 'visible' : 'hidden'">
		<label class="visually-hidden"
			for="selectQuickFiltersControl">
			Select Quick Filters
		</label>
		<p-listbox
			inputId="selectQuickFiltersControl"
			[options]="filters"
			[(ngModel)]="selectedFilterValue"
			(onChange)="quickFilterSelected()">
		</p-listbox>
	</div>
</div>