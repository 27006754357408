/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ChangeDetectorRef,
	Component
} from '@angular/core';
import {
	CustomActionMenuDirective
} from '@entity/directives/custom-action-menu.directive';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	state,
	style,
	trigger
} from '@angular/animations';

@Component({
	selector: 'custom-input',
	templateUrl: './custom-input.component.html',
	animations: [
		trigger('displayAnimation', [
			state('hidden', style({
				display: 'none'
			})),
			state('visible', style({
				display: 'block'
			}))
		])
	]
})

/**
 * A component representing an instance of a Custom Input.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomInputComponent
 * @extends {CustomActionMenuDirective}
 */
export class CustomInputComponent
	extends CustomActionMenuDirective
{
	/** Initializes a new instance of the CustomInputComponent.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service.
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof CustomInputComponent
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService,
		public changeDetector: ChangeDetectorRef)
	{
		super(siteLayoutService,
			changeDetector);
	}
}