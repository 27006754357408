/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ActivatedRoute,
	Router
} from '@angular/router';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	Component,
	OnInit
} from '@angular/core';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityLayoutApiService
} from '@api/services/entities/entity-layout.api.service';
import {
	EntityLayoutTypeApiService
} from '@api/services/entities/entity-layout-type.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EntityVersionApiService
} from '@api/services/entities/entity-version.api.service';
import {
	FormControl
} from '@angular/forms';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityDefinition
} from '@shared/interfaces/entities/entity-definition.interface';
import {
	IEntityLayout
} from '@shared/interfaces/entities/entity-layout.interface';
import {
	IEntityLayoutType
} from '@shared/interfaces/entities/entity-layout-type.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IEntityVersion
} from '@shared/interfaces/entities/entity-version.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	TableHelper
} from '@shared/helpers/table.helper';

/* eslint-enable max-len */

@Component({
	selector: 'app-entity-layouts',
	templateUrl: './entity-layouts.component.html'
})

/**
 * A component representing an instance of the system entity layouts
 * component.
 *
 * @export
 * @class EntityLayoutsComponent
 * @extends {CommonTablePageDirective}
 * @implements {OnInit}
 */
export class EntityLayoutsComponent
	extends CommonTablePageDirective
	implements OnInit
{
	/**
	 * Creates an instance of an EntityLayoutsComponent.
	 *
	 * @param {EntityLayoutApiService} entityLayoutApiService
	 * The api service used to get the entity layout data.
	 * @param {EntityLayoutTypeApiService} entityLayoutTypeApiService
	 * The api service used to get the entity layout type data.
	 * @param {EntityDefinitionApiService} entityDefinitionApiService
	 * The entity instance service used to populate the entity definition data.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to get the entity type data.
	 * @param {EntityVersionApiService} entityVersionApiService
	 * The entity instance service used to populate the entity version data.
	 * @param {ActivatedRoute} route
	 * The activated route that opened this component.
	 * @param {Router} router
	 * The router service.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof EntityLayoutsComponent
	 */
	public constructor(
		public entityLayoutApiService: EntityLayoutApiService,
		public entityLayoutTypeApiService: EntityLayoutTypeApiService,
		public entityDefinitionApiService: EntityDefinitionApiService,
		public entityTypeApiService: EntityTypeApiService,
		public entityVersionApiService: EntityVersionApiService,
		public route: ActivatedRoute,
		public router: Router,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the entity definition.
	 *
	 * @type {IEntityDefinition}
	 * @memberof EntityLayoutsComponent
	 */
	public entityDefinition: IEntityDefinition;

	/**
	 * Gets or sets the entity layout types.
	 *
	 * @type {IEntityLayoutType[]}
	 * @memberof EntityLayoutsComponent
	 */
	public entityLayoutTypes: IEntityLayoutType[];

	/**
	 * Gets or sets the entity type.
	 *
	 * @type {IEntityType}
	 * @memberof EntityLayoutsComponent
	 */
	public entityType: IEntityType;

	/**
	 * Gets or sets the entity version.
	 *
	 * @type {IEntityVersion}
	 * @memberof EntityLayoutsComponent
	 */
	public entityVersion: IEntityVersion;

	/**
	 * Gets or sets the table definitions.
	 *
	 * @type {ICommonTable}
	 * @memberof EntityLayoutsComponent
	 */
	public tableDefinitions: ICommonTable;

	/**
	 * Gets or sets the layout type name
	 * dropdown options.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof EntityLayoutsComponent
	 */
	public layoutTypeNameOptions: IDropdownOption[];

	/**
	 * Gets or sets the entity definition id.
	 *
	 * @type {number}
	 * @memberof EntityLayoutsComponent
	 */
	public entityDefinitionId: number;

	/**
	 * Initializes the component to set the page variables
	 * and setup the table definitions.
	 *
	 * @async
	 * @memberof EntityLayoutsComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		await this.setupPageVariables();
		this.setupTableDefinitions();
	}

	/**
	 * Sets the page variables needed for this component.
	 *
	 * @async
	 * @memberof EntityLayoutsComponent
	 */
	public async setupPageVariables(): Promise<void>
	{
		this.entityDefinitionId = this.route.snapshot.paramMap.get(
			AppConstants.commonProperties.id) as unknown as number;

		this.entityDefinition =
			await this.entityDefinitionApiService
				.get(this.entityDefinitionId);

		this.entityType =
			await this.entityTypeApiService
				.get(this.entityDefinition.typeId);

		this.entityVersion =
			await this.entityVersionApiService
				.get(this.entityDefinition.versionId);

		this.tableFilterQuery = `${AppConstants.commonProperties.typeId}`
			+ ` eq ${this.entityDefinition.typeId}`
			+ ` and ${AppConstants.commonProperties.versionId}`
			+ ` eq ${this.entityDefinition.versionId}`;

		this.entityLayoutTypes =
			await this.entityLayoutTypeApiService
				.query(
					AppConstants.empty,
					AppConstants.empty);

		this.availableColumns =
			[
				{
					dataKey: 'layoutTypeName',
					columnHeader: 'Layout Type',
					displayOrder: 1
				}
			];
		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Sets the common table definitions needed for this component.
	 *
	 * @async
	 * @memberof EntityLayoutsComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		this.tableDefinitions = {
			hideExpanderArrow: true,
			actions: {
				create: {
					displayCreateRow: false,
					layout: [
						{
							key: 'data.layoutTypeId',
							type: FormlyConstants.customControls.customSelect,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Layout Type',
								disabled: false,
								required: true,
								showClear: true,
								placeholder: AppConstants.placeholders
									.selectAnOption,
								options: this.entityLayoutTypes
									?.map((type:
									IEntityType) =>
									<IDropdownOption>
											{
												label: type.name,
												value: type.id
											})
									.sort((
										optionOne: IDropdownOption,
										optionTwo: IDropdownOption) =>
										ObjectHelper.sortByPropertyValue(
											optionOne,
											optionTwo,
											'label')),
							},
							asyncValidators: {
								uniqueLayoutType: {
									expression: (
										control: FormControl) =>
										this.uniqueLayoutType(control),
									message: 'Existing Layout Type Name.'
								}
							}
						}
					],
					items: [
						{
							label: 'Create',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: () => this.createLayout()
						}]
				},
				view: {
					disabledExpandRow: true,
					items: []
				},
				update: {
					disabledExpandRow: true,
					items: [
						{
							command: () =>
							{
								const layoutId: string =
									this.commonTableContext.data.id;
								this.router.navigate(
									[
										'admin/entity/layoutDefinition/edit',
										this.entityDefinitionId
									],
									{
										queryParams:
											{
												routeData:
													ObjectHelper.mapRouteData(
														{
															layoutId:
																layoutId
														})
											}
									});
							}
						}
					]
				},
				delete: {
					items: [
						{
							label: 'Confirm',
							styleClass: AppConstants.cssClasses.pButtonDanger,
							command: async() => this.deleteLayout(),
						}],
					deleteStatement: () => this.deleteStatement(),
				}
			},
			tableTitle: null,
			objectSearch: {
				filter: this.tableFilterQuery,
				orderBy: `Id ${AppConstants.sortDirections.descending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: this.tableRowCount
			},
			apiPromise: async (objectSearch: IObjectSearch) =>
			{
				const dataPromise: object[] = [];

				const entityLayout: IEntityLayout[] =
					await this.entityLayoutApiService
						.query(
							objectSearch.filter,
							objectSearch.orderBy,
							objectSearch.offset,
							objectSearch.limit);

				for (const layout of entityLayout)
				{
					for (const layoutType of this.entityLayoutTypes)
					{
						if (layoutType.id === layout.layoutTypeId)
						{
							dataPromise.push(
								{
									id: layout.id,
									layoutTypeName:
										layoutType.name,
									layoutTypeId:
										layoutType.id
								});
						}
					}
				}

				return dataPromise.sort((
					layoutTypeOne: IDropdownOption,
					layoutTypeTwo: IDropdownOption) =>
					ObjectHelper.sortByPropertyValue(
						layoutTypeOne,
						layoutTypeTwo,
						'layoutTypeName'));
			},
			availableColumns: this.availableColumns,
			selectedColumns: this.selectedColumns,
			columnSelectionMode: this.columnSelectionMode,
			expandTitle: () =>
				TableHelper.getExpandTitle(
					this.commonTableContext,
					'Entity Layout'),
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			filterCriteriaChanged: (filterCriteria: string) =>
			{
				this.tableFilterQuery = filterCriteria;
				this.restoreTableDefinition();
			},
			rowCountChanged: (rowCount: number) =>
			{
				this.tableRowCount = rowCount;
				this.restoreTableDefinition();
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.tableDefinitions.selectedColumns =
					selectedColumns;
				this.selectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.tableDefinitions.columnSelectionMode =
					columnSelectionMode;
				this.columnSelectionMode = columnSelectionMode;
			}
		};

		this.loadingTableDefinitions = false;
	}

	/**
	 * creates a new entity layout.
	 *
	 * @async
	 * @memberof EntityLayoutsComponent
	 */
	private async createLayout(): Promise<void>
	{
		const createLayoutAction: Function = async() =>
		{
			const newEntityLayout: IEntityLayout =
				<IEntityLayout>
				{
					typeId: this.entityType.id,
					layoutTypeId: this.commonTableContext.data.layoutTypeId,
					versionId: this.entityVersion.id,
					jsonData: AppConstants.emptyArray
				};

			const newEntityLayoutId: number =
				await this.entityLayoutApiService
					.create(newEntityLayout);

			this.router.navigate(
				[
					'admin/entity/layoutDefinition/edit',
					this.entityDefinitionId
				],
				{
					queryParams:
						{
							routeData:
								ObjectHelper.mapRouteData(
									{
										layoutId:
											newEntityLayoutId
									})
						}
				});
		};

		await this.activityService.handleActivity(
			new Activity(
				createLayoutAction(),
				'<strong>Creating Layout</strong>',
				'<strong>Created Layout</strong>',
				`Layout Type Id ${this.commonTableContext.data.layoutTypeId}`
					+ ' was added.',
				`Layout Type Id ${this.commonTableContext.data.layoutTypeId}`
					+ ' was not added.'));
	}

	/**
	 * Gets the common table delete statement.
	 *
	 * @async
	 * @returns {string}
	 * The delete statement
	 * @memberof EntityLayoutsComponent
	 */
	private deleteStatement(): string
	{
		return 'Confirm to delete Layout Type'
			+ ` ${this.commonTableContext.data.layoutTypeName}`
			+ ` for ${this.entityType.name}`;
	}

	/**
	 * Deletes an existing entity layout.
	 *
	 * @async
	 * @memberof EntityLayoutsComponent
	 */
	private async deleteLayout(): Promise<void>
	{
		await this.activityService.handleActivity(
			new Activity(
				this.entityLayoutApiService
					.delete(this.commonTableContext.data.id),
				'<strong>Deleting Layout</strong>',
				'<strong>Deleted Layout</strong>',
				`Layout Type ${this.commonTableContext.data.layoutTypeName}`
					+ ' was deleted.',
				`Layout Type ${this.commonTableContext.data.layoutTypeName}`
					+ ' was not deleted.'));

		this.restoreTableDefinition();
	}

	/**
	 * Validates the layout type is unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The Form Control used to get the input value
	 * @returns {Promise<boolean>}
	 * The Validator promise result.
	 * @memberof EntityLayoutsComponent
	 */
	private async uniqueLayoutType(control: FormControl): Promise<boolean>
	{
		const existingLayout: IEntityLayout[] =
			await this.entityLayoutApiService
				.query(
					`${AppConstants.commonProperties.typeId}`
						+ ` eq ${this.entityDefinition.typeId}`
						+ ` and ${AppConstants.commonProperties.versionId}`
						+ ` eq ${this.entityDefinition.versionId}`
						+ ` and ${AppConstants.commonProperties.layoutTypeId}`
						+ ` eq ${control.value}`,
					AppConstants.empty);

		return Promise.resolve(existingLayout.length === 0);
	}
}