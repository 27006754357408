/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ActivatedRoute,
	ActivatedRouteSnapshot,
	Params,
	Router
} from '@angular/router';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	OnInit
} from '@angular/core';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	Location
} from '@angular/common';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	SessionService
} from '@shared/services/session.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

@Component({
	selector: 'app-access-denied',
	templateUrl: './access-denied.component.html',
	styleUrls: [
		'./access-denied.component.scss'
	],
	animations: [
		ContentAnimation
	]
})

/**
 * A component representing an instance of an access denied page.
 *
 * @export
 * @class AccessDeniedComponent
 * @implements {OnInit}
 */
export class AccessDeniedComponent
implements OnInit
{
	/**
	 * Creates an instance of an AccessDeniedComponent.
	 *
	 * @param {ActivatedRoute} route
	 * The route used in this component.
	 * @param {Router} router
	 * The router used in this component.
	 * @param {Location} location
	 * The router used in this component.
	 * @param {SessionService} sessionService
	 * The session service used in this component.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this component.
	 * @memberof AccessDeniedComponent
	 */
	public constructor(
		public route: ActivatedRoute,
		public router: Router,
		public location: Location,
		public sessionService: SessionService,
		public siteLayoutService: SiteLayoutService)
	{
		this.existingRouteReuseStrategy =
			this.router.routeReuseStrategy.shouldReuseRoute;
		this.router.routeReuseStrategy.shouldReuseRoute =
			(_future: ActivatedRouteSnapshot,
				_curr: ActivatedRouteSnapshot): boolean =>
				false;
	}

	/**
	 * Gets or sets the loading value.
	 *
	 * @type {boolean}
	 * @memberof AccessDeniedComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets the session identifier.
	 *
	 * @type {string}
	 * @memberof AccessDeniedComponent
	 */
	public sessionIdentifier: string = AppConstants.empty;

	/**
	 * Gets or sets the loading value.
	 *
	 * @type {string}
	 * @memberof AccessDeniedComponent
	 */
	public accessDeniedUrl: string = AppConstants.empty;

	/**
	 * Gets or sets the loading value.
	 *
	 * @type {string[]}
	 * @memberof AccessDeniedComponent
	 */
	public resources: string[] = [];

	/**
	 * Gets or sets the loading value.
	 *
	 * @type {string}
	 * @memberof AccessDeniedComponent
	 */
	public clientMessage: string =
		AppConstants.messages.pageAccessNotAllowed;

	/**
	 * Gets or sets the parameter identifiers.
	 *
	 * @type {{
	 * 	accessDeniedUrlQueryParameter: string;
	 * 	resourcesQueryParameter: string;
	 * 	clientMessageQueryParameter: string;
	 * }}
	 * @memberof AccessDeniedComponent
	 */
	public queryParameters: {
		accessDeniedUrlQueryParameter: string;
		resourcesQueryParameter: string;
		clientMessageQueryParameter: string;
	} = {
		accessDeniedUrlQueryParameter: 'route',
		resourcesQueryParameter: 'resources',
		clientMessageQueryParameter: 'clientMessage',
	};

	/**
	 * Gets the route reuse strategy for the router on initial
	 * load. This is used to reset the route reuse strategy to it's
	 * original value on destroy, but force a component refresh on route changes
	 * to this component.
	 *
	 * @type {(
	 * 	future: ActivatedRouteSnapshot,
	 * 	curr: ActivatedRouteSnapshot) => boolean}
	 * @memberof AccessDeniedComponent
	 */
	private readonly existingRouteReuseStrategy:
		(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot) =>
			boolean;

	/**
	 * On initialization event.
	 * Handles route parameters for display.
	 *
	 * @memberof AccessDeniedComponent
	 */
	public ngOnInit(): void
	{
		this.route.queryParams.subscribe(
			(parameters: Params) =>
			{
				const mappedRouteData: any =
					ObjectHelper.mapFromRouteData(
						parameters);

				this.accessDeniedUrl =
					AnyHelper.isNullOrEmpty(
						mappedRouteData[
							this.queryParameters.accessDeniedUrlQueryParameter])
						? AppConstants.empty
						: mappedRouteData[
							this.queryParameters.accessDeniedUrlQueryParameter];
				this.resources =
					AnyHelper.isNullOrEmpty(
						mappedRouteData[
							this.queryParameters.resourcesQueryParameter])
						? []
						: mappedRouteData[
							this.queryParameters.resourcesQueryParameter];
				this.clientMessage =
					AnyHelper.isNullOrEmpty(
						mappedRouteData[
							this.queryParameters.clientMessageQueryParameter])
						? AppConstants.empty
						: mappedRouteData[
							this.queryParameters.clientMessageQueryParameter];

				this.sessionIdentifier = this.sessionService.sessionId;

				this.loading = false;
			});
	}

	/**
	 * On component destroy event.
	 * This method is used to complete the parameter based routing.
	 *
	 * @memberof EntityInstanceComponent
	 */
	public ngOnDestroy(): void
	{
		this.router.routeReuseStrategy.shouldReuseRoute =
			<(future: ActivatedRouteSnapshot,
				curr: ActivatedRouteSnapshot) => boolean>
			this.existingRouteReuseStrategy;
	}

	/**
	 * Handles the back action component event.
	 *
	 * @memberof AccessDeniedComponent
	 */
	public backNavigate(): void
	{
		this.location.back();
	}
}