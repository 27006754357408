<div *ngIf="to.loadingTableDefinitions"
	class="text-center ui-g-12">
	<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
	</span>
</div>

<div *ngIf="!to.loadingTableDefinitions"
	class="ui-g-12 no-padding">
	<app-common-table
		[tableDefinitions]="to.tableDefinitions"
		[fieldContext]="to.context"
		[field]="field"
		(finishedLoading)="fireChanges()"
		(rowSelected)="rowSelected($event)">
	</app-common-table>
</div>