/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ChartConstants
} from '@shared/constants/chart-constants';
import {
	ChartFactory
} from '@shared/factories/chart-factory';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	Component,
	OnInit
} from '@angular/core';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	DateTime
} from 'luxon';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	IAggregate
} from '@shared/interfaces/application-objects/aggregate.interface';
import {
	IChartDefinition
} from '@shared/interfaces/application-objects/chart-definition.interface';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IInformationMenuItem
} from '@shared/interfaces/application-objects/information-menu-item.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	ISecuritySessionEvent
} from '@shared/interfaces/security/security-session-event.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SecurityApplicationApiService
} from '@api/services/security/security-application.api.service';
import {
	SecuritySessionApiService
} from '@api/services/security/security-session.api.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/* eslint-enable max-len */

@Component({
	selector: 'app-session',
	templateUrl: './session.component.html',
	styleUrls: ['./session.component.scss']
})

/**
 * A component representing an instance of the session component.
 *
 * @export
 * @class SessionComponent
 * @extends {CommonTablePageDirective}
 * @implements {IDynamicComponent<CommonTableComponent, any>}
 * @implements {OnInit}
 */
export class SessionComponent
	extends CommonTablePageDirective
	implements IDynamicComponent<CommonTableComponent, any>, OnInit
{
	/**
	 * Initializes a new instance of the SessionComponent class.
	 *
	 * @param {SecurityApplicationApiService} securityApplicationApiService
	 * The api service used to load security application data.
	 * @param {SecuritySessionApiService} securitySessionApiService
	 * The api service used to load security session data.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The api service used to work with entity instances.
	 * @param {ChartFactory} chartFactory
	 * The chart factory to use for charted information displays.
	 * @param {SiteLayoutService} siteLayoutService
	 * The api service used to load the side layout data.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof SessionComponent
	 */
	public constructor(
		public securityApplicationApiService: SecurityApplicationApiService,
		public securitySessionApiService: SecuritySessionApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public chartFactory: ChartFactory,
		public siteLayoutService: SiteLayoutService,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the context that will be set when implementing this
	 * as a dynamic component.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof SessionComponent
	 */
	public context: IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the formated token value.
	 *
	 * @type {string}
	 * @memberof SessionComponent
	 */
	public token: string;

	/**
	 * Gets or sets the formated create date value.
	 *
	 * @type {string}
	 * @memberof SessionComponent
	 */
	public createDate: string;

	/**
	 * Gets or sets the formated last active date value.
	 *
	 * @type {string}
	 * @memberof SessionComponent
	 */
	public lastActiveDate: string;

	/**
	 * Gets or sets the formated expire date value.
	 *
	 * @type {string}
	 * @memberof SessionComponent
	 */
	public expireDate: string;

	/**
	 * Gets or sets the user name value.
	 *
	 * @type {string}
	 * @memberof SessionComponent
	 */
	public userName: string;

	/**
	 * Gets or sets the user label value.
	 *
	 * @type {string}
	 * @memberof SessionComponent
	 */
	public userLabel: string;

	/**
	 * Gets or sets the application value.
	 *
	 * @type {string}
	 * @memberof SessionComponent
	 */
	public application: string;

	/**
	 * Gets or sets the context menu items.
	 *
	 * @type {object}
	 * @memberof SessionComponent
	 */
	public summaryCardItems: object;

	/**
	 * Gets or sets the table definitions for the standard table view.
	 *
	 * @type {object}
	 * @memberof SessionComponent
	 */
	public securitySessionEventTableDefinitions: ICommonTable;

	/**
	 * Gets or sets the loading table definitions state.
	 *
	 * @type {boolean}
	 * @memberof EntitySelectComponent
	 */
	public loadingTableDefinition: boolean = true;

	/**
	 * Sets the event by response chart definition.
	 *
	 * @type {{
		chartColors: string[],
		chartLabels: string[],
		chartPivotProperty: string}}
	 * @memberof SessionComponent
	 */
	private readonly eventByResponseChartDefinition: {
		chartColors: string[];
		chartLabels: string[];
		chartPivotProperty: string;
	} =
	{
		chartColors:
		[
			ChartConstants.themeColors.green,
			ChartConstants.themeColors.red,
			ChartConstants.themeColors.orange
		],
		chartLabels:
		[
			'Success',
			'Error',
			'Redirection'
		],
		chartPivotProperty: 'Response'
	};

	/**
	 * Sets the event by method chart definition.
	 *
	 * @type {{
		chartColors: string[],
		chartLabels: string[],
		chartPivotProperty: string}}
	 * @memberof SessionComponent
	 */
	private readonly eventByMethodChartDefinition: {
		chartColors: string[];
		chartLabels: string[];
		chartPivotProperty: string;
	} =
	{
		chartColors:
		[
			ChartConstants.themeColors.blue,
			ChartConstants.themeColors.lightBlue,
			ChartConstants.themeColors.yellow
		],
		chartLabels:
		[
			'Get',
			'Put',
			'Post'
		],
		chartPivotProperty: 'Name'
	};

	/**
	 * Sets the events by method string.
	 *
	 * @type {string}
	 * @memberof SessionComponent
	 */
	private readonly eventsByMethod: string = 'Events by Method';

	/**
	 * Handles the data setup on initialization.
	 * This will setup the required variables for the session page component.
	 *
	 * @memberof SessionComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.partiallyMaskedToken(
			this.context.data.data.token);
		this.datesSetup();
		this.userName = await this.getUserName();
		this.application = await this.getApplication();
		this.setupContextMenuItems();
		this.setupTableDefinitions();
	}

	/**
	 * Sets up chart context menu items on this component.
	 * This will setup the data and summary cards.
	 *
	 * @memberof SessionComponent
	 */
	public async setupContextMenuItems(): Promise<void>
	{
		this.summaryCardItems =
		[
			<IInformationMenuItem<any>>
			{
				dataPromise: new Promise(async (resolve) =>
				{
					const sessionEvents = await this.securitySessionApiService
						.querySessionEvents(
							this.context.data.data.id,
							AppConstants.empty,
							'id desc',
							null,
							AppConstants.dataLimits.large,
							null);

					let millisecondsCounter = 0;
					let millisecondsDateDifference = 0;

					sessionEvents.forEach(
						(item: ISecuritySessionEvent) =>
						{
							millisecondsDateDifference =
								Date.parse(item.changeDate) -
								Date.parse(item.createDate);

							millisecondsCounter += millisecondsDateDifference;
						});

					const millisecondsAverage: number =
						millisecondsCounter / sessionEvents.length;

					const timeAverage =
						this.getAverageTime(millisecondsAverage);

					EventHelper.dispatchTableExpansionPanelLoadedEvent();

					resolve(
						{
							responseTime: `${timeAverage}`
						});
				}),
				summaryTemplate: '${data.responseTime}',
				titleTemplate: 'Avg Response Time',
				width: AppConstants.sizeIdentifiers.large,
				summaryCardDisplay: true,
				squareCardDisplay: false
			},
			<IInformationMenuItem<IAggregate[]>>
				{
					chartDefinition: <IChartDefinition<IAggregate[]>>{
						dataPromise: new Promise(async (resolve) => {
							const sessionEvents =
								await this.securitySessionApiService
									.querySessionEvents(
										this.context.data.data.id,
										AppConstants.empty,
										'id desc',
										null,
										AppConstants.dataLimits.large,
										null);

							let successCounter: number = 0;
							let redirectionCounter: number = 0;
							let errorCounter: number = 0;
							sessionEvents.forEach(
								(item: ISecuritySessionEvent) =>
								{
									const firstCharacter: string =
										item.response?.slice(0, 1);
									if (firstCharacter === '2')
									{
										successCounter++;
									}

									if (firstCharacter === '3')
									{
										redirectionCounter++;
									}

									if (firstCharacter === '4'
										|| firstCharacter === '5')
									{
										errorCounter++;
									}
								});

							EventHelper
								.dispatchTableExpansionPanelLoadedEvent();

							resolve(
								<IAggregate[]>
								[
									{
										key:
										{
											response: 'success'
										},
										value: successCounter
									},
									{
										key:
										{
											response: 'redirection'
										},
										value: redirectionCounter
									},
									{
										key:
										{
											response: 'error'
										},
										value: errorCounter
									}
								]);
						}),
						chartColors:
							this.eventByResponseChartDefinition
								.chartColors,
						chartConfiguration: this.chartFactory.doughnutChart(
							'Error Count Ratio',
							this.eventByResponseChartDefinition.chartLabels,
							[],
							'Number of Events'),
						chartPivotProperty:
							this.eventByResponseChartDefinition
								.chartPivotProperty
					},
					titleTemplate: 'Error Count Ratio',
					width: AppConstants.sizeIdentifiers.large,
					summaryCardDisplay: false,
					squareCardDisplay: true
				},
			<IInformationMenuItem<any>>
			{
				dataPromise: new Promise(
					async(resolve) =>
					{
						const sessionEvents =
							await this.securitySessionApiService
								.aggregateSessionEvents(
									this.context.data.data.id,
									'Count',
									AppConstants.empty);

						EventHelper.dispatchTableExpansionPanelLoadedEvent();

						resolve(
							{
								eventCounter: sessionEvents[0].value
							});
					}),
				summaryTemplate: '${data.eventCounter}',
				titleTemplate: 'Number of Events',
				width: AppConstants.sizeIdentifiers.large,
				summaryCardDisplay: true,
				squareCardDisplay: false
			},
			<IInformationMenuItem<IAggregate[]>>
			{
				chartDefinition: <IChartDefinition<IAggregate[]>>{
					dataPromise: new Promise(async (resolve) => {
						const sessionEvents =
							await this.securitySessionApiService
								.querySessionEvents(
									this.context.data.data.id,
									AppConstants.empty,
									'id desc',
									null,
									AppConstants.dataLimits.large,
									null);

						let getCounter: number = 0;
						let putCounter: number = 0;
						let postCounter: number = 0;

						sessionEvents.forEach(
							(item: ISecuritySessionEvent) =>
							{
								const firstCharacters: string =
									item.name?.slice(0, 4);
								if (firstCharacters
									.includes(AppConstants.apiMethods
										.get.toUpperCase()))
								{
									getCounter++;
								}
								else
								if (firstCharacters
									.includes(AppConstants.apiMethods
										.put.toUpperCase()))
								{
									putCounter++;
								}
								else
								if (firstCharacters
									.includes(AppConstants.apiMethods
										.post.toUpperCase()))
								{
									postCounter++;
								}
							});

						EventHelper.dispatchTableExpansionPanelLoadedEvent();

						resolve(
							<IAggregate[]>
							[
								{
									key:
									{
										name: AppConstants.apiMethods.get
									},
									value: getCounter
								},
								{
									key:
									{
										name: AppConstants.apiMethods.update
									},
									value: putCounter
								},
								{
									key:
									{
										name: AppConstants.apiMethods.create
									},
									value: postCounter
								}
							]);
					}),
					chartColors: this.eventByMethodChartDefinition.chartColors,
					chartConfiguration: this.chartFactory.doughnutChart(
						this.eventsByMethod,
						this.eventByMethodChartDefinition.chartLabels,
						[],
						this.eventsByMethod),
					chartPivotProperty:
						this.eventByMethodChartDefinition
							.chartPivotProperty
				},
				titleTemplate: this.eventsByMethod,
				width: AppConstants.sizeIdentifiers.large,
				summaryCardDisplay: false,
				squareCardDisplay: true
			}
		];

		let displayOrder: number = 1;
		this.availableColumns =
			[
				{
					dataKey: 'name',
					columnHeader: 'Event',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'response',
					columnHeader: 'Result',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'createDate',
					dataFormatType: AppConstants.dataFormatTypes.dateTime,
					columnHeader: 'Time',
					displayOrder: displayOrder
				}
			];
		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Gets the average time.
	 *
	 * @param {number} millisecondsAverage
	 * The milliseconds average time.
	 * @returns {string}
	 * The calculated average time.
	 * @memberof SessionComponent
	 */
	public getAverageTime(millisecondsAverage: number): string
	{
		if (this.siteLayoutService.getContentCssClass()
			!== AppConstants.cssClasses.pContentSmallPhone)
		{
			return millisecondsAverage % 1 === 0
				? millisecondsAverage + 'ms'
				: millisecondsAverage.toFixed(2) + 'ms';
		}
		else if (millisecondsAverage < 10)
		{
			return (millisecondsAverage % 1 === 0)
				? millisecondsAverage + 'ms'
				: millisecondsAverage.toFixed(2) + 'ms';
		}
		else if ((millisecondsAverage / 1000) < 10)
		{
			return (millisecondsAverage / 1000) % 1 === 0
				? (millisecondsAverage / 1000) + 's'
				: (millisecondsAverage / 1000).toFixed(2) + 's';
		}

		return '+1min';
	}

	/**
	 * Returns the interpolated value of a user name
	 * queried from the api interface.
	 *
	 * @returns {string}
	 * @memberof SessionComponent
	 */
	public async getUserName(): Promise<string>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.users;
		const user = await this.entityInstanceApiService
			.get(this.context.data.data.entityInstanceId);

		const userFullName = StringHelper.interpolate(
			'${user.data.firstName} ${user.data.lastName}',
			user);

		if (userFullName.includes(AppConstants.undefined))
		{
			this.userLabel = 'User Id';

			return this.context.data.data.entityInstanceId.toString();
		}

		this.userLabel = 'User';

		return userFullName;
	}

	/**
	 * Returns the interpolated value of an application
	 * queried from the api interface.
	 *
	 * @returns {string}
	 * @memberof SessionComponent
	 */
	public async getApplication(): Promise<string>
	{
		const application = await this.securityApplicationApiService
			.get(this.context.data.data.applicationId);

		return	StringHelper.interpolate('${application.name}', application);
	}

	/**
	 * Sets up the dates using luxon to translate the utc to local.
	 *
	 * @memberof SessionComponent
	 */
	public datesSetup(): void
	{
		const dbCreateDate =
			DateTime.fromISO(
				this.context.data.data.createDate);
		this.createDate = this.dateFormatter(dbCreateDate);

		const dbLastActiveDate =
			DateTime.fromISO(
				this.context.data.data.lastActiveDate);
		this.lastActiveDate = this.dateFormatter(dbLastActiveDate);

		const dbExpireDate =
			DateTime.fromISO(
				this.context.data.data.expireDate);
		this.expireDate = this.dateFormatter(dbExpireDate);
	}

	/**
	 * Translates the date to an specific format and appends
	 * the time difference from that date to now by using luxon js.
	 *
	 * @param {DateTime} date
	 * The date to be formatted.
	 * @returns {string}
	 * @memberof SessionComponent
	 */
	public dateFormatter(
		date: DateTime): string
	{
		return date.toLocal()
			.toFormat(DateHelper.presetFormats.dateAndHourFormat)
			+ ` (${date.toRelative()})`;
	}

	/**
	 * Sets the token full value to a patialle masked token.
	 *
	 * @memberof SessionComponent
	 */
	public partiallyMaskedToken(fullToken: string): void
	{
		const partialToken: string = fullToken?.substring(
			fullToken.length - 10,
			fullToken.length);
		const mask: string = '...';
		this.token = mask + partialToken;
	}

	/**
	 * Sets the definitions for the entity search common table
	 *
	 * @memberof EntitySelectComponent
	 */
	public setupTableDefinitions(): void
	{
		this.securitySessionEventTableDefinitions = {
			hideExpanderArrow: true,
			hideSettings: true,
			actions: {
				view: {
					definition: {
						$id: 'http://json-schema.org/draft-07/schema#',
						title: 'Session Events',
						type: 'object',
						additionalProperties: false,
						properties: {
							data: {
								type: 'object',
								additionalProperties: false,
								properties: {
									name: {
										type: 'string',
										description: 'Request'
									},
									response: {
										type: 'string',
										description: 'Response'
									},
									createDate: {
										type: 'string',
										description: 'Create Date'
									},
									changeDate: {
										type: 'string',
										description: 'Change Date'
									}
								}
							}
						}
					},
					items: []
				}
			},
			tableTitle: 'Session Events',
			objectSearch: {
				id: this.context.data.data.id,
				filter: AppConstants.empty,
				orderBy: `Id ${AppConstants.sortDirections.descending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				last: null,
				virtualIndex: 0,
				virtualPageSize: 5
			},
			apiPromise: async(objectSearch: IObjectSearch) =>
			{
				const sessionEvents: ISecuritySessionEvent[] =
						await this.securitySessionApiService
							.querySessionEvents(
								objectSearch.id,
								objectSearch.filter,
								objectSearch.orderBy,
								objectSearch.offset,
								objectSearch.limit,
								objectSearch.last);

				EventHelper.dispatchTableExpansionPanelLoadedEvent();

				return sessionEvents;
			},
			availableColumns: this.availableColumns,
			selectedColumns: this.selectedColumns,
			columnSelectionMode: this.columnSelectionMode,
			expandTitle: () => 'View Event',
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.securitySessionEventTableDefinitions.selectedColumns =
					selectedColumns;
				this.selectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.securitySessionEventTableDefinitions.columnSelectionMode =
					columnSelectionMode;
				this.columnSelectionMode = columnSelectionMode;
			}
		};
		this.loadingTableDefinition = false;
	}
}