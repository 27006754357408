<ng-template [ngIf]="to.inputGroupItems == null"
	[ngIfElse]="inputGroupView">
	<div class="custom-input-switch-container">
		<label class="visually-hidden"
			[attr.for]="'AriaInput' + id">
			{{to.label}}
		</label>
		<p-inputSwitch
			[formControl]="formControl"
			[formlyAttributes]="field"
			[styleClass]="inputStyleClass"
			(onChange)="handleInputChanges($event)">
		</p-inputSwitch>
	</div>
</ng-template>

<ng-template #inputGroupView>
	<div *ngFor="let inputGroupItem of inputGroupItems"
		class="custom-input-switch-container">
		<div class="p-inputgroup">
			<span class="p-inputgroup-addon radio-button-title ellipsis-text">
				{{inputGroupItem.label}}
			</span>
			<span *ngIf="inputGroupItem.description != null"
				class="description-tooltip-container"
				[pTooltip]="inputGroupItem.description"
				tooltipPosition="left">
				<em class="fa fa-info-circle theme-color">
				</em>
			</span>
			<span class="p-inputgroup-addon radio-button">
				<p-inputSwitch
					[name]="inputGroupItem.name"
					[disabled]="to.disabled"
					[(ngModel)]="inputGroupItem.value"
					[formlyAttributes]="field"
					[styleClass]="inputStyleClass"
					(onChange)="handleInputGroupChanges($event, inputGroupItem.key)">
				</p-inputSwitch>
			</span>
		</div>
	</div>
</ng-template>

<field-validation-message
	[field]="field">
</field-validation-message>