/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';

/* eslint-enable max-len */

@Component({
	selector: 'insurance-status-reasons',
	templateUrl: './status-reasons.component.html',
	styleUrls: [
		'./status-reasons.component.scss'
	]
})

/**
 * A component representing a shared display for gathering selected reasons and
 * note data.
 *
 * @export
 * @class StatusReasonsComponent
 */
export class StatusReasonsComponent
{
	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<DynamicWizardComponent, IWizardContext>}
	 * @memberof StatusReasonsComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the validity change event emitter that will signal to all
	 * listening components that this component has has a change in validity.
	 *
	 * @type {EventEmitter<boolean>}
	 * @memberof StatusReasonsComponent
	 */
	@Output() public validityChange: EventEmitter<boolean> =
		new EventEmitter<boolean>();

	/**
	 * Gets or sets a boolean value to determine if the status reasons
	 * component will display the detailed reasons view.
	 *
	 * @type {boolean}
	 * @memberof StatusReasonsComponent
	 */
	@Input() public detailedView: boolean = false;

	/**
	 * Gets or sets the formly layout used in implementing components.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof StatusReasonsComponent
	 */
	@Input() public dynamicFormlyLayout: FormlyFieldConfig[] =
		<FormlyFieldConfig[]>
		[
			{
				key: 'data.comments',
				type: FormlyConstants.customControls.customTextArea,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					label: 'Comments',
					rows: FormlyConstants.textAreaRowSizes.small
				}
			}
		];

	/**
	 * Handles the validity changed event sent from the formly component and
	 * the reasons list.
	 *
	 * @async
	 * @param {boolean} isValid
	 * A truthy representing if the data in the form display is valid.
	 * @memberof StatusReasonsComponent
	 */
	public async validityChanged(
		isValid: boolean = null): Promise<void>
	{
		this.validityChange.emit(
			(this.context.source.activeMenuItem.currentData.data
				.availableReasons == null
				|| this.context.source.activeMenuItem.currentData.data
					.selectedReasons?.length > 0)
				&& (AnyHelper.isNull(isValid)
					|| isValid === true));
	}
}