/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	ApiError
} from '@api/errors/api.error';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	ElementRef,
	Injectable,
	OnInit,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup
} from '@angular/forms';
import {
	LoginComponent
} from '@appComponents/login/login.component';
import {
	SessionService
} from '@shared/services/session.service';
import {
	ShakeAnimation
} from '@shared/app-animations';

@Component({
	selector: 'app-login-reset',
	templateUrl: './login-reset.component.html',
	styleUrls: ['./login.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: [
		ShakeAnimation
	]
})

/**
 * A class representing an instance of the login reset component.
 *
 * @export
 * @class LoginResetComponent
 * @implements {OnInit}
 */
@Injectable()
export class LoginResetComponent
implements OnInit
{
	/**
	 * Creates an instance of LoginResetComponent.
	 *
	 * @param {SessionService} sessionService
	 * @param {Router} router
	 * @param {LoginComponent} loginComponent
	 * @param {FormBuilder} formBuilder
	 * @memberof LoginResetComponent
	 */
	public constructor(
		private readonly sessionService: SessionService,
		private readonly loginComponent: LoginComponent,
		private readonly formBuilder: FormBuilder)
	{
	}

	/**
	 * Gets or sets the reset form group.
	 *
	 * @public
	 * @type {FormGroup}
	 * @memberof LoginResetComponent
	 */
	public resetForm: FormGroup;

	/**
	 * Gets or sets a value indicating whether a form submit is in progress.
	 *
	 * @public
	 * @type {boolean}
	 * @memberof LoginResetComponent
	 */
	public submitInProgress: boolean = false;

	/**
	 * Gets a value indicating whether the login button is disabled.
	 *
	 * @type {boolean} A combination of status of whether the resetForm is valid
	 * and submitInProgress.
	 * @memberof LoginDialogComponent
	 */
	public get resetIsDisabled(): boolean
	{
		return !this.resetForm.valid || this.submitInProgress;
	}

	/**
	 * Gets or sets the userName element.
	 *
	 * @protected
	 * @type {ElementRef}
	 * @memberof LoginResetComponent
	 */
	@ViewChild('userName', { static: true })
	protected userNameElement: ElementRef;

	/**
	 * Gets a {string} value representing the userName from the form.
	 *
	 * @private
	 * @type {string}
	 * @memberof LoginResetComponent
	 */
	private get userName(): string
	{
		return this.resetForm.controls.userName.value;
	}

	/**
	 * Sets a {string} value representing the userName to the form.
	 *
	 * @private
	 * @memberof LoginResetComponent
	 */
	private set userName(
		value: string)
	{
		this.resetForm.controls.userName.setValue(value);
	}

	/**
	 * On initialization event.
	 * Resets the login component.
	 *
	 * @returns {void}
	 * @memberof LoginResetComponent
	 */
	public ngOnInit(): void
	{
		this.resetForm = this.formBuilder.group(
			{
				'userName': new FormControl('')
			}
		);

		if (this.loginComponent.userName)
		{
			this.userName = this.loginComponent.userName;
		}

		this.userNameElement.nativeElement.focus();
	}

	/**
	 * On form cancel event.
	 *
	 * @returns {void}
	 * @memberof LoginResetComponent
	 */
	public async cancel(): Promise<void>
	{
		this.sessionService.logOut();
		this.loginComponent.displayLogin(this.userName);
	}

	/**
	 * On form submit event.
	 *
	 * @returns {void}
	 * @memberof LoginResetComponent
	 */
	public async reset(): Promise<void>
	{
		try
		{
			this.submitInProgress = true;
			await this.sessionService.resetUser(
				this.userName);

			this.loginComponent.displayLogin(
				this.userName,
				'Your account reset request was successful.');
		}
		catch (exception)
		{
			if (exception.status === 404)
			{
				// User specified is not found
				this.loginComponent.addMessage(
					AppConstants.messageLevel.warn,
					AppConstants.loginStatus.invalid,
					`Username '${this.userName}' not found.`);

				this.resetForm.controls.userName.reset();
				this.userNameElement.nativeElement.focus();
			}
			else
			{
				if (exception instanceof ApiError)
				{
					this.loginComponent.addMessage(
						AppConstants.messageLevel.error,
						exception.message,
						exception.messages[0].description);
				}
				else
				{
					this.loginComponent.addMessage(
						AppConstants.messageLevel.error,
						AppConstants.loginStatus.failure,
						exception.message);
				}
			}

			return;
		}
		finally
		{
			this.submitInProgress = false;
		}
	}
}