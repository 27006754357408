/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ActivatedRoute,
	ActivatedRouteSnapshot,
	Params,
	Router,
	UrlCreationOptions
} from '@angular/router';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	OnDestroy,
	OnInit
} from '@angular/core';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	DisplayComponentFactory
} from '@shared/factories/display-component-factory';
import {
	DisplayComponentInstance
} from '@shared/implementations/display-components/display-component-instance';
import {
	DisplayComponentParameterDirective
} from '@shared/directives/display-component-parameter.directive';
import {
	DisplayComponentService
} from '@shared/services/display-component.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IDashboardParameterSet
} from '@shared/interfaces/application-objects/dashboard-parameter-set';
import {
	IDashboardSection
} from '@shared/interfaces/application-objects/dashboard-section.interface';
import {
	IDashboardWidget
} from '@shared/interfaces/application-objects/dashboard-widget.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IUser
} from '@shared/interfaces/users/user.interface';
import {
	Location
} from '@angular/common';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SessionService
} from '@shared/services/session.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';

@Component({
	selector: 'app-generic-dashboard',
	templateUrl: './generic-dashboard.component.html',
	styleUrls: [
		'./generic-dashboard.component.scss'
	],
	animations: [
		ContentAnimation
	]
})

/**
 * A component representing an instance of the generic dashboard
 * component.
 *
 * @export
 * @class GenericDashboardComponent
 * @extends {DisplayComponentParameterDirective}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
export class GenericDashboardComponent
	extends DisplayComponentParameterDirective
	implements OnInit, OnDestroy
{
	/**
	 * Initializes a new instance of the GenericDashboardComponent.
	 * This component is used to display dynamic content based
	 * dashboard content.
	 *
	 * @param {ActivatedRoute} route
	 * The route used in this component.
	 * @param {Router} router
	 * The router used in this component.
	 * @param {Location} location
	 * The Angular common location service used for url interaction.
	 * @param {ModuleService} moduleService
	 * The module service used in this component.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this component.
	 * @param {SessionService} sessionService
	 * The session service used in this component.
	 * @param {DisplayComponentService} displayComponentService
	 * The service used to load and gather display component data.
	 * @param {DisplayComponentFactory} displayComponentFactory
	 * The factory used to generate display component interfaces.
	 * @param {ResolverService} resolver
	 * The resolver service used for display component providers.
	 * @memberof GenericDashboardComponent
	 */
	public constructor(
		public route: ActivatedRoute,
		public router: Router,
		public location: Location,
		public moduleService: ModuleService,
		public siteLayoutService: SiteLayoutService,
		public sessionService: SessionService,
		public displayComponentService: DisplayComponentService,
		public displayComponentFactory: DisplayComponentFactory,
		public resolver: ResolverService)
	{
		super(
			siteLayoutService,
			displayComponentService,
			displayComponentFactory);

		this.existingRouteReuseStrategy =
			this.router.routeReuseStrategy.shouldReuseRoute;

		this.router.routeReuseStrategy.shouldReuseRoute =
			(_future: ActivatedRouteSnapshot,
				_curr: ActivatedRouteSnapshot): boolean =>
				false;
	}

	/**
	 * Gets or sets the number of authorized sections that this
	 * dashboard is expected to display.
	 *
	 * @type {number}
	 * @memberof GenericDashboardComponent
	 */
	public authorizedSectionCount: number;

	/**
	 * Gets or sets the value that signifies that all sections and
	 * widgets have been displayed.
	 *
	 * @type {boolean}
	 * @memberof GenericDashboardComponent
	 */
	public dashboardDisplayComplete: boolean = false;

	/**
	 * Gets or sets the display component instance name for
	 * this dashboard.
	 *
	 * @type {string}
	 * @memberof GenericDashboardComponent
	 */
	public dashboardDisplayComponentInstanceName: string;

	/**
	 * Gets or sets number of currently loaded and displayed sections.
	 *
	 * @type {number}
	 * @memberof GenericDashboardComponent
	 */
	public displayedSectionCount: number = 0;

	/**
	 * Gets or sets the array of dashboard sections to display.
	 *
	 * @type {IDashboardSection[]}
	 * @memberof GenericDashboardComponent
	 */
	public dashboardSections: IDashboardSection[] = [];

	/**
	 * Gets or sets the display title for this dashboard.
	 *
	 * @type {string}
	 * @memberof GenericDashboardComponent
	 */
	public dashboardTitle: string;

	/**
	 * Gets or sets the dashboard type.
	 *
	 * @type {string}
	 * @memberof GenericDashboardComponent
	 */
	public dashboardType: string;

	/**
	 * Gets or sets the display component instance for this dashboard.
	 *
	 * @type {DisplayComponentInstance}
	 * @memberof GenericDashboardComponent
	 */
	public displayComponentInstance: DisplayComponentInstance;

	/**
	 * Gets or sets the parameter layout schema for this dashboard.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof GenericDashboardComponent
	 */
	public parameterLayoutSchema: FormlyFieldConfig[];

	/**
	 * Gets or sets the parameter layout data for this dashboard.
	 *
	 * @type {any}
	 * @memberof GenericDashboardComponent
	 */
	public parameterLayoutData: any;

	/**
	 * Gets or sets the serialized route parameters for this dashboard.
	 *
	 * @type {IDashboardParameterSet}
	 * @memberof GenericDashboardComponent
	 */
	public routeParameters: IDashboardParameterSet;

	/**
	 * Gets or sets the user associated with this dashboard to make
	 * this available in the page context source.
	 *
	 * @type {IUser}
	 * @memberof GenericDashboardComponent
	 */
	public sessionUser: IUser;

	/**
	 * Gets or sets the header context content.
	 *
	 * @type {any}
	 * @memberof GenericDashboardComponent
	 */
	public headerContextContent: any;

	/**
	 * Gets the dashboard type identifier for url parameter
	 * lookups.
	 *
	 * @type {string}
	 * @memberof GenericDashboardComponent
	 */
	private readonly dashboardTypeIdentifier: string = 'dashboardType';

	/**
	 * Gets or sets the route reuse strategy for the router on initial
	 * load. This is used to reset the route reuse strategy to it's
	 * original value on destroy, but force a component refresh on route
	 * changes to this component.
	 *
	 * @type {(
		future: ActivatedRouteSnapshot,
		curr: ActivatedRouteSnapshot) => boolean}
	* @memberof GenericDashboardComponent
	*/
	private readonly existingRouteReuseStrategy:
		(future: ActivatedRouteSnapshot,
			curr: ActivatedRouteSnapshot) => boolean;

	/**
	 * On component initialization event.
	 * This method is used to set this component for route based
	 * initialization.
	 *
	 * @memberof GenericDashboardComponent
	 */
	public ngOnInit(): void
	{
		this.sessionUser = this.sessionService.user;

		const dashboardParameterValue: string =
			this.route.snapshot.paramMap.get(
				this.dashboardTypeIdentifier) as unknown as string;
		this.dashboardType =
			StringHelper.toProperCase(
				AnyHelper.isNullOrWhitespace(dashboardParameterValue)
					? AppConstants.empty
					: dashboardParameterValue);

		this.dashboardDisplayComponentInstanceName =
			this.getDashboardDisplayName();

		this.subscriptions.add(
			this.route.queryParams.subscribe(
				(parameters: Params) =>
				{
					this.routeParameters =
						ObjectHelper.mapFromRouteData(
							parameters);
				}));

		this.pageContext =
			<IDynamicComponentContext<Component, any>>
				{
					source: this,
					data: <any> {}
				};
	}

	/**
	 * On component destroy event.
	 * This method is used to reset the route strategy to it's original
	 * implementation on destroy.
	 *
	 * @memberof GenericDashboardComponent
	 */
	public ngOnDestroy(): void
	{
		this.subscriptions.unsubscribe();

		this.router.routeReuseStrategy.shouldReuseRoute =
			<(future: ActivatedRouteSnapshot,
				curr: ActivatedRouteSnapshot) => boolean>
			this.existingRouteReuseStrategy;
	}

	/**
	 * Returns a formatted display name for use when loading the dynamic
	 * dashboard content. This will be the identifier for the
	 * display content.
	 *
	 * @returns {string}
	 * A formatted name matching the dynamic display key in the database.
	 * @memberof GenericDashboardComponent
	 */
	public getDashboardDisplayName(): string
	{
		return this.moduleService.name
			+ this.dashboardType
			+ 'GenericDashboard';
	}

	/**
	 * Returns a formatted display title for this dashboard page.
	 *
	 * @returns {string}
	 * A formatted page title.
	 * @memberof GenericDashboardComponent
	 */
	public getDashboardDisplayTitle(): string
	{
		let dashboardDisplayTitle = this.moduleService.name;

		if (!AnyHelper.isNullOrEmpty(
			this.displayComponentInstance?.jsonInterpolationData.titleFunction))
		{
			return StringHelper
				.transformToFunction(
					this.displayComponentInstance.jsonInterpolationData
						.titleFunction,
					this.pageContext)();
		}
		else if (!AnyHelper.isNullOrWhitespace(this.dashboardType))
		{
			dashboardDisplayTitle = this.dashboardType;
		}

		return StringHelper.normalizeCamelcase(dashboardDisplayTitle)
			+ AppConstants.characters.space
			+ 'Dashboard';
	}

	/**
	 * Sets the header context content.
	 *
	 * @param {object} contextContent
	 * The context content.
	 *
	 * @memberof GenericDashboardComponent
	 */
	public setHeaderContextContent(
		contextContent: object): void
	{
		this.headerContextContent = contextContent;
		this.displayComponentInstance =
			this.headerContextContent.displayComponentInstance;
		this.dashboardTitle = this.getDashboardDisplayTitle();
	}

	/**
	 * Handles a click from the dashboard parameter component.
	 *
	 * @override
	 * @async
	 * @memberof GenericDashboardComponent
	 */
	public async applyParameters(): Promise<void>
	{
		this.handleChildParameterData(
			this.headerContextContent.dashboardSections,
			this.headerContextContent.parameterLayoutSchema,
			this.headerContextContent.parameterLayoutData);

		setTimeout(
			() =>
			{
				this.updateRouteData();
			},
			this.siteLayoutService.debounceDelay);
	}

	/**
	 * Adds the current dashboard parameter set to the URL when data changes
	 * are applied.
	 *
	 * @memberof GenericDashboardComponent
	 */
	public updateRouteData(): void
	{
		this.location
			.replaceState(
				this.router
					.createUrlTree(
						[],
						<UrlCreationOptions>
						{
							relativeTo: this.route,
							replaceUrl: true,
							queryParams: {
								routeData:
									ObjectHelper.mapRouteData(
										this.getRouteDataForUrlStorage())
							}
						})
					.toString());
	}

	/**
	 * Maps and returns a serializable dashboard parameter set for
	 * url storage.
	 *
	 * @returns {IDashboardParameterSet}
	 * A mapped dashboard parameter set ready for url use.
	 * @memberof GenericDashboardComponent
	 */
	public getRouteDataForUrlStorage(): IDashboardParameterSet
	{
		const dashboardParameterSet: IDashboardParameterSet =
			<IDashboardParameterSet>
			{
				identifier: this.headerContextContent.displayComponentInstance.name,
				data: this.headerContextContent.parameterLayoutData.data,
				children: []
			};

		this.headerContextContent.dashboardSections.forEach(
			(section: IDashboardSection) =>
			{
				const sectionParameterSet: IDashboardParameterSet =
					<IDashboardParameterSet>
					{
						identifier: section.displayComponentInstance.name,
						data: section.parameterLayoutData.data,
						children: []
					};

				section.widgets.forEach(
					(widget: IDashboardWidget) =>
					{
						sectionParameterSet.children.push(
							<IDashboardParameterSet>
							{
								identifier: widget.displayComponentInstance.name,
								data: widget.parameterLayoutData.data,
								children: []
							});
					});

				dashboardParameterSet.children.push(sectionParameterSet);
			});

		return dashboardParameterSet;
	}
}