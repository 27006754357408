/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ActivatedRoute,
	ActivatedRouteSnapshot,
	Params,
	Router,
	UrlCreationOptions
} from '@angular/router';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiFilterHelper
} from '@shared/helpers/api-filter.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	Component,
	OnDestroy,
	OnInit
} from '@angular/core';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityLayoutTypeApiService
} from '@api/services/entities/entity-layout-type.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	EntityTableDirective
} from '@entity/directives/entity-table.directive';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityDefinition
} from '@shared/interfaces/entities/entity-definition.interface';
import {
	IEntityLayoutType
} from '@shared/interfaces/entities/entity-layout-type.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IOwnershipGuardComponent
} from '@shared/interfaces/application-objects/ownership-guard-component';
import {
	Location
} from '@angular/common';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/* eslint-enable max-len */

@Component({
	templateUrl: './entity-search.component.html',
	styleUrls: ['./entity-search.component.scss'],
	animations: [
		ContentAnimation
	]
})

/**
 * A class representing an instance of an entity search component.
 *
 * @export
 * @class EntitySearchComponent
 * @extends {EntityTableDirective}
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {IOwnershipGuardComponent}
 */
export class EntitySearchComponent
	extends EntityTableDirective
	implements OnInit, OnDestroy, IOwnershipGuardComponent
{
	/**
	 * Creates an instance of an EntitySearchComponent. This component
	 * will display a filtered list of entities for navigation.
	 *
	 * @param {ActivatedRoute} route
	 * The activated route that opened this component.
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ModuleService} moduleService
	 * The module service used to store the current module.
	 * @param {EntityService} entityService
	 * The entity service used for data lookups.
	 * @param {EntityTypeApiService} EntityTypeApiService
	 * The entity type service used for filter lookups.
	 * @param {EntityLayoutTypeApiService} entityLayoutTypeApiService
	 * The entity layout type api service used for layout lookups.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance service used to populate the entity list data.
	 * @param {EntityDefinitionApiService} entityDefinitionApiService
	 * The entity definition service used to populate the entity data.
	 * @param {Location} location
	 * The location service used to create and populate the url tree.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof EntitySearchComponent
	 */
	public constructor(
		public route: ActivatedRoute,
		public router: Router,
		public moduleService: ModuleService,
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public entityLayoutTypeApiService: EntityLayoutTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public entityDefinitionApiService: EntityDefinitionApiService,
		public location: Location,
		public resolver: ResolverService)
	{
		super();
		this.existingRouteReuseStrategy =
			this.router.routeReuseStrategy.shouldReuseRoute;
		this.router.routeReuseStrategy.shouldReuseRoute =
			(_future: ActivatedRouteSnapshot,
				_curr: ActivatedRouteSnapshot): boolean =>
				false;
	}

	/**
	 * Gets or sets the entity search table definitions.
	 *
	 * @type {ICommonTable}
	 * @memberof EntitySearchComponent
	 */
	public entitySearchTableDefinitions: ICommonTable = null;

	/**
	 * Gets or sets the common table context.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof EntitySearchComponent
	 */
	public commonTableContext:
		IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the category options.
	 *
	 * @type {object[]}
	 * @memberof EntitySearchComponent
	 */
	public categoryOptions: object[] = [];

	/**
	 * Gets the available entity types.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	public availableEntityTypes: IEntityType[] = [];

	/**
	 * Gets or sets the loaded entity type group.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	public entityTypeGroup: string = null;

	/**
	 * Gets or sets the loaded entity definition.
	 *
	 * @type {IEntityDefinition}
	 * @memberof EntitySearchComponent
	 */
	public entityDefinition: IEntityDefinition;

	/**
	 * Gets or sets the route url entity types parameter.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	public entityTypesParam: string;

	/**
	 * Gets or sets the route url entity type parameter.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	public entityTypeParam: string;

	/**
	 * Gets or sets the route url entity filter parameter.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	public entityFilterParam: string;

	/**
	 * Gets or sets the route url entity order by parameter.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	public entityOrderByParam: string;

	/**
	 * Gets or sets the display title.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	public displayTitle: string;

	/**
	 * Gets or sets the category label.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	public selectedCategory: string;

	/**
	 * Gets or sets the loading categories flag.
	 *
	 * @type {boolean}
	 * @memberof EntitySearchComponent
	 */
	public loadingCategories: boolean = true;

	/**
	 * Gets or sets the summary data path available columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof EntitySearchComponent
	 */
	public summaryDataPathColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the nested parent key.
	 *
	 * @type {number}
	 * @memberof EntitySearchComponent
	 */
	public nestedParentKey: string;

	/**
	 * Gets or sets the entity sort field.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	public entitySortField: string;

	/**
	 * Gets or sets the entity sort order.
	 *
	 * @type {number}
	 * @memberof EntitySearchComponent
	 */
	public entitySortOrder: number;

	/**
	 * Gets or sets the entity row count.
	 *
	 * @type {number}
	 * @memberof EntitySearchComponent
	 */
	public entityRowCount: number = 15;

	/**
	 * Gets the page context sent to associated context utilities
	 * and menus.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof EntitySearchComponent
	 */
	public get pageContext(): IDynamicComponentContext<Component, any>
	{
		return <IDynamicComponentContext<Component, any>> {
			source: this
		};
	}

	/**
	 * Gets the loading entity type flag.
	 *
	 * @type {boolean}
	 * @memberof EntitySearchComponent
	 */
	public get loadingEntityType(): boolean
	{
		return this._loadingEntityType;
	}

	/**
	* Sets the loading entity type flag.
	*
	* @param {boolean} value
	* The value to set.
	* @memberof EntitySearchComponent
	*/
	public set loadingEntityType(
		value: boolean)
	{
		setTimeout(
			() =>
			{
				this._loadingEntityType = value;
			});
	}

	/**
	 * Gets or sets the the loading entity type flag property.
	 *
	 * @type {boolean}
	 * @memberof EntitySearchComponent
	 */
	private _loadingEntityType: boolean = true;

	/**
	 * Gets the entity types string value.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	private readonly entityTypes: string = 'entityTypes';

	/**
	 * Gets the entity types string value.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	private readonly entityType: string = 'entityType';

	/**
	 * Gets the entity filter string value.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	private readonly entityFilter: string = 'filter';

	/**
	 * Gets the entity order by string value.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	private readonly entityOrderBy: string = 'orderBy';

	/**
	 * Gets the order by group string.
	 *
	 * @type {string}
	 * @memberof EntitySearchComponent
	 */
	private readonly orderByGroup: string = 'Group';

	/**
	 * Gets or sets the route reuse strategy for the router on initial
	 * load. This is used to reset the route reuse strategy to it's
	 * original value on destroy, but force a component refresh on route changes
	 * to this component.
	 *
	 * @type {(
		future: ActivatedRouteSnapshot,
		curr: ActivatedRouteSnapshot) => boolean}
	* @memberof EntitySearchDirective
	*/
	private readonly existingRouteReuseStrategy:
		(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot)
			=> boolean;

	/**
	 * On initialization event.
	 * Configures this components display based on the sent route
	 * information.
	 *
	 * @async
	 * @memberof EntitySearchComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.route.queryParams.subscribe(
			async(parameters: Params) =>
			{
				const mappedRouteData: any =
					ObjectHelper.mapFromRouteData(
						parameters);

				this.entityTypesParam =
					!AnyHelper.isNullOrEmpty(mappedRouteData[this.entityTypes])
						? mappedRouteData[this.entityTypes]
						: AppConstants.empty;
				this.entityTypeParam =
					!AnyHelper.isNullOrEmpty(mappedRouteData[this.entityType])
						? mappedRouteData[this.entityType]
						: AppConstants.empty;
				this.entityFilterParam =
					!AnyHelper.isNullOrEmpty(mappedRouteData[this.entityFilter])
						? mappedRouteData[this.entityFilter]
						: AppConstants.empty;
				this.entityOrderByParam =
					!AnyHelper.isNullOrEmpty(
						mappedRouteData[this.entityOrderBy])
						? mappedRouteData[this.entityOrderBy]
						: `Id ${AppConstants.sortDirections.descending}`;
			});

		this.entitySortField =
			this.entityOrderByParam.split(
				AppConstants.characters.space)[0];

		this.setFilterKeywordString(this.entityFilterParam);
		await this.setCategories();
		this.setCategoryLabel(this.entityTypeGroup);
		this.setupTableDefinitions();
		this.updateUrlQuery();
	}

	/**
	 * On destroy event.
	 * Unsubscribes from any current subscriptions on component destroy.
	 *
	 * @memberof EntitySearchDirective
	 */
	public ngOnDestroy(): void
	{
		this.router.routeReuseStrategy.shouldReuseRoute =
			<(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot)
				=> boolean>
			this.existingRouteReuseStrategy;
	}

	/**
	 * Implements the ownership guard interface.
	 * This will calculate page ownership permissions.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * A value signifying whether or not access is allowed to this page.
	 * @memberof EntitySearchComponent
	 */
	public async isPageOwnershipAllowed(): Promise<boolean>
	{
		if (this.availableEntityTypes.length === 0)
		{
			return false;
		}

		const fullEntityLayoutType: IEntityLayoutType =
			await this.entityLayoutTypeApiService.getSingleQueryResult(
				AppConstants.commonProperties.name
					+ ` eq '${AppConstants.layoutTypes.full}'`,
				AppConstants.empty,
				true);

		if (AnyHelper.isNull(fullEntityLayoutType))
		{
			return false;
		}

		const initialPromiseArray: Promise<any>[] = [];
		this.availableEntityTypes.forEach(
			(entityType: IEntityType) =>
			{
				initialPromiseArray.push(
					this.entityService.verifyEntityTypeAccess(
						entityType,
						fullEntityLayoutType));
			});

		const allowedEntities: boolean[] =
			await Promise.all(initialPromiseArray);

		return allowedEntities.some(
			(allowed: boolean) =>
				allowed === true);
	}

	/**
	 * Sets the categories properties wich defines
	 * the category label and options to be displayed in
	 * the search filter component.
	 *
	 * @async
	 * @memberof EntitySearchComponent
	 */
	public async setCategories(): Promise<void>
	{
		const originalTypeParameter: string =
			this.entityTypesParam;
		this.availableEntityTypes =
			this.entityTypesParam === AppConstants.empty
				? await this.entityTypeApiService
					.query(
						AppConstants.empty,
						this.orderByGroup,
						null,
						100)
				: await this.entityTypeApiService
					.query(
						ApiFilterHelper.getWildcardFilter(
							'Group',
							this.entityTypesParam),
						this.orderByGroup,
						null,
						100);

		if (this.availableEntityTypes.length > 1)
		{
			// populate the category options with the existing operation groups.
			this.availableEntityTypes.forEach(
				(entityType) =>
				{
					this.categoryOptions.push(
						{
							value: entityType.group,
							label: StringHelper.beforeCapitalSpaces(
								StringHelper.getLastSplitValue(
									entityType.name,
									AppConstants.characters.period))
						});
				});

			// Set group and name if it was previously loaded.
			if (!AnyHelper.isNullOrEmpty(this.entityTypeParam))
			{
				const entityType =
					await this.entityTypeApiService
						.query(
							`Name eq '${this.entityTypeParam}'`,
							AppConstants.empty);

				this.entityTypeGroup = entityType[0].group;
				this.selectedCategory = entityType[0].group;
				this.entityInstanceApiService.entityInstanceTypeGroup =
					this.selectedCategory;
				await this.setDynamicColumns();
				this.loadingEntityType = false;
			}
		}
		else if (this.availableEntityTypes.length === 1)
		{
			this.entityTypeGroup = this.entityTypesParam;
			this.categoryOptions.push(
				{
					value: this.availableEntityTypes[0].group,
					label: StringHelper.beforeCapitalSpaces(
						StringHelper.getLastSplitValue(
							this.availableEntityTypes[0].name,
							AppConstants.characters.period))
				});
			this.entityTypeParam = this.availableEntityTypes[0].name;
			this.selectedCategory = this.availableEntityTypes[0].group;
			this.entityInstanceApiService.entityInstanceTypeGroup =
				this.selectedCategory;
			await this.setDynamicColumns();
			this.loadingEntityType = false;
		}
		else
		{
			this.entityTypeGroup = AppConstants.empty;
			this.entityTypesParam = AppConstants.empty;

			this.categoryOptions.push(
				{
					value: AppConstants.empty,
					label: AppConstants.empty
				});
		}

		if (!await this.isPageOwnershipAllowed())
		{
			EventHelper.dispatchNavigateToAccessDeniedEvent(
				this.location.path(),
				[
					'Entity.Type',
					'Entity.Version',
					'Entity.Layout'
				],
				'Access is required to at least one entity type and version. '
					+ (AnyHelper.isNullOrWhitespace(originalTypeParameter)
						? AppConstants.empty
						: `The type selection of ${originalTypeParameter} `
							+ 'did not return any available entities.'));

			return;
		}

		this.loadingCategories = false;
	}

	/**
	 * Sets the definitions for the entity search common table
	 *
	 * @memberof EntitySearchComponent
	 */
	public setupTableDefinitions(): void
	{
		this.entitySearchTableDefinitions = {
			hideExpanderArrow: true,
			actions: {
				filter: {
					considerFilteringResults: () =>
					{
						document.getElementById(
							AppConstants.commonTableActions.filterInput)
							.focus();
					}
				},
				view: {
					disabledExpandRow: true,
					items: [
						{
							command: async() =>
							{
								this.router.navigate(
									[
										await this.getContextMenuModule() +
											'/entities',
										this.entityTypeGroup,
										AppConstants.viewTypes.edit,
										this.commonTableContext.source
											.rowData.id
									],
									{
										queryParams:
										{
											routeData:
												ObjectHelper.mapRouteData(
													{
														layoutType:
															AppConstants
																.layoutTypes
																.full
													})
										}
									});
							}
						}
					]
				}
			},
			tableTitle: AppConstants.empty,
			allowSortColumns: true,
			objectSearch: {
				filter: this.entityFilterParam,
				orderBy: this.entityOrderByParam,
				offset: 0,
				limit: 30,
				virtualIndex: 0,
				virtualPageSize: this.entityRowCount,
				sortField: this.entitySortField,
				sortOrder: this.entitySortOrder
			},
			apiPromise: async(objectSearch: IObjectSearch) =>
			{
				this.entityInstanceApiService.entityInstanceTypeGroup =
						this.entityTypeGroup;

				return this.entityInstanceApiService
					.query(
						objectSearch.filter,
						objectSearch.orderBy,
						objectSearch.offset,
						objectSearch.limit);
			},
			availableColumns: this.summaryDataPathColumns,
			selectedColumns: this.selectedColumns,
			columnSelectionMode: this.columnSelectionMode,
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			filterCriteriaChanged: (filterCriteria: string) =>
			{
				// Get the query string filter
				this.entityFilterParam =
					(!AnyHelper.isNullOrEmpty(filterCriteria))
						? this.getQueryStringContainsFilter(filterCriteria)
						: AppConstants.empty;

				this.updateUrlQuery();

				// Return if null or empty
				if (AnyHelper.isNullOrEmpty(this.selectedCategory)
						|| this.selectedCategory === AppConstants.undefined)
				{
					return;
				}

				this.restoreTableDefinition();
			},
			sortCriteriaChanged: (
				sortCriteria: string,
				sortField: string,
				sortOrder: number) =>
			{
				this.entityOrderByParam = sortCriteria;
				this.entitySortField = sortField;
				this.entitySortOrder = sortOrder;

				// Restore the table definition loading state
				this.loadingTableDefinitions = true;
				setTimeout(() =>
				{
					this.setupTableDefinitions();
					this.updateUrlQuery();
				});
			},
			rowSelection: {
				selectionMode: 'single'
			},
			rowCountChanged: (rowCount: number) =>
			{
				this.entityRowCount = rowCount;
				this.restoreTableDefinition();
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.entitySearchTableDefinitions.selectedColumns =
					selectedColumns;
				this.selectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.entitySearchTableDefinitions.columnSelectionMode =
					columnSelectionMode;
				this.columnSelectionMode = columnSelectionMode;
			}
		};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Gets the context menu module.
	 *
	 * @async
	 * @returns
	 * The context menu module string name.
	 * @memberof EntitySearchComponent
	 */
	public async getContextMenuModule(): Promise<string>
	{
		return JSON.parse(
			this.entityDefinition.jsonData).contextMenuModule;
	}

	/**
	 * Updates the url query string upon entity type group
	 * and the defined query parameters previously loaded
	 * if existing.
	 *
	 * @memberof EntitySearchComponent
	 */
	public updateUrlQuery(): void
	{
		const queryParameters =
			AnyHelper.isNullOrEmpty(this.entityTypeGroup)
				? <object>
					{
						entityTypes: this.entityTypesParam
					}
				: <object>
					{
						entityTypes: this.entityTypesParam,
						entityType: this.entityTypeParam,
						filter: this.entityFilterParam,
						orderBy: this.entitySearchTableDefinitions
							.objectSearch.orderBy
					};

		this.location
			.replaceState(
				this.router
					.createUrlTree(
						[],
						<UrlCreationOptions>
						{
							replaceUrl: true,
							queryParams: {
								routeData:
									ObjectHelper.mapRouteData(queryParameters)
							}
						})
					.toString());
	}

	/**
	 * Sets the columns object array based on the available
	 * data keys.
	 *
	 * @memberof EntitySearchComponent
	 */
	public async setDynamicColumns(): Promise<void>
	{
		const entityType: IEntityType =
			await this.entityTypeApiService
				.getSingleQueryResult(
					`Group eq '${this.entityTypeGroup}'`,
					AppConstants.empty);
		this.entityDefinition =
			await this.entityDefinitionApiService
				.getSingleQueryResult(
					`TypeId eq ${entityType.id}`,
					AppConstants.empty);

		this.summaryDataPathColumns =
			await this.entityService.getDynamicSummaryColumns(
				this.entityTypeGroup,
				this.entityDefinition);

		// Start with all columns selected and in column selection mode.
		this.selectedColumns =
			this.summaryDataPathColumns;
		this.columnSelectionMode = false;
	}

	/**
	 * Handles the category selection change. This will
	 * update the view based on the selected type group.
	 *
	 * @async
	 * @param {string} selectedCategory
	 * The selected category string.
	 * @memberof EntitySearchComponent
	 */
	public async selectedCategoryChanged(
		selectedCategory: string): Promise<void>
	{
		this.selectedCategory = selectedCategory;
		// Restore the table definition loading state
		this.loadingTableDefinitions = true;
		this.loadingEntityType = true;
		this.entitySortOrder = 1;
		this.entitySortField = AppConstants.empty;

		// Set the Entity Group and Name based on the selected category
		this.entityTypeGroup = selectedCategory;
		const entityType =
			await this.entityTypeApiService
				.query(
					`Group eq '${this.entityTypeGroup}'`,
					AppConstants.empty);

		this.entityTypeParam = entityType.length > 0
			? entityType[0].name
			: AppConstants.empty;

		this.entityOrderByParam =
			`Id ${AppConstants.sortDirections.descending}`;

		this.setCategoryLabel(this.entityTypeGroup);

		// Reload the table definitions and query string
		if (AnyHelper.isNullOrEmpty(selectedCategory))
		{
			this.loadingCategories = true;
			setTimeout(() =>
			{
				this.entitySearchTableDefinitions = null;
				this.filterValue = AppConstants.empty;
				this.entityFilterParam = AppConstants.empty;
				this.loadingCategories = false;
				this.loadingTableDefinitions = false;
				this.loadingEntityType = false;
				this.updateUrlQuery();
			});
		}
		else
		{
			this.entityInstanceApiService.entityInstanceTypeGroup =
				this.selectedCategory;
			await this.setDynamicColumns();

			setTimeout(() =>
			{
				this.setupTableDefinitions();
				this.loadingEntityType = false;
				this.updateUrlQuery();
			});
		}

		if (!AnyHelper.isNull)
		{
			this.entitySearchTableDefinitions
				.objectSearch.filter = this.entityFilterParam;
		}
	}
}