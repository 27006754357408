/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ChangeDetectorRef,
	Component
} from '@angular/core';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	FormControl
} from '@angular/forms';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IBaseEntity
} from '@api/interfaces/base/base-entity.interface';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IOperationDefinitionParameter
} from '@operation/interfaces/operation-definition-parameter.interface';
import {
	IOperationGroup
} from '@operation/interfaces/operation-group.interface';
import {
	IOperationTypeParameter
} from '@operation/interfaces/operation-type-parameter.interface';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	OperationDefinitionParameterApiService
} from '@api/services/operations/operation-definition-parameter.api.service';
import {
	OperationGroupApiService
} from '@api/services/operations/operation-group.api.service';
import {
	OperationTypeParameterApiService
} from '@api/services/operations/operation-type-parameter.api.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-operation-definition',
	templateUrl: './operation-definition.component.html',
	styleUrls: [
		'./operation-definition.component.scss'
	]
})

/**
 * A component representing an instance of the
 * operation definition expand component.
 *
 * @export
 * @class OperationDefinitionComponent
 * @extends {CommonTablePageDirective}
 * @implements {IDynamicComponent<CommonTableComponent, any>}
 */
export class OperationDefinitionComponent
	extends CommonTablePageDirective
	implements IDynamicComponent<CommonTableComponent, any>
{
	/**
	 * Initializes a new instance of the OperationDefinitionComponent class.
	 *
	 * @param {OperationDefinitionParameterApiService}
	 * 	operationDefinitionParameterApiService
	 * The api service used to load operation definition parameter data.
	 * @param {OperationTypeParameterApiService}
	 * 	operationTypeParameterApiService
	 * The api service used to load operation type parameter data.
	 * @param {OperationGroupApiService} operationGroupApiService
	 * The api service used to load operation group data.
	 * @param {OperationDefinitionApiService} operationDefinitionApiService
	 * The api service used to load operation definition data.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this component to get its data.
	 * @param {ResolverService} resolver
	 * The resolver service used to handle shared logic.
	 * @param {ChangeDetectorRef} changeDetectorRef
	 * The base class used to detect tree changes.
	 * @memberof OperationDefinitionComponent
	 */
	public constructor(
		public operationDefinitionParameterApiService:
			OperationDefinitionParameterApiService,
		public operationTypeParameterApiService:
			OperationTypeParameterApiService,
		public operationGroupApiService: OperationGroupApiService,
		public operationDefinitionApiService: OperationDefinitionApiService,
		public activityService: ActivityService,
		public siteLayoutService: SiteLayoutService,
		public resolver: ResolverService,
		private readonly changeDetectorRef: ChangeDetectorRef)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the context that will be set when implementing this
	 * as a dynamic component.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof OperationDefinitionComponent
	 */
	public context: IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the table definitions for the parameters common table view.
	 *
	 * @type {ICommonTable}
	 * @memberof OperationDefinitionComponent
	 */
	public parametersTableDefinitionsView: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof OperationDefinitionComponent
	 */
	public parametersViewAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof OperationDefinitionComponent
	 */
	public parametersViewSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the column selection mode.
	 *
	 * @type {boolean}
	 * @memberof OperationDefinitionComponent
	 */
	public parametersViewColumnSelectionMode?: boolean = false;

	/**
	 * Gets or sets the table definitions for the
	 * operation groups common table view.
	 *
	 * @type {ICommonTable}
	 * @memberof OperationDefinitionComponent
	 */
	public operationGroupsTableDefinitionsView: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof OperationDefinitionComponent
	 */
	public operationGroupsViewAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof OperationDefinitionComponent
	 */
	public operationGroupsViewSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the column selection mode.
	 *
	 * @type {boolean}
	 * @memberof OperationDefinitionComponent
	 */
	public operationGroupsViewColumnSelectionMode?: boolean = false;

	/**
	 * Gets or sets the table definitions for the
	 * parameters common table update view.
	 *
	 * @type {ICommonTable}
	 * @memberof OperationDefinitionComponent
	 */
	public parametersTableDefinitionsUpdate: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof OperationDefinitionComponent
	 */
	public parametersUpdateAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof OperationDefinitionComponent
	 */
	public parametersUpdateSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the column selection mode.
	 *
	 * @type {boolean}
	 * @memberof OperationDefinitionComponent
	 */
	public parametersUpdateColumnSelectionMode?: boolean = false;

	/**
	 * Gets or sets the table definitions for the
	 * operation groups common table update view.
	 *
	 * @type {ICommonTable}
	 * @memberof OperationDefinitionComponent
	 */
	public operationGroupsTableDefinitionsUpdate: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof OperationDefinitionComponent
	 */
	public operationGroupsUpdateAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof OperationDefinitionComponent
	 */
	public operationGroupsUpdateSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the column selection mode.
	 *
	 * @type {boolean}
	 * @memberof OperationDefinitionComponent
	 */
	public operationGroupsUpdateColumnSelectionMode?: boolean = false;

	/**
	 * Gets or sets the error message displayed on the formly async validator.
	 *
	 * @type {string}
	 * @memberof OperationDefinitionComponent
	 */
	public asyncValidatorErrorMessage: string;

	/**
	 * Gets or sets the operation groups.
	 *
	 * @type {IOperationGroup[]}
	 * @memberof OperationDefinitionComponent
	 */
	public operationGroups: IOperationGroup[];

	/**
	 * Gets or sets the operation group options.
	 *
	 * @type {object[]}
	 * @memberof OperationDefinitionsComponent
	 */
	public operationGroupOptions: object[] = [];

	/**
	 * Gets or sets the operation type parameters.
	 *
	 * @type {IOperationTypeParameter[]}
	 * @memberof OperationDefinitionComponent
	 */
	public operationTypeParameters: IOperationTypeParameter[];

	/**
	 * Gets or sets the operation type parameter options.
	 *
	 * @type {object[]}
	 * @memberof OperationDefinitionsComponent
	 */
	public operationTypeParameterOptions: object[] = [];

	/**
	 * Gets or sets the loading operations definition flag.
	 *
	 * @type {boolean}
	 * @memberof OperationDefinitionComponent
	 */
	public loadingOperationDefinition: boolean = true;

	/**
	 * Gets or sets the type id.
	 *
	 * @type {number}
	 * @memberof OperationDefinitionComponent
	 */
	public typeId: number;

	/**
	 * Gets the html icon class name.
	 *
	 * @type {string}
	 * @memberof OperationDefinitionComponent
	 */
	public get htmlIconClassName (): string
	{
		return `fa fa-${this.context.data.data.icon}`;
	}

	/**
	 * Catches if there are any layout changes.
	 *
	 * @param {boolean} event
	 * The validity change.
	 * @memberof OperationDefinitionComponent
	 */
	public catchLayoutChanges(event: boolean): void
	{
		if (this.typeId !== this.context.data.data.typeId)
		{
			this.restoreTableDefinition();
		}

		this.context.source.validExpandComponentChanged(event);
	}

	/**
	 * Sets up variables used in this admin page based table.
	 *
	 * @memberof OperationDefinitionComponent
	 */
	public setupPageVariables(): void
	{
		let displayOrder: number = 1;
		this.parametersViewAvailableColumns =
			[
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'value',
					columnHeader: 'Value',
					displayOrder: displayOrder
				}
			];
		this.parametersViewSelectedColumns =
			this.parametersViewAvailableColumns;

		this.parametersUpdateAvailableColumns =
			[
				...this.parametersViewSelectedColumns
			];
		this.parametersUpdateSelectedColumns =
			this.parametersUpdateAvailableColumns;

		displayOrder = 1;
		this.operationGroupsViewAvailableColumns =
			[
				{
					dataKey: 'operationGroupName',
					columnHeader: 'Operation Group',
					displayOrder: displayOrder
				}
			];
		this.operationGroupsViewSelectedColumns =
			this.operationGroupsViewAvailableColumns;

		this.operationGroupsUpdateAvailableColumns =
			[
				...this.operationGroupsViewAvailableColumns
			];
		this.operationGroupsUpdateSelectedColumns =
			this.operationGroupsUpdateAvailableColumns;
	}

	/**
	 * Sets the available parameter options.
	 *
	 * @async
	 * @memberof OperationDefinitionComponent
	 */
	public async setAvailableParameterOptions(): Promise<void>
	{
		this.operationTypeParameterOptions = [];
		this.context.data.data.typeId =
			this.context.data.data.typeId === undefined
				? null
				: this.context.data.data.typeId;

		this.operationTypeParameters =
			<IOperationTypeParameter[]>
			await this.operationTypeParameterApiService
				.query(
					`TypeId eq ${this.context.data.data.typeId}`,
					AppConstants.empty);

		this.operationTypeParameters.forEach((operationTypeParameter) =>
		{
			this.operationTypeParameterOptions
				.push(
					{
						value: operationTypeParameter.name,
						label: operationTypeParameter.name
					});
		});
	}

	/**
	 * Sets up the table definitions for a common table
	 *
	 * @async
	 * @memberof OperationDefinitionComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		await this.setupParameterTableDefinitions();
		await this.setupOperationGroupsTableDefinitions();
		await this.getAssociatedSecurityGroup();

		this.changeDetectorRef.detectChanges();

		this.loadingTableDefinitions = false;
	}

	/**
	 * Sets up the table definitions for common operation group tables.
	 *
	 * @async
	 * @memberof OperationDefinitionComponent
	 */
	public async setupOperationGroupsTableDefinitions(): Promise<void>
	{
		this.operationGroupsTableDefinitionsView = {
			hideExpanderArrow: true,
			hideSettings: true,
			tableTitle: 'Associated Operation Groups',
			objectSearch: {
				filter: AppConstants.empty,
				orderBy: `Id ${AppConstants.sortDirections.descending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: 3
			},
			dataSetup: async() =>
			{
				await this.dataSetup();
			},
			apiPromise: async () => this.getOperationGroupParents(),
			availableColumns: this.operationGroupsViewAvailableColumns,
			selectedColumns: this.operationGroupsViewSelectedColumns,
			columnSelectionMode: this.operationGroupsViewColumnSelectionMode,
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.operationGroupsTableDefinitionsView.selectedColumns =
					selectedColumns;
				this.operationGroupsViewSelectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.operationGroupsTableDefinitionsView.columnSelectionMode =
					columnSelectionMode;
				this.operationGroupsViewColumnSelectionMode =
					columnSelectionMode;
			}
		};

		this.operationGroupsTableDefinitionsUpdate = {
			hideExpanderArrow: true,
			hideSettings: true,
			actions: {
				create: {

					displayCreateRow: false,
					layout: [
						{
							key: 'data.operationGroupId',
							type: FormlyConstants.customControls.customSelect,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								required: true,
								placeholder: 'Select an Operation Group',
								options: this.operationGroupOptions,
								showClear: true
							},
							asyncValidators: {
								uniqueOperationGroup: {
									expression: (control: FormControl) =>
										this.associatedGroup(control),
									message: 'Existing Operation Group.'
								}
							}
						}
					],
					items: [
						{
							label: 'Save',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: async() => this.createGroupAssociation()
						}],
				},
				delete: {
					deleteStatement: async () =>
						`Confirm to remove parent group association
							${this.commonTableContext.data.operationGroupName}
							from operation definition
							${this.context.data.data.name}.`,
					items: [
						{
							label: 'Remove',
							styleClass: AppConstants.cssClasses.pButtonDanger,
							command: async() => this.deleteGroupAssociation()
						}]
				}
			},
			tableTitle: 'Associated Operation Groups',
			objectSearch: {
				filter: AppConstants.empty,
				orderBy: `Id ${AppConstants.sortDirections.descending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: 3
			},
			dataSetup: async() =>
			{
				await this.dataSetup();
			},
			apiPromise: async () => this.getOperationGroupParents(),
			availableColumns: this.operationGroupsUpdateAvailableColumns,
			selectedColumns: this.operationGroupsUpdateSelectedColumns,
			columnSelectionMode: this.operationGroupsUpdateColumnSelectionMode,
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.operationGroupsTableDefinitionsUpdate.selectedColumns =
					selectedColumns;
				this.operationGroupsUpdateSelectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.operationGroupsTableDefinitionsUpdate.columnSelectionMode =
					columnSelectionMode;
				this.operationGroupsUpdateColumnSelectionMode =
					columnSelectionMode;
			}
		};
	}

	/**
	 * Sets up the table definitions for common parameter tables.
	 *
	 * @async
	 * @memberof OperationDefinitionComponent
	 */
	public async setupParameterTableDefinitions(): Promise<void>
	{
		this.typeId = this.context.data.data.typeId;

		this.parametersTableDefinitionsView = {
			hideExpanderArrow: true,
			tableTitle: 'Parameters',
			hideSettings: true,
			objectSearch: {
				filter: AppConstants.empty,
				orderBy: `Id ${AppConstants.sortDirections.descending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: 3
			},
			apiPromise: async () =>
			{
				const displayParameters: any[] = [];
				const operationTypeParameters: IOperationTypeParameter[] =
					<IOperationTypeParameter[]>
					await this.operationTypeParameterApiService
						.query(
							`typeId eq ${this.context.data.data.typeId}`,
							AppConstants.empty);

				for (const operationTypeParameter of operationTypeParameters)
				{
					const operationDefinitionFilterString =
						(!AnyHelper
							.isNullOrEmpty(
								this.parametersTableDefinitionsView
									.objectSearch.filter))
							? this.parametersTableDefinitionsView
								.objectSearch.filter
									+ 'and typeParameterId eq '
									+ operationTypeParameter.id
									+ 'and definitionId eq '
									+ this.context.data.data.id
							: 'typeParameterId eq '
									+ operationTypeParameter.id
									+ 'and definitionId eq '
									+ this.context.data.data.id;

					const operationDefinitionParameter:
						IOperationDefinitionParameter =
						await this.operationDefinitionParameterApiService
							.getSingleQueryResult(
								operationDefinitionFilterString,
								AppConstants.empty,
								true);

					if (!AnyHelper.isNull(operationDefinitionParameter))
					{
						displayParameters.unshift(
							this.mapDisplayParameter(
								operationDefinitionParameter,
								operationTypeParameter));
					}
				}

				EventHelper.dispatchTableExpansionPanelLoadedEvent();

				return displayParameters;
			},
			availableColumns: this.parametersViewAvailableColumns,
			selectedColumns: this.parametersViewSelectedColumns,
			columnSelectionMode: this.parametersViewColumnSelectionMode,
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.parametersTableDefinitionsView.selectedColumns =
					selectedColumns;
				this.parametersViewSelectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.parametersTableDefinitionsView.columnSelectionMode =
					columnSelectionMode;
				this.parametersViewColumnSelectionMode = columnSelectionMode;
			}
		};

		this.setAvailableParameterOptions();
		this.parametersTableDefinitionsUpdate = {
			hideExpanderArrow: true,
			hideSettings: true,
			actions: {
				create: {
					displayCreateRow: false,
					layout: [
						{
							key: 'data.name',
							type: FormlyConstants.customControls.customSelect,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								required: true,
								showClear: true,
								placeholder: 'Select a Parameter Type',
								options: this.operationTypeParameterOptions,
							},
							asyncValidators: {
								uniqueCombination: {
									expression: async (
										control: FormControl,
										field: FormlyFieldConfig) =>
										this.isExistingType(
											control,
											field),
									message: 'Existing Parameter.'
								}
							}
						},
						{
							key: 'data.value',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								required: true
							}
						}
					],
					definition: null,
					items: [
						{
							label: 'Save',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: async() => this.createNewParameter()
						}]
				},
				delete: {
					deleteStatement: async () =>
						`Confirm to remove parameter
							${this.commonTableContext.data.name}
							from operation definition
							${this.context.data.data.name}.`,
					items: [
						{
							label: 'Remove',
							styleClass: AppConstants.cssClasses.pButtonDanger,
							command: async() =>
							{
								await this.removeParameter();
							}
						}],
				}
			},
			tableTitle: 'Parameters',
			objectSearch: {
				filter: AppConstants.empty,
				orderBy: `Id ${AppConstants.sortDirections.descending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: 3
			},
			apiPromise: async () =>
			{
				const displayParameters: any[] = [];
				const operationTypeParameters: IOperationTypeParameter[] =
					<IOperationTypeParameter[]>
					await this.operationTypeParameterApiService
						.query(
							`typeId eq ${this.context.data.data.typeId}`,
							AppConstants.empty);

				for (const operationTypeParameter of operationTypeParameters)
				{
					const operationDefinitionFilterString = (
						!AnyHelper
							.isNullOrEmpty(
								this.parametersTableDefinitionsUpdate
									.objectSearch.filter))
						? `${this.parametersTableDefinitionsUpdate
							.objectSearch.filter} `
								+ 'and typeParameterId eq '
								+ `${operationTypeParameter.id} `
								+ 'and definitionId eq '
								+ `${this.context.data.data.id}`
						: `typeParameterId eq ${operationTypeParameter.id} `
							+ 'and definitionId eq '
							+ `${this.context.data.data.id}`;

					const operationDefinitionParameter:
						IOperationDefinitionParameter =
						await this.operationDefinitionParameterApiService
							.getSingleQueryResult(
								operationDefinitionFilterString,
								AppConstants.empty,
								true);

					if (!AnyHelper.isNull(operationDefinitionParameter))
					{
						displayParameters.unshift(
							this.mapDisplayParameter(
								operationDefinitionParameter,
								operationTypeParameter));
					}
				}

				EventHelper.dispatchTableExpansionPanelLoadedEvent();

				return displayParameters;
			},
			availableColumns: this.parametersUpdateAvailableColumns,
			selectedColumns: this.parametersUpdateSelectedColumns,
			columnSelectionMode: this.parametersUpdateColumnSelectionMode,
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.parametersTableDefinitionsUpdate.selectedColumns =
					selectedColumns;
				this.parametersUpdateSelectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.parametersTableDefinitionsUpdate.columnSelectionMode =
					columnSelectionMode;
				this.parametersUpdateColumnSelectionMode = columnSelectionMode;
			}
		};
	}

	/**
	 * Gets the security groups associated by operation
	 * definition to be displayed in the update view.
	 *
	 * @async
	 * @memberof OperationDefinitionComponent
	 */
	private async getAssociatedSecurityGroup(): Promise<void>
	{
		const securityGroups: number[] =
			await this.operationDefinitionApiService
				.getSecurityGroups(this.context.data.data.id);

		this.context.data.data.securityGroups = securityGroups;

		this.loadingOperationDefinition = false;
	}

	/**
	 * Gets the the validity for an unique
	 * data combination set.
	 *
	 * @async
	 * @param {FormControl} control
	 * The field form control.
	 * @param {FormlyFieldConfig} field
	 * The formly field configuration.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof OperationDefinitionComponent
	 */
	private async isExistingType(
		control: FormControl,
		field: FormlyFieldConfig): Promise<boolean>
	{
		const typeParameter =
			await this.operationTypeParameterApiService
				.query(
					`Name eq '${control.value}'`,
					AppConstants.empty);
		const operationDefinitionParameter =
			await this.operationDefinitionParameterApiService
				.query(
					'DefinitionId eq ' + this.context.data.data.id +
					' and TypeParameterId eq ' + typeParameter[0].id,
					AppConstants.empty);

		const persistedData =
			await this.operationDefinitionApiService
				.query(
					`Id eq ${this.context.data.data.id}`,
					AppConstants.empty);

		if (persistedData[0].typeId !== this.context.data.data.typeId)
		{
			field.asyncValidators.uniqueCombination.message =
				'Save the updated type prior.';
		}

		return Promise.resolve(
			persistedData[0].typeId === this.context.data.data.typeId
				&& operationDefinitionParameter.length === 0);
	}

	/**
	 * Creates and adds a new operation parameter
	 * associated to the operation definition in play.
	 *
	 * @async
	 * @memberof OperationDefinitionComponent
	 */
	private async createNewParameter(): Promise<void>
	{
		const typeParameterName =
			await this.operationTypeParameterApiService
				.query(
					`name eq '${this.commonTableContext.source.rowData.name}'`,
					AppConstants.empty);
		const rowData: any = this.commonTableContext.source.rowData;
		const name: string = this.context.data.data.name;

		await this.activityService.handleActivity(
			new Activity(
				this.operationDefinitionParameterApiService
					.create(
						<IBaseEntity>
						{
							definitionId: this.context.data.data.id,
							typeParameterId: typeParameterName[0].id,
							value: rowData.value
						}),
				`<strong>Adding Parameter ${rowData.name} `
					+ `to Operation Definition ${name}</strong>`,
				`<strong>Added Parameter ${rowData.name} `
					+ `to Operation Definition ${name}</strong>`,
				`Parameter ${rowData.name} was added to `
					+ `Operation Definition ${name}.`,
				`Parameter ${rowData.name} was not added to `
					+ `Operation Definition ${name}.`));

		this.restoreTableDefinition();
	}

	/**
	 * Removes an operation parameter
	 * associated to the operation definition in play.
	 *
	 * @async
	 * @memberof OperationDefinitionComponent
	 */
	private async removeParameter(): Promise<void>
	{
		const rowData: any = this.commonTableContext.source.rowData;
		const name: string = this.context.data.data.name;

		await this.activityService.handleActivity(
			new Activity(
				this.operationDefinitionParameterApiService
					.delete(
						this.commonTableContext.source.rowData.id),
				`<strong>Removing Parameter ${rowData.name} `
					+ `from Operation Definition ${name}</strong>`,
				`<strong>Removed Parameter ${rowData.name} `
					+ `from Operation Definition ${name}</strong>`,
				`Parameter ${rowData.name} `
					+ 'was removed from Operation Definition '
					+ `${name}.`,
				`Parameter ${rowData.name} `
					+ 'was not removed from Operation Definition '
					+ `${name}.`));

		this.restoreTableDefinition();
	}

	/**
	 * Adds an operation group
	 * associated as the parent of an
	 * operation definition.
	 *
	 * @async
	 * @memberof OperationDefinitionComponent
	 */
	private async createGroupAssociation(): Promise<void>
	{
		const rowData: any = this.commonTableContext.source.rowData;
		const name: string = this.context.data.data.name;

		await this.activityService.handleActivity(
			new Activity(
				this.operationGroupApiService
					.createChild(
						this.commonTableContext.source.rowData.operationGroupId,
						{
							id: this.context.data.data.id,
							order: 0,
							type: 'OperationDefinition'
						}),
				`<strong>Adding</strong> Group Association
					${rowData.operationGroupName}
					to Operation Definition ${name}`,
				'<strong>Added</strong> Group Association '
					+ `${rowData.operationGroupName}`
					+ ` to Operation Definition ${name}`,
				'Group Association '
					+ `${rowData.operationGroupName}`
					+ ' was added to Operation Definition '
					+ `${name}.`,
				'Group Association '
					+ `${rowData.operationGroupName}`
					+ 'was not added to Operation Definition'
					+ `${name}.`));

		this.restoreTableDefinition();
	}

	/**
	 * Removes an operation group
	 * associated as the parent of an
	 * operation definition.
	 *
	 * @async
	 * @memberof OperationDefinitionComponent
	 */
	private async deleteGroupAssociation(): Promise<void>
	{
		await this.activityService.handleActivity(
			new Activity(
				this.operationGroupApiService
					.deleteChild(
						this.commonTableContext.source.rowData.operationGroupId,
						{
							id: this.context.data.data.id,
							type: 'OperationDefinition'
						}),
				`<strong>Removing</strong> Parent Group Association
					${this.commonTableContext.source.rowData.operationGroupName}
					from Operation Definition ${this.context.data.data.name}`,
				`<strong>Removed</strong> Parent Group Association
					${this.commonTableContext.source.rowData.operationGroupName}
					from Operation Definition ${this.context.data.data.name}`,
				`Parent Group Association
					${this.commonTableContext.source.rowData.operationGroupName}
					was removed from Operation Definition
					${this.context.data.data.name}.`,
				`Parent Group Association
					${this.commonTableContext.source.rowData.operationGroupName}
					was not removed from Operation Definition
					${this.context.data.data.name}.`));

		this.restoreTableDefinition();
	}

	/**
	 * Validates if there is any
	 * associated Operation Group existing.
	 *
	 * @async
	 * @param {FormControl} control
	 * The field form control.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof OperationDefinitionComponent
	 */
	private async associatedGroup(control: FormControl): Promise<boolean>
	{
		let isNotExisting = true;

		const childOperationGroupHierarchies =
			await this.operationGroupApiService
				.query(
					'ChildOperationGroupHierarchies.Any(ChildDefinitionId eq ' +
						this.context.data.data.id + ')',
					AppConstants.empty);

		childOperationGroupHierarchies.forEach((operationGroup: IBaseEntity) =>
		{
			if (control.value === operationGroup.id)
			{
				isNotExisting = false;
			}
		});

		return Promise.resolve(isNotExisting);
	}

	/**
	 * Maps a Display Parameter object.
	 *
	 * @param {IOperationDefinitionParameter} operationDefinitionParameter
	 * The operation definition parameter.
	 * @param {IOperationTypeParameter} operationTypeParameter
	 * The operation type parameter.
	 * @returns {any[]}
	 * The display parameter.
	 * @memberof OperationDefinitionComponent
	 */
	private mapDisplayParameter(
		operationDefinitionParameter: IOperationDefinitionParameter,
		operationTypeParameter: IOperationTypeParameter): any
	{
		return <any> {
			id: operationDefinitionParameter.id,
			name: operationTypeParameter.name,
			value: operationDefinitionParameter.value
		};
	}

	/**
	 * Sets up the data needed for the common table display.
	 *
	 * @async
	 * @memberof OperationDefinitionComponent
	 */
	private async dataSetup(): Promise<void>
	{
		this.operationGroups =
			<IOperationGroup[]>
			await this.operationGroupApiService
				.query(
					AppConstants.empty,
					AppConstants.empty,
					null,
					100);

		this.operationGroups.forEach((operationGroup) =>
		{
			this.operationGroupOptions
				.push(
					{
						value: operationGroup.id,
						label: operationGroup.name
					});
		});
	}

	/**
	 * Gets operation group parents.
	 *
	 * @async
	 * @memberof OperationDefinitionComponent
	 */
	private async getOperationGroupParents(): Promise<any[]>
	{
		const operationGroupsParents: any[] = [];
		const childOperationGroupHierarchies =
			await this.operationGroupApiService
				.query(
					'ChildOperationGroupHierarchies.Any('
						+ 'ChildDefinitionId eq '
						+ this.context.data.data.id + ')',
					AppConstants.empty);

		childOperationGroupHierarchies
			.forEach((childOperationGroupHierarchy) =>
			{
				operationGroupsParents.push(
					{
						id: childOperationGroupHierarchy.id,
						operationGroupId:
									childOperationGroupHierarchy.id,
						operationGroupName:
									childOperationGroupHierarchy.name
					}
				);
			});

		return operationGroupsParents;
	}
}