<p-password inputStyleClass="password-input"
	[formControl]="formControl"
	[formlyAttributes]="field"
	[toggleMask]="enableMaskToogle">
	<ng-template pTemplate="header">
		<div class="panel-sub-title">Pick a password</div>
	</ng-template>
	<ng-template pTemplate="footer">
		<p class="suggestions-title">Suggestions</p>
		<ul class="suggestions-section">
			<li>At least one lowercase</li>
			<li>At least one uppercase</li>
			<li>At least one numeric</li>
			<li>Minimum 8 characters</li>
		</ul>
	</ng-template>
</p-password>

<field-validation-message
	[field]="field">
</field-validation-message>