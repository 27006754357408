/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */

import {
	AuthenticateApiService
} from '@api/services/security/authenticate.api.service';
import {
	DisplayComponentDefinitionApiService
} from '@api/services/display-components/display-component-definition.api.service';
import {
	DisplayComponentInstanceApiService
} from '@api/services/display-components/display-component-instance.api.service';
import {
	DisplayComponentTypeApiService
} from '@api/services/display-components/display-component-type.api.service';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityInstanceRuleViolationApiService
} from '@api/services/entities/entity-instance-rule-violation.api.service';
import {
	EntityInstanceRuleViolationOverrideApiService
} from '@api/services/entities/entity-instance-rule-violation-override.api.service';
import {
	EntityLayoutApiService
} from '@api/services/entities/entity-layout.api.service';
import {
	EntityLayoutTypeApiService
} from '@api/services/entities/entity-layout-type.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EntityVersionApiService
} from '@api/services/entities/entity-version.api.service';
import {
	InjectionToken
} from '@angular/core';
import {
	LogApiService
} from '@api/services/logs/log.api.service';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	OperationDefinitionParameterApiService
} from '@api/services/operations/operation-definition-parameter.api.service';
import {
	OperationGroupApiService
} from '@api/services/operations/operation-group.api.service';
import {
	OperationGroupHierarchyApiService
} from '@api/services/operations/operation-group-hierarchy.api.service';
import {
	OperationTypeApiService
} from '@api/services/operations/operation-type.api.service';
import {
	OperationTypeParameterApiService
} from '@api/services/operations/operation-type-parameter.api.service';
import {
	RuleDefinitionApiService
} from '@api/services/rules/rule-definition.api.service';
import {
	RulePresentationDefinitionApiService
} from '@api/services/rules/rule-presentation-definition.api.service';
import {
	RulePresentationLogicDefinitionApiService
} from '@api/services/rules/rule-presentation-logic-definition.api.service';
import {
	RuleValidationDefinitionApiService
} from '@api/services/rules/rule-validation-definition.api.service';
import {
	RuleValidatorDefinitionApiService
} from '@api/services/rules/rule-validator-definition.api.service';
import {
	RuleViolationWorkflowActionDefinitionApiService
} from '@api/services/rules/rule-violation-workflow-action-definition.api.service';
import {
	SecurityAccessPolicyApiService
} from '@api/services/security/security-access-policy.api.service';
import {
	SecurityAccessPolicyDefinitionApiService
} from '@api/services/security/security-access-policy-definition.api.service';
import {
	SecurityAccessPolicyMappingApiService
} from '@api/services/security/security-access-policy-mapping.api.service';
import {
	SecurityApplicationApiService
} from '@api/services/security/security-application.api.service';
import {
	SecurityGroupApiService
} from '@api/services/security/security-group.api.service';
import {
	SecurityGroupRuleDefinitionViolationOverrideApiService
} from '@api/services/security/security-group-rule-definition-violation-override.api.service';
import {
	SecuritySessionApiService
} from '@api/services/security/security-session.api.service';
import {
	WorkflowActionDefinitionsApiService
} from '@api/services/workflow/workflow-action-definitions.api.service';
import {
	WorkflowActionInstancesApiService
} from '@api/services/workflow/workflow-action-instances.api.service';
import {
	WorkflowActionRequisitesApiService
} from '@api/services/workflow/workflow-action-requisites.api.service';
import {
	WorkflowFailureActionsApiService
} from '@api/services/workflow/workflow-failure-actions.api.service';
import {
	WorkflowRequisiteTypesApiService
} from '@api/services/workflow/workflow-requisite-types.api.service';

/* eslint-enable max-len */

/*
 * Export all tokens to be used in dependency injector for
 * string based resolution.
*/
export const AuthenticateApiServiceToken =
	new InjectionToken<AuthenticateApiService>(
		'AuthenticateApiService');
export const DisplayComponentDefinitionApiServiceToken =
	new InjectionToken<DisplayComponentDefinitionApiService>(
		'DisplayComponentDefinitionApiService');
export const DisplayComponentInstanceApiServiceToken =
	new InjectionToken<DisplayComponentInstanceApiService>(
		'DisplayComponentInstanceApiService');
export const DisplayComponentTypeApiServiceToken =
	new InjectionToken<DisplayComponentTypeApiService>(
		'DisplayComponentTypeApiService');
export const EntityDefinitionApiServiceToken =
	new InjectionToken<EntityDefinitionApiService>(
		'EntityDefinitionApiService');
export const EntityInstanceApiServiceToken =
	new InjectionToken<EntityInstanceApiService>(
		'EntityInstanceApiService');
export const EntityInstanceRuleViolationApiServiceToken =
	new InjectionToken<EntityInstanceRuleViolationApiService>(
		'EntityInstanceRuleViolationApiService');
export const EntityInstanceRuleViolationOverrideApiServiceToken =
	new InjectionToken<EntityInstanceRuleViolationOverrideApiService>(
		'EntityInstanceRuleViolationOverrideApiService');
export const EntityLayoutApiServiceToken =
	new InjectionToken<EntityLayoutApiService>(
		'EntityLayoutApiService');
export const EntityLayoutTypeApiServiceToken =
	new InjectionToken<EntityLayoutTypeApiService>(
		'EntityLayoutTypeApiService');
export const EntityTypeApiServiceToken =
	new InjectionToken<EntityTypeApiService>(
		'EntityTypeApiService');
export const EntityVersionApiServiceToken =
	new InjectionToken<EntityVersionApiService>(
		'EntityVersionApiService');
export const LogApiServiceToken =
	new InjectionToken<LogApiService>(
		'LogApiService');
export const OperationDefinitionApiServiceToken =
	new InjectionToken<OperationDefinitionApiService>(
		'OperationDefinitionApiService');
export const OperationDefinitionParameterApiServiceToken =
	new InjectionToken<OperationDefinitionParameterApiService>(
		'OperationDefinitionParameterApiService');
export const OperationGroupApiServiceToken =
	new InjectionToken<OperationGroupApiService>(
		'OperationGroupApiService');
export const OperationGroupHierarchyApiServiceToken =
	new InjectionToken<OperationGroupHierarchyApiService>(
		'OperationGroupHierarchyApiService');
export const OperationTypeApiServiceToken =
	new InjectionToken<OperationTypeApiService>(
		'OperationTypeApiService');
export const OperationTypeParameterApiServiceToken =
	new InjectionToken<OperationTypeParameterApiService>(
		'OperationTypeParameterApiService');
export const RuleDefinitionApiServiceToken =
	new InjectionToken<RuleDefinitionApiService>(
		'RuleDefinitionApiService');
export const RulePresentationDefinitionApiServiceToken =
	new InjectionToken<RulePresentationDefinitionApiService>(
		'RulePresentationDefinitionApiService');
export const RulePresentationLogicDefinitionApiServiceToken =
	new InjectionToken<RulePresentationLogicDefinitionApiService>(
		'RulePresentationLogicDefinitionApiService');
export const RuleValidationDefinitionApiServiceToken =
	new InjectionToken<RuleValidationDefinitionApiService>(
		'RuleValidationDefinitionApiService');
export const RuleValidatorDefinitionApiServiceToken =
	new InjectionToken<RuleValidatorDefinitionApiService>(
		'RuleValidatorDefinitionApiService');
export const RuleViolationWorkflowActionDefinitionApiServiceToken =
	new InjectionToken<RuleViolationWorkflowActionDefinitionApiService>(
		'RuleViolationWorkflowActionDefinitionApiService');
export const SecurityApplicationApiServiceToken =
	new InjectionToken<SecurityApplicationApiService>(
		'SecurityApplicationApiService');
export const SecurityAccessPolicyMappingApiServiceToken =
	new InjectionToken<SecurityAccessPolicyMappingApiService>(
		'SecurityAccessPolicyMappingApiService');
export const SecurityAccessPolicyDefinitionApiServiceToken =
	new InjectionToken<SecurityAccessPolicyDefinitionApiService>(
		'SecurityAccessPolicyDefinitionApiService');
export const SecurityAccessPolicyApiServiceToken =
	new InjectionToken<SecurityAccessPolicyApiService>(
		'SecurityAccessPolicyApiService');
export const SecurityGroupApiServiceToken =
	new InjectionToken<SecurityGroupApiService>(
		'SecurityGroupApiService');
export const SecurityGroupRuleDefinitionViolationOverrideApiServiceToken =
	new InjectionToken<SecurityGroupRuleDefinitionViolationOverrideApiService>(
		'SecurityGroupRuleDefinitionViolationOverrideApiService');
export const SecuritySessionApiServiceToken =
	new InjectionToken<SecuritySessionApiService>(
		'SecuritySessionApiService');
export const WorkflowActionDefinitionsApiServiceToken =
	new InjectionToken<WorkflowActionDefinitionsApiService>(
		'WorkflowActionDefinitionsApiService');
export const WorkflowActionInstancesApiServiceToken =
	new InjectionToken<WorkflowActionInstancesApiService>(
		'WorkflowActionInstancesApiService');
export const WorkflowActionRequisitesApiServiceToken =
	new InjectionToken<WorkflowActionRequisitesApiService>(
		'WorkflowActionRequisitesApiService');
export const WorkflowFailureActionsApiServiceToken =
	new InjectionToken<WorkflowFailureActionsApiService>(
		'WorkflowFailureActionsApiService');
export const WorkflowRequisiteTypesApiServiceToken =
	new InjectionToken<WorkflowRequisiteTypesApiService>(
		'WorkflowRequisiteTypesApiService');

/**
 * A module used to defined available api methods.
 *
 * @export
 * @class ApiTokenLookup
 */
export class ApiTokenLookup
{
	/**
	 * Dictionary to be used in dependency injector for
	 * string based api service resolution.
	 *
	 * @static
	 * @memberof ApiTokenLookup
	 */
	public static tokens =
	{
		'AuthenticateApiService':
			AuthenticateApiServiceToken,
		'DisplayComponentDefinitionApiService':
			DisplayComponentDefinitionApiServiceToken,
		'DisplayComponentInstanceApiService':
			DisplayComponentInstanceApiServiceToken,
		'DisplayComponentTypeApiService':
			DisplayComponentTypeApiServiceToken,
		'EntityDefinitionApiService':
			EntityDefinitionApiServiceToken,
		'EntityInstanceApiService':
			EntityInstanceApiServiceToken,
		'EntityInstanceRuleViolationOverrideApiService':
			EntityInstanceRuleViolationOverrideApiServiceToken,
		'EntityInstanceRuleViolationApiService':
			EntityInstanceRuleViolationApiServiceToken,
		'EntityLayoutApiService':
			EntityLayoutApiServiceToken,
		'EntityLayoutTypeApiService':
			EntityLayoutTypeApiServiceToken,
		'EntityTypeApiService':
			EntityTypeApiServiceToken,
		'EntityVersionApiService':
			EntityVersionApiServiceToken,
		'LogApiService':
			LogApiServiceToken,
		'OperationDefinitionApiService':
			OperationDefinitionApiServiceToken,
		'OperationDefinitionParameterApiService':
			OperationDefinitionParameterApiServiceToken,
		'OperationGroupApiService':
			OperationGroupApiServiceToken,
		'OperationGroupHierarchyApiService':
			OperationGroupHierarchyApiServiceToken,
		'OperationTypeApiService':
			OperationTypeApiServiceToken,
		'OperationTypeParameterApiService':
			OperationTypeParameterApiServiceToken,
		'RuleDefinitionApiService':
			RuleDefinitionApiServiceToken,
		'RulePresentationDefinitionApiService':
			RulePresentationDefinitionApiServiceToken,
		'RulePresentationLogicDefinitionApiService':
			RulePresentationLogicDefinitionApiServiceToken,
		'RuleValidationDefinitionApiService':
			RuleValidationDefinitionApiServiceToken,
		'RuleValidatorDefinitionApiService':
			RuleValidatorDefinitionApiServiceToken,
		'RuleViolationWorkflowActionDefinitionApiService':
			RuleViolationWorkflowActionDefinitionApiServiceToken,
		'SecurityApplicationApiService':
			SecurityApplicationApiServiceToken,
		'SecurityAccessPolicyMappingApiService':
			SecurityAccessPolicyMappingApiServiceToken,
		'SecurityAccessPolicyDefinitionApiService':
			SecurityAccessPolicyDefinitionApiServiceToken,
		'SecurityAccessPolicyApiService':
			SecurityAccessPolicyApiServiceToken,
		'SecurityGroupApiService':
			SecurityGroupApiServiceToken,
		'SecurityGroupRuleDefinitionViolationOverrideApiService':
			SecurityGroupRuleDefinitionViolationOverrideApiServiceToken,
		'SecuritySessionApiService':
			SecuritySessionApiServiceToken,
		'WorkflowActionDefinitionsApiService':
			WorkflowActionDefinitionsApiServiceToken,
		'WorkflowActionInstancesApiService':
			WorkflowActionInstancesApiServiceToken,
		'WorkflowActionRequisitesApiService':
			WorkflowActionRequisitesApiServiceToken,
		'WorkflowFailureActionsApiService':
			WorkflowFailureActionsApiServiceToken,
		'WorkflowRequisiteTypesApiService':
			WorkflowRequisiteTypesApiServiceToken
	};
}