/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ActivatedRoute,
	Params
} from '@angular/router';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
} from '@angular/core';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityManagerDirective
} from '@admin/directives/entity-manager.directive';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	FormControl
} from '@angular/forms';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IRuleDefinitionDto
} from '@api/interfaces/rules/rule-definition.dto.interface';
import {
	IRulePresentationDefinition
} from '@shared/interfaces/rules/rule-presentation-definition.interface';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	RuleDefinitionApiService
} from '@api/services/rules/rule-definition.api.service';
import {
	RulePresentationDefinitionApiService
} from '@api/services/rules/rule-presentation-definition.api.service';
import {
	RulePresentationLogicDefinitionApiService
} from '@api/services/rules/rule-presentation-logic-definition.api.service';
import {
	RulePresentationService
} from '@shared/services/rule-presentation.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

@Component({
	selector: 'app-entity-rule-presentation',
	templateUrl: './entity-rule-presentation.component.html',
	styleUrls: [
		'./entity-rule-presentation.component.scss'
	]
})

/**
 * A component representing an instance of the entity rule presentation
 * component.
 *
 * @export
 * @class EntityRulePresentationComponent
 * @extends {EntityManagerDirective}
 */
export class EntityRulePresentationComponent
	extends EntityManagerDirective
{
	/**
	 * Creates an instance of an EntityRulePresentationComponent.
	 *
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to get the entity type data.
	 * @param {EntityDefinitionApiService} entityDefinitionApiService
	 * The api service used to get the entity definition data.
	 * @param {RuleDefinitionApiService} ruleDefinitionApiService
	 * The api service used to get rule definition data.
	 * @param {RulePresentationLogicDefinitionApiService}
	 * rulePresentationLogicDefinitionApiService
	 * The api service used to get rule presentation logic definition data.
	 * @param {RulePresentationDefinitionApiService}
	 * rulePresentationDefinitionApiService
	 * The api service used to get rule presentation definition data.
	 * @param {RulePresentationService} rulePresentationService
	 * The rule presentation service used to get event type data.
	 * @param {SiteLayoutService} siteLayoutService
	 * The service used to get the site layout data.
	 * @param {ActivatedRoute} route
	 * The activated route that opened this component.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof EntityRulePresentationComponent
	 */
	public constructor(
		public entityTypeApiService: EntityTypeApiService,
		public entityDefinitionApiService: EntityDefinitionApiService,
		public ruleDefinitionApiService: RuleDefinitionApiService,
		public rulePresentationLogicDefinitionApiService:
			RulePresentationLogicDefinitionApiService,
		public rulePresentationDefinitionApiService:
			RulePresentationDefinitionApiService,
		public rulePresentationService: RulePresentationService,
		public siteLayoutService: SiteLayoutService,
		public route: ActivatedRoute,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(
			route,
			activityService,
			resolver);
	}

	/**
	 * Gets or sets the rule definition.
	 *
	 * @type {IRuleDefinitionDto}
	 * @memberof EntityRulePresentationComponent
	 */
	public ruleDefinition: IRuleDefinitionDto;

	/**
	 * Gets or sets the rule definition.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof EntityRulePresentationComponent
	 */
	public eventTypeOptions: IDropdownOption[];

	/**
	 * Gets or sets the rule presentation definition.
	 *
	 * @type {IRulePresentationDefinition}
	 * @memberof EntityRulePresentationComponent
	 */
	public rulePresentationDefinition: IRulePresentationDefinition;

	/**
	 * Gets or sets the rule presentation definition id.
	 *
	 * @type {number}
	 * @memberof EntityRulePresentationComponent
	 */
	public rulePresentationDefinitionId: number;

	/**
	 * Sets the rule presentation definition id query parameter.
	 *
	 * @type {string}
	 * @memberof EntityRulePresentationComponent
	 */
	private readonly rulePresentationDefinitionIdQueryParameter: string =
		AppConstants.commonProperties.rulePresentationDefinitionId;

	/**
	 * Sets the context data required for this component.
	 *
	 * @async
	 * @memberof EntityRulePresentationComponent
	 */
	public async setContextData(): Promise<void>
	{
		this.subscriptions.add(
			this.route.queryParams.subscribe((parameters: Params) =>
			{
				const mappedRouteData: any =
						ObjectHelper.mapFromRouteData(
							parameters);

				this.rulePresentationDefinitionId =
						mappedRouteData[
							this.rulePresentationDefinitionIdQueryParameter];
			}));

		this.entityDefinitionId = this.route.snapshot.paramMap.get(
			AppConstants.commonProperties.id) as unknown as number;

		this.entityDefinition =
			await this.entityDefinitionApiService
				.get(this.entityDefinitionId);

		this.entityType =
			await this.entityTypeApiService
				.get(this.entityDefinition.typeId);

		this.rulePresentationDefinition =
			<IRulePresentationDefinition>
			await this.rulePresentationDefinitionApiService
				.get(this.rulePresentationDefinitionId);

		this.ruleDefinition =
			await this.ruleDefinitionApiService
				.get(this.rulePresentationDefinition.definitionId);

		this.eventTypeOptions = [
			{
				label: this.rulePresentationService.eventTypes.onChange,
				value: this.rulePresentationService.eventTypes.onChange
			},
			{
				label:
					this.rulePresentationService.eventTypes.onChangeValidation,
				value:
					this.rulePresentationService.eventTypes.onChangeValidation
			},
			{
				label:
					this.rulePresentationService.eventTypes
						.onChangeValidationAsync,
				value:
					this.rulePresentationService.eventTypes
						.onChangeValidationAsync
			}
		];

		this.contextData = {
			data: {
				id: this.rulePresentationDefinition.id,
				presentationLogicName:
					(await this.rulePresentationLogicDefinitionApiService
						.get(this.rulePresentationDefinition
							.presentationLogicDefinitionId)).name,
				dataKey: this.rulePresentationDefinition.dataKey,
				eventType: this.rulePresentationDefinition.eventType,
				order: this.rulePresentationDefinition.order,
				definition: this.rulePresentationDefinition.jsonData
			}
		};

		this.saveTitle = 'Rule Presentation';
		this.saveContent = `${this.ruleDefinition.name} Rule Presentation`;
	}

	/**
	 * Excecutes the save action.
	 *
	 * @async
	 * @memberof EntityRulePresentationComponent
	 */
	public async saveAction(): Promise<void>
	{
		const rulePresentationDataObject: IRulePresentationDefinition =
			<IRulePresentationDefinition>
			{
				id: this.rulePresentationDefinition.id,
				definitionId: this.rulePresentationDefinition.definitionId,
				presentationLogicDefinitionId:
					this.rulePresentationDefinition
						.presentationLogicDefinitionId,
				dataKey: this.contextData.data.dataKey,
				eventType: this.contextData.data.eventType,
				order: this.contextData.data.order,
				jsonData: this.contextData.data.definition,
			};

		await this.rulePresentationDefinitionApiService
			.update(
				this.rulePresentationDefinition.id,
				rulePresentationDataObject);
	}

	/**
	 * Sets the formly layout fields.
	 *
	 * @async
	 * @memberof EntityRulePresentationComponent
	 */
	public async setLayoutFields(): Promise<void>
	{
		this.layoutFields =
			<FormlyFieldConfig[]>
			[
				{
					key: 'data.presentationLogicName',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Name',
						disabled: true
					}
				},
				{
					key: 'data.dataKey',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Data Key'
					},
					validators: {
						validDataKey: {
							expression: ((
								control: FormControl) =>
								this.dataKeyValidator(
									control)),
							message:
								'Must start with \'data.\''
						}
					}
				},
				{
					key: 'data.eventType',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Event Type',
						required: true,
						options: this.eventTypeOptions
					}
				},
				{
					key: 'data.order',
					type: FormlyConstants.customControls.customInputNumber,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Order',
						required: true,
						multipleOf: 10
					},
					asyncValidators: {
						uniqueOrder: {
							expression: (control: FormControl) =>
								this.uniqueOrder(
									control),
							message: 'Existing Order.'
						}
					}
				},
				{
					key: 'data.definition',
					type: FormlyConstants.customControls.customTextArea,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Definition',
						required: true,
						rows: FormlyConstants.textAreaRowSizes.large
					},
					validators: {
						validDefinition: {
							expression: ((
								control: FormControl,
								field: FormlyFieldConfig) =>
								this.definitionValidator(
									control,
									field)),
							message: AppConstants.empty
						}
					}
				}
			];
	}

	/**
	 * Validates the definition is a correct input.
	 *
	 * @param {FormControl} control
	 * The form control to get the input value.
	 * @param {FormlyFieldConfig} field
	 * The formly field configuration.
	 * @returns {boolean}
	 * The validation passed or failed.
	 * @memberof EntityLayoutDefinitionComponent
	 */
	private definitionValidator(
		control: FormControl,
		field: FormlyFieldConfig): boolean
	{
		// Checks if entry is an object type.
		try
		{
			JSON.parse(control.value);
		}
		catch
		{
			field.validators.validDefinition.message =
				'Not a valid Definition Object.';

			return false;
		}

		return true;
	}

	/**
	 * Validates if the requisite order is unique.
	 *
	 * @param {FormControl} control
	 * The field form control.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof EntityActionDefinitionComponent
	 */
	private dataKeyValidator(
		control: FormControl): RegExpMatchArray
	{
		return control.value.match(/(^(data.))/g);
	}

	/**
	 * Validates if the requisite order is unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The field form control.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof EntityActionDefinitionComponent
	 */
	private async uniqueOrder(
		control: FormControl): Promise<boolean>
	{
		const items: IRulePresentationDefinition[] =
			<IRulePresentationDefinition[]>
			await this.rulePresentationDefinitionApiService
				.query(
					`id neq ${this.rulePresentationDefinition.id}`
						+ ` and order eq ${control.value}`
						+ ' and definitionId eq'
						+ ` ${this.ruleDefinition.id}`,
					AppConstants.empty);

		return Promise.resolve(items.length <= 0);
	}
}