/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	OnInit
} from '@angular/core';
import {
	IAssociatedEntityListContext
} from '@shared/interfaces/dynamic-interfaces/associated-entity-list-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IInsuranceKeyContacts
} from '@insurance/interfaces/insurance-key-contacts.interface';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/* eslint-enable max-len */

@Component({
	selector: 'app-associated-key-contacts',
	templateUrl: './associated-key-contacts.component.html',
	styleUrls: [
		'./associated-key-contacts.component.scss'
	]
})

/**
 * A component representing an associated key contact display used
 * in the associated entity list.
 *
 * @export
 * @class AssociatedKeyContactsComponent
 * @implements {IDynamicComponent<Component, {
		keyContacts: IInsuranceKeyContacts,
		entityListContext: IAssociatedEntityListContext
	}>}
 */
export class AssociatedKeyContactsComponent
implements IDynamicComponent<Component, {
		keyContacts: IInsuranceKeyContacts;
		entityListContext: IAssociatedEntityListContext;
	}>, OnInit
{
	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, IInsuranceKeyContacts>}
	 * @memberof AssociatedKeyContactsComponent
	 */
	public context: IDynamicComponentContext<Component, {
			keyContacts: IInsuranceKeyContacts;
			entityListContext: IAssociatedEntityListContext;
		}>;

	/**
	 * Initiates the component to get the data object.
	 *
	 * @memberof AssociatedKeyContactsComponent
	 */
	public ngOnInit(): void
	{
		if (AnyHelper.isNull(
			(<any>this.context.data).entityInstance))
		{
			return;
		}

		const keyContacts: IInsuranceKeyContacts =
			(<any>this.context.data).entityInstance;
		this.context.data.keyContacts =
				<any>
				{
					primaryInsured: {
						name:
							StringHelper.toNameString(
								keyContacts.primaryInsured.characteristics
									.name.firstName,
								keyContacts.primaryInsured.characteristics
									.name.lastName),
						type:
							keyContacts.primaryInsured.subType,
						phone:
							keyContacts.primaryInsured.characteristics
								.phones.length > 0
								? keyContacts.primaryInsured.characteristics
									.phones[0].number || AppConstants.empty
								: AppConstants.empty,
						email:
							keyContacts.primaryInsured.characteristics
								.emails.length > 0
								? keyContacts.primaryInsured.characteristics
									.emails[0].address || AppConstants.empty
								: AppConstants.empty,
						address:
							keyContacts.primaryInsured.characteristics
								.addresses.length > 0
								? StringHelper.toAddressString(
									keyContacts.primaryInsured.characteristics
										.addresses[0].address,
									keyContacts.primaryInsured.characteristics
										.addresses[0].city,
									keyContacts.primaryInsured.characteristics
										.addresses[0].state,
									keyContacts.primaryInsured.characteristics
										.addresses[0].postalCode)
								: AppConstants.empty
					},
					agencyInformation: {
						name:
							StringHelper.toNameString(
								keyContacts.producer.data.firstName,
								keyContacts.producer.data.lastName),
						agency:
							keyContacts.agency.data.name.legalName
								|| AppConstants.empty,
						phone:
							keyContacts.producer.data.phone
								|| AppConstants.empty,
						email:
							keyContacts.producer.data.email
								|| AppConstants.empty,
						address:
							keyContacts.agency.data
								.addresses.length > 0
								? StringHelper.toAddressString(
									keyContacts.agency.data
										.addresses[0].address,
									keyContacts.agency.data
										.addresses[0].city,
									keyContacts.agency.data
										.addresses[0].state,
									keyContacts.agency.data
										.addresses[0].postalCode)
								: AppConstants.empty
					}
				};
	}
}
