/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 * A class representing static constant and read only values for reports.
 *
 * @export
 * @class ReportConstants
 */
export class ReportConstants
{
	public static readonly externalReportTypes:
	{
		powerBi: string;
	} = {
		powerBi: 'powerBi'
	};

	public static readonly powerBiEmbedEndpoint: string =
		'https://app.powerbi.com';

	public static readonly powerBiDefaults:
	{
		pageName: string;
	} = {
		pageName: 'ReportSection'
	};

	public static readonly powerBiObjectTypes:
	{
		dashboard: string;
		dataset: string;
		group: string;
		page: string;
		report: string;
		tile: string;
		visual: string;
	} = {
		dashboard: 'dashboard',
		dataset: 'dataset',
		group: 'group',
		page: 'page',
		report: 'report',
		tile: 'tile',
		visual: 'visual'
	};

	public static readonly powerBiWorkspaceTypes:
	{
		custom: string;
		standard: string;
	} = {
		custom: 'Custom',
		standard: 'Standard'
	};
}