/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	BaseEntityApiService
} from '@api/services/base/base-entity.api.service';
import {
	Component,
	Directive,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	ICommonListContext
} from '@shared/interfaces/dynamic-interfaces/common-list-context.interface';
import {
	ICommonListFilter
} from '@shared/interfaces/dynamic-interfaces/common-list-filter.interface';
import {
	ICommonListItem
} from '@shared/interfaces/dynamic-interfaces/common-list-item.interface';
import {
	ICommonListSort
} from '@shared/interfaces/dynamic-interfaces/common-list-sort.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';

/* eslint-enable max-len */

@Directive({
	selector: '[CommonListDirective]'
})

/**
 * A class representing the common code for a component displaying a list.
 *
 * @export
 * @class CommonListDirective
 * @typeparam {TEntity} The interface type that will be displayed in this
 * common list.
 * @typeparam {TEntityDto} The public dto type that will be displayed in this
 * common list.
 * @implements {OnInit}
 */
export class CommonListDirective<TEntity, TEntityDto> implements OnInit
{
	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof CommonListDirective
	 */
	@Input() public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the change selected item event emitter.
	 *
	 * @type {EventEmitter<TEntity>}
	 * @memberof CommonListDirective
	 */
	@Output() public changeSelectedItem: EventEmitter<TEntity> =
		new EventEmitter();

	/**
	 * Gets or sets the navigate event
	 *
	 * @type {EventEmitter<string>}
	 * @memberof CommonListDirective
	 */
	@Output() public changeDisplayMode: EventEmitter<string> =
		new EventEmitter<string>();

	/**
	 * Gets or sets a value representing the common list context to be loaded
	 * during view mode.
	 *
	 * @type {IDynamicComponentContext<
	 * 	Component,
	 * 	ICommonListContext<TEntity>>}
	 * @memberof CommonListDirective
	 */
	public commonListContext: IDynamicComponentContext<
		Component,
		ICommonListContext<TEntity>>;

	/**
	 * Gets or sets a value indicating whether the current display mode is
	 * loading.
	 *
	 * @type {boolean}
	 * @memberof CommonListDirective
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets a collection of enabled filters.
	 *
	 * @type {ICommonListFilter[]}
	 * @memberof CommonListDirective
	 */
	public enabledFilters: ICommonListFilter[];

	/**
	 * Gets or sets the list of common sorters for the list view.
	 *
	 * @type {ICommonListSort[]}
	 * @memberof CommonListDirective
	 */
	public sorters: ICommonListSort[] = [];

	/**
	 * Gets or sets the selected sorter.
	 *
	 * @type {ICommonListSort}
	 * @memberof CommonListDirective
	 */
	public sorter: ICommonListSort;

	/**
	 * Gets or sets the api service to be used.
	 *
	 * @type {BaseEntityApiService<TEntityDto>}
	 * @memberof CommonListDirective
	 */
	public apiService: BaseEntityApiService<TEntityDto>;

	/**
	 * Gets or sets the message sent to developers implementing this directive
	 * when a method must be created.
	 *
	 * @type {string}
	 * @memberof CommonListDirective
	 */
	private readonly requiredImplementationMessage: string =
		'must be implemented in the component.';

	/**
	 * Handles the on initialization event.
	 * This will gather and display associated entities based on the given
	 * context parameters.
	 *
	 * @memberof CommonListDirective
	 */
	public ngOnInit(): void
	{
		this.sorter =
			this.sorter || this.sorters[0];

		this.refreshCommonListContext(
			this.loadItems(
				this.enabledFilters,
				this.sorter),
			this);
	}

	/**
	 * A method that handles any sorter change and refreshes data.
	 *
	 * @param {any} source
	 * A value representing the source component triggering the sort change
	 * event.
	 * @param {ICommonListSort} sorter
	 * A value representing the enabled and selected sorter.
	 * @memberof CommonListDirective
	 */
	public handleSortingChange(
		source: any,
		sorter: ICommonListSort): void
	{
		this.sorter = sorter;
		this.refreshCommonListContext(
			this.loadItems(
				this.enabledFilters,
				this.sorter),
			source);
	}

	/**
	 * A method that handles any filter change and refreshes data.
	 *
	 * @param {any} source
	 * A value representing the source component triggering the sort change
	 * event.
	 * @param {ICommonListFilter[]} filters
	 * A collection of enabled and active filters.
	 * @memberof CommonListDirective
	 */
	public handleFilterChange(
		source: any,
		filters: ICommonListFilter[]): void
	{
		this.enabledFilters = filters;
		this.refreshCommonListContext(
			this.loadItems(
				this.enabledFilters,
				this.sorter),
			source);
	}

	/**
	 * A method to either create or refresh the common list context.
	 *
	 * @rule
	 * The source control's which loading parameter, icon display to enable.
	 *
	 * @async
	 * @param {Promise<TEntity[]>} dataPromise
	 * A value representing the data promise to load into the context.
	 * @param {any} source
	 * A value representing the source component we are refreshing the context
	 * from.
	 * @memberof CommonListDirective
	 */
	public async refreshCommonListContext(
		dataPromise: Promise<TEntity[]>,
		source: any): Promise<void>
	{
		const firstLoad: boolean =
			AnyHelper.isNull(this.commonListContext) === true;
		source.loading = true;

		try
		{
			const queryData: TEntity[] = await dataPromise;
			const data: ICommonListItem<TEntity>[] =
				queryData.map(
					(item: TEntity) =>
						this.mapToListItem(item));

			if (firstLoad)
			{
				// Generate entire context.
				this.commonListContext =
					<IDynamicComponentContext<
						Component,
						ICommonListContext<TEntity>>>
					{
						data: this.generateCommonListContext(data),
						source: <Component>this
					};
			}
			else
			{
				// We need to refresh data only.
				this.commonListContext.data.data = data;
			}

			this.performPostLoadActions();

			source.loading = false;

			if (AnyHelper.isFunction(
				source.performPostLoadActions))
			{
				source.performPostLoadActions();
			}
		}
		catch (exception)
		{
			source.loading = false;
			throw exception;
		}
	}

	/**
	 * A method to generate the proper data promise for loading rules data.
	 *
	 * @async
	 * @param {ICommonListFilter[]} [filters]
	 * A value representing the enabled filters to apply to the data promise
	 * query.
	 * @param {ICommonListSort} [sorter]
	 * A value representing the enabled sorter to apply to the data promise
	 * query.
	 * @returns {Promise<TEntity[]>}
	 * A data promise with an  filter, order, and offset.
	 * @memberof CommonListDirective
	 */
	public async loadItems(
		filters?: ICommonListFilter[],
		sorter?: ICommonListSort): Promise<TEntity[]>
	{
		let filter: string =
			this.getPrimaryFilter();

		if (AnyHelper.isNull(filters) === false)
		{
			filters.forEach(
				(item: ICommonListFilter) =>
				{
					filter = AnyHelper.isNullOrWhitespace(filter)
						? item.value
						: `(${filter}) AND (${item.value})`;
				});
		}

		let orderBy: string;
		if (AnyHelper.isNull(sorter) === false)
		{
			orderBy = `${sorter.value} ${sorter.direction}`;
		}

		if (this.context.source instanceof EntityInstanceComponent)
		{
			return this.getData(
				filter,
				orderBy);
		}

		return null;
	}

	/**
	 * A method to generate an initial filter for all calls to this list.
	 *
	 * @rule
	 * This filter will be used for all calls, including those in the
	 * list context filter set.
	 *
	 * @returns {string}
	 * A filter value used for all data loads in this list.
	 * @memberof CommonListDirective
	 */
	public getPrimaryFilter(): string
	{
		throw new Error(
			`GetPrimaryFilter ${this.requiredImplementationMessage}`);
	}

	/**
	 * A method to generate the proper data promise for loading data.
	 *
	 * @async
	 * @param {string} filter
	 * A value representing the compiled filters from the common list filter
	 * array.
	 * @param {string} orderBy
	 * A value representing the compiled order by from the common list sort.
	 * @param {number} offset
	 * A value representing the current offset to load from. This value
	 * defaults to 0.
	 * @returns {Promise<TEntity[]>}
	 * A data promise with an applied filter, order, and offset.
	 * @memberof CommonListDirective
	 */
	public async getData(
		_filter: string,
		_orderBy: string,
		_offset: number = 0): Promise<TEntity[]>
	{
		throw new Error(
			`GetData ${this.requiredImplementationMessage}`);
	}

	/**
	 * A method that generates the base rules common list context.
	 *
	 * @rule
	 * This method will generate on firstLoad the default common list context
	 * with properties used for rules.
	 *
	 * @param {ICommonListItem<TEntity>[]} data
	 * A value representing the data to load within the common list context.
	 * @returns {ICommonListContext<TEntity>}
	 * A value representing the common list context for first load.
	 * @memberof CommonListDirective
	 */
	public generateCommonListContext(
		_data: ICommonListItem<TEntity>[]): ICommonListContext<TEntity>
	{
		throw new Error(
			`GenerateCommonListContext ${this.requiredImplementationMessage}`);
	}

	/**
	 * A method that maps entity instance data to a common list item.
	 *
	 * @param {TEntity} entity
	 * A value representing the TEntity to map to common list item.
	 * @returns {ICommonListItem<TEntity>}
	 * A value representing the mapped common list item from the instance.
	 * @memberof CommonListDirective
	 */
	public mapToListItem(
		_entity: TEntity): ICommonListItem<TEntity>
	{
		throw new Error(
			`MapToListItem ${this.requiredImplementationMessage}`);
	}

	/**
	 * An available lifecycle event which is called after the list data is
	 * initially loaded.
	 *
	 * @memberof CommonListDirective
	 */
	public performPostLoadActions(): void
	{
		// Placeholder method that can be implemented in the child component.
	}
}