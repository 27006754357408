/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppEventParameterConstants
} from '@shared/constants/app-event-parameter.constants';
import {
	BaseOperationGroupDirective
} from '@operation/directives/base-operation-group.directive';
import {
	Component,
	EventEmitter,
	HostListener,
	Inject,
	Input,
	OnDestroy,
	Output
} from '@angular/core';
import {
	debounceTime,
	distinctUntilChanged,
	Subject
} from 'rxjs';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	LoggerService
} from '@shared/services/logger.service';
import {
	MenuItem
} from 'primeng/api';
import {
	OperationExecutionService
} from '@operation/services/operation-execution.service';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/* eslint-enable max-len */

@Component({
	selector: 'operation-menu',
	templateUrl: './operation-menu.component.html'
})

/**
 * A component used to define menu based navigation.
 *
 * @export
 * @class OperationMenuComponent
 * @extends {BaseOperationGroupDirective}
 * @implements {OnDestroy}
 */
export class OperationMenuComponent
	extends BaseOperationGroupDirective
	implements OnDestroy
{
	/**
	 * Initializes a news instance of the operation menu component class.
	 * @note These injected services are used as abstract classes multiple
	 * levels deep are unable to use the standard DI pattern. This will
	 * be altered in Angular 9 and directive only support.
	 *
	 * @memberof OperationMenuComponent
	 */
	public constructor(
		@Inject(LoggerService)
			loggerService: LoggerService,
		@Inject(OperationService)
			operationService: OperationService,
		@Inject(OperationExecutionService)
			operationExecutionService: OperationExecutionService,
		@Inject(SiteLayoutService)
			siteLayoutService: SiteLayoutService)
	{
		super(
			loggerService,
			operationService,
			operationExecutionService,
			siteLayoutService);
	}

	/**
	 * Gets or sets the id for the operation group container.
	 *
	 * @type {string}
	 * @memberof OperationMenuComponent
	 */
	@Input() public id: string;

	/**
	 * Gets or sets a class for the operation group container.
	 *
	 * @type {string}
	 * @memberof OperationMenuComponent
	 */
	@Input() public class: string;

	/**
	 * Gets or sets the value used to reset the
	 * operation menu items.
	 *
	 * @type {boolean}
	 * @memberof OperationMenuComponent
	 */
	@Input() public reset: boolean;

	/**
	 * Gets or sets the value used to set the active parent
	 * value of operation menu.
	 *
	 * @type {boolean}
	 * @memberof OperationMenuComponent
	 */
	@Input() public parentActive: boolean;

	/**
	 * Gets or sets the dynamic component context.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof OperationMenuComponent
	 */
	@Input() public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the event sent to listening components that
	 * says that this operation menu should be closed.
	 *
	 * @type {EventEmitter<void>}
	 * @memberof OperationMenuComponent
	 */
	@Output() public closeList: EventEmitter<void> =
		new EventEmitter();

	/**
	 * Gets or sets the event sent to listening components that the
	 * overlay menu holding this menu should be closed. This is used
	 * to hide and show the navigation menu when in overlay mode.
	 *
	 * @type {EventEmitter<void>}
	 * @memberof OperationMenuComponent
	 */
	@Output() public closeOverlays: EventEmitter<void> =
		new EventEmitter();

	/**
	 * Gets or sets the active menu item of this operation menu.
	 *
	 * @type {MenuItem}
	 * @memberof OperationMenuComponent
	 */
	public activeMenuItem: MenuItem = null;

	/**
	 * Gets or sets the subject changes observer for the active
	 * menu item.
	 *
	 * @type {Subject<MenuItem>}
	 * @memberof OperationMenuComponent
	 */
	public activeMenuItemChanged: Subject<MenuItem> =
		new Subject<MenuItem>();

	/**
	 * Gets or sets the delay to debounce active menu item changes.
	 *
	 * @type {number}
	 * @memberof OperationMenuComponent
	 */
	private readonly operationMenuDebounceDelay: number = 25;

	/**
	 * Handles the hide associated menus event.
	 * This is used to ensure that only one operation overlay menu
	 * is displayed at a time. This sets the active menu item as null
	 * if the event was sent from a newly active operation menu.
	 *
	 * @param {string} controlIdentifer
	 * The identifier of the control sending this message.
	 * @memberof OperationMenuComponent
	 */
	@HostListener(
		AppEventConstants.hideAssociatedMenusEvent,
		[AppEventParameterConstants.id])
	public hideAssociatedMenus(
		controlIdentifer: string): void
	{
		if (!AnyHelper.isNullOrEmpty(this.activeMenuItem)
			&& this.id !== controlIdentifer)
		{
			this.activeMenuItemChanged.next(null);
		}
	}

	/**
	 * Handles the on destroy lifecycle event.
	 * This will complete the active subscription.
	 *
	 * @memberof OperationMenuComponent
	 */
	public ngOnDestroy(): void
	{
		this.activeMenuItemChanged.complete();
	}

	/**
	 * Handles the menu item click action sent from the menu
	 * sub-group component. This will emit to listening components
	 * that an action was clicked and close the list.
	 *
	 * @param {MenuItem} menuItem
	 * The menu item that was clicked.
	 * @memberof OperationMenuComponent
	 */
	public itemClicked(
		menuItem: MenuItem): void
	{
		const previousActiveMenuItem = this.activeMenuItem;

		// If this is a command or link, send the close list and close
		// overlay commands.
		if (!(AnyHelper.isNullOrEmpty(menuItem.command)
			&& AnyHelper.isNullOrEmpty(menuItem.url)
			&& AnyHelper.isNullOrEmpty(menuItem.routerLink)))
		{
			this.closeList.emit();
			this.closeOverlays.emit();
		}

		const primaryLevelItems: MenuItem[] =
			this.model.filter((item: MenuItem) =>
				menuItem === item);

		if (primaryLevelItems.length > 0
			&& previousActiveMenuItem == null)
		{
			this.activeMenuItemChanged.next(menuItem);
		}
	}

	/**
	 * Handles the click outside action which occurs when a click
	 * occurs outside of this displayed operation menu.
	 * This sets the active menu item as null.
	 *
	 * @memberof OperationMenuComponent
	 */
	public clickedOutside(): void
	{
		this.activeMenuItemChanged.next(null);
	}

	/**
	 * Performs actions post operation group load.
	 * This is used to enable the active changed subject and
	 * handle business rules on change of this value.
	 *
	 * @memberof OperationMenuComponent
	 */
	public performPostOperationLoadActions(): void
	{
		this.activeMenuItemChanged.pipe(
			debounceTime(this.operationMenuDebounceDelay),
			distinctUntilChanged())
			.subscribe((newValue: MenuItem) =>
			{
				this.activeMenuItem = newValue;

				if (this.activeMenuItem == null)
				{
					this.closeList.emit();
				}
				else
				{
					EventHelper.dispatchHideAssociatedMenusEvent(
						this.id);
				}
			});
	}
}