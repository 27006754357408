<div class="ui-g-12 no-padding text-center">

	<ng-template
		#BackNavigation
		[ngIf]="isDisplayModeAvailable() === false
			|| displayMode !== displayModes.list">
		<div
			class="ui-g-12 no-padding text-left nested-display-header">
			<span
				class="text-link theme-color nested-display-back-container"
				(click)="displayMode = displayModes.list">
				<i class="fa fa-arrow-left nested-display-back-icon">
				</i>
				<span class="nested-display-back">
					Back
				</span>
			</span>
		</div>
	</ng-template>

	<ng-template
		#Invalid
		[ngIf]="isValid() === false
			|| isDisplayModeAvailable() === false
			|| isOwnershipAllowed === false"
		[ngIfElse]="Content">
		<div class="ui-g-12 text-center">
			<div *ngIf="isOwnershipAllowed === true">
				This view is not currently available.
			</div>
			<div *ngIf="isOwnershipAllowed === false">
				<app-access-denied-content
					[drawerDisplay]="true"
					drawerName="Rules"
					[sessionIdentifier]="sessionIdentifier"
					[accessDeniedUrl]="accessDeniedUrl"
					[resources]="resources"
					[clientMessage]="clientMessage">
				</app-access-denied-content>
			</div>
		</div>
	</ng-template>

	<ng-template
		#Content>
		<ng-template
			#ListRule
			[ngIf]="displayMode === displayModes.list">
			<app-rule-list
				[context]="context"
				(changeDisplayMode)="changeDisplayMode($event)"
				(changeSelectedItem)="changeSelectedItem($event)">
			</app-rule-list>
		</ng-template>

		<ng-template
			#ViewRule
			[ngIf]="displayMode === displayModes.view">
			<app-rule-override
				[context]="context"
				[selectedItem]="selectedItem"
				(changeDisplayMode)="changeDisplayMode($event)">
			</app-rule-override>
		</ng-template>
	</ng-template>

</div>