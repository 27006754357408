/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BaseEntityApiService
} from '@api/services/base/base-entity.api.service';
import {
	CacheService
} from '@shared/services/cache.service';
import {
	HttpClient
} from '@angular/common/http';
import {
	Inject,
	Injectable
} from '@angular/core';
import {
	IRuleValidationDefinitionDto
} from '@api/interfaces/rules/rule-validation-definition.dto.interface';

/**
 * A class representing the logic and services of the rule validation
 * definition controller.
 *
 * @export
 * @class RuleValidationDefinitionApiService
 * @extends {BaseEntityApiService<IRuleValidationDefinitionDto>}
 */
@Injectable()
export class RuleValidationDefinitionApiService
	extends BaseEntityApiService<IRuleValidationDefinitionDto>
{
	/**
	 * Creates an instance of a RuleValidationDefinitionApiService.
	 *
	 * @param {HttpClient} HttpClient
	 * The injected http client to use in the base api service.
	 * @param {CacheService} cache
	 * The injected cache service to use in the base api service.
	 * @memberof RuleValidationDefinitionApiService
	 */
	public constructor(
		@Inject(HttpClient) http: HttpClient,
		@Inject(CacheService) cache: CacheService)
	{
		super();
		this.httpClient = http;
		this.cacheService = cache;
		this.endpoint =
			AppConstants.apiControllers.ruleValidationDefinitions;
	}
}