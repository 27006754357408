/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	Input
} from '@angular/core';
import {
	FileDirective
} from '@shared/directives/file.directive';

@Component({
	selector: 'app-file',
	templateUrl: './file.component.html',
	styleUrls: [
		'./file.component.scss'
	]
})

/**
 * A class for diplaying file details
 *
 * @export
 * @class DocumentComponent
 * @extends {FileDirective}
 */
export class FileComponent
	extends FileDirective
{
	/**
	 * Gets or sets the file entity to display.
	 *
	 * @type {any}
	 * @memberof FileComponent
	 */
	@Input() public fileEntity: any;
}