/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AccordionModule
} from 'primeng/accordion';
import {
	AutoCompleteModule
} from 'primeng/autocomplete';
import {
	BadgeModule
} from 'primeng/badge';
import {
	BreadcrumbModule
} from 'primeng/breadcrumb';
import {
	ButtonModule
} from 'primeng/button';
import {
	CalendarModule
} from 'primeng/calendar';
import {
	CardModule
} from 'primeng/card';
import {
	CarouselModule
} from 'primeng/carousel';
import {
	ChartModule
} from 'primeng/chart';
import {
	CheckboxModule
} from 'primeng/checkbox';
import {
	ChipsModule
} from 'primeng/chips';
import {
	CodeHighlighterModule
} from 'primeng/codehighlighter';
import {
	ColorPickerModule
} from 'primeng/colorpicker';
import {
	CommonModule
} from '@angular/common';
import {
	ConfirmDialogModule
} from 'primeng/confirmdialog';
import {
	ContextMenuModule
} from 'primeng/contextmenu';
import {
	DataViewModule
} from 'primeng/dataview';
import {
	DialogModule
} from 'primeng/dialog';
import {
	DividerModule
} from 'primeng/divider';
import {
	DragDropModule
} from 'primeng/dragdrop';
import {
	DropdownModule
} from 'primeng/dropdown';
import {
	EditorModule
} from 'primeng/editor';
import {
	FieldsetModule
} from 'primeng/fieldset';
import {
	FileUploadModule
} from 'primeng/fileupload';
import {
	GalleriaModule
} from 'primeng/galleria';
import {
	GMapModule
} from 'primeng/gmap';
import {
	InputMaskModule
} from 'primeng/inputmask';
import {
	InputSwitchModule
} from 'primeng/inputswitch';
import {
	InputTextareaModule
} from 'primeng/inputtextarea';
import {
	InputTextModule
} from 'primeng/inputtext';
import {
	LightboxModule
} from 'primeng/lightbox';
import {
	ListboxModule
} from 'primeng/listbox';
import {
	MegaMenuModule
} from 'primeng/megamenu';
import {
	MenubarModule
} from 'primeng/menubar';
import {
	MenuModule
} from 'primeng/menu';
import {
	MessageModule
} from 'primeng/message';
import {
	MessagesModule
} from 'primeng/messages';
import {
	MultiSelectModule
} from 'primeng/multiselect';
import {
	NgModule
} from '@angular/core';
import {
	OrderListModule
} from 'primeng/orderlist';
import {
	OrganizationChartModule
} from 'primeng/organizationchart';
import {
	OverlayPanelModule
} from 'primeng/overlaypanel';
import {
	PaginatorModule
} from 'primeng/paginator';
import {
	PanelMenuModule
} from 'primeng/panelmenu';
import {
	PanelModule
} from 'primeng/panel';
import {
	PasswordModule
} from 'primeng/password';
import {
	PickListModule
} from 'primeng/picklist';
import {
	ProgressBarModule
} from 'primeng/progressbar';
import {
	ProgressSpinnerModule
} from 'primeng/progressspinner';
import {
	RadioButtonModule
} from 'primeng/radiobutton';
import {
	RatingModule
} from 'primeng/rating';
import {
	ScrollPanelModule
} from 'primeng/scrollpanel';
import {
	SelectButtonModule
} from 'primeng/selectbutton';
import {
	SidebarModule
} from 'primeng/sidebar';
import {
	SlideMenuModule
} from 'primeng/slidemenu';
import {
	SliderModule
} from 'primeng/slider';
import {
	SpinnerModule
} from 'primeng/spinner';
import {
	SplitButtonModule
} from 'primeng/splitbutton';
import {
	StepsModule
} from 'primeng/steps';
import {
	TableModule
} from 'primeng/table';
import {
	TabMenuModule
} from 'primeng/tabmenu';
import {
	TabViewModule
} from 'primeng/tabview';
import {
	TerminalModule
} from 'primeng/terminal';
import {
	TieredMenuModule
} from 'primeng/tieredmenu';
import {
	ToastModule
} from 'primeng/toast';
import {
	ToggleButtonModule
} from 'primeng/togglebutton';
import {
	ToolbarModule
} from 'primeng/toolbar';
import {
	TooltipModule
} from 'primeng/tooltip';
import {
	TreeModule
} from 'primeng/tree';
import {
	TreeTableModule
} from 'primeng/treetable';

@NgModule({
	imports: [
		CommonModule,
		AccordionModule,
		AutoCompleteModule,
		BadgeModule,
		BreadcrumbModule,
		ButtonModule,
		CalendarModule,
		CardModule,
		CarouselModule,
		ColorPickerModule,
		ChartModule,
		CheckboxModule,
		ChipsModule,
		CodeHighlighterModule,
		ConfirmDialogModule,
		ContextMenuModule,
		DataViewModule,
		DialogModule,
		DividerModule,
		DragDropModule,
		DropdownModule,
		EditorModule,
		FieldsetModule,
		FileUploadModule,
		GalleriaModule,
		GMapModule,
		InputMaskModule,
		InputSwitchModule,
		InputTextModule,
		InputTextareaModule,
		LightboxModule,
		ListboxModule,
		MegaMenuModule,
		MenuModule,
		MenubarModule,
		MessagesModule,
		MessageModule,
		MultiSelectModule,
		OrderListModule,
		OrganizationChartModule,
		OverlayPanelModule,
		PaginatorModule,
		PanelModule,
		PanelMenuModule,
		PasswordModule,
		PickListModule,
		ProgressBarModule,
		ProgressSpinnerModule,
		RadioButtonModule,
		RatingModule,
		ScrollPanelModule,
		SelectButtonModule,
		SidebarModule,
		SlideMenuModule,
		SliderModule,
		SpinnerModule,
		SplitButtonModule,
		StepsModule,
		TableModule,
		TabMenuModule,
		TabViewModule,
		TerminalModule,
		TieredMenuModule,
		ToastModule,
		ToggleButtonModule,
		ToolbarModule,
		TooltipModule,
		TreeModule,
		TreeTableModule
	],
	exports: [
		AccordionModule,
		AutoCompleteModule,
		BadgeModule,
		BreadcrumbModule,
		ButtonModule,
		CalendarModule,
		CardModule,
		CarouselModule,
		ColorPickerModule,
		ChartModule,
		CheckboxModule,
		ChipsModule,
		CodeHighlighterModule,
		ConfirmDialogModule,
		ContextMenuModule,
		DataViewModule,
		DialogModule,
		DividerModule,
		DragDropModule,
		DropdownModule,
		EditorModule,
		FieldsetModule,
		FileUploadModule,
		GalleriaModule,
		GMapModule,
		InputMaskModule,
		InputSwitchModule,
		InputTextModule,
		InputTextareaModule,
		LightboxModule,
		ListboxModule,
		MegaMenuModule,
		MenuModule,
		MenubarModule,
		MessagesModule,
		MessageModule,
		MultiSelectModule,
		OrderListModule,
		OrganizationChartModule,
		OverlayPanelModule,
		PaginatorModule,
		PanelModule,
		PanelMenuModule,
		PasswordModule,
		PickListModule,
		ProgressBarModule,
		ProgressSpinnerModule,
		RadioButtonModule,
		RatingModule,
		ScrollPanelModule,
		SelectButtonModule,
		SidebarModule,
		SlideMenuModule,
		SliderModule,
		SpinnerModule,
		SplitButtonModule,
		StepsModule,
		TableModule,
		TabMenuModule,
		TabViewModule,
		TerminalModule,
		TieredMenuModule,
		ToastModule,
		ToggleButtonModule,
		ToolbarModule,
		TooltipModule,
		TreeModule,
		TreeTableModule
	],
	providers: [],
	declarations: []
})

/**
 * A module to be shared accross the application used
 * to hold all prime ng modules for import.
 *
 * @export
 * @class PrimeNgModule
 */
export class PrimeNgModule
{
}