/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	ActivityService
} from './services/activity.service';
import {
	ChartFactory
} from '@shared/factories/chart-factory';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	InjectionToken
} from '@angular/core';
import {
	RuleService
} from '@shared/services/rule.service';
import {
	SessionService
} from '@shared/services/session.service';
import {
	UserService
} from '@shared/services/user.service';
import {
	WorkItemService
} from '@shared/services/work-item.service';

/*
 * Export all tokens to be used in the dependency injector for
 * string based resolution
*/
export const activitytServiceToken =
	new InjectionToken<ActivityService>(
		'ActivityService');
export const ChartFactoryToken =
	new InjectionToken<ChartFactory>(
		'ChartFactory');
export const ruleServiceToken =
	new InjectionToken<RuleService>(
		'RuleService');
export const entityServiceToken =
	new InjectionToken<EntityService>(
		'EntityService');
export const sessionServiceToken =
	new InjectionToken<SessionService>(
		'SessionService');
export const userServiceToken =
	new InjectionToken<UserService>(
		'UserService');
export const workItemServiceToken =
	new InjectionToken<WorkItemService>(
		'WorkItemService');

/**
 * A lookup to be shared accross the application used
 * for classes available in the shared module.
 *
 * @export
 * @class SharedTokenLookup
 */
export class SharedTokenLookup
{
	/**
	 * Dictionary to be used in dependency injector for
	 * string based resolution.
	 *
	 * @static
	 * @memberof SharedTokenLookup
	 */
	public static tokens =
	{
		'ActivityService':
			activitytServiceToken,
		'ChartFactory':
			ChartFactoryToken,
		'RuleService':
			ruleServiceToken,
		'EntityService':
			entityServiceToken,
		'SessionService':
			sessionServiceToken,
		'UserService':
			userServiceToken,
		'WorkItemService':
			workItemServiceToken
	};
}