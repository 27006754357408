/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonFormlyFieldConstants
} from '@shared/constants/common-formly-field-constants';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	Component
} from '@angular/core';
import {
	DisplayComponentDefinitionApiService
} from '@api/services/display-components/display-component-definition.api.service';
import {
	DisplayComponentInstanceApiService
} from '@api/services/display-components/display-component-instance.api.service';
import {
	DisplayComponentTypeApiService
} from '@api/services/display-components/display-component-type.api.service';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	FormControl
} from '@angular/forms';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDisplayComponentDefinition
} from '@shared/interfaces/display-components/display-component-definition.interface';
import {
	IDisplayComponentInstance
} from '@shared/interfaces/display-components/display-component-instance.interface';
import {
	IDisplayComponentType
} from '@shared/interfaces/display-components/display-component-type.interface';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	OptionsFactory
} from '@shared/factories/options-factory';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	TableHelper
} from '@shared/helpers/table.helper';

/* eslint-enable max-len */

@Component({
	selector: 'app-display-definitions',
	templateUrl: './display-definitions.component.html',
	styleUrls: [
		'./display-definitions.component.scss'
	]
})

/**
 * A component representing an instance of the display component definitions
 * component.
 *
 * @export
 * @class DisplayDefinitionsComponent
 * @extends {CommonTablePageDirective}
 */
export class DisplayDefinitionsComponent
	extends CommonTablePageDirective
{
	/**
	 * Initializes a new instance of the DisplayDefinitionsComponent class.
	 *
	 * @param {DisplayComponentDefinitionApiService}
	 * displayComponentDefinitionApiService
	 * The api service used to load display component definition data.
	 * @param {DisplayComponentTypeApiService} displayComponentTypeApiService
	 * The api service used to load display component type data.
	 * @param {DisplayComponentInstanceApiService}
	 * displayComponentInstanceApiService
	 * The api service used to load display component instance data.
	 * @param {OptionsFactory} optionsFactory
	 * The options factory used for common dropdown options.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof DisplayDefinitionsComponent
	 */
	public constructor(
		public displayComponentDefinitionApiService:
			DisplayComponentDefinitionApiService,
		public displayComponentTypeApiService: DisplayComponentTypeApiService,
		public displayComponentInstanceApiService:
			DisplayComponentInstanceApiService,
		public optionsFactory: OptionsFactory,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the table definitions for the common table view.
	 *
	 * @type {ICommonTable}
	 * @memberof DisplayDefinitionsComponent
	 */
	public tableDefinitions: ICommonTable;

	/**
	 * Gets or sets the key for a nested display component type public value.
	 *
	 * @type {string}
	 * @memberof DisplayDefinitionsComponent
	 */
	private readonly displayTypePublicKey: string =
		'data.displayType.public';

	/**
	 * Gets or sets the key for a nested display component type public value.
	 *
	 * @type {string}
	 * @memberof DisplayDefinitionsComponent
	 */
	private readonly displayTypeOwnershipSecurityGroupKey: string =
		'data.displayType.ownershipSecurityGroupId';

	/**
	 * Sets up variables used in this admin page based table.
	 *
	 * @memberof DisplayDefinitionsComponent
	 */
	public setupPageVariables(): void
	{
		this.availableColumns =
			[
				{
					dataKey: 'id',
					columnHeader: 'Id',
					displayOrder: 1
				},
				{
					dataKey: 'typeId',
					columnHeader: 'Type Id',
					displayOrder: 2
				},
				{
					dataKey: 'componentName',
					columnHeader: 'Component Name',
					displayOrder: 3
				}
			];
		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Sets up the table definitions for a standard table
	 *
	 * @async
	 * @memberof DisplayDefinitionsComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		const securityGroupOptions: IDropdownOption[] =
			await this.optionsFactory.getSecurityGroupOptions();

		this.tableDefinitions = {
			actions: {
				create: {
					layout: [
						{
							key: 'data.displayType.name',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Type',
								required: true
							},
							validators: {
								alphaNumeric: {
									expression: (control: FormControl) =>
										/^[a-zA-Z0-9.]*$/.test(control.value),
									message:
										'Must contain letters, '
											+ 'numbers or periods only.'
								}
							},
							asyncValidators: {
								uniqueType: {
									expression: (control: FormControl) =>
										this.isExistingType(control.value),
									message: 'Existing Type.'
								}
							}
						},
						{
							key: 'data.componentName',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Component Name'
							}
						},
						{
							...CommonFormlyFieldConstants
								.publicField,
							key: this.displayTypePublicKey,
							templateOptions: {
								...CommonFormlyFieldConstants
									.publicField
									.templateOptions,
								label: 'Type '
									+ CommonFormlyFieldConstants
										.publicField
										.templateOptions
										.label
							}
						},
						{
							...CommonFormlyFieldConstants
								.ownershipSecurityGroupField,
							key: this.displayTypeOwnershipSecurityGroupKey,
							templateOptions: {
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField
									.templateOptions,
								options: securityGroupOptions,
								label: 'Type '
									+ CommonFormlyFieldConstants
										.ownershipSecurityGroupField
										.templateOptions
										.label
							}
						},
						{
							...CommonFormlyFieldConstants
								.publicField,
							templateOptions: {
								...CommonFormlyFieldConstants
									.publicField
									.templateOptions,
								label: 'Definition '
									+ CommonFormlyFieldConstants
										.publicField
										.templateOptions
										.label
							}
						},
						{
							...CommonFormlyFieldConstants
								.ownershipSecurityGroupField,
							templateOptions: {
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField
									.templateOptions,
								options: securityGroupOptions,
								label: 'Definition '
									+ CommonFormlyFieldConstants
										.ownershipSecurityGroupField
										.templateOptions
										.label
							}
						},
						{
							key: 'data.displayType.description',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Description',
								required: true
							}
						},
						{
							key: 'data.jsonData',
							type: FormlyConstants.customControls.customTextArea,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Attributes',
								rows: FormlyConstants.textAreaRowSizes.standard,
								required: true
							},
							validators: {
								validJson: {
									expression: (
										control: FormControl,
										field: FormlyFieldConfig) =>
										this.isValidJson(control, field),
									message:
										''
								}
							}
						}
					],
					items: [
						{
							label: 'Create',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: () => this.createDisplayDefinition()
						}]
				},
				view: {
					layout: [
						{
							key: 'data.displayType.name',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Type',
								disabled: true
							}
						},
						{
							key: 'data.componentName',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Component Name',
								disabled: true
							}
						},
						{
							...CommonFormlyFieldConstants
								.publicField,
							key: this.displayTypePublicKey,
							templateOptions: {
								...CommonFormlyFieldConstants
									.publicField
									.templateOptions,
								disabled: true,
								label: 'Type '
									+ CommonFormlyFieldConstants
										.publicField
										.templateOptions
										.label
							}
						},
						{
							...CommonFormlyFieldConstants
								.ownershipSecurityGroupField,
							key: this.displayTypeOwnershipSecurityGroupKey,
							templateOptions: {
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField
									.templateOptions,
								options: securityGroupOptions,
								disabled: true,
								label: 'Type '
									+ CommonFormlyFieldConstants
										.ownershipSecurityGroupField
										.templateOptions
										.label
							}
						},
						{
							...CommonFormlyFieldConstants
								.publicField,
							templateOptions: {
								...CommonFormlyFieldConstants
									.publicField
									.templateOptions,
								disabled: true,
								label: 'Definition '
									+ CommonFormlyFieldConstants
										.publicField
										.templateOptions
										.label
							}
						},
						{
							...CommonFormlyFieldConstants
								.ownershipSecurityGroupField,
							templateOptions: {
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField
									.templateOptions,
								options: securityGroupOptions,
								disabled: true,
								label: 'Definition '
									+ CommonFormlyFieldConstants
										.ownershipSecurityGroupField
										.templateOptions
										.label
							}
						},
						{
							key: 'data.displayType.description',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Description',
								disabled: true
							}
						},
						{
							key: 'data.jsonData',
							type: FormlyConstants.customControls
								.customTextDisplay,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Attributes',
								useCodeBlock: true,
								codeBlockType:
									AppConstants.markdownLanguages.json,
								content: ''
							},
							expressionProperties: {
								'templateOptions.content':
									'`${model.data.jsonData}`'
							}
						}
					],
					items: []
				},
				update: {
					layout: [
						{
							key: 'data.displayType.name',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Type',
								required: true
							},
							validators: {
								alphaNumeric: {
									expression: (control: FormControl) =>
										/^[a-zA-Z0-9.]*$/.test(control.value),
									message:
										'Must contain letters, numbers or '
											+ 'periods only.'
								}
							},
							asyncValidators: {
								uniqueType: {
									expression: (control: FormControl) =>
										this.allowToUpdateType(control.value),
									message: 'Existing Type.'
								}
							}
						},
						{
							key: 'data.componentName',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Component Name'
							}
						},
						{
							...CommonFormlyFieldConstants
								.publicField,
							key: this.displayTypePublicKey,
							templateOptions: {
								...CommonFormlyFieldConstants
									.publicField
									.templateOptions,
								label: 'Type '
									+ CommonFormlyFieldConstants
										.publicField
										.templateOptions
										.label
							}
						},
						{
							...CommonFormlyFieldConstants
								.ownershipSecurityGroupField,
							key: this.displayTypeOwnershipSecurityGroupKey,
							templateOptions: {
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField
									.templateOptions,
								options: securityGroupOptions,
								label: 'Type '
									+ CommonFormlyFieldConstants
										.ownershipSecurityGroupField
										.templateOptions
										.label
							}
						},
						{
							...CommonFormlyFieldConstants
								.publicField,
							templateOptions: {
								...CommonFormlyFieldConstants
									.publicField
									.templateOptions,
								label: 'Definition '
									+ CommonFormlyFieldConstants
										.publicField
										.templateOptions
										.label
							}
						},
						{
							...CommonFormlyFieldConstants
								.ownershipSecurityGroupField,
							templateOptions: {
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField
									.templateOptions,
								options: securityGroupOptions,
								label: 'Definition '
									+ CommonFormlyFieldConstants
										.ownershipSecurityGroupField
										.templateOptions
										.label
							}
						},
						{
							key: 'data.displayType.description',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Description',
								required: true
							}
						},
						{
							key: 'data.jsonData',
							type: FormlyConstants.customControls.customTextArea,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Attributes',
								rows: FormlyConstants.textAreaRowSizes.standard,
								required: true
							},
							validators: {
								validJson: {
									expression: (
										control: FormControl,
										field: FormlyFieldConfig) =>
										this.isValidJson(control, field),
									message:
										''
								}
							}
						}
					],
					items: [
						{
							label: 'Save',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: () => this.updateDisplayDefinition()
						}]
				},
				delete: {
					items: [
						{
							label: 'Confirm',
							styleClass: AppConstants.cssClasses.pButtonDanger,
							disabled: false,
							command: () => this.deleteDisplayDefinition()
						}],
					deleteStatement: () => this.getDeleteStatement(),
				}
			},
			tableTitle: 'Definitions',
			objectSearch: {
				filter: AppConstants.empty,
				orderBy: `Id ${AppConstants.sortDirections.descending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: this.tableRowCount
			},
			apiPromise: async (objectSearch: IObjectSearch) =>
			{
				const displayDefinitions: any[] = [];
				const apiDefinitions: IDisplayComponentDefinition[] =
					await this.displayComponentDefinitionApiService
						.query(
							objectSearch.filter,
							objectSearch.orderBy,
							objectSearch.offset,
							objectSearch.limit);

				for (const apiDefinition of apiDefinitions)
				{
					displayDefinitions.push(
						<IDisplayComponentDefinition>
						{
							id: apiDefinition.id,
							typeId: apiDefinition.typeId,
							displayType:
								await this.displayComponentTypeApiService
									.get(apiDefinition.typeId),
							componentName: apiDefinition.componentName,
							componentDefinition: apiDefinition
								.componentDefinition,
							jsonData: apiDefinition.jsonData,
							public: apiDefinition.public,
							ownershipSecurityGroupId:
								apiDefinition.ownershipSecurityGroupId,
							createdById: apiDefinition.createdById
						});
				}

				return displayDefinitions;
			},
			availableColumns: this.availableColumns,
			selectedColumns: this.selectedColumns,
			columnSelectionMode: this.columnSelectionMode,
			expandTitle: () =>
				TableHelper.getExpandTitle(
					this.commonTableContext,
					'Display Component Definition'),
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			rowCountChanged: (rowCount: number) =>
			{
				this.tableRowCount = rowCount;
				this.restoreTableDefinition();
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.tableDefinitions.selectedColumns =
					selectedColumns;
				this.selectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.tableDefinitions.columnSelectionMode =
					columnSelectionMode;
				this.columnSelectionMode = columnSelectionMode;
			}
		};
		this.loadingTableDefinitions = false;
	}

	/**
	 * Creates the display definition.
	 *
	 * @async
	 * @memberof DisplayDefinitionsComponent
	 */
	private async createDisplayDefinition(): Promise<void>
	{
		const rowData: any = this.commonTableContext.source.rowData;
		const displayComponentTypeId: number =
			await this.displayComponentTypeApiService
				.create(
					<IDisplayComponentType>
					{
						name: rowData.displayType.name,
						description: rowData.displayType.description,
						public: rowData.displayType.public,
						ownershipSecurityGroupId:
							rowData.displayType.ownershipSecurityGroupId
					});

		const displayComponentDefinitionData: IDisplayComponentDefinition =
			<IDisplayComponentDefinition>
			{
				componentDefinition: rowData.componentDefinition,
				componentName: rowData.componentName,
				jsonData: rowData.jsonData,
				typeId: displayComponentTypeId,
				public: rowData.public,
				ownershipSecurityGroupId:
					rowData.ownershipSecurityGroupId
			};

		await this.activityService.handleActivity(
			new Activity(
				this.displayComponentDefinitionApiService
					.create(displayComponentDefinitionData),
				'<strong>Creating Display Definition</strong>',
				'<strong>Created Display Definition</strong>',
				`Display Definition
					${this.commonTableContext.source.rowData.componentName}
					was successfully created.`,
				`Display Definition
					${this.commonTableContext.source.rowData.componentName}
					was not created.`));

		this.restoreTableDefinition();
	}

	/**
	 * Updates the display definition.
	 *
	 * @async
	 * @memberof DisplayDefinitionsComponent
	 */
	private async updateDisplayDefinition(): Promise<void>
	{
		const updateDisplayComponentDefinition =
			async () =>
			{
				const rowData: any = this.commonTableContext.source.rowData;
				const displayComponentType: IDisplayComponentType =
					<IDisplayComponentType>
					{
						id: rowData.displayType.id,
						name: rowData.displayType.name,
						description: rowData.displayType.description,
						public: rowData.displayType.public,
						ownershipSecurityGroupId:
							rowData.displayType.ownershipSecurityGroupId,
						createdById: rowData.displayType.createdById
					};

				await this.displayComponentTypeApiService
					.update(
						this.commonTableContext.source.rowData.displayType.id,
						displayComponentType);

				const displayComponentDefinition: IDisplayComponentDefinition =
					<IDisplayComponentDefinition>
					{
						id: rowData.id,
						componentName: rowData.componentName,
						componentDefinition: rowData.componentDefinition,
						jsonData: rowData.jsonData,
						typeId: rowData.typeId,
						public: rowData.public,
						ownershipSecurityGroupId:
							rowData.ownershipSecurityGroupId,
						createdById: rowData.createdById
					};

				await this.displayComponentDefinitionApiService
					.update(
						this.commonTableContext.source.rowData.id,
						displayComponentDefinition);
			};

		await this.activityService.handleActivity(
			new Activity(
				updateDisplayComponentDefinition(),
				'<strong>Updating the Display Definition</strong>',
				'<strong>Updated the Display Definition</strong>',
				`Display Definition
					${this.commonTableContext.source.rowData.componentName}
					was successfully updated.`,
				`Display Definition
					${this.commonTableContext.source.rowData.componentName}
					was not updated.`),
			AppConstants.activityStatus.complete,
			true);

		this.restoreTableDefinition();
	}

	/**
	 * Gets the delete statement.
	 *
	 * @async
	 * @returns {Promise<string>}
	 * The delete statement string.
	 * @memberof DisplayDefinitionsComponent
	 */
	private async getDeleteStatement(): Promise<string>
	{
		const rowData: any = this.commonTableContext.source.rowData;
		const displayComponentInstanceRelationships:
			IDisplayComponentInstance[] =
			<IDisplayComponentInstance[]>
			await this.displayComponentInstanceApiService
				.query(
					`DefinitionId eq ${rowData.id}`,
					AppConstants.empty);

		this.tableDefinitions.actions.delete.items[1]
			.disabled = displayComponentInstanceRelationships.length > 0;

		EventHelper.dispatchTableExpansionPanelLoadedEvent();

		return displayComponentInstanceRelationships.length > 0
			? `Unable to delete Display Component Definition
				${rowData.id}
				${rowData.componentName}
				due to the existence of instances.`
			: `Confirm you are about to delete Display Component Definition
				${rowData.id}
				${rowData.componentName}.`;
	}

	/**
	 * Deletes display definition.
	 *
	 * @async
	 * @memberof DisplayDefinitionsComponent
	 */
	private async deleteDisplayDefinition(): Promise<void>
	{
		const deleteDisplayComponentDefinition = async () =>
		{
			await this.displayComponentDefinitionApiService
				.delete(this.commonTableContext.source.rowData.id);

			await this.displayComponentTypeApiService
				.delete(this.commonTableContext.source.rowData.typeId);
		};

		await this.activityService.handleActivity(
			new Activity(
				deleteDisplayComponentDefinition(),
				'<strong>Deleting the Display Definition</strong>',
				'<strong>Deleted the Display Definition</strong>',
				`Display Definition
					${this.commonTableContext.source.rowData.componentName}
					was successfully deleted.`,
				`Display Definition
					${this.commonTableContext.source.rowData.componentName}
					was not deleted.`));

		this.restoreTableDefinition();
	}

	/**
	 * Defines and validates if the type is existing or not.
	 *
	 * @async
	 * @param {string} value
	 * The type value.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof DisplayDefinitionsComponent
	 */
	private async isExistingType(value: string): Promise<boolean>
	{
		const displayType =
			await this.displayComponentTypeApiService
				.query(
					`Name.ToLower() eq '${value.toLowerCase()}'`,
					AppConstants.empty);

		return Promise.resolve(displayType.length === 0);
	}

	/**
	 * Defines and validates if the updated value is existing or not.
	 *
	 * @async
	 * @param {string} value
	 * The type value.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof DisplayDefinitionsComponent
	 */
	private async allowToUpdateType(value: string): Promise<boolean>
	{
		let  allowedToUpdate: boolean = true;
		const persistedGroup =
				await this.displayComponentTypeApiService
					.get(this.commonTableContext.source.rowData.typeId);

		if (persistedGroup.name !== value)
		{
			const displayComponentType =
				await this.displayComponentTypeApiService
					.query(
						`Name.ToLower() eq '${value.toLowerCase()}'`,
						AppConstants.empty);

			allowedToUpdate = displayComponentType.length <= 0;
		}

		return Promise.resolve(allowedToUpdate);
	}

	/**
	 * Checks if value is a valid JSON format.
	 *
	 * @param {FormControl} control
	 * The form contrl.
	 * @returns {FormlyFieldConfig}
	 * The formly field config.
	 * @memberof DisplayDefinitionsComponent
	 */
	private isValidJson(
		control: FormControl,
		field: FormlyFieldConfig): boolean
	{
		let isValidJson: boolean = true;
		try{
			JSON.parse(control.value);
		}
		catch
		{
			isValidJson = false;
			field.validators.validJson.message =
				'Not a valid JSON.';
		}

		return isValidJson;
	}
}