/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	DocumentHelper
} from '@shared/helpers/document.helper';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	IApplicationMessage
} from '@shared/interfaces/application-messages/application-message.interface';
import {
	Injectable
} from '@angular/core';
import {
	IOperationAction
} from '@operation/actions/interfaces/operation-action.interface';
import {
	IOperationDefinition
} from '@operation/interfaces/operation-definition.interface';
import {
	OperationFactory
} from '@operation/factories/operation-factory';

/**
 * A class to handle executing all operation definition
 * based logic.
 *
 * @export
 * @class OperationExecutionService
 */
@Injectable()
export class OperationExecutionService
{
	/**
	 * Creates an instance of an OperationExecutionService.
	 *
	 * @param {ActivityService} activityService
	 * The factivity service used to execute this operation.
	 * @param {OperationFactory} operationFactory
	 * The factory used to create operation actions dynamically.
	 * @memberof OperationExecutionService
	 */
	public constructor(
		public activityService: ActivityService,
		private readonly operationFactory: OperationFactory)
	{
	}

	/**
	 * Executes a fully mapped operation definition populated via
	 * the operation service.
	 *
	 * @async
	 * @param {IOperationDefinition} operationDefinition
	 * The operation definition to execute.
	 * @returns {Promise<IOperationAction>} The operation action
	 * that was executed.
	 * @memberof OperationExecutionService
	 */
	public async executeMappedOperation(
		operationDefinition: IOperationDefinition): Promise<IOperationAction>
	{
		try
		{
			const operationAction: IOperationAction =
				this.operationFactory.create(
					operationDefinition.operationType.name,
					operationDefinition.operationTypeParameters);

			operationAction.label = operationDefinition.label;
			operationAction.icon = operationDefinition.icon;
			operationAction.order = operationDefinition.order;
			operationAction.pageContext = operationDefinition.pageContext;
			let data: any = null;

			if (operationAction.canDeactivateOperation === false)
			{
				return null;
			}

			// block interface.
			if (!AnyHelper.isNullOrEmpty(operationAction.blockInterface))
			{
				DocumentHelper.setElementDisplay(
					AppConstants.cssClasses.entityLayoutMask,
					<boolean>operationAction.blockInterface);
			}

			if (AnyHelper.isNullOrEmpty(
				operationAction.operationSuccessMessage))
			{
				await operationAction.execute();
			}
			else
			{
				data = await this.activityService.handleActivity(
					new Activity(
						operationAction.execute(),
						'<strong>Calling Action</strong> '
							+ operationAction.operationName,
						'<strong>Completed Action</strong> '
							+ operationAction.operationName,
						operationAction.operationSuccessMessage,
						operationAction.operationFailureMessage),
					AppConstants.activityStatus.info);
			}

			// Handle execute action errors.
			if (typeof data === AppConstants.variableTypes.string)
			{
				let messages: IApplicationMessage[] = [];
				try
				{
					const jsonData = JSON.parse(data);
					messages =
						AnyHelper.isNull(jsonData.messages)
							? []
							: jsonData.messages;
				}
				catch (exception)
				{
					// More cases may need handled here as they arise.
				}

				if (messages.length === 0
					|| messages.filter(
						(message: IApplicationMessage) =>
							message.status ===
								AppConstants.activityStatus.error).length === 0)
				{
					await operationAction.executePostOperationActions();
				}
			}
			else
			{
				await operationAction.executePostOperationActions();
			}

			// unblock interface.
			if (!AnyHelper.isNullOrEmpty(operationAction.unblockInterface))
			{
				DocumentHelper.setElementDisplay(
					AppConstants.cssClasses.entityLayoutMask,
					!operationAction.unblockInterface);
			}

			return operationAction;
		}
		catch (error)
		{
			EventHelper.dispatchBannerEvent(
				AppConstants.messages.genericErrorMessage,
				error.message,
				AppConstants.activityStatus.error,
				error);
		}

		return null;
	}
}