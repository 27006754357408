/**
 * @copyright WaterStreet. All rights reserved.
*/

import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AppCanDeactivateGuard
} from '@shared/guards/app-can-deactivate.guard';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BaseOperationAction
} from '@operation/actions/base/base-operation-action';
import {
	Injectable
} from '@angular/core';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	OperationExecutionService
} from '@operation/services/operation-execution.service';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/**
 * A class representing the change layout color
 * action.
 *
 * @export
 * @class ChangeLayoutColorAction
 * @extends {BaseOperationAction}
 */
@Injectable()
export class ChangeLayoutColorAction
	extends BaseOperationAction
{
	/**
	 * Creates an instance of a ChangeLayoutColorAction.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used for this action.
	 * @param {ActivityService} activityService
	 * The activity service used for this action.
	 * @param {OperationExecutionService} operationExecutionService
	 * The operation execution service used for this action.
	 * @param {OperationService} operationService
	 * The operation service used for this action.
	 * @param {OperationDefinitionApiService} operationDefinitionApiService
	 * The operation definition api service used for this action.
	 * @param {AppCanDeactivateGuard} appCanDeactivateGuard
	 * The app can deactivate guard.
	 * @memberof ChangeLayoutColorAction
	 */
	public constructor(
		protected siteLayoutService: SiteLayoutService,
		protected activityService: ActivityService,
		protected operationExecutionService: OperationExecutionService,
		protected operationService: OperationService,
		protected operationDefinitionApiService: OperationDefinitionApiService,
		protected appCanDeactivateGuard: AppCanDeactivateGuard)
	{
		super(
			activityService,
			operationExecutionService,
			operationService,
			operationDefinitionApiService,
			appCanDeactivateGuard);
	}

	/**
	 * Gets or sets the operation name.
	 *
	 * @type {string}
	 * @memberof ChangeLayoutColorAction
	 */
	public operationName: string =
		'Change Layout Color';

	/**
	 * Gets or sets the layout color.
	 *
	 * @type {string}
	 * @memberof ChangeLayoutColorAction
	 */
	public color: string = AppConstants.empty;

	/**
	 * Gets or sets the allowed parameter entries
	 * as a comma delimited string.
	 *
	 * @type {string}
	 * @memberof ChangeLayoutColorAction
	 */
	public allowedEntries: string[] =
	[
		'amber',
		'blue',
		'brown',
		'cyan',
		'darkgrey',
		'deeppurple',
		'green',
		'indigo',
		'lightblue',
		'lightgreen',
		'lime',
		'orange',
		'pink',
		'purple',
		'teal',
		'calm',
		'crimson',
		'firewatch',
		'influenza',
		'joomla',
		'littleleaf',
		'night',
		'skyline',
		'sunkist',
		'suzy'
	];

	/**
	 * Gets the message to be displayed when this operation
	 * has completed successfully.
	 *
	 * @type {string}
	 * @memberof ChangeLayoutColorAction
	 */
	public get operationSuccessMessage(): string
	{
		return `Changed the layout color to '${this.color}'.`;
	}

	/**
	 * Gets the message to be displayed when this operation
	 * has failed.
	 *
	 * @type {string}
	 * @memberof ChangeLayoutColorAction
	 */
	public get operationFailureMessage(): string
	{
		return `Unable to change the layout color to '${this.color}'.`;
	}

	/**
	 * Executes the defined action.
	 *
	 * @async
	 * @memberof ChangeLayoutColorAction
	 */
	public async execute(): Promise<void>
	{
		this.validateParameters(this.color);
		this.siteLayoutService.changeLayout(
			this.color);
	}
}