/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	DateTime
} from 'luxon';
import {
	differenceWith,
	each,
	get,
	isArray,
	isEqual,
	isObject,
	orderBy
} from 'lodash-es';
import {
	IDescriptionDisplayDefinition
} from '@shared/interfaces/application-objects/description-display-definition.interface';
import {
	IDifferenceDefinition
} from '@shared/interfaces/application-objects/difference-definition.interface';
import {
	Params
} from '@angular/router';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import JSONCrush from 'JSONCrush';
import LZString from 'lz-string';

/* eslint-disable max-len */

/**
 * A class containing static helper methods
 * for the object type.
 *
 * @export
 * @class ObjectHelper
 */
export class ObjectHelper
{
	/**
	 * Gets the property name for an object acting as an array place holder.
	 * This object is used in business logic array comparisons.
	 *
	 * @type {string}
	 * @memberof ObjectHelper
	 */
	private static readonly arrayPlaceholderIdentifier: string =
		'arrayPlaceholder';

	/**
	 * Gets the number of milliseconds difference allowed before considering
	 * two datetimes as different. This is currently set to allow for
	 * millisecond level differences.
	 *
	 * @type {number}
	 * @memberof ObjectHelper
	 */
	private static readonly dateComparisonDelta: number = 1000;

	/**
	 * Filter an array of objects by the sent filter by property.
	 *
	 * @static
	 * @param {object} item
	 * The item to be evaluated.
	 * @param {string} filterBy
	 * The string representing the property to filter by.
	 * @returns {boolean}
	 * The filtered value matching the criteria sent in.
	 * @memberof ObjectHelper
	 */
	public static filterByNumberValue(
		item: object,
		filterBy: string): boolean
	{
		return item.hasOwnProperty(filterBy)
			&& typeof item[filterBy] === 'number';
	}

	/**
	 * Sort array of objects by a property key.
	 *
	 * @static
	 * @param {object} objectOne
	 * The first Item to be compared.
	 * @param {object} objectTwo
	 * The second Item to be compared.
	 * @param {string} sortBy
	 * The string representing the property to sort by.
	 * @param {boolean} useDateComparison
	 * If sent and true, this will use a date based conversion and comparison
	 * of the sent property. This value defaults to false.
	 * @returns {number}
	 * The comparison value of the sorted object.
	 * @memberof ObjectHelper
	 */
	public static sortByPropertyValue(
		objectOne: object,
		objectTwo: object,
		sortBy: string,
		useDateComparison: boolean = false): number
	{
		const objectOneData: any =
			get(objectOne, sortBy);
		const objectTwoData: any =
			get(objectTwo, sortBy);
		const propertyOne: any =
			useDateComparison === true
				? DateTime.fromISO(objectOneData)
				: objectOneData;
		const propertyTwo: any =
			useDateComparison === true
				?  DateTime.fromISO(objectTwoData)
				: objectTwoData;

		if (propertyOne < propertyTwo
			|| (AnyHelper.isNullOrWhitespace(propertyOne)
				&& !AnyHelper.isNullOrWhitespace(propertyTwo)))
		{
			return -1;
		}

		if (propertyOne > propertyTwo
			|| (!AnyHelper.isNullOrWhitespace(propertyOne)
				&& AnyHelper.isNullOrWhitespace(propertyTwo)))
		{
			return 1;
		}

		return 0;
	}

	/**
	 * Sorts an array of objects based on an acceptable api filter order value
	 * manually. This is used when querying multiple result sets so that
	 * the combined value remains in the expected sort order.
	 * @note This will sort up to two levels deep currently and will handle
	 * inputs in data and without such as 'name desc, id asc'.
	 *
	 * @static
	 * @param {any[]} data
	 * The set of objects to be sorted programatically.
	 * @param {string} sortOrder
	 * The string value of the order by that would be sent to an api endpoint.
	 * @returns {any[]}
	 * The set of values sorted manually matching the sent order by.
	 * @memberof ObjectHelper
	 */
	public static handleOrderBySort(
		data: any[],
		sortOrder: string): any[]
	{
		// Manually handle up to two sort order values.
		const sortSplitout: string[] =
			sortOrder.split(AppConstants.characters.comma);
		if (AnyHelper.isNullOrWhitespace(sortSplitout[0]))
		{
			return data;
		}

		// Primary sort.
		const primarySplitSort: string[] =
			sortSplitout[0].split(AppConstants.characters.space);
		const primarySortKey =
			this.getSortKey(primarySplitSort);
		const primarySortDirection: number =
			this.getSortDirection(primarySplitSort);

		// Secondary sort if applicable.
		const secondarySplitSort: string[] =
			sortSplitout.length > 1
				? StringHelper
					.trim(sortSplitout[1])
					.split(AppConstants.characters.space)
				: [];

		let secondarySortKey: string = null;
		let secondarySortDirection: number = 1;
		if (secondarySplitSort.length > 0)
		{
			secondarySortKey =
				this.getSortKey(secondarySplitSort);
			secondarySortDirection =
				this.getSortDirection(secondarySplitSort);
		}

		// Manually sort this data.
		data.sort(
			(objectOne: any,
				objectTwo: any) =>
				this.compareTwoObjects(
					objectOne,
					objectTwo,
					primarySortKey,
					primarySortDirection,
					secondarySortKey,
					secondarySortDirection));

		return data;
	}

	/**
	 * Compares two values and returns a boolean that defines whether or not
	 * these objects are equal. The response result is considered equal in terms
	 * of business logic purposes.
	 * @note For the purposes of this equality check nulls and empty strings
	 * are treated as equal, nulls and objects that will evaluate as null are
	 * treated the same, and string based dates that have become javascript
	 * dates will be converted and checked for equality as well.
	 *
	 * @static
	 * @param {object} initialObject
	 * The initial value to be evaluated.
	 * @param {object} comparisonObject
	 * The comparison value to be checked.
	 * @memberof ObjectHelper
	 * A boolean value representing whether or not the two sent objects are
	 * equal.
	 */
	public static checkBusinessLogicEquality(
		initialObject: object,
		comparisonObject: object): boolean
	{
		const initialObjectKeys = Object.keys(initialObject ?? {});
		const comparisonObjectKeys = Object.keys(comparisonObject ?? {});

		if (initialObjectKeys.length !== comparisonObjectKeys.length)
		{
			// Array size differences are not equivalent.
			if (isArray(initialObject) && isArray(comparisonObject))
			{
				return false;
			}

			// If an object holds a value that is non-null, not a resource
			// identifier, and different the object is altered.
			if (!this.checkNullBusinessLogicObjectEquality(
				comparisonObjectKeys.filter(
					(propertyKey: string) =>
						propertyKey !==
							AppConstants.commonProperties.resourceIdentifier
							&& !initialObjectKeys.includes(
								propertyKey)),
				comparisonObject)
				|| !this.checkNullBusinessLogicObjectEquality(
					initialObjectKeys.filter(
						(propertyKey: string) =>
							propertyKey !==
								AppConstants.commonProperties.resourceIdentifier
								&& !comparisonObjectKeys.includes(
									propertyKey)),
					initialObject))
			{
				return false;
			}
		}

		let altered: boolean = false;

		for (const key of initialObjectKeys)
		{
			// Check nested properties for differences.
			altered =
				this.checkNestedBusinessLogicEquality(
					initialObject[key],
					comparisonObject[key]);

			if (altered === true)
			{
				return false;
			}
		}

		return !altered;
	}

	/**
	 * Compares two values and returns a collection of differences that defines
	 * whether or not these objects are equal. The response result is
	 * considered equal in terms of business logic purposes.
	 * @note For the purposes of this equality check nulls and empty strings
	 * are treated as equal, nulls, arrays, and objects that will evaluate as
	 * null are treated the same, and string based dates that have become
	 * javascript dates will be converted and checked for equality as well.
	 * @note Array values must have resource identifiers decorated. This
	 * is used to differentiate array inputs.
	 *
	 * @static
	 * @param {string} key
	 * The key value of the object being checked.
	 * @param {any} initialObject
	 * The initial value to be evaluated.
	 * @param {any} comparisonObject
	 * The comparison value to be checked.
	 * @param {string[]} excludedPropertyKeys
	 * The set of explicit fully defined property keys to remove as well
	 * as possible wildcards that will remove any property of that sent name.
	 * @returns {IDifferenceDefinition[]}
	 * A collection of difference definitions that will define all differences
	 * if they exist between the two objects.
	 * @memberof ObjectHelper
	 */
	public static getBusinessLogicDifferences(
		key: string,
		initialObject: any,
		comparisonObject: any,
		excludedPropertyKeys: string[] = []): IDifferenceDefinition[]
	{
		let initialObjectValue: any = initialObject;
		let comparisonObjectValue: any = comparisonObject;
		let differences: IDifferenceDefinition[] = [];

		let initialObjectKeys: string[] =
			this.getFilteredPropertyKeys(
				initialObjectValue,
				key,
				excludedPropertyKeys);
		const comparisonObjectKeys: string[] =
			this.getFilteredPropertyKeys(
				comparisonObjectValue,
				key,
				excludedPropertyKeys);

		if (initialObjectKeys.length !== comparisonObjectKeys.length
			&& !isArray(initialObjectValue)
			&& !isArray(comparisonObjectValue)
			&& !(typeof(initialObjectValue) ===
				AppConstants.propertyTypes.string
				|| typeof(comparisonObjectValue) ===
					AppConstants.propertyTypes.string))
		{
			// Ensure all properties exist for comparison.
			initialObjectValue =
				this.createMissingProperties(
					initialObjectValue,
					comparisonObjectValue,
					initialObjectKeys,
					comparisonObjectKeys);
			comparisonObjectValue =
				this.createMissingProperties(
					comparisonObjectValue,
					initialObjectValue,
					comparisonObjectKeys,
					initialObjectKeys);
			initialObjectKeys =
				this.getFilteredPropertyKeys(
					initialObjectValue,
					key,
					excludedPropertyKeys);
		}

		if (isArray(initialObjectValue) && isArray(comparisonObjectValue))
		{
			// Get deleted or added items.
			initialObjectValue =
				orderBy(initialObjectValue || []);
			comparisonObjectValue =
				orderBy(comparisonObjectValue || []);

			const uniqueInitialValues: any[] =
				differenceWith(
					initialObjectValue,
					comparisonObjectValue,
					this.isArrayItemUnique.bind(this));
			const uniqueComparisonValues: any[] =
				differenceWith(
					comparisonObjectValue,
					initialObjectValue,
					this.isArrayItemUnique.bind(this));

			if (uniqueInitialValues.length > 0
				|| uniqueComparisonValues.length > 0)
			{
				differences.push(
					this.getDifferenceDefinition(
						`${key}`,
						this.sortBusinessLogicArray(
							uniqueInitialValues || []),
						this.sortBusinessLogicArray(
							uniqueComparisonValues || []),
						AppConstants.differenceTypes.array));
			}
		}

		for (const objectKey of initialObjectKeys)
		{
			// Get differences in nested objects.
			const nestedDifferences: IDifferenceDefinition[] =
				this.getNestedBusinessLogicDifferences(
					key,
					objectKey,
					initialObjectValue[objectKey],
					comparisonObjectValue[objectKey],
					excludedPropertyKeys);

			differences =
				differences.concat(
					nestedDifferences);
		}

		return differences;
	}

	/**
	 * Checks a list of properties of an object and returns a boolean that
	 * defines whether or not all properties of the object are equal to null or
	 * an empty string. The response result is considered equal in terms of
	 * business logic purposes.
	 * @note For the purposes of this equality check nulls and empty strings
	 * are treated as equal as well as nulls and objects that hold only values
	 * of null or empty strings.
	 *
	 * @static
	 * @param {string[]} keysToCheck
	 * The set of keys to be evaluated for null equivalency on this object.
	 * @param {object} objectToCheck
	 * The object value to be checked.
	 * @returns {boolean}
	 * A boolean value representing whether or not all of the sent properties
	 * are equivalent to null in terms of business logic.
	 * @memberof ObjectHelper
	 */
	public static checkNullBusinessLogicObjectEquality(
		keysToCheck: string[],
		objectToCheck: object): boolean
	{
		let nullEqivalent: boolean = true;

		for (const key of keysToCheck.filter(
			(keyToCheck: string) =>
				keyToCheck !==
					AppConstants.commonProperties.resourceIdentifier))
		{
			const propertyValue: any =
				objectToCheck[key];

			if (isObject(propertyValue))
			{
				nullEqivalent = isArray(propertyValue)
					? propertyValue.length === 0
					: this.checkNullBusinessLogicObjectEquality(
						Object.keys(propertyValue),
						propertyValue);
			}
			else
			{
				nullEqivalent = AnyHelper.isNullOrWhitespace(propertyValue);
			}

			if (nullEqivalent !== true)
			{
				return false;
			}
		}

		return nullEqivalent;
	}

	/**
	 * Checks a list of properties of an object and cleans any object or value
	 * that is null equivalent. An object holding properties that are all null
	 * or whitespace or an array holding an object with all null or whitespace
	 * properties are considered null equivalent for this clean operation.
	 *
	 * @static
	 * @param {object} objectToClean
	 * The object value to be clean.
	 * @memberof ObjectHelper
	 */
	public static removeNullsInObject(
		objectToClean: any): void
	{
		if (typeof objectToClean === AppConstants.variableTypes.string
			|| objectToClean === AppConstants.empty)
		{
			return;
		}

		each(objectToClean,
			(value: any,
				key: string) =>
			{
				if (AnyHelper.isNullOrWhitespace(value))
				{
					delete objectToClean[key];
				}
				else if (isArray(value))
				{
					if (value.length === 0 )
					{
						delete objectToClean[key];

						return;
					}

					each(
						value,
						function(itemValue: any)
						{
							this.removeNullsInObject(itemValue);
						}.bind(this));

					const filteredItems =
						value.filter(
							(item: any) =>
								!AnyHelper.isNull(item)
									&& Object.keys(item).length > 0);

					objectToClean[key] = filteredItems;
					if (filteredItems.length === 0)
					{
						delete objectToClean[key];
					}
				}
				else if (typeof value === AppConstants.variableTypes.object
					&& !(value instanceof DateTime))
				{
					if (Object.keys(value).length === 0)
					{
						delete objectToClean[key];

						return;
					}

					this.removeNullsInObject(value);

					if (Object.keys(value).length === 0)
					{
						delete objectToClean[key];
					}
				}
			});
	}

	/**
	 * Returns query parameters that match the expected route data format from
	 * a sent object.
	 *
	 * @static
	 * @param {object} mapObject
	 * The object to map into a route data object.
	 * @returns {Params}
	 * Query parameters with the expected route data object.
	 * @memberof ObjectHelper
	 */
	public static mapRouteData(
		mapObject: object): string
	{
		return LZString.compressToEncodedURIComponent(
			JSONCrush.crush(JSON.stringify(mapObject)));
	}

	/**
	 * Returns mapped route data from the sent parameters subscribed to in
	 * a component expecting route data.
	 *
	 * @static
	 * @param {Params} parameters
	 * The parameters value to pull route data from.
	 * @returns {any}
	 * An expected parsed object value for the route data url parameters.
	 * @memberof ObjectHelper
	 */
	public static mapFromRouteData(
		parameters: Params): any
	{
		return AnyHelper.isNull(parameters)
			|| AnyHelper.isNullOrEmpty(
				parameters[AppConstants.urlParameters.routeData])
			? {}
			: JSON.parse(
				JSONCrush.uncrush(
					LZString.decompressFromEncodedURIComponent(
						parameters[
							AppConstants.urlParameters.routeData])));
	}

	/**
	 * Given a sent object, this will map the UI description for that object
	 * based on the sent property keys. If any value is null or an empty string,
	 * this combined description will return as an emprt string.
	 *
	 * @static
	 * @param {object} item
	 * The object to get a description for.
	 * @param {IDescriptionDisplayDefinition[]} propertyKeys
	 * The set of description display definitions to map into an object
	 * description.
	 * @returns {string}
	 * A string based description of the object or an empty string if
	 * description level values are null or an empty string.
	 * @memberof ObjectHelper
	 */
	public static getObjectDescription(
		item: object,
		propertyKeys: IDescriptionDisplayDefinition[]):
		string
	{
		let description: string = AppConstants.empty;
		for (const propertyKey of propertyKeys)
		{
			if (!AnyHelper.isNullOrWhitespace(
				propertyKey.inlineValue))
			{
				description += propertyKey.inlineValue;
			}
			else
			{
				const value =
					get(item, propertyKey.key);

				if (AnyHelper.isNullOrWhitespace(value))
				{
					return AppConstants.empty;
				}

				const formattedValue: string =
					StringHelper.format(
						value,
						propertyKey.outputFormat);

				description +=
					(AnyHelper.isNullOrWhitespace(description)
						? AppConstants.empty
						: AppConstants.characters.space)
						+ formattedValue;
			}
		}

		return description;
	}

	/**
	 * Add or updates a property.
	 *
	 * @param {object} item
	 * The object on which to add or update the property.
	 * @param {object} propertyKey
	 * The property key/name.
	 * @param {object} propertyValue
	 * The new property value.
	 * @memberof ObjectHelper
	 */
	public static addOrUpdateProperty(
		item: object,
		propertyKey: string,
		propertyValue: any): void
	{
		const properties: object =
			Object.fromEntries(
				[
					[propertyKey, propertyValue]
				]);

		this.addOrUpdateProperties(item, properties);
	}

	/**
	 * Add or updates a properties.
	 *
	 * @param {object} item
	 * The object on which to add or update the properties.
	 * @param {object} properties
	 * The properties and values to add or update.
	 * @memberof ObjectHelper
	 */
	public static addOrUpdateProperties(
		item: object,
		properties: object): void
	{
		Object.assign(item, properties);
	}

	/**
	 * Given a sent object, this will check each property key in that object
	 * to ensure that it holds a value. Null, undefined, and empty strings
	 * will all evaluate to false.
	 *
	 * @static
	 * @param {object} item
	 * The object to check for existing property values.
	 * @param {object} propertyKeys
	 * The set of object level properties to find on the sent object. This can
	 * included nested lookups.
	 * @returns {boolean}
	 * A value signifying whether or not all property values are set for each
	 * property key sent.
	 * @memberof ObjectHelper
	 */
	public static allPropertyValuesExist(
		item: object,
		propertyKeys: string[]): boolean
	{
		for (const propertyKey of propertyKeys)
		{
			if (AnyHelper.isNullOrWhitespace(
				get(item, propertyKey)))
			{
				return false;
			}
		}

		return true;
	}

	/**
	 * Given a sent array and a possible comparison array, this value will
	 * return the business logic or resource identifier based equivalent
	 * array.
	 * @note
	 * This method will add array placeholder objects when the comparison
	 * array is sent but no match was found in the sent array to enable
	 * a business logic comparison.
	 *
	 * @static
	 * @param {any[]} array
	 * The array to be sorted.
	 * @param {any[]} itemTwo
	 * The comparison array that should be used to order object based arrays
	 * in a matching business logic order.
	 * @returns {any[]}
	 * A sorted business logic array. If these values are primitives they will
	 * return sorted with default logic. If these are object based arrays these
	 * will return sorted by type if applicable and then by resource identifier.
	 * @memberof ObjectHelper
	 */
	public static sortBusinessLogicArray(
		array: any[],
		comparisonArray: any[] = []): any[]
	{
		const isObjectArray: boolean =
			array?.length > 0 && isObject(array[0]);

		const sortedArray: any[] =
			orderBy(
				array,
				isObjectArray === false
					? []
					: [
						AppConstants.commonProperties.type,
						AppConstants.commonProperties.resourceIdentifier
					]);

		if (isObjectArray === false
			|| comparisonArray.length === 0)
		{
			return sortedArray;
		}

		const sortedByComparisonArray: any[] = [];
		for (const comparisonArrayItem of comparisonArray)
		{
			const matchingArrayIndex: number =
				sortedArray.findIndex(
					(arrayItem: any) =>
						arrayItem.resourceIdentifier ===
							comparisonArrayItem.resourceIdentifier);

			sortedByComparisonArray.push(
				matchingArrayIndex !== -1
					? sortedArray.splice(matchingArrayIndex, 1)[0]
					: { arrayPlaceholder: true });
		}

		return <any[]>
			[
				...sortedByComparisonArray,
				...sortedArray
			];
	}

	/**
	 * Creates and returns a difference definition of the sent values.
	 *
	 * @static
	 * @param {string} key
	 * The key for this difference definition.
	 * @param {any} originalValue
	 * The original value that has been altered.
	 * @param {any} updatedValue
	 * The updated value this was altered to.
	 * @param {string} differenceType
	 * The mapped difference type.
	 * @returns {IDifferenceDefinition}
	 * A difference definition with sufficient information to display
	 * differences.
	 * @memberof ObjectHelper
	 */
	private static getDifferenceDefinition(
		key: string,
		originalValue: any,
		updatedValue: any,
		differenceType: string): IDifferenceDefinition
	{
		return <IDifferenceDefinition>
			{
				key: key,
				originalValue: originalValue,
				updatedValue: updatedValue,
				differenceType: differenceType
			};
	}

	/**
	 * Gets and returns a set of property keys that are applicable for the
	 * sent object.
	 *
	 * @static
	 * @param {object} objectValue
	 * The object that requires property filters.
	 * @param {string} nestedKey
	 * The nested location of this object used to find explicit properties to
	 * remove.
	 * @param {string[]} excludedPropertyKeys
	 * The set of explicit fully defined property keys to remove as well
	 * as possible wildcards that will remove any property of that sent name.
	 * @returns {string[]}
	 * The set of property keys that are applicable following the filter logic.
	 * @memberof ObjectHelper
	 */
	private static getFilteredPropertyKeys(
		objectValue: object,
		nestedKey: string,
		excludedPropertyKeys: string[]): string[]
	{
		if (typeof(objectValue) === AppConstants.propertyTypes.string)
		{
			return [];
		}

		return Object.keys(objectValue)
			.filter(
				(filterKey: string) =>
					excludedPropertyKeys.indexOf(filterKey) === -1
						&& excludedPropertyKeys.indexOf(
							`${nestedKey}.${filterKey}`) === -1);
	}

	/**
	 * Given an object and set of properties, this method will decorate the
	 * object by adding null definitions where the property does not yet
	 * exist.
	 *
	 * @static
	 * @param {object} objectValue
	 * The object that should have properties mapped to equal the desired
	 * object.
	 * @param {object} desiredObject
	 * The object that should have properties mapped into the object value.
	 * @param {string[]} objectKeys
	 * The set of properties that exist on this initial object.
	 * @param {string[]} desiredObjectKeys
	 * The set of properties that when missing will be added to this object.
	 * @returns {object}
	 * A decorated object holding null valued properties for any missing
	 * desired object key.
	 * @memberof ObjectHelper
	 */
	private static createMissingProperties(
		objectValue: object,
		desiredObject: object,
		objectKeys: string[],
		desiredObjectKeys: string[]): object
	{
		const keyDifferences: string[] =
			desiredObjectKeys.filter(
				(propertyKey: string) =>
					!objectKeys.includes(
						propertyKey));

		for (const keyDifference of keyDifferences)
		{
			let newValue: any = null;
			if (isArray(desiredObject[keyDifference]))
			{
				newValue = [];
			}
			else if (isObject(desiredObject[keyDifference]))
			{
				newValue = {};
			}

			objectValue[keyDifference] = newValue;
		}

		return objectValue;
	}

	/**
	 * Given a set of two array items, this value will return a value signifying
	 * if it is unique. In the case of primitives this comparison will be one
	 * to one, but in the case of object checks, this value will be based
	 * on a matching resource identifier.
	 * @throws {Error}
	 * This method will throw an error if an array object item is sent and
	 * a resouce identifier does not exist.
	 *
	 * @static
	 * @param {any} itemOne
	 * The first item to be compared.
	 * @param {any} itemTwo
	 * The second item to be compared.
	 * @returns {boolean}
	 * A value signifying whether or not this array item is unique as defined.
	 * @memberof ObjectHelper
	 */
	private static isArrayItemUnique(
		itemOne: any,
		itemTwo: any): boolean
	{
		if (isObject(itemOne) || isObject(itemTwo))
		{
			if (this.isArrayPlaceholder(itemOne) === true)
			{
				return true;
			}

			if (AnyHelper.isNull(itemOne.resourceIdentifier)
				&& AnyHelper.isNull(itemTwo.resourceIdentifier))
			{
				throw new Error(
					'The array item unique method requires a resource '
						+ 'identifier in each item for object based arrays.');
			}

			return itemOne.resourceIdentifier === itemTwo.resourceIdentifier;
		}

		return isEqual(
			itemOne,
			itemTwo);
	}

	/**
	 * Checks an object to see if the value is an array placeholder or returns
	 * false if it is a primitive value.
	 *
	 * @static
	 * @param {any} item
	 * The item to check for a match of an array placeholder object.
	 * @returns {boolean}
	 * A value that signifies whether or not the item sent in is an object
	 * and also matches an array placeholder object.
	 * @memberof ObjectHelper
	 */
	private static isArrayPlaceholder(
		item: any): boolean
	{
		return isObject(item) && item[this.arrayPlaceholderIdentifier] === true;
	}

	/**
	 * Given a possible primitive, complex object, or array based value, this
	 * method will check for any differences and if found return as true.
	 *
	 * @static
	 * @param {any} initialValue
	 * The initial value to compare.
	 * @param {any} comparisonValue
	 * The item to compare with the initial value for possible differences.
	 * @returns {boolean}
	 * A value signifying whether or not the two sent objects are equal in
	 * business logic terms. Null, empty strings, empty objects, empty arrays,
	 * and undefined are all considered equal in terms of business logic
	 * differences.
	 * @memberof ObjectHelper
	 */
	private static checkNestedBusinessLogicEquality(
		initialValue: any,
		comparisonValue: any): boolean
	{
		let altered: boolean = false;

		if (DateTime.fromISO(initialValue).isValid
			&& DateTime.fromISO(comparisonValue).isValid)
		{
			altered =
				Math.abs(
					DateHelper.fromUtcIso(initialValue)
						.diff(
							DateHelper.fromUtcIso(
								comparisonValue))
						.toMillis()) > this.dateComparisonDelta;
		}
		else if (isArray(initialValue) && isArray(comparisonValue))
		{
			altered =
				!this.checkBusinessLogicEquality(
					orderBy(initialValue || []),
					orderBy(comparisonValue || []));
		}
		else if (isObject(initialValue) && isObject(comparisonValue))
		{
			altered =
				!this.checkBusinessLogicEquality(
					initialValue,
					comparisonValue);
		}
		else
		{
			altered =
				!isEqual(
					AnyHelper.isNullOrWhitespace(initialValue)
						? null
						: initialValue,
					AnyHelper.isNullOrWhitespace(comparisonValue)
						? null
						: comparisonValue);
		}

		return altered;
	}

	/**
	 * Given a possible primitive, complex object, or array based value, this
	 * method will check for any differences and if found return the combined
	 * set of differences for this object.
	 *
	 * @static
	 * @param {string} key
	 * The key value representing the current data location.
	 * @param {string} nestedKey
	 * The nested key value representing the nested property being checked
	 * for differences.
	 * @param {any} initialValue
	 * The initial value to compare.
	 * @param {any} comparisonValue
	 * The item to compare with the initial value for possible differences.
	 * @param {any} excludedPropertyKeys
	 * The current object being checked. This value is used to keep
	 * a reference to the object holding these differences for later lookups.
	 * @returns {IDifferenceDefinition[]}
	 * A set of difference definitions found between the initial and comparison
	 * value.
	 * @memberof ObjectHelper
	 */
	private static getNestedBusinessLogicDifferences(
		key: string,
		nestedKey: string,
		initialValue: any,
		comparisonValue: any,
		excludedPropertyKeys: string[]): IDifferenceDefinition[]
	{
		let differences: IDifferenceDefinition[] = [];
		const isObjectComparison: boolean =
			isObject(initialValue) || isObject(comparisonValue);

		if (this.isDateComparisonDifference(
			initialValue,
			comparisonValue) === true)
		{
			differences.push(
				this.getDifferenceDefinition(
					`${key}.${nestedKey}`,
					initialValue,
					comparisonValue,
					AppConstants.differenceTypes.date));

			return differences;
		}

		if (isArray(initialValue) || isArray(comparisonValue))
		{
			const sortedInitialValue: any[] =
				this.sortBusinessLogicArray(initialValue || []);
			const sortedComparisonValue: any[] =
				this.sortBusinessLogicArray(
					(comparisonValue || []),
					sortedInitialValue);

			differences =
				differences.concat(
					this.getBusinessLogicDifferences(
						`${key}.${nestedKey}`,
						sortedInitialValue,
						sortedComparisonValue,
						excludedPropertyKeys));

			return differences;
		}

		if (isObjectComparison === true)
		{
			if (this.isArrayPlaceholder(initialValue) !== true
				&& this.isArrayPlaceholder(comparisonValue) !== true)
			{
				differences =
					differences.concat(
						this.getBusinessLogicDifferences(
							`${key}.${nestedKey}`,
							initialValue || {},
							comparisonValue || {},
							excludedPropertyKeys));
			}

			return differences;
		}

		if (!isEqual(
			AnyHelper.isNullOrWhitespace(initialValue)
				? null
				: initialValue,
			AnyHelper.isNullOrWhitespace(comparisonValue)
				? null
				: comparisonValue))
		{
			differences.push(
				this.getDifferenceDefinition(
					`${key}.${nestedKey}`,
					initialValue,
					comparisonValue,
					AppConstants.differenceTypes.property));
		}

		return differences;
	}

	/**
	 * Given an initial value and comparison value, this will confirm
	 * that the sent values are ISO strings that are valid and that the
	 * difference in time is sufficient to be considered a date time
	 * difference.
	 *
	 * @static
	 * @param {any} initialValue
	 * The initial value to compare.
	 * @param {any} comparisonValue
	 * The item to compare with the initial value for possible differences.
	 * @returns {boolean}
	 * A value signifying whether or not date comparison differences exist.
	 * @memberof ObjectHelper
	 */
	private static isDateComparisonDifference(
		initialValue: any,
		comparisonValue: any): boolean
	{
		return (!AnyHelper.isNullOrWhitespace(initialValue)
			&& typeof(initialValue) === AppConstants.propertyTypes.string
			|| !AnyHelper.isNullOrWhitespace(comparisonValue)
				&& typeof(comparisonValue) === AppConstants.propertyTypes.string)
			&& (DateHelper.fromUtcIso(initialValue).isValid
				|| DateHelper.fromUtcIso(comparisonValue).isValid)
			&& Math.abs(
				DateHelper
					.fromUtcIso(initialValue)
					.diff(DateHelper.fromUtcIso(comparisonValue))
					.toMillis()) > this.dateComparisonDelta;
	}

	/**
	 * Given a split singular sort value such as 'name desc', this will get the
	 * expected sort key.
	 *
	 * @static
	 * @param {string[]} splitSingularOrderBy
	 * The string array of the singular order by value split by spaces.
	 * @returns {string}
	 * The key to be used for in place sorts.
	 * @memberof ObjectHelper
	 */
	private static getSortKey(
		splitSingularOrderBy: string[]): string
	{
		return splitSingularOrderBy[0] === AppConstants.commonProperties.id
			? splitSingularOrderBy[0]
			: AppConstants.nestedDataIdentifier + splitSingularOrderBy[0];
	}

	/**
	 * Given a split singular sort value such as 'name desc', this will get the
	 * expected sort direction.
	 *
	 * @static
	 * @param {string[]} splitSingularOrderBy
	 * The string array of the singular order by value split by spaces.
	 * @returns {number}
	 * The number value representing the direction where -1 is descending.
	 * @memberof ObjectHelper
	 */
	private static getSortDirection(
		splitSingularOrderBy: string[]): number
	{
		return splitSingularOrderBy.length > 1
			&& !AnyHelper.isNullOrWhitespace(splitSingularOrderBy[1])
			&& splitSingularOrderBy[1] ===
				AppConstants.sortDirections.descending
			? -1
			: 1;
	}

	/**
	 * Given two objects and a primary with an option sort key and direction,
	 * this will return the comparison value of the two objects. 1 will be
	 * greater than, -1 will be less than, and 0 will be equal.
	 *
	 * @static
	 * @param {any} objectOne
	 * The first value to compare.
	 * @param {any} objectTwo
	 * The second value to compare.
	 * @param {string} primarySortKey
	 * The primary sort key defining the property to sort by.
	 * @param {string} primarySortDirection
	 * The primary sort direction defining the order to sort by.
	 * @param {string} secondarySortKey
	 * The secondary sort key defining the property to sort by if the first sort
	 * is equal.
	 * @param {string} secondarySortDirection
	 * The secondary sort direction defining the order to sort by if the first
	 * sort is equal.
	 * @returns {number}
	 * A value representing the first values sorted weight, 1 will be greater
	 * than, -1 will be less than, and 0 will be equal.
	 * @memberof ObjectHelper
	 */
	private static compareTwoObjects(
		objectOne: any,
		objectTwo: any,
		primarySortKey: string,
		primarySortDirection: number,
		secondarySortKey: string,
		secondarySortDirection: number): number
	{
		const firstValue: any =
			get(objectOne, primarySortKey)
				?.toString()
				.toLowerCase();
		const secondValue: any =
			get(objectTwo, primarySortKey)
				?.toString()
				.toLowerCase();

		if (firstValue < secondValue
			|| (AnyHelper.isNullOrWhitespace(firstValue)
				&& !AnyHelper.isNullOrWhitespace(secondValue)))
		{
			return -1 * primarySortDirection;
		}

		if (firstValue > secondValue
			|| (!AnyHelper.isNullOrWhitespace(firstValue)
				&& AnyHelper.isNullOrWhitespace(secondValue)))
		{
			return 1 * primarySortDirection;
		}

		const firstSecondarySortValue: any =
			get(objectOne, secondarySortKey)
				?.toString()
				.toLowerCase();
		const secondSecondarySortValue: any =
			get(objectTwo, secondarySortKey)
				?.toString()
				.toLowerCase();

		if (firstSecondarySortValue < secondSecondarySortValue
			|| (AnyHelper.isNullOrWhitespace(firstSecondarySortValue)
				&& !AnyHelper.isNullOrWhitespace(
					secondSecondarySortValue)))
		{
			return -1 * secondarySortDirection;
		}

		if (firstSecondarySortValue > secondSecondarySortValue
			|| (!AnyHelper.isNullOrWhitespace(firstSecondarySortValue)
				&& AnyHelper.isNullOrWhitespace(
					secondSecondarySortValue)))
		{
			return 1 * secondarySortDirection;
		}

		return 0;
	}
}
