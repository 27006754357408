<div class="ui-g-12"
	*ngIf="loading">
	<span class="center-spinner loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
	</span>
</div>

<span *ngIf="!loading">
	<div class="ui-g-12 no-padding">
		<div class="section-margin">
			<app-dynamic-formly
				[dataSet]="context.data"
				[layoutSchema]="staticFormlyLayout"
				[context]="context"
				(validityChanged)="staticFormValidity($event)">
			</app-dynamic-formly>
		</div>
	</div>
	<div class="ui-g-12 no-horizontal-padding"
		*ngIf="!loadingDynamicLayout">
		<div class="section-margin">
			<app-dynamic-formly
				[dataSet]="context.data"
				[layoutSchema]="dynamicFormlyLayout"
				(validityChanged)="dynamicFormValidity($event)">
			</app-dynamic-formly>
		</div>
	</div>

	<div class="ui-g-12">
		<operation-button-bar
			[initialModel]="context.source.expandActions"
			(loadingOperations)="context.source.operationButtonClicked($event)">
		</operation-button-bar>
	</div>
</span>