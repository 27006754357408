/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/**
 * A class representing the available methods (business logic) for an
 * entity layout.
 *
 * @export
 * @class EntityType
 * @implements {IEntityType}
 */
export class EntityType implements IEntityType
{
	/**
	 * Creates an instance of an EntityType.
	 *
	 * @param {IEntityType} iEntityType
	 * The entity type interface to create this new
	 * object from.
	 * @memberof EntityType
	 */
	public constructor(
		public iEntityType: IEntityType)
	{
		Object.assign(this, iEntityType);
	}

	/**
	 * Gets or sets the id.
	 *
	 * @type {number}
	 * @memberof EntityDefinition
	 */
	public id: number;

	/**
	 * Gets or sets the name.
	 *
	 * @type {string}
	 * @memberof EntityType
	 */
	public name: string;

	/**
	 * Gets or sets the group.
	 *
	 * @type {string}
	 * @memberof EntityType
	 */
	public group: string;

	/**
	* Gets or sets the ownership public or read allowed value.
	*
	* @type {boolean}
	* @memberof EntityType
	*/
	public public: boolean;

	/**
	* Gets or sets the ownership security group or alter allowed value.
	*
	* @type {number}
	* @memberof EntityType
	*/
	public ownershipSecurityGroupId: number;

	/**
	* Gets or sets the created by id. This user will have read and alter
	* permissions.
	*
	* @type {number}
	* @memberof EntityType
	*/
	public createdById: number;

	/**
	 * Gets the display name for this, which is defined as
	 * the last element in a '.' delimited string.
	 *
	 * @type {string}
	 * @memberof EntityType
	 */
	public get displayName(): string
	{
		return StringHelper.beforeCapitalSpaces(
			StringHelper.getLastSplitValue(
				this.name,
				AppConstants.characters.period));
	}
}