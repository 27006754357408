/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AddDrawerAction
} from '@operation/actions/add-drawer-action';
import {
	AddRelatedContextMenuAction
} from '@operation/actions/add-related-context-menu-action';
import {
	ChangeLayoutColorAction
} from '@operation/actions/change-layout-color-action';
import {
	ChangeThemeAction
} from '@operation/actions/change-theme-action';
import {
	ContextOperationAction
} from '@operation/actions/context-operation-action';
import {
	DeleteEntityAction
} from '@operation/actions/delete-entity-action';
import {
	EmitEventAction
} from '@operation/actions/emit-event-action';
import {
	ExecuteOperationPromiseAction
} from '@operation/actions/execute-operation-promise-action';
import {
	ExecuteWorkflowAction
} from '@operation/actions/execute-workflow-action';
import {
	GenerateDocumentAction
} from '@operation/actions/generate-document-action';
import {
	GenerateFilesAction
} from './actions/generate-files-action';
import {
	InjectionToken
} from '@angular/core';
import {
	LogOutAction
} from '@operation/actions/logout-action';
import {
	NavigationAction
} from '@operation/actions/navigation-action';
import {
	PrintFileAction
} from '@operation/actions/print-file-action';
import {
	SaveEntityAction
} from '@operation/actions/save-entity-action';

/*
 * Export all tokens to be used in the dependency injector for
 * string based resolution
*/
export const AddDrawerActionToken =
	new InjectionToken<AddDrawerAction>(
		'AddDrawer');
export const AddRelatedContextMenuActionToken =
	new InjectionToken<AddRelatedContextMenuAction>(
		'AddRelatedContextMenu');
export const ChangeLayoutColorActionToken =
	new InjectionToken<ChangeLayoutColorAction>(
		'ChangeLayoutColor');
export const ChangeThemeActionToken =
	new InjectionToken<ChangeThemeAction>(
		'ChangeTheme');
export const ContextOperationActionToken =
	new InjectionToken<ContextOperationAction>(
		'ContextOperation');
export const DeleteEntityActionToken =
	new InjectionToken<DeleteEntityAction>(
		'DeleteEntity');
export const EmitEventActionToken =
	new InjectionToken<EmitEventAction>(
		'EmitEvent');
export const ExecuteOperationPromiseActionToken =
	new InjectionToken<ExecuteOperationPromiseAction>(
		'ExecuteOperationPromise');
export const ExecuteWorkflowActionToken =
	new InjectionToken<ExecuteWorkflowAction>(
		'ExecuteWorkflow');
export const GenerateDocumentActionToken =
	new InjectionToken<GenerateDocumentAction>(
		'GenerateDocument');
export const GenerateFilesActionToken =
	new InjectionToken<GenerateFilesAction>(
		'GenerateFiles');
export const LogOutActionToken =
	new InjectionToken<LogOutAction>(
		'LogOut');
export const NavigationActionToken =
	new InjectionToken<NavigationAction>(
		'Navigation');
export const PrintFileActionToken =
	new InjectionToken<PrintFileAction>(
		'PrintFile');
export const SaveEntityActionToken =
	new InjectionToken<SaveEntityAction>(
		'SaveEntity');

/**
 * A lookup to be shared accross the application used
 * for operation based dynamic injection.
 *
 * @export
 * @class OperationTokenLookup
 */
export class OperationTokenLookup
{
	/**
	 * Dictionary to be used in dependency injector for
	 * string based resolution.
	 *
	 * @static
	 * @memberof OperationTokenLookup
	 */
	public static tokens =
	{
		'AddDrawer':
			AddDrawerActionToken,
		'AddRelatedContextMenu':
			AddRelatedContextMenuActionToken,
		'ChangeLayoutColor':
			ChangeLayoutColorActionToken,
		'ChangeTheme':
			ChangeThemeActionToken,
		'ContextOperation':
			ContextOperationActionToken,
		'DeleteEntity':
			DeleteEntityActionToken,
		'EmitEvent':
			EmitEventActionToken,
		'ExecuteWorkflow':
			ExecuteWorkflowActionToken,
		'ExecuteOperationPromise':
			ExecuteOperationPromiseActionToken,
		'GenerateDocument':
			GenerateDocumentActionToken,
		'GenerateFiles':
			GenerateFilesActionToken,
		'LogOut':
			LogOutActionToken,
		'Navigation':
			NavigationActionToken,
		'PrintFile':
			PrintFileActionToken,
		'SaveEntity':
			SaveEntityActionToken
	};
}