/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component,
	OnInit
} from '@angular/core';
import {
	DrawerEntityComponentDirective
} from '@shared/directives/drawer-entity-component-directive';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	FormlyHelper
} from '@shared/helpers/formly.helper';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';

/* eslint-enable max-len */

@Component({
	selector: 'entity-view',
	templateUrl: './entity-view.component.html',
	styleUrls: [
		'./entity-view.component.scss'
	]
})

/**
 * A component representing a context level entity view.
 *
 * @export
 * @class EntityViewComponent
 * @extends {DrawerEntityComponentDirective}
 * @implements {OnInit}
 * @implements {IDynamicComponent<Component, any>}
 */
export class EntityViewComponent
	extends DrawerEntityComponentDirective
	implements OnInit, IDynamicComponent<Component, any>
{
	/**
	 * Initializes a new instance of the entity view component.
	 *
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance API service.
	 * @param {EntityTypeApiService} entityService
	 * The entity service.
	 * @memberof EntityViewComponent
	 */
	 public constructor(
		public entityInstanceApiService: EntityInstanceApiService,
		public entityService: EntityService)
	{
		super(
			entityService,
			entityInstanceApiService);
	}

	/**
	 * Handles the on initialization event.
	 * This will gather information and display the entity based on the given
	 * context parameters.
	 *
	 * @async
	 * @memberof EntityViewComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		await this.selectedEntityDisplaySetup();
		FormlyHelper.disableAllFields(this.formlyEntityLayout);

		this.loading = false;
	}
}