/**
 * @copyright WaterStreet. All rights reserved.
*/

import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppCanDeactivateGuard
} from '@shared/guards/app-can-deactivate.guard';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BaseOperationAction
} from '@operation/actions/base/base-operation-action';
import {
	Injectable
} from '@angular/core';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	OperationExecutionService
} from '@operation/services/operation-execution.service';
import {
	OperationService
} from '@operation/services/operation.service';

/**
 * A class representing the action to emit an event
 * that all host listener components can consume.
 *
 * @export
 * @class EmitEventAction
 * @extends {BaseOperationAction}
 */
@Injectable()
export class EmitEventAction
	extends BaseOperationAction
{
	/**
	 * Creates an instance of an EmitEventAction.
	 *
	 * @param {ActivityService} activityService
	 * The activity service used for this action.
	 * @param {OperationExecutionService} operationExecutionService
	 * The operation execution service used for this action.
	 * @param {OperationService} operationService
	 * The operation service used for this action.
	 * @param {OperationDefinitionApiService} operationDefinitionApiService
	 * The operation definition api service used for this action.
	 * @param {AppCanDeactivateGuard} appCanDeactivateGuard
	 * The app can deactivate guard.
	 * @memberof EmitEventAction
	 */
	public constructor(
		protected activityService: ActivityService,
		protected operationExecutionService: OperationExecutionService,
		protected operationService: OperationService,
		protected operationDefinitionApiService: OperationDefinitionApiService,
		protected appCanDeactivateGuard: AppCanDeactivateGuard)
	{
		super(
			activityService,
			operationExecutionService,
			operationService,
			operationDefinitionApiService,
			appCanDeactivateGuard);
	}

	/**
	 * Gets or sets the operation name.
	 *
	 * @type {string}
	 * @memberof EmitEventAction
	 */
	public operationName: string =
		'Event Emitter';

	/**
	 * Gets or sets the event to emit.
	 * Available events are defined in OperationEventConstants.
	 *
	 * @type {string}
	 * @memberof EmitEventAction
	 */
	public event: string = AppConstants.empty;

	/**
	 * Gets or sets the content component for this event.
	 *
	 * @type {string}
	 * @memberof EmitEventAction
	 */
	public contentComponent: string = AppConstants.empty;

	/**
	 * Gets or sets the target component of this event.
	 *
	 * @type {string}
	 * @memberof EmitEventAction
	 */
	public targetComponent: string = AppConstants.empty;

	/**
	 * Executes the defined action.
	 *
	 * @async
	 * @memberof EmitEventAction
	 */
	public async execute(): Promise<void>
	{
		const actionEvent: Event | CustomEvent =
			AnyHelper.isNullOrWhitespace(this.contentComponent)
				&& AnyHelper.isNullOrWhitespace(this.targetComponent)
				? new Event(
					this.event)
				: new CustomEvent(
					this.event,
					{
						detail: {
							contentComponent: this.contentComponent,
							targetComponent: this.targetComponent
						}
					});

		window.dispatchEvent(actionEvent);
	}
}