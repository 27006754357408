/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */

import {
	Component
} from '@angular/core';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicLayoutConfiguration
} from '@shared/interfaces/application-objects/dynamic-layout-configuration.interface';

/* eslint-enable max-len */

@Component({
	selector: 'app-dynamic-formly-wrapper',
	templateUrl: './dynamic-formly-wrapper.component.html'
})

/**
 * A component representing a wrapper to pass context to a dynamic formly
 * display.
 *
 * @export
 * @class DynamicFormlyWrapperComponent
 * @implements {IDynamicComponent<Component, IDynamicLayoutConfiguration>}
 */
export class DynamicFormlyWrapperComponent
implements IDynamicComponent<Component, IDynamicLayoutConfiguration>
{
	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, IDynamicLayoutConfiguration>}
	 * @memberof DynamicFormlyWrapperComponent
	 */
	public context:
		IDynamicComponentContext<
			Component,
			IDynamicLayoutConfiguration>;
}
