<div class="ui-g-12 no-padding">

	<ng-template
		[ngIf]="mappedDifference.difference?.differenceType === 'Array'
			|| isNumericKey === true"
		[ngIfElse]="StandardDisplay">

		<div class="ui-g-12 no-padding edit-level-item"
			[ngClass]="{
				'nested-level': (dataLevel > 1),
				'first-level-item': (dataLevel <= 4)
			}"
			*ngIf="isNumericKey === true && getObjectDisplayType() !== ''">

			<div class="ui-g-12 no-padding difference-type-divider"
				*ngIf="previousSibling?.key != null && mappedDifference?.key != null">
			</div>

			<p-divider align="left">
				<div class="inline-divider">
					<span class="ellipsis-text"
						[ngClass]="{
							'panel-sub-title': (dataLevel > 2 && dataLevel <= 4),
							'panel-title': (dataLevel <= 2)
						}">
						<i class="fa fa-fw fa-edit">
						</i>
						<span class="object-display-icon">
							<i class="{{getObjectDisplayIcon()}}">
							</i>
						</span>
						{{getObjectDisplayType()}}
					</span>
				</div>
			</p-divider>
			<div class="ui-g-12 no-padding description-container">
				<span class="nested-item-identifier secondary-text ellipsis-text"
					[ngClass]="{
						'panel-sub-title': (dataLevel > 2 && dataLevel <= 4),
						'panel-title': (dataLevel <= 2)
					}"
					*ngIf="getObjectDescription() !== ''">
					{{getObjectDescription()}}
				</span>
			</div>
		</div>

		<div class="ui-g-12 no-padding"
			*ngIf="mappedDifference.difference?.differenceType === 'Array'"
			[ngClass]="{
				'nested-level': (dataLevel > 2)
			}">

			<div class="ui-g-12 no-padding difference-type-divider">
			</div>

			<div class="ui-g-12 no-padding">
				<span class="ellipsis-text"
					[ngClass]="{
						'panel-sub-title': (dataLevel > 2 && dataLevel <= 4),
						'panel-title': (dataLevel <= 2)
					}">
					{{getLabelValue(mappedDifference.key)}}
				</span>
			</div>

			<div class="ui-g-12 no-padding nested-item-identifier"
				*ngIf="mappedDifference.difference?.differenceType === 'Array'">

				<div class="ui-g-12 no-padding">
					<div class="ui-g-12 no-padding"
						*ngFor="let itemValue of mappedDifference.difference.updatedValue">
						<div class="ui-g-12-no-padding"
							[ngClass]="{
								'first-level-item': (dataLevel <= 3)
							}">
							<p-divider align="left">
								<div class="inline-divider">
									<span class="ellipsis-text"
										[ngClass]="{
											'panel-sub-title': (dataLevel > 1 && dataLevel <= 3),
											'panel-title': (dataLevel <= 1)
										}">
										<i class="fa fa-fw fa-plus-square-o">
										</i>
										<span class="object-display-icon">
											<i class="{{getObjectDisplayIcon(itemValue)}}">
											</i>
										</span>
										{{getObjectDisplayType(itemValue)}}
									</span>
								</div>
							</p-divider>
						</div>


						<div class="ui-g-12 no-padding description-container">
							<span class="nested-item-identifier secondary-text ellipsis-text"
								[ngClass]="{
									'panel-sub-title': (dataLevel > 1 && dataLevel <= 3),
									'panel-title': (dataLevel <= 1)
								}"
								*ngIf="getObjectDescription(itemValue) !== ''">
								{{getObjectDescription(itemValue)}}
							</span>
						</div>

						<div class="ui-g-12 no-padding difference-type-divider"
							*ngIf="i < mappedDifference.difference.updatedValue.length - 1
								&& mappedDifference.difference.originalValue.length > 0">
						</div>
					</div>
				</div>


				<div class="ui-g-12 no-padding">
					<div class="ui-g-12 no-padding"
						*ngFor="let itemValue of mappedDifference.difference.originalValue; let i = index">

						<div class="ui-g-12-no-padding"
							[ngClass]="{
								'first-level-item': (dataLevel <= 3)
							}">
							<p-divider align="left">
								<div class="inline-divider">
									<span class="ellipsis-text"
										[ngClass]="{
											'panel-sub-title': (dataLevel > 1 && dataLevel <= 3),
											'panel-title': (dataLevel <= 1)
										}">
										<i class="fa fa-fw fa-minus-square-o">
										</i>
										<span class="object-display-icon">
											<i class="{{getObjectDisplayIcon(itemValue)}}">
											</i>
										</span>
										{{getObjectDisplayType(itemValue)}}
									</span>
								</div>
							</p-divider>
						</div>

						<div class="ui-g-12 no-padding description-container">
							<span class="nested-item-identifier secondary-text ellipsis-text"
								[ngClass]="{
									'panel-sub-title': (dataLevel > 1 && dataLevel <= 3),
									'panel-title': (dataLevel <= 1)
								}"
								*ngIf="getObjectDescription(itemValue) !== ''">
								{{getObjectDescription(itemValue)}}
							</span>
						</div>

						<div class="ui-g-12 no-padding difference-type-divider"
							*ngIf="i < mappedDifference.difference.originalValue.length - 1">
							2
						</div>
					</div>
				</div>

			</div>

		</div>

	</ng-template>

	<ng-template #StandardDisplay>
		<div *ngIf="displayObjectName === true">
			<div class="ui-g-12 no-padding difference-type-divider"
				*ngIf="mappedDifference.nestedDifferences.length > 0">
			</div>
			<div class="difference-key-column"
				[ngClass]="{
					'nested-key': (dataLevel > 2 || mappedDifference.difference != null)
				}">
				<span class="ellipsis-text"
					[ngClass]="{
						'panel-sub-title': (mappedDifference.difference == null && dataLevel > 2 && dataLevel <= 4),
						'panel-title': (mappedDifference.difference == null && dataLevel <= 2)
					}">
					{{getLabelValue(mappedDifference.key)}}
				</span>
			</div>
			<div class="difference-key-value">
				<span class="ellipsis-text">
					{{getFormattedValue(mappedDifference.difference?.originalValue?.toString())}}
				</span>
			</div>
			<div class="difference-key-value">
				<span class="ellipsis-text">
					{{getFormattedValue(mappedDifference.difference?.updatedValue?.toString())}}
				</span>
			</div>
		</div>
	</ng-template>

	<ng-template
		ngFor
		let-nestedDifference
		let-i="index"
		[ngForOf]="mappedDifference.nestedDifferences">

		<div class="ui-g-12 no-padding"
			[ngClass]="{
				'nested-level': (dataLevel > 2)
			}">
			<app-difference-display
				[mappedDifference]="nestedDifference"
				[previousSibling]="mappedDifference.nestedDifferences[i-1]"
				[dataLevel]="dataLevel">
			</app-difference-display>
		</div>

	</ng-template>
</div>