/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ChangeDetectorRef,
	Component,
	OnInit
} from '@angular/core';
import {
	Context
} from 'vm';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	FieldType,
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	FormControl
} from '@angular/forms';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IOperationDefinition
} from '@operation/interfaces/operation-definition.interface';
import {
	IOperationGroup
} from '@operation/interfaces/operation-group.interface';
import {
	IOperationGroupRelationship
} from '@operation/interfaces/operation-group-relationship.interface';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	OperationGroupApiService
} from '@api/services/operations/operation-group.api.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-operation-group-create-child',
	templateUrl: './operation-group-create-child.component.html',
	styleUrls: ['./operation-group-create-child.component.scss']
})

/**
 * A component representing an instance of the
 * operation group expand component.
 *
 * @export
 * @class OperationGroupCreateChild
 * @implements {IDynamicComponent<Context, any>}
 */
export class OperationGroupCreateChildComponent
	extends FieldType
	implements IDynamicComponent<Context, any>, OnInit
{
	/**
	 * Initializes a new instance of the OperationGroupCreateChild class.
	 *
	 * @param {OperationGroupApiService} operationGroupApiService
	 * The api service used to load operation group data.
	 * @param {OperationDefinitionApiService} operationGroupApiService
	 * The api service used to load operation definition data.
	 * @param {OperationTypeApiService} operationTypeApiService
	 * The api service used to load operation type data.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this component to get its data.
	 * @memberof OperationGroupCreateChild
	 */
	public constructor(
		public operationGroupApiService: OperationGroupApiService,
		public operationDefinitionApiService: OperationDefinitionApiService,
		public siteLayoutService: SiteLayoutService,
		public changeRef: ChangeDetectorRef)
	{
		super();
	}

	/**
	 * Gets or sets the context that will be set when implementing this
	 * as a dynamic component.
	 *
	 * @type {IDynamicComponentContext<Context, any>}
	 * @memberof OperationGroupCreateChild
	 */
	public context: IDynamicComponentContext<Context, any>;

	/**
	 * Gets or sets the operation definitions.
	 *
	 * @type {IOperationDefinition[]}
	 * @memberof OperationGroupCreateChild
	 */
	public operationDefinitions: IOperationDefinition[] = [];

	/**
	 * Gets or sets the operation groups.
	 *
	 * @type {IOperationGroup[]}
	 * @memberof OperationGroupCreateChild
	 */
	public operationGroups: IOperationGroup[] = [];

	/**
	 * Sets the data chunk limit.
	 *
	 * @type {string}
	 * @memberof OperationGroupCreateChild
	 */
	public dataChunkLimit: number = 100;

	/**
	 * Sets the test mode.
	 *
	 * @type {boolean}
	 * @memberof OperationGroupCreateChild
	 */
	public testMode: boolean = false;

	/**
	 * Gets or sets the operation query params.
	 *
	 * @type {IObjectSearch}
	 * @memberof OperationGroupCreateChild
	 */
	public operationQueryParams: IObjectSearch = {
		filter: AppConstants.empty,
		orderBy: `Id ${AppConstants.sortDirections.ascending}`,
		offset: null,
		limit: this.dataChunkLimit
	};

	/**
	 * Gets or sets the operation child type.
	 *
	 * @type {string}
	 * @memberof OperationGroupCreateChild
	 */
	public operationChildType: string = AppConstants.empty;

	/**
	 * Gets or sets the create formly layout.
	 *
	 * @type {object[]}
	 * @memberof OperationGroupCreateChild
	 */
	public createFormlylayout: object[];

	/**
	 * Gets or sets the loading layout state.
	 *
	 * @type {boolean}
	 * @memberof OperationGroupCreateChild
	 */
	public loadingLayout: boolean = true;

	/**
	 * Gets the layout redraw debounce delay.
	 *
	 * @type {boolean}
	 * @memberof OperationGroupCreateChild
	 */
	private readonly layoutRedrawDebounceDelay: number = 250;

	/**
	 * Initialazes the operation group create child component
	 * by getting all existing operation groups and definitions
	 * and drawing a formly layout.
	 *
	 * @async
	 * @memberof OperationGroupCreateChild
	 */
	public async ngOnInit()
	{
		this.operationGroups = await this.populateOperations(
			{
				operationQueryReturn: async(queryParams: any) =>
					this.operationGroupApiService
						.query(
							queryParams.filter,
							queryParams.orderBy,
							queryParams.offset,
							queryParams.limit),
				operationQueryParams: this.operationQueryParams
			});

		this.operationDefinitions = await this.populateOperations(
			{
				operationQueryReturn: async(queryParams: any) =>
					this.operationDefinitionApiService
						.query(
							queryParams.filter,
							queryParams.orderBy,
							queryParams.offset,
							queryParams.limit),
				operationQueryParams: this.operationQueryParams
			});

		this.defineFormlyLayout();
	}

	/**
	 * Defines the formly layout.
	 *
	 * @memberof OperationGroupCreateChild
	 */
	public defineFormlyLayout(): void
	{
		this.context.data.data.name = null;
		this.createFormlylayout =
		[
			{
				key: 'data.type',
				type: FormlyConstants.customControls.customSelect,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					appendTo: FormlyConstants.appendToTargets.body,
					label: 'Type',
					placeholder: 'Select a Type',
					showClear: true,
					required: true,
					default: this.operationChildType,
					options:
					[
						{
							value: 'OperationDefinition',
							label: 'Operation Definition'
						},
						{
							value: 'OperationGroup',
							label: 'Operation Group'
						}
					],
					change: (field: FormlyFieldConfig) =>
					{
						if (field.formControl.value === this.operationChildType)
						{
							return;
						}

						if (field.formControl.pristine === true)
						{
							field.formControl.setValue(this.operationChildType);
							this.changeRef.detectChanges();
						}
						else
						{
							this.operationChildType = field.formControl.value;
							this.loadingLayout = true;
							setTimeout(() =>
							{
								this.defineFormlyLayout();
							},
							this.layoutRedrawDebounceDelay);
						}
					}
				}
			},
			{
				key: 'data.name',
				type: FormlyConstants.customControls.customSelect,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					appendTo: FormlyConstants.appendToTargets.body,
					label: 'Name',
					placeholder: 'Select a Name',
					showClear: true,
					default: null,
					options: this.mapDefinitionNames(this.operationChildType),
					required: true
				},
				asyncValidators: {
					uniqueCombination: {
						expression: (control: FormControl) =>
							this.uniqueCombination(control),
						message: 'Existing Relationship.'
					}
				}
			},
			{
				key: 'data.order',
				type: FormlyConstants.customControls.customInputNumber,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					appendTo: FormlyConstants.appendToTargets.body,
					label: 'Order',
					required: true
				},
				asyncValidators: {
					uniqueOrder: {
						expression: (control: FormControl) =>
							this.uniqueOrder(control),
						message: 'Existing Order.'
					}
				}
			}
		];

		this.loadingLayout = false;

		EventHelper.dispatchTableExpansionPanelLoadedEvent();
	}

	/**
	 * Filters and maps the operation definition
	 * based on the entity type
	 *
	 * @param {number} typeId
	 * The operation type id.
	 * @returns {Observable<any>}
	 * An observable array filtered and mapped
	 * based on the type id.
	 * @memberof OperationGroupCreateChild
	 */
	public mapDefinitionNames(type: string): object[]
	{
		let operationType: any[] = [];

		if (type === 'OperationDefinition')
		{
			operationType = <any[]> this.operationDefinitions;
		}
		else if (type === 'OperationGroup')
		{
			operationType = <any[]> this.operationGroups;
		}

		return operationType.map(
			(option: IOperationDefinition | IOperationGroup) =>
				({
					value: option.id,
					label: option.name
				}))
			.sort((
				optionOne: IDropdownOption,
				optionTwo: IDropdownOption) =>
				ObjectHelper.sortByPropertyValue(
					optionOne,
					optionTwo,
					'label'));
	}

	/**
	 * Validates if the operation order is unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The field form control.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof OperationGroupCreateChild
	 */
	private async uniqueOrder(
		control: FormControl): Promise<boolean>
	{
		let operationGroupRelationships: IOperationGroupRelationship[] =
			await this.operationGroupApiService
				.getChildren(
					this.context.source.customContext.data.data.id);
		operationGroupRelationships =
			operationGroupRelationships.filter(
				(child: IOperationGroupRelationship) =>
					child.order === parseInt(
						control.value,
						AppConstants.parseRadix));

		return Promise.resolve(
			operationGroupRelationships.length === 0);
	}

	/**
	 * Validates if the operation relationship
	 * with parent group and child type is unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The field form control.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof OperationGroupCreateChild
	 */
	private async uniqueCombination(
		control: FormControl): Promise<boolean>
	{
		if (AnyHelper.isNullOrEmpty(this.operationChildType))
		{
			return true;
		}

		let operationGroupRelationships: IOperationGroupRelationship[] =
			await this.operationGroupApiService
				.getChildren(
					this.context.source.customContext.data.data.id);
		operationGroupRelationships =
			operationGroupRelationships.filter(
				(child: IOperationGroupRelationship) =>
					child.id === control.value
						&& child.type === this.operationChildType);

		return Promise.resolve(
			operationGroupRelationships.length === 0);
	}

	/**
	 * Populates the operation object with all the existing.
	 *
	 * @async
	 * @param {any} operationQueryDefinitions
	 * The operation api definitions.
	 * @returns {Promise<any>}
	 * The awaitable promise data query.
	 * @memberof OperationGroupCreateChild
	 */
	private async populateOperations(
		operationQueryDefinitions: any): Promise<any[]>
	{
		let dataChunk =
			await operationQueryDefinitions
				.operationQueryReturn(
					operationQueryDefinitions.operationQueryParams);

		let operations =
		[
			...dataChunk
		];

		while (dataChunk.length === this.dataChunkLimit)
		{
			operationQueryDefinitions.operationQueryParams.filter =
				`Id gt ${dataChunk[dataChunk.length - 1].id}`;

			dataChunk =
				await operationQueryDefinitions
					.operationQueryReturn(
						operationQueryDefinitions.operationQueryParams);

			operations =
			[
				...operations,
				...dataChunk
			];

			this.dataChunkLimit =
				this.testMode === true
					? 0
					: this.dataChunkLimit;
		}

		return operations;
	}
}