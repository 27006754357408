/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityLayout
} from '@shared/implementations/entities/entity-layout';
import {
	EntityLayoutApiService
} from '@api/services/entities/entity-layout.api.service';
import {
	EntityLayoutTypeApiService
} from '@api/services/entities/entity-layout-type.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	FormlyHelper
} from '@shared/helpers/formly.helper';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityLayout
} from '@shared/interfaces/entities/entity-layout.interface';
import {
	IEntityLayoutType
} from '@shared/interfaces/entities/entity-layout-type.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Router
} from '@angular/router';
import {
	SessionService
} from '@shared/services/session.service';

/* eslint-enable max-len */

@Component({
	selector: 'create-transaction',
	templateUrl: './create-transaction.component.html',
	styleUrls: []
})

/**
 * A component representing a wizard step for creating a policy transaction.
 *
 * @export
 * @class CreateTransactionComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class CreateTransactionComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the create transaction component.
	 *
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes on entity creation.
	 * @param {EntityService} entityService
	 * The entity service used to lookup entity modules upon creation.
	 * @param {EntityLayoutApiService} entityLayoutApiService
	 * The entity layout API service.
	 * @param {EntityLayoutTypeApiService} entityLayoutTypeApiService
	 * The entity layout type API service.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service used in this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @memberof CreateTransactionComponent
	 */
	public constructor(
		public router: Router,
		public activityService: ActivityService,
		public moduleService: ModuleService,
		public entityService: EntityService,
		public entityLayoutApiService: EntityLayoutApiService,
		public entityLayoutTypeApiService: EntityLayoutTypeApiService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<
	 * 	DynamicWizardComponent,
	 * 	IWizardContext>}
	 * @memberof CreateTransactionComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the formly layout used in implementing components.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof CreateTransactionComponent
	 */
	public dynamicFormlyLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the entity type to be created.
	 *
	 * @type {IEntityType}
	 * @memberof CreateTransactionComponent
	 */
	public entityCreationType: IEntityType;

	/**
	 * Gets or sets a string representing the parent type group.
	 *
	 * @type {string}
	 * @memberof CreateTransactionComponent
	 */
	private parentTypeGroup: string;

	/**
	 * Gets or sets a number representing the parent id.
	 *
	 * @type {number}
	 * @memberof CreateTransactionComponent
	 */
	private parentId: number;

	/**
	 * Gets or sets the product name associated to this transaction.
	 *
	 * @type {string}
	 * @memberof CreateTransactionComponent
	 */
	private productName: string;

	/**
	 * Gets or sets the transaction entity group associated to the current data.
	 *
	 * @type {string}
	 * @memberof CreateTransactionComponent
	 */
	private transactionEntityGroup: string;

	/**
	 * Gets or sets the transaction entity name associated to the current data.
	 *
	 * @type {string}
	 * @memberof CreateTransactionComponent
	 */
	private transactionEntityName: string;

	/**
	 * Gets or sets the context active menu item current data collected on the
	 * dynamic wizard steps.
	 *
	 * @type {any}
	 * @memberof CreateTransactionComponent
	 */
	private currentData: any;

	/**
	 * Gets or sets the transaction id.
	 *
	 * @type {number}
	 * @memberof CreateTransactionComponent
	 */
	private transactionId: number;

	/**
	 * Gets or sets the layout type name associated to this transaction.
	 *
	 * @type {string}
	 * @memberof CreateTransactionComponent
	 */
	private readonly layoutTypeName: string = 'CreateWizard';

	/**
	 * Implements the on initialization interface.
	 *
	 * @memberof CreateTransactionComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.currentData =
			this.context.source.activeMenuItem.currentData;

		this.productName =
			this.currentData.data.productName;

		this.transactionEntityGroup =
			`PolicyTermTransaction.${this.productName}`;

		this.transactionEntityName =
			`PolicyTermTransaction.${this.productName}`;

		this.entityCreationType =
			await this.entityTypeApiService
				.getSingleQueryResult(
					`Group eq '${this.transactionEntityGroup}' AND Name eq `
					+ `'${this.transactionEntityName}'`,
					AppConstants.empty);

		this.setParentEntityInformation();

		this.context.source
			.addToNext(
				this.create.bind(this));

		await this.performPostInitActions();

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * Handles the validity changed event sent from the child dynamic
	 * formly component. This will update the validity of the form for
	 * action buttons.
	 *
	 * @param {boolean} isValid
	 * The validity of the current displayed step data set.
	 * @memberof CreateTransactionComponent
	 */
	public async validityChanged(
		isValid: boolean): Promise<void>
	{
		this.context.source.validStepChanged(isValid);
	}

	/**
	 * This will send the transaction creation event and navigate to the new
	 * entity.
	 *
	 * @async
	 * @memberof CreateTransactionComponent
	 */
	public async create(): Promise<void>
	{
		const applicantType =
			AnyHelper.isNullOrWhitespace(this.currentData.data.type)
				? 'Individual'
				: this.currentData.data.type;

		const transactionData: IEntityInstance =
			this.createTransactionData(
				this.entityCreationType.name,
				applicantType,
				this.currentData.data?.legalName,
				this.currentData.data?.legalStructure,
				this.currentData.data?.prefix,
				this.currentData.data?.firstName,
				this.currentData.data?.middleName,
				this.currentData.data?.lastName,
				this.currentData.data?.suffix,
				this.currentData.data?.effectiveDate,
				this.currentData.data?.termLength);

		this.transactionId =
			await this.createEntity(
				this.entityCreationType,
				transactionData);

		await this.navigateToQuickQuote(
			this.transactionId,
			this.entityCreationType.group);
	}

	/**
	 * Creates the Transaction entity intance data based on the collected
	 * data. This data will be saved on the creation process.
	 *
	 * @param {string} entityType
	 * The entity type to be created.
	 * @param {string} applicantType
	 *  The applicant type (Individual/Organization).
	 * @param {string} legalName
	 * The organization legal name.
	 * @param {string} legalStructure
	 *  The organization legal structure:
	 * (Corporation/Limited Liability Company/Sole Proprietorship/Non-Profit).
	 * @param {string} prefix
	 * The prefix.
	 * @param {string} firstName
	 *  The first name.
	 * @param {string} middleName
	 * The middle name.
	 * @param {string} lastName
	 *  The last name.
	 * @param {string} suffix
	 * The suffix.
	 * @param {string} effectiveDate
	 * The effective date.
	 * @param {string} termLength
	 * The term length.
	 * @memberof CreateTransactionComponent
	 */
	private createTransactionData(
		entityType: string,
		applicantType: string,
		legalName: string,
		legalStructure: string,
		prefix: string,
		firstName: string,
		middleName: string,
		lastName: string,
		suffix: string,
		effectiveDate: string,
		termLength: string): IEntityInstance
	{
		const transactionData: IEntityInstance =
			<IEntityInstance>
			{
				id: 0,
				entityType: entityType,
				versionNumber: null,
				data: {
					effectiveDate: effectiveDate,
					interests: [
						{
							type: 'NamedInsured',
							subType: 'Primary',
							characteristics: {
								name: {
									type: applicantType,
									legalName: applicantType === 'Organization'
										? legalName ?? null
										: null,
									legalStructure: applicantType ===
										'Organization'
										? legalStructure ?? null
										: null,
									prefix: applicantType === 'Individual'
										? prefix ?? null
										: null,
									firstName: applicantType === 'Individual'
										? firstName ?? null
										: null,
									middleName: applicantType === 'Individual'
										? middleName ?? null
										: null,
									lastName: applicantType === 'Individual'
										? lastName ?? null
										: null,
									suffix: applicantType === 'Individual'
										? suffix ?? null
										: null
								}
							}
						}
					],
					termLength: parseInt(
						termLength,
						AppConstants.parseRadix)
				}
			};

		return transactionData;
	}

	/**
	 * This will send the entity creation event.
	 *
	 * @param {IEntityType} entityCreationType
	 * The entity type to be created.
	 * @param {IEntityInstance} entityInstanceData
	 *  The entity instance data to be saved.
	 * @async
	 * @memberof CreateTransactionComponent
	 */
	private async createEntity(
		entityCreationType: IEntityType,
		entityInstanceData: IEntityInstance): Promise<number>
	{
		setTimeout(
			() =>
			{
				this.context.source.wizardStepLoading = true;
			});

		const displayName: string =
			new EntityType(entityCreationType)
				.displayName;

		const newEntityId: number =
			await this.activityService.handleActivity<number>(
				new Activity<number>(
					this.createEntityInstance(
						entityCreationType,
						entityInstanceData),
					'<strong>Creating transaction</strong>',
					'<strong>Transaction created</strong>',
					`${displayName} has been created.`,
					`${displayName} has not been created.`));

		return newEntityId;
	}

	/**
	 * Creates an entity instance and all entity relationships.
	 *
	 * @param {IEntityType} entityCreationType
	 * The entity type to be created.
	 * @param {IEntityInstance} entityInstanceData
	 *  The entity instance data to be saved.
	 * @returns {Promise<number>}
	 * The id of the newly created entity.
	 * @memberof CreateTransactionComponent
	 */
	private async createEntityInstance(
		entityCreationType: IEntityType,
		entityInstanceData: IEntityInstance): Promise<number>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup =
				entityCreationType.group;

		const createdEntityId: number =
			await this.entityInstanceApiService
				.createEntityInstance(
					entityInstanceData,
					this.parentTypeGroup,
					this.parentId,
					{
						productName: this.productName,
						accountNumber: this.currentData.data.accountNumber
					});

		return createdEntityId;
	}

	/**
	 * This will navigate to the quick quote using the entity id provided.
	 *
	 * @param {number} entityId
	 * The entity id to navigate.
	 * @param {string} group
	 *  The entity group associated to the navigation.
	 * @async
	 * @memberof CreateTransactionComponent
	 */
	private async navigateToQuickQuote(
		entityId: number,
		group: string)
	{
		this.moduleService.name =
			await this.entityService.getContextMenuModule(
				this.entityCreationType.name);

		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.context.source.storeData(
			this.currentData);

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				group,
				AppConstants.viewTypes.edit,
				entityId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.quickQuote
							})
				}
			});
	}

	// /**
	//  * Sets the parent entity information.
	//  *
	//  * @memberof CreateTransactionComponent
	//  */
	private setParentEntityInformation()
	{
		this.parentTypeGroup = 'Organization.Agencies';
		this.parentId =
			this.currentData.data.agencyId;
	}

	/**
	 * Handles the post initialization action.
	 * This will create the dynamic formly layout for display component creation
	 * and permissions.
	 *
	 * @memberof CreateTransactionComponent
	 */
	private async performPostInitActions(): Promise<void>
	{
		const entityLayoutType: IEntityLayoutType =
			await this.entityLayoutTypeApiService
				.getSingleQueryResult(
					`${AppConstants.commonProperties.name} eq `
						+ `'${this.layoutTypeName}'`,
					`${AppConstants.commonProperties.id} `
						+ AppConstants.sortDirections.descending);

		const entityLayout: IEntityLayout =
			await this.entityLayoutApiService
				.getSingleQueryResult(
					`${AppConstants.commonProperties.typeId} eq `
						+ `${this.entityCreationType.id} and `
						+ `${AppConstants.commonProperties.layoutTypeId} eq `
						+ entityLayoutType.id,
					`${AppConstants.commonProperties.versionId} `
						+ AppConstants.sortDirections.descending);

		this.dynamicFormlyLayout =
			FormlyHelper.getFormlyLayout(
				new EntityLayout(entityLayout).jsonEntityLayout,
				this.context);
	}
}