/**
 * @copyright WaterStreet. All rights reserved.
*/

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BaseOperationMenuDisplayDirective
} from '@operation/directives/base-operation-menu-display.directive';
import {
	Component,
	EventEmitter,
	Output
} from '@angular/core';
import {
	MenuItem
} from 'primeng/api';
import {
	OperationMenuComponent
} from '@operation/components/operation-menu/operation-menu.component';
import {
	Router
} from '@angular/router';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

@Component({
	selector: 'app-menu',
	templateUrl: './app-menu.component.html',
	styleUrls: [
		'./app-menu.component.scss'
	]
})

/**
 * A component used to define primary menu based actions and
 * navigation.
 *
 * @export
 * @class AppMenuComponent
 * @extends {BaseOperationMenuDisplayComponent}
 */
export class AppMenuComponent
	extends BaseOperationMenuDisplayDirective
{
	/**
	 * Initializes a new instance of the context menu
	 * component.
	 *
	 * @param {Router} router
	 * The router used for menu navigation.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service to use for responsive layouts.
	 * @memberof AppContextMenuComponent
	 */
	public constructor(
		public router: Router,
		public siteLayoutService: SiteLayoutService)
	{
		super(siteLayoutService);
	}

	/**
	 * Sets the event emitter for the close overlays event so this
	 * can be sent to listening parent components.
	 *
	 * @type {EventEmitter<void>}
	 * @memberof AppMenuComponent
	 */
	@Output() public closeOverlays: EventEmitter<void> =
		new EventEmitter();

	/**
	 * Gets the base model used to display the operation
	 * group. This item will display prior to the data
	 * load of the operation group.
	 *
	 * @type {MenuItem[]}
	 * @memberof AppMenuComponent
	 */
	public initialModel: MenuItem[] =
		[
			{
				label: 'Dashboard',
				icon: 'menu-logo',
				command: () => {
					this.router.navigate(
						[
							AppConstants.route.dashboardPage
						]);
				}
			}
		];

	/**
	 * Defines the primary navigation group identifier that will
	 * be displayed via this operation group display component.
	 *
	 * @memberof AppMenuComponent
	 */
	public primaryNavigationGroupName: string =
		AppConstants.primaryOperationGroups.primaryNavigation;

	/**
	 * Sends the reset event to the navigation menu.
	 *
	 * @memberof AppMenuComponent
	 */
	public closeNavigationMenu(
		operationMenu: OperationMenuComponent): void
	{
		operationMenu.reset = true;

		setTimeout(() =>
		{
			operationMenu.reset = false;
		});
	}

	/**
	 * Handles the close overlay event sent from the operation menu.
	 * This will emit the close overlay event to listening parent components
	 * signifying that a nested operation action was performed.
	 *
	 * @memberof AppMenuComponent
	 */
	public closeOverlaysEvent(): void
	{
		this.closeOverlays.emit();
	}
}