/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Type
} from '@angular/core';

/**
 * A lookup to be shared across the application used
 * for accessing site level dynamic components.
 *
 * @export
 * @class DynamicComponentLookup
 */
export class DynamicComponentLookup
{
	/**
	 * A static constant set representing component types that
	 * are currently available in this lookup.
	 *
	 * @static
	 * @type {object}
	 * @memberof DynamicComponentLookup
	 */
	public static supportedTypes: {
		activityListComponent: string;
		addressListComponent: string;
		addExposureClaimComponent: string;
		adjustmentWriteOffAmountComponent: string;
		adjustmentWriteOffCommentsComponent: string;
		adjustReservesClaimComponent: string;
		applicationSearchComponent: string;
		associatedEntityListComponent: string;
		associatedKeyContactsComponent: string;
		associatedOrganizationComponent: string;
		chartComponent: string;
		commonListComponent: string;
		clonePowerBiReportComponent: string;
		bindTransactionComponent: string;
		createClaimComponent: string;
		createTransactionComponent: string;
		filesComponent: string;
		dynamicCommonTableComponent: string;
		dynamicFormlyWrapperComponent: string;
		dynamicReallySimpleSyndicationFeedReaderComponent: string;
		dynamicSummaryCardComponent: string;
		dynamicWeatherCardComponent: string;
		dynamicWizardComponent: string;
		finalWizardLayoutStepComponent: string;
		externalReportComponent: string;
		genericBasePageComponent: string;
		genericDashboardComponent: string;
		makePaymentComponent: string;
		makePaymentReceiptComponent: string;
		manageExposurePartiesClaimComponent: string;
		nextPaymentInstallmentDetailsComponent: string;
		nonRenewPolicyComponent: string;
		notesComponent: string;
		paymentBatchApproveComponent: string;
		paymentBatchComponent: string;
		paymentBatchCreateComponent: string;
		paymentDetailsComponent: string;
		parentSelectionComponent: string;
		paymentMethodCreateComponent: string;
		paymentMethodProcessorComponent: string;
		paymentReversalConfirmationComponent: string;
		paymentReversalSelectionComponent: string;
		processOnePaymentMethodComponent: string;
		productSettingsComponent: string;
		productSettingListComponent: string;
		rescindNonRenewPolicyComponent: string;
		rulesComponent: string;
		ruleListItemComponent: string;
		selectExistingPowerBiReportComponent: string;
		termAlterPaymentPlanComponent: string;
		transactionAssetsComponent: string;
		transactionCancelEffectiveDateComponent: string;
		transactionCancelReasonsComponent: string;
		transactionCancelReinstateCommentsComponent: string;
		transactionCoveragesDeductiblesComponent: string;
		transactionFormsComponent: string;
		transactionEndorseIssueDifferencesComponent: string;
		transactionEndorseIssueSummaryComponent: string;
		transactionEndorseReasonsComponent: string;
		transactionEndorseStatusEffectiveDateComponent: string;
		transactionInterestsComponent: string;
		transactionReasonsComponent: string;
		transactionRescindCancelComponent: string;
		transactionRescindEndorseComponent: string;
		verifyCoverageClaimComponent: string;
		workItemsComponent: string;
		workItemListComponent: string;
		transactionDeclineReasonsComponenet: string;
		bIReportComponent: string;
	} = {
		activityListComponent: 'ActivityListComponent',
		addressListComponent: 'AddressListComponent',
		addExposureClaimComponent: 'AddExposureClaimComponent',
		adjustmentWriteOffAmountComponent: 'AdjustmentWriteOffAmountComponent',
		adjustmentWriteOffCommentsComponent:
			'AdjustmentWriteOffCommentsComponent',
		adjustReservesClaimComponent: 'AdjustReservesClaimComponent',
		applicationSearchComponent: 'ApplicationSearchComponent',
		associatedEntityListComponent: 'AssociatedEntityListComponent',
		associatedKeyContactsComponent: 'AssociatedKeyContactsComponent',
		associatedOrganizationComponent: 'AssociatedOrganizationComponent',
		chartComponent: 'ChartComponent',
		commonListComponent: 'CommonListComponent',
		clonePowerBiReportComponent: 'ClonePowerBiReportComponent',
		bindTransactionComponent: 'BindTransactionComponent',
		createClaimComponent : 'CreateClaimComponent',
		createTransactionComponent: 'CreateTransactionComponent',
		finalWizardLayoutStepComponent: 'FinalWizardLayoutStepComponent',
		filesComponent: 'FilesComponent',
		dynamicCommonTableComponent: 'DynamicCommonTableComponent',
		dynamicFormlyWrapperComponent: 'DynamicFormlyWrapperComponent',
		dynamicReallySimpleSyndicationFeedReaderComponent:
			'DynamicReallySimpleSyndicationFeedReaderComponent',
		dynamicSummaryCardComponent: 'DynamicSummaryCardComponent',
		dynamicWeatherCardComponent: 'DynamicWeatherCardComponent',
		dynamicWizardComponent: 'DynamicWizardComponent',
		externalReportComponent: 'ExternalReportComponent',
		genericBasePageComponent: 'GenericBasePageComponent',
		genericDashboardComponent: 'GenericDashboardComponent',
		makePaymentComponent: 'MakePaymentComponent',
		makePaymentReceiptComponent: 'MakePaymentReceiptComponent',
		manageExposurePartiesClaimComponent:
			'ManageExposurePartiesClaimComponent',
		nextPaymentInstallmentDetailsComponent:
			'NextPaymentInstallmentDetailsComponent',
		nonRenewPolicyComponent: 'NonRenewPolicyComponent',
		notesComponent: 'NotesComponent',
		paymentDetailsComponent: 'PaymentDetailsComponent',
		parentSelectionComponent: 'ParentSelectionComponent',
		paymentBatchApproveComponent: 'PaymentBatchApproveComponent',
		paymentBatchComponent: 'PaymentBatchComponent',
		paymentBatchCreateComponent: 'PaymentBatchCreateComponent',
		paymentMethodCreateComponent: 'PaymentMethodCreateComponent',
		paymentMethodProcessorComponent: 'PaymentMethodProcessorComponent',
		paymentReversalConfirmationComponent:
			'PaymentReversalConfirmationComponent',
		paymentReversalSelectionComponent: 'PaymentReversalSelectionComponent',
		processOnePaymentMethodComponent: 'ProcessOnePaymentMethodComponent',
		productSettingsComponent: 'ProductSettingsComponent',
		productSettingListComponent: 'ProductSettingListComponent',
		rulesComponent: 'RulesComponent',
		ruleListItemComponent: 'RuleListItemComponent',
		rescindNonRenewPolicyComponent: 'RescindNonRenewPolicyComponent',
		selectExistingPowerBiReportComponent:
			'SelectExistingPowerBiReportComponent',
		termAlterPaymentPlanComponent: 'TermAlterPaymentPlanComponent',
		transactionAssetsComponent: 'TransactionAssetsComponent',
		transactionCancelEffectiveDateComponent:
			'TransactionCancelEffectiveDateComponent',
		transactionCancelReasonsComponent:
			'TransactionCancelReasonsComponent',
		transactionCancelReinstateCommentsComponent:
			'TransactionCancelReinstateCommentsComponent',
		transactionCoveragesDeductiblesComponent:
			'TransactionCoveragesDeductiblesComponent',
		transactionFormsComponent:
			'transactionFormsComponent',
		transactionEndorseIssueDifferencesComponent:
			'TransactionEndorseIssueDifferencesComponent',
		transactionEndorseIssueSummaryComponent:
			'TransactionEndorseIssueSummaryComponent',
		transactionEndorseReasonsComponent:
			'TransactionEndorseReasonsComponent',
		transactionEndorseStatusEffectiveDateComponent:
			'TransactionEndorseStatusEffectiveDateComponent',
		transactionInterestsComponent: 'TransactionInterestsComponent',
		transactionReasonsComponent: 'TransactionReasonsComponent',
		transactionRescindCancelComponent: 'TransactionRescindCancelComponent',
		transactionRescindEndorseComponent:
			'TransactionRescindEndorseComponent',
		transactionDeclineReasonsComponenet:
			'TransactionDeclineReasonsComponent',
		verifyCoverageClaimComponent : 'VerifyCoverageClaimComponent',
		workItemsComponent: 'WorkItemsComponent',
		workItemListComponent: 'WorkItemListComponent',
		bIReportComponent: 'BIReportComponent',
	};

	/**
	 * A static constant set representing component types that
	 * are currently capable of displaying dynamic components.
	 *
	 * @static
	 * @type {object}
	 * @memberof DynamicComponentLookup
	 */
	public static targetComponents: {
		utilityMenuComponent: string;
	} = {
		utilityMenuComponent: 'UtilityMenuComponent'
	};

	/**
	 * Dictionary to be used for string based resolution of
	 * dynamic components in Ivy.
	 *
	 * @static
	 * @memberof DynamicComponentLookup
	 */
	public static components =
	{
		'ActivityListComponent':
			async(): Promise<Type<any>> =>
				import('@shared/dynamic-components/activity-list'
					+ '/activity-list.component')
					.then((source) =>
						source.ActivityListComponent),
		'AddressListComponent':
			async(): Promise<Type<any>> =>
				import('@shared/dynamic-components/address-list'
					+ '/address-list.component')
					.then((source) =>
						source.AddressListComponent),
		'AddExposureClaimComponent':
			async(): Promise<Type<any>> =>
				import('@claims/components/wizard-steps'
					+ '/claim/add-exposure-claim'
					+ '/add-exposure-claim.component')
					.then((source) =>
						source.AddExposureClaimComponent),
		'AdjustmentWriteOffAmountComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps/policy'
					+ '/adjustment-write-off-amount'
					+ '/adjustment-write-off-amount.component')
					.then((source) =>
						source.AdjustmentWriteOffAmountComponent),
		'AdjustmentWriteOffCommentsComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps/policy'
					+ '/adjustment-write-off-comments'
					+ '/adjustment-write-off-comments.component')
					.then((source) =>
						source.AdjustmentWriteOffCommentsComponent),
		'AdjustReservesClaimComponent':
			async(): Promise<Type<any>> =>
				import('@claims/components/wizard-steps'
					+ '/claim/adjust-reserves-claim'
					+ '/adjust-reserves-claim.component')
					.then((source) =>
						source.AdjustReservesClaimComponent),
		'ApplicationSearchComponent':
			async(): Promise<Type<any>> =>
				import('@shared/dynamic-components/application-search'
					+ '/application-search.component')
					.then((source) =>
						source.ApplicationSearchComponent),
		'AssociatedEntityListComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/dynamic-components/associated-entity-list'
					+ '/associated-entity-list.component')
					.then((source) =>
						source.AssociatedEntityListComponent),
		'AssociatedKeyContactsComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/dynamic-components/associated-entity-list'
					+ '/associated-key-contacts/'
					+ 'associated-key-contacts.component')
					.then((source) =>
						source.AssociatedKeyContactsComponent),
		'AssociatedOrganizationComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/dynamic-components/associated-entity-list'
					+ '/associated-organization/'
					+ 'associated-organization.component')
					.then((source) =>
						source.AssociatedOrganizationComponent),
		'ChartComponent':
			async(): Promise<Type<any>> =>
				import('@shared/dynamic-components/chart/chart.component')
					.then((source) =>
						source.ChartComponent),
		'ClonePowerBiReportComponent':
			async(): Promise<Type<any>> =>
				import('@bi/components/wizard-steps'
					+ '/clone-power-bi-report/clone-power-bi-report.component')
					.then((source) =>
						source.ClonePowerBiReportComponent),
		'BIReportComponent':
			async(): Promise<Type<any>> =>
				import(
					'@shared/dynamic-components/bi-report-settings'
					+ '/bi-report-settings.component')
					.then((source) =>
						source.BIReportComponent),
		'BindTransactionComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps'
					+ '/policy/bind-transaction/bind-transaction.component')
					.then((source) =>
						source.BindTransactionComponent),
		'CreateClaimComponent':
			async(): Promise<Type<any>> =>
				import('@claims/components/wizard-steps'
					+ '/claim/create-claim/create-claim.component')
					.then((source) =>
						source.CreateClaimComponent),
		'CreateTransactionComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps'
					+ '/policy/create-transaction/create-transaction.component')
					.then((source) =>
						source.CreateTransactionComponent),
		'CommonListComponent':
			async(): Promise<Type<any>> =>
				import('@shared/dynamic-components/'
					+ 'common-list/common-list.component')
					.then((source) =>
						source.CommonListComponent),
		'FinalWizardLayoutStepComponent':
			async(): Promise<Type<any>> =>
				import('@shared/dynamic-components/final-wizard-layout-step'
					+ '/final-wizard-layout-step.component')
					.then((source) =>
						source.FinalWizardLayoutStepComponent),
		'FilesComponent':
			async(): Promise<Type<any>> =>
				import('@shared/dynamic-components/'
					+ 'files/files.component')
					.then((source) =>
						source.FilesComponent),
		'DynamicCommonTableComponent':
			async(): Promise<Type<any>> =>
				import('@shared/dynamic-components/dynamic-common-table'
					+ '/dynamic-common-table.component')
					.then((source) =>
						source.DynamicCommonTableComponent),
		'DynamicFormlyWrapperComponent':
			async(): Promise<Type<any>> =>
				import('@shared/dynamic-components/dynamic-formly-wrapper'
					+ '/dynamic-formly-wrapper.component')
					.then((source) =>
						source.DynamicFormlyWrapperComponent),
		'DynamicReallySimpleSyndicationFeedReaderComponent':
			async(): Promise<Type<any>> =>
				import('@shared/dynamic-components'
					+ '/dynamic-really-simple-syndication-feed-reader'
					+ '/dynamic-really-simple-syndication-feed'
						+ '-reader.component')
					.then((source) =>
						source
							.DynamicReallySimpleSyndicationFeedReaderComponent),
		'DynamicSummaryCardComponent':
			async(): Promise<Type<any>> =>
				import('@shared/dynamic-components/dynamic-summary-card'
					+ '/dynamic-summary-card.component')
					.then((source) =>
						source.DynamicSummaryCardComponent),
		'DynamicWeatherCardComponent':
			async(): Promise<Type<any>> =>
				import('@shared/dynamic-components/dynamic-weather-card'
					+ '/dynamic-weather-card.component')
					.then((source) =>
						source.DynamicWeatherCardComponent),
		'DynamicWizardComponent':
			async(): Promise<Type<any>> =>
				import('@shared/dynamic-components/dynamic-wizard'
					+ '/dynamic-wizard.component')
					.then((source) =>
						source.DynamicWizardComponent),
		'ExternalReportComponent':
			async(): Promise<Type<any>> =>
				import('@shared/dynamic-components/external-report'
					+ '/external-report.component')
					.then((source) =>
						source.ExternalReportComponent),
		'MakePaymentComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps'
					+ '/policy/make-payment/make-payment.component')
					.then((source) =>
						source.MakePaymentComponent),
		'MakePaymentReceiptComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps'
					+ '/policy/make-payment/make-payment-receipt.component')
					.then((source) =>
						source.MakePaymentReceiptComponent),
		'ManageExposurePartiesClaimComponent':
			async(): Promise<Type<any>> =>
				import('@claims/components/wizard-steps'
					+ '/claim/manage-exposure-parties-claim'
					+ '/manage-exposure-parties-claim.component')
					.then((source) =>
						source.ManageExposurePartiesClaimComponent),
		'NonRenewPolicyComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps'
					+ '/policy/non-renew-policy/non-renew-policy.component')
					.then((source) =>
						source.NonRenewPolicyComponent),
		'TransactionDeclineReasonsComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps'
					+ '/policy/transaction-decline-reasons'
					+ '/transaction-decline-reasons.component')
					.then((source) =>
						source.TransactionDeclineReasonsComponent),
		'NextPaymentInstallmentDetailsComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/dynamic-components'
					+ '/next-payment-installment-details'
					+ '/next-payment-installment-details.component')
					.then((source) =>
						source.NextPaymentInstallmentDetailsComponent),
		'NotesComponent':
			async(): Promise<Type<any>> =>
				import('@shared/dynamic-components/notes/notes.component')
					.then((source) =>
						source.NotesComponent),
		'PaymentDetailsComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps'
					+ '/policy/make-payment/payment-details'
					+ '/payment-details.component')
					.then((source) =>
						source.PaymentDetailsComponent),
		'ParentSelectionComponent':
			async(): Promise<Type<any>> =>
				import('@entity/components/wizard-steps'
					+ '/parent-selection/parent-selection.component')
					.then((source) =>
						source.ParentSelectionComponent),
		'PaymentBatchApproveComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps'
					+ '/policy/payment-batch-approve'
					+ '/payment-batch-approve.component')
					.then((source) =>
						source.PaymentBatchApproveComponent),
		'PaymentBatchComponent':
			async(): Promise<Type<any>> =>
				import('@shared/dynamic-components/payment-batch'
						+ '/payment-batch.component')
					.then((source) =>
						source.PaymentBatchComponent),
		'PaymentBatchCreateComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps'
					+ '/policy/payment-batch-create'
					+ '/payment-batch-create.component')
					.then((source) =>
						source.PaymentBatchCreateComponent),
		'PaymentMethodCreateComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps'
					+ '/policy/add-payment-method/'
					+ 'payment-method-create.component')
					.then((source) =>
						source.PaymentMethodCreateComponent),
		'PaymentMethodProcessorComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps'
					+ '/policy/add-payment-method/'
					+ 'payment-method-processor.component')
					.then((source) =>
						source.PaymentMethodProcessorComponent),
		'PaymentReversalConfirmationComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps'
					+ '/policy/payment-reversal/'
					+ 'payment-reversal-confirmation.component')
					.then((source) =>
						source.PaymentReversalConfirmationComponent),
		'PaymentReversalSelectionComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps'
					+ '/policy/payment-reversal/'
					+ 'payment-reversal-selection.component')
					.then((source) =>
						source.PaymentReversalSelectionComponent),
		'ProcessOnePaymentMethodComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps'
					+ '/policy/add-payment-method/process-one/'
					+ 'process-one-payment-method.component')
					.then((source) =>
						source.ProcessOnePaymentMethodComponent),
		'ProductSettingsComponent':
			async(): Promise<Type<any>> =>
				import('@shared/dynamic-components/product-settings/'
				+ 'product-settings.component')
					.then((source) =>
						source.ProductSettingsComponent),
		'ProductSettingListComponent':
			async(): Promise<Type<any>> =>
				import('@shared/dynamic-components/product-settings/'
				+ 'product-setting-list/product-setting-list.component')
					.then((source) =>
						source.ProductSettingListComponent),
		'RescindNonRenewPolicyComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps'
					+ '/policy/rescind-non-renew-policy'
					+ '/rescind-non-renew-policy.component')
					.then((source) =>
						source.RescindNonRenewPolicyComponent),
		'RulesComponent':
			async(): Promise<Type<any>> =>
				import('@shared/dynamic-components/rules/rules.component')
					.then((source) =>
						source.RulesComponent),
		'RuleListItemComponent':
			async(): Promise<Type<any>> =>
				import('@shared/dynamic-components/rules/'
					+ 'rule-list-item/rule-list-item.component')
					.then((source) =>
						source.RuleListItemComponent),
		'SelectExistingPowerBiReportComponent':
			async(): Promise<Type<any>> =>
				import('@bi/components/wizard-steps'
					+ '/select-existing-power-bi-report/'
					+ 'select-existing-power-bi-report.component')
					.then((source) =>
						source.SelectExistingPowerBiReportComponent),
		'TermAlterPaymentPlanComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps/policy'
					+ '/term-alter-payment-plan'
					+ '/term-alter-payment-plan.component')
					.then((source) =>
						source.TermAlterPaymentPlanComponent),
		'TransactionAssetsComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/dynamic-components/associated-entity-list'
					+ '/transaction-assets/transaction-assets.component')
					.then((source) =>
						source.TransactionAssetsComponent),
		'TransactionCancelEffectiveDateComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps/policy'
					+ '/transaction-cancel-effective-date'
					+ '/transaction-cancel-effective-date.component')
					.then((source) =>
						source.TransactionCancelEffectiveDateComponent),
		'TransactionCancelReasonsComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps/policy'
					+ '/transaction-cancel-reasons'
					+ '/transaction-cancel-reasons.component')
					.then((source) =>
						source.TransactionCancelReasonsComponent),
		'TransactionCancelReinstateCommentsComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps/policy'
					+ '/transaction-cancel-reinstate-comments'
					+ '/transaction-cancel-reinstate-comments.component')
					.then((source) =>
						source.TransactionCancelReinstateCommentsComponent),
		'TransactionCoveragesDeductiblesComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/dynamic-components/associated-entity-list'
					+ '/transaction-coverages-deductibles'
					+ '/transaction-coverages-deductibles.component')
					.then((source) =>
						source.TransactionCoveragesDeductiblesComponent),
		'TransactionFormsComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/dynamic-components/associated-entity-list'
					+ '/transaction-forms/transaction-forms.component')
					.then((source) =>
						source.TransactionFormsComponent),
		'TransactionEndorseIssueDifferencesComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps/policy/'
					+ 'transaction-endorse-issue-differences'
					+ '/transaction-endorse-issue-differences'
					+ '.component')
					.then((source) =>
						source.TransactionEndorseIssueDifferencesComponent),
		'TransactionEndorseIssueSummaryComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps/policy'
					+ '/transaction-endorse-issue-summary'
					+ '/transaction-endorse-issue-summary'
					+ '.component')
					.then((source) =>
						source.TransactionEndorseIssueSummaryComponent),
		'TransactionEndorseReasonsComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps'
					+ '/policy/transaction-endorse-reasons'
					+ '/transaction-endorse-reasons.component')
					.then((source) =>
						source.TransactionEndorseReasonsComponent),
		'TransactionEndorseStatusEffectiveDateComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components'
					+ '/wizard-steps/policy'
					+ '/transaction-endorse-status-effective-date'
					+ '/transaction-endorse-status-effective-date.component')
					.then((source) =>
						source.TransactionEndorseStatusEffectiveDateComponent),
		'TransactionInterestsComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/dynamic-components/associated-entity-list'
					+ '/transaction-interests/transaction-interests.component')
					.then((source) =>
						source.TransactionInterestsComponent),
		'TransactionReasonsComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/dynamic-components/associated-entity-list'
					+ '/transaction-reasons/transaction-reasons.component')
					.then((source) =>
						source.TransactionReasonsComponent),
		'TransactionRescindCancelComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps/policy'
					+ '/transaction-rescind-cancel'
					+ '/transaction-rescind-cancel.component')
					.then((source) =>
						source.TransactionRescindCancelComponent),
		'TransactionRescindEndorseComponent':
			async(): Promise<Type<any>> =>
				import('@insurance/components/wizard-steps/policy'
					+ '/transaction-rescind-endorse'
					+ '/transaction-rescind-endorse.component')
					.then((source) =>
						source.TransactionRescindEndorseComponent),
		'VerifyCoverageClaimComponent':
			async(): Promise<Type<any>> =>
				import('@claims/components/wizard-steps'
					+ '/claim/verify-coverage-claim'
					+ '/verify-coverage-claim.component')
					.then((source) =>
						source.VerifyCoverageClaimComponent),
		'WorkItemListComponent':
			async(): Promise<Type<any>> =>
				import('@shared/dynamic-components/work-items/work-item-list/'
					+ 'work-item-list.component')
					.then((source) =>
						source.WorkItemListComponent),
		'WorkItemsComponent':
			async(): Promise<Type<any>> =>
				import('@shared/dynamic-components/work-items/'
					+ 'work-items.component')
					.then((source) =>
						source.WorkItemsComponent)
	};
}