/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/member-ordering */

import {
	animate,
	keyframes,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';
import {
	DisplayAnimation,
	EaseAnimation
} from '@shared/app-animations';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	MenuItem
} from 'primeng/api';
import {
	MouseEventConstants
} from '@shared/constants/mouse-event.constants';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/* eslint-enable max-len */

@Component({
	selector: '[operation-subgroup]',
	templateUrl: './operation-menu-subgroup.component.html',
	styleUrls: ['./operation-menu-subgroup.component.scss'],
	animations: [
		DisplayAnimation,
		EaseAnimation,
		trigger('children', [
			state('hiddenAnimated', style({
				height: '0px'
			})),
			state('visibleAnimated', style({
				height: '*'
			})),
			state('visible', style({
				display: 'block'
			})),
			state('hidden', style({
				display: 'none'
			})),
			transition('visibleAnimated => hiddenAnimated',
				animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
			transition('hiddenAnimated => visibleAnimated',
				animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
		]),
		trigger('divState', [
			state('in',
				style({
					backgroundColor: 'red',
					transform: AppConstants.contentAnimation.translateXZero
				})),
			transition('void => *', [
				animate(
					1000,
					keyframes([
						style({
							opacity: 0,
							transform: 'translateX(-100%)',
							offset: 0
						}),
						style({
							backgroundColor: '#bee0ff',
							opacity: 1,
							transform: AppConstants.contentAnimation
								.translateXZero,
							offset: 0.3
						}),
						style({
							opacity: 1,
							transform: AppConstants.contentAnimation
								.translateXZero,
							offset: 1.0
						})
					]))
			]),
			transition('* => void', [
				animate(300, keyframes([
					style({
						opacity: 1,
						transform: AppConstants.contentAnimation.translateXZero,
						offset: 0
					}),
					style({
						opacity: 1,
						transform: AppConstants.contentAnimation.translateXZero,
						offset: 0.7
					}),
					style({
						opacity: 0,
						transform: 'translateX(100%)',
						offset: 1.0
					})
				]))
			])
		])
	]
})

/**
 * A component used to handle nested operation sub groups.
 *
 * @export
 * @class OperationMenuSubGroupComponent
 */
export class OperationMenuSubGroupComponent
{
	/**
	 * Gets or sets the current menu item.
	 *
	 * @type {MenuItem}
	 * @memberof OperationMenuSubGroupComponent
	 */
	@Input() public item: MenuItem;

	/**
	 * Gets or sets the value of the current item
	 * being a root menu item.
	 *
	 * @type {boolean}
	 * @memberof OperationMenuSubGroupComponent
	 */
	@Input() public root: boolean;

	/**
	 * Gets or sets whether or not this menu
	 * item is currently visible. This is calculated on each
	 * list and passed to the child list.
	 *
	 * @type {boolean}
	 * @memberof OperationMenuSubGroupComponent
	 */
	@Input() public visible: boolean;

	/**
	 * Gets or sets the dynamic component context.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof OperationMenuSubGroupComponent
	 */
	 @Input() public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets whether or not this menu item
	 * needs reset. This is accessed in a custom
	 * get/set.
	 *
	 * @type {boolean}
	 * @memberof OperationMenuSubGroupComponent
	 */
	public _reset: boolean;

	/**
	 * Gets or sets whether or not this menu item
	 * needs reset.
	 *
	 * @type {boolean}
	 * @memberof OperationMenuSubGroupComponent
	 */
	@Input() public get reset(): boolean
	{
		return this._reset;
	}
	public set reset(val: boolean)
	{
		this._reset = val;

		if (this._reset)
		{
			this.activeIndex = null;
		}
	}

	/**
	 * Gets or sets whether or not this menu's
	 * parent item is currently active. This is
	 * accessed in a custom get/set.
	 *
	 * @type {boolean}
	 * @memberof OperationMenuSubGroupComponent
	 */
	public _parentActive: boolean;

	/**
	 * Gets or sets whether or not this menu's
	 * parent item is currently active.
	 *
	 * @type {boolean}
	 * @memberof OperationMenuSubGroupComponent
	 */
	@Input() public get parentActive(): boolean
	{
		return this._parentActive;
	}
	public set parentActive(val: boolean)
	{
		this._parentActive = val;

		if (!this._parentActive)
		{
			this.activeIndex = null;
		}
	}

	/**
	 * Gets or sets the event emitter for the menu item being clicked. This
	 * event will be passed to the parent of this component if specified.
	 *
	 * @type {EventEmitter<MenuItem>}
	 * @memberof OperationMenuSubGroupComponent
	 */
	@Output() public menuItemClicked: EventEmitter<MenuItem> =
		new EventEmitter();

	/**
	 * Gets or the sub menu's active index.
	 *
	 * @type {number}
	 * @memberof OperationMenuSubGroupComponent
	 */
	public activeIndex: number;

	/**
	 * Handles the click event attached to the menu item.
	 *
	 * @param {any} event
	 * The click event.
	 * @param {MenuItem} item
	 * The menu item that sent this click event.
	 * @param {number} index
	 * The menu item's index.
	 * @memberof OperationMenuSubGroupComponent
	 */
	public async itemClick(
		event: any,
		item: any,
		index: number): Promise<void>
	{
		// IOS requires a tap, so this handles our click event and keeps
		// our click outside interface.
		if (AnyHelper.isNull(event.srcEvent))
		{
			event.preventDefault();
			event.stopPropagation();

			return;
		}

		// Avoid processing disabled items.
		if (item.disabled)
		{
			return;
		}

		// Perform item level commands.
		if (item.command)
		{
			if (item.useCommandPromise === true)
			{
				await StringHelper.transformToDataPromise(
					StringHelper.interpolate(
						<string>item.command,
						this.context),
					this.context);
			}
			else
			{
				item.command(
					{
						originalEvent: event,
						item: item
					});
			}
		}

		// Allow the selection.
		this.activeIndex = (this.activeIndex === index)
			? null
			: index;

		this.menuItemClicked.emit(item);
	}

	/**
	 * Handles the item click event sent from operations in this menu.
	 * This will bubble the item clicked action up the tree from
	 * children components all the way to the top level operation menu
	 * component.
	 *
	 * @param {MenuItem} item
	 * The menu item that sent this click event.
	 * @memberof OperationMenuSubGroupComponent
	 */
	public itemClicked(
		menuItem: MenuItem): void
	{
		this.menuItemClicked.emit(menuItem);
	}

	/**
	 * This will handle the tap mobile only event on the tooltip icon
	 * and toggle the display of the tooltip.
	 *
	 * @param {any} element
	 * The element that has captured a tooltip toggle event.
	 * @memberof OperationMenuSubGroupComponent
	 */
	public mobileTooltipToggle(
		element: any): void
	{
		element.dispatchEvent(
			new Event(MouseEventConstants.mouseEnter));
	}

	/**
	 * This method will remove the auto focus click event attached to
	 * primeNg tooltips.
	 *
	 * @param {MouseEvent} event
	 * The click event to be captured and halted.
	 * @param {string} tooltipMessage
	 * If sent, this will be the tooltip message signifying that a tooltip
	 * should be displayed. If this is null, the nested click command
	 * will be ran.
	 * @memberof OperationMenuSubGroupComponent
	 */
	public preventDefault(
		event: MouseEvent,
		tooltipMessage: string): void
	{
		if (AnyHelper.isNullOrWhitespace(tooltipMessage))
		{
			return;
		}

		event.preventDefault();
		event.stopImmediatePropagation();
	}

	/**
	 * Gets whether or not this menu item is active.
	 *
	 * @param {number} index
	 * The menu item's index.
	 * @memberof OperationMenuSubGroupComponent
	 */
	public isActive(
		index: number): boolean
	{
		return this.activeIndex === index
			&& (this.parentActive === true
				|| this.root === true);
	}
}