/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiFilterHelper
} from '@shared/helpers/api-filter.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Directive,
	Input,
	OnInit
} from '@angular/core';
import {
	DisplayComponentDefinition
} from '@shared/implementations/display-components/display-component-definition';
import {
	DisplayComponentDefinitionApiService
} from '@api/services/display-components/display-component-definition.api.service';
import {
	DisplayComponentTypeApiService
} from '@api/services/display-components/display-component-type.api.service';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IDisplayComponentDefinition
} from '@shared/interfaces/display-components/display-component-definition.interface';
import {
	IDisplayComponentType
} from '@shared/interfaces/display-components/display-component-type.interface';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEmbedConfiguration
} from 'embed';
import {
	IGroupedDropdownOption
} from '@shared/interfaces/application-objects/grouped-dropdown-option.interface';
import {
	IPowerBiDataset
} from '@shared/interfaces/reports/power-bi/power-bi-dataset.interface';
import {
	IPowerBiGroup
} from '@shared/interfaces/reports/power-bi/power-bi-group.interface';
import {
	IPowerBiReport
} from '@shared/interfaces/reports/power-bi/power-bi-report.interface';
import {
	IPowerBiReportDefinition
} from '@shared/interfaces/reports/power-bi/power-bi-report-definition.interface';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	PowerBiApiService
} from '@shared/services/power-bi-api.service';
import {
	PowerBiService
} from '@shared/services/power-bi.service';
import {
	ReportConstants
} from '@shared/constants/report.constants';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/* eslint-enable max-len */

@Directive({
	selector: '[PowerBiWizardStep]'
})

/**
 * A directive representing shared logic for a component interacting
 * with power bi in a wizard.
 *
 * @export
 * @class PowerBiComponentDirective
 * @implements {OnInit}
 */
export class PowerBiWizardStepDirective<TApiDataType> implements OnInit
{
	/**
	 * Creates an instance of the power bi wizard step directive.
	 *
	 * @param {PowerBiService} powerBiService
	 * The power bi display service used for external power bi reports.
	 * @param {PowerBiApiService} powerBiApiService
	 * The power bi api service used for external power bi reports.
	 * @param {DisplayComponentDefinitionApiService}
	 * displayComponentDefinitionApiService
	 * The display component definition api service used in this component.
	 * @param {DisplayComponentTypeApiService}
	 * displayComponentTypeApiService
	 * The display component type api service used in this component.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this component.
	 * @memberof PowerBiWizardStepDirective
	 */
	public constructor(
		public powerBiService: PowerBiService,
		public powerBiApiService: PowerBiApiService,
		public displayComponentDefinitionApiService:
			DisplayComponentDefinitionApiService,
		public displayComponentTypeApiService:
			DisplayComponentTypeApiService,
		public siteLayoutService: SiteLayoutService)
	{
	}

	/**
	 * Gets the string that signifies the active step holds a report location
	 * selection of existing.
	 *
	 * @type {string}
	 * @memberof PowerBiWizardStepDirective
	 */
	public static readonly existingReportLocation: string = 'Existing';

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<DynamicWizardComponent, any>}
	 * @memberof PowerBiWizardStepDirective
	 */
	@Input() public context:
		IDynamicComponentContext<DynamicWizardComponent, any>;

	/**
	 * Gets or sets the context of the report that will be set
	 * during initialization.
	 *
	 * @type {IDynamicComponentContext<DynamicWizardComponent, any>}
	 * @memberof PowerBiWizardStepDirective
	 */
	public reportContext: IDynamicComponentContext<DynamicWizardComponent, any>;

	/**
	 * Gets or sets the lookup type used when gathering available values of
	 * the api data type.
	 *
	 * @type {string}
	 * @memberof PowerBiWizardStepDirective
	 */
	public lookupType: string;

	/**
	 * Gets or sets the available grouped lookups for report or dataset
	 * selection.
	 *
	 * @type {IGroupedDropdownOption[]}
	 * @memberof PowerBiWizardStepDirective
	 */
	public groupedSelectionOptions: IGroupedDropdownOption[] = [];

	/**
	 * Gets or sets the formly layout used in implementing components.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof PowerBiWizardStepDirective
	 */
	public dynamicFormlyLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the selected lookup for report creation or cloning.
	 *
	 * @type {TApiDataType}
	 * @memberof PowerBiWizardStepDirective
	 */
	public selectedItem: TApiDataType;

	/**
	 * Gets the value specifying if we should clone or create a new report.
	 *
	 * @type {boolean}
	 * @memberof PowerBiWizardStepDirective
	 */
	public get useExisting(): boolean
	{
		return this.context.source.activeMenuItem
			.currentData.data.reportLocation ===
				PowerBiWizardStepDirective.existingReportLocation;
	}

	/**
	 * Handles the on initialization event.
	 * This will load and initialize the lookups available for report creation.
	 *
	 * @async
	 * @memberof PowerBiWizardStepDirective
	 */
	public async ngOnInit(): Promise<void>
	{
		await this.setupGroupedSelections();
		await this.performPostInitActions();
	}

	/**
	 * Handles the post initialization action, this can be implemented in
	 * a directive component and it will fire after initialization
	 * is complete.
	 *
	 * @async
	 * @memberof PowerBiWizardStepDirective
	 */
	public async performPostInitActions(): Promise<void>
	{
		// Override in the implementing component.
		this.context.source.wizardStepLoading = false;
	}

	/**
	 * Handles the validity changed event sent from the child dynamic
	 * formly component. This will update the validity of the form for
	 * action buttons.
	 *
	 * @param {boolean} isValid
	 * The validity of the current displayed step data set.
	 * @memberof PowerBiWizardStepDirective
	 */
	public async validityChanged(
		isValid: boolean): Promise<void>
	{
		this.context.source.validStepChanged(isValid);
	}

	/**
	 * Loads and defines the available grouped selection options for the api
	 * data type being requested.
	 *
	 * @async
	 * @memberof PowerBiWizardStepDirective
	 */
	public async setupGroupedSelections(): Promise<void>
	{
		this.groupedSelectionOptions = [];
		const definedGroups: IPowerBiGroup[] = [];
		const groupedOptions: IGroupedDropdownOption[] = [];

		const displayTypes: IDisplayComponentType[] =
			await this.displayComponentTypeApiService
				.query(
					`${AppConstants.commonProperties.name}.StartsWith(`
						+ '\'Report.PowerBi\') eq true',
					AppConstants.empty);
		const typeFilter: string =
			ApiFilterHelper.getEnumerationFilter(
				AppConstants.commonProperties.typeId,
				displayTypes.map(
					(displayType: IDisplayComponentType) =>
						`${displayType.id}`));
		const availableReportDefinitions: IDisplayComponentDefinition[] =
			await this.displayComponentDefinitionApiService
				.query(
					typeFilter,
					AppConstants.empty);
		const availableGroups: IPowerBiGroup[] =
			await this.powerBiApiService.baseLevelData(
				ReportConstants.powerBiObjectTypes.group);

		let standardGroupId: string = AppConstants.empty;
		for (const definitionInterface of availableReportDefinitions)
		{
			const definition: DisplayComponentDefinition =
				new DisplayComponentDefinition(definitionInterface);
			const definitionGroupId = definition.jsonDefinition.groupId;

			if (definitionGroupId.indexOf('${') === -1)
			{
				const matchingPowerBiGroups: IPowerBiGroup[] =
					availableGroups.filter(
						(group: IPowerBiGroup) =>
							group.id === definitionGroupId);
				definedGroups.push(
					matchingPowerBiGroups[0]);

				switch (definition.jsonDefinition.workspaceType)
				{
					case ReportConstants.powerBiWorkspaceTypes.custom:
						this.context.source
							.addOrUpdateStepData(
								<object>
								{
									customGroupId:
										matchingPowerBiGroups[0].id
								});
						break;
					case ReportConstants.powerBiWorkspaceTypes.standard:
						standardGroupId = matchingPowerBiGroups[0].id;
						const mappedDatasets: string[] =
							await this.getAvailableGroupDatasets(
								matchingPowerBiGroups[0].id);
						this.context.source
							.addOrUpdateStepData(
								<object>
								{
									standardGroupId:
										matchingPowerBiGroups[0].id,
									standardDatasetIds:
										mappedDatasets
								});
						break;
				}
			}
		}

		for (const powerBiGroup of definedGroups)
		{
			const matchingValues: TApiDataType[] =
				await this.powerBiApiService
					.groupLevelData<TApiDataType>(
						powerBiGroup.id,
						this.lookupType);
			const groupedItems: IDropdownOption[] =
				matchingValues
					.filter(
						(item: TApiDataType) =>
							(<any>item).name.indexOf(
								AppConstants.characters.underscore) !== 0)
					.map(
						(item: TApiDataType) =>
							this.mapOption(
								item));

			if (groupedItems.length > 0)
			{
				groupedOptions.push(
					<IGroupedDropdownOption>
					{
						label:
							standardGroupId === powerBiGroup.id
								? ReportConstants.powerBiWorkspaceTypes.standard
								: ReportConstants.powerBiWorkspaceTypes.custom,
						value: powerBiGroup.id,
						items: groupedItems
					});
			}
		}

		this.sortDropdownOptionsByLabel(
			groupedOptions);
		this.groupedSelectionOptions = groupedOptions;

		this.groupedSelectionOptions.forEach(
			(groupedDropdownOption: IGroupedDropdownOption) =>
			{
				this.sortDropdownOptionsByLabel(
					groupedDropdownOption.items);
			});
	}

	/**
	 * Creates an existing power bi report loaded from the power bi api
	 * service if a report context is created and creates a report
	 * definition in step data.
	 *
	 * @param {IPowerBiReport} powerBiReport
	 * The report to clone.
	 * @param {boolean} createReportContext
	 * If true this will create a report context value for an external
	 * report display. This value defaults to true.
	 * @param {string} groupId
	 * If sent, this will use the group id sent directly instead of performing
	 * a lookup on a currently displayed report dropdown.
	 * @memberof PowerBiWizardStepDirective
	 */
	public async createReportDefinition(
		powerBiReport: IPowerBiReport,
		createReportContext: boolean = true,
		groupId: string = null): Promise<void>
	{
		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;

		const reportDefinition: IPowerBiReportDefinition =
			<IPowerBiReportDefinition>
			{
				datasetId: powerBiReport.datasetId,
				datasetGroupId: this.getReportDatasetGroupId(powerBiReport),
				externalReportType: ReportConstants.externalReportTypes.powerBi,
				groupId: (AnyHelper.isNullOrWhitespace(groupId)
					? this.getReportGroupId(powerBiReport.id)
					: groupId),
				pageName: ReportConstants.powerBiDefaults.pageName,
				reportId: powerBiReport.id,
				reportName: powerBiReport.name,
				reportType: ReportConstants.powerBiObjectTypes.report,
				viewOnly: true,
				newDatasetId: currentData.reportDefinition?.newDatasetId,
				newReportName: currentData.reportDefinition?.newReportName,
				workspaceType: ReportConstants.powerBiWorkspaceTypes.custom
			};

		reportDefinition.embedConfiguration =
			<IEmbedConfiguration>
			await this.powerBiApiService
				.populatePowerBiEmbedConfiguration(
					reportDefinition);

		this.context.source
			.addOrUpdateStepData(
				<object>
				{
					reportDefinition:
						reportDefinition
				});

		if (createReportContext === true)
		{
			this.reportContext = null;
			setTimeout(() =>
			{
				this.reportContext =
					<IDynamicComponentContext<DynamicWizardComponent, any>>
					{
						source: this.context.source,
						data: {
							createReport: this.useExisting === false,
							reportDefinition: reportDefinition
						}
					};
			});
		}
	}

	/**
	 * Returns the group id associated with the sent report id.
	 *
	 * @param {string} reportId
	 * The id of the report to find a group for.
	 * @returns {string}
	 * The group id associated with the sent report id.
	 * @memberof PowerBiWizardStepDirective
	 */
	protected getReportGroupId(
		reportId: string): string
	{
		return this.groupedSelectionOptions.filter(
			(groupedOption: IGroupedDropdownOption) =>
			{
				const matchingValues: IDropdownOption[] =
					groupedOption.items.filter(
						(option: IDropdownOption) =>
							option.value.id === reportId);

				return matchingValues.length > 0;
			})[0].value;
	}

	/**
	 * Returns the group id associated with the sent reports dataset id.
	 *
	 * @param {IPowerBiReport} powerBiReport
	 * The report to find a matching dataset group for.
	 * @returns {string}
	 * The matching dataset group for the sent reports dataset.
	 * @memberof PowerBiWizardStepDirective
	 */
	protected getReportDatasetGroupId(
		powerBiReport: IPowerBiReport): string
	{
		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;
		const isStandardDataset: boolean =
			(<string[]>currentData.standardDatasetIds)
				.filter((datasetId: string) =>
					datasetId === powerBiReport.datasetId).length > 0;

		return isStandardDataset
			? currentData.standardGroupId
			: currentData.customGroupId;
	}

	/**
	 * Given a sent group id this will gather all of the available datasets for
	 * this group and return this value as a string array of available
	 * dataset ids for this group.
	 *
	 * @async
	 * @param {string} groupId
	 * The group id to find and map available datasets for.
	 * @returns {string}
	 * The set of available dataset ids for the sent group in a string array.
	 * @memberof PowerBiWizardStepDirective
	 */
	protected async getAvailableGroupDatasets(
		groupId: string): Promise<string[]>
	{
		const availableDatasets: IPowerBiDataset[] =
			await this.powerBiApiService
				.groupLevelData<IPowerBiDataset>(
					groupId,
					ReportConstants
						.powerBiObjectTypes
						.dataset);

		return availableDatasets.map(
			(item: IPowerBiDataset) =>
				item.id);
	}

	/**
	 * Returns a dropdown option with the label as the item name and the
	 * value as the item.
	 *
	 * @param {TApiDataType} item
	 * The item to be mapped as an option.
	 * @memberof PowerBiWizardStepDirective
	 */
	private mapOption(
		item: TApiDataType): IDropdownOption
	{
		const label: string =
			this.lookupType === ReportConstants.powerBiObjectTypes.report
				? (<IPowerBiReport><unknown>item).name
				: (<IPowerBiDataset><unknown>item).name;

		return <IDropdownOption> {
			label: label,
			value: item
		};
	}

	/**
	 * Performs an in-place sort on the sent dropdown option array based
	 * on the label property value.
	 *
	 * @param {IDropdownOption[]} dropdownOptions
	 * The item to be mapped as an option.
	 * @memberof PowerBiWizardStepDirective
	 */
	private sortDropdownOptionsByLabel(
		dropdownOptions: IDropdownOption[])
	{
		dropdownOptions.sort((
			firstItem: IGroupedDropdownOption,
			secondItem: IGroupedDropdownOption) =>
			ObjectHelper.sortByPropertyValue(
				firstItem,
				secondItem,
				AppConstants.commonProperties.label));
	}
}