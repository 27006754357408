/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AfterViewInit,
	ChangeDetectorRef,
	Directive,
	ElementRef,
	HostListener,
	ViewChild,
	ViewChildren
} from '@angular/core';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	cloneDeep
} from 'lodash-es';
import {
	debounceTime,
	distinctUntilChanged,
	from,
	Subject,
	Subscription
} from 'rxjs';
import {
	ExtendedCustomControlDirective
} from '@entity/directives/extended-custom-control.directive';
import {
	MenuItem
} from 'primeng/api';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';

@Directive({
	selector: '[CustomActionMenu]'
})

/**
 * A directive representing shared logic for a formly custom control.
 *
 * @export
 * @extends {CustomActionMenuDirective}
 * @implements {AfterViewInit}
 * @class CustomActionMenuDirective
 */
export class CustomActionMenuDirective
	extends ExtendedCustomControlDirective
	implements AfterViewInit
{
	/** Initializes a new instance of the CustomActionMenuDirective.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof CustomActionMenuDirective
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService,
		public changeDetector: ChangeDetectorRef)
	{
		super(changeDetector);
	}

	/**
	 * Gets or sets every action item element into an ElementRef array.
	 *
	 * @type {ElementRef<any>[]}
	 * @memberof CustomActionMenuDirective
	 */
	@ViewChildren('ActionMenuItems')
	public actionMenuItemElements: ElementRef<HTMLButtonElement>[];

	/**
	 * Gets or sets the dropdown element.
	 *
	 * @type {TemplateRef<ElementRef>}
	 * @memberof CustomActionMenuDirective
	 */
	@ViewChild('InputGroupContainer')
	public inputGroupContainer: ElementRef;

	/**
	 * Gets or sets the dropdown width.
	 *
	 * @type {string}
	 * @memberof CustomActionMenuDirective
	 */
	public calculatedFieldWidth: string;

	/**
	 * Gets or sets the data subscription class
	 *
	 * @type {Subscription}
	 * @memberof CustomActionMenuDirective
	 */
	public actionMenuSubscription: Subscription;

	/**
	 * Gets or sets loading action items state.
	 *
	 * @type {boolean}
	 * @memberof CustomActionMenuDirective
	 */
	public loadingActionItems: boolean = false;

	/**
	 * Gets or sets the reset menu.
	 *
	 * @type {boolean}
	 * @memberof CustomActionMenuDirective
	 */
	public resetEllipsisMenu: boolean = false;

	/**
	 * Gets or sets the activeEllipsisMenu state of the ellipsis menu.
	 *
	 * @type {boolean}
	 * @memberof CustomActionMenuDirective
	 */
	public activeEllipsisMenu: boolean = false;

	/**
	 * Gets or sets ellipsis menu action items.
	 *
	 * @type {MenuItem[]}
	 * @memberof CustomActionMenuDirective
	 */
	public ellipsisActionItems: MenuItem[] = [];

	/**
	 * Gets or sets the activeEllipsisMenuChanged Subject boolean.
	 *
	 * @type {new Subject<boolean>()}
	 * @memberof CustomActionMenuDirective
	 */
	public activeEllipsisMenuChanged: Subject<boolean> =
		new Subject<boolean>();

	/**
	 * Gets or sets the ellipsis menu left position.
	 *
	 * @type {string}
	 * @memberof CustomActionMenuDirective
	 */
	public ellipsisMenuOffsetLeft: string;

	/**
	 * Gets or sets the ellipsis menu top position.
	 *
	 * @type {string}
	 * @memberof CustomActionMenuDirective
	 */
	public ellipsisMenuOffsetTop: string;

	/**
	 * Gets or sets the initial action menu items.
	 *
	 * @type {ElementRef[]}
	 * @memberof CustomActionMenuDirective
	 */
	public initialActionMenuItemElements: ElementRef[] = [];

	/**
	 * Gets or sets the initial action menu items.
	 *
	 * @type {number}
	 * @memberof CustomActionMenuDirective
	 */
	public calculatedActionMenuWidth: number;

	/**
	 * Gets or sets the action menu confirguration data.
	 *
	 * @type {any[]}
	 * @memberof CustomActionMenuDirective
	 */
	public actionMenuConfigurationData: any[] = [];

	/**
	 * Gets or sets the initial action menu items.
	 *
	 * @type {any[]}
	 * @memberof CustomActionMenuDirective
	 */
	public initialActionMenuItems: any[] = [];

	/**
	 * Gets the ellipsis menu state debounce delay.
	 *
	 * @type {number}
	 * @memberof CustomActionMenuDirective
	 */
	public readonly debounceDelay: number = 25;

	/**
	 * Gets the ellipsis menu width on large views.
	 *
	 * @type {number}
	 * @memberof CustomActionMenuDirective
	 */
	public readonly ellipsisMenuLargeWidth: number = 240;

	/**
	 * Gets the ellipsis menu width on small views.
	 *
	 * @type {number}
	 * @memberof CustomActionMenuDirective
	 */
	public readonly ellipsisMenuSmallWidth: number = 175;

	/**
	 * Gets the ellipsis item heigth.
	 *
	 * @type {number}
	 * @memberof CustomActionMenuDirective
	 */
	public readonly ellipsisItemHeigth: number = 42;

	/**
	 * Gets the maximum allowed of ellipsis items.
	 *
	 * @type {number}
	 * @memberof CustomActionMenuDirective
	 */
	public readonly maxEllipsisItemsCount: number = 4;

	/**
	 * Gets the ellipsis button width.
	 *
	 * @type {number}
	 * @memberof CustomActionMenuDirective
	 */
	public readonly ellipsisActionItemWidth: number = 19.75;

	/**
	 * Gets the width percentage allowed for the action menu.
	 *
	 * @type {number}
	 * @memberof CustomActionMenuDirective
	 */
	public readonly actionMenuWidthPercentage: number = 0.45;

	/**
	 * Gets the additional offset width.
	 *
	 * @type {number}
	 * @memberof CustomActionMenuDirective
	 */
	public readonly additionalOffsetWidth: number = 1;

	/**
	 * Handles the site layout change event which is called
	 * when the site layout service has altered it's variables
	 * or loading has completed.
	 * This will ensure the action menu view configuration on
	 * page resize when action menu items exist in the template options.
	 *
	 * @memberof CustomActionMenuDirective
	 */
	@HostListener(
		AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		if (AnyHelper.isNullOrEmptyArray(
			this.field.templateOptions.actionMenuItems)
			|| AnyHelper.isNullOrEmpty(this.inputGroupContainer))
		{
			return;
		}

		this.activeEllipsisMenuChanged.next(false);
		this.setActionMenuConfiguration();
	}

	/**
	 * On component initialization event.
	 * Will call the extended initialization event and in addition set the
	 * initial action menu items and the update action menu method definition.
	 *
	 * @memberof CustomActionMenuDirective
	 */
	public ngOnInit(): void
	{
		super.ngOnInit();
		this.initialActionMenuItems =
			cloneDeep(this.field.templateOptions.actionMenuItems);
		this.field.templateOptions.updateActionMenu =
			this.updateActionMenu.bind(this);
	}

	/**
	 * After component view initialization event.
	 * This method sets the action menu view configuration
	 * when action menu items exist in the template options.
	 *
	 * @memberof CustomActionMenuDirective
	 */
	public async ngAfterViewInit(): Promise<void>
	{
		if (AnyHelper.isNullOrEmptyArray(
			this.field.templateOptions.actionMenuItems)
			|| AnyHelper.isNullOrEmpty(this.inputGroupContainer))
		{
			return;
		}

		this.activeEllipsisMenuChanged.pipe(
			debounceTime(this.debounceDelay),
			distinctUntilChanged())
			.subscribe((newValue: boolean) =>
			{
				if (this.activeEllipsisMenu === true
					&& newValue === false)
				{
					this.resetEllipsisMenu = true;

					setTimeout(() =>
					{
						this.resetEllipsisMenu = false;
					},
					this.debounceDelay);
				}

				this.activeEllipsisMenu = newValue;
				this.changeDetector.detectChanges();
			});

		this.initialActionMenuItemElements =
			cloneDeep(this.actionMenuItemElements);

		if (!AnyHelper.isNullOrEmptyArray(
			this.field.templateOptions.actionMenuItems))
		{
			setTimeout(async () =>
			{
				this.populateActionMenuConfiguration();
				await this.setVisibleActionMenuItems();
				this.setActionMenuConfiguration();
			});
		}
	}

	/**
	 * Handles any dynamic action menu change.
	 *
	 * @async
	 * @memberof CustomActionMenuDirective
	 */
	public async updateActionMenu(): Promise<void>
	{
		setTimeout(
			async () =>
			{
				this.activeEllipsisMenuChanged.next(false);
				this.field.templateOptions.actionMenuItems =
					this.initialActionMenuItems;
				await this.setVisibleActionMenuItems();
				this.setActionMenuConfiguration();
			});
	}

	/**
	 * Sets the visible action menu items
	 *
	 * @async
	 * @memberof CustomActionMenuDirective
	 */
	public async setVisibleActionMenuItems(): Promise<void>
	{
		for (const actionMenuConfiguration of this.actionMenuConfigurationData)
		{
			const visible: boolean =
				AnyHelper.isNullOrEmpty(
					actionMenuConfiguration.actionMenuItem.visible)
					|| typeof actionMenuConfiguration.actionMenuItem.visible
						=== AppConstants.propertyTypes.boolean
					? actionMenuConfiguration.actionMenuItem.visible
					: await StringHelper.transformToDataPromise(
						StringHelper.interpolate(
							actionMenuConfiguration.actionMenuItem.visible,
							this.field),
						this.field);

			actionMenuConfiguration.actionMenuItem.displayActionItem = visible;
		}
	}

	/**
	 * Sets the action menu configuration required to display the
	 * needed amount of menu items allowed per display size and insert
	 * the rest, if any, into an ellipsis menu.
	 *
	 * @memberof CustomActionMenuDirective
	 */
	public setActionMenuConfiguration(): Promise<void>
	{
		this.loadingActionItems = true;
		this.ellipsisActionItems = [
			...[]
		];

		if (this.actionMenuSubscription != null)
		{
			this.actionMenuSubscription.unsubscribe();
		}

		return new Promise<void>(
			(resolve) =>
			{
				this.actionMenuSubscription =
					from(
						this.calculateDropdownFieldWidth())
						.subscribe(
							() =>
							{
								this.loadingActionItems = false;
								resolve();
							});
			});
	}

	/**
	 * Populates the action menu configuration
	 *
	 * @memberof CustomActionMenuDirective
	 */
	public populateActionMenuConfiguration(): void
	{
		if (this.actionMenuConfigurationData.length === 0)
		{
			this.initialActionMenuItemElements.forEach(
				(actionItemElement: ElementRef,
					actionItemIndex: number) =>
				{
					const actionItemwidth: number =
						(<HTMLElement>actionItemElement.nativeElement)
							.getBoundingClientRect()
							.width;

					const offsetPadding = actionItemIndex > 0
						? AppConstants.staticLayoutSizes.tinyPadding
						: 0;

					this.actionMenuConfigurationData.push(
						{
							index: actionItemIndex,
							actionMenuItem:
								this.field.templateOptions
									.actionMenuItems[actionItemIndex],
							width: actionItemwidth + offsetPadding
						});
				});
		}
	}

	/**
	 * Calculates the dropdown field width based on the given amount of
	 * action menu items and the width of each item depending on the amount
	 * of text. This will allow the set the exact allowed width for the
	 * required items to display and an ellipsis menu action if the
	 * amount of items exceeds the allowed width.
	 *
	 * @memberof CustomActionMenuDirective
	 */
	public async calculateDropdownFieldWidth(): Promise<void>
	{
		this.field.templateOptions.actionMenuItems = [];

		const actionMenuAvailableWidth: number =
			this.inputGroupContainer.nativeElement.offsetWidth
				* this.actionMenuWidthPercentage;

		const ellipsisRequiredWidth: number =
			this.ellipsisActionItemWidth
				+ AppConstants.staticLayoutSizes.tinyPadding;

		let accumulatedWidth: number = 0;
		let actionMenuWidth: number = 0;

		this.actionMenuConfigurationData.forEach(
			(actionMenuConfiguration) =>
			{
				if (actionMenuConfiguration.actionMenuItem.displayActionItem
					=== false)
				{
					return;
				}

				const ellipsisActionItemWidth: number =
					this.ellipsisActionItems.length > 0
						|| (actionMenuConfiguration
							.accumulatedWidth + ellipsisRequiredWidth)
								> actionMenuAvailableWidth
						? ellipsisRequiredWidth
						: 0;

				const actionMenuWidthLimit: number =
					actionMenuAvailableWidth - ellipsisActionItemWidth;

				actionMenuConfiguration.actionMenuItem = {
					...actionMenuConfiguration.actionMenuItem,
					...{
						maxWidth:
							`calc(${actionMenuConfiguration.width}px)`,
						loading: false
					}
				};

				accumulatedWidth += actionMenuConfiguration.width;
				if (accumulatedWidth <=
						actionMenuWidthLimit)
				{
					this.field.templateOptions.actionMenuItems.push(
						actionMenuConfiguration.actionMenuItem);

					actionMenuWidth += actionMenuConfiguration.width;
				}
				else
				{
					this.ellipsisActionItems.push(
						actionMenuConfiguration.actionMenuItem);
				}
			});

		this.calculatedActionMenuWidth =
			this.field.templateOptions.actionMenuItems.length > 0
				? actionMenuWidth
				: 0;

		this.addEllipsisMenuItem(ellipsisRequiredWidth);

		this.calculatedFieldWidth =
			`calc(100% - ${this.calculatedActionMenuWidth}px)`;
	}

	/**
	 * Adds the ellipsis menu item when there are more action items then
	 * the available space.
	 *
	 * @memberof CustomActionMenuDirective
	 */
	public addEllipsisMenuItem(ellipsisRequiredWidth: number): void
	{
		if (this.ellipsisActionItems.length > 0
			&& !this.field.templateOptions.actionMenuItems
				.some((actionMenuItem: MenuItem) =>
					actionMenuItem.icon === AppConstants.cssIcons.ellipsis))
		{
			this.field.templateOptions.actionMenuItems.push(
				{
					icon: AppConstants.cssIcons.ellipsis,
					command: () =>
					{
						this.loadingActionItems = true;
						setTimeout(
							() =>
							{
								if (this.activeEllipsisMenu === true)
								{
									return;
								}

								this.calculateEllipsisMenuLocation();
								this.activeEllipsisMenuChanged.next(
									!this.activeEllipsisMenu);
								this.loadingActionItems = false;
							},
							AppConstants.time.twentyFiveMilliseconds);

					}
				});

			this.calculatedActionMenuWidth =
				this.calculatedActionMenuWidth
					+ ellipsisRequiredWidth
					+ this.additionalOffsetWidth;
		}
	}

	/**
	 * Calculates the ellipsis menu location.
	 *
	 * @memberof CustomActionMenuDirective
	 */
	public calculateEllipsisMenuLocation(): void
	{
		const ellipsisActionItemButton: HTMLElement =
			this.actionMenuItemElements.find(
				(actionItem) =>
					actionItem.nativeElement.innerHTML
						.includes(AppConstants.cssIcons.ellipsis))
				.nativeElement;

		const ellipsisDomRect: DOMRect =
			ellipsisActionItemButton.getBoundingClientRect();

		const ellipsisMenuContainerWidth: number =
			this.siteLayoutService.contentWidth <=
				AppConstants.layoutBreakpoints.phone
				? this.ellipsisMenuSmallWidth
				: this.ellipsisMenuLargeWidth;

		this.ellipsisMenuOffsetLeft =
			`calc(${ellipsisActionItemButton.offsetLeft}px`
				+ ` - ${ellipsisMenuContainerWidth}px`
				+ ` + ${ellipsisDomRect.width}px)`;

		const availableTopDisplay: number =
			ellipsisDomRect.top
				- (this.siteLayoutService.displayTabletView === true
					? AppConstants.staticLayoutSizes.mobileHeaderHeight
					: 0);

		const availableBottomDisplay: number =
			this.siteLayoutService.windowHeight
				- ellipsisDomRect.bottom;

		const useTopDisplay: boolean =
			availableTopDisplay > availableBottomDisplay
				&& !ellipsisActionItemButton.offsetParent.className
					.includes(AppConstants.cssClasses.scrollPanelContent);

		if (useTopDisplay)
		{
			const ellipsisMenuItemsCount: number =
				this.ellipsisActionItems.length > this.maxEllipsisItemsCount
					? this.maxEllipsisItemsCount
					: this.ellipsisActionItems.length;
			const additionalEllipsisMenuTop: number =
				 ellipsisMenuItemsCount
					* this.ellipsisItemHeigth;

			this.ellipsisMenuOffsetTop =
				`calc(${ellipsisActionItemButton.offsetTop}px - `
					+ `${additionalEllipsisMenuTop}px)`;
		}
	}

	/** Executes the actionMenuItem command.
	 *
	 * @param {Event} event
	 * The click event.
	 * @param {any} actionMenuItem
	 * The the action menu item with the command to be executed.
	 * @memberof CustomActionMenuDirective
	 */
	public executeActionMenuItemCommand(
		event: Event,
		actionMenuItem: any): void
	{
		if (AnyHelper.isNullOrEmpty(
			actionMenuItem?.command))
		{
			throw new Error('Command is not defined.');
		}

		if (actionMenuItem?.icon === AppConstants.cssIcons.ellipsis)
		{
			actionMenuItem.command();

			return;
		}

		actionMenuItem.loading = true;

		setTimeout(
			async () =>
			{
				if (AnyHelper.isFunction(
					actionMenuItem.command))
				{
					await actionMenuItem.command();
				}
				else
				{
					await StringHelper.transformToLayoutPromise(
						actionMenuItem.command,
						this.field,
						event,
						this.field.templateOptions.context);
				}

				this.activeEllipsisMenuChanged.next(false);
				this.setActionMenuConfiguration();
			},
			AppConstants.time.quarterSecond);
	}

	/**
	 * Handles the close overlay event sent from the operation menu
	 * component. This is sent when an action item is clicked, any
	 * site layout event is capture or click outside the ellipsis menu.
	 *
	 * @memberof CustomActionMenuDirective
	 */
	public closeEllipsisMenu(): void
	{
		setTimeout(
			() =>
			{
				this.activeEllipsisMenuChanged.next(false);
			},
			AppConstants.time.twentyFiveMilliseconds);
	}
}