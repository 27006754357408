/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	OnInit,
	Output
} from '@angular/core';
import {
	SelectCustomControlDirective
} from '@entity/directives/select-custom-control.directive';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

@Component({
	selector: 'custom-multi-select',
	templateUrl: './custom-multi-select.component.html',
	styleUrls: [
		'./custom-multi-select.component.scss'
	]
})

/**
 * A component representing an instance of a Custom Multi-Select.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomMultiSelectComponent
 * @extends {SelectCustomControlDirective}
 * @implements {OnInit}
 */
export class CustomMultiSelectComponent
	extends SelectCustomControlDirective
	implements OnInit
{
	/** Initializes a new instance of the CustomMultiSelectComponent.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service.
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof CustomMultiSelectComponent
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService,
		public changeDetector: ChangeDetectorRef)
	{
		super(siteLayoutService,
			changeDetector);
	}

	/**
	 * Gets or sets the filter event that will be emitted when the filter
	 * input is altered.
	 *
	 * @type {EventEmitter<string>}
	 * @memberof CustomMultiSelectComponent
	 */
	@Output() public onfilterEvent: EventEmitter<string> =
		new EventEmitter<string>();

	/**
	 * On component initialization event.
	 * This method is used to set default values if these are defined
	 * and a set value does not exist.
	 *
	 * @memberof CustomMultiSelectComponent
	 */
	public ngOnInit()
	{
		this.archivedDisable = this.field.templateOptions.disabled;
		this.archivedPlaceHolder = this.field.templateOptions.placeholder;

		if (AnyHelper.isNull(this.dataOptions))
		{
			this.dataOptions = this.field.templateOptions.options;
		}

		this.field.templateOptions.updateDataOptions =
			this.updateDataOptions.bind(this);

		if (!AnyHelper.isNull(this.field.templateOptions?.default)
			&& AnyHelper.isNull(this.field.formControl.value))
		{
			setTimeout(() =>
			{
				this.field.formControl.setValue(
					this.field.templateOptions.default);
			});
		}

		super.ngOnInit();
		this.loading = false;
	}

	/**
	 * This event handler method is used to capture and send a filter event
	 * from a filtered multiSelect.
	 *
	 * @param {any} event
	 * The filter event that fired this handler.
	 * @memberof CustomMultiSelectComponent
	 */
	public onFilter(
		event: any): void
	{
		this.onfilterEvent.emit(event.filter);
	}

	/**
	 * This event handler method is used to set the value of the form control
	 * as an empty string if null or undefined. This will then call the existing
	 * change function.
	 *
	 * @param {any} event
	 * The change event that fired this handler.
	 * @memberof CustomMultiSelectComponent
	 */
	 public change(
		event: any): void
	{
		if (!AnyHelper.isNullOrWhitespace(this.field.templateOptions.change))
		{
			this.field.templateOptions.change(
				this.field,
				event);
		}

		this.validateControl();
	}
}