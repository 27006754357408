/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ClaimConstants
} from '@claims/constants/claims-constants';
import {
	Directive,
	Input,
	OnInit
} from '@angular/core';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	IActionResponse
} from '@shared/interfaces/workflow/action-response.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IInsuranceEntityTypes
} from '@insurance/interfaces/insurance-entity-types.interface';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	InsuranceService
} from '@insurance/services/insurance.service';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Router
} from '@angular/router';
import {
	SessionService
} from '@shared/services/session.service';

@Directive({
	selector: '[InsuranceStatusReasons]'
})

/**
 * A component representing a shared display for gathering selected reasons and
 * note data.
 *
 * @export
 * @class StatusReasonsDirective
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class StatusReasonsDirective
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of a status reasons directive which will share
	 * logic for a wizard step using a status reasons component.
	 *
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes on entity creation.
	 * @param {EntityService} entityService
	 * The entity service used to lookup entity modules upon creation.
	 * @param {InsuranceService} insuranceService
	 * The insurance service used to lookup insurance modules upon creation.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service used in this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @param {SessionService} sessionService
	 * The session service used in this component.
	 * @memberof StatusReasonsDirective
	 */
	public constructor(
		public router: Router,
		public activityService: ActivityService,
		public moduleService: ModuleService,
		public entityService: EntityService,
		public insuranceService: InsuranceService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<DynamicWizardComponent, IWizardContext>}
	 * @memberof StatusReasonsDirective
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the entity instance id that will store these reasons and
	 * notes.
	 *
	 * @type {number}
	 * @memberof StatusReasonsDirective
	 */
	public entityInstanceId: number;

	/**
	 * Gets or sets the entity instance type group that will store these reasons
	 * and notes.
	 *
	 * @type {string}
	 * @memberof StatusReasonsDirective
	 */
	public entityInstanceTypeGroup: string;

	/**
	 * Gets or sets the insruance entity types.
	 *
	 * @type {IInsuranceEntityTypes}
	 * @memberof StatusReasonsDirective
	 */
	public insuranceEntityTypes: IInsuranceEntityTypes;

	/**
	 * Gets or sets the primary entity type.
	 *
	 * @type {IEntityType}
	 * @memberof StatusReasonsDirective
	 */
	public entityType: IEntityType;

	/**
	 * Gets or sets the primary entity instance associated to the current step
	 * data.
	 *
	 * @type {IEntityInstance}
	 * @memberof StatusReasonsDirective
	 */
	public entityInstance: IEntityInstance;

	/**
	 * Gets or sets the PolicyTerm entity instance associated to the current
	 * Policy data.
	 *
	 * @type {IEntityInstance}
	 * @memberof StatusReasonsDirective
	 */
	public policyTermEntityInstance: IEntityInstance;

	/**
	 * Gets or sets the Product entity instance associated to the current
	 * data.
	 *
	 * @type {IEntityInstance}
	 * @memberof StatusReasonsDirective
	 */
	public productEntityInstance: IEntityInstance;

	/**
	 * Gets or sets the workflow action being executed against the current
	 * wizard steps entity instance.
	 *
	 * @type {string}
	 * @memberof StatusReasonsDirective
	 */
	protected workflowActionName: string;

	/**
	 * Implements the on initialization interface.
	 * This method will lookup and initialize data for a status reasons
	 * display.
	 *
	 * @async
	 * @memberof StatusReasonsDirective
	 */
	public async ngOnInit(): Promise<void>
	{
		this.context.source.wizardStepLoading = true;

		const currentData: any =
			this.context.source.activeMenuItem.currentData;

		this.entityType =
			await this.entityTypeApiService
				.getSingleQueryResult(
					`${AppConstants.commonProperties.name} `
						+ `eq '${currentData.data.entityType}'`,
					AppConstants.empty);

		this.entityInstanceId =
			currentData.data.id;
		this.entityInstanceTypeGroup =
			this.entityType.group;

		if (this.entityInstanceTypeGroup !==
			ClaimConstants.claimEntityTypeGroups.claims)
		{
			this.insuranceEntityTypes =
			await this.insuranceService.populateInsuranceEntityTypes();

			this.entityInstance =
				await this.getEntityInstance(
					this.entityInstanceTypeGroup,
					this.entityInstanceId);

			this.policyTermEntityInstance =
				await this.getPolicyTerm();

			this.productEntityInstance =
				await this.getProduct(
					this.insuranceEntityTypes.productEntityType,
					this.policyTermEntityInstance.data.productName);
		}

		await this.performPostInitActions();

		this.context.source.updateGuardComparisonData();

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * Handles the post initialization action.
	 * This will be implemented in components using this directive for business
	 * logic that requires loaded values.
	 *
	 * @async
	 * @memberof StatusReasonsDirective
	 */
	public async performPostInitActions(): Promise<void>
	{
		// this method can be implemented in the extended component for
		// wizard step specific setups.
	}

	/**
	 * Handles the validity changed event sent from the status reason component.
	 *
	 * @param {boolean} isValid
	 * A truthy defining if the displayed status reason component is valid.
	 * @memberof StatusReasonsDirective
	 */
	public validityChanged(
		isValid: boolean): void
	{
		this.context.source.validStepChanged(isValid);
	}

	/**
	 * Gets the entity instance data associated to the current
	 * entity instance id collected from the Wizard data.
	 *
	 * @async
	 * @param {string} entityInstanceTypeGroup
	 * The entity type group.
	 * @param {number} entityInstanceId
	 * The entity instance id.
	 * @return {Promise<IEntityInstance>}
	 * The entity instance.
	 * @memberof StatusReasonsDirective
	 */
	protected async getEntityInstance(
		entityInstanceTypeGroup: string,
		entityInstanceId: number): Promise<IEntityInstance>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			entityInstanceTypeGroup;

		return this.entityInstanceApiService
			.get(entityInstanceId);
	}

	/**
	 * Gets the PolicyTerm instance data associated to the current
	 * entity instance id using this component.
	 *
	 * @async
	 * @return {Promise<IEntityInstance>}
	 * The PolicyTerm instance.
	 * @memberof StatusReasonsDirective
	 */
	protected async getPolicyTerm(): Promise<IEntityInstance>
	{
		if (this.entityInstanceTypeGroup ===
			this.insuranceEntityTypes.policyTermEntityType.group)
		{
			return this.entityInstance;
		}

		this.entityInstanceApiService
			.entityInstanceTypeGroup = this.entityInstanceTypeGroup;

		const entityAssociations: IEntityInstance[] =
			this.entityInstanceTypeGroup
				.indexOf(InsuranceConstants.policyTermTransactionPrefix) === 0
				? await this.entityInstanceApiService
					.getParents(
						this.entityInstanceId,
						null,
						null,
						null,
						AppConstants.dataLimits.large,
						this.insuranceEntityTypes.policyTermEntityType.group)
				: await this.entityInstanceApiService
					.getChildren(
						this.entityInstanceId,
						null,
						null,
						null,
						AppConstants.dataLimits.large,
						this.insuranceEntityTypes.policyTermEntityType.group);

		return entityAssociations[0];
	}

	/**
	 * Gets the Product instance data associated to the current PolicyTerm data.
	 *
	 * @async
	 * @param {IEntityType} productEntityType
	 * The Product entity type.
	 * @param {number} productName
	 * The product name.
	 * @return {Promise<IEntityInstance>}
	 * The Product instance.
	 * @memberof StatusReasonsDirective
	 */
	protected async getProduct(
		productEntityType: IEntityType,
		productName: string): Promise<IEntityInstance>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup = productEntityType.group;

		const policyInstance: IEntityInstance =
			await this.entityInstanceApiService
				.getSingleQueryResult(
					`${AppConstants.commonProperties.name} eq '${productName}'`,
					AppConstants.empty);

		return policyInstance;
	}

	/**
	 * Executes an entity workflow action.
	 *
	 * @async
	 * @param {IEntityType} workflowEntityType
	 * The workflow entity type.
	 * @param {IEntityInstance} workflowInstanceData
	 * The workflow entity instance data to be updated.
	 * @returns {Promise<IActionResponse>}
	 * The IActionResponse response from the Policy update.
	 * @param {string} query
	 * An optional query string of parameters.
	 * @memberof StatusReasonsDirective
	 */
	protected async executeWorkflowAction(
		workflowEntityType: IEntityType,
		workflowInstanceData: IEntityInstance,
		query?: string): Promise<IActionResponse>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			workflowEntityType.group;

		const actionResponse: IActionResponse =
			await this.entityInstanceApiService
				.executeAction(
					workflowInstanceData.id,
					this.workflowActionName,
					workflowInstanceData,
					query);

		return actionResponse;
	}

	/**
	 * Gets the available reasons from the sent reasons array.
	 *
	 * @param {any[]} reasons
	 * The product reasons available.
	 * @param {string} type
	 * The status reason type to filter the result set by.
	 * @returns {any[]}
	 * The list of all active reasons in the sent reason array.
	 * @memberof StatusReasonsDirective
	 */
	protected getReasons(
		reasons: any[],
		type: string): any[]
	{
		return reasons
			.filter(
				(reason: any) =>
					reason.active === true
						&& reason.type === type)
			.sort(
				(itemOne: any,
					itemTwo: any) =>
					ObjectHelper.sortByPropertyValue(
						itemOne,
						itemTwo,
						AppConstants.commonProperties.name));
	}
}