/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonFormlyFieldConstants
} from '@shared/constants/common-formly-field-constants';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	Component
} from '@angular/core';
import {
	FormControl
} from '@angular/forms';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IOperationGroup
} from '@operation/interfaces/operation-group.interface';
import {
	OperationGroupApiService
} from '@api/services/operations/operation-group.api.service';
import {
	OperationGroupComponent
} from './operation-group-expand/operation-group.component';
import {
	OptionsFactory
} from '@shared/factories/options-factory';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	TableHelper
} from '@shared/helpers/table.helper';

/* eslint-disable max-len */

@Component({
	selector: 'app-operation-groups',
	templateUrl: './operation-groups.component.html'
})

/**
 * A component representing an instance of the user interface operation
 * groups component.
 *
 * @export
 * @class OperationGroupsComponent
 * @extends {CommonTablePageDirective}
 */
export class OperationGroupsComponent
	extends CommonTablePageDirective
{
	/**
	 * Initializes a new instance of the operation group component and sets up
	 * the services to be utilized within the component.
	 *
	 * @param {OperationGroupApiService} operationGroupApiService
	 * The api service used to load the operation group data.
	 * @param {OptionsFactory} optionsFactory
	 * The options factory used for common dropdown options.
	 * @param {ActivityService} activityService
	 * The activity service to be displayed in the Activity List Component.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof OperationGroupsComponent
	 */
	public constructor(
		public operationGroupApiService: OperationGroupApiService,
		public optionsFactory: OptionsFactory,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the table definitions for the standard table view.
	 *
	 * @type {ICommonTable}
	 * @memberof OperationGroupsComponent
	 */
	public operationGroupsTableDefinitions: ICommonTable;

	/**
	 * Gets the formly validator debounce time.
	 *
	 * @type {number}
	 * @memberof OperationGroupsComponent
	 */
	private readonly validatorDebounceTime: number = 250;

	/**
	 * Sets up variables used in this admin page based table.
	 *
	 * @memberof ManageUserComponent
	 */
	public setupPageVariables(): void
	{
		let displayOrder: number = 1;
		this.availableColumns =
			[
				{
					dataKey: 'id',
					columnHeader: 'Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'label',
					columnHeader: 'Label',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'description',
					columnHeader: 'Description',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'icon',
					columnHeader: 'Icon',
					displayOrder: displayOrder
				}
			];
		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Sets up the list column definitions for the current
	 * operation definition object list.
	 *
	 * @async
	 * @memberof OperationGroupsComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		const securityGroupOptions: IDropdownOption[] =
			await this.optionsFactory.getSecurityGroupOptions();

		this.operationGroupsTableDefinitions = {
			actions: {
				create: {
					layout: [
						{
							key: 'data.name',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls.customFieldWrapper
							],
							templateOptions: {
								label: 'Name',
								description: 'Name',
								required: true,
								maxLength: 64
							},
							asyncValidators: {
								uniqueName: {
									expression: (control: FormControl) =>
										this.isExistingName(control.value),
									message: 'Existing Name.'
								}
							}
						},
						{
							key: 'data.label',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls.customFieldWrapper
							],
							templateOptions: {
								label: 'Label',
								description: 'Label',
								maxLength: 64
							}
						},
						{
							key: 'data.icon',
							type: FormlyConstants.customControls.customIconInput,
							wrappers: [
								FormlyConstants.customControls.customFieldWrapper
							],
							templateOptions: {
								label: 'Icon',
								description: 'Icon',
								externalLinkTooltip: 'Font Awesome Icons',
								externalLink: AppConstants
									.externalUrls.fontAwesomeIcons,
								maxLength: 256
							}
						},
						{
							key: 'data.description',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls.customFieldWrapper
							],
							templateOptions: {
								label: 'Description',
								description: 'Description',
								required: true,
								maxLength: 256
							}
						},
						CommonFormlyFieldConstants.publicField,
						{
							...CommonFormlyFieldConstants
								.ownershipSecurityGroupField,
							templateOptions: {
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField
									.templateOptions,
								options: securityGroupOptions
							}
						}
					],
					items: [
						{
							label: 'Create',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: () => this.createOperationGroup()
						}]
				},
				view: {
					component: OperationGroupComponent,
					layout: [
						{
							key: 'data.name',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls.customFieldWrapper
							],
							templateOptions: {
								label: 'Name',
								disabled: true
							}
						},
						{
							key: 'data.label',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls.customFieldWrapper
							],
							templateOptions: {
								label: 'Label',
								disabled: true
							}
						},
						{
							key: 'data.icon',
							type: FormlyConstants.customControls.customIconInput,
							wrappers: [
								FormlyConstants.customControls.customFieldWrapper
							],
							templateOptions: {
								label: 'Icon',
								disabled: true
							}
						},
						{
							key: 'data.description',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls.customFieldWrapper
							],
							templateOptions: {
								label: 'Description',
								disabled: true
							}
						},
						{
							...CommonFormlyFieldConstants
								.publicField,
							templateOptions: {
								...CommonFormlyFieldConstants
									.publicField
									.templateOptions,
								disabled: true
							}
						},
						{
							...CommonFormlyFieldConstants
								.ownershipSecurityGroupField,
							templateOptions: {
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField
									.templateOptions,
								options: securityGroupOptions,
								disabled: true
							}
						}
					],
					items: []
				},
				update: {
					component: OperationGroupComponent,
					layout: [
						{
							key: 'data.name',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls.customFieldWrapper
							],
							templateOptions: {
								label: 'Name',
								description: 'Name',
								required: true,
								maxLength: 64
							},
							asyncValidators: {
								uniqueName: {
									expression: async(control: FormControl) =>
										this.allowToUpdateName(control.value),
									message: 'Existing Name.'
								}
							}
						},
						{
							key: 'data.label',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls.customFieldWrapper
							],
							templateOptions: {
								label: 'Label',
								description: 'Label',
								maxLength: 64
							}
						},
						{
							key: 'data.icon',
							type: FormlyConstants.customControls.customIconInput,
							wrappers: [
								FormlyConstants.customControls.customFieldWrapper
							],
							templateOptions: {
								label: 'Icon',
								description: 'Icon',
								externalLinkTooltip: 'Font Awesome Icons',
								externalLink: AppConstants
									.externalUrls.fontAwesomeIcons,
								maxLength: 256
							}
						},
						{
							key: 'data.description',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls.customFieldWrapper
							],
							templateOptions: {
								label: 'Description',
								description: 'Description',
								required: true,
								maxLength: 256
							}
						},
						CommonFormlyFieldConstants.publicField,
						{
							...CommonFormlyFieldConstants
								.ownershipSecurityGroupField,
							templateOptions: {
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField
									.templateOptions,
								options: securityGroupOptions
							}
						}
					],
					items: [
						{
							label: 'Save',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: () => this.updateOperationDefinition()
						}]
				},
				delete: {
					items: [
						{
							label: 'Confirm',
							styleClass: AppConstants.cssClasses.pButtonDanger,
							disabled: false,
							command: () => this.deleteOperationGroup()
						}],
					deleteStatement: () => this.deleteStatementLogic(),
				}
			},
			tableTitle: 'Groups',
			objectSearch: {
				filter: AppConstants.empty,
				orderBy: `Id ${AppConstants.sortDirections.descending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: this.tableRowCount
			},
			apiPromise: (objectSearch: IObjectSearch) =>
				this.operationGroupApiService
					.query(
						objectSearch.filter,
						objectSearch.orderBy,
						objectSearch.offset,
						objectSearch.limit),
			availableColumns: this.availableColumns,
			selectedColumns: this.selectedColumns,
			columnSelectionMode: this.columnSelectionMode,
			expandTitle: () =>
				TableHelper.getExpandTitle(
					this.commonTableContext,
					'Operation Group'),
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			rowCountChanged: (rowCount: number) =>
			{
				this.tableRowCount = rowCount;
				this.restoreTableDefinition();
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.operationGroupsTableDefinitions.selectedColumns =
					selectedColumns;
				this.selectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.operationGroupsTableDefinitions.columnSelectionMode =
					columnSelectionMode;
				this.columnSelectionMode = columnSelectionMode;
			}
		};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Creates an operation definition.
	 *
	 * @async
	 * @memberof OperationGroupsComponent
	 */
	private async createOperationGroup(): Promise<void>
	{
		const operationGroupData: IOperationGroup =
			<IOperationGroup>this.commonTableContext.source.rowData;

		await this.activityService.handleActivity(
			new Activity(
				this.operationGroupApiService
					.create(
						operationGroupData),
				'<strong>Creating Operation Group</strong>',
				'<strong>Created Operation Group</strong>',
				`Operation Group ${this.commonTableContext.source.rowData.name}
					was successfully created.`,
				`Operation Group ${this.commonTableContext.source.rowData.name}
					was not created.`));

		this.restoreTableDefinition();
	}

	/**
	 * Deletes an operation groups.
	 *
	 * @async
	 * @memberof OperationGroupsComponent
	 */
	private async deleteOperationGroup(): Promise<void>
	{
		await this.activityService.handleActivity(
			new Activity(
				this.operationGroupApiService
					.delete(this.commonTableContext.source.rowData.id),
				'<strong>Deleting Operation Group</strong>',
				'<strong>Deleted Operation Group</strong>',
				`Operation Group ${this.commonTableContext.source.rowData.id} ` +
					'was deleted.',
				`Operation Group ${this.commonTableContext.source.rowData.id} ` +
					'was not deleted.'));

		this.restoreTableDefinition();
	}

	/**
	 * Defines and validates if the value is existing or not.
	 *
	 * @async
	 * @param {string} value
	 * The Name value.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof OperationGroupsComponent
	 */
	private async isExistingName(value: string): Promise<boolean>
	{
		const operationGroup =
			await this.operationGroupApiService
				.query(
					`Name eq '${value}'`,
					AppConstants.empty);

		return new Promise((resolve) =>
		{
			setTimeout(
				() => resolve(operationGroup.length === 0),
				this.validatorDebounceTime);
		});
	}

	/**
	 * Defines and validates if the value is existing or not.
	 *
	 * @async
	 * @param {string} value
	 * The Name value.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof OperationGroupsComponent
	 */
	private async allowToUpdateName(value: string): Promise<boolean>
	{
		let  allowedToUpdate: boolean = true;
		const persistedGroup =
				await this.operationGroupApiService
					.get(this.commonTableContext.source.rowData.id);

		if (persistedGroup.name !== value)
		{
			const operationGroup =
				await this.operationGroupApiService
					.query(
						`Name eq '${value}'`,
						AppConstants.empty);

			allowedToUpdate = operationGroup.length <= 0;
		}

		return new Promise(
			(resolve) =>
			{
				setTimeout(
					() => resolve(allowedToUpdate),
					this.validatorDebounceTime);
			});
	}

	/**
	 * Returns a delete statement string
	 * based on if there are any children relationship.
	 *
	 * @async
	 * @returns {Promise<string>}
	 * The delete statement to be displayed on te component.
	 * @memberof OperationGroupsComponent
	 */
	private async deleteStatementLogic(): Promise<string>
	{
		const childRelationship = await this.operationGroupApiService
			.getChildren(this.commonTableContext.source.rowData.id);

		this.operationGroupsTableDefinitions.actions.delete.items[1]
			.disabled = childRelationship.length > 0;

		return childRelationship.length > 0
			? `Unable to delete Group
				${this.commonTableContext.source.rowData.id}
				${this.commonTableContext.source.rowData.name}
				due to child relationships.`
			: `Confirm you are about to delete Group
				${this.commonTableContext.source.rowData.id}
				${this.commonTableContext.source.rowData.name}.`;
	}

	/**
	 * Updates an operation definition.
	 *
	 * @async
	 * @memberof OperationDefinitionsComponent
	 */
	private async updateOperationDefinition(): Promise<void>
	{
		await this.activityService.handleActivity(
			new Activity(
				this.operationGroupApiService
					.update(
						this.commonTableContext.source.rowData.id,
						<IOperationGroup>this.commonTableContext.source.rowData),
				'<strong>Updating Operation Group</strong>',
				'<strong>Updated Operation Group</strong>',
				`Operation Group ${this.commonTableContext.source.rowData.id}
					was updated.`,
				`Operation Group ${this.commonTableContext.source.rowData.id}
					was not updated.`),
			AppConstants.activityStatus.complete,
			true);
	}
}