/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AppModule
} from 'src/app/app.module';
import {
	enableProdMode
} from '@angular/core';
import {
	environment
} from 'src/environments/environment';
import {
	platformBrowserDynamic
} from '@angular/platform-browser-dynamic';
import 'hammerjs';

if (environment.production)
{
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.log(err));
