<div class="dashboard-widget-container">
	<div class="dashboard-widget content-g-{{widget.width * responsiveWidthMultiplier}} no-padding">
		<div class="float-right widget-settings-icon-container"
			[ngClass]="{
				'fixed-height-icon-container': useFixedIcon,
				'summary-card-offset': widget.dynamicComponent === 'DynamicSummaryCardComponent',
				'active-overlay-container': displayingOverlay
			}">

			<app-display-component-parameter-header
				[parameterLayoutData]="widget.parameterLayoutData"
				[parentParameterLayoutData]="parentParameterLayoutData"
				[parameterLayoutSchema]="widget.parameterLayoutSchema"
				[displayParameters]="false"
				[displayParameterOption]="widget.parameterLayoutSchema !== null"
				[displaySecondaryOption]="widget.secondaryDynamicComponent != null"
				[displayEditOption]="displayEditOption"
				[displayingSettings]="settingsActive"
				[displayingSecondary]="secondaryDisplayActive"
				[display]="parameterDisplayReady"
				[useClickOnDisplay]="widget.useClickOnDisplay"
				(displaySettingsClick)="settingsDisplayClicked()"
				(displaySecondaryClick)="secondaryDisplayClicked()"
				(displayEditClick)="editDisplayClicked()">
			</app-display-component-parameter-header>
		</div>

		<div #PrimaryDisplay
			(click)="clickOnDisplay()"
			class="widget-container content-h-{{secondaryDisplayActive === true && useOverlay === false ? widget.secondaryHeight : widget.height}}"
			[ngClass]="{
				'active-container theme-box-shadow': displayingOverlay,
				'fixed-height-icon-widget-container': useFixedIcon,
				'cursor-pointer': widget.useClickOnDisplay === true && widget.secondaryDynamicComponent != null
			}">
			<div class="widget-content"
				[ngClass]="{
					'centered-card-content': widget.widgetContext.data.chartDefinition === null && (widget.height != null && widget.height >= 3 && (secondaryDisplayActive === true ? widget.secondaryTopAlign !== true : widget.topAlign !== true))
				}">
				<div [ngClass]="{
						'display-none': (settingsActive === true || secondaryDisplayActive === true) && useOverlay === false
					}">
					<app-dynamic-component
						[context]="widget.widgetContext"
						[displayComponent]="widget.dynamicComponent">
					</app-dynamic-component>
				</div>

				<div *ngIf="secondaryDisplayActive === true && useOverlay === false"
					[ngClass]="{
						'display-none': settingsActive === true || secondaryDisplayActive === false || useOverlay === true
					}">
					<app-dynamic-component
						[context]="widget.secondaryDynamicContext"
						[displayComponent]="widget.secondaryDynamicComponent">
					</app-dynamic-component>
				</div>

				<ng-template [ngIf]="settingsActive === true && useOverlay === false">

					<div [ngClass]="{
							'box-shadow': useFixedIcon === true
						}">

						<app-display-component-parameter
							[displayComponentInstance]="widget.widgetDisplayComponentInstance"
							[parameterLayoutData]="widget.parameterLayoutData"
							[parameterLayoutSchema]="widget.parameterLayoutSchema"
							[pageContext]="pageContext"
							[useScrollPanel]="widget.height >= 6"
							[useOverlay]="useOverlay"
							[settingsActive]="settingsActive"
							(appliedParameters)="applyParameters()"
							(cancelClicked)="cancelClicked()">
						</app-display-component-parameter>
					</div>

				</ng-template>

			</div>

			<div class="position-relative secondary-parameter-container"
				*ngIf="settingsActive === true && useOverlay === true">
				<div class="content-g-12 secondary-parameter-panel no-padding">
					<div class="secondary-parameter-overlay">
						<div class="secondary-parameter-container theme-box-shadow">

							<div class='overlay-content'
								[ngClass]="{
									'box-shadow': useFixedIcon === true
								}">

								<span *ngIf="widget.dynamicComponent === 'ExternalReportComponent'">
									<br/>
								</span>

								<app-display-component-parameter
									[displayComponentInstance]="widget.widgetDisplayComponentInstance"
									[parameterLayoutData]="widget.parameterLayoutData"
									[parameterLayoutSchema]="widget.parameterLayoutSchema"
									[pageContext]="pageContext"
									[useScrollPanel]="widget.height >= 6"
									[useOverlay]="useOverlay"
									[settingsActive]="settingsActive"
									(appliedParameters)="applyParameters()"
									(cancelClicked)="cancelClicked()">
								</app-display-component-parameter>
							</div>

						</div>
					</div>
				</div>
			</div>

		</div>
	</div>

	<div class="position-relative secondary-dashboard-widget-container">
		<div #SecondaryOverlay
			class="dashboard-widget content-g-12 secondary-dashboard-widget no-padding">
			<div class="secondary-widget-overlay"
				*ngIf="displayingSecondaryOverlay">
				<div class="widget-container secondary-widget-container theme-box-shadow content-h-{{widget.secondaryHeight}}"
					[ngClass]="{
						'fixed-height-icon-widget-container': widget.secondaryFloatingIcon === false
					}">
					<div class="widget-content"
						clickOutside
						(clickOutside)="secondaryDisplayClicked(true)"
						[contentDisplayed]="displayingSecondaryOverlay === true">

						<app-dynamic-component
							[context]="widget.secondaryDynamicContext"
							[displayComponent]="widget.secondaryDynamicComponent">
						</app-dynamic-component>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>