<ng-template [ngIf]="!loading"
	[ngIfElse]= "loadingDisplay">
	<div class="text-display-container ui-g-12">
		<ng-template [ngIf]="to.usePanelDisplay"
			[ngIfElse]= "useDiv">
			<div class="accordion-content"
				[ngClass]="{
					'text-center': (to.centerText === true)
				}">
				<p-accordion *ngIf= "to.usePanelDisplay">
					<p-accordionTab header="{{to.title}}"
						[selected]="to.expanded">
						<ng-template [ngIf]="hasContent() && (to.useMarkdown || to.useCodeBlock)"
							[ngIfElse]= "useText">
							<span *ngIf="to.copyToClipboard && content !== '' && content != null"
								class="copy-to-clipboard"
								(click)="copyToClipboard(content)">
								<i *ngIf="!copiedToClipboard"
									class="pi pi-copy theme-color float-right">
								</i>
								<span *ngIf="copiedToClipboard"
									class="fade-out">
									<i class="pi pi-check float-right">
									</i>
									<span class="float-right">
										copied!
									</span>
								</span>
							</span>
							<markdown [data]="content">
							</markdown>
						</ng-template>

						<ng-template #useText>
							<span *ngIf="to.copyToClipboard && content !== '' && content != null"
								class="copy-to-clipboard"
								(click)="copyToClipboard(content)">
								<i  *ngIf="!copiedToClipboard"
									class="pi pi-copy theme-color float-right">
								</i>
								<span *ngIf="copiedToClipboard"
									class="fade-out">
									<i class="pi pi-check float-right">
									</i>
									<span class="float-right">
										copied!
									</span>
								</span>
							</span>
							<p [innerHtml]="content">
							</p>
						</ng-template>
					</p-accordionTab>
				</p-accordion>

			</div>
		</ng-template>

		<ng-template #useDiv>
			<div class="text-content"
				[ngClass]="{
					'text-center': (to.centerText === true)
				}">
				<ng-template [ngIf]="hasContent() && (to.useMarkdown || to.useCodeBlock)"
					[ngIfElse]= "useText">
					<span *ngIf="to.copyToClipboard && content !== '' && content != null"
						class="copy-to-clipboard"
						(click)="copyToClipboard(content)">
						<i  *ngIf="!copiedToClipboard"
							class="pi pi-copy theme-color float-right">
						</i>
						<span *ngIf="copiedToClipboard"
							class="fade-out">
							<i class="pi pi-check float-right">
							</i>
							<span class="float-right">
								copied!
							</span>
						</span>
					</span>
					<markdown [data]="content">
					</markdown>
				</ng-template>

				<ng-template #useText>
					<span *ngIf="content !== '' && content != null"
						class="copy-to-clipboard"
						(click)="copyToClipboard(content)">
						<i *ngIf="!copiedToClipboard"
							class="pi pi-copy theme-color float-right">
						</i>
						<span *ngIf="copiedToClipboard"
							class="fade-out">
							<i class="pi pi-check float-right">
							</i>
							<span class="float-right">
								copied!
							</span>
						</span>
					</span>
					<h2>
						{{to.title}}
					</h2>
					<p [innerHtml]="content">
					</p>
				</ng-template>
			</div>
		</ng-template>
	</div>
</ng-template>

<ng-template #loadingDisplay>
	<div class="text-center ui-g-12">
		<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
		</span>
	</div>
</ng-template>