/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable max-len */

import {
	AppAuthGuard
} from '@shared/guards/app-auth.guard';
import {
	AppCanDeactivateGuard
} from '@shared/guards/app-can-deactivate.guard';
import {
	CommonModule
} from '@angular/common';
import {
	EntityComponent
} from '@entity/entity.component';
import {
	EntityFormComponent
} from '@entity/components/entity-form/entity-form.component';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	EntitySearchComponent
} from '@entity/components/entity-search/entity-search.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	GenericBasePageComponent
} from '@appComponents/generic-base-page/generic-base-page.component';
import {
	MarkdownModule
} from 'ngx-markdown';
import {
	ModuleGuard
} from '@shared/guards/module.guard';
import {
	NgModule
} from '@angular/core';
import {
	ParentSelectionComponent
} from '@entity/components/wizard-steps/parent-selection/parent-selection.component';
import {
	RouterModule
} from '@angular/router';
import {
	SharedModule
} from '@shared/shared.module';

/* eslint-enable max-len */

@NgModule({
	declarations: [
		EntityComponent,
		EntityFormComponent,
		EntityInstanceComponent,
		EntitySearchComponent,
		EntityComponent,
		EntityFormComponent,
		EntityInstanceComponent,
		EntitySearchComponent,
		ParentSelectionComponent
	],
	imports: [
		CommonModule,
		MarkdownModule.forChild(),
		SharedModule,
		RouterModule.forChild([
			{
				path: ':moduleName/entities',
				component: EntityComponent,
				canActivate: [
					AppAuthGuard,
					ModuleGuard
				],
				children: [
					{
						path: ':entityType/view/:id',
						component: EntityInstanceComponent
					},
					{
						path: ':entityType/edit/:id',
						component: EntityInstanceComponent
					},
					{
						path: 'display',
						children: [
							{
								path: ':displayComponent/create',
								component: GenericBasePageComponent
							}
						]
					},
					{
						path: '',
						component: EntitySearchComponent
					}
				]
			}])
	],
	exports: [
		ParentSelectionComponent
	],
	providers: [
		AppCanDeactivateGuard,
		EntityService,
		ParentSelectionComponent
	]
})

/**
 * A module used to hold entity route specific
 * logic and display resources.
 *
 * @export
 * @class EntityModule
 */
export class EntityModule
{
}