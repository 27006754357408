/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 * A class representing static constant and read only values for formly.
 *
 * @export
 * @class FormlyConstants
 */
export class FormlyConstants
{
	public static readonly appendToTargets: {
		body: string;
	} =
	{
		body: 'body'
	};

	public static readonly attributeKeys: {
		associatedRules: string;
		dataKey: string;
		fieldIndex: string;
		sectionIndex: string;
		tabIndex: string;
	} =
	{
		associatedRules: 'associated-rules',
		dataKey: 'data-key',
		fieldIndex: 'field-index',
		sectionIndex: 'section-index',
		tabIndex: 'tab-index'
	};

	public static readonly controlStatus: {
		invalid: string;
	} =
	{
		invalid: 'INVALID'
	};

	public static readonly customControls: {
		customCalendar: string;
		customCheckbox: string;
		customDashboard: string;
		customDataSelect: string;
		customTabContent: string;
		customEmptyWrapper: string;
		customFieldWrapper: string;
		customIframe: string;
		customIconInput: string;
		customImageInput: string;
		customInputNumber: string;
		customInputPassword: string;
		customInputRadioButton: string;
		customInputSearch: string;
		customInputSwitch: string;
		customMessage: string;
		customMultiSelect: string;
		customOperationButton: string;
		customRepeater: string;
		customResourceIdentifier: string;
		customSectionTitle: string;
		customSelect: string;
		customTableDisplay: string;
		customTextArea: string;
		customTextDisplay: string;
		input: string;
	} =
	{
		customCalendar: 'custom-calendar',
		customCheckbox: 'custom-checkbox',
		customDashboard: 'custom-dashboard',
		customDataSelect: 'custom-data-select',
		customTabContent: 'custom-tab-content',
		customEmptyWrapper: 'custom-empty-wrapper',
		customFieldWrapper: 'custom-field-wrapper',
		customIconInput: 'custom-icon-input',
		customIframe: 'custom-iframe',
		customInputNumber: 'custom-input-number',
		customImageInput: 'custom-image-input',
		customInputPassword: 'custom-input-password',
		customInputRadioButton: 'custom-input-radio-button',
		customInputSearch: 'custom-input-search',
		customInputSwitch: 'custom-input-switch',
		customMessage: 'custom-message',
		customMultiSelect: 'custom-multi-select',
		customOperationButton: 'custom-operation-button',
		customRepeater: 'custom-repeater',
		customResourceIdentifier: 'custom-resource-identifier',
		customSectionTitle: 'custom-section-title',
		customSelect: 'custom-select',
		customTableDisplay: 'custom-table-display',
		customTextArea: 'custom-textarea',
		customTextDisplay: 'custom-text-display',
		input: 'input',
	};

	public static readonly formlyMethodParameters: {
		control: string;
		disableField: string;
		error: string;
		event: string;
		field: string;
		formState: string;
		model: string;
	} =
	{
		control: 'control',
		disableField: 'disableField',
		error: 'error',
		event: 'event',
		field: 'field',
		formState: 'formState',
		model: 'model'
	};

	public static readonly gridColumns: {
		halfGrid: string;
		fullGrid: string;
	} =
	{
		halfGrid: '6',
		fullGrid: '12'
	};

	public static readonly gridSizeRelationship: {
		halfGrid: number;
		fullGrid: number;
	} =
	{
		halfGrid: 2,
		fullGrid: 1
	};

	public static readonly repeaterActions: {
		add: string;
		remove: string;
	} =
	{
		add: 'add',
		remove: 'remove'
	};

	public static readonly valueChangeTypes: {
		valueChanges: string;
	} =
	{
		valueChanges: 'valueChanges'
	};

	public static readonly textAreaRowSizes: {
		small: number;
		standard: number;
		large: number;
		fullSize: number;
	} =
	{
		small: 5,
		standard: 10,
		large: 20,
		fullSize: 40
	};
}