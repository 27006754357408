/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	EventEmitter,
	Output
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	FileDirective
} from '@shared/directives/file.directive';
import {
	FileService
} from '@shared/services/files/file.service';
import {
	HttpEvent,
	HttpEventType
} from '@angular/common/http';
import {
	ResolverService
} from '@shared/services/resolver.service';

@Component({
	selector: 'app-download-file',
	templateUrl: './download-file.component.html',
	styleUrls: ['./download-file.component.scss']
})

/**
 * A class for downloading files
 *
 * @export
 * @class DownloadFileComponent
 * @extends {FileDirective}
 */
export class DownloadFileComponent
	extends FileDirective
{
	/**
	 * Initializes this component
	 * @param {FileService} fileService
	 * The file service for this component
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to get the entity type data.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The api service used to get entity instance data.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof DownloadFileComponent
	 */
	public constructor(
		private readonly fileService: FileService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public resolver: ResolverService)
	{
		super(
			entityTypeApiService,
			entityInstanceApiService,
			resolver);
	}

	/**
	 * Gets or sets the event emitter for notifying
	 * about a download
	 *
	 * @type {EventEmitter<void>}
	 * @memberof DownloadFileComponent
	 */
	@Output()
	public downloaded: EventEmitter<void> =
		new EventEmitter<void>();

	/**
	 * Gets or sets the progress message.
	 *
	 * @type {string}
	 * @memberof DownloadFileComponent
	 */
	public progressMessage: string = 'Accessing storage...';

	/**
	 * Gets or sets a vlue indicating whether to
	 * show the download form.
	 *
	 * @type {boolean}
	 * @memberof DownloadFileComponent
	 */
	public showDownload: boolean = true;

	/**
	 * Gets or sets the window for opening a print dialogue.
	 *
	 * @type {Window}
	 * @memberof DownloadFileComponent
	 */
	 public printWindow: Window;

	/**
	 * Gets or sets a the value for deciding
	 * what to do with the downloaded file.
	 *
	 * @private
	 * @type {string}
	 * @memberof DownloadFileComponent
	*/
	private openOrDownload: string = 'save';

	/**
	 * Handles the open click event.
	 *
	 * @memberof DownloadFileComponent
	*/
	public onOpenClicked(): void
	{
		this.openOrDownload = 'open';
		this.getFile();
	}

	/**
	 * Handles the save click event.
	 *
	 * @memberof DownloadFileComponent
	*/
	public onSaveClicked(): void
	{
		this.openOrDownload = 'save';
		this.getFile();
	}

	/**
	 * Handles the save click event.
	 *
	 * @memberof DownloadFileComponent
	*/
	public onPrintClicked(): void
	{
		this.openOrDownload = 'print';
		this.getFile();
	}

	/**
	 * downloads a file and opens it or prompts to save.
	 *
	 * @private
	 * @memberof DownloadFileComponent
	 */
	private getFile(): void
	{
		this.showDownload = false;
		this.progressVisible = true;

		const typeGroup =
			this.categories.find(category =>
				category.value === this.fileEntity.entityType)
				.entityType.group;

		this.fileService
			.download(
				this.fileEntity.id,
				typeGroup)
			.subscribe(
				(event: HttpEvent<Blob>) =>
				{
					if (event.type === HttpEventType.DownloadProgress)
					{
						this.progressMessage = 'Downloading...';
						this.progressMode = 'progressBar';

						this.progressAmount = Math.round(
							(event.loaded /
							this.fileEntity.data.metadata.sizeInBytes)
								* 100);

						if (this.progressAmount >= 100)
						{
							setTimeout(() =>
							{
								this.progressMode = 'done';
								this.progressMessage = 'Done!';
								setTimeout(() => {
									this.downloaded.emit();
								},
								AppConstants.time.twoSeconds);
							},
							AppConstants.time.oneSecond);
						}
					}

					if (event.type === HttpEventType.Response)
					{
						const blob: Blob = event.body;

						const fileUrl = window.URL
							.createObjectURL(blob);

						const anchor = document
							.createElement('a');

						document.body
							.appendChild(anchor);

						anchor.href = fileUrl;

						anchor.download = this.fileEntity
							.data
							.name;

						switch (this.openOrDownload)
						{
							case 'open':
								window.open(fileUrl);
								break;
							case 'save':
								anchor.click();
								break;
							case 'print':
								this.printWindow = window.open(
									fileUrl,
									AppConstants
										.windowTargets.blank);
								this.printWindow.print();
								break;
						}
					}
				});
	}
}