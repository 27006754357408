/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 * A constant library used to defined constants that are used to
 * setup payment batches.
 *
 * @export
 * @class PaymentBatchConstants
 */
export class PaymentBatchConstants
{
	/**
	 * Gets the identifiers used for payment batch business logic.
	 *
	 * @type {object}
	 * @memberof PaymentBatchConstants
	 */
	public static readonly paymentBatchIdentifiers:
	{
		entityTypePrefix: string;
		entityTypeIdentifier: string;
		parentNavigationEntityIdentifer: string;
		parentNavigationEntityType: string;
		paymentBatchIdentifier: string;
	} = {
		entityTypePrefix: 'PaymentBatch.',
		entityTypeIdentifier: 'PaymentBatch.*',
		parentNavigationEntityIdentifer: 'ParentNavigationEntityId',
		parentNavigationEntityType: 'ParentNavigationEntityType',
		paymentBatchIdentifier: 'PaymentBatchIdentifier'
	};

	/**
	 * Gets the payment batch status options.
	 *
	 * @type {object}
	 * @memberof PaymentBatchConstants
	 */
	public static readonly paymentBatchStatus:
	{
		approved: string;
		pending: string;
		rejected: string;
	} = {
		approved: 'Approved',
		pending: 'Pending',
		rejected: 'Rejected'
	};
}