/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */

import {
	AccessDeniedComponent
} from '@appComponents/access-denied/access-denied.component';
import {
	AddExposureClaimComponent
} from '@claims/components/wizard-steps/claim/add-exposure-claim/add-exposure-claim.component';
import {
	AdjustReservesClaimComponent
} from '@claims/components/wizard-steps/claim/adjust-reserves-claim/adjust-reserves-claim.component';
import {
	AppAuthGuard
} from '@shared/guards/app-auth.guard';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ClaimsComponent
} from '@claims/claims.component';
import {
	CommonModule
} from '@angular/common';
import {
	CreateClaimComponent
} from '@claims/components/wizard-steps/claim/create-claim/create-claim.component';
import {
	EntityComponent
} from '@entity/entity.component';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	EntitySearchComponent
} from '@entity/components/entity-search/entity-search.component';
import {
	GenericBasePageComponent
} from '@appComponents/generic-base-page/generic-base-page.component';
import {
	GenericDashboardComponent
} from '@appComponents/generic-dashboard/generic-dashboard.component';
import {
	InsuranceModule
} from '@insurance/insurance.module';
import {
	ManageExposurePartiesClaimComponent
} from '@claims/components/wizard-steps/claim/manage-exposure-parties-claim/manage-exposure-parties-claim.component';
import {
	ModuleGuard
} from '@shared/guards/module.guard';
import {
	NgModule
} from '@angular/core';
import {
	RouterModule
} from '@angular/router';
import {
	SharedModule
} from '@shared/shared.module';
import {
	VerifyCoverageClaimComponent
} from '@claims/components/wizard-steps/claim/verify-coverage-claim/verify-coverage-claim.component';

/* eslint-disable max-len */

@NgModule({
	declarations: [
		AddExposureClaimComponent,
		AdjustReservesClaimComponent,
		ClaimsComponent,
		CreateClaimComponent,
		ManageExposurePartiesClaimComponent,
		VerifyCoverageClaimComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		InsuranceModule,
		RouterModule.forChild([
			{
				path: 'claim',
				component: ClaimsComponent,
				canActivate: [
					AppAuthGuard,
					ModuleGuard
				],
				data:
				{
					roles: [
						AppConstants.securityGroups.claimModuleAccess
					]
				},
				children: [
					{
						path: 'accessDenied',
						component: AccessDeniedComponent
					},
					{
						path: 'dashboard',
						component: GenericDashboardComponent
					},
					{
						path: 'dashboard/:dashboardType',
						component: GenericDashboardComponent
					},
					{
						path: 'display',
						children: [
							{
								path: ':displayComponent/view',
								component: GenericBasePageComponent
							},
							{
								path: ':displayComponent/edit',
								component: GenericBasePageComponent,
								canActivate: [
									AppAuthGuard
								],
								data:
								{
									roles: [
										AppConstants.securityGroups.claimModuleAccess
									]
								}
							}
						]
					},
					{
						path: 'entities',
						component: EntityComponent,
						children: [
							{
								path: ':entityType/view/:id',
								component: EntityInstanceComponent
							},
							{
								path: ':entityType/edit/:id',
								component: EntityInstanceComponent
							},
							{
								path: 'display',
								children: [
									{
										path: ':displayComponent/create',
										component: GenericBasePageComponent
									}
								]
							},
							{
								path: '',
								component: EntitySearchComponent
							}
						]
					},
					{
						path: '',
						redirectTo: 'dashboard',
						pathMatch: 'full'
					},
					{
						path: '**',
						redirectTo: 'dashboard',
						pathMatch: 'full'
					}
				]
			}])
	],
	exports: [
		AddExposureClaimComponent,
		AdjustReservesClaimComponent,
		CreateClaimComponent,
		ManageExposurePartiesClaimComponent,
		VerifyCoverageClaimComponent
	],
	providers: [
		AddExposureClaimComponent,
		AdjustReservesClaimComponent,
		CreateClaimComponent,
		ManageExposurePartiesClaimComponent,
		VerifyCoverageClaimComponent
	]
})

/**
 * A module used to hold claims route specific logic and display resources.
 *
 * @export
 * @class ClaimsModule
 */
export class ClaimsModule
{
}