/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import {
	debounceTime,
	distinctUntilChanged,
	startWith,
	Subject
} from 'rxjs';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	Tooltip
} from 'primeng/tooltip';
import {
	WindowEventConstants
} from '@shared/constants/window-event.constants';

/* eslint-enable max-len */

@Component({
	selector: 'app-common-icon-tooltip',
	templateUrl: './common-icon-tooltip.component.html'
})

/**
 * A component representing an instance of the object list component.
 *
 * @export
 * @class CommonIconTooltipComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
export class CommonIconTooltipComponent
implements OnInit, OnDestroy
{
	/**
	 * Initializes a new instance of the object list component.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * Service utilized to catch any site layout change and
	 * site layout information
	 * @param {ChangeDetectorRef} changeDetectorRef
	 * The change detector reference for this component.
	 * @param {ResolverService} resolver
	 * The resolver service.
	 * @memberof CommonIconTooltipComponent
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService,
		public changeDetectorRef: ChangeDetectorRef,
		public resolver: ResolverService)
	{
	}

	/**
	 * Gets or sets the table definitions data required to
	 * generate the table display within it's own functionality.
	 *
	 * @type {string}
	 * @memberof CommonIconTooltipComponent
	 */
	@Input() public content: string;

	/**
	 * Gets or sets the field context.
	 *
	 * @type {FormlyFieldConfig}
	 * @memberof CommonIconTooltipComponent
	 */
	@Input() public field?: FormlyFieldConfig;

	/**
	 * Gets or sets the external link.
	 *
	 * @type {string}
	 * @memberof CommonIconTooltipComponent
	 */
	@Input() public externalLink?: string;

	/**
	 * Gets or sets the tooltip escape.
	 * Defaults to true.
	 *
	 * @type {boolean}
	 * @memberof CommonIconTooltipComponent
	 */
	@Input() public escape?: boolean = true;

	/**
	 * Gets or sets the tooltip icon class.
	 * Defaults to 'fa fa-info-circle theme-color label-info-icon text-link'.
	 *
	 * @type {string}
	 * @memberof CommonIconTooltipComponent
	 */
	@Input() public iconClass?: string =
		'fa fa-info-circle theme-color label-info-icon text-link';

	/**
	 * Gets or sets the tooltip position.
	 * Defaults to 'top'.
	 *
	 * @type {string}
	 * @memberof CommonIconTooltipComponent
	 */
	@Input() public position?: string = 'top';

	/**
	 * Gets or sets the tooltip style class.
	 * Defaults to 'label-tooltip'.
	 *
	 * @type {string}
	 * @memberof CommonIconTooltipComponent
	 */
	@Input() public styleClass?: string = 'label-tooltip';

	/**
	 * Gets or sets the thruthy that allows tooltip activation.
	 * Defaults to true.
	 *
	 * @type {boolean}
	 * @memberof CommonIconTooltipComponent
	 */
	@Input() public allowTooltipActivation?: boolean = true;

	/**
	 * Sets the event emitter for the icon click.
	 *
	 * @type {EventEmitter<any>}
	 * @memberof CommonIconTooltipComponent
	 */
	@Output() public iconClick?: EventEmitter<any> =
		new EventEmitter();

	/**
	 * Gets or sets the tooltip element reference.
	 *
	 * @type {Tooltip}
	 * @memberof CommonIconTooltipComponent
	 */
	@ViewChild(Tooltip)
	public tooltip: Tooltip;

	/**
	 * Gets or sets if the tooltip Id.
	 *
	 * @type {number}
	 * @memberof CommonIconTooltipComponent
	 */
	public tooltipId: number;

	/**
	 * Gets or sets if the tooltip is active state.
	 * Defaults to false.
	 *
	 * @type {boolean}
	 * @memberof CommonIconTooltipComponent
	 */
	public activeTooltip: boolean = false;

	/**
	 * Gets or sets when the tooltip redraws.
	 * Defaults to false.
	 *
	 * @type {boolean}
	 * @memberof CommonIconTooltipComponent
	 */
	public tooltipRedraw: boolean = false;

	/**
	 * Gets or sets the observer tooltip view changes.
	 *
	 * @type {Subject<void>}
	 * @memberof CommonIconTooltipComponent
	 */
	public tooltipViewChangedSubject:
		Subject<any> = new Subject<any>();

	/**
	 * Handles the site layout change event which is called
	 * when the site layout service has altered it's variables
	 * or loading has completed.
	 *
	 * @memberof CommonTableComponent
	 */
	@HostListener(
		AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		this.deactivateTooltip();
	}

	/**
	 * Handles the scroll event sent from the window.
	 * This will hide any currently displayed tooltips.
	 *
	 * @memberof CustomFieldWrapperComponent
	 */
	@HostListener(
		WindowEventConstants.scrollEvent)
	public scroll(): void
	{
		this.deactivateTooltip();
	}

	/**
	 * On initialization event.
	 * Checks for the existence of a required rule validator on this field if
	 * it is set via rules.
	 *
	 * @memberof CommonIconTooltipComponent
	 */
	public ngOnInit(): void
	{
		this.setTooltipId();
		this.tooltipViewChangedSubject.pipe(
			startWith(null),
			debounceTime(AppConstants.time.twentyFiveMilliseconds),
			distinctUntilChanged())
			.subscribe(
				(changedTooltip: any) =>
				{
					if (!AnyHelper.isNull(changedTooltip)
					&& (changedTooltip.deactivateTooltip === true
						|| (!AnyHelper.isNull(this.tooltip)
							&& this.tooltip.el.nativeElement.id
								=== changedTooltip.id)))
					{
						if (this.activeTooltip === false
							&& this.allowTooltipActivation === true)
						{
							if (this.tooltip.active === false)
							{
								this.tooltip.activate();
							}
							this.tooltip.unbindEvents();
						}
						else
						{
							this.redrawTooltip();
						}

						this.activeTooltip = !this.activeTooltip;

						if (!AnyHelper.isNull(this.iconClick)
							&& !AnyHelper.isNullOrEmpty(
								changedTooltip.clickEvent))
						{
							this.iconClick.next(changedTooltip.clickEvent);
						}

						this.changeDetectorRef.detectChanges();
						this.updateFieldValueAndValidity();
					}
				});
	}

	/**
	 * Handles the on destroy event.
	 * This will complete any current observables.
	 *
	 * @memberof CustomFieldWrapperComponent
	 */
	public ngOnDestroy(): void
	{
		this.tooltipViewChangedSubject.complete();
	}

	/**
	 * This method will deactivate the tooltip when is active.
	 * This is used by the clickOutside directive and
	 * the HostListener siteLayoutChangedEvent.
	 *
	 * @memberof CommonIconTooltipComponent
	 */
	public deactivateTooltip(): void
	{
		if (this.activeTooltip === false
			|| this.allowTooltipActivation !== true)
		{
			return;
		}

		this.tooltipViewChangedSubject.next(
			{
				id: null,
				deactivateTooltip: true
			});
	}

	/**
	 * This method will remove the auto focus click event attached to
	 * primeNg tooltips, and handle the click event toogling.
	 *
	 * @param {MouseEvent} event
	 * The click event to be captured and halted.
	 * @memberof CommonIconTooltipComponent
	 */
	public handleTooltipClickEvent(
		event: MouseEvent): void
	{
		this.toogleTooltipDisplay(event);
		event.preventDefault();
		event.stopImmediatePropagation();
	}

	/**
	 * This will handle the toogle tooltip event.
	 *
	 * @memberof CommonIconTooltipComponent
	 */
	public toogleTooltipDisplay(event: MouseEvent): void
	{
		this.tooltipViewChangedSubject.next(
			{
				id: (<Element>event.target).id,
				deactivateTooltip: false,
				clickEvent: event
			});
	}

	/**
	 * This will redirect and load in a new tab the external
	 * link specified on the template options externalLink property.
	 *
	 * @memberof CommonIconTooltipComponent
	 */
	public accessExternalLink(): void
	{
		const externalLink =
			window.open(
				this.externalLink,
				AppConstants.windowTargets.blank);
		externalLink?.focus();
	}

	/**
	 * This method will handle the tooltip redraw action.
	 *
	 * @memberof CommonIconTooltipComponent
	 */
	private redrawTooltip(): void
	{
		this.tooltipRedraw = true;
		this.changeDetectorRef.detectChanges();
		this.updateFieldValueAndValidity();
		setTimeout(
			() =>
			{
				this.tooltipRedraw = false;
				this.changeDetectorRef.detectChanges();
				this.updateFieldValueAndValidity();
			});
	}

	/**
	 * Updates form control field value and validity
	 * when field form control is defined.
	 *
	 * @memberof CommonIconTooltipComponent
	 */
	private updateFieldValueAndValidity(): void
	{
		if (AnyHelper.isNull(this.field?.formControl))
		{
			return;
		}

		this.field.formControl.updateValueAndValidity();
	}

	/**
	 * Sets the tooltip id.
	 *
	 * @memberof CommonIconTooltipComponent
	 */
	private setTooltipId(): void
	{
		this.tooltipId = !AnyHelper.isNullOrEmpty(this.field?.id)
			? parseInt(
				this.field.id,
				AppConstants.parseRadix)
			: 0;
	}
}