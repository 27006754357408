/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';

/**
 * A constant library used to defined commonly used formly fields.
 *
 * @export
 * @class CommonFormlyFieldConstants
 */
export class CommonFormlyFieldConstants
{
	/**
	 * Gets the common public field config.
	 *
	 * @type {FormlyFieldConfig}
	 * @memberof CommonFormlyFieldConstants
	 */
	public static readonly publicField: FormlyFieldConfig =
		<FormlyFieldConfig>
		{
			key: 'data.public',
			type: FormlyConstants.customControls.customInputSwitch,
			wrappers: [
				FormlyConstants.customControls.customFieldWrapper
			],
			templateOptions: {
				label: 'Publish',
				tooltip: 'If Public is enabled, then that means'
					+ ' the report is published and accessible to'
					+ ' the specified Report Viewers. If Public is'
					+ ' disabled, then only the Report Owner\'s will'
					+ ' be able to see the new report.',
				default: true
			}
		};

	/**
	 * Gets the common public field config.
	 *
	 * @type {FormlyFieldConfig}
	 * @memberof CommonFormlyFieldConstants
	 */
	public static readonly ownershipSecurityGroupField: FormlyFieldConfig =
		<FormlyFieldConfig>
		{
			key: 'data.ownershipSecurityGroupId',
			type: FormlyConstants.customControls.customSelect,
			wrappers: [
				FormlyConstants.customControls.customFieldWrapper
			],
			templateOptions: {
				label: 'Report Owner(s)*',
				tooltip: 'Set Additional Report Owner\'s'
					+ ' that will be allowed to edit'
					+ ' the report.',
				placeholder: 'Select an ownership group',
				showClear: true,
				required: true
			}
		};
}