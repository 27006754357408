/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	Component,
	OnInit
} from '@angular/core';
import {
	DrawerListDirective
} from '@shared/directives/drawer-list-directive';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IOwnershipGuardComponent
} from '@shared/interfaces/application-objects/ownership-guard-component';
import {
	Location
} from '@angular/common';
import {
	SessionService
} from '@shared/services/session.service';

@Component({
	selector: 'app-notes',
	templateUrl: './notes.component.html',
	styleUrls: [
		'./notes.component.scss'
	]
})

/**
 * A component representing context level notes.
 *
 * @export
 * @class NotesComponent
 * @extends {BaseDrawerListDirective<IEntityInstance>}
 * @implements {OnInit}
 * @implements {IDynamicComponent<Component, any>}
 * @implements {IOwnershipGuardComponent}
 */
export class NotesComponent
	extends DrawerListDirective<IEntityInstance>
	implements OnInit, IDynamicComponent<Component, any>,
		IOwnershipGuardComponent
{
	/**
	 * Initializes a new instance of the rules component.
	 *
	 * @param {EntityService} entityService
	 * The entity service.
	 * @param {SessionService} sessionService
	 * The session service.
	 * @param {Location} location
	 * The location object.
	 * @memberof NotesComponent
	 */
	public constructor(
		public entityService: EntityService,
		public sessionService: SessionService,
		public location: Location)
	{
		super();
	}

	/**
	 * Gets the entity type name to filter on for this drawer component.
	 *
	 * @type {string}
	 * @memberof NotesComponent
	 */
	private readonly entityTypeName: string = 'Note';

	/**
	 * Handles the on initialization event.
	 * This method will set configurable properties used in the drawer list
	 * directive and this component's view.
	 *
	 * @async
	 * @memberof NotesComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		if (!await this.isPageOwnershipAllowed())
		{
			this.accessDeniedUrl = this.location.path();
			this.sessionIdentifier = this.sessionService.sessionId;
			this.resources =
				[
					'Note.*'
				];
			this.clientMessage =
				'Access is required to at least one note '
					+ 'entity type and version.';
			this.isOwnershipAllowed = false;
		}
	}

	/**
	 * Implements the ownership guard interface.
	 * This will calculate drawer ownership permissions.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * A value signifying whether or not access is allowed to this drawer.
	 * @memberof NotesComponent
	 */
	public async isPageOwnershipAllowed(): Promise<boolean>
	{
		await this.entityService.setStoredVariables();

		const entityType: IEntityType =
			this.entityService
				.entityTypes
				.find(
					(type: IEntityType) =>
						type.name === this.entityTypeName);

		if (AnyHelper.isNull(entityType))
		{
			return false;
		}

		return this.entityService.verifyEntityTypeAccess(
			entityType);
	}
}