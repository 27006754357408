<app-base-page pageTitle="Api Module Test Builder"
	[pageContext]="pageContext"
	utilityMenuOperationGroupName="">

	<div #AdditionalHeaderContent
		additional-header-content
		class="test-error-button-panel {{siteLayoutService.contentCssClass}}">
		<p-panel>
			<p-header>
				<span class="panel-sub-title">
					Test Helpers
				</span>
			</p-header>

			<button
				pButton
				role="button"
				label="Throw Angular Error"
				class="p-button-secondary"
				(click)="throwAngularError()">
			</button>
			<button
				pButton
				role="button"
				label="Throw HttpResponse Error"
				class="p-button-secondary"
				(click)="throwHttpResponseError()">
			</button>
			<button
				pButton
				role="button"
				label="Throw Api Error"
				class="p-button-secondary"
				(click)="throwApiError()">
			</button>
		</p-panel>
	</div>

	<form [formGroup]="servicesForm"
		class="test-component-form"
		(ngSubmit)="onSubmit()">
		<div class="ui-g test-builder-container">
			<div class="ui-g-12 ui-md-4">
				<label for="service-dropdown">API service</label><br>
				<p-dropdown id="float-input"
							formControlName="apiService"
							[options]="servicesArray"
							optionLabel="name"
							[filter]="true"
							(onChange)=onSelectService($event.value)></p-dropdown>
				<br><br>
				<div *ngIf="showTypeGroup">
					<label for="type-group">Type Group</label><br>
					<input pInputText
							id="type-group"
							type="text"
							formControlName="typeGroup" />
				</div>
			</div>
			<div class="ui-g-12 ui-md-4">
				<label for="method-dropdown">API method</label><br>
				<p-dropdown id="method-dropdown"
							formControlName="apiMethod"
							[options]="methodsArray"
							optionLabel="name"
							[filter]="true"
							(onChange)=onSelectMethod()></p-dropdown>
			</div>
			<div class="ui-g-12 ui-md-4 button-label">
				<p>
					Parameters
					<button pButton
						class="p-button-secondary plus-button"
						type="button"
						icon="fa fa-plus"
						(click)="addParam()">
					</button>
				</p>
				<div formArrayName="params" *ngFor="let param of servicesForm.get('params')['controls']; let i=index">
					<div class="p-inputgroup" [formGroupName]="i">
						<span class="p-inputgroup-addon"
							pTooltip="Is param JSON?"
							tooltipPosition="top">
							<p-checkbox formControlName="isParamObject"
								[binary]="true">
							</p-checkbox>
						</span>
						<input pInputText
							type="text"
							formControlName="paramValue"
							pTooltip="Param Value"
							tooltipPosition="top" />
						<button pButton
								class="p-button-danger"
								icon="pi pi-times"
								type="button"
								(click)="removeParam(i)"></button>
					</div>
					<br>
				</div>
			</div>
			<div class="ui-g-12 ui-md-12">
				<button pButton
					type="submit"
					label="Test"
					[disabled]="!servicesForm.valid"></button>
			</div>
		</div>
	</form>
	<div class="ui-g" *ngIf="results">
		<div class="ui-g-12 ui-md-12">
			<p>Results: </p>
			<textarea pInputTextarea
					[rows]="12"
					[cols]="80"
					autoResize="autoResize">{{results | json}}</textarea>
		</div>
	</div>
</app-base-page>