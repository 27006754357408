/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	DisplayComponentDefinition
} from '@shared/implementations/display-components/display-component-definition';
import {
	IDisplayComponentInstance
} from '@shared/interfaces/display-components/display-component-instance.interface';

/* eslint-enable max-len */

/**
 * A class representing the available methods (business logic) for a
 * display component instance.
 *
 * @export
 * @class DisplayComponentInstance
 * @implements {DisplayComponentInstance}
 */
export class DisplayComponentInstance implements IDisplayComponentInstance
{
	/**
	 * Creates an instance of a display component instance.
	 *
	 * @param {DisplayComponentInstance} iDisplayComponentInstance
	 * The display component instance interface to create this new
	 * object from.
	 * @memberof DisplayComponentInstance
	 */
	public constructor(
		public iDisplayComponentInstance: IDisplayComponentInstance)
	{
		Object.assign(this, iDisplayComponentInstance);
	}

	/**
	 * Gets or sets the display component definition id.
	 *
	 * @type {number}
	 * @memberof DisplayComponentInstance
	 */
	public definitionId: number;

	/**
	 * Gets or sets the description.
	 *
	 * @type {string}
	 * @memberof DisplayComponentInstance
	 */
	public description: string;

	/**
	 * Gets or sets array display mode and the associated instances.
	 *
	 * @type {DisplayComponentInstance[]}
	 * @memberof DisplayComponentInstance
	 */
	public displayArray: DisplayComponentInstance[] = [];

	/**
	 * Gets or sets the display component definition.
	 *
	 * @type {DisplayComponentDefinition}
	 * @memberof DisplayComponentInstance
	 */
	public displayComponentDefinition: DisplayComponentDefinition;

	/**
	 * Gets or sets the id.
	 *
	 * @type {number}
	 * @memberof DisplayComponentInstance
	 */
	public id: number;

	/**
	 * Gets or sets the json data.
	 * This is an implemented instance of the configuration.
	 *
	 * @type {string}
	 * @memberof DisplayComponentInstance
	 */
	public jsonData: string;

	/**
	 * Gets or sets the name.
	 *
	 * @type {string}
	 * @memberof DisplayComponentInstance
	 */
	public name: string;

	/**
	 * Gets or sets the order.
	 *
	 * @type {number}
	 * @memberof DisplayComponentInstance
	 */
	public order: number;

	/**
	 * Gets or sets the display component type id.
	 *
	 * @type {number}
	 * @memberof DisplayComponentInstance
	 */
	public typeId: number;

	/**
	 * Gets or sets the visible value that is calculated when running the
	 * raw display promise of this display component instance.
	 *
	 * @type {boolean}
	 * @memberof DisplayComponentInstance
	 */
	public visible: boolean;

	/**
	* Gets or sets the ownership public or read allowed value.
	*
	* @type {boolean}
	* @memberof DisplayComponentInstance
	*/
	public public: boolean;

	/**
	* Gets or sets the ownership security group or alter allowed value.
	*
	* @type {number}
	* @memberof DisplayComponentInstance
	*/
	public ownershipSecurityGroupId: number;

	/**
	* Gets or sets the created by id. This user will have read and alter
	* permissions.
	*
	* @type {number}
	* @memberof DisplayComponentInstance
	*/
	public createdById: number;

	/**
	 * Returns the initial parameter json data object.
	 *
	 * @type {any}
	 * @memberof DisplayComponentInstance
	 */
	public get jsonInitialParameters(): any
	{
		return this.parsedJson[
			this.initialParametersDataKey];
	}

	/**
	 * Returns the interpolation json data object.
	 *
	 * @type {any}
	 * @memberof DisplayComponentInstance
	 */
	public get jsonInterpolationData(): any
	{
		return this.parsedJson[
			this.interpolationDataKey];
	}

	/**
	 * Returns the string based boolean display data promise available in
	 * the interpolation data of this instance.
	 *
	 * @type {string}
	 * @memberof DisplayComponentInstance
	 */
	public get rawDisplayPromise(): string
	{
		return this.jsonInterpolationData.display;
	}

	/**
	 * Returns the parameter definition json data object.
	 *
	 * @type {any}
	 * @memberof DisplayComponentInstance
	 */
	public get jsonParameterDefinition(): any
	{
		return this.parsedJson[
			this.parameterDefinitiontDataKey];
	}

	/**
	 * Returns the parameter layout json data object.
	 *
	 * @type {any}
	 * @memberof DisplayComponentInstance
	 */
	public get jsonParameterLayout(): any
	{
		return this.parsedJson[
			this.parameterLayoutDataKey];
	}

	/**
	 * Returns the parsed json object from the display component
	 * instance json data.
	 *
	 * @type {any}
	 * The json parsed json data object.
	 * @memberof DisplayComponentInstance
	 */
	public get parsedJson(): any
	{
		return JSON.parse(this.jsonData);
	}

	/**
	 * Returns the display component instance title for a data parameter
	 * definition.
	 *
	 * @type {string}
	 * @memberof DisplayComponentInstance
	 */
	public get title(): string
	{
		return AnyHelper.isNull(this.jsonParameterDefinition)
			|| AnyHelper.isNullOrWhitespace(
				this.jsonParameterDefinition[
					this.titleDataKey])
			? AppConstants.empty
			: this.jsonParameterDefinition[
				this.titleDataKey
			];
	}

	/**
	 * Gets the key used to find the initial parameter data
	 * from the display component instance json.
	 *
	 * @type {string}
	 * @memberof DisplayComponentInstance
	 */
	private readonly initialParametersDataKey: string = 'initialParameters';

	/**
	 * Gets the key used to find the interpolation data
	 * from the display component instance json.
	 *
	 * @type {string}
	 * @memberof DisplayComponentInstance
	 */
	private readonly interpolationDataKey: string = 'interpolationData';

	/**
	 * Gets the key used to find the parameter definition
	 * from the display component instance json.
	 *
	 * @type {string}
	 * @memberof DisplayComponentInstance
	 */
	private readonly parameterDefinitiontDataKey: string =
		'parameterDefinition';

	/**
	 * Gets the key used to find the parameter layout
	 * from the display component instance json.
	 *
	 * @type {string}
	 * @memberof DisplayComponentInstance
	 */
	private readonly parameterLayoutDataKey: string = 'parameterLayout';

	/**
	 * Gets the key used to find the title held in the parameter
	 * definition.
	 *
	 * @type {string}
	 * @memberof DisplayComponentInstance
	 */
	private readonly titleDataKey: string = 'title';
}