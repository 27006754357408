/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BaseEntityApiService
} from '@api/services/base/base-entity.api.service';
import {
	CacheService
} from '@shared/services/cache.service';
import {
	HttpClient
} from '@angular/common/http';
import {
	Inject,
	Injectable
} from '@angular/core';
import {
	IOperationDefinitionDto
} from '@api/interfaces/operations/operation-definition.dto.interface';
import {
	lastValueFrom
} from 'rxjs';

/**
 * A class representing the logic and services of the operation
 * definition controller.
 *
 * @export
 * @class OperationDefinitionApiService
 * @extends {BaseEntityApiService<IOperationDefinitionDto>}
 */
@Injectable()
export class OperationDefinitionApiService
	extends BaseEntityApiService<IOperationDefinitionDto>
{
	/**
	 * Creates an instance of an OperationDefinitionApiService.
	 *
	 * @param {HttpClient} HttpClient
	 * The injected http client to use in the base api service.
	 * @param {CacheService} cache
	 * The injected cache service to use in the base api service.
	 * @memberof OperationDefinitionApiService
	 */
	public constructor(
		@Inject(HttpClient) http: HttpClient,
		@Inject(CacheService) cache: CacheService)
	{
		super();
		this.httpClient = http;
		this.cacheService = cache;
		this.endpoint =
			AppConstants.apiControllers.operationDefinitions;
	}

	/**
	 * Gets the associated security groups of the operation definition
	 * with the specified identifier.
	 *
	 * @param {number} id
	 * The identifier of the operation definition to get the security
	 * groups of.
	 * @returns {Promise<ISecurityGroupDto[]>}
	 * The security groups found via this get method.
	 * @memberof OperationDefinitionApiService
	 */
	public async getSecurityGroups(
		id: number): Promise<number[]>
	{
		const url = this.getNestedUrl(
			id,
			AppConstants.nestedRouteTypes.securityGroups);

		return lastValueFrom(
			this.httpClient.get<number[]>(url));
	}

	/**
	 * Creates a security group to operation definition association
	 * with the specified information, this allows access to
	 * the operation definition for the specified security group.
	 *
	 * @param {number} id
	 * The identifier of the operation definition to set the security
	 * group assocation.
	 * @param {number} securityGroupId
	 * The identifier of the security group to create the operation
	 * definition assocation to.
	 * @returns {Promise<number>}
	 * The operation definition identifier holding the new association.
	 * @memberof OperationDefinitionApiService
	 */
	public async createSecurityGroupMap(
		id: number,
		securityGroupId: number): Promise<number>
	{
		const nestedUrl: string =
			this.getNestedUrl(
				id,
				AppConstants.nestedRouteTypes.securityGroups);
		const url =
			this.formUrlParam(
				nestedUrl,
				{
					securityGroupId: securityGroupId
				});
		await this.resetAssociatedCache(nestedUrl);

		const response = await lastValueFrom(
			this.httpClient.post(
				url,
				{},
				{
					observe: 'response',
					headers: this.queryOnlyGetHeaders
				}));

		const createdItemId: number =
			this.getCreatedAtRouteIdentifier(
				response.headers.get('location'));

		return Promise.resolve(createdItemId);
	}

	/**
	 * deletes a security group that allows access to the operation
	 * definition.
	 *
	 * @param {number} id
	 * The identifier of the operation definition to delete the security
	 * group assocation for.
	 * @param {number} securityGroupId
	 * The identifier of the security group to delete the operation
	 * definition assocation to.
	 * @returns {Promise<object>}
	 * An observable of the delete no-content response.
	 * @memberof OperationDefinitionApiService
	 */
	public async deleteSecurityGroupMap(
		id: number,
		securityGroupId: number): Promise<object>
	{
		const nestedUrl: string =
			this.getNestedUrl(
				id,
				AppConstants.nestedRouteTypes.securityGroups);
		const url =
			`${nestedUrl}/${securityGroupId}`;
		await this.resetAssociatedCache(nestedUrl);

		return lastValueFrom(
			this.httpClient.delete(
				url));
	}
}