/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonFormlyFieldConstants
} from '@shared/constants/common-formly-field-constants';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	Component,
	OnInit
} from '@angular/core';
import {
	DisplayComponentDefinitionApiService
} from '@api/services/display-components/display-component-definition.api.service';
import {
	DisplayComponentInstanceApiService
} from '@api/services/display-components/display-component-instance.api.service';
import {
	DisplayComponentTypeApiService
} from '@api/services/display-components/display-component-type.api.service';
import {
	DisplayManagementExpandComponent
} from './display-management-expand/display-management-expand.component';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDisplayComponentDefinition
} from '@shared/interfaces/display-components/display-component-definition.interface';
import {
	IDisplayComponentInstance
} from '@shared/interfaces/display-components/display-component-instance.interface';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	OptionsFactory
} from '@shared/factories/options-factory';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	TableHelper
} from '@shared/helpers/table.helper';

/* eslint-enable max-len */

@Component({
	selector: 'app-display-management-component',
	templateUrl: './display-management.component.html',
	styleUrls: [
		'./display-management.component.scss'
	]
})

/**
 * A component representing an instance of the workflow engine action
 * execution history component.
 *
 * @export
 * @class DisplayManagementComponent
 * @extends {CommonTablePageDirective}
 * @implements {OnInit}
 */
export class DisplayManagementComponent
	extends CommonTablePageDirective
	implements OnInit
{
	/**
	 * Initializes a new instance of the DisplayManagementComponent class.
	 *
	 * @param {DisplayComponentDefinitionApiService}
	 * displayComponentDefinitionApiService
	 * The api service used to load display component definition data.
	 * @param {DisplayComponentInstanceApiService}
	 * displayComponentInstanceApiService
	 * The api service used to load display component instance data.
	 * @param {DisplayComponentTypeApiService} displayComponentTypeApiService
	 * The api service used to load display component type data.
	 * @param {OptionsFactory} optionsFactory
	 * The options factory used for common dropdown options.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {SecurityGroupApiService} securityGroupApiService
	 * The api service used to get security group data.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof DisplayManagementComponent
	 */
	public constructor(
		public displayComponentDefinitionApiService:
			DisplayComponentDefinitionApiService,
		public displayComponentInstanceApiService:
			DisplayComponentInstanceApiService,
		public displayComponentTypeApiService: DisplayComponentTypeApiService,
		public optionsFactory: OptionsFactory,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the table definitions for the common table view.
	 *
	 * @type {ICommonTable}
	 * @memberof DisplayManagementComponent
	 */
	public tableDefinitions: ICommonTable;

	/**
	 * Sets the data chunk limit.
	 *
	 * @type {number}
	 * @memberof DisplayManagementComponent
	 */
	public dataChunkLimit: number = 100;

	/**
	 * Gets or sets the operation query params.
	 *
	 * @type {IObjectSearch}
	 * @memberof DisplayManagementComponent
	 */
	public operationQueryParams: IObjectSearch = {
		filter: AppConstants.empty,
		orderBy: `Id ${AppConstants.sortDirections.ascending}`,
		offset: null,
		limit: this.dataChunkLimit
	};

	/**
	 * Gets or sets the display component type options.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof DisplayManagementComponent
	 */
	public displayComponentTypeOptions: IDropdownOption[];

	/**
	 * Gets or sets the display component definition options.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof DisplayManagementComponent
	 */
	public displayComponentDefinitionOptions: IDropdownOption[];

	/**
	 * Gets or sets the security group options.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof DisplayManagementComponent
	 */
	public securityGroupOptions: IDropdownOption[];

	/**
	 * Gets or sets the display management context.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof DisplayManagementComponent
	 */
	public displayManagementContext: IDynamicComponentContext<Component, any>;

	/**
	 * Initializes the display management component by seting up page variables
	 * and table definitions.
	 *
	 * @async
	 * @memberof DisplayManagementComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		await this.setupPageVariables();
		await this.setupTableDefinitions();
	}

	/**
	 * Sets up the page variables.
	 *
	 * @async
	 * @memberof DisplayManagementComponent
	 */
	public async setupPageVariables(): Promise<void>
	{
		this.displayComponentTypeOptions =
			this.getDropdownOptions(
				await this.displayComponentTypeApiService
					.query(
						AppConstants.empty,
						AppConstants.empty),
				'name',
				'id');

		this.displayComponentDefinitionOptions =
			this.getDropdownOptions(
				await this.displayComponentDefinitionApiService
					.query(
						AppConstants.empty,
						AppConstants.empty),
				'componentName',
				'id');

		this.securityGroupOptions =
			await this.optionsFactory.getSecurityGroupOptions();

		let displayOrder: number = 1;
		this.availableColumns =
			[
				{
					dataKey: 'id',
					columnHeader: 'Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'typeId',
					columnHeader: 'Type Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'definitionId',
					columnHeader: 'Definition Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'description',
					columnHeader: 'Description',
					displayOrder: displayOrder
				}
			];
		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Sets up the table definitions for a standard table
	 *
	 * @async
	 * @memberof DisplayManagementComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		this.displayManagementContext =
			<IDynamicComponentContext<Component, any>>
			{
				source: this
			};

		this.tableDefinitions = {
			actions: {
				create: {
					component: DisplayManagementExpandComponent,
					customContext: this.displayManagementContext,
					items: [
						{
							label: 'Create',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: () => this.createDisplayInstance()
						}]
				},
				view: {
					additionalRowData: async() => {
						const additionalData: any =
							await this.getAdditionalRowData();

						return additionalData;
					},
					customContext: this.displayManagementContext,
					layout: [
						{
							key: 'data.typeId',
							type: FormlyConstants.customControls.customSelect,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Type',
								disabled: true,
								options: this.displayComponentTypeOptions
							}
						},
						{
							key: 'data.definitionId',
							type: FormlyConstants.customControls.customSelect,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Definition',
								disabled: true,
								options: this.displayComponentDefinitionOptions
							}
						},
						{
							key: 'data.name',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Name',
								disabled: true
							}
						},
						{
							key: 'data.additionalRowData.securityGroups',
							type: FormlyConstants.customControls.customSelect,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Security Groups',
								disabled: true,
								options: this.securityGroupOptions
							}
						},
						{
							...CommonFormlyFieldConstants
								.publicField,
							templateOptions: {
								...CommonFormlyFieldConstants
									.publicField
									.templateOptions,
								disabled: true
							}
						},
						{
							...CommonFormlyFieldConstants
								.ownershipSecurityGroupField,
							templateOptions: {
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField
									.templateOptions,
								options: this.securityGroupOptions,
								disabled: true
							}
						},
						{
							key: 'data.description',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Description',
								disabled: true,
							}
						},
						{
							type: FormlyConstants.customControls
								.customTextDisplay,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								title: 'Definition',
								useCodeBlock: true,
								usePanelDisplay: true,
								expanded: false,
								codeBlockType:
									AppConstants.markdownLanguages.json,
								content: AppConstants.empty
							},
							expressionProperties: {
								'templateOptions.content':
									// eslint-disable-next-line max-len
									'`${model.data.additionalRowData.definitionJsonData}`'
							}
						},
						{
							type: FormlyConstants.customControls
								.customTextDisplay,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Attributes',
								useCodeBlock: true,
								usePanelDisplay: false,
								codeBlockType: AppConstants.markdownLanguages
									.json,
								content: AppConstants.empty
							},
							expressionProperties: {
								'templateOptions.content':
									'`${model.data.jsonData}`'
							}
						}
					],
					items: []
				},
				update: {
					additionalRowData: async() => {
						const additionalData: any =
							await this.getAdditionalRowData();

						return additionalData;
					},
					component: DisplayManagementExpandComponent,
					customContext: this.displayManagementContext,
					items: [
						{
							label: 'Save',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: () => this.updateDisplayDefinition()
						}]
				},
				delete: {
					items: [
						{
							label: 'Confirm',
							styleClass: AppConstants.cssClasses.pButtonDanger,
							disabled: false,
							command: () => this.deleteDisplayInstance()
						}],
					deleteStatement: () => this.getDeleteStatement(),
				}
			},
			tableTitle: 'Management',
			objectSearch: {
				filter: AppConstants.empty,
				orderBy: `Id ${AppConstants.sortDirections.descending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: this.tableRowCount
			},
			apiPromise: async (objectSearch: IObjectSearch) =>
			{
				const displayComponentInstances: IDisplayComponentInstance[] =
					<IDisplayComponentInstance[]>
					await this.displayComponentInstanceApiService
						.query(
							objectSearch.filter,
							objectSearch.orderBy,
							objectSearch.offset,
							objectSearch.limit);

				const dataReturn = [];

				displayComponentInstances
					.forEach((displayComponentInstance:
						IDisplayComponentInstance) =>
					{
						dataReturn.push(
							<IDisplayComponentInstance>
							{
								id: displayComponentInstance.id,
								name: displayComponentInstance.name,
								description:
									displayComponentInstance.description,
								typeId: displayComponentInstance.typeId,
								definitionId:
									displayComponentInstance.definitionId,
								jsonData: displayComponentInstance.jsonData,
								public: displayComponentInstance.public,
								ownershipSecurityGroupId:
									displayComponentInstance
										.ownershipSecurityGroupId,
								createdById:
									displayComponentInstance.createdById
							}
						);
					});

				return dataReturn;
			},
			availableColumns: this.availableColumns,
			selectedColumns: this.selectedColumns,
			columnSelectionMode: this.columnSelectionMode,
			expandTitle: () =>
				TableHelper.getExpandTitle(
					this.commonTableContext,
					'Display Component Instance'),
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			rowCountChanged: (rowCount: number) =>
			{
				this.tableRowCount = rowCount;
				this.restoreTableDefinition();
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.tableDefinitions.selectedColumns =
					selectedColumns;
				this.selectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.tableDefinitions.columnSelectionMode =
					columnSelectionMode;
				this.columnSelectionMode = columnSelectionMode;
			}
		};
		this.loadingTableDefinitions = false;
	}

	/**
	 * Creates the display definition.
	 *
	 * @async
	 * @memberof DisplayManagementComponent
	 */
	private async createDisplayInstance(): Promise<void>
	{
		const createDisplayInstance: Function = async () =>
		{
			const rowData: any = this.commonTableContext.source.rowData;
			const displayComponentInstanceId: number =
				await this.displayComponentInstanceApiService
					.create(
						<IDisplayComponentInstance>
						{
							id: rowData.id,
							name: rowData.name,
							description: rowData.description,
							typeId: parseInt(
								rowData.typeId,
								AppConstants.parseRadix),
							definitionId: parseInt(
								this.commonTableContext.source
									.rowData.definitionId,
								AppConstants.parseRadix),
							jsonData: rowData.jsonData,
							public: rowData.public,
							ownershipSecurityGroupId:
								rowData.ownershipSecurityGroupId
						});

			for (let index = 0;
				index < rowData.additionalRowData.securityGroups.length;
				index++)
			{
				await this.displayComponentInstanceApiService
					.createSecurityGroupMap(
						displayComponentInstanceId,
						parseInt(
							rowData.additionalRowData
								.securityGroups[index],
							AppConstants.parseRadix));
			}
		};

		await this.activityService.handleActivity(
			new Activity(
				createDisplayInstance(),
				'<strong>Creating Display Instance</strong>',
				'<strong>Created Display Instance</strong>',
				`Display Instance
					${this.commonTableContext.source.rowData.name}
					was successfully created.`,
				`Display Instance
					${this.commonTableContext.source.rowData.name}
					was not created.`));

		this.restoreTableDefinition();
	}

	/**
	 * Updates the display definition.
	 *
	 * @async
	 * @memberof DisplayManagementComponent
	 */
	private async updateDisplayDefinition(): Promise<void>
	{
		const updateDisplayInstance: Function =
			async () =>
			{
				const rowData: any = this.commonTableContext.source.rowData;
				await this.displayComponentInstanceApiService
					.update(
						rowData.id,
						<IDisplayComponentInstance>
						{
							id: rowData.id,
							name: rowData.name,
							description: rowData.description,
							typeId: parseInt(
								rowData.typeId,
								AppConstants.parseRadix),
							definitionId: parseInt(
								rowData.definitionId,
								AppConstants.parseRadix),
							jsonData: rowData.jsonData,
							public: rowData.public,
							ownershipSecurityGroupId:
								rowData.ownershipSecurityGroupId,
							createdById: rowData.createdById
						});

				const existingSecurityGroups: number[] =
					await this.displayComponentInstanceApiService
						.getSecurityGroups(
							rowData.id);

				if (existingSecurityGroups.length
					!== rowData.additionalRowData.securityGroups.length)
				{
					for (const existingSecurityGroup of existingSecurityGroups)
					{
						await this.displayComponentInstanceApiService
							.deleteSecurityGroupMap(
								rowData.id,
								existingSecurityGroup);
					}

					for (let index = 0;
						index < rowData.additionalRowData.securityGroups.length;
						index++)
					{
						await this.displayComponentInstanceApiService
							.createSecurityGroupMap(
								rowData.id,
								parseInt(
									rowData
										.additionalRowData
										.securityGroups[index],
									AppConstants.parseRadix));
					}
				}
			};

		await this.activityService.handleActivity(
			new Activity(
				updateDisplayInstance(),
				'<strong>Updating Display Instance</strong>',
				'<strong>Updated Display Instance</strong>',
				`Display Instance
					${this.commonTableContext.source.rowData.name}
					was successfully updated.`,
				`Display Instance
					${this.commonTableContext.source.rowData.name}
					was not updated.`),
			AppConstants.activityStatus.complete,
			true);
	}

	/**
	 * Gets the delete statement.
	 *
	 * @async
	 * @returns {Promise<string>}
	 * The delete statement string.
	 * @memberof DisplayManagementComponent
	 */
	private async getDeleteStatement(): Promise<string>
	{
		return `Confirm you are about to delete Display Component Instance
			${this.commonTableContext.source.rowData.id}
			${this.commonTableContext.source.rowData.name}.`;
	}

	/**
	 * Deletes display definition.
	 *
	 * @async
	 * @memberof DisplayManagementComponent
	 */
	private async deleteDisplayInstance(): Promise<void>
	{
		await this.activityService.handleActivity(
			new Activity(
				this.displayComponentInstanceApiService
					.delete(this.commonTableContext.data.id),
				'<strong>Deleting the Display Instance</strong>',
				'<strong>Deleted the Display Instance</strong>',
				`Display Instance
					${this.commonTableContext.source.rowData.componentName}
					was successfully deleted.`,
				`Display Instance
					${this.commonTableContext.source.rowData.componentName}
					was not deleted.`));

		this.restoreTableDefinition();
	}

	/**
	 * Sets up the page variables.
	 *
	 * @param {any} apiData
	 * The api resolved with data for the dropdown options.
	 * @param {string} label
	 * The dropdown label to map with the data promise results.
	 * @param {string} value
	 * The dropdown value to map with the data promise results.
	 * @async
	 * @memberof DisplayManagementComponent
	 */
	private getDropdownOptions(
		apiData: any,
		label: string,
		value: string): IDropdownOption[]
	{
		return (apiData)
			.map((item: any) =>
				<IDropdownOption>
				{
					label: item[label],
					value: item[value]
				});
	}

	/**
	 * Gets the additional row data.
	 *
	 * @async
	 * @returns {Promise<any>}
	 * The additional row data object.
	 * @memberof DisplayManagementComponent
	 */
	private async getAdditionalRowData(): Promise<any>
	{
		const securityGroups: number[] =
			this.commonTableContext.data.id === 0
				? []
				: await this.displayComponentInstanceApiService
					.getSecurityGroups(
						this.commonTableContext.data.id);
		const definitionData: IDisplayComponentDefinition =
			await this.displayComponentDefinitionApiService
				.get(this.commonTableContext.data.definitionId);

		return {
			securityGroups: securityGroups,
			definitionJsonData: definitionData.jsonData
		};
	}
}