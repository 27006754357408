/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	ActivityListComponent
} from '@shared/dynamic-components/activity-list/activity-list.component';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppEventParameterConstants
} from '@shared/constants/app-event-parameter.constants';
import {
	BaseOperationGroupDirective
} from '@operation/directives/base-operation-group.directive';
import {
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	Output
} from '@angular/core';
import {
	DrawerAnimation
} from '@shared/app-animations';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	IDrawerMenuItem
} from '@shared/interfaces/application-objects/drawer-menu-item.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IUtilityMenuPinPreference
} from '@shared/interfaces/settings/utility-menu-pin-preference.interface';
import {
	LoggerService
} from '@shared/services/logger.service';
import {
	MenuItem
} from 'primeng/api';
import {
	OperationExecutionService
} from '@operation/services/operation-execution.service';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	SettingPreferenceType
} from '@shared/constants/enums/setting-preference-type.enum';
import {
	SettingsService
} from '@shared/services/settings.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	WindowEventConstants
} from '@shared/constants/window-event.constants';

/* eslint-enable max-len */

@Component({
	selector: 'app-utility-menu',
	templateUrl: './app-utility-menu.component.html',
	styleUrls: [
		'./app-utility-menu.component.scss'
	],
	animations: [
		DrawerAnimation
	]
})

/**
 * A component representing an instance of a utility
 * menu component. This component represents global and
 * content context specific navigation and actions.
 *
 * @export
 * @extends {BaseOperationGroupDirective}
 * @implements {OnDestroy}
 * @class AppUtilityMenuComponent
 */
export class AppUtilityMenuComponent
	extends BaseOperationGroupDirective
	implements OnDestroy
{
	/**
	 * Creates an instance of a AppUtilityMenuComponent.
	 *
	 * @param {LoggerService} loggerService
	 * The logger service to use for client and server side logging.
	 * @param {OperationService} operationService
	 * The operation service to use when loading operation group data.
	 * @param {OperationExecutionService} operationExecutionService
	 * The operation execution service to use when performing operation
	 * commands.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service to use for responsive layouts.
	 * @param {SettingsService<IUtilityMenuPinPreference>} settingsService
	 * The settings service used to load and save pinned utility menu
	 * preferences.
	 * @memberof AppUtilityMenuComponent
	 */
	public constructor(
		public loggerService: LoggerService,
		public operationService: OperationService,
		public operationExecutionService: OperationExecutionService,
		public siteLayoutService: SiteLayoutService,
		public settingsService: SettingsService<IUtilityMenuPinPreference>)
	{
		super(
			loggerService,
			operationService,
			operationExecutionService,
			siteLayoutService);

		settingsService.settingKey =
			SettingPreferenceType.utilityMenuPinPreference;

		this.globalUtilities =
			[
				<IDrawerMenuItem>
				{
					dynamicComponent: ActivityListComponent,
					icon: 'fa fa-fw fa-bell-o',
					label: 'Activities'
				},
				<IDrawerMenuItem>
				{
					icon: 'fa fa-fw fa-question',
					label: 'Help',
					command: () => {
						window.inline_manual_player.showPanel();
					}
				}
			];

		this.displayName = 'context utilities';
	}

	/**
	 * Gets or sets the page context used for linking drawer displays to
	 * page content.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof AppUtilityMenuComponent
	 */
	@Input() public pageContext: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the event that will be emitted to all listening components
	 * when a drawer is displayed or removed as a pinned item consuming layout.
	 *
	 * @type {EventEmitter<number>}
	 * @memberof AppUtilityMenuComponent
	 */
	@Output() public pinnedDrawerCountChange: EventEmitter<number> =
		new EventEmitter();

	/**
	 * Gets or sets the set of drawer menu items displayed in the global
	 * utility menu list.
	 *
	 * @type {IDrawerMenuItem[]}
	 * @memberof AppUtilityMenuComponent
	 */
	public globalUtilities: IDrawerMenuItem[] = [];

	/**
	 * Gets or sets the set of drawer menu items displayed in the context
	 * utility menu list.
	 *
	 * @type {IDrawerMenuItem[]}
	 * @memberof AppUtilityMenuComponent
	 */
	public contextUtilities: IDrawerMenuItem[] = [];

	/**
	 * Gets or sets the number of utilities loaded from the api to display
	 * in this utility menu.
	 *
	 * @type {number}
	 * @memberof AppUtilityMenuComponent
	 */
	public apiUtilityCount: number;

	/**
	 * Gets or sets the drawer items that are currently pinned.
	 *
	 * @type {IDrawerMenuItem[]}
	 * @memberof AppUtilityMenuComponent
	 */
	public pinnedDrawerItems: IDrawerMenuItem[] = [];

	/**
	 * Gets or sets the drawer item that is currently overlayed.
	 *
	 * @type {IDrawerMenuItem}
	 * @memberof AppUtilityMenuComponent
	 */
	public overlayDrawerItem: IDrawerMenuItem;

	/**
	 * Gets or sets an active drawer component that will display on page load.
	 *
	 * @type {string | Component}
	 * @memberof AppUtilityMenuComponent
	 */
	public activeDrawerComponent: string | Component;

	/**
	 * Gets or sets a partial description of the active drawer component item.
	 * This value is used to set an item as active after setting a drawer
	 * component as active on page load.
	 * @note This value can be sent as a span with the class visually-hidden
	 * to ensure that you can find the specific item based on unique values.
	 *
	 * @type {string}
	 * @memberof AppUtilityMenuComponent
	 */
	public activeDrawerItemDescription: string;

	/**
	 * Gets the maximum number of pinnable drawers.
	 *
	 * @type {number}
	 * @memberof AppUtilityMenuComponent
	 */
	private readonly maximumPinnableDrawerCount: number = 5;

	/**
	 * Gets the identifier for this component. This is used as a
	 * target component to handle add drawer actions.
	 *
	 * @type {string}
	 * @memberof AppUtilityMenuComponent
	 */
	private readonly componentName: string = 'UtilityMenuComponent';

	/**
	 * Handles the add drawer event.
	 * This will add a drawer menu item to this utility menu
	 * if the target component is a match for our component
	 * identifier.
	 *
	 * @async
	 * @param {Component | string} contentComponent
	 * The dynamic component that will be displayed as drawer
	 * content.
	 * @param {string} targetComponent
	 * The identifier of the drawer command component to add this drawer to.
	 * @param {string} label
	 * The drawer header as well as the label displayed on hover.
	 * @param {string} icon
	 * The fontAwesome icon to display in the drawer and the utility menu for
	 * this drawer item. Note: Only the icon identifier is required,
	 * ie 'edit-o' as these are decorated into the standard of
	 * 'fa fa-fw fa-{icon}'.
	 * @param {number} order
	 * The order to display this drawer.
	 * @param {boolean} startOpen
	 * The value that will define if this drawer should immediately be
	 * overlaid on add.
	 * @param {boolean} disabled
	 * The value that will define if this drawer should be displayed but
	 * disabled.
	 * @param {string} disabledMessage
	 * If sent, this value will be added to the utility menu tooltip to
	 * define why this is disabled.
	 * @memberof AppUtilityMenuComponent
	 */
	@HostListener(
		AppEventConstants.addDrawerEvent,
		[
			AppEventParameterConstants.contentComponent,
			AppEventParameterConstants.targetComponent,
			AppEventParameterConstants.label,
			AppEventParameterConstants.icon,
			AppEventParameterConstants.order,
			AppEventParameterConstants.startOpen,
			AppEventParameterConstants.disabled,
			AppEventParameterConstants.disabledMessage,
			AppEventParameterConstants.displayDrawerOverlay,
			AppEventParameterConstants.overlayClassName
		])
	public async addDrawer(
		contentComponent: Component | string,
		targetComponent: string,
		label: string,
		icon: string,
		order: number,
		startOpen: boolean,
		disabled: boolean,
		disabledMessage: string,
		displayDrawerOverlay: boolean = false,
		overlayClassName: string = null): Promise<void>
	{
		if (targetComponent === this.componentName)
		{
			const drawerItem: IDrawerMenuItem =
				<IDrawerMenuItem>
				{
					dynamicComponent: contentComponent,
					active: contentComponent === this.activeDrawerComponent,
					label: StringHelper.beforeCapitalSpaces(label),
					icon: 'fa fa-fw fa-' + icon,
					visible: true,
					disabled: disabled,
					tooltipOptions: {
						tooltipLabel: disabledMessage
					},
					pinned: false,
					pinnable: this.isPinnable(),
					queryParams: {
						order: order
					},
					displayDrawerOverlay: displayDrawerOverlay,
					overlayClassName: overlayClassName
				};

			if (startOpen === true
				&& disabled === false
				&& AnyHelper.isNullOrEmpty(this.activeDrawerComponent))
			{
				this.activeDrawerComponent = contentComponent;
			}

			this.contextUtilities.push(
				drawerItem);
			this.contextUtilities.sort(
				(firstMenuItem: MenuItem, secondMenuItem: MenuItem) =>
					firstMenuItem.queryParams.order -
						secondMenuItem.queryParams.order);

			if (this.apiUtilityCount === this.contextUtilities.length)
			{
				this.handlePinPreferences();
				this.handleActiveDrawer();
			}
		}
	}

	/**
	 * Handles the set badge promise event.
	 * This will add a promise from a string or a method to the drawer item
	 * and set  this value.
	 *
	 * @async
	 * @param {Component | string} contentComponent
	 * The dynamic component to be targetted for the badge promise.
	 * @param {string} targetComponent
	 * The identifier for the content component container.
	 * @param {string} badgePromise
	 * The promise as a string which will return a populated badge item.
	 * @memberof AppUtilityMenuComponent
	 */
	@HostListener(
		AppEventConstants.setBadgePromiseEvent,
		[
			AppEventParameterConstants.contentComponent,
			AppEventParameterConstants.targetComponent,
			AppEventParameterConstants.badgePromise
		])
	public async setBadgePromise(
		contentComponent: Component | string,
		targetComponent: string,
		badgePromise: string): Promise<void>
	{
		if (targetComponent === this.componentName)
		{
			const matchingDrawerItem: IDrawerMenuItem =
				this.getMatchingDrawerItem(contentComponent);

			if (matchingDrawerItem == null)
			{
				return;
			}

			matchingDrawerItem.state = matchingDrawerItem.state || {};
			matchingDrawerItem.state.rawBadgePromise = badgePromise;

			matchingDrawerItem.state.badgeItem =
				await StringHelper.transformToDataPromise(
					StringHelper.interpolate(
						matchingDrawerItem.state.rawBadgePromise,
						this.pageContext),
					this.pageContext);
		}
	}

	/**
	 * Handles the refresh badge promise event.
	 * This will call the existing badge promise for the matching drawer.
	 *
	 * @async
	 * @param {Component | string} contentComponent
	 * The dynamic component to be targetted for the badge promise refresh.
	 * @param {string} targetComponent
	 * The identifier for the content component container.
	 * @memberof AppUtilityMenuComponent
	 */
	@HostListener(
		AppEventConstants.refreshBadgePromiseEvent,
		[
			AppEventParameterConstants.contentComponent,
			AppEventParameterConstants.targetComponent
		])
	public async refreshBadgePromise(
		contentComponent: Component | string,
		targetComponent: string): Promise<void>
	{
		if (targetComponent === this.componentName)
		{
			const matchingDrawerItem: IDrawerMenuItem =
				this.getMatchingDrawerItem(contentComponent);

			if (AnyHelper.isNull(matchingDrawerItem?.state?.rawBadgePromise))
			{
				return;
			}

			matchingDrawerItem.state.badgeItem =
				await StringHelper.transformToDataPromise(
					StringHelper.interpolate(
						matchingDrawerItem.state.rawBadgePromise,
						this.pageContext),
					this.pageContext);
		}
	}

	/**
	 * Handles the refresh component event.
	 * This will refresh the drawer matching the content component if this
	 * utility menu is the target component.
	 *
	 * @param {Component | string} contentComponent
	 * The dynamic component to be targetted for a view refresh.
	 * @param {string} targetComponent
	 * The identifier for the content component container.
	 * @memberof AppUtilityMenuComponent
	 */
	@HostListener(
		AppEventConstants.refreshComponentEvent,
		[
			AppEventParameterConstants.contentComponent,
			AppEventParameterConstants.targetComponent
		])
	public refreshDrawer(
		contentComponent: Component | string,
		targetComponent: string): void
	{
		if (targetComponent === this.componentName)
		{
			this.refreshBadgePromise(
				contentComponent,
				targetComponent);

			const matchingDrawerItem: IDrawerMenuItem =
				this.getMatchingDrawerItem(contentComponent);

			if (matchingDrawerItem == null)
			{
				return;
			}

			if (matchingDrawerItem.pinned === false
				&& this.overlayDrawerItem !== matchingDrawerItem)
			{
				return;
			}

			const dynamicComponent: string | Component =
				matchingDrawerItem.dynamicComponent;

			matchingDrawerItem.dynamicComponent = null;
			setTimeout(
				() =>
				{
					matchingDrawerItem.dynamicComponent = dynamicComponent;
				});
		}
	}

	/**
	 * Handles the set utility menu event. This will set the
	 * utility menu operation group name to display and, if sent, this will
	 * also set a drawer component and item in that drawer as active.
	 *
	 * @param {string} utilityMenuGroup
	 * The utility menu to display in this application.
	 * @param {string | Component} activeDrawerComponent
	 * If sent, this will be the drawer component to set as active.
	 * @param {string} activeDrawerItemDescription
	 * If sent, this will be the unique description found in a drawer item list
	 * to select an item as active.
	 * @memberof AppUtilityMenuComponent
	 */
	@HostListener(
		AppEventConstants.setUtilityMenuEvent,
		[
			AppEventParameterConstants.utilityMenuOperationGroupName,
			AppEventParameterConstants.activeDrawerComponent,
			AppEventParameterConstants.activeDrawerItemDescription
		])
	public displayUtilityMenu(
		utilityMenuGroup: string,
		activeDrawerComponent?: string | Component,
		activeDrawerItemDescription?: string): void
	{
		this.activeDrawerComponent = activeDrawerComponent;
		this.activeDrawerItemDescription = activeDrawerItemDescription;

		setTimeout(
			() =>
			{
				this.operationGroupName = utilityMenuGroup;

				this.pinnedDrawerItems =
					this.pinnedDrawerItems.filter(
						(drawerMenuItem: IDrawerMenuItem) =>
							!AnyHelper.isNull(
								this.globalUtilities.find(
									(globalMenuItem: IDrawerMenuItem) =>
										globalMenuItem.label ===
											drawerMenuItem.label)));

				this.overlayDrawerItem = null;

				this.pinnedDrawerCountChanged(
					this.pinnedDrawerItems.length);

				this.ngOnInit();
			});
	}

	/**
	 * Handles the site layout change event which is called
	 * when the site layout service has altered it's variables.
	 *
	 * @memberof AppUtilityMenuComponent
	 */
	@HostListener(
		AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		this.closeOverlay(true);

		if (this.pinnedDrawerItems.length > 0
			&& this.siteLayoutService.breakpointWidth
				+ AppConstants.staticLayoutSizes.drawerWidth
					< AppConstants.layoutBreakpoints.pinnableDrawerWidth)
		{
			const numberOfDrawersToRemove: number =
				Math.floor((AppConstants.layoutBreakpoints.pinnableDrawerWidth
					- this.siteLayoutService.contentWidth)
						/ AppConstants.staticLayoutSizes.drawerWidth);

			if (numberOfDrawersToRemove > 0
				&& this.pinnedDrawerItems.length > 0)
			{
				const drawerToRemove: IDrawerMenuItem =
					this.pinnedDrawerItems[0];

				drawerToRemove.active = false;
				drawerToRemove.pinned = false;
				drawerToRemove.pinnable = false;

				const currentPinnedItems: number =
					this.pinnedDrawerItems.length;

				this.pinnedDrawerItems =
					this.pinnedDrawerItems.filter(
						(drawerMenuItem: IDrawerMenuItem) =>
							drawerMenuItem !== drawerToRemove);

				if (currentPinnedItems !==
					this.pinnedDrawerItems.length)
				{
					this.pinnedDrawerCountChanged(
						this.pinnedDrawerItems.length);
				}
			}

			if (numberOfDrawersToRemove > 1)
			{
				setTimeout(
					() =>
					{
						this.siteLayoutChanged();
					},
					this.siteLayoutService.debounceDelay);
			}
		}

		this.handlePinPreferences();
		this.handleActiveDrawer();
	}

	/**
	 * Handles the click outside event sent when clicking outside of
	 * the utility menu or associated drawers component. Also handles the
	 * hide associated menus event. This is used to close overlays when
	 * an associated menu is opened, such as a sibling context menu.
	 * This will close any existing overlays matching overlay business rules.
	 *
	 * @param {string} controlIdentifer
	 * The identifier of the control sending this event.
	 * @memberof AppUtilityMenuComponent
	 */
	@HostListener(
		AppEventConstants.hideAssociatedMenusEvent,
		[AppEventParameterConstants.id])
	public clickOutside(
		controlIdentifer: string): void
	{
		if (!AnyHelper.isNullOrEmpty(this.overlayDrawerItem)
			&& controlIdentifer !== 'ellipsis-menu')
		{
			this.closeOverlay(true);
		}
	}

	/**
	 * Handles the on destroy event.
	 * This is called only during logout and will refresh the singleton
	 * layout service pinned count for the next login session.
	 *
	 * @memberof AppUtilityMenuComponent
	 */
	public ngOnDestroy(): void
	{
		this.pinnedDrawerItems = [];
		this.overlayDrawerItem = null;
		this.pinnedDrawerCountChanged(
			this.pinnedDrawerItems.length);
	}

	/**
	 * Handles the perform post operation load actions operation group
	 * component post load lifecycle event.
	 * This will call the associated utility operation group commands
	 * after load. With AddDrawer operations defined this will add
	 * each drawer to it's associated command target component. A
	 * layout watcher will also ensure we close overlays on window
	 * width changes.
	 *
	 * @memberof AppUtilityMenuComponent
	 */
	public performPostOperationLoadActions(): void
	{
		this.contextUtilities = [];
		this.apiUtilityCount = this.model.length;

		// Handle global utility preferences.
		this.handlePinPreferences();

		this.model.forEach(
			(menuItem: MenuItem) =>
			{
				if (menuItem.visible === false)
				{
					return;
				}

				menuItem.command();
			});
	}

	/**
	 * This method will remove the auto focus click event attached to
	 * primeNg tooltips.
	 *
	 * @param {MouseEvent} event
	 * The click event to be captured and halted.
	 * @memberof AppUtilityMenuComponent
	 */
	public preventDefault(
		event: MouseEvent): void
	{
		event.preventDefault();
		event.stopImmediatePropagation();
	}

	/**
	 * Handles pin preferences on events such as load and layout changed.
	 * This will ensure that the currently displayed pin set reflects
	 * preferences stored in the setting service.
	 *
	 * @async
	 * @memberof AppUtilityMenuComponent
	 */
	public async handlePinPreferences(): Promise<void>
	{
		if (!this.isPinnable())
		{
			return;
		}

		const pinnedPreferences: IUtilityMenuPinPreference[] =
			await this.settingsService.loadSettings();

		// Pin matching utilities in the longest pinned order.
		for (const pinPreference of
			pinnedPreferences.filter(
				(preference: IUtilityMenuPinPreference) =>
					preference.pinned === true))
		{
			const matchingDrawerItems: IDrawerMenuItem[] =
				[
					...this.globalUtilities,
					...this.contextUtilities
				].filter(
					(drawerMenuItem: IDrawerMenuItem) =>
						drawerMenuItem.label === pinPreference.key);

			const pinnedItems: IDrawerMenuItem[] =
				this.pinnedDrawerItems.filter(
					(drawerMenuItem: IDrawerMenuItem) =>
						drawerMenuItem.label === pinPreference.key);

			if (matchingDrawerItems.length > 0
				&& pinnedItems.length === 0)
			{
				this.handlePinPreference(
					matchingDrawerItems[0]);
			}

			if (!this.isPinnable())
			{
				break;
			}
		}
	}

	/**
	 * Handles the click event sent when clicking the global or
	 * context level utility menu items.
	 * This will hide or display the existing drawer matching business rules.
	 *
	 * @param {IDrawerMenuItem} drawerMenuItem
	 * The drawer menu item that received the item click event.
	 * @memberof AppUtilityMenuComponent
	 */
	public utilityMenuItemClick(
		drawerMenuItem: IDrawerMenuItem): void
	{
		if (drawerMenuItem.disabled === true)
		{
			return;
		}

		// Inline manual tracking which works outside of the pin/active system.
		if (AnyHelper.isNull(drawerMenuItem.command) === false)
		{
			drawerMenuItem.command(null);

			return;
		}

		drawerMenuItem.active = !drawerMenuItem.active;

		if (drawerMenuItem.active === true)
		{
			if (!AnyHelper.isNullOrEmpty(this.overlayDrawerItem))
			{
				this.overlayDrawerItem.active = false;
			}

			drawerMenuItem.pinned = false;
			drawerMenuItem.pinnable = this.isPinnable();
			this.overlayDrawerItem = drawerMenuItem;

			EventHelper.dispatchDrawerOverlayEvent(true);
		}
		else
		{
			this.closeOverlay(true);

			const currentPinnedItems: number =
				this.pinnedDrawerItems.length;

			this.pinnedDrawerItems =
				this.pinnedDrawerItems.filter(
					(drawerItem: IDrawerMenuItem) =>
						drawerItem !== drawerMenuItem);

			drawerMenuItem.pinned = false;
			this.storeContextPinSettings(
				drawerMenuItem);

			if (currentPinnedItems !==
				this.pinnedDrawerItems.length)
			{
				this.pinnedDrawerCountChanged(
					this.pinnedDrawerItems.length);
			}
		}
	}

	/**
	 * Handles the click event sent when the pin action is called on
	 * the sent drawer menu item.
	 * This will pin or unpin the display matching existing business rules
	 * and call to the app component to ensure layouts can change based
	 * on smaller available content widths due to pinned drawers.
	 *
	 * @param {IDrawerMenuItem} drawerMenuItem
	 * The drawer menu item that received the item click event.
	 * @memberof AppUtilityMenuComponent
	 */
	public pinDrawerClick(
		drawerMenuItem: IDrawerMenuItem): void
	{
		this.closeOverlay(drawerMenuItem !== this.overlayDrawerItem);
		drawerMenuItem.pinned = !drawerMenuItem.pinned;

		const currentPinnedItems: number =
			this.pinnedDrawerItems.length;

		if (drawerMenuItem.pinned === true)
		{
			this.pinnedDrawerItems =
				[
					drawerMenuItem,
					...this.pinnedDrawerItems
				];
		}
		else
		{
			this.pinnedDrawerItems =
				this.pinnedDrawerItems.filter(
					(utilityItem: IDrawerMenuItem) =>
						utilityItem !== drawerMenuItem);

			drawerMenuItem.active = false;
		}

		this.storeContextPinSettings(
			drawerMenuItem);

		if (currentPinnedItems !==
			this.pinnedDrawerItems.length)
		{
			this.pinnedDrawerCountChanged(
				this.pinnedDrawerItems.length);
		}
	}

	/**
	 * On pin value changes, this will store those as pin preferences
	 * for this session if the pinned item is in the context utility
	 * set.
	 *
	 * @param {IDrawerMenuItem} drawerMenuItem
	 * The drawer menu item that received a pinned value change.
	 * @memberof AppUtilityMenuComponent
	 */
	public storeContextPinSettings(
		drawerMenuItem: IDrawerMenuItem): void
	{
		this.settingsService.addOrUpdateSetting(
			<IUtilityMenuPinPreference>
			{
				key: drawerMenuItem.label,
				pinned: drawerMenuItem.pinned
			});
	}

	/**
	 * Handles changes in the pinned drawer counts by emitting this pinned count
	 * to the listening application component.
	 *
	 * @param {number} pinnedDrawerCount
	 * The number of currently pinned drawers.
	 * @memberof AppUtilityMenuComponent
	 */
	public pinnedDrawerCountChanged(
		pinnedDrawerCount: number): void
	{
		const countChanged: boolean =
			this.siteLayoutService.pinnedDrawerCount !== pinnedDrawerCount;

		if (countChanged === true)
		{
			this.siteLayoutService.pinnedDrawerCount = pinnedDrawerCount;
			this.pinnedDrawerCountChange.emit(pinnedDrawerCount);
		}
	}

	/**
	 * Closes any utility menu drawers that are currently displaying
	 * in overlay mode.
	 *
	 * @param {boolean} setAsInactive
	 * The truthy specifying if this overlay close should also set
	 * the drawer menu item as no longer active.
	 * @memberof AppUtilityMenuComponent
	 */
	public closeOverlay(
		setAsInactive: boolean): void
	{
		if (setAsInactive === true
			&& !AnyHelper.isNullOrEmpty(this.overlayDrawerItem))
		{
			this.overlayDrawerItem.active = false;
		}

		this.overlayDrawerItem = null;
		EventHelper.dispatchDrawerOverlayEvent(false);
	}

	/**
	 * Returns a value specifying if this drawer is currently pinnable.
	 *
	 * @returns {boolean}
	 * A boolean signifying whether or not this drawer item is pinnable.
	 * @memberof AppUtilityMenuComponent
	 */
	public isPinnable(): boolean
	{
		return this.pinnedDrawerItems.length <= this.maximumPinnableDrawerCount
			&& this.siteLayoutService.breakpointWidth
				>= AppConstants.layoutBreakpoints.pinnableDrawerWidth;
	}

	/**
	 * Gets the icon class to be displayed for all utilty menu item
	 * icons.
	 *
	 * @param {IDrawerMenuItem} drawerMenuItem
	 * The menu item that requires icon decoration.
	 * @returns {string}
	 * The class used for utility menu item icons.
	 * @memberof AppUtilityMenuComponent
	 */
	public iconClass(
		drawerMenuItem: IDrawerMenuItem): string
	{
		return `utility-icon ${drawerMenuItem.icon}`;
	}

	/**
	 * Gets and returns a filtered drawer menu item with the matching content
	 * component.
	 *
	 * @param {Component | string} contentComponent
	 * The dynamic component to filter by.
	 * @returns {IDrawerMenuItem}
	 * The matching component displayed in the utility menu that holds this
	 * content component.
	 * @memberof AppUtilityMenuComponent
	 */
	private getMatchingDrawerItem(
		contentComponent: string | Component): IDrawerMenuItem
	{
		return [
			...this.globalUtilities,
			...this.contextUtilities
		].find(
			(drawerMenuItem: IDrawerMenuItem) =>
				drawerMenuItem.dynamicComponent === contentComponent);
	}

	/**
	 * Given a sent drawer to pin, this will handle logic that will include
	 * preference based drawer pins on page load.
	 *
	 * @param {IDrawerMenuItem} drawerToPin
	 * The drawer to be pinned.
	 * @memberof AppUtilityMenuComponent
	 */
	private handlePinPreference(
		drawerToPin: IDrawerMenuItem): void
	{
		if (drawerToPin.disabled === true)
		{
			return;
		}

		if (drawerToPin === this.overlayDrawerItem)
		{
			this.closeOverlay(true);
		}

		drawerToPin.active = true;
		drawerToPin.pinned = true;
		drawerToPin.pinnable = true;

		this.pinnedDrawerItems =
			[
				drawerToPin,
				...this.pinnedDrawerItems
			];

		this.pinnedDrawerCountChanged(
			this.pinnedDrawerItems.length);
	}

	/**
	 * Given a set active drawer component, this will handle logic that will
	 * set that drawer as active in overlay mode or pinned if available.
	 *
	 * @param {number} retryCount
	 * The number of retries to attempt displaying this active drawer. This is
	 * used to handle differences in drawer load times.
	 * @memberof AppUtilityMenuComponent
	 */
	private handleActiveDrawer(
		retryCount: number = 0): void
	{
		if (AnyHelper.isNull(this.activeDrawerComponent)
			|| retryCount >= 10)
		{
			return;
		}

		const activeDrawer: IDrawerMenuItem =
			this.getMatchingDrawerItem(this.activeDrawerComponent);

		if (AnyHelper.isNull(activeDrawer))
		{
			setTimeout(
				() =>
				{
					this.handleActiveDrawer(
						retryCount + 1);
				},
				AppConstants.time.oneSecond);

			return;
		}

		const isOverlaid: boolean =
			!AnyHelper.isNull(this.overlayDrawerItem)
				&& this.overlayDrawerItem.dynamicComponent ===
					this.activeDrawerComponent;
		const isPinned: boolean =
			this.pinnedDrawerItems.filter(
				(pinnedItem: IDrawerMenuItem) =>
					pinnedItem.dynamicComponent ===
						this.activeDrawerComponent).length > 0;

		if (this.isPinnable()
			&& isPinned === false)
		{
			if (isOverlaid === true)
			{
				this.closeOverlay(true);
			}

			this.pinDrawerClick(activeDrawer);
		}
		else if (isPinned === false
			&& isOverlaid === false)
		{
			this.utilityMenuItemClick(activeDrawer);
		}

		this.setActiveDrawerItem();

		setTimeout(
			() =>
			{
				this.activeDrawerComponent = null;
			},
			AppConstants.time.fiveSeconds);
	}

	/**
	 * Given a set active drawer component item, this will handle logic that
	 * will set that drawer item as selected.
	 *
	 * @param {number} retryCount
	 * The number of retries to attempt displaying this active drawer item. This
	 * is used to handle differences in drawer load times.
	 * @memberof AppUtilityMenuComponent
	 */
	private setActiveDrawerItem(
		retryCount: number = 0): void
	{
		if (AnyHelper.isNullOrWhitespace(this.activeDrawerItemDescription)
			|| retryCount >= 5)
		{
			return;
		}

		let listElement: Element = null;

		document.querySelectorAll(
			`.${AppConstants.cssClasses.lineOne}`)
			.forEach(
				(element: Element) =>
				{
					if (element.textContent
						.includes(this.activeDrawerItemDescription))
					{
						listElement = element.parentElement.parentElement;
					}
				});

		if (AnyHelper.isNull(listElement))
		{
			setTimeout(
				() =>
				{
					this.setActiveDrawerItem(
						retryCount + 1);
				},
				AppConstants.time.oneSecond);

			return;
		}

		if (!listElement.classList.contains(
			AppConstants.cssClasses.selected))
		{
			listElement.dispatchEvent(
				new Event(WindowEventConstants.click));
			this.activeDrawerItemDescription = null;
		}
	}
}