/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ActivatedRoute,
	Router
} from '@angular/router';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	Component,
	OnInit
} from '@angular/core';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	FormControl
} from '@angular/forms';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityDefinition
} from '@shared/interfaces/entities/entity-definition.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IRuleDefinition
} from '@shared/interfaces/rules/rule-definition.interface';
import {
	IRuleDefinitionDto
} from '@api/interfaces/rules/rule-definition.dto.interface';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	RuleDefinitionApiService
} from '@api/services/rules/rule-definition.api.service';
import {
	TableHelper
} from '@shared/helpers/table.helper';

/* eslint-enable max-len */

@Component({
	selector: 'app-entity-rules',
	templateUrl: './entity-rules.component.html'
})

/**
 * A component representing an instance of the system entity rules
 * component.
 *
 * @export
 * @class EntityRulesComponent
 * @extends {CommonTablePageDirective}
 * @implements {OnInit}
 */
export class EntityRulesComponent
	extends CommonTablePageDirective
	implements OnInit
{
	/**
	 * Creates an instance of an EntityRulesComponent.
	 *
	 * @param {EntityLayoutApiService} entityLayoutApiService
	 * The api service used to get the entity layout data.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to get the entity type data.
	 * @param {EntityVersionApiService} entityVersionApiService
	 * The api service used to get entity version data.
	 * @param {RuleDefinitionApiService} ruleDefinitionApiService
	 * The api service used to get rule definition data.
	 * @param {ActivatedRoute} route
	 * The activated route that opened this component.
	 * @param {Router} router
	 * The router service.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof EntityRulesComponent
	 */
	public constructor(
		public entityDefinitionApiService: EntityDefinitionApiService,
		public entityTypeApiService: EntityTypeApiService,
		public ruleDefinitionApiService: RuleDefinitionApiService,
		public route: ActivatedRoute,
		public router: Router,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the entity definition.
	 *
	 * @type {IEntityDefinition}
	 * @memberof EntityRulesComponent
	 */
	public entityDefinition: IEntityDefinition;

	/**
	 * Gets or sets the entity type.
	 *
	 * @type {IEntityType}
	 * @memberof EntityRulesComponent
	 */
	public entityType: IEntityType;

	/**
	 * Gets or sets the table definitions.
	 *
	 * @type {ICommonTable}
	 * @memberof EntityRulesComponent
	 */
	public tableDefinitions: ICommonTable;

	/**
	 * Gets or sets the entity definition id.
	 *
	 * @type {number}
	 * @memberof EntityRulesComponent
	 */
	public entityDefinitionId: number;

	/**
	 * Initializes the component to set the page variables
	 * and setup the table definitions.
	 *
	 * @async
	 * @memberof EntityRulesComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		await this.setupPageVariables();
		this.setupTableDefinitions();
	}

	/**
	 * Sets the page variables needed for this component.
	 *
	 * @async
	 * @memberof EntityRulesComponent
	 */
	public async setupPageVariables(): Promise<void>
	{
		this.entityDefinitionId = this.route.snapshot.paramMap.get(
			AppConstants.commonProperties.id) as unknown as number;

		this.entityDefinition =
			await this.entityDefinitionApiService
				.get(this.entityDefinitionId);

		this.entityType =
			await this.entityTypeApiService
				.get(this.entityDefinition.typeId);

		this.tableFilterQuery = 'EntityTypeId'
			+ ` eq ${this.entityDefinition.typeId}`
			+ ' and EntityVersionId eq'
			+ ` ${this.entityDefinition.versionId}`;

		let displayOrder: number = 1;
		this.availableColumns =
			[
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'displayName',
					columnHeader: 'Display Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'description',
					columnHeader: 'Description',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'overridable',
					columnHeader: 'Overridable',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'order',
					columnHeader: 'Order',
					displayOrder: displayOrder
				}
			];
		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Sets the common table definitions needed for this component.
	 *
	 * @async
	 * @memberof EntityRulesComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		this.tableDefinitions = {
			hideExpanderArrow: true,
			actions: {
				create: {
					displayCreateRow: false,
					layout: [
						{
							key: 'data.name',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Name',
								required: true
							},
							asyncValidators: {
								uniqueName: {
									expression: (
										control: FormControl) =>
										this.uniqueName(control),
									message: 'Existing Rule Name.'
								}
							}
						}
					],
					items: [
						{
							label: 'Create',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: async() => this.createAction()
						}]
				},
				view: {
					disabledExpandRow: true,
					items: []
				},
				update: {
					disabledExpandRow: true,
					items: [
						{
							command: () =>
							{
								const routeData: string =
									ObjectHelper.mapRouteData(
										{
											ruleDefinitionId:
												this.commonTableContext
													.data.id
										});

								this.router.navigate(
									[
										'admin/entity/ruleDefinition/edit',
										this.entityDefinitionId
									],
									{
										queryParams:
											{
												routeData: routeData
											}
									});
							}
						}
					]
				},
				delete: {
					items: [
						{
							label: 'Confirm',
							styleClass: AppConstants.cssClasses.pButtonDanger,
							command: async() => this.deleteAction(),
						}],
					deleteStatement: () => this.getDeleteStatement(),
				},
				updateIndex:
				[
					{
						id: 'updateIndexUp',
						visible: (
							rowData: any,
							virtualData: any[]) =>
							rowData.order !== virtualData[0].order,
						command: async(
							commonTableContext: CommonTableComponent) =>
							this.updateOrderIndex(
								commonTableContext,
								-1)
					},
					{
						id: 'updateIndexDown',
						visible: (
							rowData: any,
							virtualData: any[]) =>
							rowData.order !==
									virtualData[virtualData.length - 1].order,
						command: async(
							commonTableContext: CommonTableComponent) =>
							this.updateOrderIndex(
								commonTableContext,
								1)
					}
				],
			},
			tableTitle: null,
			objectSearch: {
				filter: this.tableFilterQuery,
				orderBy: `Order ${AppConstants.sortDirections.ascending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: this.tableRowCount
			},
			apiPromise: async (objectSearch: IObjectSearch) =>
				this.ruleDefinitionApiService
					.query(
						objectSearch.filter,
						objectSearch.orderBy,
						objectSearch.offset,
						objectSearch.limit),
			availableColumns: this.availableColumns,
			selectedColumns: this.selectedColumns,
			columnSelectionMode: this.columnSelectionMode,
			expandTitle: () =>
				TableHelper.getExpandTitle(
					this.commonTableContext,
					'Rule Definition'),
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			rowCountChanged: (rowCount: number) =>
			{
				this.tableRowCount = rowCount;
				this.restoreTableDefinition();
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.tableDefinitions.selectedColumns =
					selectedColumns;
				this.selectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.tableDefinitions.columnSelectionMode =
					columnSelectionMode;
				this.columnSelectionMode = columnSelectionMode;
			}
		};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Creates a new entity layout.
	 *
	 * @async
	 * @memberof EntityRulesComponent
	 */
	private async createAction(): Promise<void>
	{
		const createRuleAction: Function = async() =>
		{
			const newRule: IRuleDefinitionDto =
					<IRuleDefinitionDto>
					{
						name: this.commonTableContext.data.name,
						entityTypeId: this.entityDefinition.typeId,
						entityVersionId: this.entityDefinition.versionId,
						displayName: this.commonTableContext.data.name,
						overridable: false,
						description: null,
						order: (this.commonTableContext.source.virtualData[
							this.commonTableContext.source
								.virtualData.length - 1]
							.order + 10) || 10
					};

			const newRuleId: number =
					await this.ruleDefinitionApiService
						.create(newRule);

			this.router.navigate(
				[
					'admin/entity/ruleDefinition/edit',
					this.entityDefinitionId
				],
				{
					queryParams:
						{
							routeData:
								ObjectHelper.mapRouteData(
									{
										ruleDefinitionId:
											newRuleId
									})
						}
				});
		};

		await this.activityService.handleActivity(
			new Activity(
				createRuleAction(),
				'<strong>Creating Rule Definition</strong>',
				'<strong>Created Rule Definition</strong>',
				`Rule Definition ${this.commonTableContext.data.name}`
					+ ` was created to Entity ${this.entityType.name}.`,
				`Rule Definition ${this.commonTableContext.data.name}`
					+ ` was not created to Entity ${this.entityType.name}.`));

		this.restoreTableDefinition();
	}

	/**
	 * Gets the common table delete statement.
	 *
	 * @async
	 * @returns {string}
	 * The delete statement
	 * @memberof EntityLayoutsComponent
	 */
	private async getDeleteStatement(): Promise<string>
	{
		return `Confirm you are about to delete Rule Definition
			${this.commonTableContext.source.rowData.id}
			${this.commonTableContext.source.rowData.name}.`;
	}

	/**
	 * Deletes an existing entity access policy.
	 *
	 * @async
	 * @memberof EntityRulesComponent
	 */
	private async deleteAction(): Promise<void>
	{
		await this.activityService.handleActivity(
			new Activity(
				this.ruleDefinitionApiService
					.delete(this.commonTableContext.data.id),
				'<strong>Deleting Rule Definition</strong>',
				'<strong>Deleted Rule Definition</strong>',
				`Rule Definition ${this.commonTableContext.data.name}`
					+ ` was deleted from Entity ${this.entityType.name}.`,
				`Rule Definition ${this.commonTableContext.data.name}`
					+ ` was not deleted from Entity ${this.entityType.name}.`));

		this.restoreTableDefinition();
	}

	/**
	 * Validates the name is unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The Form Control used to get the input value
	 * @returns {Promise<boolean>}
	 * The Validator promise result.
	 * @memberof EntityRulesComponent
	 */
	private async uniqueName(control: FormControl): Promise<boolean>
	{
		const existingRules: IRuleDefinitionDto[] =
			await this.ruleDefinitionApiService
				.query(
					`name eq '${control.value}'`
						+ ' and entityTypeId'
						+ ` eq ${this.entityDefinition.typeId}`
						+ ' and entityVersionId'
						+ ` eq '${this.entityDefinition.versionId}'`,
					AppConstants.empty);

		return Promise.resolve(existingRules.length === 0);
	}

	/**
	 * Updates the order index of the selected row item
	 * up or down based on the indexOperator.
	 *
	 * @async
	 * @param {CommonTableComponent} commonTableContext
	 * The common table context.
	 * @param {number} indexReference
	 * The index reference to add or substract to the current
	 * selected order index.
	 * @memberof EntityActionDefinitionComponent
	 */
	private async updateOrderIndex(
		commonTableContext: CommonTableComponent,
		indexReference: number): Promise<void>
	{
		const updateOrderIndex: Function = async() =>
		{
			commonTableContext.loadingTableDefinitions = true;

			const orderedRequisites =
				this.commonTableContext.source.virtualData
					.filter((data) => data !== undefined)
					.sort((a, b) => a.order - b.order);

			const neighborOrderIndex =
				this.findSelectedChildIndex(
					orderedRequisites,
					this.commonTableContext.source.rowData) + indexReference;

			await this.ruleDefinitionApiService
				.update(
					orderedRequisites[neighborOrderIndex].id,
					<IRuleDefinition>
					{
						id: orderedRequisites[neighborOrderIndex].id,
						entityTypeId: orderedRequisites[neighborOrderIndex]
							.entityTypeId,
						entityVersionId: orderedRequisites[neighborOrderIndex]
							.entityVersionId,
						name: orderedRequisites[neighborOrderIndex].name,
						displayName:
							orderedRequisites[neighborOrderIndex].displayName,
						description: orderedRequisites[neighborOrderIndex]
							.description,
						overridable: orderedRequisites[neighborOrderIndex]
							.overridable,
						order: 1000
					});

			await this.ruleDefinitionApiService
				.update(
					this.commonTableContext.source.rowData.id,
					<IRuleDefinition>
					{
						id: this.commonTableContext.source.rowData.id,
						entityTypeId: this.commonTableContext.source.rowData
							.entityTypeId,
						entityVersionId: this.commonTableContext.source.rowData
							.entityVersionId,
						name: this.commonTableContext.source.rowData.name,
						displayName:
							this.commonTableContext.source.rowData.displayName,
						description: this.commonTableContext.source.rowData
							.description,
						overridable: this.commonTableContext.source.rowData
							.overridable,
						order: orderedRequisites[neighborOrderIndex].order
					});

			await this.ruleDefinitionApiService
				.update(
					orderedRequisites[neighborOrderIndex].id,
					<IRuleDefinition>
					{
						id: orderedRequisites[neighborOrderIndex].id,
						entityTypeId: orderedRequisites[neighborOrderIndex]
							.entityTypeId,
						entityVersionId: orderedRequisites[neighborOrderIndex]
							.entityVersionId,
						name: orderedRequisites[neighborOrderIndex].name,
						displayName:
							orderedRequisites[neighborOrderIndex].displayName,
						description: orderedRequisites[neighborOrderIndex]
							.description,
						overridable: orderedRequisites[neighborOrderIndex]
							.overridable,
						order: this.commonTableContext.source.rowData.order
					});
		};

		await this.activityService.handleActivity(
			new Activity(
				updateOrderIndex(),
				'<strong>Updating Rule Definition Order</strong>',
				'<strong>Updated Rule Definition Order</strong>',
				'Rule Definition Order was successfully updated.',
				'Rule Definition Order was not updated.'),
			AppConstants.activityStatus.complete,
			true);

		this.restoreTableDefinition();
	}

	/**
	 * Finds the selected child index.
	 *
	 * @param {any[]} children
	 * The children object array.
	 * @param {any} selectedChild
	 * The selected child.
	 * @returns {number}
	 * The child index selected.
	 * @memberof EntityActionDefinitionComponent
	 */
	private findSelectedChildIndex(
		children: any[],
		selectedChild: any): number
	{
		for (let index = 0; index < children.length; index++)
		{
			if (children[index].id === selectedChild.id
				&& children[index].order === selectedChild.order)
			{
				return index;
			}
		}

		return -1;
	}
}