/**
 * @copyright WaterStreet. All rights reserved.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component
} from '@angular/core';
import {
	FieldType
} from '@ngx-formly/core';
import {
	IOperationDefinition
} from '@operation/interfaces/operation-definition.interface';
import {
	IOperationDefinitionDto
} from '@api/interfaces/operations/operation-definition.dto.interface';
import {
	IOperationGroupRelationship
} from '@operation/interfaces/operation-group-relationship.interface';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	OperationExecutionService
} from '@operation/services/operation-execution.service';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';

@Component({
	selector: 'custom-operation-button',
	templateUrl: './custom-operation-button.component.html',
	styleUrls: [
		'./custom-operation-button.component.scss'
	]
})

/**
 * A component representing an instance of a Custom Operation Button.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomOperationButtonComponent
 * @extends {FieldType}
 */
export class CustomOperationButtonComponent
	extends FieldType
{
	/** Initializes a new instance of the CustomOperationButtonComponent.
	 *
	 * @param {OperationService} operationService
	 * The operation service to use when loading operation group data.
	 * @param {OperationExecutionService} operationExecutionService
	 * The operation execution service to use when performing operation
	 * commands.
	 * @param {OperationDefinitionApiService} operationDefinitionsApiService
	 * The api service used to load operation definitions data.
	 * @memberof CustomOperationButtonComponent
	 */
	public constructor(
		public operationService: OperationService,
		public operationExecutionService: OperationExecutionService,
		public operationDefinitionApiService: OperationDefinitionApiService)
	{
		super();
	}

	/**
	 * Executes the operationName or operationPromise defined in the
	 * templateOptions for a custom-operation-button component.
	 *
	 * @param {Event} event
	 * The event passed from the operation button click event.
	 **/
	public async executeOperation(
		event: Event): Promise<void>
	{
		if (AnyHelper.isNullOrWhitespace(
			this.field.templateOptions.operationName))
		{
			const promise: Promise<any> =
				StringHelper.transformToLayoutPromise(
					this.field.templateOptions.operationPromise,
					this.field,
					event,
					this.field.templateOptions.context);

			await promise;

			return;
		}

		const operationDefinition: IOperationDefinitionDto =
			await this.operationDefinitionApiService
				.getSingleQueryResult(
					`${AppConstants.commonProperties.name} eq `
						+ `'${this.field.templateOptions.operationName}'`,
					`${AppConstants.commonProperties.id} `
						+ `${AppConstants.sortDirections.descending}`);

		const postOperationDefinition: IOperationDefinition =
			await this.operationService
				.populateOperationDefinition(
					<IOperationDefinition>operationDefinition,
					<IOperationGroupRelationship>{},
					this.field.templateOptions.context);

		await this.operationExecutionService
			.executeMappedOperation(
				postOperationDefinition);
	}
}