/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ClaimConstants
} from '@claims/constants/claims-constants';
import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Router
} from '@angular/router';
import {
	SessionService
} from '@shared/services/session.service';

/* eslint-enable max-len */

@Component({
	selector: 'create-claim',
	templateUrl: './create-claim.component.html',
	styleUrls: []
})

/**
 * A component representing a wizard step for creating a claim.
 *
 * @export
 * @class CreateClaimComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class CreateClaimComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the create claim component.
	 *
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes on entity creation.
	 * @param {EntityService} entityService
	 * The entity service used to lookup entity modules upon creation.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service used in this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @memberof CreateClaimComponent
	 */
	public constructor(
		public router: Router,
		public activityService: ActivityService,
		public moduleService: ModuleService,
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<
	 * 	DynamicWizardComponent,
	 * 	IWizardContext>}
	 * @memberof CreateClaimComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the formly layout used in implementing components.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof CreateClaimComponent
	 */
	public dynamicFormlyLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the claim entity type to be created.
	 *
	 * @type {IEntityType}
	 * @memberof CreateClaimComponent
	 */
	public claimEntityCreationType: IEntityType;

	/**
	 * Gets or sets the note claim entity type to be created.
	 *
	 * @type {IEntityType}
	 * @memberof CreateClaimComponent
	 */
	public noteClaimEntityCreationType: IEntityType;

	/**
	 * Gets or sets the context active menu item current data collected on the
	 * dynamic wizard steps.
	 *
	 * @type {any}
	 * @memberof CreateClaimComponent
	 */
	private currentData: any;

	/**
	 * Gets or sets parameters for api request.
	 *
	 * @type {object}
	 * @memberof CreateClaimComponent
	 */
	private parameters: object;

	/**
	 * Gets or sets the claim id.
	 *
	 * @type {number}
	 * @memberof CreateClaimComponent
	 */
	private claimId: number;

	/**
	 * Implements the on initialization interface.
	 *
	 * @memberof CreateClaimComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.currentData =
			this.context.source.activeMenuItem.currentData;

		this.claimEntityCreationType =
			await this.entityTypeApiService
				.getSingleQueryResult(
					`Group eq '${ClaimConstants.claimEntityTypeGroups.claims}'`
						+ ' AND Name eq '
						+ `'${ClaimConstants.claimEntityTypes.claim}'`,
					AppConstants.empty);

		this.noteClaimEntityCreationType =
			await this.entityTypeApiService
				.getSingleQueryResult(
					'Group eq '
						+ `'${ClaimConstants.claimEntityTypeGroups.noteClaims}'`
						+ ' AND Name eq '
						+ `'${ClaimConstants.claimEntityTypes.noteClaim}'`,
					AppConstants.empty);

		this.context.source.addToNext(this.create.bind(this));

		await this.performPostInitActions();

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * Handles the validity changed event sent from the child dynamic
	 * formly component. This will update the validity of the form for
	 * action buttons.
	 *
	 * @param {boolean} isValid
	 * The validity of the current displayed step data set.
	 * @memberof CreateClaimComponent
	 */
	public async validityChanged(
		isValid: boolean): Promise<void>
	{
		this.context.source.validStepChanged(isValid);
	}

	/**
	 * This will send the claim create event and navigate to the new
	 * entity.
	 *
	 * @async
	 * @memberof CreateClaimComponent
	 */
	public async create(): Promise<void>
	{
		const insuranceCompanyId = this.currentData.data.insuranceCompanyId;

		const claimData: IEntityInstance =
			this.createClaimData(this.currentData.data);

		const noteClaimData: IEntityInstance =
			AnyHelper.isNullOrWhitespace(this.currentData.data.note)
				? null
				: this.createNoteClaimData(this.currentData.data);

		if (!AnyHelper.isNullOrEmpty(this.currentData
			.data?.policy?.system)
				&& !AnyHelper.isNullOrEmpty(this.currentData
					.data?.policy?.identifier))
		{
			this.parameters =
				{
					system: this.currentData.data.policy.system,
					identifier: this.currentData.data.policy.identifier,
					date: this.currentData.data.claim.keyDates.lossDate
				};
		}

		this.claimId =
			await this.activityService.handleActivity<number>(
				new Activity<number>(
					this.createEntity(
						this.claimEntityCreationType,
						claimData,
						ClaimConstants.serviceProviderEntityTypeGroups
							.insuranceCompany,
						insuranceCompanyId,
						this.parameters),
					'<strong>Creating Claim</strong>',
					'<strong>Claim Created</strong>',
					'Claim has been created.',
					'Claim has not been created.'));

		if (!AnyHelper.isNull(noteClaimData)
			&& this.claimId)
		{
			await this.createEntity(
				this.noteClaimEntityCreationType,
				noteClaimData,
				ClaimConstants.claimEntityTypeGroups.claims,
				this.claimId,
				{});
		}

		await this.navigateToClaimSummary(
			this.claimId,
			ClaimConstants.claimEntityTypeGroups.claims);
	}

	/**
	 * Creates the claim entity intance data based on the collected
	 * data. This data will be saved on the creation process.
	 *
	 * @param {any} currentData
	 * The claim wizard data.
	 * @memberof CreateClaimComponent
	 */
	private createClaimData(
		currentData: any): IEntityInstance
	{
		const claimData: IEntityInstance =
			<IEntityInstance>
			{
				id: 0,
				entityType: ClaimConstants.claimEntityTypes.claim,
				versionNumber: null,
				data: currentData.claim
			};

		return claimData;
	}

	/**
	 * Creates the note.claim entity intance data based on the collected
	 * data. This data will be saved on the creation process.
	 *
	 * @param {any} currentData
	 * The claim wizard data.
	 * @memberof CreateClaimComponent
	 */
	private createNoteClaimData(
		currentData: any): IEntityInstance
	{
		const noteClaimData: IEntityInstance =
			<IEntityInstance>
			{
				id: 0,
				entityType: ClaimConstants.claimEntityTypes.noteClaim,
				versionNumber: null,
				data: currentData.note
			};

		return noteClaimData;
	}

	/**
	 * This will send the entity creation event.
	 *
	 * @param {IEntityType} entityCreationType
	 * The entity type to be created.
	 * @param {IEntityInstance} entityInstanceData
	 *  The entity instance data to be saved.
	 * @async
	 * @memberof CreateClaimComponent
	 */
	private async createEntity(
		entityCreationType: IEntityType,
		entityInstanceData: IEntityInstance,
		entityParentTypeGroup: string,
		entityParentId: number,
		parameters: object): Promise<number>
	{
		setTimeout(
			() =>
			{
				this.context.source.wizardStepLoading = true;
			});

		const newEntityId: Promise<number> =
			this.createEntityInstance(
				entityCreationType,
				entityInstanceData,
				entityParentTypeGroup,
				entityParentId,
				parameters);

		return newEntityId;
	}

	/**
	 * Creates an entity instance and all entity relationships.
	 *
	 * @param {IEntityType} entityCreationType
	 * The entity type to be created.
	 * @param {IEntityInstance} entityInstanceData
	 *  The entity instance data to be saved.
	 * @returns {Promise<number>}
	 * The id of the newly created entity.
	 * @memberof CreateClaimComponent
	 */
	private async createEntityInstance(
		entityCreationType: IEntityType,
		entityInstanceData: IEntityInstance,
		entityParentTypeGroup: string,
		entityParentId: number,
		parameters: object): Promise<number>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup =
				entityCreationType.group;

		const createdEntityId: number =
			await this.entityInstanceApiService
				.createEntityInstance(
					entityInstanceData,
					entityParentTypeGroup,
					entityParentId,
					parameters);

		return createdEntityId;
	}

	/**
	 * This will navigate to the claim summary dashboard the claim id
	 * provided.
	 *
	 * @async
	 * @param {number} entityId
	 * The claim entity id to navigate.
	 * @param {string} group
	 * The entity group associated to the navigation.
	 * @memberof CreateClaimComponent
	 */
	private async navigateToClaimSummary(
		entityId: number,
		group: string)
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				group,
				AppConstants.viewTypes.edit,
				entityId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}

	/**
	 * Handles the post initialization action.
	 * This will create the dynamic formly layout for display component creation
	 * and permissions.
	 *
	 * @memberof CreateClaimComponent
	 */
	private async performPostInitActions(): Promise<void>
	{
		this.dynamicFormlyLayout =
			<FormlyFieldConfig[]>
			[
				<FormlyFieldConfig>
				{
					key: 'data.note.content',
					type: FormlyConstants.customControls.customTextArea,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						description: 'Intake notes on claim.',
						placeholder: 'Intake Notes',
						rows: 15,
						gridColumns: 12
					}
				}
			];
	}
}