/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ActivatedRoute,
	Params,
	Router
} from '@angular/router';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
} from '@angular/core';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityManagerDirective
} from '@admin/directives/entity-manager.directive';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IRuleDefinitionDto
} from '@api/interfaces/rules/rule-definition.dto.interface';
import {
	IRuleViolationWorkflowActionDefinition
} from '@shared/interfaces/rules/rule-violation-workflow-action-definition.interface';
import {
	ISecurityGroupRuleDefinitionViolationOverride
} from '@shared/interfaces/security/security-group-rule-definition-violation-override.interface';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	OptionsFactory
} from '@shared/factories/options-factory';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	RuleDefinitionApiService
} from '@api/services/rules/rule-definition.api.service';
import {
	RuleViolationWorkflowActionDefinitionApiService
} from '@api/services/rules/rule-violation-workflow-action-definition.api.service';
import {
	SecurityGroupRuleDefinitionViolationOverrideApiService
} from '@api/services/security/security-group-rule-definition-violation-override.api.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	WorkflowActionDefinitionsApiService
} from '@api/services/workflow/workflow-action-definitions.api.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-entity-rule-workflow',
	templateUrl: './entity-rule-workflow.component.html',
	styleUrls: [
		'./entity-rule-workflow.component.scss'
	]
})

/**
 * A component representing an instance of the entity rule workflow
 * component.
 *
 * @export
 * @class EntityRuleWorkflowComponent
 * @extends {EntityManagerDirective}
 */
export class EntityRuleWorkflowComponent
	extends EntityManagerDirective
{
	/**
	 * Creates an instance of an EntityRuleWorkflowComponent.
	 *
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to get the entity type data.
	 * @param {EntityDefinitionApiService} entityDefinitionApiService
	 * The api service used to get the entity definition data.
	 * @param {RuleDefinitionApiService} ruleDefinitionApiService
	 * The api service used to get rule definition data.
	 * @param {RuleViolationWorkflowActionDefinitionApiService}
	 * ruleViolationWorkflowActionDefinitionApiService
	 * The api service used to get rule violation workflowAction definition
	 * data.
	 * @param {WorkflowActionDefinitionsApiService}
	 * workflowActionDefinitionApiService
	 * The api service used to get workflow action definition data.
	 * @param {SecurityGroupRuleDefinitionViolationOverrideApiService}
	 * securityGroupRuleViolationOverrideApiService
	 * The service used to get Security Group Rule Definition
	 * Violation Override data.
	 * @param {OptionsFactory} optionsFactory
	 * The options factory used for common dropdown options.
	 * @param {SiteLayoutService} siteLayoutService
	 * The service used to get the site layout data.
	 * @param {ActivatedRoute} route
	 * The activated route that opened this component.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof EntityRuleWorkflowComponent
	 */
	public constructor(
		public entityTypeApiService: EntityTypeApiService,
		public entityDefinitionApiService: EntityDefinitionApiService,
		public ruleDefinitionApiService: RuleDefinitionApiService,
		public ruleViolationWorkflowActionDefinitionApiService:
			RuleViolationWorkflowActionDefinitionApiService,
		public workflowActionDefinitionApiService:
			WorkflowActionDefinitionsApiService,
		public securityGroupRuleViolationOverrideApiService:
			SecurityGroupRuleDefinitionViolationOverrideApiService,
		public optionsFactory: OptionsFactory,
		public siteLayoutService: SiteLayoutService,
		public route: ActivatedRoute,
		public router: Router,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(
			route,
			activityService,
			resolver);
	}

	/**
	 * Gets or sets the rule definition.
	 *
	 * @type {IRuleDefinitionDto}
	 * @memberof EntityRuleWorkflowComponent
	 */
	public ruleDefinition: IRuleDefinitionDto;

	/**
	 * Gets or sets the rule violation workflow action definition.
	 *
	 * @type {IRuleViolationWorkflowActionDefinition}
	 * @memberof EntityRuleWorkflowComponent
	 */
	public ruleViolationWorkflowActionDefinition:
		IRuleViolationWorkflowActionDefinition;

	/**
	 * Gets or sets the rule workflow definition id.
	 *
	 * @type {number}
	 * @memberof EntityRuleWorkflowComponent
	 */
	public ruleWorkflowDefinitionId: number;

	/**
	 * Gets or sets the rule action type options.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof EntityRuleWorkflowComponent
	 */
	public ruleActionTypeOptions: IDropdownOption[];

	/**
	 * Gets or sets the security group options.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof EntityRuleWorkflowComponent
	 */
	public securityGroupOptions: IDropdownOption[];

	/**
	 * Gets or sets the security group rule definition violation override.
	 *
	 * @type {ISecurityGroupRuleDefinitionViolationOverride[]}
	 * @memberof EntityRuleWorkflowComponent
	 */
	public securityGroupRuleDefinitionViolationOverride:
		ISecurityGroupRuleDefinitionViolationOverride[];

	/**
	 * Gets or sets the security groups.
	 *
	 * @type {number[]}
	 * @memberof EntityRuleWorkflowComponent
	 */
	public securityGroups: number[] = [];

	/**
	 * Sets the rule definition id query parameter.
	 *
	 * @type {string}
	 * @memberof EntityRuleWorkflowComponent
	 */
	private readonly ruleWorkflowDefinitionIdQueryParameter:
		string =
		AppConstants.commonProperties.ruleWorkflowDefinitionId;

	/**
	 * Sets the context data required for this component.
	 *
	 * @async
	 * @memberof EntityRuleWorkflowComponent
	 */
	public async setContextData(): Promise<void>
	{
		this.subscriptions.add(
			this.route.queryParams.subscribe((parameters: Params) =>
			{
				const mappedRouteData: any =
						ObjectHelper.mapFromRouteData(
							parameters);

				this.ruleWorkflowDefinitionId =
						mappedRouteData[
							this.ruleWorkflowDefinitionIdQueryParameter];
			}));

		this.entityDefinitionId = this.route.snapshot.paramMap.get(
			AppConstants.commonProperties.id) as unknown as number;

		this.entityDefinition =
			await this.entityDefinitionApiService
				.get(this.entityDefinitionId);

		this.entityType =
			await this.entityTypeApiService
				.get(this.entityDefinition.typeId);

		this.ruleViolationWorkflowActionDefinition =
			<IRuleViolationWorkflowActionDefinition>
			await this.ruleViolationWorkflowActionDefinitionApiService
				.get(this.ruleWorkflowDefinitionId);

		this.ruleDefinition =
			await this.ruleDefinitionApiService
				.get(this.ruleViolationWorkflowActionDefinition.definitionId);

		this.ruleActionTypeOptions = [
			{
				label: AppConstants.ruleActionTypeNames.blocked,
				value: AppConstants.ruleActionTypes.blocked
			},
			{
				label: AppConstants.ruleActionTypeNames.continue,
				value: AppConstants.ruleActionTypes.continue
			}
		];

		this.securityGroupOptions =
			await this.optionsFactory.getSecurityGroupOptions();

		this.securityGroups = [];

		this.securityGroupRuleDefinitionViolationOverride =
			await this.securityGroupRuleViolationOverrideApiService
				.query('ruleDefinitionId eq '
					+ this.ruleViolationWorkflowActionDefinition.definitionId
					+ ' and workflowActionDefinitionId eq'
					+ ` ${this.ruleViolationWorkflowActionDefinition
						.workflowActionDefinitionId}`,
				AppConstants.empty);

		this.securityGroupRuleDefinitionViolationOverride
			.forEach((securityRuleViolation) =>
			{
				this.securityGroups.push(securityRuleViolation.groupId);
			});

		this.contextData = {
			data: {
				id: this.ruleViolationWorkflowActionDefinition.id,
				actionTypeId: this.ruleViolationWorkflowActionDefinition
					.actionTypeId,
				workflowActionDefinitionName:
					(await this.workflowActionDefinitionApiService
						.get(this.ruleViolationWorkflowActionDefinition
							.workflowActionDefinitionId)).name,
				workflowActionDefinitionId:
					this.ruleViolationWorkflowActionDefinition
						.workflowActionDefinitionId,
				security: this.securityGroups
			}
		};

		this.saveTitle = 'Rule Definition';
		this.saveContent = `${this.ruleDefinition.name} Rule Definition`;
	}

	/**
	 * Excecutes the save action.
	 *
	 * @async
	 * @memberof EntityRuleWorkflowComponent
	 */
	public async saveAction(): Promise<void>
	{
		const ruleWorkflowDataObject: IRuleViolationWorkflowActionDefinition =
			<IRuleViolationWorkflowActionDefinition>
			{
				id: this.ruleViolationWorkflowActionDefinition.id,
				definitionId:
					this.ruleViolationWorkflowActionDefinition
						.definitionId,
				actionTypeId: this.contextData.data.actionTypeId,
				workflowActionDefinitionId:
					this.ruleViolationWorkflowActionDefinition
						.workflowActionDefinitionId,
			};

		await this.ruleViolationWorkflowActionDefinitionApiService
			.update(
				this.ruleViolationWorkflowActionDefinition.id,
				ruleWorkflowDataObject);

		// Update Override Permissions if applicable
		if (this.contextData.data.security.length !==
			this.securityGroups.length)
		{
			if (this.securityGroups.length >
				this.contextData.data.security.length)
			{
				for (let securityGroupIndex = 0;
					securityGroupIndex < this.securityGroups.length;
					securityGroupIndex++)
				{
					let existingGroup = false;
					for (const securityRule of this.contextData.data.security)
					{
						if (securityRule ===
							this.securityGroups[securityGroupIndex])
						{
							existingGroup = true;
						}
					}

					if (existingGroup === false)
					{
						await this.securityGroupRuleViolationOverrideApiService
							.delete(
								await this.getSecurityRuleViolationOverrideId(
									securityGroupIndex));
					}
				}
			}
			else
			{
				for (const securityRule of this.contextData.data.security)
				{
					let existingGroup = false;

					for (const securityGroup of this.securityGroups)
					{
						if (securityRule === securityGroup)
						{
							existingGroup = true;
						}
					}

					if (existingGroup === false)
					{
						const securityRuleViolationOverrideDataObject:
							ISecurityGroupRuleDefinitionViolationOverride =
							<ISecurityGroupRuleDefinitionViolationOverride>
							{
								groupId: securityRule,
								ruleDefinitionId: this.ruleDefinition.id,
								workflowActionDefinitionId:
									this.ruleViolationWorkflowActionDefinition
										.workflowActionDefinitionId
							};

						await this.securityGroupRuleViolationOverrideApiService
							.create(securityRuleViolationOverrideDataObject);
					}
				}
			}
		}
		else
		{
			for (let index = 0;
				index < this.securityGroups.length;
				index++)
			{
				await this.securityGroupRuleViolationOverrideApiService
					.delete(
						await this.getSecurityRuleViolationOverrideId(index));
			}

			for (const securityRule of this.contextData.data.security)
			{
				const securityRuleViolationOverrideDataObject:
					ISecurityGroupRuleDefinitionViolationOverride =
					<ISecurityGroupRuleDefinitionViolationOverride>
					{
						groupId: securityRule,
						ruleDefinitionId: this.ruleDefinition.id,
						workflowActionDefinitionId:
							this.ruleViolationWorkflowActionDefinition
								.workflowActionDefinitionId
					};

				await this.securityGroupRuleViolationOverrideApiService
					.create(securityRuleViolationOverrideDataObject);
			}
		}
	}

	/**
	 * Sets the formly layout fields.
	 *
	 * @async
	 * @memberof EntityRuleWorkflowComponent
	 */
	public async setLayoutFields(): Promise<void>
	{
		this.layoutFields =
			<FormlyFieldConfig[]>
			[
				{
					key: 'data.workflowActionDefinitionName',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Action',
						disabled: true
					}
				},
				{
					key: 'data.actionTypeId',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Type',
						required: true,
						options: this.ruleActionTypeOptions
					}
				},
				{
					key: 'data.security',
					type: FormlyConstants.customControls.customMultiSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Override Permissions',
						options: this.securityGroupOptions
					}
				}
			];
	}

	/**
	 * gets the security rule violation override id.
	 *
	 * @async
	 * @param {number} securityGroupIndex
	 * The security group index.
	 * @returns {Promise<number>}
	 * The security rule violation override id.
	 * @memberof EntityRuleWorkflowComponent
	 */
	public async getSecurityRuleViolationOverrideId(
		securityGroupIndex: number): Promise<number>
	{
		const securityGroupRuleDefinitionViolationOverride:
			ISecurityGroupRuleDefinitionViolationOverride =
			await this.securityGroupRuleViolationOverrideApiService
				.getSingleQueryResult(
					'groupId eq'
						+ ` ${this.securityGroups[securityGroupIndex]}`
						+ ' and ruleDefinitionId eq'
						+ ` ${this.ruleDefinition.id}`
						+ ' and workflowActionDefinitionId eq'
						+ ` ${this.ruleViolationWorkflowActionDefinition
							.workflowActionDefinitionId}`,
					AppConstants.empty);

		return securityGroupRuleDefinitionViolationOverride.id;
	}
}