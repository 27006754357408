/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonFormlyFieldConstants
} from '@shared/constants/common-formly-field-constants';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	Component
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	FormControl
} from '@angular/forms';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IOperationDefinition
} from '@operation/interfaces/operation-definition.interface';
import {
	IOperationType
} from '@operation/interfaces/operation-type.interface';
import {
	ISecurityGroup
} from '@shared/interfaces/security/security-group.interface';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	OperationDefinitionComponent
} from '@admin/components/user-interface/operations/operation-definitions/operation-expand/operation-definition.component';
import {
	OperationDefinitionParameterApiService
} from '@api/services/operations/operation-definition-parameter.api.service';
import {
	OperationGroupApiService
} from '@api/services/operations/operation-group.api.service';
import {
	OperationTypeApiService
} from '@api/services/operations/operation-type.api.service';
import {
	OperationTypeParameterApiService
} from '@api/services/operations/operation-type-parameter.api.service';
import {
	OptionsFactory
} from '@shared/factories/options-factory';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SecurityGroupApiService
} from '@api/services/security/security-group.api.service';
import {
	TableHelper
} from '@shared/helpers/table.helper';

/* eslint-enable max-len */

@Component({
	selector: 'app-operation-definitions',
	templateUrl: './operation-definitions.component.html',
	styleUrls: ['./operation-definitions.component.scss']
})

/**
 * A component representing an instance of the operation definitions component.
 *
 * @export
 * @class OperationDefinitionsComponent
 * @extends {CommonTablePageDirective}
 */
export class OperationDefinitionsComponent
	extends CommonTablePageDirective
{
	/**
	 * Initializes a new instance of the logs component and sets up
	 * the list columns to be displayed in the logs table.
	 *
	 * @param {OperationDefinitionApiService} operationDefinitionsApiService
	 * The api service used to load operation definitions data.
	 * @param {OperationTypeApiService} operationTypeApiService
	 * The api service used to load operation type data.
	 * @param {SecurityGroupApiService} securityGroupApiService
	 * The api service used to load the security group data.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The api service used to load the entity instance data.
	 * @param {OperationTypeParameterApiService}
	 * operationTypeParameterApiService
	 * The api service used to load the operation type parameter data.
	 * @param {OperationDefinitionParameterApiService}
	 * 	operationDefinitionParameterApiService
	 * The api service used to load the operation definition parameter data.
	 * @param {OperationGroupApiService} operationGroupApiService
	 * The api service used to load the operation group data.
	 * @param {OptionsFactory} optionsFactory
	 * The options factory used for common dropdown options.
	 * @param {ActivityService} activityService
	 * The activity service to be displayed in the Activity List Component.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof OperationDefinitionsComponent
	 */
	public constructor(
		public operationDefinitionsApiService: OperationDefinitionApiService,
		public operationTypeApiService: OperationTypeApiService,
		public securityGroupApiService: SecurityGroupApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public operationTypeParameterApiService:
			OperationTypeParameterApiService,
		public operationDefinitionParameterApiService:
			OperationDefinitionParameterApiService,
		public operationGroupApiService: OperationGroupApiService,
		public optionsFactory: OptionsFactory,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(resolver);
		this.entityInstanceApiService.entityInstanceTypeGroup = 'Systems';
	}

	/**
	 * Gets or sets the table definitions for the standard table view.
	 *
	 * @type {ICommonTable}
	 * @memberof OperationDefinitionsComponent
	 */
	public operationDefinitionsTableDefinitions: ICommonTable;

	/**
	 * Gets or sets the security group options.
	 *
	 * @type {object[]}
	 * @memberof OperationDefinitionsComponent
	 */
	public securityGroupOptions: object[] = [];

	/**
	 * Gets or sets the operation type options.
	 *
	 * @type {object[]}
	 * @memberof OperationDefinitionsComponent
	 */
	public operationTypeOptions: object[] = [];

	/**
	 * Gets or sets the security groups from database.
	 *
	 * @type {any[]}
	 * @memberof OperationDefinitionsComponent
	 */
	public securityGroups: ISecurityGroup[];

	/**
	 * Gets or sets the operation Types from database.
	 *
	 * @type {any[]}
	 * @memberof OperationDefinitionsComponent
	 */
	public operationTypes: IOperationType[];

	/**
	 * Gets the field value debounce time.
	 *
	 * @type {number}
	 * @memberof OperationDefinitionsComponent
	 */
	private readonly fieldValueDebounceTime: number = 2000;

	/**
	 * Sets up variables used in this admin page based table.
	 *
	 * @memberof OperationDefinitionsComponent
	 */
	public setupPageVariables(): void
	{
		let displayOrder: number = 1;
		this.availableColumns =
			[
				{
					dataKey: 'id',
					columnHeader: 'Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'typeId',
					columnHeader: 'Type Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'label',
					columnHeader: 'Label',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'description',
					columnHeader: 'Description',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'icon',
					columnHeader: 'Icon',
					displayOrder: displayOrder
				}
			];
		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Sets up the list column definitions for the current
	 * operation definition object list.
	 *
	 * @async
	 * @memberof OperationDefinitionsComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		const securityGroupOptions: IDropdownOption[] =
			await this.optionsFactory.getSecurityGroupOptions();

		this.operationDefinitionsTableDefinitions = {
			actions: {
				create: {
					layout: [
						{
							key: 'data.name',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Name',
								required: true,
								maxLength: 64
							},
							asyncValidators: {
								uniqueName: {
									expression: async(control: FormControl) =>
										this.isExistingName(control),
									message: 'Existing definition Name.'
								}
							}
						},
						{
							key: 'data.typeId',
							type: FormlyConstants.customControls.customSelect,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Type',
								required: true,
								placeholder: 'Select an Operation Type',
								options: this.operationTypeOptions,
								showClear: true
							}
						},
						{
							key: 'data.label',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Label',
								maxLength: 64
							}
						},
						{
							key: 'data.description',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Description',
								required: true,
								maxLength: 256
							}
						},
						{
							key: 'data.icon',
							type: FormlyConstants.customControls
								.customIconInput,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Icon',
								externalLinkTooltip: 'Font Awesome Icons',
								externalLink: AppConstants
									.externalUrls.fontAwesomeIcons,
								maxLength: 256
							}
						},
						{
							key: 'data.securityGroups',
							type: FormlyConstants.customControls
								.customMultiSelect,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Security Groups',
								placeholder: 'Select a Security Group',
								required: true,
								options: this.securityGroupOptions
							}
						},
						CommonFormlyFieldConstants.publicField,
						{
							...CommonFormlyFieldConstants
								.ownershipSecurityGroupField,
							templateOptions: {
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField
									.templateOptions,
								options: securityGroupOptions
							}
						}
					],
					items: [
						{
							label: 'Create',
							icon: AppConstants.empty,
							id: 'createOperationDefinition',
							styleClass: 'ui-button-primary',
							command: () => this.createOperationDefinition()
						}]
				},
				view: {
					component: OperationDefinitionComponent,
					layout: [
						{
							key: 'data.name',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Name',
								disabled: true
							}
						},
						{
							key: 'data.typeId',
							type: FormlyConstants.customControls.customSelect,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Type',
								options: this.operationTypeOptions,
								disabled: true
							}
						},
						{
							key: 'data.label',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Label',
								disabled: true
							}
						},
						{
							key: 'data.description',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Description',
								disabled: true
							}
						},
						{
							key: 'data.icon',
							type: FormlyConstants.customControls
								.customIconInput,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Icon',
								disabled: true
							}
						},
						{
							key: 'data.securityGroups',
							type: FormlyConstants.customControls
								.customMultiSelect,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Security Groups',
								options: this.securityGroupOptions,
								disabled: true
							}
						},
						{
							...CommonFormlyFieldConstants
								.publicField,
							templateOptions: {
								...CommonFormlyFieldConstants
									.publicField
									.templateOptions,
								disabled: true
							}
						},
						{
							...CommonFormlyFieldConstants
								.ownershipSecurityGroupField,
							templateOptions: {
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField
									.templateOptions,
								options: securityGroupOptions,
								disabled: true
							}
						}

					],
					items: []
				},
				update: {
					component: OperationDefinitionComponent,
					layout: [
						{
							key: 'data.name',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Name',
								required: true,
								maxLength: 64
							},
							asyncValidators: {
								uniqueName: {
									expression: async(control: FormControl) =>
										this.isExistingName(control),
									message: 'Existing definition Name.'
								}
							}
						},
						{
							key: 'data.typeId',
							type: FormlyConstants.customControls.customSelect,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Type',
								required: true,
								placeholder: 'Select an Operation Type',
								options: this.operationTypeOptions,
								showClear: true
							},
							asyncValidators: {
								validTypeId: {
									expression: (control: FormControl) =>
										this.allowedToChange(control),
									message: 'Check parameter association.'
								}
							}
						},
						{
							key: 'data.label',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Label',
								maxLength: 64
							}
						},
						{
							key: 'data.description',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Description',
								required: true,
								maxLength: 256
							}
						},
						{
							key: 'data.icon',
							type: FormlyConstants.customControls
								.customIconInput,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Icon',
								externalLinkTooltip: 'Font Awesome Icons',
								externalLink: AppConstants
									.externalUrls.fontAwesomeIcons,
								maxLength: 256
							}
						},
						{
							key: 'data.securityGroups',
							type: FormlyConstants.customControls
								.customMultiSelect,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Security Groups',
								placeholder: 'Select a Security Group',
								required: true,
								options: this.securityGroupOptions
							}
						},
						CommonFormlyFieldConstants.publicField,
						{
							...CommonFormlyFieldConstants
								.ownershipSecurityGroupField,
							templateOptions: {
								...CommonFormlyFieldConstants
									.ownershipSecurityGroupField
									.templateOptions,
								options: securityGroupOptions
							}
						}
					],
					items: [
						{
							label: 'Save',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: () => this.updateOperationDefinition()
						}]
				},
				delete: {
					items: [
						{
							label: 'Confirm',
							styleClass: AppConstants.cssClasses.pButtonDanger,
							disabled: false,
							command: async() => this.deleteOperationDefinition()
						}],
					deleteStatement: () => this.deleteStatementLogic()
				}
			},
			tableTitle: 'Operations',
			objectSearch: {
				filter: AppConstants.empty,
				orderBy: `Id ${AppConstants.sortDirections.descending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: this.tableRowCount
			},
			dataSetup: async () =>
			{
				if (this.securityGroupOptions.length === 0)
				{
					this.securityGroups =
						await this.securityGroupApiService
							.query(
								AppConstants.empty,
								AppConstants.empty);

					this.securityGroups.forEach(
						(securityGroup) =>
						{
							this.securityGroupOptions
								.push(
									{
										value: securityGroup.id,
										label: securityGroup.name
									});
						});
				}

				if (this.operationTypeOptions.length === 0)
				{
					this.operationTypes =
						await this.operationTypeApiService
							.query(
								AppConstants.empty,
								AppConstants.empty);

					this.operationTypes.forEach(
						(operationType) =>
						{
							this.operationTypeOptions
								.push(
									{
										value: operationType.id,
										label: operationType.name
									});
						});
				}
			},
			apiPromise: (objectSearch: IObjectSearch) =>
				this.operationDefinitionsApiService
					.query(
						objectSearch.filter,
						objectSearch.orderBy,
						objectSearch.offset,
						objectSearch.limit),
			availableColumns: this.availableColumns,
			selectedColumns: this.selectedColumns,
			columnSelectionMode: this.columnSelectionMode,
			expandTitle: () =>
				TableHelper.getExpandTitle(
					this.commonTableContext,
					'Operation Definition'),
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			rowCountChanged: (rowCount: number) =>
			{
				this.tableRowCount = rowCount;
				this.restoreTableDefinition();
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.operationDefinitionsTableDefinitions.selectedColumns =
					selectedColumns;
				this.selectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.operationDefinitionsTableDefinitions.columnSelectionMode =
					columnSelectionMode;
				this.columnSelectionMode = columnSelectionMode;
			}
		};
		this.loadingTableDefinitions = false;
	}

	/**
	 * Returns a delete statement string
	 * based on the operation groups and
	 * parameters associated to an operation definition.
	 *
	 * @async
	 * @returns {Promise<string>}
	 * The delete statement to be displayed on te component.
	 * @memberof OperationDefinitionsComponent
	 */
	private async deleteStatementLogic(): Promise<string>
	{
		const displayParameters = [];
		const operationTypeParameters =
			await this.operationTypeParameterApiService.query(
				`typeId eq ${this.commonTableContext.source.rowData.typeId}`,
				AppConstants.empty);

		let operationDefinitionParameters = [];

		for (const operationTypeParameter of operationTypeParameters)
		{
			operationDefinitionParameters =
			[
				...await this.operationDefinitionParameterApiService
					.query(
						`typeParameterId eq ${operationTypeParameter.id}
							and definitionId eq ${this.commonTableContext
		.source.rowData.id}`,
						AppConstants.empty)
			];

			operationDefinitionParameters
				.forEach((operationDefinitionParameter) =>
				{
					displayParameters.unshift({
						id: operationDefinitionParameter.id,
						name: operationTypeParameter.name,
						value: operationDefinitionParameter.value});
				});
		}

		const childOperationGroupHierarchies =
			await this.operationGroupApiService
				.query(
					'ChildOperationGroupHierarchies.Any(ChildDefinitionId eq ' +
						this.commonTableContext.source.rowData.id + ')',
					AppConstants.empty);

		let deleteStatement: string;
		if (displayParameters.length > 0
			|| childOperationGroupHierarchies.length > 0)
		{
			this.operationDefinitionsTableDefinitions
				.actions.delete.items[1].disabled = true;

			deleteStatement =
				`Not allowed to delete Operation Definitions with
					associated Operation Groups or Parameters.
					Removed them first.`;
		}
		else
		{
			this.operationDefinitionsTableDefinitions
				.actions.delete.items[1].disabled = false;

			const operationDefinition: IOperationDefinition[] =
				<IOperationDefinition[]>
				await this.operationDefinitionsApiService
					.query(
						`Id eq ${this.commonTableContext.source.rowData.id}`,
						AppConstants.empty);

			deleteStatement =
				`Confirm you are about to delete Operation Definition
					${this.commonTableContext.source.rowData.id}
					${operationDefinition[0].name}.`;
		}

		EventHelper.dispatchTableExpansionPanelLoadedEvent();

		return deleteStatement;
	}

	/**
	 * Deletes an operation definition.
	 *
	 * @async
	 * @memberof OperationDefinitionsComponent
	 */
	private async deleteOperationDefinition(): Promise<void>
	{
		await this.activityService.handleActivity(
			new Activity(
				this.operationDefinitionsApiService
					.delete(this.commonTableContext.source.rowData.id),
				'<strong>Deleting Operation Definition</strong>',
				'<strong>Deleted Operation Definition</strong>',
				`Operation Definition ${this.commonTableContext
					.source.rowData.id} ` +
					'was deleted.',
				`Operation Definition ${this.commonTableContext
					.source.rowData.id} ` +
					'was not deleted.'));

		this.restoreTableDefinition();
	}

	/**
	 * Updates an operation definition.
	 *
	 * @async
	 * @memberof OperationDefinitionsComponent
	 */
	private async updateOperationDefinition(): Promise<void>
	{
		const updateOperationDefinition = async () =>
		{
			const rowData: any = this.commonTableContext.source.rowData;
			const operationDefinitionData: IOperationDefinition =
				<IOperationDefinition>
				{
					id: rowData.id,
					name: rowData.name,
					description: rowData.description,
					label: rowData.label,
					icon: rowData.icon,
					typeId: rowData.typeId,
					public: rowData.public,
					ownershipSecurityGroupId: rowData.ownershipSecurityGroupId,
					createdById: rowData.createdById
				};

			await this.operationDefinitionsApiService
				.update(
					this.commonTableContext.source.rowData.id,
					operationDefinitionData);

			const mappedSecurityGroups: number[] =
				await this.operationDefinitionsApiService
					.getSecurityGroups(
						this.commonTableContext.source.rowData.id);

			mappedSecurityGroups.sort(
				(itemOne: number,
					itemTwo: number) =>
					(itemOne - itemTwo));

			this.commonTableContext.source.rowData
				.securityGroups.sort(
					(itemOne: number,
						itemTwo: number) =>
						(itemOne - itemTwo));

			if (mappedSecurityGroups !==
				this.commonTableContext.source.rowData.securityGroups)
			{
				for (const mappedSecurityGroup of mappedSecurityGroups)
				{
					await this.operationDefinitionsApiService
						.deleteSecurityGroupMap(
							this.commonTableContext.source.rowData.id,
							mappedSecurityGroup);
				}

				for (const securityGroup of
					this.commonTableContext.source.rowData.securityGroups)
				{
					await this.operationDefinitionsApiService
						.createSecurityGroupMap(
							this.commonTableContext.source.rowData.id,
							securityGroup);
				}
			}
		};

		await this.activityService.handleActivity(
			new Activity(
				updateOperationDefinition(),
				'<strong>Updating Operation Definition</strong>',
				'<strong>Updated Operation Definition</strong>',
				'Operation Definition '
					+ `${this.commonTableContext.source.rowData.id} `
					+ 'was updated.',
				'Operation Definition '
					+ `${this.commonTableContext.source.rowData.id} `
					+ 'was not updated.'),
			AppConstants.activityStatus.complete,
			true);
	}

	/**
	 * Creates an operation definition.
	 *
	 * @async
	 * @memberof OperationDefinitionsComponent
	 */
	private async createOperationDefinition(): Promise<void>
	{
		const createOperationDefinition =
			async () =>
			{
				const rowData: any = this.commonTableContext.source.rowData;
				const operationDefinitionData: IOperationDefinition =
					<IOperationDefinition>
					{
						id: rowData.id,
						name: rowData.name,
						description: rowData.description,
						label: rowData.label,
						icon: rowData.icon,
						typeId: rowData.typeId,
						public: rowData.public,
						ownershipSecurityGroupId:
							rowData.ownershipSecurityGroupId
					};

				const createdOperationId =
					await this.operationDefinitionsApiService
						.create(
							operationDefinitionData);

				this.commonTableContext.source.rowData.securityGroups
					.forEach(
						(securityGroupId: number) =>
						{
							this.securityGroups
								.forEach(
									(securityGroup: ISecurityGroup) =>
									{
										if (securityGroupId ===
											securityGroup.id)
										{
											this.operationDefinitionsApiService
												.createSecurityGroupMap(
													createdOperationId,
													securityGroup.id);
										}
									});
						});
			};

		await this.activityService.handleActivity(
			new Activity(
				createOperationDefinition(),
				'<strong>Creating Operation Definition</strong>',
				'<strong>Created Operation Definition</strong>',
				`Operation Definition ${this.commonTableContext
					.source.rowData.name}
					was successfully created.`,
				`Operation Definition ${this.commonTableContext
					.source.rowData.name}
					was not created.`));

		this.restoreTableDefinition();
	}

	/**
	 * Defines and validates if the value is existing or not.
	 *
	 * @async
	 * @param {FormControl} control
	 * The field form control.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof OperationDefinitionsComponent
	 */
	private async isExistingName(control: FormControl): Promise<boolean>
	{
		const initialPromiseArray: Promise<any>[] =
			[
				this.operationDefinitionsApiService
					.query(
						`Name.ToLower() eq '${control.value.toLowerCase()}'`,
						AppConstants.empty)
			];

		initialPromiseArray.push(
			this.operationDefinitionsApiService
				.query(
					`Id eq ${this.commonTableContext.source.rowData.id}`,
					AppConstants.empty));

		return Promise.all(
			initialPromiseArray)
			.then(async (
				[
					typedOperationName,
					existingOperation
				]) =>
				Promise.resolve(typedOperationName.length === 0
					|| typedOperationName[0].name ===
						existingOperation[0]?.name));
	}

	/**
	 * Defines and validates if the typeId is allowed to
	 * be modified depending on the existing parameter type.
	 *
	 * @async
	 * @param {FormControl} control
	 * The field form control.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof OperationDefinitionsComponent
	 */
	private async allowedToChange(control: FormControl): Promise<boolean>
	{
		let allowToChange: boolean = false;
		let operationDefinitionParameter: object[] = [];

		const isParameterExisting =
			await this.operationDefinitionParameterApiService
				.query(
					'DefinitionId eq '
						+ this.commonTableContext.source.rowData.id,
					AppConstants.empty);

		if (!AnyHelper.isNullOrEmpty(control.value))
		{
			const typeParameterDefinition =
				await this.operationTypeParameterApiService
					.query(
						`typeId eq ${control.value}`,
						AppConstants.empty);

			operationDefinitionParameter = typeParameterDefinition.length > 0
				? await this.operationDefinitionParameterApiService
					.query(
						'DefinitionId eq ' + this.commonTableContext
							.source.rowData.id +
						' and TypeParameterId eq ' +
						typeParameterDefinition[0].id,
						AppConstants.empty)
				: [];
		}

		if (isParameterExisting.length === 0
			|| operationDefinitionParameter.length > 0)
		{
			allowToChange = true;
		}
		else
		{
			const persistedData =
				await this.operationDefinitionsApiService
					.query(
						`Id eq ${this.commonTableContext.source.rowData.id}`,
						AppConstants.empty);

			if (control.value !== persistedData[0].typeId)
			{
				setTimeout(
					() =>
					{
						control.setValue(
							persistedData[0].typeId,
							{
								emitEvent: false,
								emitModelToViewChange: true,
								emitViewToModelChange: false
							});
					},
					this.fieldValueDebounceTime);
			}
		}

		return Promise.resolve(allowToChange);
	}
}