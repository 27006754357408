<div #FieldWrapper
	class="form-group custom-field-wrapper ui-g-12 ui-md-{{gridColumns || 6}}">
	<div *ngIf="to.label"
		class="input-label">
		<label [attr.for]="id">
			<strong>
				{{to.label}}
			</strong>
			<ng-container
				*ngIf="to.tooltip != null && to.tooltip.length > 0">
				<app-common-icon-tooltip
					[content]="to.tooltip"
					[field]="field">
				</app-common-icon-tooltip>
			</ng-container>
			<ng-container
				*ngIf="to.externalLink != null && to.externalLink.length > 0
					&& to.externalLinkTooltip != null">
				<app-common-icon-tooltip
					[content]="to.externalLinkTooltip"
					[field]="field"
					[externalLink]="to.externalLink">
				</app-common-icon-tooltip>
			</ng-container>
		</label>
	</div>

	<div class="p-input-div">
		<ng-template
			#fieldComponent>
		</ng-template>
		<span *ngIf="field.formControl.status === 'PENDING'">
			<i class="fa fa-fw fa-spin fa-spinner validation-spinner">
			</i>
		</span>
	</div>
</div>