/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component
} from '@angular/core';
import {
	IAssociatedEntityListContext
} from '@shared/interfaces/dynamic-interfaces/associated-entity-list-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Router
} from '@angular/router';

/* eslint-enable max-len */

@Component({
	selector: 'associated-organization',
	templateUrl: './associated-organization.component.html',
	styleUrls: [
		'./associated-organization.component.scss'
	]
})

/**
 * A component representing an associated company used
 * in the associated entity list.
 *
 * @export
 * @class AssociatedCompanyComponent
 * @implements {IDynamicComponent<Component, {
		entityInstance: IEntityInstance,
		entityListContext: IAssociatedEntityListContext
	}>}
 */
export class AssociatedOrganizationComponent
implements IDynamicComponent<Component, {
		entityInstance: IEntityInstance;
		entityListContext: IAssociatedEntityListContext;
	}>
{
	/**
	 * Initializes a new instance of the associated organization component.
	 *
	 * @param {Router} router
	 * The router used for organization navigation.
	 * @memberof AssociatedEntityListComponent
	 */
	public constructor(
		public router: Router)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, IEntityInstance>}
	 * @memberof AssociatedCompanyComponent
	 */
	public context: IDynamicComponentContext<Component, {
			entityInstance: IEntityInstance;
			entityListContext: IAssociatedEntityListContext;
		}>;

	/**
	 * Handles the legal name click by navigating to the organization
	 * this component represents.
	 *
	 * @memberof AssociatedEntityListComponent
	 */
	public navigateToOrganization(): void
	{
		// With a same route change occurring, add this to the history
		// to keep back functionality
		history.pushState(
			null,
			'',
			this.router.url
		);

		this.router.navigate(
			[
				`${AppConstants.moduleNames.organizations}/entities`,
				this.context.data.entityListContext.associatedEntityTypeGroup,
				AppConstants.viewTypes.edit,
				this.context.data.entityInstance.id
			],
			{
				replaceUrl: true,
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}});
	}
}