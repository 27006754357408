<span *ngIf="loadingBaseExpand"
	class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner center-spinner">
</span>

<span *ngIf="!loadingBaseExpand">
	<div class= 'ui-g-6 no-padding'
		[ngClass]="{'ui-g-12': context.source.additionalLayout == null && (layoutSchema?.length > 1 || siteLayoutService.displayTabletView),
			'single-row': layoutSchema?.length === 1}">
		<app-dynamic-formly
			[dataSet]="context.data"
			[layoutSchema]="layoutSchema"
			[context]="context"
			(validityChanged)="context.source.validExpandComponentChanged($event)">
		</app-dynamic-formly>
	</div>

	<div *ngIf="context.source.additionalLayout != null"
		class= 'ui-g-6 no-padding'>
		<app-dynamic-formly
			[dataSet]="context.data"
			[layoutSchema]="context.source.additionalLayout"
			[context]="context"
			(validityChanged)="context.source.validExpandComponentChanged($event)">
		</app-dynamic-formly>
	</div>

	<div class= 'ui-g-6 single-row button-bar'
		[ngClass]="{'ui-g-12': layoutSchema?.length > 1
			|| siteLayoutService.displayTabletView
			|| (layoutSchema?.length === 1 && layoutSchema[0].templateOptions?.label !== '')}">
		<operation-button-bar
			[initialModel]="context.source.expandActions"
			[pageContext]="context"
			(loadingOperations)="context.source.operationButtonClicked($event)">
		</operation-button-bar>
	</div>
</span>