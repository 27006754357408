/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	ClientMessageDetail
} from '@shared/implementations/application-data/client-message-detail';
import {
	Component,
	Input
} from '@angular/core';

@Component({
	selector: 'app-banner-detail',
	templateUrl: './app-banner-detail.component.html'
})

/**
 * A component representing an instance of the banner detail component.
 *
 * @export
 * @class AppBannerDetailComponent
 */
export class AppBannerDetailComponent
{
	/**
	 * Gets or sets the current extended detail being displayed in this
	 * component.
	 *
	 * @type {ClientMessageDetail}
	 * @memberof AppBannerDetailComponent
	 */
	@Input() public extendedDetail: ClientMessageDetail;
}
