<div class="associated-entity-container ui-g-12 no-horizontal-padding text-center">
	<div class="panel-sub-title">
		{{context.data.associationLabel}}
	</div>

	<ng-template
		[ngIf]="loading"
		[ngIfElse]="Data">
		<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
		</i>
	</ng-template>

	<ng-template #Data>
		<ul class="layout-menu entity-list ui-g-12 no-padding {{siteLayoutService.contentCssClass}}"
			[ngClass]="{'entity-border-containers': context.data?.useBorderContainers !== false}">
			<p-scrollPanel>
				<ng-template
					[ngIf]="(data?.length || 0) > 0"
					[ngIfElse]="NoResults">
					<li *ngFor="let item of data">
						<div class="ui-g-12 no-padding text-left associated-list-item">
							<app-dynamic-component
									[context]="getItemContext(item)"
									[displayComponent]="context.data.entityListItemComponent">
							</app-dynamic-component>
						</div>
					</li>
				</ng-template>
				<ng-template #NoResults>
					<li>
						<div class="ui-g-12 no-results-message text-center">
							{{noResultsMessage}}
						</div>
					</li>
				</ng-template>
			</p-scrollPanel>
		</ul>
	</ng-template>

</div>