/**
 * @copyright WaterStreet. All rights reserved.
*/

import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppCanDeactivateGuard
} from '@shared/guards/app-can-deactivate.guard';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BaseOperationAction
} from '@operation/actions/base/base-operation-action';
import {
	Injectable
} from '@angular/core';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	OperationExecutionService
} from '@operation/services/operation-execution.service';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	Router
} from '@angular/router';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/**
 * A class representing the navigation action.
 *
 * @export
 * @class NavigationAction
 * @extends {BaseOperationAction}
 */
@Injectable()
export class NavigationAction
	extends BaseOperationAction
{
	/**
	 * Creates an instance of a NavigationAction.
	 *
	 * @param {ActivityService} activityService
	 * The activity service used for this action.
	 * @param {Router} router
	 * The router used for this action.
	 * @param {OperationExecutionService} operationExecutionService
	 * The operation execution service used for this action.
	 * @param {OperationService} operationService
	 * The operation service used for this action.
	 * @param {OperationDefinitionApiService} operationDefinitionApiService
	 * The operation definition api service used for this action.
	 * @param {AppCanDeactivateGuard} appCanDeactivateGuard
	 * The app can deactivate guard.
	 * @memberof NavigationAction
	 */
	public constructor(
		public activityService: ActivityService,
		public router: Router,
		protected operationExecutionService: OperationExecutionService,
		protected operationService: OperationService,
		protected operationDefinitionApiService: OperationDefinitionApiService,
		protected appCanDeactivateGuard: AppCanDeactivateGuard)
	{
		super(
			activityService,
			operationExecutionService,
			operationService,
			operationDefinitionApiService,
			appCanDeactivateGuard);
	}

	/**
	 * Gets or sets the operation name.
	 *
	 * @type {string}
	 * @memberof NavigationAction
	 */
	public operationName: string =
		'Navigation';

	/**
	 * Gets or sets the allowed parameter entries
	 * as a comma delimited string.
	 *
	 * @type {string[]}
	 * @memberof NavigationAction
	 */
	public allowedNavigationTypes: string[] =
		[
			AppConstants.viewTypes.create,
			AppConstants.viewTypes.direct,
			AppConstants.viewTypes.view,
			AppConstants.viewTypes.edit,
			AppConstants.viewTypes.search
		];

	/**
	 * Gets or sets the base url.
	 *
	 * @type {string}
	 * @memberof NavigationAction
	 */
	public baseUrl: string = AppConstants.empty;

	/**
	 * Gets or sets the navigation type.
	 *
	 * @type {string}
	 * @memberof NavigationAction
	 */
	public navigationType: string = AppConstants.empty;

	/**
	 * Gets or sets the entity type.
	 *
	 * @type {string}
	 * @memberof NavigationAction
	 */
	public entityType: string = AppConstants.empty;

	/**
	 * Gets or sets the report type.
	 *
	 * @type {string}
	 * @memberof NavigationAction
	 */
	public reportType: string = AppConstants.empty;

	/**
	 * Gets or sets the display component.
	 *
	 * @type {string}
	 * @memberof NavigationAction
	 */
	public displayComponent: string = AppConstants.empty;

	/**
	 * Gets or sets the identifier. This value is
	 * only used when viewing an entity.
	 * @type {string}
	 * @memberof NavigationAction
	 */
	public identifier: string = AppConstants.empty;

	/**
	 * Gets or sets the direct component
	 * for a direct navigation action.
	 *
	 * @type {string}
	 * @memberof NavigationAction
	 */
	public directComponent: string = AppConstants.empty;

	/**
	 * Gets or sets the full url. If sent this
	 * value is used over all other mappings.
	 *
	 * @type {string}
	 * @memberof NavigationAction
	 */
	public fullUrl: string = AppConstants.empty;

	/**
	 * Gets or sets the mapped url. This value
	 * is populated via execute.
	 *
	 * @type {string}
	 * @memberof NavigationAction
	 */
	public mappedUrl: string = AppConstants.empty;

	/**
	 * Gets or sets the route data json value.
	 *
	 * @type {string}
	 * @memberof NavigationAction
	 */
	public routeData: string = AppConstants.empty;

	/**
	 * Executes the defined action.
	 *
	 * @memberof NavigationAction
	 */
	public async execute(): Promise<void>
	{
		if (!AnyHelper.isNullOrEmpty(this.fullUrl))
		{
			this.mappedUrl = this.fullUrl;
		}
		else
		{
			this.populateMappedUrl();
		}

		this.mappedUrl = StringHelper.interpolate(
			this.mappedUrl,
			this.pageContext);

		await this.router.navigate(
			[this.mappedUrl],
			this.getMappedParameters());
	}

	/**
	 * Populates the mapped url property
	 * based on the supplied action parameters.
	 *
	 * @memberof NavigationAction
	 */
	private populateMappedUrl(): void
	{
		this.validateUrlParameters();

		let url: string = '/' + this.baseUrl;

		switch (this.navigationType)
		{
			case AppConstants.viewTypes.search:
				break;
			case AppConstants.viewTypes.create:
				url += (`/${AppConstants.route.display}`
					+ (!AnyHelper.isNullOrWhitespace(this.displayComponent)
						? `/${this.displayComponent}`
						: `/${AppConstants.displayComponentTypes
							.basePageEntityCreate}`)
					+ `/${this.navigationType}`);
				break;
			case AppConstants.viewTypes.view:
			case AppConstants.viewTypes.edit:
				url = url
					+ (!AnyHelper.isNullOrWhitespace(this.entityType)
						? `/${this.entityType}`
						: AppConstants.empty)
					+ (!AnyHelper.isNullOrWhitespace(this.displayComponent)
						? `/${AppConstants.route.display}`
							+ `/${this.displayComponent}`
						: AppConstants.empty)
					+ `/${this.navigationType}`
					+ (!AnyHelper.isNullOrWhitespace(this.identifier)
						? `/${this.identifier}`
						: AppConstants.empty);
				break;
			case AppConstants.viewTypes.direct:
				url += (this.baseUrl.length === 0)
					? `${this.directComponent}`
					: `/${this.directComponent}`;
				this.mappedUrl = url;

				return;
		}

		this.mappedUrl = url;
	}

	/**
	 * Validates the supplied action parameters
	 * were entered correctly.
	 *
	 * @memberof NavigationAction
	 */
	private getMappedParameters(): object
	{
		if (!AnyHelper.isNullOrEmpty(this.entityType)
			&& this.navigationType !== AppConstants.viewTypes.view
			&& this.navigationType !== AppConstants.viewTypes.edit)
		{
			const entityTypes: object =
				{
					entityTypes: this.entityType
				};

			return {
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							this.navigationType ===
								AppConstants.viewTypes.search
								? entityTypes
								: { data: entityTypes })
				}
			};
		}

		if (!AnyHelper.isNullOrEmpty(this.routeData)
			&& ((!AnyHelper.isNullOrWhitespace(this.entityType)
				|| this.navigationType === AppConstants.viewTypes.edit)
			|| !AnyHelper.isNullOrWhitespace(this.displayComponent)
			|| !AnyHelper.isNullOrWhitespace(this.directComponent)))
		{
			const interpolatedRouteData =
				StringHelper.interpolate(
					this.routeData,
					this.pageContext);

			return {
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							JSON.parse(interpolatedRouteData))
				}
			};
		}

		return {};
	}

	/**
	 * Validates the supplied action parameters
	 * were entered correctly.
	 *
	 * @memberof NavigationAction
	 */
	private validateUrlParameters(): void
	{
		if (this.allowedNavigationTypes.indexOf(this.navigationType) === -1)
		{
			throw new Error(
				'Unable to create a navigation type for the entered type. '
					+ 'The allowed entries are '
					+ `['${this.allowedNavigationTypes
						.join(this.arrayDisplayDelimiter)}'].`);
		}

		if (this.navigationType === AppConstants.viewTypes.direct)
		{
			if (AnyHelper.isNullOrEmpty(this.directComponent))
			{
				throw new Error(
					'Unable to create a direct component for the '
						+ 'navigation type of \'direct\'. '
						+ 'The direct component must be defined.');
			}

			return;
		}

		if ((this.navigationType === AppConstants.viewTypes.view
			|| this.navigationType === AppConstants.viewTypes.edit))
		{
			if (!AnyHelper.isNullOrWhitespace(this.entityType)
				&& AnyHelper.isNullOrWhitespace(this.identifier))
			{
				throw new Error(
					'Unable to create an identifier for the navigation type of '
						+ `'${this.navigationType}'. The identifier of the `
						+ `item to ${this.navigationType} must be defined.`);
			}
		}

		if (!AnyHelper.isNullOrWhitespace(this.displayComponent)
			&& !AnyHelper.isNullOrWhitespace(this.routeData))
		{
			try
			{
				JSON.parse(this.routeData);
			}
			catch (exception)
			{
				throw new Error(
					'Unable to create a display for the navigation type of '
						+ `'${this.navigationType}'. The route data sent `
						+ 'to this component requires valid JSON.');
			}
		}
	}
}