/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BaseEntityApiService
} from '@api/services/base/base-entity.api.service';
import {
	CacheService
} from '@shared/services/cache.service';
import {
	HttpClient
} from '@angular/common/http';
import {
	Inject,
	Injectable
} from '@angular/core';
import {
	IWorkflowFailureActionsDto
} from '@api/interfaces/workflow/workflow-failure-actions.dto.interface';

/**
 * A class representing the logic and services of the workflow
 * failure actions controller.
 *
 * @export
 * @class WorkflowFailureActionsApiService
 * @extends {BaseEntityApiService<IWorkflowFailureActionsDto>}
 */
@Injectable()
export class WorkflowFailureActionsApiService
	extends BaseEntityApiService<IWorkflowFailureActionsDto>
{
	/**
	 * Creates an instance of a WorkflowFailureActionsApiService.
	 *
	 * @param {HttpClient} HttpClient
	 * The injected http client to use in the base api service.
	 * @param {CacheService} cache
	 * The injected cache service to use in the base api service.
	 * @memberof WorkflowFailureActionsApiService
	 */
	public constructor(
		@Inject(HttpClient) http: HttpClient,
		@Inject(CacheService) cache: CacheService)
	{
		super();
		this.httpClient = http;
		this.cacheService = cache;
		this.endpoint =
			AppConstants.apiControllers.workflowFailureActions;
	}

	/**
	 * Creates an IWorkflowFailureActionsDto with the specified information.
	 * This method is not implemented in the API.
	 *
	 * @param {IWorkflowFailureActionsDto} _entity
	 * The workflow failure action to create.
	 * @returns {Promise<number>}
	 * The newly created item identifier.
	 * @memberof WorkflowFailureActionsApiService
	 */
	public async create(
		_entity: IWorkflowFailureActionsDto): Promise<number>
	{
		throw new Error(
			this.getHandledByOthersMessage(
				AppConstants.apiMethods.create,
				AppConstants.systemEngines.workflowEngine
			));
	}

	/**
	 * Updates an IWorkflowFailureActionsDto with the specified information.
	 * This method is not implemented in the API.
	 *
	 * @param {number} _id
	 * Id of the workflow failure action to update.
	 * @param {IWorkflowFailureActionsDto} _entity
	 * The workflow failure action to update.
	 * @returns {Promise<object>}
	 * Updated item identifier
	 * @memberof WorkflowFailureActionsApiService
	 */
	public async update(
		_id: number,
		_entity: IWorkflowFailureActionsDto): Promise<object>
	{
		throw new Error(
			this.getHandledByOthersMessage(
				AppConstants.apiMethods.update,
				AppConstants.systemEngines.workflowEngine
			));
	}

	/**
	 * Deletes an IWorkflowFailureActionsDto with the specified identifier.
	 * This method is not implemented in the API.
	 *
	 * @param {number} id
	 * The identifier of the workflow failure action to delete.
	 * @returns {Promise<object>}
	 * An observable of the delete no-content response.
	 * @memberof WorkflowFailureActionsApiService
	 */
	public async delete(
		_id: number): Promise<object>
	{
		throw new Error(
			this.getHandledByOthersMessage(
				AppConstants.apiMethods.delete,
				AppConstants.systemEngines.workflowEngine
			));
	}
}