<div class="difference-display-wrapper">

	<div class="ui-g-12 no-padding difference-display-container {{siteLayoutService.contentCssClass}}">

		<div class="ui-g-12 no-padding difference-display-labels">
			<div class="difference-key-column">
				&nbsp;
			</div>
			<div class="difference-key-value">
				<span class="panel-sub-title ellipsis-text">
					Previous
				</span>
			</div>
			<div class="difference-key-value">
				<span class="panel-sub-title ellipsis-text">
					New
				</span>
			</div>
		</div>

		<ng-template
			ngFor
			let-mappedDifference
			[ngForOf]="mappedDifferences">

			<app-difference-display
				[mappedDifference]="mappedDifference">
			</app-difference-display>

		</ng-template>

	</div>

</div>
