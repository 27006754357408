/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ClaimConstants
} from '@claims/constants/claims-constants';
import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Router
} from '@angular/router';
import {
	SessionService
} from '@shared/services/session.service';

/* eslint-enable max-len */

@Component({
	selector: 'adjust-reserves-claim',
	templateUrl: './adjust-reserves-claim.component.html',
	styleUrls: []
})

/**
 * A component representing a wizard step for adjusting reserves.
 *
 * @export
 * @class AdjustReservesClaimComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class AdjustReservesClaimComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the adjust reserves component.
	 *
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes on entity creation.
	 * @param {EntityService} entityService
	 * The entity service used to lookup entity modules upon creation..
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @memberof AdjustReservesClaimComponent
	 */
	public constructor(
		public router: Router,
		public activityService: ActivityService,
		public moduleService: ModuleService,
		public entityService: EntityService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<
	 * 	DynamicWizardComponent,
	 * 	IWizardContext>}
	 * @memberof AdjustReservesClaimComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the formly layout used in implementing components.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof AdjustReservesClaimComponent
	 */
	public dynamicFormlyLayout: FormlyFieldConfig[];

	/**
	 * Gets the id descending query used for the order by
	 * in common entity queries.
	 *
	 * @type {string}
	 * @memberof EntityService
	 */
	private readonly idDescendingQuery: string = 'Id desc';

	/**
	 * Gets or sets the claim id.
	 *
	 * @type {number}
	 * @memberof AdjustReservesClaimComponent
	 */
	private claimId: number;

	/**
	 * Gets or sets the ledger instance data.
	 *
	 * @type {IEntityInstance[]}
	 * @memberof AdjustReservesClaimComponent
	 */
	private ledger: IEntityInstance[];

	/**
	 * Gets or sets the ledger transactions instance data.
	 *
	 * @type {IEntityInstance[]}
	 * @memberof AdjustReservesClaimComponent
	 */
	private ledgerTransactionClaims: IEntityInstance[];

	/**
	 * Gets or sets the adjustment amount.
	 *
	 * @type {number}
	 * @memberof AdjustReservesClaimComponent
	 */
	private adjustmentAmount: number;

	/**
	 * Gets or sets the exposure resource identifier.
	 *
	 * @type {string}
	 * @memberof AdjustReservesClaimComponent
	 */
	private exposureResourceIdentifier: string;

	/**
	 * Gets or sets the expense type.
	 *
	 * @type {string}
	 * @memberof AdjustReservesClaimComponent
	 */
	private expenseType: string;

	/**
	 * Gets or sets the context active menu item current data collected on the
	 * dynamic wizard steps.
	 *
	 * @type {any}
	 * @memberof AdjustReservesClaimComponent
	 */
	private currentData: any;

	/**
	 * Gets the expense types that are applicable.
	 *
	 * @type {any}
	 * @memberof AdjustReservesClaimComponent
	 */
	private readonly expenseTypes: any =
		{
			'AdministrativeAndOperatingCosts': 'AO',
			'DefenseAndCostContainment': 'DCC',
			'Loss': 'Loss'
		};

	/**
	 * Implements the on initialization interface.
	 *
	 * @async
	 * @memberof AdjustReservesClaimComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.currentData =
			this.context.source.activeMenuItem.currentData;

		this.claimId = this.currentData.data.id;

		this.exposureResourceIdentifier =
			this.currentData.data.exposure.identifier;

		this.expenseType =
			this.currentData.data.adjustment.expenseType;

		this.adjustmentAmount =
			this.currentData.data.adjustment.amount;

		await this.setDisplayData();

		this.context.source.addToNext(this.create.bind(this));

		await this.performPostInitActions();

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * Handles the validity changed event sent from the child dynamic
	 * formly component. This will update the validity of the form for
	 * action buttons.
	 *
	 * @async
	 * @param {boolean} isValid
	 * The validity of the current displayed step data set.
	 * @memberof AdjustReservesClaimComponent
	 */
	public async validityChanged(
		isValid: boolean): Promise<void>
	{
		this.context.source.validStepChanged(isValid);
	}

	/**
	 * This will send the claim create event and navigate to the new
	 * entity.
	 *
	 * @async
	 * @memberof AdjustReservesClaimComponent
	 */
	public async create(): Promise<void>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		const parameterString =
				 '&exposureResourceIdentifier='
				 	+ `${this.exposureResourceIdentifier}`
					+ '&expenseType='
					+ `${this.expenseType}`
					+ '&adjustmentAmount='
					+ `${this.adjustmentAmount}`;

		await this.activityService.handleActivity(
			new Activity(
				this.entityInstanceApiService.executeAction(
					this.claimId,
					ClaimConstants.workflowActions.adjustReserves,
					null,
					parameterString),
				'<strong>Adjusting Reserves</strong>',
				'<strong>Reserves Adjusted</strong>',
				'Reserves have been adjusted.',
				'Reserves have not been adjusted.'));

		await this.navigateToClaimSummary(
			this.claimId,
			ClaimConstants.claimEntityTypeGroups.claims);
	}

	/**
	 * This sets the summary information to display.
	 *
	 * @async
	 * @memberof AdjustReservesClaimComponent
	 */
	public async setDisplayData()
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.claimEntityTypeGroups.claims;

		this.ledger =
			await this.entityInstanceApiService.getChildren(
				this.claimId,
				AppConstants.empty,
				AppConstants.empty,
				null,
				1,
				ClaimConstants.financialEntityTypeGroups.ledgers);

		this.entityInstanceApiService.entityInstanceTypeGroup =
			ClaimConstants.financialEntityTypeGroups.ledgers;

		this.ledgerTransactionClaims =
			await this.entityInstanceApiService.getChildren(
				this.ledger[0].id,
				'metadata.exposureId eq '
					+ `'${this.exposureResourceIdentifier}' `
					+ `AND type eq '${this.expenseType}'`,
				this.idDescendingQuery,
				null,
				null,
				ClaimConstants
					.financialEntityTypeGroups.ledgerTransactionClaims);

		this.currentData.data.priorReserveAmount =
			this.ledgerTransactionClaims
				.reduce((sum, item) => sum + item.data.amount, 0);

		this.currentData.data.newReserveAmount =
			this.currentData.data.priorReserveAmount + this.adjustmentAmount;

		this.currentData.data.titleLabel =
				this.expenseTypes[this.expenseType];
	}

	/**
	 * This will navigate to the claim summary dashboard the claim id
	 * provided.
	 *
	 * @async
	 * @param {number} entityId
	 * The claim entity id to navigate.
	 * @param {string} group
	 * The entity group associated to the navigation.
	 * @memberof AdjustReservesClaimComponent
	 */
	private async navigateToClaimSummary(
		entityId: number,
		group: string)
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				group,
				AppConstants.viewTypes.edit,
				entityId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}

	/**
	 * Handles the post initialization action.
	 * This will create the dynamic formly layout for display component creation
	 * and permissions.
	 *
	 * @async
	 * @memberof AdjustReservesClaimComponent
	 */
	private async performPostInitActions(): Promise<void>
	{
		this.dynamicFormlyLayout =
			<FormlyFieldConfig[]>
			[
				<FormlyFieldConfig>
				{
					type: FormlyConstants.customControls.customDashboard,
					templateOptions: {
						dashboardDisplayComponentInstanceName:
							'ClaimAdjustReservesDashboard'
					}
				}
			];
	}
}