<span #OperationButtonMenu
	class="position-relative"
	clickOutside
	(clickOutside)="closeList()"
	[contentDisplayed]="active">

	<button
		*ngIf="useEllipsis"
		pButton
		#ellipsisButton
		role="button"
		icon="fa fa-fw fa-ellipsis-v"
		class="ellipsis-button {{operationGroup.styleClass}}"
		[ngClass]="{
			'background-theme-color': invertedColors,
			'small-ellipsis': smallEllipsis,
			'left-border-radius': (roundedLeftBorder === true),
			'right-border-radius': (roundedRightBorder === true)
		}"
		(click)="buttonClick($event)">
	</button>

	<button
		*ngIf="!useEllipsis"
		pButton
		#dropdownButton
		role="button"
		class="dropdown-button {{operationGroup.styleClass}}"
		[label]="operationGroup.label"
		(click)="buttonClick()"
		[ngClass]="{
			'left-border-radius': (roundedLeftBorder === true),
			'right-border-radius': (roundedRightBorder === true)
		}">
		<span class="dropdown-button-icon">
			<i class="fa fa-fw fa-angle-down"
				[@rotationAnimation]="active ? 'rotated' : 'default'">
			</i>
		</span>
	</button>

	<div class="operation-button-menu {{siteLayoutService.contentCssClass}}"
		[ngClass]="{
			'menu-top-left': (calculatedOverlayLocation === 'TopLeft'),
			'menu-top-center': (calculatedOverlayLocation === 'TopCenter'),
			'menu-top-right': (calculatedOverlayLocation === 'TopRight'),
			'menu-bottom-left': (calculatedOverlayLocation === 'BottomLeft'),
			'menu-bottom-center': (calculatedOverlayLocation === 'BottomCenter'),
			'menu-bottom-right': (calculatedOverlayLocation === 'BottomRight')
		}"
		[@displayAnimation]="active ? 'visible' : 'hidden'">
		<ng-template [ngIf]="displayOverlayScrollPanel === false"
			[ngIfElse]="ScrollPanelOperations">
			<operation-menu
				displayName="additional actions"
				class="layout-menu"
				id="ellipsis-menu"
				[initialModel]="operationGroup.items"
				[reset]="resetMenu"
				[context]="context"
				(closeOverlays)="closeOverlays()">
			</operation-menu>
		</ng-template>
		<ng-template #ScrollPanelOperations>
			<p-scrollPanel styleClass="operation-button-menu-container">
				<operation-menu
					displayName="additional actions"
					class="layout-menu"
					id="ellipsis-menu"
					[initialModel]="operationGroup.items"
					[reset]="resetMenu"
					[context]="context"
					(closeOverlays)="closeOverlays()">
				</operation-menu>
			</p-scrollPanel>
		</ng-template>
	</div>
</span>
