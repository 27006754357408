/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	get,
	has
} from 'lodash-es';
import {
	IKeyValuePair
} from '@shared/interfaces/application-objects/key-value-pair.interface';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/**
 * A class containing static helper methods for json schema definitions.
 *
 * @export
 * @class JsonSchemaHelper
 */
export class JsonSchemaHelper
{
	/**
	 * Gets the key used to define a description found in json schema.
	 *
	 * @type {string}
	 * @memberof JsonSchemaHelper
	 */
	private static readonly descriptionJsonSchemaKey: string = 'description';

	/**
	 * Gets the key used to define object properties in json schema.
	 *
	 * @type {string}
	 * @memberof JsonSchemaHelper
	 */
	private static readonly propertiesJsonSchemaKey: string = 'properties';

	/**
	 * Gets the key used to define items found in json schema arrays.
	 *
	 * @type {string}
	 * @memberof JsonSchemaHelper
	 */
	private static readonly itemsJsonSchemaKey: string = 'items';

	/**
	 * Gets the key used to define an object found in json schema.
	 *
	 * @type {string}
	 * @memberof JsonSchemaHelper
	 */
	private static readonly objectJsonSchemaKey: string = 'object';

	/**
	 * Gets an array item level definition this will filter to the item type
	 * if the array signifies an any of definition.
	 *
	 * @param {any} arraySchemaDefinition
	 * The array level schema definition holding a single item object definition
	 * or an any of set of possible item object definitions.
	 * @param {string} itemType
	 * The item type to get an array item definition for if this definition
	 * holds an any of set. This value defaults to an empty string if not
	 * sent and will instead find the single item object definition.
	 * @returns {any}
	 * The array item object level schema definition regardless of the array
	 * definition structure.
	 * @memberof JsonSchemaHelper
	 */
	public static getArrayItemDefinition(
		arraySchemaDefinition: any,
		itemType: string = AppConstants.empty): any
	{
		return arraySchemaDefinition.items?.anyOf?.find(
			(anyOfDefinition: any) =>
				(AnyHelper.isNullOrWhitespace(itemType) &&
					AnyHelper.isNull(
						anyOfDefinition.properties?.type?.const)
					|| anyOfDefinition.properties.type.const ===
						itemType))
					|| arraySchemaDefinition.items;
	}

	/**
	 * Gets a schema definition value that can be found in the sent schema
	 * definition and is defined by the sent object level property key.
	 *
	 * @param {any} schemaDefinition
	 * The object level schema definition holding a nested schema definition.
	 * @param {string} propertyKey
	 * The property key to find a schema definition for as it is stored
	 * in data. This function will translate that value into it's matched
	 * schema definition.
	 * @returns {any}
	 * The matching schema definition for the property key found in the sent
	 * schema definition.
	 * @memberof JsonSchemaHelper
	 */
	public static getSchemaDefinition(
		schemaDefinition: any,
		propertyKey: string): any
	{
		switch (true)
		{
			case has(
				schemaDefinition,
				`${this.propertiesJsonSchemaKey}.${propertyKey}`):
				return get(
					schemaDefinition,
					`${this.propertiesJsonSchemaKey}.${propertyKey}`);
			case has(
				schemaDefinition,
				`${AppConstants.commonProperties.characteristics}.`
					+ `${this.propertiesJsonSchemaKey}.${propertyKey}`):
				return get(
					schemaDefinition,
					`${AppConstants.commonProperties.characteristics}.`
						+ `${this.propertiesJsonSchemaKey}.${propertyKey}`);
			case has(
				schemaDefinition,
				`${this.propertiesJsonSchemaKey}.`
					+ `${AppConstants.commonProperties.characteristics}.`
					+ `${this.propertiesJsonSchemaKey}.${propertyKey}`):
				return get(
					schemaDefinition,
					`${this.propertiesJsonSchemaKey}.`
						+ `${AppConstants.commonProperties.characteristics}.`
						+ `${this.propertiesJsonSchemaKey}.${propertyKey}`);
			default:
				return get (schemaDefinition, propertyKey);
		}
	}

	/**
	 * Gets properties found in a json schema object. This is a recursive
	 * method.
	 *
	 * @param {any} object
	 * The json schema object to gather properties for.
	 * @param {string} [parentObjectName]
	 * If sent, this will append the parent object name
	 * to the returned data key.
	 * @param {string} [parentObjectDescription]
	 * If sent, this will be set as the description of the parent object.
	 * @param {IKeyValuePair[]} [existingSchemaProperties]
	 * If sent, this will append the new found properties to the original set.
	 * @returns {IKeyValuePair[]}
	 * The full set of json schema properties found in this entity schema.
	 * @memberof JsonSchemaHelper
	 */
	 public static getSchemaProperties(
		object: any,
		parentObjectName?: string,
		parentObjectDescription?: string,
		existingSchemaProperties?: IKeyValuePair[]): IKeyValuePair[]
	{
		const schemaProperties: IKeyValuePair[] =
			existingSchemaProperties || [];
		let newObject: IKeyValuePair;

		for (const objectPropertyName in object)
		{
			if (object.hasOwnProperty(objectPropertyName)
				&& !object[objectPropertyName]
					.hasOwnProperty(this.propertiesJsonSchemaKey)
				&& (!object[objectPropertyName]
					.hasOwnProperty(this.itemsJsonSchemaKey)
					|| (object[objectPropertyName].items.type
						!== this.objectJsonSchemaKey
						&& AnyHelper.isNull(
							object[objectPropertyName].items.anyOf))))
			{
				object[objectPropertyName].parentDescription =
					parentObjectDescription;

				newObject =
					{
						key: AnyHelper.isNullOrEmpty(parentObjectName)
							? objectPropertyName
							: `${parentObjectName}.${objectPropertyName}`,
						value: object[objectPropertyName]
					};

				schemaProperties.push(newObject);
			}
			else
			{
				const currentObject: any = object[objectPropertyName];
				let currentParentObjectDescription: string =
					(AnyHelper.isNullOrEmpty(parentObjectDescription)
						? AppConstants.empty
						: `${parentObjectDescription} `)
						+ (currentObject[this.descriptionJsonSchemaKey]
								|| StringHelper.toProperCase(
									objectPropertyName));

				if (object[objectPropertyName].hasOwnProperty(
					this.itemsJsonSchemaKey))
				{
					currentParentObjectDescription =
						currentObject[this.itemsJsonSchemaKey][
							this.descriptionJsonSchemaKey]
								|| parentObjectDescription;

					let itemProperties: IKeyValuePair[] = [];
					if (AnyHelper.isNull(
						object[objectPropertyName].items.anyOf))
					{
						itemProperties =
							this.getSchemaProperties(
								object[objectPropertyName].items.properties,
								AnyHelper.isNullOrEmpty(parentObjectName)
									? `${objectPropertyName}[]`
									: `${parentObjectName}.`
										+ `${objectPropertyName}[]`,
								currentParentObjectDescription,
								schemaProperties);

						// This is required for recursion.
						// The return value is not required.
						schemaProperties.concat(itemProperties);
					}
					else
					{
						for (const item of
							object[objectPropertyName].items.anyOf)
						{
							itemProperties =
								this.getSchemaProperties(
									item.properties,
									AnyHelper.isNullOrEmpty(parentObjectName)
										? `${objectPropertyName}[`
											+ `${item.properties.type.const}]`
										: `${parentObjectName}.`
											+ `${objectPropertyName}[`
											+ `${item.properties.type.const}]`,
									currentParentObjectDescription,
									schemaProperties);

							// This is required for recursion.
							// The return value is not required.
							schemaProperties.concat(itemProperties);
						}
					}
				}
				else{
					const objectProperties: IKeyValuePair[] =
						this.getSchemaProperties(
							object[objectPropertyName].properties,
							AnyHelper.isNullOrEmpty(parentObjectName)
								? objectPropertyName
								: `${parentObjectName}.${objectPropertyName}`,
							currentParentObjectDescription,
							schemaProperties);

					// This is required for recursion.
					// The return value is not required.
					schemaProperties.concat(objectProperties);
				}
			}
		}

		return schemaProperties;
	}
}