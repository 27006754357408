/**
 * @copyright WaterStreet. All rights reserved.
 */

// tslint:disable: no-string-literal
/* eslint-disable max-len */

import {
	ApiTokenLookup
} from '@api/api-token.lookup';
import {
	AuthenticateApiService
} from '@api/services/security/authenticate.api.service';
import {
	CommonModule
} from '@angular/common';
import {
	DisplayComponentDefinitionApiService
} from '@api/services/display-components/display-component-definition.api.service';
import {
	DisplayComponentInstanceApiService
} from '@api/services/display-components/display-component-instance.api.service';
import {
	DisplayComponentTypeApiService
} from '@api/services/display-components/display-component-type.api.service';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityInstanceRuleViolationApiService
} from '@api/services/entities/entity-instance-rule-violation.api.service';
import {
	EntityInstanceRuleViolationOverrideApiService
} from '@api/services/entities/entity-instance-rule-violation-override.api.service';
import {
	EntityLayoutApiService
} from '@api/services/entities/entity-layout.api.service';
import {
	EntityLayoutTypeApiService
} from '@api/services/entities/entity-layout-type.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EntityVersionApiService
} from '@api/services/entities/entity-version.api.service';
import {
	HttpClient,
	HttpClientModule
} from '@angular/common/http';
import {
	LogApiService
} from '@api/services/logs/log.api.service';
import {
	NgModule
} from '@angular/core';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	OperationDefinitionParameterApiService
} from '@api/services/operations/operation-definition-parameter.api.service';
import {
	OperationGroupApiService
} from '@api/services/operations/operation-group.api.service';
import {
	OperationGroupHierarchyApiService
} from '@api/services/operations/operation-group-hierarchy.api.service';
import {
	OperationTypeApiService
} from '@api/services/operations/operation-type.api.service';
import {
	OperationTypeParameterApiService
} from '@api/services/operations/operation-type-parameter.api.service';
import {
	RuleDefinitionApiService
} from '@api/services/rules/rule-definition.api.service';
import {
	RulePresentationDefinitionApiService
} from '@api/services/rules/rule-presentation-definition.api.service';
import {
	RulePresentationLogicDefinitionApiService
} from '@api/services/rules/rule-presentation-logic-definition.api.service';
import {
	RuleValidationDefinitionApiService
} from '@api/services/rules/rule-validation-definition.api.service';
import {
	RuleValidatorDefinitionApiService
} from '@api/services/rules/rule-validator-definition.api.service';
import {
	RuleViolationWorkflowActionDefinitionApiService
} from '@api/services/rules/rule-violation-workflow-action-definition.api.service';
import {
	SecurityAccessPolicyApiService
} from '@api/services/security/security-access-policy.api.service';
import {
	SecurityAccessPolicyDefinitionApiService
} from '@api/services/security/security-access-policy-definition.api.service';
import {
	SecurityAccessPolicyMappingApiService
} from '@api/services/security/security-access-policy-mapping.api.service';
import {
	SecurityApplicationApiService
} from '@api/services/security/security-application.api.service';
import {
	SecurityGroupApiService
} from '@api/services/security/security-group.api.service';
import {
	SecurityGroupRuleDefinitionViolationOverrideApiService
} from '@api/services/security/security-group-rule-definition-violation-override.api.service';
import {
	SecuritySessionApiService
} from '@api/services/security/security-session.api.service';
import {
	WorkflowActionDefinitionsApiService
} from '@api/services/workflow/workflow-action-definitions.api.service';
import {
	WorkflowActionInstancesApiService
} from '@api/services/workflow/workflow-action-instances.api.service';
import {
	WorkflowActionRequisitesApiService
} from '@api/services/workflow/workflow-action-requisites.api.service';
import {
	WorkflowFailureActionsApiService
} from '@api/services/workflow/workflow-failure-actions.api.service';
import {
	WorkflowRequisiteTypesApiService
} from '@api/services/workflow/workflow-requisite-types.api.service';

/* eslint-enable max-len */

@NgModule({
	declarations: [
	],
	imports: [
		CommonModule,
		HttpClientModule
	],
	providers: [
		AuthenticateApiService,
		{
			provide: ApiTokenLookup.tokens.AuthenticateApiService,
			useClass: AuthenticateApiService
		},
		DisplayComponentDefinitionApiService,
		{
			provide: ApiTokenLookup.tokens.DisplayComponentDefinitionApiService,
			useClass: DisplayComponentDefinitionApiService
		},
		DisplayComponentInstanceApiService,
		{
			provide: ApiTokenLookup.tokens.DisplayComponentInstanceApiService,
			useClass: DisplayComponentInstanceApiService
		},
		DisplayComponentTypeApiService,
		{
			provide: ApiTokenLookup.tokens.DisplayComponentTypeApiService,
			useClass: DisplayComponentTypeApiService
		},
		EntityDefinitionApiService,
		{
			provide: ApiTokenLookup.tokens.EntityDefinitionApiService,
			useClass: EntityDefinitionApiService
		},
		EntityInstanceApiService,
		{
			provide: ApiTokenLookup.tokens.EntityInstanceApiService,
			useClass: EntityInstanceApiService
		},
		EntityInstanceRuleViolationApiService,
		{
			provide: ApiTokenLookup.tokens
				.EntityInstanceRuleViolationApiService,
			useClass: EntityInstanceRuleViolationApiService
		},
		EntityInstanceRuleViolationOverrideApiService,
		{
			provide: ApiTokenLookup.tokens
				.EntityInstanceRuleViolationOverrideApiService,
			useClass: EntityInstanceRuleViolationOverrideApiService
		},
		EntityLayoutApiService,
		{
			provide: ApiTokenLookup.tokens.EntityLayoutApiService,
			useClass: EntityLayoutApiService
		},
		EntityLayoutTypeApiService,
		{
			provide: ApiTokenLookup.tokens.EntityLayoutTypeApiService,
			useClass: EntityLayoutTypeApiService
		},
		EntityTypeApiService,
		{
			provide: ApiTokenLookup.tokens.EntityTypeApiService,
			useClass: EntityTypeApiService
		},
		EntityVersionApiService,
		{
			provide: ApiTokenLookup.tokens.EntityVersionApiService,
			useClass: EntityVersionApiService
		},
		HttpClient,
		LogApiService,
		{
			provide: ApiTokenLookup.tokens.LogApiService,
			useClass: LogApiService
		},
		OperationDefinitionApiService,
		{
			provide: ApiTokenLookup.tokens.OperationDefinitionApiService,
			useClass: OperationDefinitionApiService
		},
		OperationDefinitionParameterApiService,
		{
			provide: ApiTokenLookup.tokens
				.OperationDefinitionParameterApiService,
			useClass: OperationDefinitionParameterApiService
		},
		OperationGroupApiService,
		{
			provide: ApiTokenLookup.tokens.OperationGroupApiService,
			useClass: OperationGroupApiService
		},
		OperationGroupHierarchyApiService,
		{
			provide: ApiTokenLookup.tokens.OperationGroupHierarchyApiService,
			useClass: OperationGroupHierarchyApiService
		},
		OperationTypeApiService,
		{
			provide: ApiTokenLookup.tokens.OperationTypeApiService,
			useClass: OperationTypeApiService
		},
		OperationTypeParameterApiService,
		{
			provide: ApiTokenLookup.tokens.OperationTypeParameterApiService,
			useClass: OperationTypeParameterApiService
		},
		RuleDefinitionApiService,
		{
			provide: ApiTokenLookup.tokens.RuleDefinitionApiService,
			useClass: RuleDefinitionApiService
		},
		RulePresentationDefinitionApiService,
		{
			provide: ApiTokenLookup.tokens.RulePresentationDefinitionApiService,
			useClass: RulePresentationDefinitionApiService
		},
		RulePresentationLogicDefinitionApiService,
		{
			provide: ApiTokenLookup.tokens
				.RulePresentationLogicDefinitionApiService,
			useClass: RulePresentationLogicDefinitionApiService
		},
		RuleValidationDefinitionApiService,
		{
			provide: ApiTokenLookup.tokens.RuleValidationDefinitionApiService,
			useClass: RuleValidationDefinitionApiService
		},
		RuleValidatorDefinitionApiService,
		{
			provide: ApiTokenLookup.tokens.RuleValidatorDefinitionApiService,
			useClass: RuleValidatorDefinitionApiService
		},
		RuleViolationWorkflowActionDefinitionApiService,
		{
			provide: ApiTokenLookup.tokens
				.RuleViolationWorkflowActionDefinitionApiService,
			useClass: RuleViolationWorkflowActionDefinitionApiService
		},
		SecurityApplicationApiService,
		{
			provide: ApiTokenLookup.tokens.SecurityApplicationApiService,
			useClass: SecurityApplicationApiService
		},
		SecurityAccessPolicyMappingApiService,
		{
			provide: ApiTokenLookup.tokens
				.SecurityAccessPolicyMappingApiService,
			useClass: SecurityAccessPolicyMappingApiService
		},
		SecurityAccessPolicyDefinitionApiService,
		{
			provide: ApiTokenLookup.tokens
				.SecurityAccessPolicyDefinitionApiService,
			useClass: SecurityAccessPolicyDefinitionApiService
		},
		SecurityAccessPolicyApiService,
		{
			provide: ApiTokenLookup.tokens.SecurityAccessPolicyApiService,
			useClass: SecurityAccessPolicyApiService
		},
		SecurityGroupApiService,
		{
			provide: ApiTokenLookup.tokens.SecurityGroupApiService,
			useClass: SecurityGroupApiService
		},
		SecurityGroupRuleDefinitionViolationOverrideApiService,
		{
			provide: ApiTokenLookup.tokens
				.SecurityGroupRuleDefinitionViolationOverrideApiService,
			useClass: SecurityGroupRuleDefinitionViolationOverrideApiService
		},
		SecuritySessionApiService,
		{
			provide: ApiTokenLookup.tokens.SecuritySessionApiService,
			useClass: SecuritySessionApiService
		},
		WorkflowActionDefinitionsApiService,
		{
			provide: ApiTokenLookup.tokens.WorkflowActionDefinitionsApiService,
			useClass: WorkflowActionDefinitionsApiService
		},
		WorkflowActionInstancesApiService,
		{
			provide: ApiTokenLookup.tokens.WorkflowActionInstancesApiService,
			useClass: WorkflowActionInstancesApiService
		},
		WorkflowActionRequisitesApiService,
		{
			provide: ApiTokenLookup.tokens.WorkflowActionRequisitesApiService,
			useClass: WorkflowActionRequisitesApiService
		},
		WorkflowFailureActionsApiService,
		{
			provide: ApiTokenLookup.tokens.WorkflowFailureActionsApiService,
			useClass: WorkflowFailureActionsApiService
		},
		WorkflowRequisiteTypesApiService,
		{
			provide: ApiTokenLookup.tokens.WorkflowRequisiteTypesApiService,
			useClass: WorkflowRequisiteTypesApiService
		}
	]
})

/**
 * A module used to defined available api methods.
 *
 * @export
 * @class ApiModule
 */
export class ApiModule
{
}