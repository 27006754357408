<div *ngIf="!hasCategories()"
	class="ui-g-12 text-center">
	No available file types to add.
</div>

<div *ngIf="hasCategories()"
	class="ui-g-12 no-vertical-padding">
	<app-file-progress
		*ngIf="progressVisible"
		[message]="progressMessage"
		[errorMessage]="errorMessage"
		[header]="progressHeader"
		[mode]="progressMode"
		[progress] = "progressPercent">
	</app-file-progress>

	<div *ngIf="addDocumentVisible"
		class="ui-g-12 no-padding">
		<div class="ui-g-12 nested-display-subtitle no-horizontal-padding text-left">
			<div class="ellipsis-text">
				Add: File
			</div>
		</div>

		<p-scrollPanel styleClass="ui-g-12 no-vertical-padding add-file-container">

			<div *ngIf="storageType !== 'Referenced'"
				class="ui-g-12 custom-field-wrapper file-control-container"
				[ngClass]="{
					'ng-invalid': (storageType !== 'Referenced' && fileUploader?.files?.length !== 1)
				}">
				<div class="input-label">
					<label for="file">
						<strong>
							File
						</strong>
					</label>
				</div>
				<p-fileUpload
					inputId="file"
					#fileUploader
					customUpload="true"
					(onSelect)="fileSelected()"
					[multiple]="false"
					[showUploadButton] = "false"
					[showCancelButton] = "false">
					<ng-template pTemplate="content">
						<div class="drag-and-drop-file-prompt">
							(or drag the file here)
						</div>
					</ng-template>
				</p-fileUpload>
			</div>
			<div *ngIf="storageType !== 'Referenced' && fileUploader?.files?.length !== 1"
				class="ui-g-12 no-vertical-padding validation-message-container file-validation-message">
				<div
					class="validation-message theme-color-red">
					A file is required.
				</div>
			</div>

			<div *ngIf="storageType === 'Referenced'"
				class="ui-g-12 custom-field-wrapper">
				<div class="input-label">
					<label for="url">
						<strong>
							Url
						</strong>
					</label>
				</div>
				<input
					inputId="url"
					[formControl]="urlControl"
					(input)="fileUrlChanged()"
					(change)="fileUrlChanged()"
					type='text'
					placeholder="http://some.website.com/somefile.pdf"
					class="width-100"
					[ngClass]="{
						'ng-invalid': (storageType === 'Referenced' && invalid('urlControl'))
					}"
					pInputText />
			</div>
			<div *ngIf="storageType === 'Referenced' && invalid('urlControl')"
				class="ui-g-12 no-vertical-padding validation-message-container">
				<div
					class="validation-message theme-color-red">
					A valid url is required.
				</div>
			</div>

			<div class="ui-g-12 custom-field-wrapper">
				<div class="input-label">
					<label for="name">
						<strong>
							Name
						</strong>
					</label>
				</div>
				<div class="p-inputgroup extension">
					<input
						inputId="name"
						[formControl]="nameControl"
						type="text"
						maxlength="128"
						[ngClass]="{
							'ng-invalid': (invalid('nameControl'))
						}"
						pInputText />
					<span class="p-inputgroup-addon">
						.{{extension}}
					</span>
				</div>
			</div>
			<div *ngIf="invalid('nameControl')"
				class="ui-g-12 no-vertical-padding validation-message-container">
				<div
					class="validation-message theme-color-red">
					{{requiredValueMessage}}
				</div>
			</div>

			<div class="ui-g-12 custom-field-wrapper"
				[ngClass]="{
					'ng-invalid': (invalid('categoryControl'))
				}">
				<div class="input-label">
					<label for="fileCategory">
						<strong>
							Type
						</strong>
					</label>
				</div>
				<p-dropdown
					name="fileCategory"
					inputId="fileCategory"
					[formControl]="categoryControl"
					[options]="categories"
					filter="true"
					placeholder="Select Type"
					styleClass="ui-g-12 no-padding"
					(onChange)="categoryChanged($event)">
				</p-dropdown>
			</div>
			<div *ngIf="this.subTypes.length > 1"
				class="ui-g-12 custom-field-wrapper">
				<div class="input-label">
					<label for="subTypes">
						<strong>
							SubType
						</strong>
					</label>
				</div>
				<p-dropdown
					name="subType"
					inputId="subType"
					[formControl]="subTypeControl"
					[options]="subTypes"
					filter="true"
					placeholder="Select Subtype"
					styleClass="ui-g-12 no-padding">
				</p-dropdown>
			</div>
			<div *ngIf="invalid('categoryControl')"
				class="ui-g-12 no-vertical-padding validation-message-container">
				<div
					class="validation-message theme-color-red">
					{{requiredValueMessage}}
				</div>
			</div>

			<div class="ui-g-12 custom-field-wrapper">
				<div class="input-label">
					<label for="description">
						<strong>
							Description
						</strong>
					</label>
				</div>
				<textarea
					class="panel-text"
					pInputTextarea
					inputId="description"
					maxlength="256"
					[(ngModel)]="description">
				</textarea>
			</div>
		</p-scrollPanel>

		<div class="ui-g-12 no-padding text-right button-set">
			<a class="text-link theme-color secondary-action-button"
				(click)="changeDisplayMode.emit('list')">
				Cancel
			</a>
			<button
				pButton
				label="Save"
				class="no-margin-right"
				(click)="onAddClicked()"
				[disabled]="addForm.invalid
					|| (storageType !== 'Referenced' && fileUploader?.files?.length !== 1)">
			</button>
		</div>
	</div>
</div>