/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component
} from '@angular/core';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	DateTime
} from 'luxon';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	FormControl
} from '@angular/forms';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IActionResponse
} from '@shared/interfaces/workflow/action-response.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IInsuranceEntityTypes
} from '@insurance/interfaces/insurance-entity-types.interface';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	InsuranceService
} from '@insurance/services/insurance.service';
import {
	MenuItem
} from 'primeng/api';
import {
	ObjectArrayHelper
} from '@shared/helpers/object-array.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Router
} from '@angular/router';
import {
	SessionService
} from '@shared/services/session.service';
import {
	TransactionStatusEffectiveDateDirective
} from '@insurance/directives/transaction-status-effective-date.directive';
import {
	User
} from '@shared/implementations/users/user';

@Component({
	selector: 'transaction-cancel-effective-date',
	templateUrl: './transaction-cancel-effective-date.component.html',
	styleUrls: [
		'./transaction-cancel-effective-date.component.scss'
	]
})

/**
 * A component representing a wizard step for setting the effective date
 * of a policy transaction status change.
 *
 * @export
 * @class TransactionCancelEffectiveDateComponent
 * @extends {TransactionCancelEffectiveDateComponent}
 */
export class TransactionCancelEffectiveDateComponent
	extends TransactionStatusEffectiveDateDirective
{
	/**
	 * Initializes an instance of the transaction status effective date
	 * component.
	 *
	 * @param {Router} router
	 * The router used for navigation.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {InsuranceService} insuranceService
	 * The insurance service used in this component.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service used in this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @memberof TransactionCancelEffectiveDateComponent
	 */
	public constructor(
		public router: Router,
		public activityService: ActivityService,
		public insuranceService: InsuranceService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService)
	{
		super(
			insuranceService,
			entityTypeApiService,
			entityInstanceApiService);
	}

	/**
	 * Gets the workflow action being executed against the current
	 * wizard step entity instance.
	 *
	 * @type {string}
	 * @memberof TransactionCancelEffectiveDateComponent
	 */
	public static readonly workflowActionName: string =
		'TransactionCancel';

	/**
	 * Gets or sets the newly created pending cancel transaction id.
	 *
	 * @type {number}
	 * @memberof TransactionCancelEffectiveDateComponent
	 */
	public createdPendingCancelTransactionId: number;

	/**
	 * Gets or sets the existing transaction instance that is being cancelled.
	 *
	 * @type {IEntityInstance}
	 * @memberof TransactionCancelEffectiveDateComponent
	 */
	public existingTransactionInstance: IEntityInstance;

	/**
	 * Gets or sets the set of insurance entity types.
	 *
	 * @type {IInsuranceEntityTypes}
	 * @memberof TransactionCancelEffectiveDateComponent
	 */
	public insuranceEntityTypes: IInsuranceEntityTypes;

	/**
	 * Gets or sets a value that indicates if new navigation data should be set.
	 *
	 * @type {boolean}
	 * @memberof TransactionCancelEffectiveDateComponent
	 */
	public override updateNavigationData: boolean = false;

	/**
	 * Gets or sets the message that will be set if the valid wizard step check
	 * returns false.
	 *
	 * @type {string}
	 * @memberof TransactionCancelEffectiveDateComponent
	 */
	public invalidWizardStepMessage: string = AppConstants.empty;

	/**
	 * Gets or sets the formly layout used in implementing components. This
	 * value can be overridden in the implementing component.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof TransactionCancelEffectiveDateComponent
	 */
	public override staticFormlyLayout: FormlyFieldConfig[] =
		<FormlyFieldConfig[]>
		[
			{
				key: 'data.statusEffectiveDate',
				type: FormlyConstants.customControls.customCalendar,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					label: 'Effective Date',
					required: true,
					quickDateSelection:
						<MenuItem>
						{
							label: 'Override',
							tooltipOptions: {
								tooltipLabel: AppConstants.empty
							},
							command: () =>
								DateHelper.fromUtcSystemIsoToSimulatedLocalDate(
									this.overrideButtonClick().toISO())
						}
				},
				validators: {
					validEffectiveDate: {
						expression:
							(control: FormControl) =>
								DateTime.fromISO(control.value)
									>= this.policyTermInceptionDate
										&& DateTime.fromISO(control.value)
											< this.policyTermExpirationDate,
						message: 'The date must be between the policy '
							+ 'effective and expiration dates.'
					},
					minimumEffectiveDate: {
						expression:
							(control: FormControl) =>
								DateTime.fromISO(control.value)
									>= this.minimumTransactionEffectiveDate,
						message: this.getMinimumEffectiveDateMessage.bind(this)
					}
				}
			},
			{
				key: 'data.flatCancel',
				type: FormlyConstants.customControls.customCheckbox,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					checkboxText: 'Flat Cancel',
					default: false
				},
				hideExpression:
					(model: any,
						_formState: any,
						field: FormlyFieldConfig) =>
					{
						const hidden: boolean =
							field.form.status ===
								AppConstants.formControlStatus.invalid
								|| model.data.statusEffectiveDate
									!== model.data.inceptionDate;

						if (hidden === true)
						{
							field.formControl.setValue(false);
						}

						return hidden;
					}

			}
		];

	/**
	 * Implements the on initialization interface.
	 *
	 * @async
	 * @memberof TransactionCancelEffectiveDateComponent
	 */
	public async performPostInitActions(): Promise<void>
	{
		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;
		this.insuranceEntityTypes =
			await this.insuranceService.populateInsuranceEntityTypes();

		this.context.source.addOrUpdateStepData(
			{
				statusEffectiveDate:
					currentData.isCalculatedEffectiveDate === true
						? await this.calculateEffectiveDate()
						: currentData.statusEffectiveDate,
				inceptionDate: this.policyTermInceptionDate.toISO()
			});

		await this.getEffectiveDateBasedTransaction();
		this.context.source.addToNext(this.createTransactionCancel.bind(this));
		this.setOverrideButtonStatus();

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * Validates the wizard step based on the component logic to
	 * confirm if this should be displayed or not.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * An awaitable promise that returns a value signifying whether or not
	 * the wizard step is valid for display.
	 * @memberof TransactionCancelEffectiveDateComponent
	 */
	public async isWizardStepValidForDisplay(): Promise<boolean>
	{
		return true;
	}

	/**
	 * Creates a cancellation transaction based on a selected transaction.
	 *
	 * @async
	 * @memberof TransactionCancelEffectiveDateComponent
	 */
	public async createTransactionCancel(): Promise<void>
	{
		await this.transactionCancel();

		this.navigateToPendingCancelTransaction();
	}

	/**
	 * Gets the calculated effective date based on the cancel reason settings.
	 *
	 * @async
	 * @returns {Promise<string>}
	 * An awaitable promise that returns the calculated date value based on the
	 * cancel reason settings as an ISO string.
	 * @memberof TransactionCancelEffectiveDateComponent
	 */
	private async calculateEffectiveDate(): Promise<string>
	{
		const currentData: any =
			this.context.source.activeMenuItem.currentData;

		const cancelReason: any =
			currentData.data.selectedReasons[0];

		const statusEffectiveDate: DateTime =
			cancelReason.cancelReasonType
				===	InsuranceConstants.cancelReasonTypes.underwriting
			|| cancelReason.cancelReasonType
				=== InsuranceConstants.cancelReasonTypes.nonPayment
				? await this.getEffectiveDateBasedOnSettings(cancelReason)
				: null;

		const isCalculatedEffectiveDate: boolean =
			!AnyHelper.isNull(statusEffectiveDate);

		if (isCalculatedEffectiveDate === true)
		{
			this.disableEffectiveDateControl();
		}

		this.context.source.addOrUpdateStepData(
			{
				isCalculatedEffectiveDate: isCalculatedEffectiveDate
			});

		return AnyHelper.isNull(statusEffectiveDate)
			? null
			: DateHelper.startOf(
				statusEffectiveDate)
				.toISO();
	}

	/**
	 * Sets the transactions effective date based on the reason settings.
	 *
	 * @async
	 * @param {any} cancelReason
	 * The cancel reason that contains the settings.
	 * @returns {Promise<DateTime>}
	 * An awaitable promise that  the calculated date value based on the cancel
	 * reason settings.
	 * @memberof TransactionCancelEffectiveDateComponent
	 */
	private async getEffectiveDateBasedOnSettings(
		cancelReason: any): Promise<DateTime>
	{
		const productInstance: IEntityInstance =
			await this.insuranceService.getProductByName(
				this.policyTerm.data.productName);

		const underwritingPeriod: number =
			productInstance.data.newBusiness.underwritingPeriod;

		const cancelEffectiveDate: DateTime =
			DateTime.fromISO(this.policyTerm.data.effectiveDate)
				.plus(
					{
						day: underwritingPeriod
					});

		const today: DateTime = DateTime.local();

		const cancelReasonsDays: number = today <= cancelEffectiveDate
			? cancelReason.cancelEffectiveDateWithinUnderwritingPeriod.days
			: cancelReason.cancelEffectiveDateOutsideUnderwritingPeriod.days;

		const effectiveDate: DateTime =
			DateTime.local()
				.plus(
					{
						day: cancelReasonsDays
					});

		return effectiveDate;
	}

	/**
	 * The override button click event.
	 *
	 * @returns {DateTime}
	 * Returns the previously selected effective date value.
	 * @memberof TransactionCancelEffectiveDateComponent
	 */
	private overrideButtonClick(): DateTime
	{
		this.enableEffectiveDateControl();

		this.context.source.addOrUpdateStepData(
			{
				isCalculatedEffectiveDate: false
			});

		const currentData =
			this.context.source.activeMenuItem.currentData;

		const effectiveDate: DateTime =
			currentData.data.statusEffectiveDate === null
				? null
				: DateTime.fromISO(currentData.data.statusEffectiveDate);

		return effectiveDate;
	}

	/**
	 * Sets the override button status based on the user permissions.
	 *
	 * @memberof TransactionCancelEffectiveDateComponent
	 */
	private setOverrideButtonStatus(): void
	{
		const hasMembership: boolean =
			new User(this.sessionService.user)
				.hasMembership(
					[
						AppConstants.securityGroups.administrators
					]);

		if (hasMembership !== true)
		{
			this.disableOverrideButton();
		}
	}

	/**
	 * Disables the effective date control.
	 *
	 * @memberof TransactionCancelEffectiveDateComponent
	 */
	private disableEffectiveDateControl(): void
	{
		this.staticFormlyLayout[0]
			.templateOptions.disabled = true;
	}

	/**
	 * Enables the effective date control.
	 *
	 * @memberof TransactionCancelEffectiveDateComponent
	 */
	 private enableEffectiveDateControl(): void
	 {
		 this.staticFormlyLayout[0]
			 .templateOptions.disabled = false;
	 }

	/**
	 * Disables the override button.
	 *
	 * @memberof TransactionCancelEffectiveDateComponent
	 */
	private disableOverrideButton(): void
	{
		this.staticFormlyLayout[0]
			.templateOptions.quickDateSelection.disabled = true;

		this.staticFormlyLayout[0]
			.templateOptions.quickDateSelection
			.tooltipOptions.tooltipLabel =
				'You don\'t have permission to override';
	}

	/**
	 * Executes the transaction cancel process by executing the
	 * TransactionCancel workflow action to create a cancellation transaction
	 * with the collected data from the Cancel Wizard.
	 *
	 * @async
	 * @memberof TransactionCancelEffectiveDateComponent
	 */
	private async transactionCancel(): Promise<void>
	{
		setTimeout(
			() =>
			{
				this.context.source.wizardStepLoading = true;
			});

		await this.getEffectiveDateBasedTransaction();
		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;

		const queryString: string =
			this.entityInstanceApiService.formUrlParam(
				AppConstants.empty,
				{
					statusEffectiveDate:
						currentData.statusEffectiveDate,
					flatCancellation:
						currentData.flatCancel,
					isCalculatedEffectiveDate:
						currentData.isCalculatedEffectiveDate,
					parentId:
						currentData.id,
					comments:
						currentData.comments,
					reasons:
						ObjectArrayHelper.commaSeparatedPropertyValues(
							currentData.selectedReasons,
							AppConstants.commonProperties.id)
				});

		this.existingTransactionInstance.data.effectiveDate =
			currentData.statusEffectiveDate;

		const entityType: IEntityType =
			await this.entityTypeApiService
				.getSingleQueryResult(
					`${AppConstants.commonProperties.name} eq `
						+ `'${this.existingTransactionInstance
							.entityType}'`,
					AppConstants.empty);
		const displayName: string =
			new EntityType(entityType)
				.displayName;

		return this.activityService.handleActivity(
			new Activity(
				new Promise(
					async(resolve: any) =>
					{
						this.entityInstanceApiService.entityInstanceTypeGroup =
							entityType.group;
						const response: IActionResponse =
							await this.entityInstanceApiService
								.executeAction(
									this.existingTransactionInstance.id,
									TransactionCancelEffectiveDateComponent
										.workflowActionName,
									this.existingTransactionInstance,
									queryString);

						this.createdPendingCancelTransactionId =
							(<any>response).body.parameters[
								AppConstants.commonProperties.id];

						resolve();
					}),
				'<strong>Processing cancellation</strong>',
				'<strong>Cancellation processed</strong>',
				`${displayName} cancellation has been created.`,
				`${displayName} cancellation has not been created.`));
	}

	/**
	 * This will set the existing transaction instance based on the effective
	 * date.
	 *
	 * @async
	 * @memberof TransactionCancelEffectiveDateComponent
	 */
	private async getEffectiveDateBasedTransaction(): Promise<void>
	{
		this.matchEffectiveDateToTransaction();

		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;
		this.entityInstanceApiService.entityInstanceTypeGroup =
			currentData.basedOnEntityTypeGroup;
		this.existingTransactionInstance =
			await this.entityInstanceApiService
				.get(currentData.basedOnEntityId);
	}

	/**
	 * This will navigate to the new cancel transaction using the transaction
	 * id provided.
	 *
	 * @memberof TransactionCancelEffectiveDateComponent
	 */
	private navigateToPendingCancelTransaction(): void
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${AppConstants.moduleNames.policy}/entities`,
				this.existingTransactionInstance.entityType,
				AppConstants.viewTypes.edit,
				this.createdPendingCancelTransactionId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									InsuranceConstants.layoutTypes.cancelSummary
							})
				}
			});
	}
}