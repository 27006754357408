/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';

/**
 * A class containing static helper methods for the window document.
 *
 * @export
 * @class DocumentHelper
 */
export class DocumentHelper
{
	/**
	 * This method will return the computed vertical position of the element
	 * found by the id sent.
	 *
	 * @param {Document} document
	 * The current window document that the element is being displayed in.
	 * @param {string} elementId
	 * The identifier of the element.
	 * @returns {number}
	 * The vertical position or top of the bounding rectangle for this element.
	 * @memberof DocumentHelper
	 */
	public static getElementVerticalPositionById(
		document: Document,
		elementId: string): number
	{
		return Math.ceil(
			this.getBoundingRectangleById(
				document,
				elementId)?.top);
	}

	/**
	 * This method will return the client rectangle of an element by id.
	 *
	 * @param {Document} document
	 * The current window document that the element is being displayed in.
	 * @param {string} elementId
	 * The identifier of the element.
	 * @returns { DOMRect | null}
	 * The bounding container rectangle of this element.
	 * @memberof DocumentHelper
	 */
	public static getBoundingRectangleById(
		document: Document,
		elementId: string): DOMRect
	{
		const element: Element =
			document.querySelector('#' + elementId);

		return AnyHelper.isNull(element)
			? null
			: element.getBoundingClientRect();
	}

	/**
	 * This method will scroll to an existing window document element by id.
	 * The selected element border will be displayed upon scrolling to the
	 * element to highlight this area to the user.
	 *
	 * @param {Document} document
	 * The current window document that the element is being displayed in.
	 * @param {string} elementId
	 * The identifier of the element.
	 * @param {number} [formVerticalOffset]
	 * If sent this can be used to offset from the top of the screen. This
	 * value is the pixel measurement of the header and any content
	 * displayed prior to the form.
	 * @memberof DocumentHelper
	 */
	public static scrollToElementById(
		document: Document,
		elementId: string,
		formVerticalOffset: number = 0): void
	{
		const scrollToElement: Element =
			document.querySelector('#' + elementId);

		const scrollTop: number =
			window.scrollY
				+ scrollToElement.getBoundingClientRect().top
				- formVerticalOffset;

		window.scroll(
			{
				top: scrollTop,
				behavior: 'smooth'
			});
	}

	/**
	 * This method will add and remove the class 'selected-element-border'.
	 * This class adds a border of the primary theme color to the left
	 * of targeted elements by id.
	 *
	 * @param {Document} document
	 * The current window document that the element is being displayed in.
	 * @param {string} elementId
	 * The identifier of the element.
	 * @param {number} [fadeDelay]
	 * If sent this will change the delay time for the border to be displayed.
	 * This defaults to one second.
	 * @memberof DocumentHelper
	 */
	public static displaySelectedElementBorderById(
		document: Document,
		elementId: string,
		fadeDelay: number = 1000): void
	{
		const selectedElementClass: string =
			'selected-element-border';
		const selectedElement: Element =
			document.querySelector('#' + elementId);

		selectedElement.classList
			.add(selectedElementClass);

		setTimeout(
			() =>
			{
				selectedElement.classList
					.remove(selectedElementClass);
			},
			fadeDelay);
	}

	/**
	 * This method will show or hide an element that is available in the
	 * DOM that matches the specified class.
	 *
	 * @param {string} elementClass
	 * The overlay class to update the display css style.
	 * @param {boolean} display
	 * The set of display true = block / false = none.
	 * @memberof DocumentHelper
	 */
	public static setElementDisplay(
		elementClass: string,
		display: boolean = false): void
	{
		const element: HTMLElement =
			document.querySelector(elementClass);

		if (AnyHelper.isNull(element))
		{
			return;
		}

		element.style.display =
			display === true
				? AppConstants.displayTypes.block
				: AppConstants.displayTypes.none;
	}
}