/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	ILogEntry
} from '@shared/interfaces/logs/log-entry.interface';
import {
	LoggerService,
	LogLevel
} from '@shared/services/logger.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';

@Component({
	selector: 'app-access-denied-content',
	templateUrl: './access-denied-content.component.html',
	styleUrls: [
		'./access-denied-content.component.scss'
	]
})

/**
 * A component that generates content for an access denied view in a drawer or
 * page.
 *
 * @export
 * @implements {OnInit}
 * @class AccessDeniedContentComponent
 */
export class AccessDeniedContentComponent
implements OnInit
{
	/**
	 * Creates an instance of an AccessDeniedContentComponent.
	 *
	 * @param {LoggerService} logger
	 * The logger service used to warn access denied events.
	 * @memberof AccessDeniedContentComponent
	 */
	public constructor(
		public logger: LoggerService)
	{
	}

	/**
	 * Gets or sets the session identifier.
	 *
	 * @type {string}
	 * @memberof AccessDeniedContentComponent
	 */
	@Input() public drawerDisplay: boolean = false;

	/**
	 * Gets or sets the session identifier.
	 *
	 * @type {string}
	 * @memberof AccessDeniedContentComponent
	 */
	@Input() public drawerName: string = AppConstants.empty;

	/**
	 * Gets or sets the session identifier.
	 *
	 * @type {string}
	 * @memberof AccessDeniedContentComponent
	 */
	@Input() public sessionIdentifier: string = AppConstants.empty;

	/**
	 * Gets or sets the session identifier.
	 *
	 * @type {string}
	 * @memberof AccessDeniedContentComponent
	 */
	@Input() public accessDeniedUrl: string = AppConstants.empty;

	/**
	 * Gets or sets the session identifier.
	 *
	 * @type {string}
	 * @memberof AccessDeniedContentComponent
	 */
	@Input() public resources: string[] = [];

	/**
	 * Gets or sets the session identifier.
	 *
	 * @type {string}
	 * @memberof AccessDeniedContentComponent
	 */
	@Input() public clientMessage: string = AppConstants.empty;

	/**
	 * Gets or sets the formatted message.
	 *
	 * @type {string}
	 * @memberof AccessDeniedContentComponent
	 */
	public get formattedMessage(): string
	{
		return 'Insufficient privileges to access the '
			+ `${this.drawerDisplay === true ? 'drawer' : 'page'}.`;
	}

	/**
	 * On initialization event.
	 * Handles route parameters for display.
	 *
	 * @memberof AccessDeniedContentComponent
	 */
	public ngOnInit(): void
	{
		this.logger.logWarning(
			<ILogEntry>
			{
				message: this.formattedMessage,
				exception: JSON.stringify(this.getAccessDeniedObject()),
				level: LogLevel[LogLevel.Warning]
			});
	}

	/**
	 * Gets a common access denied object for logs and clipboard copies.
	 *
	 * @memberof AccessDeniedContentComponent
	 */
	public getAccessDeniedObject(): void
	{
		const accessDeniedObject: any =
			<any>
			{
				accessDeniedUrl: this.accessDeniedUrl,
				sessionIdentifier: this.sessionIdentifier,
				resources: this.resources
			};

		if (!AnyHelper.isNullOrWhitespace(this.clientMessage))
		{
			accessDeniedObject.clientMessage = this.clientMessage;
		}

		if (this.drawerDisplay === true)
		{
			accessDeniedObject.drawerName = this.drawerName;
		}

		return accessDeniedObject;
	}

	/**
	 * Copies information about this access denied issue to the clipboard.
	 * This will be a json stringified object matching what we send to logs.
	 *
	 * @memberof AccessDeniedContentComponent
	 */
	public copyAccessDeniedToClipboard(): void
	{
		const accessDeniedObject: any = this.getAccessDeniedObject();
		accessDeniedObject.message = this.formattedMessage;

		StringHelper.copyToClipboard(
			JSON.stringify(accessDeniedObject));

		EventHelper.dispatchBannerEvent(
			'Data Copied',
			'Access denied data was copied to your clipboard.',
			AppConstants.activityStatus.info);
	}
}