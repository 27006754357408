/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	OnInit
} from '@angular/core';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	DateTime
} from 'luxon';
import {
	ICommonListContext
} from '@shared/interfaces/dynamic-interfaces/common-list-context.interface';
import {
	ICommonListFilter
} from '@shared/interfaces/dynamic-interfaces/common-list-filter.interface';
import {
	ICommonListItem
} from '@shared/interfaces/dynamic-interfaces/common-list-item.interface';
import {
	ICommonListSort
} from '@shared/interfaces/dynamic-interfaces/common-list-sort.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	MenuItem
} from 'primeng/api';

/* eslint-enable max-len */

@Component({
	selector: 'app-activity-list',
	templateUrl: './activity-list.component.html',
	styleUrls: [
		'./activity-list.component.scss',
	],
	animations: [
		ContentAnimation
	]
})

/**
 * A component representing an instance of the activity
 * list.
 *
 * @export
 * @class ActivityListComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<SessionComponent, any>}
 */
export class ActivityListComponent
implements
		OnInit,
		IDynamicComponent<Component, any>
{
	/**
	 * Initializes a new instance of the ActivityListComponent class.
	 *
	 * @param {ActivityService} activityService
	 * The activity service used in this component.
	 * @memberof ActivityListComponent
	 */
	public constructor(
		public activityService: ActivityService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof ActivityListComponent
	 */
	public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets a value representing the common list context to be loaded
	 * during list mode.
	 *
	 * @type {IDynamicComponentContext<
		* ActivityListComponent,
		* ICommonListContext<Activity<any>>>}
	* @memberof ActivityListComponent
	*/
	public commonListContext: IDynamicComponentContext<
		ActivityListComponent,
		ICommonListContext<Activity<any>>>;

	/**
	 * Gets or sets a collection of enabled filters.
	 *
	 * @type {ICommonListFilter[]}
	 * @memberof ActivityListComponent
	 */
	public enabledFilters: ICommonListFilter[];

	/**
	 * Gets or sets the enabled sorter.
	 *
	 * @type {ICommonListSort}
	 * @memberof ActivityListComponent
	 */
	public sorter: ICommonListSort =
		<ICommonListSort>{
			name: 'Activity Time',
			value: 'lastActivityTime',
			direction: AppConstants.sortDirections.ascending,
			iconAsc: 'fa fa-sort-amount-asc',
			iconDesc: 'fa fa-sort-amount-desc'
		};

	/**
	 * On initialization event.
	 * Initializes the activity list component and creates the common list.
	 *
	 * @memberof ActivityListComponent
	 */
	public ngOnInit(): void
	{
		this.activityService
			.activityChanged
			.subscribe(
				() =>
				{
					this.refreshCommonListContext(
						this.getSortedActivities(),
						this);
				});

		this.refreshCommonListContext(
			this.getSortedActivities(),
			this);
	}

	/**
	 * A method that handles any sorter change and refreshes data.
	 *
	 * @param {any} source
	 * A value representing the source component triggering the sort change
	 * event.
	 * @param {ICommonListSort} sorter
	 * A value representing the enabled and selected sorter.
	 * @memberof ActivityListComponent
	 */
	public handleSortingChange(
		source: any,
		sorter: ICommonListSort): void
	{
		this.sorter = sorter;

		this.refreshCommonListContext(
			this.getSortedActivities(),
			source);
	}

	/**
	 * A method that handles any filter change and refreshes data.
	 *
	 * @param {any} source
	 * A value representing the source component triggering the sort change
	 * event.
	 * @param {ICommonListFilter[]} filters
	 * A collection of enabled and active filters.
	 * @memberof ActivityListComponent
	 */
	public handleFilterChange(
		source: any,
		filters: ICommonListFilter[]): void
	{
		this.enabledFilters = filters;

		this.refreshCommonListContext(
			this.getSortedActivities(),
			source);
	}

	/**
	 * Refreshed the common list context
	 *
	 * @param {Activity<any>[]} activities
	 * The activities to list
	 * @param {any} source
	 * A value representing the source component
	 * @memberof ActivityListComponent
	 */
	public refreshCommonListContext(
		activities: Activity<any>[],
		source: any): void
	{
		const firstLoad: boolean
			= AnyHelper.isNull(this.commonListContext) === true;

		const data: ICommonListItem<Activity<any>>[]
			= activities?.map(activity => this.mapToListItem(activity));

		if (firstLoad)
		{
			this.commonListContext =
			{
				data: this.generateCommonListContext(data),
				source: this
			};
		}
		else
		{
			this.commonListContext
				.data
				.data = data;
		}

		if (AnyHelper.isFunction(
			source.performPostLoadActions))
		{
			source.performPostLoadActions();
		}
	}

	/**
	 * Handles the action click event.
	 *
	 * @param {Activity<any>} activity
	 * The activity to perform the action againt
	 * @param {string} action
	 * The action to perform
	 * @memberof ActivityListComponent
	 */
	public async actionClick(
		activity: Activity<any>,
		action: string): Promise<void>
	{
		if (action === 'dismissAll')
		{
			await this.activityService
				.dismissAllActivities();
		}

		if (action === 'dismiss')
		{
			await this.activityService
				.dismissActivity(activity);
		}
	}

	/**
	 * Gets a list of sorted activities
	 *
	 * @private
	 * @memberof ActivityListComponent
	 * @returns {Activity<any>[]}
	 */
	private getSortedActivities(): Activity<any>[]
	{
		let filteredActivities: Activity<any>[] = [];
		let isFiltered: boolean = false;
		let statusFilterCount: number = 0;

		if (this.enabledFilters?.length > 0)
		{
			// include status types
			this.enabledFilters
				.forEach((filter: ICommonListFilter) =>
				{
					const isStatusFilter: boolean =
						filter.value === AppConstants.activityStatus.complete
						|| filter.value === AppConstants.activityStatus.error
						|| filter.value === AppConstants.activityStatus.info
						|| filter.value === AppConstants.activityStatus.pending;

					if (isStatusFilter)
					{
						isFiltered = true;
						statusFilterCount ++;

						filteredActivities.push(
							...this.activityService
								.activities
								.filter(activity =>
									activity.status === filter.value));
					}
				});

			// There can be only one.
			// An activity can only be one status at a time.
			if (statusFilterCount > 1)
			{
				return null;
			}

			// filter matching search filters.
			this.enabledFilters
				.forEach((filter: ICommonListFilter) =>
				{
					const isStatusFilter: boolean =
						filter.value === AppConstants.activityStatus.complete
						|| filter.value === AppConstants.activityStatus.error
						|| filter.value === AppConstants.activityStatus.info
						|| filter.value === AppConstants.activityStatus.pending;

					if (isStatusFilter)
					{
						return;
					}

					const regEx: RegExp = new RegExp(
						filter.value,
						'gi');

					filteredActivities = isFiltered
						? this.getFilteredActivities(
							filteredActivities,
							regEx)
						: this.getFilteredActivities(
							this.activityService.activities,
							regEx);
				});
		}
		else
		{
			filteredActivities = this.activityService.activities;
		}

		return filteredActivities
			.sort((activityA, activityB) =>
			{
				if (activityA.lastActivityTime > activityB.lastActivityTime)
				{
					return this.sorter?.direction === AppConstants
						.sortDirections
						.ascending
						? -1
						: 1;
				}

				return this.sorter?.direction === AppConstants
					.sortDirections
					.ascending
					? 1
					: -1;
			});
	}

	/**
	 * A method that maps activities to a common list item.
	 *
	 * @private
	 * @param {IEntityInstanceDto} entity
	 * A value representing the entity instance to map to common list item.
	 * @returns {ICommonListItem<Activity<any>>}
	 * A value representing the mapped common list item from the entity
	 * instance.
	 * @memberof ActivityListComponent
	 */
	private mapToListItem(
		activity: Activity<any>): ICommonListItem<Activity<any>>
	{
		const timePassed: string =
			DateTime.fromMillis(
				activity.lastActivityTime)
				.toISO();

		const descriptionLine: string = AnyHelper
			.isNullOrWhitespace(activity.statusMessage)
			? activity.message
			: '${item.message}<br>'
					+ '<span class="theme-color-gray font-smaller">'
						+ '${item.statusMessage}';

		const listItem: ICommonListItem<Activity<any>> = {
			item: activity,
			descriptionLineFormat: descriptionLine,
			informationLineFormat: null,
			informationLineRelativeDateTime: timePassed,
			informationIcons: [],
			actions: []
		};

		let iconClass: string;
		switch (activity.status)
		{
			case AppConstants.activityStatus.complete:
				iconClass = 'fa fa-check theme-color-green';
				break;

			case AppConstants.activityStatus.error:
				iconClass = 'fa fa-exclamation theme-color-red';
				break;

			case AppConstants.activityStatus.info:
				iconClass = 'fa fa-info theme-color-light-blue';
				break;

			case AppConstants.activityStatus.pending:
				iconClass = 'pi pi-spin pi-spinner theme-color-green';
				break;
		}

		listItem.informationIcons
			.push(iconClass);

		listItem.actions.push(<MenuItem>{
			icon: 'fa fa-times',
			command: (event: any) => {
				this.actionClick(activity, 'dismiss');
				event.stopPropagation();
			}
		});

		return listItem;
	}

	/**
	 * A method that generates the activities common list context.
	 *
	 * @note
	 * This method will generate on first Load the default common list context
	 * with properties used for activities.
	 *
	 * @private
	 * @param {ICommonListItem<Activity<any>>[]} data
	 * A value representing the data to load within the common list context.
	 * @memberof ActivityListComponent
	 * @returns {ICommonListContext<Activity<any>[]>}
	 * A value representing the common list context for first load.
	*/
	private generateCommonListContext(
		data: ICommonListItem<Activity<any>>[]):
		ICommonListContext<Activity<any>>
	{
		const generatedCommonListContext: ICommonListContext<Activity<any>> =
			<ICommonListContext<Activity<any>>>
			{
				data: data,
				searchable: true,
				sortable: true,
				sorters: [
					this.sorter
				],
				supportMarkdown: true,
				searchFilterFormat: '${inputValue}',
				actions: [<MenuItem>{
					icon: 'fa fa-bell-slash-o',
					label: 'Dismiss All',
					command: (event: any) => {
						this.actionClick(
							null,
							'dismissAll');
						event.stopPropagation();
					}
				}],
				filters: [
					{
						name: AppConstants.activityStatus.complete,
						value: AppConstants.activityStatus.complete
					},
					{
						name: AppConstants.activityStatus.pending,
						value: AppConstants.activityStatus.pending
					},
					{
						name: AppConstants.activityStatus.info,
						value: AppConstants.activityStatus.info
					},
					{
						name: AppConstants.activityStatus.error,
						value: AppConstants.activityStatus.error
					}
				],
				onFilterChange: (source, filters) =>
					this.handleFilterChange(source, filters),
				onSortChange: (source, sorter) =>
					this.handleSortingChange(source, sorter)
			};

		return generatedCommonListContext;
	}

	/**
	 * Gets filtered activities.
	 *
	 * @param {Activity<any>[]} activities
	 * The activities object.
	 * @param {RegExp} regEx
	 * The regular expresion.
	 * @memberof ActivityListComponent
	 */
	 private getFilteredActivities(
		 activities: Activity<any>[],
		 regEx: RegExp): any[]
	 {
		return activities
			.filter(activity =>
			{
				if (activity.statusMessage
					?.match(regEx))
				{
					return true;
				}

				if (activity.message
					?.match(regEx))
				{
					return true;
				}

				return false;
			});
	}
}