/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BaseEntityApiService
} from '@api/services/base/base-entity.api.service';
import {
	CacheService
} from '@shared/services/cache.service';
import {
	HttpClient
} from '@angular/common/http';
import {
	Inject,
	Injectable
} from '@angular/core';
import {
	IOperationGroupHierarchyDto
} from '@api/interfaces/operations/operation-group-hierarchy.dto.interface';

/**
 * A class representing the logic and services of the operation
 * group controller.
 *
 * @export
 * @class OperationGroupHierarchyApiService
 * @extends {BaseEntityApiService<IOperationGroupHierarchyDto>}
 */
@Injectable()
export class OperationGroupHierarchyApiService
	extends BaseEntityApiService<IOperationGroupHierarchyDto>
{
	/**
	 * Creates an instance of an OperationGroupHierarchyApiService.
	 *
	 * @param {HttpClient} HttpClient
	 * The injected http client to use in the base api service.
	 * @param {CacheService} cache
	 * The injected cache service to use in the base api service.
	 * @memberof OperationGroupHierarchyApiService
	 */
	public constructor(
		@Inject(HttpClient) http: HttpClient,
		@Inject(CacheService) cache: CacheService)
	{
		super();
		this.httpClient = http;
		this.cacheService = cache;
		this.endpoint =
			AppConstants.apiControllers.operationGroupHierarchies;
	}

	/**
	 * Gets a type IOperationGroupHierarchyDto with the specified identifier.
	 * This method is not implemented in the API.
	 *
	 * @param {number} id
	 * The identifier of the type operation group hierarchy to get.
	 * @returns {Promise<IOperationGroupHierarchyDto>}
	 * The item found via this get method.
	 * @memberof OperationGroupHierarchyApiService
	 */
	public async get(
		_id: number): Promise<IOperationGroupHierarchyDto>
	{
		throw new Error(
			this.getHandledByOthersMessage(
				AppConstants.apiMethods.get,
				AppConstants.apiControllers.operationGroups
					+ ` ${AppConstants.apiMethods.getChildren} `
					+ `or ${AppConstants.apiMethods.getParents}`));
	}

	/**
	 * Creates an IOperationGroupHierarchyDto with the specified information.
	 * This method is not implemented in the API.
	 *
	 * @param {IOperationGroupHierarchyDto} _entity
	 * The operation group hierarchy to create.
	 * @returns {Promise<number>}
	 * The newly created item identifier.
	 * @memberof OperationGroupHierarchyApiService
	 */
	public async create(
		_entity: IOperationGroupHierarchyDto): Promise<number>
	{
		throw new Error(
			this.getHandledByOthersMessage(
				AppConstants.apiMethods.createChild,
				AppConstants.apiControllers.operationGroups));
	}

	/**
	 * Updates an IOperationGroupHierarchyDto with the specified information.
	 * This method is not implemented in the API.
	 *
	 * @param {number} _id
	 * Id of the operation group hierarchy to update.
	 * @param {IOperationGroupHierarchyDto} _entity
	 * The operation group hierarchy to update.
	 * @returns {Promise<object>}
	 * Updated item identifier
	 * @memberof OperationGroupHierarchyApiService
	 */
	public async update(
		_id: number,
		_entity: IOperationGroupHierarchyDto): Promise<object>
	{
		throw new Error(
			this.getHandledByOthersMessage(
				AppConstants.apiMethods.updateChild,
				AppConstants.apiControllers.operationGroups));
	}

	/**
	 * Deletes an IOperationGroupHierarchyDto with the specified identifier.
	 * This method is not implemented in the API.
	 *
	 * @param {number} id
	 * The identifier of the operation group hierarchy instance to delete.
	 * @returns {Promise<object>}
	 * An observable of the delete no-content response.
	 * @memberof OperationGroupHierarchyApiService
	 */
	public async delete(
		_id: number): Promise<object>
	{
		throw new Error(
			this.getHandledByOthersMessage(
				AppConstants.apiMethods.deleteChild,
				AppConstants.apiControllers.operationGroups));
	}
}