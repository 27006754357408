/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AfterViewInit,
	ChangeDetectorRef,
	Component
} from '@angular/core';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ExtendedCustomControlDirective
} from '@entity/directives/extended-custom-control.directive';
import {
	WindowEventConstants
} from '@shared/constants/window-event.constants';

@Component({
	selector: 'custom-input-password',
	templateUrl: './custom-input-password.component.html',
	styleUrls: [
		'./custom-input-password.component.scss'
	],
})

/**
 * A component representing an instance of a Custom Input Password.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomInputPasswordComponent
 * @extends {ExtendedCustomControlDirective}
 * @implements {AfterViewInit}
 */
export class CustomInputPasswordComponent
	extends ExtendedCustomControlDirective
	implements AfterViewInit
{
	/** Initializes a new instance of the CustomInputPasswordComponent.
	 *
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof CustomInputPasswordComponent
	 */
	public constructor(
		public changeDetector: ChangeDetectorRef)
	{
		super(changeDetector);
	}

	/**
	 * Gets or sets the enable or disable the mask toogle display.
	 *
	 * @type {boolean}
	 * @memberof CustomInputPasswordComponent
	 */
	public enableMaskToogle: boolean = false;

	/**
	 * Sets an event into the pasword input field to handle the
	 * enable or disable the toogle mask display.
	 *
	 * @memberof CustomInputPasswordComponent
	 */
	public ngAfterViewInit(): void
	{
		const element: HTMLInputElement =
			document.querySelector('.password-input');
		const handleKeyUpEvent: EventListenerOrEventListenerObject =
			() =>
			{
				const passwordInput = element.value;
				this.enableMaskToogle = !AnyHelper.isNullOrEmpty(passwordInput);
				this.validateControl();
			};

		element.addEventListener(
			WindowEventConstants.keyup,
			handleKeyUpEvent);
	}
}