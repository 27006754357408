/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BusinessLogicEntity
} from '@shared/business-logic-entities/business-logic-entity';
import {
	Component
} from '@angular/core';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	IWizardStep
} from '@shared/interfaces/dynamic-interfaces/wizard-step-context.interface';
import {
	ObjectArrayHelper
} from '@shared/helpers/object-array.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	StatusReasonsDirective
} from '@insurance/directives/status-reasons.directive';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/* eslint-enable max-len */

@Component({
	selector: 'transaction-decline-reasons',
	templateUrl: './transaction-decline-reasons.component.html'
})

/**
 * A component representing a wizard step for the cancel transaction.
 *
 * @export
 * @class TransactionCancelReasonsComponent
 * @extends {StatusReasonsDirective}
 */
export class TransactionDeclineReasonsComponent
	extends StatusReasonsDirective
{
	/**
	 * Gets or sets a client message to display if this wizard is not valid
	 * to be completed.
	 *
	 * @type {string}
	 * @memberof TransactionDeclineReasonsComponent
	 */
	public clientMessage: string =
		'Declination is not allowed.';

	/**
	 * Gets or sets true/false if the current policy term is allowing the
	 * declination process.
	 *
	 * @type {boolean}
	 * @memberof TransactionDeclineReasonsComponent
	 */
	public allowedTransactionDecline: boolean;

	/**
	 * Gets a value of true indicating if the user
	 * can decline. False otherwise.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * the promise holding the value.
	 * @memberof TransactionDeclineReasonsComponent
	 */
	public async userCanDecline(): Promise<boolean>
	{
		return this.insuranceService
			.getTransaction(this.entityInstance)
			.userCanDecline();
	}

	/**
	 * Allows additional actions following the shared status reasons directive
	 * on init.
	 *
	 * @memberof TransactionDeclineReasonsComponent
	 */
	public async performPostInitActions(): Promise<void>
	{
		this.allowedTransactionDecline = await this.userCanDecline();

		this.workflowActionName =
			InsuranceConstants.workflowActions.transactionDecline;

		const currentData: any =
			this.context.source.activeMenuItem.currentData;

		const selectedReasons: any =
			AnyHelper.isNull(currentData.data.selectedReasons)
				? []
				: currentData.data.selectedReasons;

		const availableReasons: any[] =
			this.getReasons(
				this.productEntityInstance.data.reasons,
				InsuranceConstants.statusReasonTypes.rejection)
				.filter(reason => reason.isDeclinationReason);

		availableReasons.forEach(
			(reason: any) =>
			{
				const cancelReasonType: string =
					StringHelper.beforeCapitalSpaces(
						reason.cancelReasonType);

				reason.detailedDescription = AnyHelper
					.isNullOrEmpty(cancelReasonType)
					? reason.description
					: `${cancelReasonType} -  ${reason.description}`;
			});

		this.context.source.addOrUpdateStepData(
			{
				availableReasons: availableReasons,
				selectedReasons: selectedReasons,
				statusEntityId: this.entityInstanceId,
				statusEntityTypeGroup: this.entityType.group,
				isCalculatedEffectiveDate: true
			});

		this.context.source.addToNext(this.decline.bind(this));

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * This will send the transaction declination event and navigate
	 * to the policy dashboard.
	 *
	 * @async
	 * @memberof TransactionDeclineReasonsComponent
	 */
	public async decline(): Promise<void>
	{
		await this.declineTransaction();
		await this.refreshPolicyTermRules();
		await this.navigateToPolicyDashboard();
	}

	/**
	 * Refreshes the policy term rules by saving it.
	 *
	 * @async
	 * @memberof TransactionDeclineReasonsComponent
	 */
	public async refreshPolicyTermRules(): Promise<void> {
		const policyTerm = this.entityService
			.getBusinessLogicEntity(
				await this.getPolicyTerm());

		await policyTerm.save();
	}

	/**
	 * This will decline the transaction
	 *
	 * @async
	 * @memberof TransactionDeclineReasonsComponent
	 */
	public declineTransaction()
	{
		const displayName: string =
			new EntityType(this.entityType)
				.displayName;

		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;

		this.entityInstance.data.reasons = [];

		setTimeout(
			() =>
			{
				this.context.source.wizardStepLoading = true;
			});

		return this.activityService.handleActivity(
			new Activity(
				new Promise(async(resolve: any, _reject: any) =>
				{
					await this.executeWorkflowAction(
						this.entityType,
						this.entityInstance,
						this.getQueryString(currentData));

					resolve();
				}),
				'<strong>Processing declination</strong>',
				'<strong>Declination processed</strong>',
				`${displayName} has been declined.`,
				`${displayName} has not been declined.`));
	}

	/**
	 * This will validate if the selected cancel reasons has the same cancel
	 * reason type.
	 *
	 * @async
	 * @returns {boolean}
	 * True if all the selected cancel reasons has the same cancel reason type,
	 * otherwise will return false.
	 * @memberof TransactionDeclineReasonsComponent
	 */
	public hasValidSelectedReasons(): boolean
	{
		const currentData: any =
			this.context.source.activeMenuItem.currentData;

		if (currentData.data.selectedReasons.length === 0)
		{
			return false;
		}

		const cancelReasons: any[] =
			currentData.data.selectedReasons;

		const uniqueObject =
			[
				...new Set(cancelReasons.map((declineReason: any) =>
					declineReason.declinationReasonType))
			];

		const result: boolean =
			uniqueObject.length === 1;

		const activeWizardStep: IWizardStep =
			this.context.source.activeWizardStep;

		activeWizardStep.nextTooltipLabel =
			result === false
				? 'You can only add multiple reasons of the same '
					+ 'decline reason type'
				: null;

		return result;
	}

	/**
	 * Get the quesry string.
	 *
	 * @param {any} currentData
	 * @returns {string}
	 * the query string.
	 * @memberof TransactionDeclineReasonsComponent
	 */
	public getQueryString(
		currentData: any): string
	{
		const selectedReasons: string =
			ObjectArrayHelper.commaSeparatedPropertyValues(
				currentData.selectedReasons,
				AppConstants.commonProperties.id);

		return AnyHelper.isNullOrEmpty(currentData.comments)
			? `?reasons=${selectedReasons}`
			: `?comments=${currentData.comments}`
				+ `&reasons=${selectedReasons}`
				+ '&noteType=Note';
	}

	/**
	 * This will navigate to the policy dashboard using the
	 * policy term id aquired from the transaction.
	 *
	 * @async
	 * @memberof TransactionDeclineReasonsComponent
	 */
	private async navigateToPolicyDashboard()
	{
		const policyTermInstance: IEntityInstance =
			await this.insuranceService
				.getPolicyTermByTransaction(
					this.entityInstance.id,
					this.entityInstance.entityType);

		const policyTerm: BusinessLogicEntity =
			this.entityService.getBusinessLogicEntity(policyTermInstance);

		const policyTermType: IEntityType =
			await policyTerm.getEntityTypeAsync();

		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				policyTermType.group,
				AppConstants.viewTypes.edit,
				policyTerm.id
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}
}