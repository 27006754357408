/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	EntityInstanceRuleViolation
} from '@shared/implementations/entities/entity-instance-rule-violation';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IRuleDefinition
} from '@shared/interfaces/rules/rule-definition.interface';
import {
	IRulePresentationDefinition
} from '@shared/interfaces/rules/rule-presentation-definition.interface';
import {
	RuleListComponent
} from '@shared/dynamic-components/rules/rule-list/rule-list.component';
import {
	RuleService
} from '@shared/services/rule.service';
import {
	RuleWorkflowDisplayDirective
} from '@shared/directives/rule-workflow-display.directive';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/* eslint-enable max-len */

@Component({
	selector: 'app-rule-list-item',
	templateUrl: './rule-list-item.component.html',
	styleUrls: [
		'./rule-list-item.component.scss'
	]
})

/**
 * A component representing a context level rule list item view.
 *
 * @export
 * @class RuleListItemComponent
 * @extends {RuleWorkflowDisplayDirective}
 * @implements {OnInit}
 * @implements {IDynamicComponent<Component, any>}
 */
export class RuleListItemComponent
	extends RuleWorkflowDisplayDirective
	implements OnInit, IDynamicComponent<Component, any>
{
	/**
	 * Initializes a new instance of the rule list item component.
	 *
	 * @param {RuleService} ruleService
	 * The rule service used in this component.
	 * @memberof RuleListComponent
	 */
	public constructor(
		public ruleService: RuleService)
	{
		super();
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof RuleListItemComponent
	 */
	@Input() public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the rule definition for this violation list item.
	 *
	 * @type {IRuleDefinition}
	 * @memberof RuleListItemComponent
	 */
	public ruleDefinition: IRuleDefinition;

	/**
	 * Gets or sets the unique rule presentation definitions for this violation
	 * list item. This set only holds one presentation definition value for each
	 * associated data key in order to show associated controls.
	 *
	 * @type {IRulePresentationDefinition[]}
	 * @memberof RuleListItemComponent
	 */
	public rulePresentationDefinitions:
		IRulePresentationDefinition[] = [];

	/**
	 * Gets or sets the loading value of tables displayed in this list item.
	 *
	 * @type {boolean}
	 * @memberof RuleListItemComponent
	 */
	public loadingTables: boolean = true;

	/**
	 * Handles the on initialization event.
	 * This will load the data needed to display a rule list item.
	 *
	 * @async
	 * @memberof RuleListItemComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		const entityInstanceRuleViolation: EntityInstanceRuleViolation =
			new EntityInstanceRuleViolation(this.context.data);

		this.ruleDefinition =
			entityInstanceRuleViolation.ruleDefinition;

		this.ruleViolationWorkflowActions =
			entityInstanceRuleViolation
				.ruleDefinition
				.ruleViolationWorkflowActionDefinitions;

		this.rulePresentationDefinitions =
			await this.ruleService
				.getUniqueViolationPresentationDefinitions(
					entityInstanceRuleViolation);

		this.overrideAllowed = this.getOverrideAllowed();
		this.loadingTables = false;
	}

	/**
	 * This method will navigate to and highlight the control displayed in the
	 * list of presentation definitions. This will always navigate to the
	 * control that is a descendant of the current rule violations resource
	 * identifier to allow for repeater based lookups.
	 * @note If the control is not located due to permissions or removed items,
	 * this will display a banner to the user that this control can not be
	 * found.
	 *
	 * @param {IRulePresentationDefinition} selectedItem
	 * The rule presentation definition row display that sent this navigate to
	 * control event.
	 * @memberof RuleListItemComponent
	 */
	public navigateToControl(
		selectedItem: IRulePresentationDefinition): void
	{
		const matchingResourceIdentifier: any =
			document.querySelector(
				`label[data-resource-identifier=
					"${this.context.data.resourceIdentifier}"]`)
				?.parentElement // div
				.parentElement // custom control
				.parentElement // formly field
				.parentElement; // Base level or repeater level

		const splitKey: string[] =
			selectedItem.dataKey.split(AppConstants.characters.period);

		const matchingItem: any =
			matchingResourceIdentifier?.querySelector(
				`[data-key="${selectedItem.dataKey}"]`)
				?? matchingResourceIdentifier?.querySelector(
					`[data-key="${splitKey[splitKey.length - 1]}"]`);

		if (!AnyHelper.isNull(matchingItem))
		{
			this.openTab(
				matchingItem);
			this.expandContainers(
				matchingItem);

			if (matchingItem.classList.contains(
				AppConstants.cssClasses.sectionTitle))
			{
				this.displayElement(
					matchingItem);

				return;
			}

			let wrappingDiv: Element =
				matchingItem
					.parentElement
					.parentElement
					.parentElement;

			// Handle non standard controls.
			let currentAttempt: number = 1;
			const maximumAttempts: number = 5;
			while (!wrappingDiv.classList.contains(
				FormlyConstants.customControls.customFieldWrapper)
				&& currentAttempt <= maximumAttempts)
			{
				wrappingDiv = wrappingDiv.parentElement;
				currentAttempt++;
			}

			const nestedInput: any =
				matchingItem.querySelector(
					FormlyConstants.customControls.input);
			const matchingElement: any =
				(nestedInput || matchingItem);

			this.displayElement(
				wrappingDiv,
				matchingElement);
		}
		else
		{
			EventHelper.dispatchBannerEvent(
				'Control not displayed',
				'The current layout does not hold this control.',
				AppConstants.activityStatus.info);
		}
	}

	/**
	 * This method will set the view mode of the rule component to the override
	 * view.
	 *
	 * @memberof RuleListItemComponent
	 */
	public displayOverride(): void
	{
		const source: RuleListComponent =
			<RuleListComponent>this.context.source;

		source.changeSelectedItem.emit(
			this.context.data);
		source.changeDisplayMode.emit(
			AppConstants.displayMode.view);
	}

	/**
	 * This method will open the tab that this element to display is contained
	 * in.
	 *
	 * @param {any} elementToDisplay
	 * The element to be displayed.
	 * @memberof RuleListItemComponent
	 */
	public openTab(
		elementToDisplay: any): void
	{
		const tabKeyIdentifier: string = 'tab-key';
		const parentTabSection: HTMLDivElement =
			elementToDisplay.parentElement?.closest(
				`.${FormlyConstants.customControls.customTabContent}`);

		if (!AnyHelper.isNull(parentTabSection))
		{
			const tabKey: string =
				parentTabSection.attributes[
					tabKeyIdentifier]?.nodeValue;
			let tabElement: HTMLAnchorElement = null;

			document
				.querySelectorAll(
					`span.${AppConstants.cssClasses.tabViewTitle}`)
				.forEach(
					(element: Element) =>
					{
						if (element.textContent
							.includes(tabKey))
						{
							tabElement =
								<HTMLAnchorElement>element.parentElement;
						}
					});

			if (!AnyHelper.isNull(tabElement))
			{
				tabElement.click();
			}
		}
	}

	/**
	 * Given a sent element to display, this method will open all of the
	 * associated collapsed views in order to display a nested control in the
	 * layout.
	 *
	 * @param {any} elementToDisplay
	 * The element that will have all parent containers expanded.
	 * @memberof RuleListItemComponent
	 */
	public expandContainers(
		elementToDisplay: any): void
	{
		let matchFound: boolean = true;
		let currentElement = elementToDisplay;

		while (matchFound === true)
		{
			const parentAccordion: any =
				currentElement.parentElement
					.closest(
						'.p-accordion-tab');

			matchFound = !AnyHelper.isNull(parentAccordion);

			if (matchFound === true
				&& !parentAccordion.classList.contains(
					'p-accordion-tab-active'))
			{
				parentAccordion.querySelector(
					'.p-accordion-header-link')
					.click();
			}

			currentElement = parentAccordion;
		}
	}

	/**
	 * This method will navigate to and highlight the border element. This will
	 * also add a border highlight display to draw attention to the control
	 * and focus if a focusable element is sent.
	 *
	 * @param {Element} borderDisplayElement
	 * The element that will be scrolled to and will display a highlight
	 * error border.
	 * @param {any} focusElement
	 * The element to focus on if available in this controls API.
	 * @memberof RuleListItemComponent
	 */
	public displayElement(
		borderDisplayElement: Element,
		focusElement: any = null): void
	{
		setTimeout(
			() =>
			{
				borderDisplayElement.scrollIntoView(
					{
						block: 'center',
						inline: 'center'
					});

				borderDisplayElement.classList.add(
					AppConstants.cssClasses.selectedErrorContainerBorder);

				focusElement?.focus();
			},
			AppConstants.time.quarterSecond);

		setTimeout(
			() =>
			{
				borderDisplayElement.classList.remove(
					AppConstants.cssClasses.selectedErrorContainerBorder);
			},
			AppConstants.time.twoSeconds);
	}

	/**
	 * Creates and returns a formatted data key value to display in the
	 * available areas and fields table.
	 *
	 * @param {IRulePresentationDefinition} rulePresentationDefinition
	 * The rule presentation definition that will be parsed for a formatted
	 * data key.
	 * @returns {string}
	 * The markdown ready formatted data key value for display in the area
	 * and fields table.
	 * @memberof RuleListItemComponent
	 */
	public getFormattedDataKey(
		rulePresentationDefinition: IRulePresentationDefinition): string
	{
		return rulePresentationDefinition.dataKey
			.replace(
				AppConstants.nestedDataIdentifier,
				AppConstants.empty)
			.split(
				AppConstants.characters.period)
			.map(
				(dataKeyPart: string) =>
					StringHelper.beforeCapitalSpaces(
						StringHelper.toProperCase(
							dataKeyPart)))
			.join(
				'<i class="fa fa-fw fa-caret-right nested-field-icon "></i>');
	}
}