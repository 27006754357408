<label class="visually-hidden"
	[attr.for]="'AriaInput' + id">
	{{to.label}}
</label>
<ng-template
	[ngIf]="refreshCalendar === false">
	<p-calendar
		[(ngModel)]="displayDate"
		[formlyAttributes]="field"
		[inputStyleClass]="inputStyleClass"
		[showTime]="to.showTime"
		[disabled]="to.disabled"
		hourFormat="12"
		[minDate]="to.minDate"
		showIcon="true"
		showButtonBar="true"
		showSeconds="true"
		[appendTo]="to.appendTo"
		[panelStyleClass]="to.panelStyleClass"
		(onSelect)="dateChange($event)"
		(onInput)="dateChange($event)"
		(onClearClick)="dateChange($event)"
		[view]="to.view || 'date'"
		[dateFormat]="to.dateFormat || 'mm/dd/yy'">
	</p-calendar>
	<ng-template
		[ngIf]="to.quickDateSelection != null">
		<span
			[pTooltip]="to.quickDateSelection.tooltipOptions?.tooltipLabel"
			tooltipPosition="left"
			tooltipStyleClass="quick-date-tooltip">
			<button type="button"
				pButton
				pRipple
				class="p-button-outlined quick-date-selection ellipsis-text"
				[ngClass]="{
					'p-disabled': (to.quickDateSelection.disabled)
				}"
				[label]="to.quickDateSelection.label"
				[disabled]="to.quickDateSelection.disabled"
				(click)="displayDate = to.quickDateSelection.command($event); dateChange($event);">
			</button>
		</span>
	</ng-template>
</ng-template>

<field-validation-message
	[field]="field">
</field-validation-message>