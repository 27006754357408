/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonListDirective
} from '@shared/directives/common-list-directive';
import {
	Component
} from '@angular/core';
import {
	DynamicComponentLookup
} from '@dynamicComponents/dynamic-component.lookup';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	EntityInstanceRuleViolationApiService
} from '@api/services/entities/entity-instance-rule-violation.api.service';
import {
	ICommonListContext
} from '@shared/interfaces/dynamic-interfaces/common-list-context.interface';
import {
	ICommonListFilter
} from '@shared/interfaces/dynamic-interfaces/common-list-filter.interface';
import {
	ICommonListItem
} from '@shared/interfaces/dynamic-interfaces/common-list-item.interface';
import {
	ICommonListSort
} from '@shared/interfaces/dynamic-interfaces/common-list-sort.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IEntityInstanceRuleViolation
} from '@shared/interfaces/entities/entity-instance-rule-violation.interface';
import {
	IEntityInstanceRuleViolationDto
} from '@api/interfaces/entities/entity-instance-rule-violation.dto.interface';
import {
	IRuleViolationWorkflowActionDefinition
} from '@shared/interfaces/rules/rule-violation-workflow-action-definition.interface';
import {
	RuleService
} from '@shared/services/rule.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-rule-list',
	templateUrl: './rule-list.component.html',
	styleUrls: [
		'./rule-list.component.scss'
	]
})

/**
 * A component representing context level rules list view.
 *
 * @export
 * @class RuleListComponent
 * @extends {RuleListComponent<
 * 	IEntityInstanceRuleViolation,
 * 	IEntityInstanceRuleViolation>
 * }
 * @implements {IDynamicComponent<Component, any>}
 */
export class RuleListComponent
	extends CommonListDirective<
		IEntityInstanceRuleViolation,
		IEntityInstanceRuleViolationDto>
	implements IDynamicComponent<Component, any>
{
	/**
	 * Initializes a new instance of the rule list component.
	 *
	 * @param {EntityInstanceRuleViolationApiService}
	 * entityInstanceRuleViolationApiService
	 * The entity instance rule violation API service, used to get children.
	 * @param {RuleService} ruleService
	 * The rule service used in this component.
	 * @memberof RuleListComponent
	 */
	public constructor(
		public entityInstanceRuleViolationApiService:
			EntityInstanceRuleViolationApiService,
		public ruleService: RuleService)
	{
		super();
		this.apiService = entityInstanceRuleViolationApiService;
	}

	/**
	 * Gets or sets the list of common sorters for the list view.
	 *
	 * @type {ICommonListSort[]}
	 * @memberof RuleListComponent
	 */
	public sorters: ICommonListSort[] = [
		<ICommonListSort>{
			name: 'Rule Order',
			value: 'RuleDefinition.Order',
			direction: AppConstants.sortDirections.ascending,
			iconAsc: 'fa fa-sort-amount-asc',
			iconDesc: 'fa fa-sort-amount-desc'
		}];

	/**
	 * Gets or sets the identifier used for an action type of blocked.
	 *
	 * @type {string}
	 * @memberof RuleListComponent
	 */
	private readonly blockedIdentifier: string = 'Blocked';

	/**
	 * A method to generate an initial filter for all calls to this list.
	 *
	 * @rule
	 * This filter will be used for all calls, including those in the
	 * list context filter set.
	 *
	 * @returns {string}
	 * A filter value used for all data loads in this list.
	 * @memberof RuleListComponent
	 */
	public getPrimaryFilter(): string
	{
		return 'InstanceId eq ' +
			(<EntityInstanceComponent>this.context.source).entityInstance.id;
	}

	/**
	 * A method to generate the proper data promise for loading data.
	 *
	 * @param {string} filter
	 * A value representing the compiled filters from the common list filter
	 * array.
	 * @param {string} orderBy
	 * A value representing the compiled order by from the common list sort.
	 * @param {number} offset
	 * A value representing the current offset to load from. This value
	 * defaults to 0.
	 * @returns {Promise<IEntityInstanceRuleViolation[]>}
	 * A data promise with an applied filter, order, and offset.
	 * @memberof RuleListComponent
	 */
	public async getData(
		filter: string,
		orderBy: string,
		offset: number = 0): Promise<IEntityInstanceRuleViolation[]>
	{
		if (this.context.source instanceof EntityInstanceComponent)
		{
			const isAscendingOrder: boolean =
				this.sorter.direction === AppConstants.sortDirections.ascending;

			return (<EntityInstanceComponent>this.context.source)
				.entityInstanceRuleViolations
				.sort(
					(itemOne: IEntityInstanceRuleViolation,
						itemTwo: IEntityInstanceRuleViolation) =>
					{
						const mainInput: IEntityInstanceRuleViolation =
							isAscendingOrder
								? itemOne
								: itemTwo;
						const secondaryInput: IEntityInstanceRuleViolation =
							isAscendingOrder
								? itemTwo
								: itemOne;

						if (mainInput.ruleDefinition.order <
							secondaryInput.ruleDefinition.order)
						{
							return -1;
						}

						if (mainInput.ruleDefinition.order >
							secondaryInput.ruleDefinition.order)
						{
							return 1;
						}

						// Fall back to the rule violation age.
						return mainInput.id - secondaryInput.id;
					});
		}

		return this.ruleService.getPopulatedRuleViolations(
			filter,
			orderBy,
			offset,
			AppConstants.dataLimits.maxResultSet);
	}

	/**
	 * A method that generates the base rules common list context.
	 *
	 * @rule
	 * This method will generate on firstLoad the default common list context
	 * with properties used for rules.
	 *
	 * @param {ICommonListItem<IEntityInstanceRuleViolation>[]} data
	 * A value representing the data to load within the common list context.
	 * @returns {ICommonListContext<IEntityInstanceRuleViolation>}
	 * A value representing the common list context for first load.
	 * @memberof RuleListComponent
	 */
	public generateCommonListContext(
		data: ICommonListItem<IEntityInstanceRuleViolation>[]):
		ICommonListContext<IEntityInstanceRuleViolation>
	{
		const listFilters: ICommonListFilter[] =
			<ICommonListFilter[]>
			[
				{
					name: 'Blocked',
					value: this.blockedIdentifier
				}
			];

		const generatedCommonListContext:
			ICommonListContext<IEntityInstanceRuleViolation> =
				<ICommonListContext<IEntityInstanceRuleViolation>>
				{
					data: data,
					listItemComponent:
						DynamicComponentLookup.supportedTypes
							.ruleListItemComponent,
					supportMarkdown: true,
					searchable: true,
					sortable: true,
					sorters: this.sorters,
					searchFilterFormat: '${inputValue}',
					actions: [],
					filters: listFilters,
					onFilterChange: (source, filters) =>
						this.handleFilterChange(source, filters),
					onSortChange: (source, sorter) =>
						this.handleSortingChange(source, sorter)
				};

		return generatedCommonListContext;
	}

	/**
	 * A method that maps entity instance data to a common list item.
	 *
	 * @param {IEntityInstanceRuleViolation} entityInstanceRuleViolation
	 * A value representing the entity instance rule violation to map to
	 * a common list item.
	 * @returns {ICommonListItem<IEntityInstanceRuleViolation>}
	 * A value representing the mapped common list item from the instance.
	 * @memberof RuleListComponent
	 */
	public mapToListItem(
		entityInstanceRuleViolation: IEntityInstanceRuleViolation):
		ICommonListItem<IEntityInstanceRuleViolation>
	{
		const violationActionDefinitions:
			IRuleViolationWorkflowActionDefinition[] =
			entityInstanceRuleViolation
				.ruleDefinition
				.ruleViolationWorkflowActionDefinitions;

		const violationActionDefinitionsLength: number =
			violationActionDefinitions.length;
		const nonBlockingViolationsLength: number =
			violationActionDefinitions.filter(
				(ruleViolationWorkflowAction:
					IRuleViolationWorkflowActionDefinition) =>
					ruleViolationWorkflowAction.blocked === false).length;
		const blockingViolationsLength: number =
			violationActionDefinitions.filter(
				(ruleViolationWorkflowAction:
					IRuleViolationWorkflowActionDefinition) =>
					ruleViolationWorkflowAction.blocked === true
						&& ruleViolationWorkflowAction
							.overridden === false).length;
		const overriddenViolationsLength: number =
			violationActionDefinitions.filter(
				(ruleViolationWorkflowAction:
					IRuleViolationWorkflowActionDefinition) =>
					ruleViolationWorkflowAction.blocked === true
						&& ruleViolationWorkflowAction
							.overridden === true).length;

		const noneBlocking: boolean =
			nonBlockingViolationsLength === violationActionDefinitionsLength;
		const blockedWithNoOverrides: boolean =
			blockingViolationsLength > 0
				&& overriddenViolationsLength === 0;
		const blockedWithPartialOverrides: boolean =
			blockingViolationsLength > 0
				&& overriddenViolationsLength > 0;
		const blockedWithAllOverrides: boolean =
			blockingViolationsLength === 0
				&& overriddenViolationsLength ===
					violationActionDefinitionsLength -
						nonBlockingViolationsLength;

		let iconClass: string;
		let colorClass: string;
		switch (true)
		{
			case noneBlocking:
				iconClass = 'flag-o';
				colorClass = AppConstants.cssClasses.textSecondaryColor;
				break;
			case blockedWithNoOverrides:
				iconClass = 'flag';
				colorClass = AppConstants.cssClasses.textErrorColor;
				break;
			case blockedWithPartialOverrides:
				iconClass = 'flag-checkered';
				colorClass = AppConstants.cssClasses.textErrorColor;
				break;
			case blockedWithAllOverrides:
				iconClass = 'flag-o';
				colorClass = AppConstants.cssClasses.textSuccessColor;
				break;
		}

		return <ICommonListItem<IEntityInstanceRuleViolation>>
			{
				item: entityInstanceRuleViolation,
				descriptionLineFormat:
					`<i class="fa fa-fw fa-${iconClass} ${colorClass} `
						+ 'item-description-icon"></i>'
						+ `**${entityInstanceRuleViolation
							.ruleDefinition.displayName}**`,
				informationIcons: [],
				actions: []
			};
	}

	/**
	 * A method to generate the data promise for loading rules data.
	 *
	 * @async
	 * @param {ICommonListFilter[]} [filters]
	 * A value representing the enabled filters to apply to the data promise
	 * query.
	 * @param {ICommonListSort} [sorter]
	 * A value representing the enabled sorter to apply to the data promise
	 * query.
	 * @returns {Promise<IEntityInstanceRuleViolation[]>}
	 * A data promise with a filter, order, and offset.
	 * @memberof RuleListComponent
	 */
	public async loadItems(
		filters?: ICommonListFilter[],
		sorter?: ICommonListSort): Promise<IEntityInstanceRuleViolation[]>
	{
		this.enabledFilters = filters;

		let orderBy: string;
		if (AnyHelper.isNull(sorter) === false)
		{
			orderBy = `${sorter.value} ${sorter.direction}`;
		}

		if (this.context.source instanceof EntityInstanceComponent)
		{
			return this.getData(
				this.getPrimaryFilter(),
				orderBy);
		}

		return null;
	}

	/**
	 * This action is called to filter items after the rules are initially
	 * loaded.
	 *
	 * @memberof RuleListComponent
	 */
	public performPostLoadActions(): void
	{
		this.commonListContext.data.data =
			this.filterItems();
	}

	/**
	 * This filter items method is used to return all rules with
	 * entries matching the current filter set.
	 *
	 * @returns {ICommonListItem<IEntityInstanceRuleViolation>[]}
	 * The set of filtered items matching the current filter set.
	 * @memberof RuleListComponent
	 */
	public filterItems(): ICommonListItem<IEntityInstanceRuleViolation>[]
	{
		let combinedFilters: string[] = [];
		if (AnyHelper.isNull(this.enabledFilters) === false)
		{
			combinedFilters =
				this.enabledFilters.map(
					(item: ICommonListFilter) =>
						item.value);
		}

		const filteredData:
			ICommonListItem<IEntityInstanceRuleViolation>[] = [];

		for (const commonListItem of this.commonListContext.data.data)
		{
			const combinedFilterValue: string =
				this.getCombinedFilterValue(commonListItem)
					.toLowerCase();

			let matchingItem: boolean = true;
			combinedFilters.forEach(
				(filterValue: string) =>
				{
					matchingItem = matchingItem
						&& (filterValue === this.blockedIdentifier
							? commonListItem.item.ruleDefinition
								.ruleViolationWorkflowActionDefinitions
								.filter((violationWorkflowAction:
									IRuleViolationWorkflowActionDefinition) =>
									violationWorkflowAction
										.blocked === true
										&& violationWorkflowAction
											.overridden === false).length > 0
							: combinedFilterValue.indexOf(
								filterValue.toLowerCase()) !== -1);
				});

			if (matchingItem === true)
			{
				filteredData.push(commonListItem);
			}
		}

		return filteredData;
	}

	/**
	 * This method will get and return a combined filter value per item
	 * calculated based on the currently displayed rule list item.
	 *
	 * @param {ICommonListItem<IEntityInstanceRuleViolation>}
	 * ruleViolationListItem
	 * The rule violation list item to be mapped for a combined filter value.
	 * @returns {string}
	 * The combined string of available values found in the list item used
	 * to check for matching filters.
	 * @memberof RuleListComponent
	 */
	public getCombinedFilterValue(
		ruleViolationListItem:
			ICommonListItem<IEntityInstanceRuleViolation>): string
	{
		const ruleDescription: string =
			ruleViolationListItem.item.ruleDefinition.description
				|| AppConstants.empty;
		const violationDescriptionLine: string =
			ruleViolationListItem.descriptionLineFormat;
		const combinedActionDefinitionNames: string =
			ruleViolationListItem.item.ruleDefinition
				.ruleViolationWorkflowActionDefinitions
				.map(
					(violationWorkflowAction:
						IRuleViolationWorkflowActionDefinition) =>
						violationWorkflowAction
							.workflowActionDefinition
							.name)
				.join(AppConstants.characters.comma);

		return `${ruleDescription},${violationDescriptionLine},`
			+ combinedActionDefinitionNames;
	}
}