/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	EntityDefinition
} from '@shared/implementations/entities/entity-definition';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	IEntityTypeDto
} from '@api/interfaces/entities/entity-type.dto.interface';
import {
	ISecurityEntityTypeDefinition
} from '@shared/interfaces/security/security-entity-type-definition.interface';
import {
	ISecurityItemDto
} from '@api/interfaces/security/security-item.dto.interface';
import {
	ISecurityRightDto
} from '@api/interfaces/security/security-right.dto.interface';

/**
 * A class representing the helper methods and properties
 * for security access related processes and items.
 *
 * @export
 * @class SecurityHelper
 */
export class SecurityHelper
{
	/**
	 * Gets the string '.*' wildcard identifier.
	 *
	 * @static
	 * @type {string}
	 * @memberof SecurityHelper
	 */
	public static wildcardIdentifier: string =
		AppConstants.wildcardIdentifier;

	/**
	 * Gets the regular expression that finds wildcards.
	 *
	 * @static
	 * @readonly
	 * @type {RegExp}
	 * @memberof SecurityHelper
	 */
	private static readonly wildCardFinder: RegExp = new RegExp(
		'(?<wildCardPath>(\\$\\.data){1}(\\.[\\w]+)*)(\\.[\\*]{1}){1}');

	/**
	 * Gets the string '$.data.' data path prefix.
	 *
	 * @static
	 * @type {string}
	 * @memberof SecurityHelper
	 */
	private static readonly dataPathPrefix: string =
		AppConstants.nestedDataKeyPrefix
			+ AppConstants.nestedDataIdentifier;

	/**
	 * Gets the security rights for a given data property.
	 *
	 * @static
	 * @param {string} pathToFind
	 * The data item path on which to find the rights.
	 * @param {ISecurityItemDto[]} dataSecurityPermissions
	 * The data object permissions to check.
	 * @returns {ISecurityItemDto}
	 * the wildcard paths sorted from most granular (most depth) first
	 * to least granular ($.data) last.
	 * @memberof SecurityHelper
	 */
	public static getDataSecurityRights(
		pathToFind: string,
		dataSecurityPermissions: ISecurityItemDto[]): ISecurityItemDto
	{
		let permission: ISecurityItemDto = dataSecurityPermissions.find(
			(item: ISecurityItemDto) => item.path === pathToFind);

		if (!AnyHelper.isNull(permission)) {
			return permission;
		}

		if (pathToFind.endsWith(
			AppConstants.characters.period
				+ AppConstants.commonProperties.resourceIdentifier))
		{
			return <ISecurityItemDto>
				{
					path: pathToFind,
					rights: <ISecurityRightDto>
						{
							create: true,
							read: true,
							update: false,
							delete: false
						}
				};
		}

		const wildcards: ISecurityItemDto[] =
			this.getWildCardPermissions(dataSecurityPermissions);

		permission = wildcards
			.find((wildcard: ISecurityItemDto) =>
				pathToFind.startsWith(wildcard.path)
				|| pathToFind.startsWith(
					wildcard.path.replace(
						this.wildcardIdentifier,
						AppConstants.empty)));

		return permission;
	}

	/**
	 * Gets the security rights for a given action.
	 *
	 * @static
	 * @param {string} pathToFind
	 * The data item path on which to find the rights.
	 * @param {ISecurityItemDto[]} actionSecurityPermissions
	 * The data object permissions to check.
	 * @returns {ISecurityItemDto}
	 * the security path and rights for this action path to find.
	 * @memberof SecurityHelper
	 */
	public static getActionSecurityRights(
		pathToFind: string,
		actionSecurityPermissions: ISecurityItemDto[]): ISecurityItemDto
	{
		if (AnyHelper.isNullOrEmptyArray(actionSecurityPermissions))
		{
			return this.getNullRights(pathToFind);
		}

		const permission: ISecurityItemDto = actionSecurityPermissions.find(
			(item: ISecurityItemDto) => item.path === pathToFind);

		if (AnyHelper.isNullOrEmpty(permission))
		{
			return this.getNullRights(pathToFind);
		}

		return permission;
	}

	/**
	 * Gets the index of the security right for the given data path.
	 *
	 * @static
	 * @param {string} dataPath
	 * The data item path on which to find the index.
	 * @param {ISecurityItemDto[]} dataSecurityPermissions
	 * The data object permissions to check.
	 * @returns {number}
	 * the index to the best match security rights.
	 * @memberof SecurityHelper
	 */
	public static getSecurityPermissionIndex(
		dataPath: string,
		dataSecurityPermissions: ISecurityItemDto[]): number
	{
		const pathToFind: string = dataPath.startsWith(this.dataPathPrefix)
			? dataPath
			: this.dataPathPrefix + dataPath;

		let index: number = dataSecurityPermissions.findIndex(
			(data: ISecurityItemDto) => data.path === pathToFind);

		if (index > -1)
		{
			return index;
		}

		const foundWildCard: string = this
			.getWildCardPaths(dataSecurityPermissions)
			.find(path => dataPath.startsWith(path));

		if (AnyHelper.isNull(foundWildCard)) {
			return -1;
		}

		index = dataSecurityPermissions.findIndex(
			(data: ISecurityItemDto) =>
				data.path === foundWildCard + this.wildcardIdentifier);

		return index;
	}

	/**
	 * Gets a set entity type security rights.
	 *
	 * @param {number} parentId
	 * The path that should have the null rights.
	 * @param {string} parentTypeGroup
	 * The parent entity type goup.
	 * @param {string} childTypeWildCard
	 * The child wildcard entity type name.
	 * @param {EntityDefinition}
	 * The parent entity definition containbing the supported child types.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * An instance of entity instance api service.
	 * @returns {Promise<ISecurityEntityTypeDefinition[]>}
	 * The entity tyype security rights.
	 * @memberof SecurityHelper
	 */
	public static async getSupportedChildPermissions(
		parentId: number,
		parentTypeGroup: string,
		childTypeWildCard: string,
		entityDefinition: EntityDefinition,
		entityInstanceApiService: EntityInstanceApiService,
		entityTypeApiService: EntityTypeApiService):
		Promise<ISecurityEntityTypeDefinition[]>
	{
		const childBaseType: string =
			childTypeWildCard.replace(
				'.*',
				AppConstants.empty);

		const supportedTypeNames: string[] =
			entityDefinition.supportedChildTypes
				.filter((typeName: string) =>
					typeName === childBaseType
					|| typeName.startsWith(`${childBaseType}.`));

		if (AnyHelper.isNullOrEmptyArray(supportedTypeNames))
		{
			return [];
		}

		const supportedEntityTypes = await entityTypeApiService
			.query(
				`(Name IN ("${supportedTypeNames.join('","')}"))`,
				'Id');

		const supportedEntityTypeGroups: string[] =
			supportedEntityTypes.map(
				(entityType: IEntityTypeDto) =>
					entityType.group);

		entityInstanceApiService
			.entityInstanceTypeGroup = parentTypeGroup;

		const permissions: ISecurityEntityTypeDefinition[] =
			await entityInstanceApiService
				.getHierarchyPermissions(
					parentId,
					supportedEntityTypeGroups);

		return permissions
			.filter((permission: ISecurityEntityTypeDefinition) =>
				supportedTypeNames.includes(permission.entityTypeName));
	}

	/**
	 * Gets a set of null rights with the path given.
	 *
	 * @param path
	 * The path that should have the null rights.
	 * @returns {ISecurityItemDto}
	 * The null security rights item.
	 * @memberof SecurityHelper
	 */
	public static getNullRights(
		path: string): ISecurityItemDto
	{
		const nullRights: ISecurityItemDto =
			<ISecurityItemDto>
			{
				path,
				rights: <ISecurityRightDto>
					{
						create: null,
						read: null,
						update: null,
						delete: null,
						execute: null
					}
			};

		return nullRights;
	}

	/**
	 * sorts wildcard permissions from most granular first
	 * to least granular ($.data) last.
	 *
	 * @static
	 * @param {ISecurityItemDto[]} dataSecurityPermissions
	 * The data object permissions to sort.
	 * @returns {ISecurityItemDto[]}
	 * the sorted rights
	 * @memberof SecurityHelper
	 */
	private static sort(
		dataSecurityPermissions: ISecurityItemDto[]): ISecurityItemDto[]
	{
		const period: string = AppConstants.characters.period;

		const sortedPermissions: ISecurityItemDto[] =
			Array.from(dataSecurityPermissions);

		return sortedPermissions
			.sort(
				function (itemA, itemB)
				{
					return itemB.path.split(period).length
						- itemA.path.split(period).length;
				});
	}

	/**
	 * Gets the set of wildcard permissions.
	 *
	 * @static
	 * @param {ISecurityItemDto[]} dataSecurityPermissions
	 * The data objetc permissions to check.
	 * @returns {ISecurityItemDto[]}
	 * the wildcard permissions sorted from most granular (most depth) first
	 * to least granular ($.data) last.
	 * @memberof SecurityHelper
	 */
	private static getWildCardPermissions(
		dataSecurityPermissions: ISecurityItemDto[]): ISecurityItemDto[] {
		return this
			.sort(dataSecurityPermissions)
			.filter((data: ISecurityItemDto) =>
				this.wildCardFinder.test(data.path));
	}

	/**
	 * Gets the set of wildcard paths (no asterik).
	 *
	 * @static
	 * @param {ISecurityItemDto[]} dataSecurityPermissions
	 * The data objetc permissions to check.
	 * @returns {string[]}
	 * the wildcard paths sorted from most granular (most depth) first
	 * to least granular ($.data) last.
	 * @memberof SecurityHelper
	 */
	private static getWildCardPaths(
		dataSecurityPermissions: ISecurityItemDto[]): string[] {
		return this.getWildCardPermissions(dataSecurityPermissions)
			.map((data: ISecurityItemDto) =>
				this.wildCardFinder
					.exec(data.path)
					.groups
					.wildCardPath);
	}
}