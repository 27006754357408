/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	animate,
	keyframes,
	state,
	style,
	transition,
	trigger
} from '@angular/animations';
import {
	AppConstants
} from '@shared/constants/app.constants';

/**
 * The base content animation display. This display performs a slide
 * in from the left side of 10%.
 * When using this animation, this will be used as
 * [@contentAnimation]="state".
 */
export const ContentAnimation =
	trigger('contentAnimation', [
		state('in',
			style({
				transform: AppConstants.contentAnimation.translateXZero
			})),
		transition('void => *', [
			animate(
				750,
				keyframes([
					style({
						opacity: 0,
						transform: AppConstants.contentAnimation
							.translateXMinusFivePercent,
						offset: 0
					}),
					style({
						transform: AppConstants.contentAnimation
							.translateXMinusTwoPointFivePercent,
						offset: 0.3
					}),
					style({
						opacity: 1,
						transform: AppConstants.contentAnimation.translateXZero,
						offset: 1.0
					})
				]))
		]),
		transition('* => void', [
			animate(
				0,
				keyframes([
					style({
						opacity: 1,
						transform: AppConstants.contentAnimation.translateXZero,
						offset: 0
					}),
					style({
						transform: AppConstants.contentAnimation
							.translateXHundredPercent,
						offset: 1.0,
						opacity: 0
					})
				]))
		])
	]);

/**
 * The base drawer animation display. This display performs a slide
 * in from the right side and immediately hides on close.
 * When using this animation, this will be used as
 * [@drawerAnimation]="state" or [@drawerAnimation]="truthy".
 *
 */
export const DrawerAnimation =
	trigger('drawerAnimation', [
		state('true',
			style({
				transform: 'translateX(-360px)'
			})),
		state('in',
			style({
				transform: AppConstants.contentAnimation.translateXZero
			})),
		state('false',
			style({
				transform: AppConstants.contentAnimation.translateXZero
			})),
		state('void',
			style({
				transform: AppConstants.contentAnimation
					.translateXHundredPercent
			})),
		state('*',
			style({
				transform: AppConstants.contentAnimation.translateXZero
			})),
		transition('false => true', [
			animate('0.25s ease-in-out')
		]),
		transition('true => false', [
			animate(AppConstants.contentAnimation.zeroEaseInOut)
		]),
		transition('* => void', [
			animate(AppConstants.contentAnimation.zeroEaseInOut)
		]),
		transition('void => *', [
			animate('0.25s ease-in-out')
		])
	]);

/**
 * The list drawer animation display. This display performs a slide
 * in from the right side and immediately hides on close but in this
 * case handles the container list of drawers.
 * When using this animation, this will be used as
 * [@listDrawerAnimation]="state".
 *
 */
export const ListDrawerAnimation =
	trigger('listDrawerAnimation', [
		state('in',
			style({
				transform: AppConstants.contentAnimation
					.translateXHundredPercent,
			})),
		transition('* => void', [
			animate(AppConstants.contentAnimation.zeroEaseInOut)
		]),
		transition('void => *', [
			animate(AppConstants.contentAnimation.zeroEaseInOut)
		])
	]);

/**
 * The shake animation display. This display shakes the
 * selected element using animation when set to 0.
 * When using this animation, this will be used as
 * [@shakeAnimation]="{truthy component property}".
 */
export const ShakeAnimation =
	trigger('shakeAnimation', [
		state('1',
			style({
				transform: AppConstants.contentAnimation.translateXZero
			})),
		state('0',
			style({
				transform: AppConstants.contentAnimation.translateXZeroPercent
			})),
		transition('0 => 1', [
			animate(
				500,
				keyframes([
					style({
						transform: AppConstants.contentAnimation
							.translateXZeroPercent,
						offset: 0
					}),
					style({
						transform: AppConstants.contentAnimation
							.translateXMinusTwoPointFivePercent,
						offset: .25
					}),
					style({
						transform: AppConstants.contentAnimation
							.translateXTwoPointFivePercent,
						offset: 0.5
					}),
					style({
						transform: AppConstants.contentAnimation
							.translateXMinusTwoPointFivePercent,
						offset: 0.75
					}),
					style({
						transform: AppConstants.contentAnimation.translateXZero,
						offset: 1.0
					})
				]))
		])
	]);

/**
 * The slide down animation display. This display will slide down to fit
 * the content available. When using this animation, this will be used as
 * [@slideDownAnimation]="{truthy component property} ? 'visible' : 'hidden'">
 */
export const SlideDownAnimation =
	trigger('slideDownAnimation', [
		state('hidden',
			style({
				height: '0px'
			})),
		state('visible',
			style({
				height: '*'
			})),
		transition(AppConstants.contentAnimation.transitionVisibleHidden,
			animate(AppConstants.contentAnimation.standardAnimation)),
		transition(AppConstants.contentAnimation.transitionHiddenVisible,
			animate(AppConstants.contentAnimation.standardAnimation))
	]);

/**
 * The slide down animation display. This display will slide right to fit
 * the content available. When using this animation, this will be used as
 * [@slideRightAnimation]="{truthy component property} ? 'visible' : 'hidden'">
 */
export const SlideRightAnimation =
	trigger('slideRightAnimation', [
		state('hidden',
			style({
				width: '0px'
			})),
		state('visible',
			style({
				width: '*'
			})),
		transition(AppConstants.contentAnimation.transitionVisibleHidden,
			animate(AppConstants.contentAnimation.standardAnimation)),
		transition(AppConstants.contentAnimation.transitionHiddenVisible,
			animate(AppConstants.contentAnimation.standardAnimation))
	]);

/**
 * The drop in animation display. This display will slide in from the top
 * and fade into view.
 * When using this animation, this will be used as
 * [@dropInAnimation]="'in'"
 */
export const DropInAnimation =
	trigger('dropInAnimation', [
		state('in',
			style({
				opacity: 1,
				top: '*'
			})),
		transition('void => *', [
			style({
				opacity: 0,
				top: 0
			}),
			animate(500)
		])
	]);

/**
 * The boolean based fade animation display. This display will fade in when
 * true or out when set to false.
 * When using this animation, this will be used as
 * [@booleanFadeAnimation]="truthy"
 */
export const BooleanFadeAnimation =
	trigger('booleanFadeAnimation', [
		state('true',
			style({
				opacity: 1 })),
		state('false',
			style({
				opacity: 0 })),
		transition('false <=> true',
			animate(500))
	]);

/**
 * The boolean based ease animation display. This display will fade in when
 * true or out when set to false. This should be used in tandem with the
 * display animation.
 * When using this animation, this will be used as
 * [@easeAnimation]="truthy"
 * [@displayAnimation]="truthy"
 */
export const EaseAnimation =
	trigger('easeAnimation', [
		state('hidden', style({
			opacity: 0
		})),
		state('visible', style({
			opacity: 1
		})),
		transition(AppConstants.contentAnimation.transitionVisibleHidden,
			animate('195ms ease-out')),
		transition(AppConstants.contentAnimation.transitionHiddenVisible,
			animate('195ms ease-in'))
	]);

/**
 * The boolean based display animation display. This display will handle
 * setting the display to none when the ease animation display completes.
 * This should be used in tandem with the ease animation.
 * When using this animation, this will be used as
 * [@easeAnimation]="truthy"
 * [@displayAnimation]="truthy"
 */
export const DisplayAnimation =
	trigger('displayAnimation', [
		state('hidden', style({
			display: 'none'
		})),
		state('visible', style({
			display: 'block'
		})),
		transition(AppConstants.contentAnimation.transitionVisibleHidden,
			animate('195ms')),
		transition(AppConstants.contentAnimation.transitionHiddenVisible,
			animate('0ms'))
	]);