/**
 * @copyright WaterStreet. All rights reserved.
 */

// tslint:disable: no-duplicate-imports

import {
	Embed,
	IEmbedConfiguration
} from 'powerbi-client';
import {
	Injectable
} from '@angular/core';
import * as pbi from 'powerbi-client';

/**
 * A singleton class representing a power bi service used to display
 * configured power bi elements.
 *
 * @export
 * @class PowerBiService
 */
@Injectable({
	providedIn: 'root'
})
export class PowerBiService
{
	/**
	 * Creates an instance of a helper class for interacting with
	 * the power bi javascript service.
	 * @see https://github.com/Microsoft/PowerBI-JavaScript/wiki
	 *
	 * @memberof PowerBiService
	 */
	public constructor()
	{
		this.powerBiCoreService =
			new pbi.service.Service(
				pbi.factories.hpmFactory,
				pbi.factories.wpmpFactory,
				pbi.factories.routerFactory);
	}

	/**
	 * Gets or sets the singleton core service to be used
	 * in this power bi service.
	 *
	 * @type {pbi.service.Service}
	 * @memberof PowerBiService
	 */
	private readonly powerBiCoreService: pbi.service.Service;

	/**
	 * Creates a new power bi report.
	 *
	 * @param {HTMLElement} element
	 * The element to create this report in.
	 * @param {IEmbedConfiguration} configuration
	 * The embed configuration to create.
	 * @returns {Embed}
	 * The embedded newly created report.
	 * @memberof PowerBiService
	 */
	public createReport(
		element: HTMLElement,
		configuration: IEmbedConfiguration): Embed
	{
		return this.powerBiCoreService
			.createReport(
				element,
				configuration);
	}

	/**
	 * Embeds a power bi report. If the element is already in use,
	 * this will reuse the existing element and iframe with
	 * a new report display.
	 *
	 * @param {HTMLElement} element
	 * The element to embed this report in.
	 * @param {IEmbedConfiguration} configuration
	 * The embed configuration to embed.
	 * @returns {Embed}
	 * The embedded report.
	 * @memberof PowerBiService
	 */
	public embed(
		element: HTMLElement,
		configuration: IEmbedConfiguration): Embed
	{
		return this.powerBiCoreService
			.embed(
				element,
				configuration);
	}

	/**
	 * Loads a new power bi report. If the element is already in use,
	 * this will reuse the existing element and iframe with a
	 * new report display. This is available for phased loads and the
	 * render event will be available following a successful load.
	 *
	 * @param {HTMLElement} element
	 * The element to load this report in.
	 * @param {IEmbedConfiguration} configuration
	 * The embed configuration to load.
	 * @returns {Embed}
	 * The loaded report.
	 * @memberof PowerBiService
	 */
	public load(
		element: HTMLElement,
		configuration: IEmbedConfiguration): Embed
	{
		return this.powerBiCoreService
			.load(
				element,
				configuration);
	}

	/**
	 * Returns an instance of the power bi display associated with
	 * the sent element.
	 *
	 * @param {HTMLElement} element
	 * The element to find a displayed report in.
	 * @returns {Embed}
	 * The embedded object displayed in the sent element.
	 * @memberof PowerBiService
	 */
	public get(
		element: HTMLElement): Embed
	{
		return this.powerBiCoreService
			.get(element);
	}

	/**
	 * Resets an instance of the power bi display associated with
	 * the sent element.
	 *
	 * @param {HTMLElement} element
	 * The element to reset a displayed report in.
	 * @memberof PowerBiService
	 */
	public reset(
		element: HTMLElement)
	{
		this.powerBiCoreService
			.reset(element);
	}
}