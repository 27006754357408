/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BaseEntityApiService
} from '@api/services/base/base-entity.api.service';
import {
	CacheService
} from '@shared/services/cache.service';
import {
	HttpClient
} from '@angular/common/http';
import {
	Inject,
	Injectable
} from '@angular/core';
import {
	IWorkflowActionInstancesDto
} from '@api/interfaces/workflow/workflow-action-instances.dto.interface';

/**
 * A class representing the logic and services of the workflow
 * action instances controller.
 *
 * @export
 * @class WorkflowActionInstancesApiService
 * @extends {BaseEntityApiService<IWorkflowActionInstancesDto>}
 */
@Injectable()
export class WorkflowActionInstancesApiService
	extends BaseEntityApiService<IWorkflowActionInstancesDto>
{
	/**
	 * Creates an instance of a WorkflowActionInstancesApiService.
	 *
	 * @param {HttpClient} HttpClient
	 * The injected http client to use in the base api service.
	 * @param {CacheService} cache
	 * The injected cache service to use in the base api service.
	 * @memberof WorkflowActionInstancesApiService
	 */
	public constructor(
		@Inject(HttpClient) http: HttpClient,
		@Inject(CacheService) cache: CacheService)
	{
		super();
		this.httpClient = http;
		this.cacheService = cache;
		this.endpoint =
			AppConstants.apiControllers.workflowActionInstances;
	}

	/**
	 * Creates an IWorkflowActionInstancesDto with the specified information.
	 * This method is not implemented in the API.
	 *
	 * @param {IWorkflowActionInstancesDto} _entity
	 * The workflow action instance to create.
	 * @returns {Promise<number>}
	 * The newly created item identifier.
	 * @memberof WorkflowActionInstancesApiService
	 */
	public async create(
		_entity: IWorkflowActionInstancesDto): Promise<number>
	{
		throw new Error(
			this.getHandledByOthersMessage(
				AppConstants.apiMethods.create,
				AppConstants.systemEngines.workflowEngine
			));
	}

	/**
	 * Updates an IWorkflowActionInstancesDto with the specified information.
	 * This method is not implemented in the API.
	 *
	 * @param {number} _id
	 * Id of the workflow action instance to update.
	 * @param {IWorkflowActionInstancesDto} _entity
	 * The workflow action instance to update.
	 * @returns {Promise<object>}
	 * Updated item identifier
	 * @memberof WorkflowActionInstancesApiService
	 */
	public async update(
		_id: number,
		_entity: IWorkflowActionInstancesDto): Promise<object>
	{
		throw new Error(
			this.getHandledByOthersMessage(
				AppConstants.apiMethods.update,
				AppConstants.systemEngines.workflowEngine
			));
	}

	/**
	 * Deletes an IWorkflowActionInstancesDto with the specified identifier.
	 * This method is not implemented in the API.
	 *
	 * @param {number} id
	 * The identifier of the workflow action instance to delete.
	 * @returns {Promise<object>}
	 * An observable of the delete no-content response.
	 * @memberof WorkflowActionInstancesApiService
	 */
	public async delete(
		_id: number): Promise<object>
	{
		throw new Error(
			this.getHandledByOthersMessage(
				AppConstants.apiMethods.delete,
				AppConstants.systemEngines.workflowEngine
			));
	}
}