/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BaseEntityApiService
} from '@api/services/base/base-entity.api.service';
import {
	CacheService
} from '@shared/services/cache.service';
import {
	HttpClient
} from '@angular/common/http';
import {
	IAggregateDto
} from '@api/interfaces/common/aggregatedto.interface';
import {
	Inject,
	Injectable
} from '@angular/core';
import {
	ISecuritySessionDto
} from '@api/interfaces/security/security-session.dto.interface';
import {
	ISecuritySessionEventDto
} from '@api/interfaces/security/security-session-event.dto.interface';
import {
	lastValueFrom
} from 'rxjs';

/**
 * A class representing the logic and services of the security
 * session controller.
 *
 * @export
 * @class SecuritysessionApiService
 * @extends {BaseEntityApiService<ISecuritySessionDto>}
 */
@Injectable()
export class SecuritySessionApiService
	extends BaseEntityApiService<ISecuritySessionDto>
{
	/**
	 * Creates an instance of a SecuritySessionApiService.
	 *
	 * @param {HttpClient} HttpClient
	 * The injected http client to use in the base api service.
	 * @param {CacheService} cache
	 * The injected cache service to use in the base api service.
	 * @memberof SecuritySessionApiService
	 */
	public constructor(
		@Inject(HttpClient) http: HttpClient,
		@Inject(CacheService) cache: CacheService)
	{
		super();
		this.httpClient = http;
		this.cacheService = cache;
		this.endpoint =
			AppConstants.apiControllers.securitySessions;
	}

	/**
	 * Gets a collection of security session events with the specified
	 * filters related to the sent security session identifier.
	 *
	 * @param {number} id
	 * A number representing the security session identifier
	 * to find related events for.
	 * @param {string} filter
	 * A string representing the filters for the query.
	 * @param {string} orderBy
	 * A string representing the order by for the query.
	 * @param {number} [offset]
	 * A number representing the skip offset.
	 * @param {number} [limit]
	 * A number representing the top limit count.
	 * @param {number} [last]
	 * A number representing the last count.
	 * @returns {Promise<ISecuritySessionEventDto[]>}
	 * The array of security session events found via this query method.
	 * @memberof BaseEntityApiService
	 */
	public async querySessionEvents(
		id: number,
		filter: string,
		orderBy: string,
		offset?: number,
		limit?: number,
		last?: number): Promise<ISecuritySessionEventDto[]>
	{
		const url =
			this.formUrlParam(
				this.getNestedUrl(
					id,
					AppConstants.nestedRouteTypes.events),
				{
					filter: filter,
					orderBy: orderBy,
					offset: offset,
					limit: limit,
					last: last
				});

		return lastValueFrom(
			this.httpClient
				.get<ISecuritySessionEventDto[]>(url));
	}

	/**
	 * Gets an aggregate of session events.
	 * Count, sum, average, min, or max.
	 *
	 * @param {number} id
	 * A number representing the security session identifier
	 * to find related events for.
	 * @param {string} method
	 * The aggregate method name to use.
	 * @param {string} property
	 * The property to aggregate. Pass null if calling count.
	 * @param {string} filter
	 * The filter or "where clause."
	 * @param {string} groupBy
	 * The properties to group by.
	 * @returns {Promise<IAggregateDto[]>}
	 * An array of aggregate results.
	 * @memberof BaseEntityApiService
	 */
	public async aggregateSessionEvents(
		id: number,
		method: string,
		property?: string,
		filter?: string,
		groupBy?: string): Promise<IAggregateDto[]>
	{
		const url =
			this.formUrlParam(
				this.getBaseUrl() +
				'/Aggregate' +
				`/${id}/${AppConstants.nestedRouteTypes.events}`,
				{
					method: method,
					property: property,
					filter: filter,
					groupBy: groupBy
				});

		return lastValueFrom(
			this.httpClient
				.get<IAggregateDto[]>(url));
	}

	/**
	 * Creates an ISecuritySessionDto with the specified information.
	 * This method is not implemented in the API.
	 *
	 * @param {ISecuritySessionDto} _entity
	 * The security session to create.
	 * @returns {Promise<number>}
	 * The newly created item identifier.
	 * @memberof SecuritySessionApiService
	 */
	public async create(
		_entity: ISecuritySessionDto): Promise<number>
	{
		throw new Error(
			this.getNotImplementedMessage(
				AppConstants.apiMethods.create,
				[
					AppConstants.apiMethods.update
				]));
	}

	/**
	 * Deletes an ISecuritySessionDto with the specified identifier.
	 * This method is not implemented in the API.
	 *
	 * @param {number} id
	 * The identifier of the security session to delete.
	 * @returns {Promise<object>}
	 * An observable of the delete no-content response.
	 * @memberof SecuritySessionApiService
	 */
	public async delete(
		_id: number): Promise<object>
	{
		throw new Error(
			this.getNotImplementedMessage(
				AppConstants.apiMethods.delete,
				[
					AppConstants.apiMethods.update
				]));
	}
}