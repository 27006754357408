<span *ngIf="!loadingOperationDefinition">
	<ng-template [ngIf]="context.source.displayView" [ngIfElse]="displayUpdate">
		<div class="ui-g-6 no-padding edit-form-container"
			[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}">
			<div class="section-margin">
				<app-dynamic-formly
					[dataSet]="context.data"
					[layoutSchema]="context.source.formlyDefinitions.layout"
					(validityChanged)="catchLayoutChanges($event)">
				</app-dynamic-formly>
			</div>
		</div>
		<div class="ui-g-6"
			[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}">
			<div>
				<app-common-table
					[tableDefinitions]="parametersTableDefinitionsView">
				</app-common-table>
			</div>
			<div class="operation-group-table">
				<app-common-table
					[tableDefinitions]="operationGroupsTableDefinitionsView">
				</app-common-table>
			</div>
		</div>
	</ng-template>

	<ng-template #displayUpdate>
		<div class="ui-g-6 no-padding edit-form-container"
			[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}">
			<div class="section-margin">
				<app-dynamic-formly
					[dataSet]="context.data"
					[layoutSchema]="context.source.formlyDefinitions.layout"
					(validityChanged)="catchLayoutChanges($event)">
				</app-dynamic-formly>
			</div>
		</div>
		<div class="ui-g-6"
			[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}">

			<div class="align-center"
				*ngIf="loadingTableDefinitions">
				<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
				</span>
			</div>
			<span *ngIf="!loadingTableDefinitions">
				<div>
					<app-common-table
						[tableDefinitions]="parametersTableDefinitionsUpdate">
					</app-common-table>
				</div>
				<div class="operation-group-table">
					<app-common-table
						[tableDefinitions]="operationGroupsTableDefinitionsUpdate">
					</app-common-table>
				</div>
			</span>
		</div>
	</ng-template>

	<div class="ui-g-12">
		<operation-button-bar
			[initialModel]="context.source.expandActions"
			(loadingOperations)="context.source.operationButtonClicked($event)">
		</operation-button-bar>
	</div>
</span>

<div class="ui-g-12"
	*ngIf="loadingOperationDefinition">
	<span class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
	</span>
</div>
