/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ActivatedRoute,
	Params
} from '@angular/router';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	Component,
} from '@angular/core';
import {
	EntityManagerDirective
} from '@admin/directives/entity-manager.directive';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	FormControl
} from '@angular/forms';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IWorkflowActionDefinitions
} from '@shared/interfaces/workflow/workflow-action-definitions.interface';
import {
	IWorkflowActionRequisites
} from '@shared/interfaces/workflow/workflow-action-requisites.interface';
import {
	IWorkflowFailureActions
} from '@shared/interfaces/workflow/workflow-failure-actions.interface';
import {
	IWorkflowRequisiteTypes
} from '@shared/interfaces/workflow/workflow-requisite-types.interface';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	WorkflowActionDefinitionsApiService
} from '@api/services/workflow/workflow-action-definitions.api.service';
import {
	WorkflowActionRequisitesApiService
} from '@api/services/workflow/workflow-action-requisites.api.service';
import {
	WorkflowFailureActionsApiService
} from '@api/services/workflow/workflow-failure-actions.api.service';
import {
	WorkflowRequisiteTypesApiService
} from '@api/services/workflow/workflow-requisite-types.api.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-entity-action-definition',
	templateUrl: './entity-action-definition.component.html',
	styleUrls: [
		'./entity-action-definition.component.scss'
	]
})

/**
 * A component representing an instance of the entity action definitions
 * component.
 *
 * @export
 * @class EntityActionDefinitionComponent
 * @extends {EntityManagerDirective}
 */
export class EntityActionDefinitionComponent
	extends EntityManagerDirective
{
	/**
	 * Creates an instance of an EntityActionDefinitionComponent.
	 *
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to get the entity type data.
	 * @param {WorkflowActionDefinitionsApiService}
	 * workflowActionDefinitionsApiService
	 * The api service used to get workflow action definition data.
	 * @param {WorkflowFailureActionsApiService}
	 * 	workflowFailureActionsApiService
	 * The api service used to get workflow failure action data.
	 * @param {WorkflowRequisiteTypesApiService}
	 * 	workflowRequisiteTypesApiService
	 * The api service used to get workflow requisite types data.
	 * @param {WorkflowActionRequisitesApiService}
	 * workflowActionRequisitesApiService
	 * The api service used to get workflow action requisite data.
	 * @param {SiteLayoutService} siteLayoutService
	 * The service used to get the site layout data.
	 * @param {ActivatedRoute} route
	 * The activated route that opened this component.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {ResolverService} resolver
	 * The resolver service used for context methods in this component.
	 * @memberof EntityActionDefinitionComponent
	 */
	public constructor(
		public entityTypeApiService: EntityTypeApiService,
		public workflowActionDefinitionsApiService:
			WorkflowActionDefinitionsApiService,
		public workflowFailureActionsApiService:
			WorkflowFailureActionsApiService,
		public workflowRequisiteTypesApiService:
			WorkflowRequisiteTypesApiService,
		public workflowActionRequisitesApiService:
			WorkflowActionRequisitesApiService,
		public siteLayoutService: SiteLayoutService,
		public route: ActivatedRoute,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(
			route,
			activityService,
			resolver);
	}

	/**
	 * Gets or sets the workflow action definition data.
	 *
	 * @type {IWorkflowActionDefinitions}
	 * @memberof EntityActionDefinitionComponent
	 */
	public workflowActionDefinition: IWorkflowActionDefinitions;

	/**
	 * Gets or sets the workflow action id.
	 *
	 * @type {number}
	 * @memberof EntityActionDefinitionComponent
	 */
	public actionId: number;

	/**
	 * Gets or sets the loading state for the post requisite table definitions.
	 *
	 * @type {boolean}
	 * @memberof EntityActionDefinitionComponent
	 */
	public loadingPostRequisiteTableDefinitions: boolean = true;

	/**
	 * Gets or sets the loading state for the pre requisite table definitions.
	 *
	 * @type {boolean}
	 * @memberof EntityActionDefinitionComponent
	 */
	public loadingPreRequisiteTableDefinitions: boolean = true;

	/**
	 * Gets or sets the workflow requisite types.
	 *
	 * @type {IWorkflowRequisiteTypes[]}
	 * @memberof EntityActionDefinitionComponent
	 */
	public requisiteTypes: IWorkflowRequisiteTypes[];

	/**
	 * Gets or sets the workflow requisite options.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof EntityActionDefinitionComponent
	 */
	public requisiteOptions: IDropdownOption[];

	/**
	 * Gets or sets the PreRequisite table definitions.
	 *
	 * @type {ICommonTable}
	 * @memberof EntityActionDefinitionComponent
	 */
	public preRequisiteTableDefinitions: ICommonTable;

	/**
	 * Gets or sets the PostRequisite table definitions.
	 *
	 * @type {ICommonTable}
	 * @memberof EntityActionDefinitionComponent
	 */
	public postRequisiteTableDefinitions: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof EntityActionDefinitionComponent
	 */
	public preRequisiteAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof EntityActionDefinitionComponent
	 */
	public preRequisiteSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the column selection mode.
	 *
	 * @type {boolean}
	 * @memberof EntityActionDefinitionComponent
	 */
	public preRequisiteColumnSelectionMode?: boolean = false;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof EntityActionDefinitionComponent
	 */
	public postRequisiteAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof EntityActionDefinitionComponent
	 */
	public postRequisiteSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the column selection mode.
	 *
	 * @type {boolean}
	 * @memberof EntityActionDefinitionComponent
	 */
	public postRequisiteColumnSelectionMode?: boolean = false;

	/**
	 * Sets the action id query parameter.
	 *
	 * @type {string}
	 * @memberof EntityActionDefinitionComponent
	 */
	private readonly actionIdQueryParameter: string =
		AppConstants.commonProperties.actionId;

	/**
	 * Gets the requisite table redraw debounce time.
	 *
	 * @type {number}
	 * @memberof EntityActionDefinitionComponent
	 */
	private readonly requisiteTableRedrawDebounceTime: number =
		AppConstants.time.quarterSecond;

	/**
	 * Saves the updated entity data.
	 *
	 * @async
	 * @memberof EntityActionDefinitionComponent
	 */
	public async saveAction(): Promise<void>
	{
		const workflowActionDataObject: IWorkflowActionDefinitions =
			<IWorkflowActionDefinitions>
			{
				id: this.workflowActionDefinition.id,
				entityTypeId: this.workflowActionDefinition.entityTypeId,
				entityVersionId: this.workflowActionDefinition.entityVersionId,
				name: this.contextData.data.name,
				description: this.contextData.data.description,
				classReference: this.contextData.data.classReference,
				classDefinition: this.contextData.data.classDefinition,
				failureActionId: this.contextData.data.failureActionId
			};

		await this.workflowActionDefinitionsApiService
			.update(
				this.workflowActionDefinition.id,
				workflowActionDataObject);
	}

	/**
	 * Sets the context data required for this component.
	 *
	 * @async
	 * @memberof EntityActionDefinitionComponent
	 */
	public async setContextData(): Promise<void>
	{
		this.preRequisiteAvailableColumns =
			[
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: 1
				},
				{
					dataKey: 'order',
					columnHeader: 'Order',
					displayOrder: 2
				}
			];
		this.preRequisiteSelectedColumns = this.preRequisiteAvailableColumns;
		this.postRequisiteAvailableColumns =
			[
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: 1
				},
				{
					dataKey: 'order',
					columnHeader: 'Order',
					displayOrder: 2
				}
			];
		this.postRequisiteSelectedColumns = this.postRequisiteAvailableColumns;

		this.subscriptions.add(
			this.route.queryParams.subscribe((parameters: Params) =>
			{
				const mappedRouteData: any =
						ObjectHelper.mapFromRouteData(
							parameters);

				this.actionId =
						AnyHelper.isNullOrEmpty(
							mappedRouteData[this.actionIdQueryParameter])
							? AppConstants.empty
							: mappedRouteData[this.actionIdQueryParameter];
			}));

		this.workflowActionDefinition =
			await this.workflowActionDefinitionsApiService
				.get(this.actionId);

		this.entityType =
			await this.entityTypeApiService
				.get(this.workflowActionDefinition.entityTypeId);

		this.requisiteTypes =
			await this.workflowRequisiteTypesApiService
				.query(
					AppConstants.empty,
					AppConstants.empty);

		this.contextData = {
			data: {
				id: this.workflowActionDefinition.id,
				name: this.workflowActionDefinition.name,
				description: this.workflowActionDefinition.description,
				failureActionId: this.workflowActionDefinition.failureActionId,
				classReference: this.workflowActionDefinition.classReference,
				classDefinition: this.workflowActionDefinition.classDefinition,
				entityTypeId: this.workflowActionDefinition.entityTypeId,
				entityVersionId: this.workflowActionDefinition.entityVersionId
			}
		};

		this.saveTitle = 'Workflow Action Definition';
		this.saveContent = 'Workflow Action Definition'
			+ ` ${this.workflowActionDefinition.name}`;
	}

	/**
	 * Sets the Table Definitions.
	 *
	 * @async
	 * @memberof EntityActionDefinitionComponent
	 */
	public async setTableDefinitions(): Promise<void>
	{
		this.requisiteOptions = (await this.workflowActionDefinitionsApiService
			.query(
				'EntityTypeId eq '
					+ `${this.workflowActionDefinition.entityTypeId} `
					+ `and Id ne ${this.workflowActionDefinition.id}`,
				AppConstants.empty))
			.map(
				(actionDefinition: IWorkflowActionDefinitions) =>
					<IDropdownOption>
					{
						label: actionDefinition.name,
						value: actionDefinition.id
					});

		await this.setPreRequisiteTableDefinitions();
		setTimeout(
			async() =>
			{
				await this.setPostRequisiteTableDefinitions();
			});
	}

	/**
	 * Sets the PreRequisite Table Definitions.
	 * @async
	 * @memberof EntityActionDefinitionComponent
	 */
	public async setPreRequisiteTableDefinitions(): Promise<void>
	{
		this.preRequisiteTableDefinitions = {
			actions: {
				create: {
					displayCreateRow: false,
					layout: [
						{
							key: 'data.requisiteActionDefinitionId',
							type: FormlyConstants.customControls.customSelect,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								required: true,
								showClear: true,
								placeholder: AppConstants.placeholders
									.selectAnOption,
								options: this.requisiteOptions
							}
						},
						{
							key: 'data.order',
							type: FormlyConstants.customControls
								.customInputNumber,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								required: true
							},
							asyncValidators: {
								uniqueOrder: {
									expression: (control: FormControl) =>
										this.uniqueOrder(
											control,
											AppConstants.workflowRequisiteTypes
												.preRequisite),
									message: 'Existing Order.'
								}
							}
						}
					],
					items:
					[
						{
							label: 'Save',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: () => this.createRequisite(
								AppConstants.workflowRequisiteTypes
									.preRequisite)
						}
					]
				},
				updateIndex:
				[
					{
						id: 'updateIndexUp',
						visible: (
							rowData: any,
							virtualData: any[]) =>
							rowData.order !== virtualData[0].order,
						command: async(
							commonTableContext: CommonTableComponent) =>
							this.updateOrderIndex(
								commonTableContext,
								-1,
								AppConstants.workflowRequisiteTypes
									.preRequisite)
					},
					{
						id: 'updateIndexDown',
						visible: (
							rowData: any,
							virtualData: any[]) =>
							rowData.order !==
									virtualData[virtualData.length - 1].order,
						command: async(
							commonTableContext: CommonTableComponent) =>
							this.updateOrderIndex(
								commonTableContext,
								1,
								AppConstants.workflowRequisiteTypes
									.preRequisite)
					}
				],
				delete: {
					deleteStatement: () =>
						`Confirm to remove PreRequisite
							${this.commonTableContext.data.name}
							from action definition
							${this.workflowActionDefinition.name}.`,
					items: [
						{
							label: 'Remove',
							styleClass: AppConstants.cssClasses.pButtonDanger,
							command: async() =>
								this.deleteRequisite(
									AppConstants.workflowRequisiteTypes
										.preRequisite)
						}
					],
				}
			},
			hideSettings: true,
			hideExpanderArrow: true,
			tableTitle: 'PreRequisites',
			objectSearch: {
				filter: 'actionDefinitionId eq '
					+ this.workflowActionDefinition.id
					+ ' and requisiteTypeId eq '
					+ (this.requisiteTypes.filter(
						(requisiteType) =>
							requisiteType.name ===
								AppConstants.workflowRequisiteTypes
									.preRequisite))[0]?.id,
				orderBy: `Order ${AppConstants.sortDirections.ascending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: 5
			},
			apiPromise: async (objectSearch: IObjectSearch) =>
			{
				const actionRequisites: IWorkflowActionRequisites[] =
						await this.workflowActionRequisitesApiService
							.query(
								objectSearch.filter,
								objectSearch.orderBy,
								objectSearch.offset,
								objectSearch.limit);

				const preRequisites = [];

				for (const actionRequisite of actionRequisites)
				{
					preRequisites.push(
						{
							id: actionRequisite.id,
							name:
								(await this.workflowActionDefinitionsApiService
									.get(actionRequisite
										.requisiteActionDefinitionId)).name,
							requisiteTypeId:
								actionRequisite.requisiteTypeId,
							actionDefinitionId:
								actionRequisite.actionDefinitionId,
							requisiteActionDefinitionId:
								actionRequisite
									.requisiteActionDefinitionId,
							order: actionRequisite.order
						});
				}

				return preRequisites;
			},
			availableColumns: this.preRequisiteAvailableColumns,
			selectedColumns: this.preRequisiteSelectedColumns,
			columnSelectionMode: this.preRequisiteColumnSelectionMode,
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.preRequisiteTableDefinitions.selectedColumns =
					selectedColumns;
				this.preRequisiteSelectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.preRequisiteTableDefinitions.columnSelectionMode =
					columnSelectionMode;
				this.preRequisiteColumnSelectionMode = columnSelectionMode;
			}
		};

		this.loadingPreRequisiteTableDefinitions = false;
	}

	/**
	 * Sets the PostRequisite Table Definitions.
	 *
	 * @async
	 * @memberof EntityActionDefinitionComponent
	 */
	public async setPostRequisiteTableDefinitions(): Promise<void>
	{
		this.postRequisiteTableDefinitions = {
			actions: {
				create: {
					displayCreateRow: false,
					layout: [
						{
							key: 'data.requisiteActionDefinitionId',
							type: FormlyConstants.customControls.customSelect,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								required: true,
								showClear: true,
								placeholder: AppConstants.placeholders
									.selectAnOption,
								options: this.requisiteOptions
							}
						},
						{
							key: 'data.order',
							type: FormlyConstants.customControls
								.customInputNumber,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								required: true
							},
							asyncValidators: {
								uniqueOrder: {
									expression: (control: FormControl) =>
										this.uniqueOrder(
											control,
											AppConstants.workflowRequisiteTypes
												.postRequisite),
									message: 'Existing Order.'
								}
							}
						}
					],
					items:
					[
						{
							label: 'Save',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: () => this.createRequisite(
								AppConstants.workflowRequisiteTypes
									.postRequisite)
						}
					]
				},
				updateIndex:
				[
					{
						id: 'updateIndexUp',
						visible: (
							rowData: any,
							virtualData: any[]) =>
							rowData.order !== virtualData[0].order,
						command: async(
							commonTableContext: CommonTableComponent) =>
							this.updateOrderIndex(
								commonTableContext,
								-1,
								AppConstants.workflowRequisiteTypes
									.postRequisite)
					},
					{
						id: 'updateIndexDown',
						visible: (
							rowData: any,
							virtualData: any[]) =>
							rowData.order !==
									virtualData[virtualData.length - 1].order,
						command: async(
							commonTableContext: CommonTableComponent) =>
							this.updateOrderIndex(
								commonTableContext,
								1,
								AppConstants.workflowRequisiteTypes
									.postRequisite)
					}
				],
				delete: {
					deleteStatement: () =>
						`Confirm to remove PostRequisite
							${this.commonTableContext.data.name}
							from action definition
							${this.workflowActionDefinition.name}.`,
					items: [
						{
							label: 'Remove',
							styleClass: AppConstants.cssClasses.pButtonDanger,
							command: async() =>
								this.deleteRequisite(
									AppConstants.workflowRequisiteTypes
										.postRequisite)
						}
					],
				}
			},
			hideSettings: true,
			hideExpanderArrow: true,
			tableTitle: 'PostRequisites',
			objectSearch: {
				filter: 'actionDefinitionId eq '
					+ this.workflowActionDefinition.id
					+ ' and requisiteTypeId eq '
					+ (this.requisiteTypes.filter(
						(requisiteType) =>
							requisiteType.name ===
								AppConstants.workflowRequisiteTypes
									.postRequisite))[0]?.id,
				orderBy: `Order ${AppConstants.sortDirections.ascending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: 5
			},
			apiPromise: async (objectSearch: IObjectSearch) =>
			{
				const actionRequisites: IWorkflowActionRequisites[] =
						await this.workflowActionRequisitesApiService
							.query(
								objectSearch.filter,
								objectSearch.orderBy,
								objectSearch.offset,
								objectSearch.limit);

				const postRequisites = [];

				for (const actionRequisite of actionRequisites)
				{
					postRequisites.push(
						{
							id: actionRequisite.id,
							name:
								(await this.workflowActionDefinitionsApiService
									.get(actionRequisite
										.requisiteActionDefinitionId)).name,
							requisiteTypeId:
								actionRequisite.requisiteTypeId,
							actionDefinitionId:
								actionRequisite.actionDefinitionId,
							requisiteActionDefinitionId:
								actionRequisite
									.requisiteActionDefinitionId,
							order: actionRequisite.order
						});
				}

				return postRequisites;
			},
			availableColumns: this.postRequisiteAvailableColumns,
			selectedColumns: this.postRequisiteSelectedColumns,
			columnSelectionMode: this.postRequisiteColumnSelectionMode,
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.postRequisiteTableDefinitions.selectedColumns =
					selectedColumns;
				this.postRequisiteSelectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.postRequisiteTableDefinitions.columnSelectionMode =
					columnSelectionMode;
				this.postRequisiteColumnSelectionMode = columnSelectionMode;
			}
		};

		this.loadingPostRequisiteTableDefinitions = false;
	}

	/**
	 * Restores the requisite table definitions.
	 *
	 * @param {string} requisiteType
	 * @memberof EntityActionDefinitionComponent
	 */
	public restoreRequisiteTableDefinitions(requisiteType: string): void
	{
		this.loadingPreRequisiteTableDefinitions =
			requisiteType === AppConstants.workflowRequisiteTypes.preRequisite;

		this.loadingPostRequisiteTableDefinitions =
			requisiteType === AppConstants.workflowRequisiteTypes.postRequisite;

		setTimeout(
			async() =>
			{
				if (requisiteType === AppConstants.workflowRequisiteTypes
					.preRequisite)
				{
					await this.setPreRequisiteTableDefinitions();
				}
				else if (requisiteType
						=== AppConstants.workflowRequisiteTypes.postRequisite)
				{
					await this.setPostRequisiteTableDefinitions();
				}
			},
			this.requisiteTableRedrawDebounceTime);
	}

	/**
	 * Sets the Formly Definitions.
	 *
	 * @async
	 * @memberof EntityManagerDirective
	 */
	public async setFormlyDefinitions(): Promise<void>
	{
		await this.setContextData();
		this.updateLastSavedData();
		await this.setLayoutFields();

		this.loadingDefinitions = false;
	}

	/**
	 * Sets the formly layout fields.
	 *
	 * @async
	 * @memberof EntityActionDefinitionComponent
	 */
	public async setLayoutFields(): Promise<void>
	{
		this.layoutFields =
			<FormlyFieldConfig[]>
			[
				{
					key: 'data.name',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Name',
						required: true
					},
					asyncValidators: {
						uniqueCombination: {
							expression: async (control: FormControl) =>
								this.uniqueCombination(control),
							message:
								'Existing Type, Version and Name combination.'
						}
					}
				},
				{
					key: 'data.description',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Description'
					}
				},
				{
					key: 'data.failureActionId',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						showClear: true,
						placeholder: AppConstants.placeholders.selectAnOption,
						label: 'Failure Action',
						required: true,
						options: (await this.workflowFailureActionsApiService
							.query(
								AppConstants.empty,
								AppConstants.empty))
							?.map(
								(failureAction: IWorkflowFailureActions) =>
									this.mapWorkflowFailureAction(
										failureAction))
					}
				},
				{
					key: 'data.classReference',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Class Reference',
						required: true
					}
				},
				{
					key: 'data.classDefinition',
					type: FormlyConstants.customControls.customTextArea,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Class Definition',
						rows: FormlyConstants.textAreaRowSizes.standard,
						required: true
					},
					validators: {
						validCSharpFormat: {
							expression: (
								control: FormControl) =>
								this.isValidCSharpFormat(control.value),
							message: 'Not a valid C# format.'
						}
					},
				}
			];
	}

	/**
	 * Checks if entity type, entity verion
	 * and action name combination is existing or unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The form control.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof EntityActionDefinitionComponent
	 */
	private async uniqueCombination(control: FormControl): Promise<boolean>
	{
		let actionDefinition: IWorkflowActionDefinitions[] = [];

		if (!AnyHelper.isNullOrEmpty(this.contextData.data.entityTypeId))
		{
			actionDefinition =
				await this.workflowActionDefinitionsApiService
					.query(
						`EntityTypeId eq ${this.contextData.data.entityTypeId} `
							+ 'and EntityVersionId eq '
							+ `${this.contextData.data.entityVersionId} `
							+ `and Name eq '${control.value}' `
							+ `and Id neq ${this.contextData.data.id}`,
						AppConstants.empty);
		}

		return Promise.resolve(actionDefinition.length === 0);
	}

	/**
	 * Checks if content value is a valid c sharp format.
	 *
	 * @returns {boolean | RegExpMatchArray}
	 * The validation result.
	 * @memberof EntityActionDefinitionComponent
	 */
	private isValidCSharpFormat(value: string): boolean | RegExpMatchArray
	{
		return AnyHelper.isNullOrWhitespace(value)
			|| value?.match(new RegExp(
				'^(?=.*namespace (?=WaterStreet))'
					+ '(?=.*using (?=WaterStreet))'
					+ '(?=.*public (?=class))'
					+ '(?=.*public (?=override))'
					+ '(?=.*return).*$',
				'gs'))
			&& value.match(/\{(?:[^{}]+|\{(?:[^{}]+|\{[^{}]\})\}+)\}+/g);
	}

	/**
	 * Validates if the requisite order is unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The field form control.
	 * @param {string} requisiteType
	 * The requisite type.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof EntityActionDefinitionComponent
	 */
	private async uniqueOrder(
		control: FormControl,
		requisiteType: string): Promise<boolean>
	{
		const workflowRequisiteTypes: IWorkflowRequisiteTypes[] =
			await this.workflowRequisiteTypesApiService
				.query(
					`Name eq '${requisiteType}'`,
					AppConstants.empty);

		const workflowRequisites: IWorkflowActionRequisites[] =
			await this.workflowActionRequisitesApiService
				.query(
					`Order eq ${control.value} `
						+ 'and ActionDefinitionId eq '
						+ `${this.workflowActionDefinition.id} `
						+ `and RequisiteTypeId eq ${
							workflowRequisiteTypes[0].id}`,
					AppConstants.empty);

		return Promise.resolve(workflowRequisites.length <= 0);
	}

	/**
	 * Creates a new Requisite.
	 *
	 * @async
	 * @param {string} requisiteType
	 * @memberof EntityActionDefinitionComponent
	 */
	private async createRequisite(requisiteType: string): Promise<void>
	{
		const createRequisite: Function = async () =>
		{
			await this.workflowActionRequisitesApiService
				.create(
					<IWorkflowActionRequisites>
					{
						id: 0,
						requisiteTypeId:
							(await this.workflowRequisiteTypesApiService
								.query(
									`Name eq '${requisiteType}'`,
									AppConstants.empty))[0].id,
						actionDefinitionId: this.workflowActionDefinition.id,
						requisiteActionDefinitionId:
							this.commonTableContext.source.rowData
								.requisiteActionDefinitionId,
						order: this.commonTableContext.source.rowData.order
					});
		};

		await this.activityService.handleActivity(
			new Activity(
				createRequisite(),
				'<strong>Creating Requisite</strong>',
				'<strong>Created Requisite</strong>',
				`Requisite
					${this.commonTableContext.source.rowData.name}
					was successfully created.`,
				`Requisite
					${this.commonTableContext.source.rowData.name}
					was not created.`));

		this.restoreRequisiteTableDefinitions(requisiteType);
	}

	/**
	 * Deletes an existing Requisite.
	 *
	 * @async
	 * @param {string} requisiteType
	 * @memberof EntityActionDefinitionComponent
	 */
	private async deleteRequisite(requisiteType: string): Promise<void>
	{
		await this.activityService.handleActivity(
			new Activity(
				this.workflowActionRequisitesApiService
					.delete(this.commonTableContext.source.rowData.id),
				'<strong>Deleting Requisite</strong>',
				'<strong>Deleted Requisite</strong>',
				`Requisite
						${this.commonTableContext.source.rowData.name}
						was successfully deleted.`,
				`Requisite
						${this.commonTableContext.source.rowData.name}
						was not deleted.`));

		this.restoreRequisiteTableDefinitions(requisiteType);
	}

	/**
	 * Updates the order index of the selected row item
	 * up or down based on the indexOperator.
	 *
	 * @async
	 * @param {CommonTableComponent} commonTableContext
	 * The common table context.
	 * @param {number} indexReference
	 * The index reference to add or substract to the current
	 * selected order index.
	 * @param {string} requisiteType
	 * The requisite type.
	 * @memberof EntityActionDefinitionComponent
	 */
	private async updateOrderIndex(
		commonTableContext: CommonTableComponent,
		indexReference: number,
		requisiteType: string): Promise<void>
	{
		const updateOrderIndex: Function = async() =>
		{
			commonTableContext.loadingTableDefinitions = true;

			const orderedRequisites =
				this.commonTableContext.source.virtualData
					.filter((data) => data !== undefined)
					.sort((a, b) => a.order - b.order);

			const neighborOrderIndex =
				this.findSelectedChildIndex(
					orderedRequisites,
					this.commonTableContext.source.rowData) + indexReference;

			if (neighborOrderIndex > -1)
			{
				await this.workflowActionRequisitesApiService
					.update(
						orderedRequisites[neighborOrderIndex].id,
						<IWorkflowActionRequisites>
						{
							id: orderedRequisites[neighborOrderIndex].id,
							requisiteTypeId:
								orderedRequisites[neighborOrderIndex]
									.requisiteTypeId,
							actionDefinitionId:
								orderedRequisites[neighborOrderIndex]
									.actionDefinitionId,
							requisiteActionDefinitionId:
								orderedRequisites[neighborOrderIndex]
									.requisiteActionDefinitionId,
							order: 1000
						});

				await this.workflowActionRequisitesApiService
					.update(
						this.commonTableContext.source.rowData.id,
						<IWorkflowActionRequisites>
						{
							id: this.commonTableContext.source.rowData.id,
							requisiteTypeId:
								this.commonTableContext.source
									.rowData.requisiteTypeId,
							actionDefinitionId:
								this.commonTableContext.source.rowData
									.actionDefinitionId,
							requisiteActionDefinitionId:
								this.commonTableContext.source.rowData
									.requisiteActionDefinitionId,
							order: orderedRequisites[neighborOrderIndex].order
						});

				await this.workflowActionRequisitesApiService
					.update(
						orderedRequisites[neighborOrderIndex].id,
						<IWorkflowActionRequisites>
						{
							id: orderedRequisites[neighborOrderIndex].id,
							requisiteTypeId:
								orderedRequisites[neighborOrderIndex]
									.requisiteTypeId,
							actionDefinitionId:
								orderedRequisites[neighborOrderIndex]
									.actionDefinitionId,
							requisiteActionDefinitionId:
								orderedRequisites[neighborOrderIndex]
									.requisiteActionDefinitionId,
							order: this.commonTableContext.source.rowData.order
						});
			}
		};

		await this.activityService.handleActivity(
			new Activity(
				updateOrderIndex(),
				'<strong>Updating Requisite Order</strong>',
				'<strong>Updated Requisite Order</strong>',
				'Requisite Order was successfully updated.',
				'Requisite Order was not updated.'),
			AppConstants.activityStatus.complete,
			true);

		this.restoreRequisiteTableDefinitions(requisiteType);
	}

	/**
	 * Finds the selected child index.
	 *
	 * @param {any[]} children
	 * The children object array.
	 * @param {any} selectedChild
	 * The selected child.
	 * @returns {number}
	 * The child index selected.
	 * @memberof EntityActionDefinitionComponent
	 */
	private findSelectedChildIndex(
		children: any[],
		selectedChild: any): number
	{
		for (let index = 0; index < children.length; index++)
		{
			if (children[index].id === selectedChild.id
				&& children[index].requisiteActionDefinitionId
					=== selectedChild.requisiteActionDefinitionId
				&& children[index].order === selectedChild.order)
			{
				return index;
			}
		}

		return -1;
	}

	/**
	 * Maps and returns a workflow failure action as a dropdown option.
	 *
	 * @param {IWorkflowFailureActions} failureAction
	 * The workflow failure action to be mapped.
	 * @returns {IDropdownOption}
	 * A mapped dropdown option representing the workflow failure action.
	 * @memberof EntityActionDefinitionComponent
	 */
	private mapWorkflowFailureAction(
		failureAction: IWorkflowFailureActions): IDropdownOption
	{
		return <IDropdownOption>
			{
				label: failureAction.name,
				value: failureAction.id
			};
	}
}