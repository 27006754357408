/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BaseEntityApiService
} from '@api/services/base/base-entity.api.service';
import {
	CacheService
} from '@shared/services/cache.service';
import {
	HttpClient
} from '@angular/common/http';
import {
	Inject,
	Injectable
} from '@angular/core';
import {
	IOperationGroupDto
} from '@api/interfaces/operations/operation-group.dto.interface';
import {
	IOperationGroupRelationshipDto
} from '@api/interfaces/operations/operation-group-relationship.dto.interface';
import {
	lastValueFrom
} from 'rxjs';

/**
 * A class representing the logic and services of the operation
 * group controller.
 *
 * @export
 * @class OperationGroupApiService
 * @extends {BaseEntityApiService<IOperationGroupDto>}
 */
@Injectable()
export class OperationGroupApiService
	extends BaseEntityApiService<IOperationGroupDto>
{
	/**
	 * Creates an instance of an OperationGroupApiService.
	 *
	 * @param {HttpClient} HttpClient
	 * The injected http client to use in the base api service.
	 * @param {CacheService} cache
	 * The injected cache service to use in the base api service.
	 * @memberof OperationGroupApiService
	 */
	public constructor(
		@Inject(HttpClient) http: HttpClient,
		@Inject(CacheService) cache: CacheService)
	{
		super();
		this.httpClient = http;
		this.cacheService = cache;
		this.endpoint =
			AppConstants.apiControllers.operationGroups;
	}

	/**
	 * Gets the parent groups of the operation group with the specified
	 * identifier.
	 *
	 * @param {number} id
	 * The identifier of the operation group to get the parents of.
	 * @returns {Promise<IOperationGroupRelationshipDto[]>}
	 * The parents found via this get method.
	 * @memberof OperationGroupApiService
	 */
	public async getParents(
		id: number): Promise<IOperationGroupRelationshipDto[]>
	{
		const url = this.getNestedUrl(
			id,
			AppConstants.nestedRouteTypes.parents
		);

		return lastValueFrom(
			this.httpClient.get<IOperationGroupRelationshipDto[]>(url));
	}

	/**
	 * Gets the children of the operation group with the specified
	 * identifier.
	 *
	 * @param {number} id
	 * The identifier of the operation group to get the children of.
	 * @returns {Promise<IOperationGroupRelationshipDto[]>}
	 * The children found via this get method.
	 * @memberof OperationGroupApiService
	 */
	public async getChildren(
		id: number): Promise<IOperationGroupRelationshipDto[]>
	{
		const url = this.getNestedUrl(
			id,
			AppConstants.nestedRouteTypes.children
		);

		return lastValueFrom(
			this.httpClient.get<IOperationGroupRelationshipDto[]>(url));
	}

	/**
	 * Creates a child of the operation group with the specified
	 * identifier with the supplied operation group relationship.
	 *
	 * @param {number} id
	 * The identifier of the operation group to create a child of.
	 * @param {IOperationGroupRelationshipDto} operationGroupRelationship
	 * The operation group relationship to create.
	 * @returns {Promise<number>}
	 * The identifier of the operation group this item was added to.
	 * @memberof OperationGroupApiService
	 */
	public async createChild(
		id: number,
		operationGroupRelationship:
			IOperationGroupRelationshipDto): Promise<number>
	{
		const url =
			this.getNestedUrl(
				id,
				AppConstants.nestedRouteTypes.children);
		await this.resetAssociatedCache(url);

		const response = await lastValueFrom(
			this.httpClient.post(
				url,
				operationGroupRelationship,
				{
					observe: 'response',
					headers: this.queryOnlyGetHeaders
				}));

		const createdItemId: number =
			this.getCreatedAtRouteIdentifier(
				response.headers.get('location'));

		return Promise.resolve(createdItemId);
	}

	/**
	 * Updates a child of the operation group with the specified
	 * identifier with the supplied operation group relationship.
	 *
	 * @param {number} id
	 * The identifier of the operation group to update a child of.
	 * @param {IOperationGroupRelationshipDto} operationGroupRelationship
	 * The operation group relationship to update.
	 * @returns {Promise<object>}
	 * An observable of the put no-content response.
	 * @memberof OperationGroupApiService
	 */
	public async updateChild(
		id: number,
		operationGroupRelationship:
			IOperationGroupRelationshipDto): Promise<object>
	{
		const url = this.getNestedUrl(
			id,
			AppConstants.nestedRouteTypes.children);
		await this.resetAssociatedCache(url);

		return lastValueFrom(
			this.httpClient.put(
				url,
				operationGroupRelationship,
				{
					headers: this.queryOnlyGetHeaders
				}));
	}

	/**
	 * Deletes a child of the operation group with the specified
	 * identifier with the supplied operation group relationship.
	 *
	 * @param {number} id
	 * The identifier of the operation group to delete a child of.
	 * @param {IOperationGroupRelationshipDto} operationGroupRelationship
	 * The operation group relationship to delete.
	 * @returns {Promise<object>}
	 * An observable of the delete no-content response.
	 * @memberof OperationGroupApiService
	 */
	public async deleteChild(
		id: number,
		operationGroupRelationship:
			IOperationGroupRelationshipDto): Promise<object>
	{
		const nestedUrl: string =
			this.getNestedUrl(
				id,
				AppConstants.nestedRouteTypes.children)
				+ `/${operationGroupRelationship.type}`;
		const url =
			`${nestedUrl}/${operationGroupRelationship.id}`;
		await this.resetAssociatedCache(nestedUrl);

		return lastValueFrom(
			this.httpClient.delete(
				url));
	}
}