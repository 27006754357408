/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	OnInit,
	SecurityContext
} from '@angular/core';
import {
	DomSanitizer
} from '@angular/platform-browser';
import {
	FieldType
} from '@ngx-formly/core';
import {
	StringHelper
} from '@shared/helpers/string.helper';

@Component({
	selector: 'custom-text-display',
	templateUrl: './custom-text-display.component.html',
	styleUrls: [
		'./custom-text-display.component.scss'
	],
})

/**
 * A component representing an instance of a Custom Text Display.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomTextDisplayComponent
 * @extends {FieldType}
 */
export class CustomTextDisplayComponent
	extends FieldType
	implements OnInit
{
	/**
	 * Initializes a new instance of the ManageUserComponent class.
	 *
	 * @param {DomSanitizer} sanitizer
	 * The sanitizer used to ensure safe inner html and inner text displays.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @memberof ManageUserComponent
	 */
	public constructor(
		public sanitizer: DomSanitizer,
		public activityService: ActivityService)
	{
		super();
	}

	/**
	 * Gets or sets the text content to display.
	 *
	 * @type {string}
	 * @memberof CustomTextDisplayComponent
	 */
	public content: string;

	/**
	 * Gets or sets if content has been copied to clipboard.
	 *
	 * @type {boolean}
	 * @memberof CustomTextDisplayComponent
	 */
	public copiedToClipboard: boolean = false;

	/**
	 * Gets or sets the loading state.
	 *
	 * @type {boolean}
	 * @memberof CustomTextDisplayComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets the display time for the copied message when copying content
	 * to the clipboard.
	 *
	 * @type {number}
	 * @memberof CustomTextDisplayComponent
	 */
	private readonly copyToClipboardDisplayTime: number = 3000;

	/**
	 * Implements the on initialization interface.
	 * This will set the content to display in this text
	 * display component.
	 *
	 * @memberof CustomTextDisplayComponent
	 */
	public ngOnInit()
	{
		this.setContent();
		this.field.templateOptions.reloadTextDisplay =
			this.onTextDisplayReload.bind(this);
		this.loading = false;
	}

	/**
	 * Sets the text display content.
	 *
	 * @memberof CustomTextDisplayComponent
	 */
	public setContent(): void
	{
		this.content =
			this.sanitizer.sanitize(
				SecurityContext.HTML,
				this.sanitizer.bypassSecurityTrustHtml(
					this.field.templateOptions.useCodeBlock === true
						? this.getCodeBlock()
						: this.field.templateOptions.content));
	}

	/**
	 * The text display reload handler.
	 *
	 * @memberof CustomTextDisplayComponent
	 */
	public onTextDisplayReload(): void
	{
		this.loading = true;
		setTimeout(
			() =>
			{
				this.setContent();
				this.loading = false;
			},
			AppConstants.time.twentyFiveMilliseconds);
	}

	/**
	 * Gets the code block formatting.
	 *
	 * @returns {string}
	 * The code block.
	 * @memberof CustomTextDisplayComponent
	 */
	public getCodeBlock(): string
	{
		return `\`\`\`${this.field.templateOptions.codeBlockType}\n\r`
			+ `${this.field.templateOptions.content}\n\r\`\`\``;
	}

	/**
	 * A Function that determines content returned from the
	 * determined source (text/markdown) is null
	 *
	 * @returns {boolean}
	 * A truthy defining if the content view has content to display.
	 * @memberof CustomTextDisplayComponent
	 */
	public hasContent(): boolean
	{
		return !AnyHelper.isNullOrWhitespace(this.content);
	}

	/**
	 * Will copy the text content to clipboard.
	 *
	 * @memberof CustomTextDisplayComponent
	 */
	public copyToClipboard(text: string): void
	{
		StringHelper
			.copyToClipboard(text);
		this.copiedToClipboard = true;

		setTimeout(
			() =>
			{
				this.copiedToClipboard = false;
			},
			this.copyToClipboardDisplayTime);
	}
}