/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	DateTime
} from 'luxon';
import {
	Directive,
	Input,
	OnInit
} from '@angular/core';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	FormControl
} from '@angular/forms';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	InsuranceService
} from '@insurance/services/insurance.service';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	MenuItem
} from 'primeng/api';

/* eslint-enable max-len */

@Directive({
	selector: '[TransactionStatusEffectiveDate]'
})

/**
 * A directive representing a shared display for gather transaction based status
 * dates.
 *
 * @export
 * @class TransactionStatusEffectiveDateDirective
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class TransactionStatusEffectiveDateDirective
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the transaction status effective date
	 * component.
	 *
	 * @param {InsuranceService} insuranceService
	 * The insurance service used in this component.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service used in this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @memberof TransactionStatusEffectiveDateDirective
	 */
	public constructor(
		public insuranceService: InsuranceService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<DynamicWizardComponent, IWizardContext>}
	 * @memberof TransactionStatusEffectiveDateDirective
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets a value that indicates if new navigation data should be set.
	 *
	 * @type {boolean}
	 * @memberof TransactionStatusEffectiveDateDirective
	 */
	public updateNavigationData: boolean = true;

	/**
	 * Gets or sets a client message to display if this wizard is not valid
	 * to be completed.
	 *
	 * @type {string}
	 * @memberof TransactionStatusEffectiveDateDirective
	 */
	public clientMessage: string = AppConstants.empty;

	/**
	 * Gets or sets the message that will be set if the valid wizard step check
	 * returns false. This value should be set in the implementing component.
	 *
	 * @type {string}
	 * @memberof TransactionStatusEffectiveDateDirective
	 */
	public invalidWizardStepMessage: string = AppConstants.empty;

	/**
	 * Gets or sets the policy entity instance.
	 *
	 * @type {IEntityInstance}
	 * @memberof TransactionStatusEffectiveDateDirective
	 */
	public policy: IEntityInstance;

	/**
	 * Gets or sets the policy term entity instance.
	 *
	 * @type {IEntityInstance}
	 * @memberof TransactionStatusEffectiveDateDirective
	 */
	public policyTerm: IEntityInstance;

	/**
	 * Gets or sets the collection of non pending transactions.
	 *
	 * @type {IEntityInstance[]}
	 * @memberof TransactionStatusEffectiveDateDirective
	 */
	public transactionChildren: IEntityInstance[] = [];

	/**
	 * Gets or sets the formly layout used in implementing components. This
	 * value can be overridden in the implementing component.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof TransactionStatusEffectiveDateDirective
	 */
	public staticFormlyLayout: FormlyFieldConfig[] =
		<FormlyFieldConfig[]>
		[
			{
				key: 'data.statusEffectiveDate',
				type: FormlyConstants.customControls.customCalendar,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					label: 'Effective Date',
					required: true,
					quickDateSelection:
						<MenuItem>
						{
							label: 'Use Inception Date',
							command: () =>
								DateHelper.fromUtcSystemIsoToSimulatedLocalDate(
									this.policyTermInceptionDate.toISO())
						}
				},
				validators: {
					validEffectiveDate: {
						expression:
							(control: FormControl) =>
								DateTime.fromISO(control.value)
									>= this.policyTermInceptionDate
									&& DateTime.fromISO(control.value)
										< this.policyTermExpirationDate,
						message: 'The endorse date must be between the policy '
							+ 'effective and expiration dates.'
					},
					minimumEffectiveDate: {
						expression:
							(control: FormControl) =>
								DateTime.fromISO(control.value) >=
									this.minimumTransactionEffectiveDate,
						message: this.getMinimumEffectiveDateMessage.bind(this)
					}
				}
			}
		];

	/**
	 * Gets the minimum allowed effective date for validations. This equates
	 * the the most recent effective date of an issue transaction.
	 *
	 * @type {DateTime}
	 * @memberof TransactionStatusEffectiveDateDirective
	 */
	protected get minimumTransactionEffectiveDate(): DateTime
	{
		return DateTime.fromISO(
			this.transactionChildren[
				this.transactionChildren.length - 1].data.effectiveDate);
	}

	/**
	 * Gets the first issued transactions effective date.
	 *
	 * @type {DateTime}
	 * @memberof TransactionStatusEffectiveDateDirective
	 */
	protected get policyTermInceptionDate(): DateTime
	{
		return DateTime.fromISO(
			this.policyTerm.data.effectiveDate);
	}

	/**
	 * Gets the first issued transactions effective date.
	 *
	 * @type {DateTime}
	 * @memberof TransactionStatusEffectiveDateDirective
	 */
	protected get policyTermExpirationDate(): DateTime
	{
		return DateTime.fromISO(
			this.policyTerm.data.expirationDate);
	}

	/**
	 * Gets the dynamic child transaction entity type name.
	 *
	 * @type {string}
	 * @memberof TransactionStatusEffectiveDateDirective
	 */
	protected get childTransactionEntityTypeName(): string
	{
		return `PolicyTermTransaction.${this.policyTerm.data.productName}`;
	}

	/**
	 * Implements the on initialization interface.
	 *
	 * @async
	 * @memberof TransactionStatusEffectiveDateDirective
	 */
	public async ngOnInit(): Promise<void>
	{
		this.context.source.wizardStepLoading = true;

		this.context.source.validStepChanged(false);

		const routeData: any =
			this.context.source.activeMenuItem.currentData.data;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			routeData.statusEntityTypeGroup;
		this.policyTerm =
			await this.entityInstanceApiService.get(
				routeData.statusEntityId);

		await this.setPolicy();

		this.transactionChildren =
			await this.entityInstanceApiService.getChildren(
				this.policyTerm.id,
				`${AppConstants.commonProperties.status} eq `
					+ `'${InsuranceConstants.transactionStatusTypes.issued}'`,
				`${InsuranceConstants.commonProperties.effectiveDate} `
					+ `${AppConstants.sortDirections.ascending}, `
					+ `${AppConstants.commonProperties.id} `
					+ `${AppConstants.sortDirections.ascending}`,
				0,
				AppConstants.dataLimits.large,
				this.childTransactionEntityTypeName);

		this.context.source.updateGuardComparisonData();

		if ((await this.isWizardStepValidForDisplay()) === false)
		{
			this.clientMessage =
				this.invalidWizardStepMessage;
			this.context.source.wizardStepLoading = false;

			return;
		}

		await this.performPostInitActions();
	}

	/**
	 * Implements the on initialization interface.
	 *
	 * @async
	 * @memberof TransactionStatusEffectiveDateDirective
	 */
	public async performPostInitActions(): Promise<void>
	{
		this.context.source.wizardStepLoading = false;
	}

	/**
	 * Validates the wizard step based on the implementing component logic to
	 * confirm if this should be displayed or not.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * An awaitable promise that returns a value signifying whether or not
	 * the wizard step is valid for display.
	 * @memberof TransactionStatusEffectiveDateDirective
	 */
	public async isWizardStepValidForDisplay(): Promise<boolean>
	{
		// this should be implemented in the calling component if you wish
		// to have logic based hides or shows of this step.

		return true;
	}

	/**
	 * Sets the policy parent entity instance.
	 *
	 * @async
	 * @memberof TransactionStatusEffectiveDateDirective
	 */
	public async setPolicy()
	{
		const policyParents: IEntityInstance[] =
			await this.entityInstanceApiService.getParents(
				this.policyTerm.id,
				AppConstants.empty,
				AppConstants.empty,
				0,
				1,
				InsuranceConstants.insuranceEntityTypeGroups.policies);
		this.policy = policyParents[0];
	}

	/**
	 * Handles the validity changed event sent from the child dynamic
	 * formly component. This will update the validity of the form for
	 * action buttons.
	 *
	 * @async
	 * @param {boolean} isValid
	 * The validity of the current displayed step data set.
	 * @memberof TransactionStatusEffectiveDateDirective
	 */
	public async validityChanged(
		isValid: boolean): Promise<void>
	{
		this.context.source.validStepChanged(isValid);
	}

	/**
	 * Handles the logic of selecting the most recent issued transaction that
	 * was on or before the selected effective date in the wizard step.
	 *
	 * @memberof TransactionStatusEffectiveDateDirective
	 */
	public matchEffectiveDateToTransaction(): void
	{
		const currentEffectiveDate: string =
			this.context.source.activeMenuItem
				.currentData.data.statusEffectiveDate;
		const priorChildren: IEntityInstance[] =
			[...this.transactionChildren]
				.filter(
					(transaction: IEntityInstance) =>
						AnyHelper.isNullOrWhitespace(currentEffectiveDate)
							|| DateTime.fromISO(
								transaction.data.effectiveDate) <=
								DateTime.fromISO(currentEffectiveDate))
				.reverse();

		if (this.updateNavigationData === true)
		{
			this.context.source.addOrUpdateStepData(
				{
					entityType: this.childTransactionEntityTypeName,
					id: priorChildren[0].id
				});
		}
		else
		{
			this.context.source.addOrUpdateStepData(
				{
					basedOnEntityTypeGroup: this.childTransactionEntityTypeName,
					basedOnEntityId: priorChildren[0].id
				});
		}
	}

	/**
	 * Creates a minimum effective date validation message.
	 *
	 * @returns {string}
	 * A message signifying to the user that the selected date is not on or
	 * after to the minimum transaction effective date.
	 * @memberof TransactionStatusEffectiveDateDirective
	 */
	public getMinimumEffectiveDateMessage(): string
	{
		return 'The date must be on or after the '
			+ 'most recent issued effective date '
			+ `of ${DateHelper.formatDate(
				this.minimumTransactionEffectiveDate)}.`;
	}
}