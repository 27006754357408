/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppCanDeactivateGuard
} from '@shared/guards/app-can-deactivate.guard';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BaseOperationAction
} from '@operation/actions/base/base-operation-action';
import {
	CacheService
} from '@shared/services/cache.service';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	HttpEvent,
	HttpEventType
} from '@angular/common/http';
import {
	Injectable
} from '@angular/core';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	OperationExecutionService
} from '@operation/services/operation-execution.service';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	ResponseType
} from '@shared/constants/enums/response-type.enum';
import {
	SessionService
} from '@shared/services/session.service';
import {
	StorageType
} from '@shared/constants/enums/storage-type.enum';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/**
 * A class representing the action to generate a document with the sent
 * parameters.
 *
 * @export
 * @class GenerateDocumentAction
 * @extends {BaseOperationAction}
 */
@Injectable()
export class GenerateDocumentAction
	extends BaseOperationAction
{
	/**
	 * Creates an instance of a generate document action.
	 *
	 * @param {ActivityService} activityService
	 * The activity service used for this action.
	 * @param {sessionService} SessionService
	 * The session service used for this action.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The activity service used for this action.
	 * @param {CacheService} cacheService
	 * The cache service that is used to reset cached associations on generate.
	 * @param {OperationExecutionService} operationExecutionService
	 * The operation execution service used for this action.
	 * @param {OperationService} operationService
	 * The operation service used for this action.
	 * @param {OperationDefinitionApiService} operationDefinitionApiService
	 * The operation definition api service used for this action.
	 * @param {AppCanDeactivateGuard} appCanDeactivateGuard
	 * The app can deactivate guard.
	 * @memberof GenerateDocumentAction
	 */
	public constructor(
		public activityService: ActivityService,
		public sessionService: SessionService,
		public entityInstanceApiService: EntityInstanceApiService,
		public cacheService: CacheService,
		protected operationExecutionService: OperationExecutionService,
		protected operationService: OperationService,
		protected operationDefinitionApiService: OperationDefinitionApiService,
		protected appCanDeactivateGuard: AppCanDeactivateGuard)
	{
		super(
			activityService,
			operationExecutionService,
			operationService,
			operationDefinitionApiService,
			appCanDeactivateGuard);
	}

	/**
	 * Gets or sets the operation name.
	 *
	 * @type {string}
	 * @memberof GenerateDocumentAction
	 */
	public operationName: string =
		'GenerateDocument';

	/**
	 * Gets or sets the attach value for the workflow action. This will
	 * attach the document.
	 *
	 * @type {boolean}
	 * @memberof GenerateDocumentAction
	 */
	public attach: boolean = false;

	/**
	 * Gets or sets the files entity type.
	 *
	 * @type {string}
	 * @memberof GenerateDocumentAction
	 */
	public fileEntityType: string = AppConstants.empty;

	/**
	 * Gets or sets the file name.
	 *
	 * @type {string}
	 * @memberof GenerateDocumentAction
	 */
	public fileName: string = AppConstants.empty;

	/**
	 * Gets or sets the file description.
	 *
	 * @type {string}
	 * @memberof GenerateDocumentAction
	 */
	public fileDescription: string = AppConstants.empty;

	/**
	 * Gets or sets the output type.
	 *
	 * @type {string}
	 * @memberof GenerateDocumentAction
	 */
	public outputType: string = AppConstants.empty;

	/**
	 * Gets or sets the storage type.
	 *
	 * @type {StorageType}
	 * @memberof GenerateDocumentAction
	 */
	public storageType: StorageType = StorageType.Persisted;

	/**
	 * Gets or sets the template name.
	 *
	 * @type {string}
	 * @memberof GenerateDocumentAction
	 */
	public templateName: string = AppConstants.empty;

	/**
	 * Gets or sets the template request parameters object.
	 *
	 * @type {string}
	 * @memberof GenerateDocumentAction
	 */
	public templateParameters: string = AppConstants.empty;

	/**
	 * Gets or sets the template type.
	 *
	 * @type {string}
	 * @memberof GenerateDocumentAction
	 */
	public templateType: string = AppConstants.empty;

	/**
	 * Gets or sets the view result of this generate action.
	 *
	 * @type {boolean}
	 * @memberof GenerateDocumentAction
	 */
	public view: boolean = true;

	/**
	 * Gets or sets the query parameters string.
	 *
	 * @type {string}
	 * @memberof GenerateDocumentAction
	 */
	public query: string = AppConstants.empty;

	/**
	 * Executes the defined action.
	 *
	 * @async
	 * @memberof GenerateDocumentAction
	 */
	public async execute(): Promise<void>
	{
		const contextSource: EntityInstanceComponent =
			this.pageContext.source as EntityInstanceComponent;

		this.entityInstanceApiService
			.entityInstanceTypeGroup =
				contextSource.entityTypeGroup;

		await this.setQueryParameters();

		const promise: Promise<void> =
			new Promise<void>(
				(resolve, reject) =>
				{
					const responseType: ResponseType = this.view
						? ResponseType.Blob
						: ResponseType.Json;

					this.entityInstanceApiService
						.executeActionAsObservable<HttpEvent<any>>(
							contextSource.id,
							'GenerateDocument',
							null,
							this.query,
							responseType)
						.subscribe({
							next:
							(event: any) =>
							{
								if (event.type === HttpEventType.Response)
								{
									if (!event.ok)
									{
										reject();

										return;
									}

									const body: any = event.body;

									if (body instanceof Blob)
									{
										const fileUrl = window.URL
											.createObjectURL(body);

										const anchor = document
											.createElement('a');

										document
											.body
											.appendChild(anchor);

										anchor
											.href = fileUrl;

										window
											.open(
												fileUrl,
												AppConstants
													.windowTargets.blank);

										document
											.body
											.removeChild(anchor);
									}
								}
							},
							error: (error: any) => reject(error),
							complete: async() => {
								if ((this.pageContext.source instanceof
									EntityInstanceComponent))
								{
									await this
										.clearCachedEntityRelationships(
											this.pageContext.source);
								}

								resolve();
							}
						});
				});

		await this.activityService.handleActivity<void>(
			new Activity<void>(
				promise,
				`<strong>Generating New</strong> ${this.templateName}`,
				`<strong>Generated </strong> ${this.templateName}`,
				`New ${this.templateName} was successfully generated.`,
				`Error adding ${this.templateName}.`));
	}

	/**
	 * Clears entity id based relationship caches to allow this updated
	 * document to load in the initial query post generate.
	 *
	 * @async
	 * @param {EntityInstanceComponent} entityInstanceComponent
	 * The entity instance component that will have cleared relationships.
	 * @memberof GenerateDocumentAction
	 */
	private async clearCachedEntityRelationships(
		entityInstanceComponent: EntityInstanceComponent): Promise<void>
	{
		const baseUrl: string =
			entityInstanceComponent.entityInstanceApiService.getBaseUrl();
		const entityInstanceUrl: string =
			`${baseUrl}/${entityInstanceComponent.id}/children`;

		await this.cacheService.clearExistingStartsWithResponses(
			entityInstanceUrl);
	}

	/**
	 * Sets the query parameters from the template parameters property and the
	 * sent page context.
	 *
	 * @async
	 * @memberof GenerateDocumentAction
	 */
	private async setQueryParameters(): Promise<void>
	{
		const interpolatedTemplateParameters: string =
			await StringHelper.transformToDataPromise(
				StringHelper.interpolate(
					this.templateParameters,
					this.pageContext),
				this.pageContext);

		this.query = '?'
			+ interpolatedTemplateParameters
			+ `&currentUser.id=${this.sessionService.user.id}`
			+ `&view=${this.view}`
			+ `&attach=${this.attach}`
			+ `&fileEntityType=${this.fileEntityType}`
			+ `&outputType=${this.outputType}`
			+ `&templateType=${this.templateType}`
			+ `&templateName=${this.templateName}`
			+ `&fileDescription=${this.fileDescription}`;

		if (!AnyHelper.isNullOrWhitespace(this.fileName))
		{
			this.query += `&fileName=${this.fileName}`;
		}
	}
}