/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BaseStoredVariableService
} from '@shared/services/base/base-stored-variable.service';
import {
	Component,
	Injectable
} from '@angular/core';
import {
	DisplayComponentDefinition
} from '@shared/implementations/display-components/display-component-definition';
import {
	DisplayComponentDefinitionApiService
} from '@api/services/display-components/display-component-definition.api.service';
import {
	DisplayComponentInstance
} from '@shared/implementations/display-components/display-component-instance';
import {
	DisplayComponentInstanceApiService
} from '@api/services/display-components/display-component-instance.api.service';
import {
	DisplayComponentTypeApiService
} from '@api/services/display-components/display-component-type.api.service';
import {
	from,
	map
} from 'rxjs';
import {
	IDisplayComponentContainer
} from '@shared/interfaces/display-components/display-component-container.interface';
import {
	IDisplayComponentDefinition
} from '@shared/interfaces/display-components/display-component-definition.interface';
import {
	IDisplayComponentIdentifier
} from '@shared/interfaces/display-components/display-component-identifier.interface';
import {
	IDisplayComponentInstance
} from '@shared/interfaces/display-components/display-component-instance.interface';
import {
	IDisplayComponentType
} from '@shared/interfaces/display-components/display-component-type.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IStoredVariableDefinition
} from '@shared/interfaces/application-objects/stored-variable-definition';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/* eslint-enable max-len */

/**
 * A singleton class representing a display component service.
 *
 * @export
 * @class DisplayComponentService
 */
@Injectable({
	providedIn: 'root'
})
export class DisplayComponentService
	extends BaseStoredVariableService
{
	/**
	 * Creates an instance of a display component service.
	 *
	 * @param {DisplayComponentDefinitionApiService}
	 * displayComponentDefinitionApiService
	 * The api service for display component definitions.
	 * @param {DisplayComponentInstanceApiService}
	 * displayComponentInstanceApiService
	 * The api service for display component instances.
	 * @param {displayComponentTypeApiService}
	 * DisplayComponentTypeApiService
	 * The api service for display component types.
	 * @memberof DisplayComponentService
	 */
	public constructor(
		public displayComponentDefinitionApiService:
			DisplayComponentDefinitionApiService,
		public displayComponentInstanceApiService:
			DisplayComponentInstanceApiService,
		public displayComponentTypeApiService:
			DisplayComponentTypeApiService)
	{
		super();
		this.storedVariables.forEach(
			(storedVariable: IStoredVariableDefinition) =>
			{
				storedVariable.apiService.displayComponentService = this;
			});
	}

	/**
	 * Gets or sets the display component definitions.
	 *
	 * @type {IDisplayComponentDefinitionDto[]}
	 * @memberof DisplayComponentService
	 */
	public displayComponentDefinitions: IDisplayComponentDefinition[] = [];

	/**
	 * Gets or sets the display component instances.
	 *
	 * @type {IDisplayComponentInstance[]}
	 * @memberof DisplayComponentService
	 */
	public displayComponentInstances: IDisplayComponentInstance[] = [];

	/**
	 * Gets or sets the display component types.
	 *
	 * @type {IDisplayComponentType[]}
	 * @memberof DisplayComponentService
	 */
	public displayComponentTypes: IDisplayComponentType[] = [];

	/**
	 * Gets or sets the storage variables that will be stored in this
	 * singleton service.
	 *
	 * @type {IStoredVariableDefinition[]}
	 * @memberof DisplayComponentService
	 */
	public storedVariables: IStoredVariableDefinition[] =
		[
			{
				storageProperty:
					AppConstants.apiControllers.displayComponentDefinitions,
				apiService: this.displayComponentDefinitionApiService
			},
			{
				storageProperty:
					AppConstants.apiControllers.displayComponentInstances,
				apiService: this.displayComponentInstanceApiService
			},
			{
				storageProperty:
					AppConstants.apiControllers.displayComponentTypes,
				apiService: this.displayComponentTypeApiService
			}
		];

	/**
	 * Gets the key for the container key for a display component
	 * name.
	 *
	 * @type {string}
	 * @memberof DisplayComponentService
	 */
	private readonly stringInterpolationIdentifier: string =
		'${interpolationData.';

	/**
	 * Gets the key for the container key for a display component
	 * name.
	 *
	 * @type {string}
	 * @memberof DisplayComponentService
	 */
	private readonly propertyInterpolationIdentifier: string =
		'#{interpolationData.';

	/**
	 * Loads and subscribes to an array of display components used in a display
	 * container.
	 *
	 * @async
	 * @param {string} displayComponentInstanceName
	 * The display component instance name to load and subscribe to.
	 * @param {IDynamicComponentContext<Component, any>} pageContext
	 * The page context of this display component.
	 * @returns {Promise<IDisplayComponentContainer>}
	 * A populated display component container ready for subscriptions
	 * to child items.
	 * @memberof DisplayComponentService
	 */
	public async populateDisplayComponentContainer(
		displayComponentInstanceName: string,
		pageContext: IDynamicComponentContext<Component, any>):
		Promise<IDisplayComponentContainer>
	{
		await this.setStoredVariables();

		const displayComponent: DisplayComponentInstance =
			await this.populateDisplayComponentInstance(
				displayComponentInstanceName,
				pageContext,
				null,
				null,
				true);

		if (AnyHelper.isNull(displayComponent))
		{
			return null;
		}

		const displayContainerSubscription: IDisplayComponentContainer =
			this.subscribeToDisplayComponentContainer(
				displayComponent,
				displayComponent.jsonInterpolationData.displayComponents,
				pageContext);

		if (AnyHelper.isNull(displayContainerSubscription.container)
			|| AnyHelper.isNull(displayContainerSubscription.components))
		{
			return null;
		}

		return displayContainerSubscription;
	}

	/**
	 * Populates a definition of a display component.
	 *
	 * @async
	 * @param {string} displayComponentTypeName
	 * The name of the display component definition to populate.
	 * @param {string} displayComponentDefinitionComponentName
	 * The name of the display component definition external component for
	 * lookup matches.
	 * @returns {Promise<IDisplayComponentDefinition>}
	 * An awaitable populated display component definition matching the sent
	 * parameters.
	 * @memberof DisplayComponentService
	 */
	public async populateDisplayComponentDefinition(
		displayComponentTypeName: string,
		displayComponentDefinitionComponentName: string):
		Promise<IDisplayComponentDefinition>
	{
		await this.setStoredVariables();

		const displayComponentType: IDisplayComponentType =
			this.displayComponentTypes.find(
				(displayType: IDisplayComponentType) =>
					displayType.name === displayComponentTypeName);
		const displayComponentDefinition: IDisplayComponentDefinition =
			this.displayComponentDefinitions.find(
				(displayDefinition: IDisplayComponentDefinition) =>
					displayDefinition.typeId === displayComponentType.id
						&& displayDefinition.componentName ===
							displayComponentDefinitionComponentName);

		return displayComponentDefinition;
	}

	/**
	 * Populates an instance of a display component.
	 *
	 * @async
	 * @param {string} displayComponentInstanceName
	 * The name of the display component instance to populate.
	 * @param {IDynamicComponentContext<Component, any>} pageContext
	 * The page context of this display component.
	 * @param {number} order
	 * If sent, this will signify the order of the display component
	 * in it's associated container. The default value is 1.
	 * @param {IDisplayComponentInstance} iDisplayComponentInstance
	 * Handler that allows for a pre-loaded display component instance.
	 * @param {boolean} returnInstanceIfHidden
	 * If sent and true, this will return the display component instance with a
	 * visible value.
	 * @returns {Promise<DisplayComponentInstance>}
	 * An awaitable populated display component instance ready for
	 * display component factory creation.
	 * @memberof DisplayComponentService
	 */
	public async populateDisplayComponentInstance(
		displayComponentInstanceName: string,
		pageContext: IDynamicComponentContext<Component, any>,
		order: number = 1,
		iDisplayComponentInstance: IDisplayComponentInstance = null,
		returnInstanceIfHidden: boolean = false):
		Promise<DisplayComponentInstance>
	{
		await this.setStoredVariables();

		const displayComponentInstance: DisplayComponentInstance =
			await this.getDisplayComponentInstance(
				displayComponentInstanceName,
				order,
				iDisplayComponentInstance);

		if (AnyHelper.isNull(displayComponentInstance))
		{
			return null;
		}

		const interpolatedDisplayComponentInstance: DisplayComponentInstance =
			this.interpolateDisplayComponent(
				displayComponentInstance);

		if (AnyHelper.isNullOrWhitespace(
			interpolatedDisplayComponentInstance.rawDisplayPromise))
		{
			return interpolatedDisplayComponentInstance;
		}

		interpolatedDisplayComponentInstance.visible =
			await StringHelper.transformAndExecuteBooleanPromise(
				interpolatedDisplayComponentInstance.rawDisplayPromise,
				pageContext);

		return interpolatedDisplayComponentInstance.visible === false
			&& returnInstanceIfHidden === false
			? null
			: interpolatedDisplayComponentInstance;
	}

	/**
	 * Subscribes to an array of display components used in a display
	 * container.
	 *
	 * @async
	 * @param {DisplayComponentInstance} displayComponentInstance
	 * The display component instance to subscribe to.
	 * @param {IDisplayComponentIdentifier[]} displayComponentArray
	 * The array of defined display component identifiers to populate
	 * as a subscribable response.
	 * @param {IDynamicComponentContext<Component, any>} pageContext
	 * The page context of this display component.
	 * @returns {Promise<IDisplayComponentContainer>}
	 * A populated display component container ready for subscriptions
	 * to child items.
	 * @memberof DisplayComponentService
	 */
	public subscribeToDisplayComponentContainer(
		displayComponentInstance: DisplayComponentInstance,
		displayComponentArray: IDisplayComponentIdentifier[],
		pageContext: IDynamicComponentContext<Component, any>):
		IDisplayComponentContainer
	{
		if (AnyHelper.isNull(displayComponentInstance))
		{
			return null;
		}

		return <IDisplayComponentContainer>
			{
				container: displayComponentInstance,
				components: from(displayComponentArray)
					.pipe(
						map(async(
							displayComponent: IDisplayComponentIdentifier,
							index: number) =>
							this.populateDisplayComponentInstance(
								displayComponent.displayComponent,
								pageContext,
								index)))
			};
	}

	/**
	 * Searches in a filtered set of display component definitions that start
	 * with the supplied display type name for a json definition that has
	 * the supplied property name with the supplied property value.
	 *
	 * @async
	 * @param {string} startsWithDisplayTypeName
	 * The display type that will filter the set of display component
	 * definitions to check for this property value.
	 * @param {string} propertyName
	 * The property name to search for in the json definition.
	 * @param {any} requiredPropertyValue
	 * The property value to find a match for in the json definition.
	 * @returns {Promise<DisplayComponentDefinition>}
	 * A filtered display component definition set where all have a property
	 * name matching the supplied property value.
	 * @memberof DisplayComponentService
	 */
	public async getDisplayDefinitionsWithPropertyValue(
		startsWithDisplayTypeName: string,
		propertyName: string,
		requiredPropertyValue: any): Promise<DisplayComponentDefinition[]>
	{
		await this.setStoredVariables();

		const matchingDisplayComponentDefinitions:
			DisplayComponentDefinition[] = [];
		const displayTypes: IDisplayComponentType[] =
			this.displayComponentTypes.filter(
				(displayType: IDisplayComponentType) =>
					displayType.name.indexOf(
						startsWithDisplayTypeName) === 0);
		const displayTypeIds: number[] =
			displayTypes.map(
				(displayType: IDisplayComponentType) =>
					displayType.id);
		const availableReportDefinitions: IDisplayComponentDefinition[] =
			this.displayComponentDefinitions.filter(
				(displayDefinition: IDisplayComponentDefinition) =>
					displayTypeIds.indexOf(
						displayDefinition.typeId) !== -1);

		availableReportDefinitions.forEach(
			(definitionInterface: IDisplayComponentDefinition) =>
			{
				const definition: DisplayComponentDefinition =
					new DisplayComponentDefinition(definitionInterface);
				const mappedValue: any =
					definition.jsonDefinition[propertyName];
				if (!AnyHelper.isNullOrWhitespace(mappedValue)
					&& mappedValue.indexOf('${') === -1
					&& mappedValue === requiredPropertyValue)
				{
					matchingDisplayComponentDefinitions
						.push(definition);
				}
			});

		return matchingDisplayComponentDefinitions;
	}

	/**
	 * Gathers data via api actions to create an instance of a display
	 * component.
	 *
	 * @async
	 * @param {string} displayComponentInstanceName
	 * The name of the display component instance to populate.
	 * @param {number} order
	 * If sent, this will signify the order of the display component
	 * in it's associated container. The default value is 1.
	 * @param {IDisplayComponentInstance} iDisplayComponentInstance
	 * Handler that allows for a pre-loaded display component instance.
	 * @returns {Promise<DisplayComponentInstance>}
	 * An awaitable display component instance populated via api lookups.
	 * @memberof DisplayComponentService
	 */
	private async getDisplayComponentInstance(
		displayComponentInstanceName: string,
		order: number = 1,
		iDisplayComponentInstance: IDisplayComponentInstance = null):
		Promise<DisplayComponentInstance>
	{
		const currentDisplayComponentInstance: IDisplayComponentInstance =
			AnyHelper.isNull(iDisplayComponentInstance)
				? this.displayComponentInstances.find(
					(displayInstance: IDisplayComponentInstance) =>
						displayInstance.name ===
							displayComponentInstanceName)
				: iDisplayComponentInstance;

		if (AnyHelper.isNull(currentDisplayComponentInstance))
		{
			return null;
		}

		const displayComponentInstance: DisplayComponentInstance =
			new DisplayComponentInstance(
				currentDisplayComponentInstance);
		displayComponentInstance.order = order;

		const iDisplayComponentDefinition: IDisplayComponentDefinition =
			this.displayComponentDefinitions.find(
				(displayDefinition: IDisplayComponentDefinition) =>
					displayDefinition.id ===
					displayComponentInstance.definitionId);

		displayComponentInstance.displayComponentDefinition =
			new DisplayComponentDefinition(
				iDisplayComponentDefinition);

		return displayComponentInstance;
	}

	/**
	 * Interpolates property values between the definition and the instance
	 * interpolation data.
	 *
	 * @param {DisplayComponentInstance} displayComponentInstance
	 * The display component instance ready for data interpolation.
	 * @returns {DisplayComponentInstance}
	 * A display component instance with a mapped and interpolated
	 * definition.
	 * @memberof DisplayComponentService
	 */
	private interpolateDisplayComponent(
		displayComponentInstance: DisplayComponentInstance):
		DisplayComponentInstance
	{
		const displayComponentDefinition: DisplayComponentDefinition =
			displayComponentInstance.displayComponentDefinition;

		Object.keys(displayComponentDefinition.jsonDefinition)
			.forEach((propertyName: string) =>
			{
				const propertyValue: any =
					displayComponentDefinition.jsonDefinition[propertyName];

				// String interpolation
				if (AnyHelper.isString(propertyValue) === true
					&& propertyValue.indexOf(
						this.stringInterpolationIdentifier) !== -1)
				{
					displayComponentDefinition
						.setDefinitionProperty(
							propertyName,
							StringHelper.interpolate(
								displayComponentDefinition
									.jsonDefinition[propertyName],
								displayComponentInstance
									.jsonInterpolationData));
				}

				// Property Interpolation
				if (AnyHelper.isString(propertyValue) === true
					&& propertyValue.indexOf(
						this.propertyInterpolationIdentifier
							+ propertyName) !== -1)
				{
					const interpolationDataName: string =
						propertyValue
							.replace(
								/\#{interpolationData.(.*?)\}/gm,
								'$1');

					displayComponentDefinition
						.setDefinitionProperty(
							propertyName,
							displayComponentInstance.jsonInterpolationData[
								interpolationDataName]);
				}
			});

		return displayComponentInstance;
	}
}