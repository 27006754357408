/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/member-ordering */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiHelper
} from '@shared/helpers/api.helper';
import {
	AppConfig
} from 'src/app/app.config';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AppTimeSpan
} from '@shared/app-timespan';
import {
	AuthenticateApiService
} from '@api/services/security/authenticate.api.service';
import {
	DisplayComponentService
} from '@shared/services/display-component.service';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	HttpHeaders
} from '@angular/common/http';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	Injectable,
	OnInit
} from '@angular/core';
import {
	ISecurityGroup
} from '@shared/interfaces/security/security-group.interface';
import {
	ISecuritySession
} from '@shared/interfaces/security/security-session.interface';
import {
	IUser
} from '@shared/interfaces/users/user.interface';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	Router
} from '@angular/router';
import {
	RuleService
} from '@shared/services/rule.service';
import {
	SecurityGroupApiService
} from '@api/services/security/security-group.api.service';
import {
	SecuritySessionApiService
} from '@api/services/security/security-session.api.service';
import {
	Settings
} from 'luxon';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	UserService
} from '@shared/services/user.service';
import {
	WindowEventConstants
} from '@shared/constants/window-event.constants';

/**
 * A class representing a Session Service.
 *
 * @export
 * @class SessionService
 * @implements {OnInit}
 */
@Injectable({
	providedIn: 'root'
})
export class SessionService
implements OnInit
{
	/**
	 * Creates an instance of SessionService.
	 *
	 * @param {AuthenticateApiService} authenticateApiService
	 * The authentication api service.
	 * @param {SecurityGroupApiService} securityGroupApiService
	 * The security group api service.
	 * @param {SecuritySessionApiService} securitySessionApiService
	 * The security session api service.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service.
	 * @param {DisplayComponentService} displayComponentService
	 * The display component service.
	 * @param {OperationService} operationService
	 * The operation service.
	 * @param {EntityService} entityService
	 * The entity service.
	 * @param {RuleService} ruleService
	 * The rule service.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service.
	 * @param {Router} router
	 * The router used for navigation.
	 * @param {UserService} userService
	 * The user service.
	 * @memberof SessionService
	 */
	public constructor(
		private readonly authenticateApiService: AuthenticateApiService,
		private readonly securityGroupApiService: SecurityGroupApiService,
		private readonly securitySessionApiService: SecuritySessionApiService,
		private readonly siteLayoutService: SiteLayoutService,
		private readonly displayComponentService: DisplayComponentService,
		private readonly operationService: OperationService,
		private readonly entityService: EntityService,
		private readonly ruleService: RuleService,
		private readonly entityInstanceApiService: EntityInstanceApiService,
		private readonly router: Router,
		private readonly userService: UserService)
	{
	}

	/**
	 * Gets or sets a value defining whether all operation local storage values
	 * are currently set.
	 *
	 * @type {boolean}
	 * @memberof SessionService
	 */
	public operationGroupsStored: boolean = false;

	/**
	 * Gets a {string} representing the system time zone.
	 *
	 * @type {string}
	 * @memberof SessionService
	 */
	public get systemTimeZone(): string
	{
		const localSystemTimeZone: string =
			localStorage.getItem(
				AppConstants.storage.systemTimeZone);

		return (AnyHelper.isNullOrEmpty(localSystemTimeZone))
			? AppConstants.empty
			: localSystemTimeZone;
	}

	/**
	 * Sets a {string} representing the system time zone.
	 *
	 * @memberof SessionService
	 */
	public set systemTimeZone(
		value: string)
	{
		localStorage.setItem(
			AppConstants.storage.systemTimeZone,
			!AnyHelper.isNull(value)
				? value
				: null);
	}

	/**
	 * Gets a value indicating whether the session is valid.
	 *
	 * @type {boolean}
	 * @memberof SessionService
	 */
	public get isValid(): boolean
	{
		return 'true' === (
			localStorage.getItem(
				AppConstants.storage.sessionValidKey)
			|| 'false');
	}

	/**
	 * Sets a value indicating whether the session is valid.
	 *
	 * @memberof SessionService
	 */
	public set isValid(
		value: boolean)
	{
		localStorage.setItem(
			AppConstants.storage.sessionValidKey,
			value.toString());
	}

	/**
	 * Gets an {IUser} of the logged in session user.
	 *
	 * @type {IUser}
	 * @memberof SessionService
	 */
	public get user(): IUser
	{
		const userStringified: string
			= localStorage.getItem(
				AppConstants.storage.securityUserKey);

		if (AnyHelper.isNullOrEmpty(userStringified))
		{
			return null;
		}

		return <IUser | null>JSON.parse(userStringified);
	}

	/**
	 * Sets an {IUser} to the session user.
	 *
	 * @memberof SessionService
	 */
	public set user(
		value: IUser)
	{
		localStorage.setItem(
			AppConstants.storage.securityUserKey,
			value ? JSON.stringify(value) : null);
	}

	/**
	 * Gets a {Date} value representing the expiry date and time of the session.
	 *
	 * @type {Date}
	 * @memberof SessionService
	 */
	public get expiryDate(): Date
	{
		const localExpiry: string =
			localStorage.getItem(
				AppConstants.storage.sessionExpiryKey);

		if (AnyHelper.isNullOrEmpty(localExpiry))
		{
			return new Date();
		}

		return new Date(localExpiry);
	}

	/**
	 * Sets a {Date} value representing the expiry date and time of the session.
	 *
	 * @memberof SessionService
	 */
	public set expiryDate(
		value: Date)
	{
		localStorage.setItem(
			AppConstants.storage.sessionExpiryKey,
			value
				? value.toString()
				: null);
	}

	/**
	 * Gets a {TimeSpan} representing the duration a session is valid.
	 *
	 * @type {AppTimeSpan}
	 * @memberof SessionService
	 */
	public get expiry(): AppTimeSpan
	{
		return new AppTimeSpan(
			localStorage.getItem(
				AppConstants.storage.securityExpiryKey)
			|| AppConstants.empty);
	}

	/**
	 * Sets a {TimeSpan} representing the duration a session is valid.
	 *
	 * @memberof SessionService
	 */
	public set expiry(
		value: AppTimeSpan)
	{
		localStorage.setItem(
			AppConstants.storage.securityExpiryKey,
			value ? value.toString() : null);
	}

	/**
	 * Gets a {string} representing the session security token.
	 *
	 * @type {string}
	 * @memberof SessionService
	 */
	public get token(): string
	{
		const localToken = localStorage.getItem(
			AppConstants.storage.securityTokenKey);

		return (AnyHelper.isNullOrEmpty(localToken))
			? AppConstants.empty
			: localToken;
	}

	/**
	 * Sets a {string} representing the session security token.
	 *
	 * @memberof SessionService
	 */
	public set token(
		value: string)
	{
		localStorage.setItem(
			AppConstants.storage.securityTokenKey,
			value ? value : null);
	}

	/**
	 * Gets a {string} representing the session id.
	 *
	 * @type {string}
	 * @memberof SessionService
	 */
	public get sessionId(): string
	{
		const localSessionId =
			localStorage.getItem(
				AppConstants.storage.sessionIdKey);

		return (AnyHelper.isNullOrEmpty(localSessionId))
			? AppConstants.empty
			: localSessionId;
	}

	/**
	 * Sets a {string} representing the session id.
	 *
	 * @memberof SessionService
	 */
	public set sessionId(
		value: string)
	{
		localStorage.setItem(
			AppConstants.storage.sessionIdKey,
			value ? value : null);
	}

	/**
	 * Gets a {string} representing the multi-factor authentication method.
	 *
	 * @type {string}
	 * @memberof SessionService
	 */
	public get multiFactorMethod(): string
	{
		const localMultiFactorMethod = localStorage.getItem(
			AppConstants.storage.securityMultiFactorAuthenticationMethodKey);

		return (AnyHelper.isNullOrEmpty(localMultiFactorMethod))
			? AppConstants.empty
			: localMultiFactorMethod;
	}

	/**
	 * Sets a {string} representing the multi-factor authentication method.
	 *
	 * @memberof SessionService
	 */
	public set multiFactorMethod(
		value: string)
	{
		localStorage.setItem(
			AppConstants.storage.securityMultiFactorAuthenticationMethodKey,
			value ? value : null);
	}

	/**
	 * Gets a {boolean} value indicating whether multi-factor authentication is
	 * enabled.
	 *
	 * @type {boolean}
	 * @memberof SessionService
	 */
	public get isMultiFactorEnabled(): boolean
	{
		return 'true' === (
			localStorage.getItem(
				AppConstants.storage
					.securityMultiFactorAuthenticationEnabledKey)
			|| 'false');
	}

	/**
	 * Sets a {boolean} value indicating whether multi-factor authentication is
	 * enabled.
	 *
	 * @memberof SessionService
	 */
	public set isMultiFactorEnabled(
		value: boolean)
	{
		localStorage.setItem(
			AppConstants.storage.securityMultiFactorAuthenticationEnabledKey,
			value.toString());
	}

	/**
	 * Gets a {boolean} value indicating whether the session is logged in.
	 *
	 * @readonly
	 * @type {boolean}
	 * @memberof SessionService
	 */
	public get isLoggedIn(): boolean
	{
		if (AnyHelper.isNullOrEmpty(this.user)
			|| AnyHelper.isNullOrEmpty(this.token))
		{
			return false;
		}

		return true;
	}

	/**
	 * Gets a {boolean} value indicating whether the inline manual
	 * tracking is enabled and configured.
	 *
	 * @readonly
	 * @type {boolean}
	 * @memberof SessionService
	 */
	public get isInlineManualTrackingEnabled(): boolean
	{
		if (AnyHelper.isNullOrEmpty(window.inlineManualTracking))
		{
			if (this.isLoggedIn === false)
			{
				return false;
			}

			this.setInlineManualTracking();
		}

		return true;
	}

	/**
	 * Gets a {string} representing the application token.
	 *
	 * @readonly
	 * @type {string}
	 * @memberof SessionService
	 */
	public get applicationToken(): string
	{
		const token: string = AppConfig.settings.webApi.applicationToken;

		return AnyHelper.isNullOrEmpty(token)
			? null
			: token;
	}

	/**
	 * Gets a value indicating whether the user data is fully
	 * ready for application use.
	 *
	 * @type {boolean}
	 * A combination of status of whether the user is logged in,
	 * is valid, and security groups are populated.
	 * @memberof LoginDialogComponent
	 */
	public get isValidLoggedInAndReady(): boolean
	{
		return this.isLoggedIn && this.isValid
			&& this.user.accessibleSecurityGroups != null
			&& !AnyHelper.isNullOrWhitespace(this.systemTimeZone)
			&& this.isInlineManualTrackingEnabled;
	}

	/**
	 * On initialization of service.
	 *
	 * @memberof SessionService
	 */
	public ngOnInit(): void
	{
		this.clear();
	}

	/**
	 * Performs an async login request to the api service implementing
	 * the /Authenticate method.
	 *
	 * @param {string} userName
	 * A string representing the user name to login as.
	 * @param {string} password
	 * A string representing the password for the user name.
	 * @returns {Promise<IUser>}
	 * The logged in user.
	 * @memberof AuthenticateService
	 */
	public async login(
		userName: string,
		password: string): Promise<IUser>
	{
		// tslint:disable-next-line:no-any
		const response: any
			= await this.authenticateApiService.login(
				userName,
				password,
				this.applicationToken);

		this.setFromHeaders(response.headers);
		this.user = response.user;
		this.siteLayoutService.setFromUserSettings(this.user);

		this.isValid =
			this.isMultiFactorEnabled
				? false
				: true;

		if (this.isValid)
		{
			await this.decorateSystemTimeZone();
			await this.setSessionId();
			await this.setStoredVariables();
			await this.decorateUserSecurityGroups(this.user);
		}

		return this.user;
	}

	/**
	 * Performs an async verification request to the api service implementing
	 * the /Authenticate/Verify method.
	 *
	 * @param {string} token
	 * A string representing the security access token to verify.
	 * @param {string} code
	 * A string representing the verification code.
	 * @returns {Promise<void>}
	 * @memberof AuthenticateService
	 */
	public async verify(
		token: string,
		code: string): Promise<void>
	{
		await this.authenticateApiService.verify(
			token,
			code);

		await this.decorateSystemTimeZone();
		await this.setSessionId();
		await this.setStoredVariables();
		await this.decorateUserSecurityGroups(this.user);

		this.isValid = true;
	}

	/**
	 * Performs an async verification request to the api service implementing
	 * the /Authenticate/Reset method.
	 *
	 * @param {string} userName
	 * A string representing the user name to reset.
	 * @returns {Promise<void>}
	 * @memberof AuthenticateService
	 */
	public async resetUser(
		userName: string): Promise<void>
	{
		await this.authenticateApiService.reset(userName);
	}

	/**
	 * Sets the security token in local storage from the headers.
	 *
	 * @param {Headers} header
	 * @memberof SessionService
	 */
	public setFromHeaders(
		header: HttpHeaders): void
	{
		this.token = header.get(AppConstants.webApi.tokenKey);

		if (header.has(AppConstants.webApi.multiFactorKey))
		{
			const raw: string
				= header.get(AppConstants.webApi.multiFactorKey);

			const parts: string[] = raw.split(',');
			const enabled: boolean = parts[0].toLocaleLowerCase() === 'true';
			const method: string = parts[1];

			this.isMultiFactorEnabled = enabled;
			this.multiFactorMethod = method;
		}

		if (header.has(AppConstants.webApi.tokenExpiryKey))
		{
			this.expiry = new AppTimeSpan(
				header.get(AppConstants.webApi.tokenExpiryKey));

			this.resetExpiryDate();
		}
	}

	/**
	 * Resets the session expiry date.
	 *
	 * @memberof SessionService
	 */
	public resetExpiryDate(): void
	{
		const date: Date = new Date();
		date.setMilliseconds(
			date.getMilliseconds()
			+ this.expiry.totalMilliSeconds);

		this.expiryDate = date;
	}

	/**
	 * Clears the session from local storage.
	 *
	 * @memberof SessionService
	 */
	public clear(): void
	{
		this.isValid = false;
		this.user = null;
		this.expiryDate = new Date();
		this.expiry = new AppTimeSpan('0:15:0');
		this.token = null;
		this.multiFactorMethod = 'email';
		this.isMultiFactorEnabled = false;
		this.sessionId = null;
		this.systemTimeZone = null;
	}

	/**
	 * Logs the user session out.
	 *
	 * @memberof SessionService
	 */
	public logOut(): void
	{
		this.clear();
		this.router.navigate([AppConstants.route.loginPage]);
	}

	/**
	 * Sets stored variables on any singleton storage classes on login.
	 *
	 * @async
	 * @memberof SessionService
	 */
	public async setStoredVariables(): Promise<void>
	{

		this.operationService.clearStoredVariables();
		this.displayComponentService.clearStoredVariables();
		this.entityService.clearStoredVariables();
		this.ruleService.clearStoredVariables();

		setTimeout(
			async() =>
			{
				await this.displayComponentService.setStoredVariables();
			},
			AppConstants.time.halfSecond);

		setTimeout(
			async() =>
			{
				await this.entityService.setStoredVariables();
			},
			AppConstants.time.twoSeconds);

		setTimeout(
			async() =>
			{
				await this.ruleService.setStoredVariables();
			},
			AppConstants.time.twoSeconds);

		await this.operationService.setStoredVariables();
	}

	/**
	 * Gets and sets the security session id matching this user session.
	 *
	 * @async
	 * @memberof AuthenticateService
	 */
	public async setSessionId(): Promise<void>
	{
		const securitySession: ISecuritySession =
			await this.securitySessionApiService.getSingleQueryResult(
				`token eq '${this.token}'`,
				AppConstants.empty);

		this.sessionId = securitySession.id.toString();
	}

	/**
	 * Decorates the security groups that the user has access to
	 * into the session user object.
	 *
	 * @async
	 * @param {IUser} user
	 * The logged in user.
	 * @returns {Promise<IUser>}
	 * The user with user security group values set and ready for use.
	 * @memberof SessionService
	 */
	public async decorateUserSecurityGroups(
		user: IUser): Promise<IUser>
	{
		if (this.isValid)
		{
			const accessibleSecurityGroups: ISecurityGroup[] =
				await ApiHelper.getFullDataSet(
					this.securityGroupApiService,
					AppConstants.empty,
					AppConstants.empty);

			const membershipFilter: string =
				'SecurityGroupEntityInstances.Any('
					+ `EntityInstanceId eq ${user.id})`;

			const membershipSecurityGroups: ISecurityGroup[] =
				await ApiHelper.getFullDataSet(
					this.securityGroupApiService,
					membershipFilter,
					AppConstants.empty);

			await this.userService.setUserSecurityGroups(
				user,
				accessibleSecurityGroups,
				membershipSecurityGroups);
		}
		else
		{
			user.accessibleSecurityGroups = [];
			user.membershipSecurityGroups = [];
		}

		this.user = user;

		return user;
	}

	/**
	 * Decorates the system time zone for data handling and display site wide.
	 *
	 * @async
	 * @memberof SessionService
	 */
	public async decorateSystemTimeZone(): Promise<void>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.systems;
		const systemInstance: IEntityInstance =
			await this.entityInstanceApiService.get(
				parseInt(
					AppConstants.systemId,
					AppConstants.parseRadix));

		const systemTimeZone: string =
			systemInstance.data.settings
				?.systemTimeZone
				?.internetAssignedNumbersAuthority;

		if (AnyHelper.isNullOrWhitespace(systemTimeZone))
		{
			this.logOut();

			EventHelper.dispatchLoginMessageEvent(
				'System Time Zone Undefined',
				'Unable to calculate a system time zone. '
					+ 'Please ensure the system time zone is set in the System '
					+ 'entity instance.',
				AppConstants.messageLevel.error);
		}

		this.systemTimeZone = systemTimeZone;
		Settings.defaultZone = systemTimeZone;
	}

	/**
	 * Adds a local storage event listener.
	 *
	 * @memberof SessionService
	 */
	public addLocalStorageEventListener(): void
	{
		window.addEventListener(
			WindowEventConstants.storage,
			(storageEvent: any) =>
				this.handleStorageEvent(storageEvent));
	}

	/**
	 * Handles the storage event.
	 *
	 * @memberof SessionService
	 */
	public handleStorageEvent(event: any): void
	{
		if (event.key !== AppConstants.storage.securityUserKey)
		{
			return;
		}

		const previousUserName: string =
			!AnyHelper.isNull(event.oldValue)
				? JSON.parse(event.oldValue).data.userName
				: null;
		const currentUserName: string =
			!AnyHelper.isNull(event.newValue)
				? JSON.parse(event.newValue).data.userName
				: null;

		if (previousUserName !== currentUserName
			&& this.router.url !== '/login')
		{
			window.location.reload();
			window.removeEventListener(
				WindowEventConstants.storage,
				(storageEvent: any) =>
					this.handleStorageEvent(storageEvent));
		}
	}

	/**
	 * Sets the inline manual tracking and configuration for the user
	 * session.
	 *
	 * @private
	 * @memberof SessionService
	 */
	private setInlineManualTracking(): void
	{
		if (!this.isLoggedIn)
		{
			return;
		}

		const user: IUser = this.user;
		const userSecurityGroups: string[] =
			user.accessibleSecurityGroups.map((item) =>
				item.name);

		window.inlineManualTracking =
		{
			uid: this.user.id,
			email: this.user.data.email,
			username: this.user.data.userName,
			name: this.user.data.firstName
				+ AppConstants.characters.space
				+ this.user.data.lastName,
			roles: userSecurityGroups,
			environment: AppConfig.settings.branding.name
		};

		let retries: number = 0;
		const timer: NodeJS.Timeout = setInterval(() =>
		{
			if (AnyHelper.isNull(window.createInlineManualPlayer) === false)
			{
				window.createInlineManualPlayer(window.inlineManualPlayerData);
				clearInterval(timer);
			}
			else if (retries > AppConstants.maxRetries.oneHundred)
			{
				clearInterval(timer);
			}

			retries++;
		}, AppConstants.time.fiftyMilliseconds);
	}
}