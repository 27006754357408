/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AppEventParameterConstants
} from '@shared/constants/app-event-parameter.constants';
import {
	Component,
	HostListener,
	OnInit
} from '@angular/core';
import {
	DomSanitizer,
	SafeResourceUrl
} from '@angular/platform-browser';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	FieldType
} from '@ngx-formly/core';
import {
	WindowEventConstants
} from '@shared/constants/window-event.constants';

@Component({
	selector: 'custom-iframe',
	templateUrl: './custom-iframe.component.html',
	styleUrls: ['./custom-iframe.component.scss'],
})

/**
 * A component representing an instance of a Custom iFrame.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomIframeComponent
 * @extends {FieldType}
 * @implements {OnInit}
 */
export class CustomIframeComponent
	extends FieldType
	implements OnInit
{
	/**
	 * Creates an instance of an CustomIframeComponent.
	 *
	 * @param {DomSanitizer} sanitizer
	 * The sanitizer that helps to avoid XSS bugs.
	 */
	public constructor(
		private readonly sanitizer: DomSanitizer)
	{
		super();
	}

	/**
	 * Gets or sets message data.
	 *
	 * @type {Object}
	 * @memberof CustomIframeComponent
	 */
	public hostMessage: Object =
	{
		'string': 'testIframeHostMessageName',
		'number': 1,
		'boolean': true
	};

	/**
	 * Gets or sets FullScreen action to true.
	 *
	 * @type {string}
	 * @memberof CustomIframeComponent
	 */
	public isFullScreen: boolean = false;

	/**
	 * Gets or sets the IFrame HTML collection of elements.
	 *
	 * @type {HTMLIFrameElement}
	 * @memberof CustomIframeComponent
	 */
	public iframes: HTMLCollectionOf<HTMLIFrameElement>;

	/**
	 * Gets or sets the height string for the iFrame.
	 *
	 * @type {string}
	 * @memberof CustomIframeComponent
	 */
	public iframeHeight: string;

	/**
	 * Gets or sets the view port percentage to calculate the
	 * height for the iFrame.
	 *
	 * @type {string}
	 * @memberof CustomIframeComponent
	 */
	public iframeSize: string = 'small';

	/**
	 * Gets or sets the URL for the iFrame.
	 *
	 * @type {SafeResourceUrl}
	 * @memberof CustomIframeComponent
	 */
	public iframeTargetUrl: SafeResourceUrl;

	/**
	 * An active event listener for any window message event.
	 *
	 * @memberof CustomIframeComponent
	 */
	@HostListener(
		WindowEventConstants.messageEvent,
		[AppEventParameterConstants.event])
	public onMessage(event: { origin: string; data: any }): void
	{
		this.sendDataToIframe(event, 0);
	}

	/**
	 * On initialization event.
	 * Displays to the developer what is allowed in a FieldType Component
	 * above and beyond normal component interactions.
	 *
	 * @memberof CustomIframeComponent
	 */
	public ngOnInit(): void
	{
		this.setIframeHeight();
		this.lowerCaseIframeSize();
		this.setIframeSource();
	}

	/**
	 * A function that expands to full screen the iframe section.
	 *
	 * @memberof CustomIframeComponent
	 */
	public fullScreen(): void
	{
		this.isFullScreen = true;
	}

	/**
	 * Locates the iframe HTML element within the Host Application
	 *
	 * @memberof CustomIframeComponent
	 */
	public iframeLocator(): void
	{
		this.iframes = document.getElementsByTagName('iframe');
	}

	/**
	 * A Function that parses to lower case the size string that comes
	 * from the dababase template options.
	 *
	 * @memberof CustomIframeComponent
	 */
	public lowerCaseIframeSize(): void
	{
		if (!AnyHelper.isNullOrEmpty(this.to.size))
		{
			this.iframeSize = this.to.size.toLowerCase();
		}
	}

	/**
	 * Sends data to the iframe contained within the application
	 *
	 * @param {any} event
	 * The event passed from the Host Application
	 * @param {number} iframeIndex
	 * The HTML object index number of the desired iframe to send the data
	 * @memberof CustomIframeComponent
	 */
	public sendDataToIframe(
		event: any,
		iframeIndex: number): void
	{
		this.iframeLocator();
		this.iframes[iframeIndex].contentWindow
			.postMessage(this.hostMessage, event.origin);

		EventHelper
			.dispatchBannerEvent(
				'Completed',
				'Data sent to iframe ' + JSON.stringify(this.hostMessage),
				AppConstants.activityStatus.info);
	}

	/**
	 * A Function that sets the size of the iframe to be displayed within
	 * a layout based on the database template option.
	 *
	 * @memberof CustomIframeComponent
	 */
	public setIframeHeight(): void
	{
		const defaultHeightViewPort = 33;
		const heightViewPort: number =
			!AnyHelper.isNullOrEmpty(this.to.customHeight)
				&& this.to.useCustomHeight
				? this.to.customHeight
				: defaultHeightViewPort;

		this.iframeHeight = 'calc(' + heightViewPort + 'vh)';
	}

	/**
	 * A function that dinamically sets the source attribute
	 * for every existing iframe based on the database.
	 *
	 * @memberof CustomIframeComponent
	 */
	public setIframeSource(): void
	{
		this.iframeTargetUrl =
			this.sanitizer.bypassSecurityTrustResourceUrl(
				this.to.source);
	}
}