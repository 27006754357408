/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	Component,
	OnInit
} from '@angular/core';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	JsonSchemaLayout
} from '@shared/implementations/application-data/json-schema-layout';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

@Component({
	selector: 'admin-base-expand',
	templateUrl: './base-expand.component.html',
	styleUrls: ['./base-expand.component.scss']
})

/**
 * A component representing an instance of the session component.
 *
 * @export
 * @class BaseExpandComponent
 * @implements {IDynamicComponent<CommonTableComponent, any>}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
export class BaseExpandComponent
implements IDynamicComponent<CommonTableComponent, any>, OnInit
{
	/**
	 * Initializes a new instance of the BaseExpandComponent class.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The api service used to load the side layout data.
	 * @memberof SessionComponent
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService)
	{
	}

	/**
	 * Gets or sets the context that will be set when implementing this
	 * as a dynamic component.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof BaseExpandComponent
	 */
	public context: IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the layout schema array
	 * @type {object}
	 * @memberof BaseExpandComponent
	 */
	public layoutSchema: FormlyFieldConfig[];

	/**
	 * Gets or sets the loading base expand flag.
	 * @type {boolean}
	 * @memberof BaseExpandComponent
	 */
	public loadingBaseExpand: boolean = true;

	/**
	 * Gets or sets the default layout schema object
	 * @type {object}
	 * @memberof BaseExpandComponent
	 */
	private readonly defaultLayout: object =
	[
		{
			type: FormlyConstants.customControls.customMessage,
			templateOptions: {
				message: 'Please define a layout or definition for this view.'
			}
		}
	];

	/**
	 * Implements the on initialization interface.
	 * This will lset the layout schema definition
	 *
	 * @async
	 * @memberof BaseExpandComponent
	*/
	public async ngOnInit(): Promise<void>
	{
		const layoutDefinition: object[] =
			!AnyHelper.isNullOrEmpty(
				this.context.source.formlyDefinitions.definition)
				? JSON.parse(new JsonSchemaLayout()
					.generateDefaultLayout(
						this.context.source.formlyDefinitions.definition,
						this.context.source.formlyDefinitions.viewOnly))
				: this.defaultLayout;

		const layoutSchema: object[] =
			!AnyHelper.isNullOrEmpty(
				this.context.source.formlyDefinitions.layout)
				? this.context.source.formlyDefinitions.layout
				: layoutDefinition;

		this.layoutSchema = (this.context.source.displayDelete === true)
			? [
				{
					type: FormlyConstants.customControls.customMessage,
					templateOptions: {
						message: `${await this.context.source.tableDefinitions
							.actions.delete
							.deleteStatement()}`
					}
				}
			]
			: layoutSchema;
		this.loadingBaseExpand = false;
		this.context.source.finishedLoading.emit(true);
	}
}