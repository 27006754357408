/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	BusinessLogicEntity
} from '@shared/business-logic-entities/business-logic-entity';
import {
	EmbeddedFileEncoding
} from '@shared/constants/enums/embedded-file-encoding.enum';
import {
	FileState
} from '@shared/constants/enums/file-state.enum';
import {
	IFileEntity
} from '@shared/interfaces/files/file-entity.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	StorageType
} from '@shared/constants/enums/storage-type.enum';

/**
 * A file business logic entity.
 *
 * @export
 * @class FileEntity
 * @extends {BusinessLogicEntity}
 * @implements {IFileEntity}
 */
export class FileEntity
	extends BusinessLogicEntity
	implements IFileEntity
{
	/**
	 * Creates an instance of a File Entity.
	 *
	 * @param {IFileEntity} fileEntity
	 * The underlying IFileEntity.
	 * @param {ResolverService} resolverService
	 * The resolver service for this class.
	 * @memberof FileEntity
	 */
	public constructor(
		fileEntity: IFileEntity,
		resolverService: ResolverService)
	{
		super(
			fileEntity,
			resolverService);
	}

	/**
	 * The FileEntity data object.
	 *
	 * @type {object}
	 * @memberof FileEntity
	 */
	public data: {
		name: string;
		description: string;
		subType: string;
		tags: string[];
		status:
			{
				state: FileState;
				stateChangeReason: string;
			};
		storage:
			{
				storageType: StorageType;
				location: string;
				value: string;
				encoding: EmbeddedFileEncoding;
				persistenceService: string;
			};
		metadata:
			{
				sizeInBytes: number;
				mimeType: string;
				extension: string;
				custom: any;
			};
	};

	/**
	 * Adds or updates properties to the metadata custom object.
	 *
	 * @returns {FileEntity}
	 * An awaitable insurance entity type object used for lookups.
	 * @memberof FileEntity
	 */
	public addOrUpdateCustomProperties(
		properties: object): FileEntity
	{
		if (AnyHelper.isNull(this.data.metadata.custom))
		{
			this.data.metadata.custom = <any>{};
		}

		this.addOrUpdateProperties(
			this.data.metadata.custom,
			properties);

		return this;
	}

}