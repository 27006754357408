<app-base-page pageTitle="No Access"
	utilityMenuOperationGroupName="">

	<div class="ui-g-12 page-content"
		*ngIf="loading === false"
		[@contentAnimation]="state">

		<div class="box-shadow card-content text-center {{siteLayoutService.contentCssClass}}">

			<div class="back-navigate-button">
				<span class="text-link theme-color nested-display-back-container"
					(click)="backNavigate()">
					<i class="fa fa-arrow-left nested-display-back-icon">
					</i>
					<span class="nested-display-back">
						Back
					</span>
				</span>
			</div>

			<app-access-denied-content
				[sessionIdentifier]="sessionIdentifier"
				[accessDeniedUrl]="accessDeniedUrl"
				[resources]="resources"
				[clientMessage]="clientMessage">
			</app-access-denied-content>

		</div>

	</div>

</app-base-page>