/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Component
} from '@angular/core';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';

/* eslint-enable max-len */

@Component({
	selector: 'app-dynamic-really-simple-syndication-feed-reader',
	templateUrl:
		'./dynamic-really-simple-syndication-feed-reader.component.html'
})

/**
 * A component representing a wrapper to pass context to a
 * dynamic really simple syndication feed reader component.
 *
 * @export
 * @class DynamicReallySimpleSyndicationFeedReaderComponent
 * @implements {IDynamicComponent<Component, any}
 */
export class DynamicReallySimpleSyndicationFeedReaderComponent
implements IDynamicComponent<Component, any>
{
	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any}
	 * @memberof DynamicReallySimpleSyndicationFeedReaderComponent
	 */
	public context:
		IDynamicComponentContext<Component, any>;
}
