<div class="custom-checkbox-container">
	<label class="visually-hidden"
		[attr.for]="'AriaInput' + id">
		{{to.checkboxText || to.label}}
	</label>
	<p-checkbox
		binary="true"
		[label]="to.checkboxText || to.label"
		[formControl]="formControl"
		[formlyAttributes]="field"
		[inputId]="'AriaInput' + id"
		(onChange)="to.change && to.change(field, $event)">
	</p-checkbox>
</div>

<field-validation-message
	[field]="field">
</field-validation-message>