/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ActionDefinitionExpandComponent
} from '@admin/components/workflow-engine/action-definitions/action-definitions/action-definition-expand/action-definition-expand.component';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	Component
} from '@angular/core';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EntityVersionApiService
} from '@api/services/entities/entity-version.api.service';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IWorkflowActionDefinitions
} from '@shared/interfaces/workflow/workflow-action-definitions.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	TableHelper
} from '@shared/helpers/table.helper';
import {
	WorkflowActionDefinitionsApiService
} from '@api/services/workflow/workflow-action-definitions.api.service';
import {
	WorkflowActionRequisitesApiService
} from '@api/services/workflow/workflow-action-requisites.api.service';
import {
	WorkflowFailureActionsApiService
} from '@api/services/workflow/workflow-failure-actions.api.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-action-definitions',
	templateUrl: './action-definitions.component.html',
	styleUrls: ['./action-definitions.component.scss']
})

/**
 * A component representing an instance of the workflow engine action
 * definitions.
 *
 * @export
 * @class ActionDefinitionsComponent
 * @extends {CommonTablePageDirective}
 */
export class ActionDefinitionsComponent
	extends CommonTablePageDirective
{
	/**
	 * Initializes a new instance of the ActionDefinitionComponent class.
	 *
	 * @param {WorkflowActionDefinitionsApiService}
	 *	workflowActionDefinitionsApiService
	 * The api service used to gather action definition data.
	 * @param {WorkflowActionRequisitesApiService}
	 * workflowActionRequisitesApiService
	 * The api service used to gather action requisite data.
	 * @param {WorkflowFailureActionsApiService}
	 * workflowFailureActionsApiService
	 * The api service used to gather action failure data.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to gather entity type data.
	 * @param {ActivityService} activityService
	 * The Activity Service.
	 * @memberof ActionDefinitionsComponent
	 */
	public constructor(
		public workflowActionDefinitionsApiService:
			WorkflowActionDefinitionsApiService,
		public workflowActionRequisitesApiService:
			WorkflowActionRequisitesApiService,
		public workflowFailureActionsApiService:
			WorkflowFailureActionsApiService,
		public entityTypeApiService: EntityTypeApiService,
		public entityVersionApiService: EntityVersionApiService,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the display management context.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof ActionDefinitionsComponent
	 */
	public actionDefinitionsContext: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the table definitions for the standard table view.
	 *
	 * @type {object}
	 * @memberof ActionDefinitionsComponent
	 */
	public tableDefinitions: ICommonTable;

	/**
	 * Gets or sets the table filter.
	 *
	 * @type {string}
	 * @memberof ActionDefinitionsComponent
	 */
	public tableFilter: string = AppConstants.empty;

	/**
	 * Sets up variables used in this admin page based table.
	 *
	 * @memberof ActionDefinitionsComponent
	 */
	public setupPageVariables(): void
	{
		let displayOrder: number = 1;
		this.availableColumns =
			[
				{
					dataKey: 'id',
					columnHeader: 'Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'entityTypeId',
					columnHeader: 'Entity Type',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'entityVersionId',
					columnHeader: 'Entity Version',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'description',
					columnHeader: 'Description',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'classReference',
					columnHeader: 'Class Reference',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'failureActionId',
					columnHeader: 'Failure Action ',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'createDate',
					dataFormatType: AppConstants.dataFormatTypes.dateTime,
					columnHeader: 'Create Date',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'startDate',
					dataFormatType: AppConstants.dataFormatTypes.dateTime,
					columnHeader: 'Start Date',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'endDate',
					dataFormatType: AppConstants.dataFormatTypes.dateTime,
					columnHeader: 'End Date',
					displayOrder: displayOrder
				}
			];
		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Sets up the table definitions for the standard table
	 *
	 * @async
	 * @memberof ActionDefinitionsComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		this.actionDefinitionsContext =
			<IDynamicComponentContext<Component, any>>
			{
				source: this
			};

		const entityTypeFilterOptions = (await this.entityTypeApiService
			.query(
				AppConstants.empty,
				AppConstants.empty))
			.map((type: IEntityType) =>
				({
					label: type.name,
					value: `EntityTypeId eq ${type.id}`
				}));

		entityTypeFilterOptions.unshift(
			{
				label: 'All Entity Types',
				value: AppConstants.empty
			});

		this.tableDefinitions = {
			actions: {
				create: {
					customContext: this.actionDefinitionsContext,
					component: ActionDefinitionExpandComponent,
					items:
					[
						{
							label: 'Create',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: async() =>
								this.createActionDefinition()
						}
					]
				},
				update: {
					customContext: this.actionDefinitionsContext,
					component: ActionDefinitionExpandComponent,
					items:
					[
						{
							label: 'Save',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: () => this.updateActionDefinition()
						}
					]
				},
				delete: {
					items: [
						{
							label: 'Confirm',
							styleClass: AppConstants.cssClasses.pButtonDanger,
							disabled: false,
							command: () => this.deleteActionDefinition()
						}],
					deleteStatement: () => this.getDeleteStatement(),
				},
				view: {
					customContext: this.actionDefinitionsContext,
					component: ActionDefinitionExpandComponent,
					items: []
				},
				filter: {
					quickFilters: entityTypeFilterOptions,
					selectedFilterValue: this.tableFilter
				}
			},
			tableTitle: 'Definitions',
			objectSearch: {
				filter: this.tableFilter,
				orderBy: `Id ${AppConstants.sortDirections.descending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: this.tableRowCount
			},
			apiPromise: async (objectSearch: IObjectSearch) =>
				this.workflowActionDefinitionsApiService
					.query(
						objectSearch.filter,
						objectSearch.orderBy,
						objectSearch.offset,
						objectSearch.limit),
			availableColumns: this.availableColumns,
			selectedColumns: this.selectedColumns,
			columnSelectionMode: this.columnSelectionMode,
			expandTitle: () =>
				TableHelper.getExpandTitle(
					this.commonTableContext,
					'Workflow Action Definition'),
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			rowCountChanged: (rowCount: number) =>
			{
				this.tableRowCount = rowCount;
				this.restoreTableDefinition();
			},
			filterCriteriaChanged: (event: string) =>
			{
				this.tableFilter = event;
				this.restoreTableDefinition();
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.tableDefinitions.selectedColumns =
					selectedColumns;
				this.selectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.tableDefinitions.columnSelectionMode =
					columnSelectionMode;
				this.columnSelectionMode = columnSelectionMode;
			}
		};
		this.loadingTableDefinitions = false;
	}

	/**
	 * Creates an action definition.
	 *
	 * @async
	 * @memberof ActionDefinitionsComponent
	 */
	private async createActionDefinition(): Promise<void>
	{
		const workflowActionDefinition: IWorkflowActionDefinitions =
			<IWorkflowActionDefinitions>
			{
				entityTypeId:
					this.commonTableContext.source.rowData.entityTypeId,
				entityVersionId:
					this.commonTableContext.source.rowData.entityVersionId,
				name:
					this.commonTableContext.source.rowData.name,
				description:
					this.commonTableContext.source.rowData.description,
				classReference:
					this.commonTableContext.source.rowData.classReference,
				classDefinition:
					this.commonTableContext.source.rowData.classDefinition,
				failureActionId:
					this.commonTableContext.source.rowData.failureActionId
			};

		await this.activityService.handleActivity(
			new Activity(
				this.workflowActionDefinitionsApiService
					.create(workflowActionDefinition),
				'<strong>Creating Action Definition</strong>',
				'<strong>Created Action Definition</strong>',
				`Action Definition
					${this.commonTableContext.source.rowData.name}
					was successfully created.`,
				`Action Definition
					${this.commonTableContext.source.rowData.name}
					was not created.`));

		this.restoreTableDefinition();
	}

	/**
	 * Updates an action definition.
	 *
	 * @async
	 * @memberof ActionDefinitionsComponent
	 */
	private async updateActionDefinition(): Promise<void>
	{
		const workflowActionDefinition: IWorkflowActionDefinitions =
			<IWorkflowActionDefinitions>
			{
				id: this.commonTableContext.source.rowData.id,
				entityTypeId:
					this.commonTableContext.source.rowData.entityTypeId,
				entityVersionId:
					this.commonTableContext.source.rowData.entityVersionId,
				name:
					this.commonTableContext.source.rowData.name,
				description:
					this.commonTableContext.source.rowData.description,
				classReference:
					this.commonTableContext.source.rowData.classReference,
				classDefinition:
					this.commonTableContext.source.rowData.classDefinition,
				failureActionId:
					this.commonTableContext.source.rowData.failureActionId
			};

		await this.activityService.handleActivity(
			new Activity(
				this.workflowActionDefinitionsApiService
					.update(
						this.commonTableContext.source.rowData.id,
						workflowActionDefinition),
				'<strong>Updating Action Definition</strong>',
				'<strong>Updated Action Definition</strong>',
				`Action Definition
					${this.commonTableContext.source.rowData.name}
					was successfully updated.`,
				`Action Definition
					${this.commonTableContext.source.rowData.name}
					was not updated.`),
			AppConstants.activityStatus.complete,
			true);
	}

	/**
	 * Deletes an action definition.
	 *
	 * @async
	 * @memberof ActionDefinitionsComponent
	 */
	private async deleteActionDefinition(): Promise<void>
	{
		await this.activityService.handleActivity(
			new Activity(
				this.workflowActionDefinitionsApiService
					.delete(
						this.commonTableContext.source.rowData.id),
				'<strong>Deleting Action Definition</strong>',
				'<strong>Deleted Action Definition</strong>',
				`Action Definition
					${this.commonTableContext.source.rowData.name}
					was successfully deleted.`,
				`Action Definition
					${this.commonTableContext.source.rowData.name}
					was not deleted.`));

		this.restoreTableDefinition();
	}

	/**
	 * Gets the delete statement.
	 *
	 * @async
	 * @return {Promise<string>}
	 * The delete statement string.
	 * @memberof ActionDefinitionsComponent
	 */
	private async getDeleteStatement(): Promise<string>
	{
		const existingRequisites =
			await this.workflowActionRequisitesApiService
				.query(
					'ActionDefinitionId eq '
						+ `${this.commonTableContext.source.rowData.id} `
						+ 'Or RequisiteActionDefinitionId eq '
						+ `${this.commonTableContext.source.rowData.id}`,
					AppConstants.empty);

		if (existingRequisites.length > 0)
		{
			this.commonTableContext.source.tableDefinitions.actions
				.delete.items[1].disabled = true;

			return `Unable to delete Worflow Action Definition
				${this.commonTableContext.source.rowData.id}
				${this.commonTableContext.source.rowData.name}
				due to existing requisite relationship
				action definition id
				${existingRequisites[0].actionDefinitionId}
				and requisite action definition id
				${existingRequisites[0].requisiteActionDefinitionId}.`;
		}
		else
		{
			this.commonTableContext.source.tableDefinitions.actions
				.delete.items[1].disabled = false;

			return `Confirm you are about to delete Workflow Action Definition
				${this.commonTableContext.source.rowData.id}
				${this.commonTableContext.source.rowData.name}.`;
		}
	}
}