/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ChartConstants
} from '@shared/constants/chart-constants';
import {
	ChartFactory
} from '@shared/factories/chart-factory';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	Component
} from '@angular/core';
import {
	DateTime
} from 'luxon';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	FormControl
} from '@angular/forms';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IAggregate
} from '@shared/interfaces/application-objects/aggregate.interface';
import {
	IChartDefinition
} from '@shared/interfaces/application-objects/chart-definition.interface';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityVersion
} from '@shared/interfaces/entities/entity-version.interface';
import {
	IInformationMenuItem
} from '@shared/interfaces/application-objects/information-menu-item.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IWorkflowActionDefinitions
} from '@shared/interfaces/workflow/workflow-action-definitions.interface';
import {
	IWorkflowActionInstances
} from '@shared/interfaces/workflow/workflow-action-instances.interface';
import {
	IWorkflowActionRequisites
} from '@shared/interfaces/workflow/workflow-action-requisites.interface';
import {
	IWorkflowRequisiteTypes
} from '@shared/interfaces/workflow/workflow-requisite-types.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	WorkflowActionInstancesApiService
} from '@api/services/workflow/workflow-action-instances.api.service';
import {
	WorkflowRequisiteTypesApiService
} from '@api/services/workflow/workflow-requisite-types.api.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-action-definition-expand',
	templateUrl: './action-definition-expand.component.html',
	styleUrls: [
		'./action-definition-expand.component.scss'
	]
})

/**
 * A component representing an instance of an action definition expand.
 *
 * @export
 * @class ActionDefinitionExpandComponent
 * @implements {CommonTablePageDirective}
 * @implements {IDynamicComponent<any, any>}
 */
export class ActionDefinitionExpandComponent
	extends CommonTablePageDirective
	implements IDynamicComponent<any, any>
{
	/**
	 * Initializes a new instance of the ActionDefinitionExpandComponent class.
	 *
	 * @param {WorkflowRequisiteTypesApiService} workflowRequisiteTypeApiService
	 * The api service used to gather workflow requisite type data.
	 * @param {WorkflowActionDefinitionsApiService}
	 * workflowActionDefinitionApiService
	 * The api service used to gather workflow action definition data.
	 * @param {WorkflowActionInstancesApiService}
	 * workflowActionInstanceApiService
	 * The api service used to gather workflow action instance data.
	 * @param {ActivityService} activityService
	 * The activity service.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service.
	 * @param {ResolverService} resolver
	 * The resolver service used for display component providers.
	 * @param {ChartFactory} chartFactory
	 * The chart factory service.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof ActionDefinitionExpandComponent
	 */
	public constructor(
		public workflowRequisiteTypeApiService:
			WorkflowRequisiteTypesApiService,
		public workflowActionInstanceApiService:
			WorkflowActionInstancesApiService,
		public activityService: ActivityService,
		public siteLayoutService: SiteLayoutService,
		public chartFactory: ChartFactory,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the context that will be set when implementing this
	 * as a dynamic component.
	 *
	 * @type {IDynamicComponentContext<any, any>}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public context: IDynamicComponentContext<any, any>;

	/**
	 * Gets or sets the formly layout definition.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public formlyLayoutDefinition: FormlyFieldConfig[];

	/**
	 * Gets or sets the loading formly definitions.
	 *
	 * @type {boolean}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public loadingFormlyDefinitions: boolean = true;

	/**
	 * Gets or sets the PreRequisite table definitions.
	 *
	 * @type {ICommonTable}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public preRequisiteTableDefinitions: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public preRequisiteAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public preRequisiteSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the column selection mode.
	 *
	 * @type {boolean}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public preRequisiteColumnSelectionMode?: boolean = false;

	/**
	 * Gets or sets the PostRequisite table definitions.
	 *
	 * @type {ICommonTable}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public postRequisiteTableDefinitions: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public postRequisiteAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public postRequisiteSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the column selection mode.
	 *
	 * @type {boolean}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public postRequisiteColumnSelectionMode?: boolean = false;

	/**
	 * Gets or sets the loading instance table definitions.
	 *
	 * @type {boolean}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public loadingInstanceTableDefinitions: boolean = true;

	/**
	 * Gets or sets the PostRequisite table definitions.
	 *
	 * @type {ICommonTable}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public actionInstanceTableDefinitions: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public actionInstanceAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public actionInstanceSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the column selection mode.
	 *
	 * @type {boolean}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public actionInstanceColumnSelectionMode?: boolean = false;

	/**
	 * Gets or sets the workflow requisite types.
	 *
	 * @type {IWorkflowRequisiteTypes[]}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public requisiteTypes: IWorkflowRequisiteTypes[];

	/**
	 * Gets or sets the action definition expand context.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public actionExpandDefinitionsContext:
		IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the context menu items.
	 *
	 * @type {object}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public summaryCardItems: object;

	/**
	 * Gets or sets the context menu items.
	 *
	 * @type {object}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public chartItem: object;

	/**
	 * Gets or sets the local storage filter used
	 * to hide or show context menu content.
	 *
	 * @type {string}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public filter: string;

	/**
	 * Gets or sets if display the requisites tables.
	 *
	 * @type {boolean}
	 * @memberof ActionDefinitionExpandComponent
	 */
	public displayRequisiteTables: boolean = true;

	/**
	 * Sets the event by method chart definition.
	 *
	 * @type {{
		chartColors: string[],
		chartLabels: string[],
		chartPivotProperty: string}}
	 * @memberof ActionDefinitionExpandComponent
	 */
	private readonly eventByMethodChartDefinition: {
		chartColors: string[];
		chartLabels: string[];
		chartPivotProperty: string;
	} =
	{
		chartColors:
		[
			ChartConstants.themeColors.green,
			ChartConstants.themeColors.red,
			ChartConstants.themeColors.yellow
		],
		chartLabels:
		[
			'Complete',
			'Failed',
			'Pending'
		],
		chartPivotProperty: 'Name'
	};

	/**
	 * Gets the requisite table redraw debounce time.
	 *
	 * @type {number}
	 * @memberof ActionDefinitionExpandComponent
	 */
	private readonly requisiteTableRedrawDebounceTime: number = 250;

	/**
	 * Sets the instance by state string literal.
	 *
	 * @type {string}
	 * @memberof ActionDefinitionExpandComponent
	 */
	private readonly instancesByState: string = 'Instances by State';

	/**
	 * Sets up the page variables.
	 *
	 * @memberof ActionDefinitionExpandComponent
	 */
	public async setupPageVariables(): Promise<void>
	{
		await this.setFormlyLayoutDefinition();

		this.actionExpandDefinitionsContext =
			<IDynamicComponentContext<Component, any>>
			{
				source: this
			};

		this.requisiteTypes =
			await this.workflowRequisiteTypeApiService
				.query(
					AppConstants.empty,
					AppConstants.empty);
		this.filter = `actionDefinitionId eq ${this.context.data.data.id}`;
		this.setActionInstanceTableDefinitions();
		this.setupContextMenuItems();
		if (!AnyHelper.isNullOrEmpty(this.context.data.data.entityTypeId))
		{
			await this.setPreRequisiteTableDefinitions();
			this.loadingTableDefinitions = true;
			setTimeout(async() =>
			{
				await this.setPostRequisiteTableDefinitions();
			});
		}

		let displayOrder: number = 1;
		this.actionInstanceAvailableColumns =
			[
				{
					dataKey: 'entityInstanceId',
					columnHeader: 'Entity Instance Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'userId',
					columnHeader: 'User Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'responseTime',
					columnHeader: 'Response Time',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'state',
					columnHeader: 'State',
					displayOrder: displayOrder
				}
			];
		this.actionInstanceSelectedColumns =
			this.actionInstanceAvailableColumns;

		displayOrder = 1;
		this.preRequisiteAvailableColumns =
			[
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'order',
					columnHeader: 'Order',
					displayOrder: displayOrder
				}
			];
		this.preRequisiteSelectedColumns =
			this.preRequisiteAvailableColumns;

		this.postRequisiteAvailableColumns =
			[
				...this.preRequisiteAvailableColumns
			];
		this.postRequisiteSelectedColumns =
			this.postRequisiteAvailableColumns;
	}

	/**
	 * Sets up chart context menu items on this component.
	 * This will setup the data and summary cards.
	 *
	 * @memberof ActionDefinitionExpandComponent
	 */
	public async setupContextMenuItems(): Promise<void>
	{
		this.summaryCardItems =
			[
				<IInformationMenuItem<any>>
				{
					dataPromise: new Promise(async (resolve) =>
					{
						const actionInstances =
							await this.workflowActionInstanceApiService
								.query(
									'ActionDefinitionId eq '
										+ this.context.data.data.id,
									AppConstants.empty);

						let averageResponse: number = 0;

						actionInstances.forEach((actionInstance) =>
						{
							averageResponse +=
								DateTime.fromISO(
									actionInstance.changeDate)
									.diff(
										DateTime.fromISO(
											actionInstance.createDate))
									.milliseconds;
						});

						const totalAverageTime: number =
							averageResponse / actionInstances.length;

						const responseTime =
							this.getAverageResponseTime(totalAverageTime);

						resolve(
							{
								responseTime: `${responseTime}`
							}
						);
					}),
					summaryTemplate: '${data.responseTime}',
					titleTemplate: 'Avg Response Time',
					width: AppConstants.sizeIdentifiers.large,
					summaryCardDisplay: true,
					squareCardDisplay: false
				},
				<IInformationMenuItem<any>>
				{
					dataPromise: new Promise(async (resolve) =>
					{
						const actionInstances =
							await this.workflowActionInstanceApiService
								.aggregate(
									'Count',
									null,
									'ActionDefinitionId eq '
										+ this.context.data.data.id);

						resolve(
							{
								actionInstances: `${actionInstances[0].value}`
							}
						);
					}),
					summaryTemplate: '${data.actionInstances}',
					titleTemplate: 'Instances Count',
					width: AppConstants.sizeIdentifiers.large,
					summaryCardDisplay: true,
					squareCardDisplay: false
				}
			];

		const dataId: string = this.context.data.data.id;
		this.chartItem =
			[
				<IInformationMenuItem<IAggregate[]>>
				{
					chartDefinition: <IChartDefinition<IAggregate[]>>{
						dataPromise: new Promise(async (resolve) => {
							const completeStateCount =
								await this.workflowActionInstanceApiService
									.aggregate(
										'Count',
										AppConstants.empty,
										`ActionDefinitionId eq ${dataId}`
											+ ` And StateId eq ${1}`);

							const failedStateCount =
								await this.workflowActionInstanceApiService
									.aggregate(
										'Count',
										AppConstants.empty,
										`ActionDefinitionId eq ${dataId}`
										+ ` And StateId eq ${2}`);

							const pendingStateCount =
								await this.workflowActionInstanceApiService
									.aggregate(
										'Count',
										AppConstants.empty,
										`ActionDefinitionId eq ${dataId}`
										+ ` And StateId eq ${3}`);

							resolve(<IAggregate[]>
							[
								{
									key:
									{
										name: 'complete'
									},
									value: completeStateCount[0].value
								},
								{
									key:
									{
										name: 'failed'
									},
									value: failedStateCount[0].value
								},
								{
									key:
									{
										name: 'pending'
									},
									value: pendingStateCount[0].value
								}
							]);
						}),
						chartColors:
							this.eventByMethodChartDefinition.chartColors,
						chartConfiguration: this.chartFactory.doughnutChart(
							this.instancesByState,
							this.eventByMethodChartDefinition.chartLabels,
							[],
							this.instancesByState),
						chartPivotProperty:
							this.eventByMethodChartDefinition.chartPivotProperty
					},
					titleTemplate: this.instancesByState,
					width: AppConstants.sizeIdentifiers.large,
					summaryCardDisplay: false,
					squareCardDisplay: true
				}
			];
	}

	/**
	 * Sets up the formly layout definitions.
	 *
	 * @param {number} averageTime
	 * The average time in miliseconds.
	 * @returns {string}
	 * The formated average time calculation to be displayed
	 * within a summary card.
	 * @memberof ActionDefinitionExpandComponent
	 */
	public getAverageResponseTime(
		averageTime: number): string
	{
		const dateTimeFromEpoch: DateTime =
			DateTime.fromMillis(averageTime);

		return !dateTimeFromEpoch.isValid
			? AppConstants.timeSpan.zeroMinutes
			: `${dateTimeFromEpoch.minute}:`
				+ `${dateTimeFromEpoch.second}.`
				+ `${dateTimeFromEpoch.millisecond}`;
	}

	/**
	 * Sets up the formly layout definitions.
	 *
	 * @memberof ActionDefinitionExpandComponent
	 */
	public async setFormlyLayoutDefinition(): Promise<void>
	{
		const hideRequiredMarker: boolean = this.context.source.displayView;
		const entityTypeDropdownOptions: IDropdownOption[] =
				this.getDropdownOptions(
					await this.context?.source?.customContext?.source
						?.entityTypeApiService
						.query(
							AppConstants.empty,
							AppConstants.empty,
							null,
							100),
					AppConstants.commonProperties.name,
					AppConstants.commonProperties.id);

		const failureActionDropdownOptions: IDropdownOption[] =
				this.getDropdownOptions(
					await this.context?.source?.customContext?.source
						?.workflowFailureActionsApiService
						.query(
							AppConstants.empty,
							AppConstants.empty,
							null,
							100),
					AppConstants.commonProperties.name,
					AppConstants.commonProperties.id);

		this.formlyLayoutDefinition = [
			{
				key: 'data.entityTypeId',
				type: FormlyConstants.customControls.customSelect,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					label: 'Entity Type',
					showClear: true,
					required: true,
					hideRequiredMarker: hideRequiredMarker,
					placeholder: AppConstants.placeholders.selectAnOption,
					options: entityTypeDropdownOptions,
					change: async (field: FormlyFieldConfig) =>
					{
						if (this.context.data.data.entityTypeId
								=== field.formControl.value)
						{
							return;
						}

						this.context.data.data.entityTypeId =
							AnyHelper.isNullOrEmpty(field.formControl.value)
								? undefined
								: field.formControl.value;

						let entityVersions: IEntityVersion[] = [];

						if (!AnyHelper.isNullOrEmpty(field.formControl.value))
						{
							entityVersions =
								await this.context.source
									.customContext.source
									.entityVersionApiService.query(
										`TypeId eq ${field.formControl.value}`);
							await this.setPreRequisiteTableDefinitions();
							await this.setPostRequisiteTableDefinitions();
						}

						this.context.data.data.entityVersionId =
							entityVersions.length === 1
								? entityVersions[0].number
								: undefined;

						setTimeout(async() =>
						{
							await this.setFormlyLayoutDefinition();
							this.displayRequisiteTables =
									!AnyHelper.isNullOrEmpty(
										this.context.data.data.entityTypeId);
						});
					}
				}
			},
			{
				key: 'data.entityVersionId',
				name: 'test',
				type: FormlyConstants.customControls.customSelect,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					showClear: true,
					required: true,
					hideRequiredMarker: hideRequiredMarker,
					placeholder: AppConstants.placeholders.selectAnOption,
					label: 'Entity Version',
					options: ((await this.context?.source?.customContext?.source
						?.entityVersionApiService
						.query(
							'typeId eq '
								+ `${this.context.data.data.entityTypeId}`,
							AppConstants.empty))
						?.map((item: any) =>
							<IDropdownOption>
							{
								label: item.number,
								value: item.id
							}))
				}
			},
			{
				key: 'data.name',
				type: FormlyConstants.customControls.input,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					label: 'Name',
					required: true,
					hideRequiredMarker: hideRequiredMarker
				},
				asyncValidators: {
					uniqueCombination: {
						expression: (control: FormControl) =>
							this.uniqueCombination(control),
						message: 'Existing Type, Version and Name combination.'
					}
				}
			},
			{
				key: 'data.description',
				type: FormlyConstants.customControls.input,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					label: 'Description'
				}
			},
			{
				key: 'data.failureActionId',
				type: FormlyConstants.customControls.customSelect,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					showClear: true,
					placeholder: AppConstants.placeholders.selectAnOption,
					label: 'Failure Action',
					required: true,
					hideRequiredMarker: hideRequiredMarker,
					options: failureActionDropdownOptions
				}
			},
			{
				key: 'data.classReference',
				type: FormlyConstants.customControls.input,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					label: 'Class Reference',
					required: true,
					hideRequiredMarker: hideRequiredMarker
				}
			},
			{
				key: 'data.classDefinition',
				type: FormlyConstants.customControls.customTextArea,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					label: 'Class Definition',
					rows: FormlyConstants.textAreaRowSizes.standard,
					required: true
				},
				validators: {
					validCSharpFormat: {
						expression: (
							control: FormControl) =>
							this.isValidCSharpFormat(control.value),
						message: 'Not a valid C# format.'
					}
				},
			}
		];

		if (this.context.source.displayView === true)
		{
			this.formlyLayoutDefinition.forEach((definition) =>
			{
				definition.templateOptions.disabled = true;
			});

			this.formlyLayoutDefinition
				.splice(
					6,
					1,
					{
						type: FormlyConstants.customControls.customTextDisplay,
						wrappers: [
							FormlyConstants.customControls.customFieldWrapper
						],
						templateOptions: {
							label: 'Class Definition',
							useCodeBlock: true,
							usePanelDisplay: false,
							codeBlockType:
								AppConstants.markdownLanguages.csharp,
							content: AppConstants.empty
						},
						expressionProperties: {
							'templateOptions.content':
								'`${model.data.classDefinition == null '
									+ '? "No Data" : '
									+ 'model.data.classDefinition}`'
						}
					});
		}

		if (this.context.source.displayCreate === true)
		{
			this.context.data.data.classDefinition =
				'namespace WaterStreet.Nautix.Data.Workflow.Actions\n'
					+ '{\n'
					+ '\tusing WaterStreet.Nautix.Data.Workflow;\n'
					+ '\tusing WaterStreet.Nautix.Public;\n'
					+ '\tusing WaterStreet.Nautix.Public.Workflow;\n\r'
					+ '\tpublic class YourWorkflowAction\n'
					+ '\t\t: BaseAction\n'
					+ '\t{\n'
					+ '\t\tpublic override ActionResponse Execute()\n'
					+ '\t\t{\n'
					+ '\t\t\treturn Ok(new object());\n'
					+ '\t\t}\n'
					+ '\t}\n'
					+ '}';
		}

		this.loadingFormlyDefinitions = false;
	}

	/**
	 * Sets up the PreRequisite Table Definitions.
	 *
	 * @memberof ActionDefinitionExpandComponent
	 */
	public setActionInstanceTableDefinitions(): void
	{
		this.actionInstanceTableDefinitions = {
			hideSettings: true,
			hideExpanderArrow: true,
			hideSummary: true,
			actions: {
				filter: {
					quickFilters:
						[
							{
								label: 'All',
								value: AppConstants.empty
							},
							{
								label: 'Complete',
								value: 'StateId eq 1'
							},
							{
								label: 'Failed',
								value: 'StateId eq 2'
							},
							{
								label: 'Pending',
								value: 'StateId eq 3'
							}
						],
					selectedFilterValue: this.filter
				},
			},
			tableTitle: 'Instances',
			objectSearch: {
				filter: this.filter,
				orderBy: 'Id desc',
				offset: 0,
				limit: 50,
				virtualIndex: 0,
				virtualPageSize: 5
			},
			tableHeight: {
				virtualPageSizeBased: false,
				explicitHeight: '138'
			},
			apiPromise: async(objectSearch: IObjectSearch) =>
			{
				const actionInstances: IWorkflowActionInstances[] =
						await this.workflowActionInstanceApiService
							.query(
								objectSearch.filter,
								objectSearch.orderBy,
								objectSearch.offset,
								objectSearch.limit);

				const dataPromise = [];

				actionInstances.forEach((instance) =>
				{
					dataPromise.push(
						{
							id: instance.id,
							entityInstanceId: instance.entityInstanceId,
							userId: instance.userId,
							responseTime:
								`${DateTime.fromISO(
									instance.changeDate)
									.diff(
										DateTime.fromISO(
											instance.createDate))
									.milliseconds}ms`,
							state: `${this.getState(instance.stateId)}`
						});
				});

				return dataPromise;
			},
			availableColumns: this.actionInstanceAvailableColumns,
			selectedColumns: this.actionInstanceSelectedColumns,
			columnSelectionMode: this.actionInstanceColumnSelectionMode,
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			filterCriteriaChanged: (event) =>
			{
				this.filter =
					event === AppConstants.empty
						? `actionDefinitionId eq ${this.context.data.data.id}`
						: `actionDefinitionId eq ${this.context.data.data.id}
							And ${event}`;
				this.loadingInstanceTableDefinitions = true;
				setTimeout(() =>
				{
					this.setActionInstanceTableDefinitions();
				});
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.actionInstanceTableDefinitions.selectedColumns =
					selectedColumns;
				this.actionInstanceSelectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.actionInstanceTableDefinitions.columnSelectionMode =
					columnSelectionMode;
				this.actionInstanceColumnSelectionMode = columnSelectionMode;
			}
		};
		this.loadingInstanceTableDefinitions = false;
	}

	/**
	 * Gets the State string.
	 *
	 * @param {number} stateId
	 * @memberof ActionDefinitionExpandComponent
	 */
	public getState(stateId: number): string
	{
		if (stateId === 1)
		{
			return 'Complete';
		}
		else if (stateId === 2)
		{
			return 'Failed';
		}

		return 'Pending';
	}

	/**
	 * Sets up the PreRequisite Table Definitions.
	 *
	 * @memberof ActionDefinitionExpandComponent
	 */
	public async setPreRequisiteTableDefinitions(): Promise<void>
	{
		this.preRequisiteTableDefinitions = {
			hideSettings: true,
			hideExpanderArrow: true,
			tableTitle: 'PreRequisites',
			objectSearch: {
				filter: 'actionDefinitionId eq '
					+ this.context.source.rowData.id
					+ ' and requisiteTypeId eq '
					+ (this.requisiteTypes.filter(requisiteType =>
						requisiteType.name === 'PreRequisite'))[0].id,
				orderBy: `Order ${AppConstants.sortDirections.ascending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: 5
			},
			apiPromise: async (objectSearch: IObjectSearch) =>
			{
				const actionRequisites: IWorkflowActionRequisites[] =
						await this.context.source.customContext.source
							.workflowActionRequisitesApiService
							.query(
								objectSearch.filter,
								objectSearch.orderBy,
								objectSearch.offset,
								objectSearch.limit);

				const preRequisites = [];

				for (const actionRequisite of actionRequisites)
				{
					preRequisites.push(
						{
							id: actionRequisite.id,
							name:
								(await this.context.source.customContext
									?.source.workflowActionDefinitionsApiService
									.get(actionRequisite
										.requisiteActionDefinitionId))?.name,
							requisiteTypeId:
								actionRequisite.requisiteTypeId,
							actionDefinitionId:
								actionRequisite.actionDefinitionId,
							requisiteActionDefinitionId:
								actionRequisite
									.requisiteActionDefinitionId,
							order: actionRequisite.order
						});
				}

				EventHelper.dispatchTableExpansionPanelLoadedEvent();

				return preRequisites;
			},
			availableColumns: this.preRequisiteAvailableColumns,
			selectedColumns: this.preRequisiteSelectedColumns,
			columnSelectionMode: this.preRequisiteColumnSelectionMode,
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.preRequisiteTableDefinitions.selectedColumns =
					selectedColumns;
				this.preRequisiteSelectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.preRequisiteTableDefinitions.columnSelectionMode =
					columnSelectionMode;
				this.preRequisiteColumnSelectionMode = columnSelectionMode;
			}
		};

		if (this.context.source.displayUpdate !== true)
		{
			this.loadingTableDefinitions = false;

			return;
		}

		const preRequisiteDropdownOptions: IDropdownOption[] =
			this.getDropdownOptions(
				await this.context.source
					.customContext.source
					.workflowActionDefinitionsApiService
					.query(
						'EntityTypeId eq '
							+ `${this.context.source
								.rowData.entityTypeId} `
							+ `and Id ne ${this.context.source
								.rowData.id}`,
						AppConstants.empty,
						null,
						100),
				AppConstants.commonProperties.name,
				AppConstants.commonProperties.id);

		this.preRequisiteTableDefinitions.actions =
			{
				create: {
					displayCreateRow: false,
					customContext: this.actionExpandDefinitionsContext,
					layout: [
						{
							key: 'data.requisiteActionDefinitionId',
							type: FormlyConstants.customControls.customSelect,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								required: true,
								showClear: true,
								placeholder:
									AppConstants.placeholders.selectAnOption,
								options: preRequisiteDropdownOptions
							}
						},
						{
							key: 'data.order',
							type: FormlyConstants.customControls
								.customInputNumber,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								required: true,
								type: 'number'
							},
							asyncValidators: {
								uniqueOrder: {
									expression: (control: FormControl) =>
										this.uniqueOrder(
											control,
											'PreRequisite'),
									message: 'Existing Order.'
								}
							}
						}
					],
					items:
				[
					{
						label: 'Save',
						styleClass: AppConstants.cssClasses.pButtonPrimary,
						command: () => this.createRequisite('PreRequisite')
					}
				]
				},
				updateIndex:
			[
				{
					id: 'updateIndexUp',
					visible: (
						rowData: any,
						virtualData: any[]) =>
						rowData.order !== virtualData[0].order,
					command: async(
						commonTableContext: CommonTableComponent) =>
						this.updateOrderIndex(
							commonTableContext,
							-1,
							'PreRequisite')
				},
				{
					id: 'updateIndexDown',
					visible: (
						rowData: any,
						virtualData: any[]) =>
						rowData?.order !==
								virtualData[virtualData.length - 1]?.order,
					command: async(
						commonTableContext: CommonTableComponent) =>
						this.updateOrderIndex(
							commonTableContext,
							1,
							'PreRequisite')
				}
			],
				delete: {
					deleteStatement: () =>
						`Confirm to remove PreRequisite
						${this.commonTableContext.data.name}
						from action definition
						${this.context.data.data.name}.`,
					items: [
						{
							label: 'Remove',
							styleClass: AppConstants.cssClasses.pButtonDanger,
							command: async() =>
								this.deleteRequisite('PreRequisite')
						}
					],
				}
			};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Sets up the PostRequisite Table Definitions.
	 *
	 * @memberof ActionDefinitionExpandComponent
	 */
	public async setPostRequisiteTableDefinitions(): Promise<void>
	{
		this.postRequisiteTableDefinitions = {
			hideSettings: true,
			hideExpanderArrow: true,
			tableTitle: 'PostRequisites',
			objectSearch: {
				filter: 'actionDefinitionId eq '
					+ this.context.source.rowData.id
					+ ' and requisiteTypeId eq '
					+ (this.requisiteTypes.filter(requisiteType =>
						requisiteType.name === 'PostRequisite'))[0].id,
				orderBy: `Order ${AppConstants.sortDirections.ascending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: 5
			},
			apiPromise: async (objectSearch: IObjectSearch) =>
			{
				const actionRequisites: IWorkflowActionRequisites[] =
						await this.context.source.customContext.source
							.workflowActionRequisitesApiService
							.query(
								objectSearch.filter,
								objectSearch.orderBy,
								objectSearch.offset,
								objectSearch.limit);

				const postRequisites = [];

				for (const actionRequisite of actionRequisites)
				{
					postRequisites.push(
						{
							id: actionRequisite.id,
							name:
								(await this.context.source.customContext
									?.source.workflowActionDefinitionsApiService
									.get(actionRequisite
										.requisiteActionDefinitionId))
									?.name,
							requisiteTypeId:
								actionRequisite.requisiteTypeId,
							actionDefinitionId:
								actionRequisite.actionDefinitionId,
							requisiteActionDefinitionId:
								actionRequisite
									.requisiteActionDefinitionId,
							order: actionRequisite.order
						});
				}

				EventHelper.dispatchTableExpansionPanelLoadedEvent();

				return postRequisites;
			},
			availableColumns: this.postRequisiteAvailableColumns,
			selectedColumns: this.postRequisiteSelectedColumns,
			columnSelectionMode: this.postRequisiteColumnSelectionMode,
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.postRequisiteTableDefinitions.selectedColumns =
					selectedColumns;
				this.postRequisiteSelectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.postRequisiteTableDefinitions.columnSelectionMode =
					columnSelectionMode;
				this.postRequisiteColumnSelectionMode = columnSelectionMode;
			}
		};

		if (this.context.source.displayUpdate === true)
		{
			const postRequisiteDropdownOptions: IDropdownOption[] =
				this.getDropdownOptions(
					await this.context.source
						.customContext.source
						.workflowActionDefinitionsApiService
						.query(
							'EntityTypeId eq '
								+ `${this.context.source
									.rowData.entityTypeId}`
								+ ` and Id ne ${this.context
									.source.rowData.id}`,
							AppConstants.empty,
							null,
							100),
					AppConstants.commonProperties.name,
					AppConstants.commonProperties.id);

			this.postRequisiteTableDefinitions.actions =
				{
					create: {
						displayCreateRow: false,
						customContext: this.actionExpandDefinitionsContext,
						layout: [
							{
								key: 'data.requisiteActionDefinitionId',
								type: FormlyConstants.customControls
									.customSelect,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								templateOptions: {
									required: true,
									showClear: true,
									placeholder:
										AppConstants.placeholders
											.selectAnOption,
									options: postRequisiteDropdownOptions
								}
							},
							{
								key: 'data.order',
								type: FormlyConstants.customControls
									.customInputNumber,
								wrappers: [
									FormlyConstants.customControls
										.customFieldWrapper
								],
								templateOptions: {
									required: true,
									type: 'number'
								},
								asyncValidators: {
									uniqueOrder: {
										expression: (control: FormControl) =>
											this.uniqueOrder(
												control,
												'PostRequisite'),
										message: 'Existing Order.'
									}
								}
							}
						],
						items:
						[
							{
								label: 'Save',
								styleClass:
									AppConstants.cssClasses.pButtonPrimary,
								command:
									() => this.createRequisite('PostRequisite')
							}
						]
					},
					updateIndex:
					[
						{
							id: 'updateIndexUp',
							visible: (
								rowData: any,
								virtualData: any[]) =>
								rowData.order !== virtualData[0].order,
							command: async(
								commonTableContext: CommonTableComponent) =>
								this.updateOrderIndex(
									commonTableContext,
									-1,
									'PostRequisite')
						},
						{
							id: 'updateIndexDown',
							visible: (
								rowData: any,
								virtualData: any[]) =>
								rowData?.order !==
									virtualData[virtualData.length - 1]?.order,
							command: async(
								commonTableContext: CommonTableComponent) =>
								this.updateOrderIndex(
									commonTableContext,
									1,
									'PostRequisite')
						}
					],
					delete: {
						deleteStatement: () =>
							`Confirm to remove PostRequisite
								${this.commonTableContext.data.name}
								from action definition
								${this.context.data.data.name}.`,
						items: [
							{
								label: 'Remove',
								styleClass:
									AppConstants.cssClasses.pButtonDanger,
								command: async() =>
									this.deleteRequisite('PostRequisite')
							}
						],
					}
				};
		}
		this.loadingTableDefinitions = false;
	}

	/**
	 * Restores the requisite table definitions.
	 *
	 * @param {string} requisiteType
	 * @memberof ActionDefinitionExpandComponent
	 */
	public restoreRequisiteTableDefinitions(requisiteType: string): void
	{
		this.loadingTableDefinitions = true;
		setTimeout(async() =>
		{
			if (requisiteType === 'PreRequisite')
			{
				await this.setPreRequisiteTableDefinitions();
			}
			else if (requisiteType === 'PostRequisite')
			{
				await this.setPostRequisiteTableDefinitions();
			}
		},
		this.requisiteTableRedrawDebounceTime);
	}

	/**
	 * Checks if content value is a valid c sharp format.
	 *
	 * @memberof ActionDefinitionExpandComponent
	 */
	private isValidCSharpFormat(value: string): RegExpMatchArray
	{

		return value?.match(new RegExp(
			'^(?=.*namespace (?=WaterStreet))'
				+ '(?=.*using (?=WaterStreet))'
				+ '(?=.*public (?=class))'
				+ '(?=.*public (?=override))'
				+ '(?=.*return).*$',
			'gs'))
			&& value.match(/\{(?:[^{}]+|\{(?:[^{}]+|\{[^{}]\})\}+)\}+/g);
	}

	/**
	 * Validates if the requisite order is unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The field form control.
	 * @param {string} requisiteType
	 * The requisite type.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof ActionDefinitionExpandComponent
	 */
	private async uniqueOrder(
		control: FormControl,
		requisiteType: string): Promise<boolean>
	{
		const workflowRequisiteTypes: IWorkflowRequisiteTypes[] =
			await this.workflowRequisiteTypeApiService
				.query(
					`Name eq '${requisiteType}'`,
					AppConstants.empty);

		const workflowRequisites: IWorkflowActionRequisites[] =
			await this.context.source.customContext.source
				.workflowActionRequisitesApiService
				.query(
					`Order eq ${control.value} `
						+ 'and ActionDefinitionId eq '
						+ `${this.context.data.data.id} `
						+ 'and RequisiteTypeId eq '
						+ workflowRequisiteTypes[0].id,
					AppConstants.empty);

		return Promise.resolve(workflowRequisites.length <= 0);
	}

	/**
	 * Creates a new Requisite.
	 *
	 * @async
	 * @param {string} requisiteType
	 * @memberof ActionDefinitionExpandComponent
	 */
	private async createRequisite(requisiteType: string): Promise<void>
	{
		const createRequisite: Function = async () =>
		{
			await this.context.source.customContext.source
				.workflowActionRequisitesApiService
				.create(
						<IWorkflowActionRequisites>
						{
							id: 0,
							requisiteTypeId:
								(await this.workflowRequisiteTypeApiService
									.query(
										`Name eq '${requisiteType}'`,
										AppConstants.empty))[0].id,
							actionDefinitionId: this.context.data.data.id,
							requisiteActionDefinitionId:
								this.commonTableContext.source.rowData
									.requisiteActionDefinitionId,
							order: this.commonTableContext.source.rowData.order
						});
		};

		await this.activityService.handleActivity(
			new Activity(
				createRequisite(),
				'<strong>Creating Requisite</strong>',
				'<strong>Created Requisite</strong>',
				`Requisite
					${this.commonTableContext.source.rowData.name}
					was successfully created.`,
				`Requisite
					${this.commonTableContext.source.rowData.name}
					was not created.`));

		this.restoreRequisiteTableDefinitions(requisiteType);
	}

	/**
	 * Deletes an existing Requisite.
	 *
	 * @async
	 * @param {string} requisiteType
	 * @memberof ActionDefinitionExpandComponent
	 */
	private async deleteRequisite(requisiteType: string): Promise<void>
	{
		await this.activityService.handleActivity(
			new Activity(
				this.context.source.customContext.source
					.workflowActionRequisitesApiService
					.delete(this.commonTableContext.source.rowData.id),
				'<strong>Deleting Requisite</strong>',
				'<strong>Deleted Requisite</strong>',
				`Requisite
							${this.commonTableContext.source.rowData.name}
							was successfully deleted.`,
				`Requisite
							${this.commonTableContext.source.rowData.name}
							was not deleted.`));

		this.restoreRequisiteTableDefinitions(requisiteType);
	}

	/**
	 * Updates the order index of the selected row item
	 * up or down based on the indexOperator.
	 *
	 * @async
	 * @param {CommonTableComponent} commonTableContext
	 * The common table context.
	 * @param {number} indexReference
	 * The index reference to add or substract to the current
	 * selected order index.
	 * @param {string} requisiteType
	 * The requisite type.
	 * @memberof ActionDefinitionExpandComponent
	 */
	private async updateOrderIndex(
		commonTableContext: CommonTableComponent,
		indexReference: number,
		requisiteType: string): Promise<void>
	{
		const updateOrderIndex: Function = async() =>
		{
			commonTableContext.loadingTableDefinitions = true;

			const orderedRequisites =
				this.commonTableContext.source.virtualData
					.filter((data) => data !== undefined)
					.sort((a, b) => a.order - b.order);

			const neighborOrderIndex =
				this.findSelectedChildIndex(
					orderedRequisites,
					this.commonTableContext.source.rowData) + indexReference;

			if (neighborOrderIndex > -1)
			{
				await this.context.source.customContext.source
					.workflowActionRequisitesApiService
					.update(
						orderedRequisites[neighborOrderIndex].id,
							<IWorkflowActionRequisites>
							{
								id: orderedRequisites[neighborOrderIndex].id,
								requisiteTypeId: orderedRequisites[
									neighborOrderIndex]
									.requisiteTypeId,
								actionDefinitionId:
									orderedRequisites[neighborOrderIndex]
										.actionDefinitionId,
								requisiteActionDefinitionId:
									orderedRequisites[neighborOrderIndex]
										.requisiteActionDefinitionId,
								order: 1000
							});

				await this.context.source.customContext.source
					.workflowActionRequisitesApiService
					.update(
						this.commonTableContext.source.rowData.id,
							<IWorkflowActionRequisites>
							{
								id: this.commonTableContext.source.rowData.id,
								requisiteTypeId: this.commonTableContext
									.source.rowData
									.requisiteTypeId,
								actionDefinitionId:
									this.commonTableContext.source.rowData
										.actionDefinitionId,
								requisiteActionDefinitionId:
									this.commonTableContext.source.rowData
										.requisiteActionDefinitionId,
								order: orderedRequisites[
									neighborOrderIndex].order
							});

				await this.context.source.customContext.source
					.workflowActionRequisitesApiService
					.update(
						orderedRequisites[neighborOrderIndex].id,
							<IWorkflowActionRequisites>
							{
								id: orderedRequisites[neighborOrderIndex].id,
								requisiteTypeId:
									orderedRequisites[neighborOrderIndex]
										.requisiteTypeId,
								actionDefinitionId:
									orderedRequisites[neighborOrderIndex]
										.actionDefinitionId,
								requisiteActionDefinitionId:
									orderedRequisites[neighborOrderIndex]
										.requisiteActionDefinitionId,
								order:
									this.commonTableContext.source.rowData.order
							});
			}
		};

		await this.activityService.handleActivity(
			new Activity(
				updateOrderIndex(),
				'<strong>Updating Requisite Order</strong>',
				'<strong>Updated Requisite Order</strong>',
				'Requisite Order was successfully updated.',
				'Requisite Order was not updated.'),
			AppConstants.activityStatus.complete,
			true);

		this.restoreRequisiteTableDefinitions(requisiteType);
	}

	/**
	 * Finds the selected child index.
	 *
	 * @param {any[]} children
	 * The children object array.
	 * @param {any} selectedChild
	 * The selected child.
	 * @memberof ActionDefinitionExpandComponent
	 */
	private findSelectedChildIndex(
		children: any[],
		selectedChild: any): number
	{
		for (let index = 0; index < children.length; index++)
		{
			if (children[index].id === selectedChild.id
				&& children[index].requisiteActionDefinitionId
					=== selectedChild.requisiteActionDefinitionId
				&& children[index].order === selectedChild.order)
			{
				return index;
			}
		}

		return -1;
	}

	/**
	 * Checks if entity type, entity verion
	 * and action name combination is existing or unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The form control.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof ActionDefinitionExpandComponent
	 */
	private async uniqueCombination(control: FormControl): Promise<boolean>
	{

		let actionDefinition: IWorkflowActionDefinitions[] = [];

		if (!AnyHelper.isNullOrEmpty(this.context.data.data.entityTypeId))
		{
			const entityVersionId = this.context.source.displayCreate === true
				? (await this.context.source.customContext
					.source.entityVersionApiService
					.query(
						`TypeId eq ${this.context.data.data.entityTypeId}`,
						AppConstants.empty))[0].id
				: this.context.data.data.entityVersionId;

			actionDefinition =
				await this.context.source.customContext.source
					.workflowActionDefinitionsApiService
					.query(
						'EntityTypeId eq '
							+ `${this.context.data.data.entityTypeId} `
							+ `and EntityVersionId eq ${entityVersionId} `
							+ `and Name eq '${control.value}'`,
						AppConstants.empty);
		}

		return Promise.resolve(
			actionDefinition.length === 0
				|| (this.context.source.displayCreate === false
					&& this.context.data.data.id ===
						actionDefinition[0]?.id));
	}

	/**
	 * Gets the dropdown options.
	 *
	 * @param {any} apiData
	 * The api resolved with data for the dropdown options.
	 * @param {string} label
	 * The dropdown label to map with the data promise results.
	 * @param {string} value
	 * The dropdown value to map with the data promise results.
	 * @returns {IDropdownOption[]}
	 * The dropdown options object
	 * @memberof EntityManagerDirective
	 */
	private getDropdownOptions(
		apiData: any,
		label: string,
		value: string): IDropdownOption[]
	{
		return (apiData)
			.map((item: any) =>
			<IDropdownOption>
					{
						label: item[label],
						value: item[value]
					});
	}
}