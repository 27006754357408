/**
 * @copyright WaterStreet. All rights reserved.
*/

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BaseSessionUserDirective
} from '@operation/directives/base-session-user.directive';
import {
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	SessionService
} from '@shared/services/session.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

@Component({
	selector: 'app-topbar',
	templateUrl: './app-topbar.component.html',
	styleUrls: ['./app-topbar.component.scss']
})

/**
 * A component used to display and handle top bar based
 * actions and navigation.
 *
 * @export
 * @class AppTopBarComponent
 * @extends {BaseSessionUserComponent}
 */
export class AppTopBarComponent
	extends BaseSessionUserDirective
{
	/**
	 * Creates an instance of an AppTopBarComponent.
	 *
	 * @param {SessionService} sessionService
	 * The session service to use for top bar displays.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service to use for responsive layouts.
	 * @memberof AppTopBarComponent
	 */
	public constructor(
		sessionService: SessionService,
		siteLayoutService: SiteLayoutService)
	{
		super(
			siteLayoutService,
			sessionService);
	}

	/**
	 * Gets or sets the rotate menu button value.
	 * This is used to define if the menu display should
	 * have a rotate animation (Used when expanded).
	 *
	 * @type {boolean}
	 * @memberof AppTopBarComponent
	 */
	@Input() public rotateMenuButton: boolean = false;

	/**
	 * Sets the event emitter for the toggle overlay menu event so this
	 * can be sent to listening parent components.
	 *
	 * @type {EventEmitter<Event>}
	 * @memberof AppTopBarComponent
	 */
	@Output() public overlayMenuButtonClick: EventEmitter<Event> =
		new EventEmitter();

	/**
	 * Defines the profile group identifier that will
	 * be displayed via this operation group display component.
	 *
	 * @type {string}
	 * @memberof AppTopBarComponent
	 */
	public profileGroupName: string =
		AppConstants.primaryOperationGroups.profileActions;

	/**
	 * Defines the settings group identifier that will
	 * be displayed via this operation group display component.
	 *
	 * @type {string}
	 * @memberof AppTopBarComponent
	 */
	public settingsGroupName: string =
		AppConstants.primaryOperationGroups.settingActions;

	/**
	 * Handles the overlay menu toggle buttonn click event.
	 * This sends the overlay menu button click event to the
	 * parent application component.
	 *
	 * @param {Event} event
	 * The click event sent when clicking the overlay menu button.
	 * @memberof AppTopBarComponent
	 */
	public overlayMenuButtonClicked(
		event: Event): void
	{
		this.overlayMenuButtonClick.emit(event);

		EventHelper.dispatchHideAssociatedMenusEvent(
			'primary-navigation');
	}
}