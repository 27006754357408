<div class="ui-g-12">
	<div class="ui-g-6 no-horizontal-padding request-container">
		<span class="request-section ellipsis-text">
			<span class="request-method {{method}}">
				<span class="method-align">
				{{context.data.data.requestMethod}}
				</span>
			</span>
			<span class="request-url">
				{{requestUrl}}
			</span>
		</span>
	</div>

	<div class="ui-g-6 no-horizontal-padding">
		<strong class="information-header data-format float-right time-section">
			{{timeLog}}
		</strong>
	</div>

	<ng-template [ngIf]="!siteLayoutService.displayTabletView"
		[ngIfElse]="mobileView">
		<div class="ui-g-12 no-padding">
			<div class="ui-g-6 information-section">
				<div class="ellipsis-text">
					<strong class="information-header">
						User Name
					</strong>
					<span class="data-format">
						{{context.data.data.userName}}
					</span>
				</div>
				<div class="ellipsis-text section-margin">
					<strong class="information-header">
						Request Host
					</strong>
					<span class="data-format">
						{{context.data.data.requestHost}}
					</span>
				</div>
				<div class="ellipsis-text section-margin">
					<strong class="information-header">
						Logger
					</strong>
					<span class="data-format">
						{{context.data.data.logger}}
					</span>
				</div>
				<div class="ellipsis-text section-margin">
					<strong class="information-header">
						Call Site
					</strong>
					<span class="data-format">
						{{context.data.data.callsite}}
					</span>
				</div>
			</div>
			<div class="ui-g-6 message-section background no-vertical-padding">
				<strong>
					Message
				</strong>
				<div class="data-format message-content">
					{{context.data.data.message}}
				</div>
				<div class="highlight-opacity">
				</div>
			</div>
		</div>
	</ng-template>

	<ng-template #mobileView>
		<div class="ui-g-12 message-section background">
			<strong>
				Message
			</strong>
			<div class="data-format message-content">
				{{context.data.data.message}}
			</div>
			<div class="highlight-opacity">
			</div>
		</div>

		<div class="ui-g-12">
			<div class="ellipsis-text">
				<strong class="information-header">
					User Name
				</strong>
				<span class="data-format">
					{{context.data.data.userName}}
				</span>
			</div>
			<div class="ellipsis-text section-margin">
				<strong class="information-header">
					Request Host
				</strong>
				<span class="data-format">
					{{context.data.data.requestHost}}
				</span>
			</div>
			<div class="ellipsis-text section-margin">
				<strong class="information-header">
					Logger
				</strong>
				<span class="data-format">
					{{context.data.data.logger}}
				</span>
			</div>
			<div class="ellipsis-text section-margin">
				<strong class="information-header">
					Call Site
				</strong>
				<span class="data-format">
					{{context.data.data.callsite}}
				</span>
			</div>
		</div>
	</ng-template>

	<div class="ui-g-12 no-horizontal-padding dynamic-formly-section">
		<app-dynamic-formly
			[dataSet]="context.data"
			[layoutSchema]="context.source.tableDefinitions.actions.view.layout">
		</app-dynamic-formly>
	</div>

	<div class="ui-g-12 no-padding">
		<operation-button-bar
			[initialModel]="context.source.expandActions"
			(loadingOperations)="context.source.operationButtonClicked(context.source.rowData)">
		</operation-button-bar>
	</div>
</div>