/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	EntityTableDirective
} from '@entity/directives/entity-table.directive';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityDefinition
} from '@shared/interfaces/entities/entity-definition.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntitySearch
} from '@entity/interfaces/entity-search.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	ISelectable
} from '@shared/interfaces/application-objects/selectable.interface';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/* eslint-enable max-len */

@Component({
	selector: 'entity-select',
	templateUrl: './entity-select.component.html',
	styleUrls: ['./entity-select.component.scss'],
	animations: [
		ContentAnimation
	]
})

/**
 * A class representing an instance of an entity search component.
 *
 * @export
 * @class EntitySelectComponent
 * @implements {OnInit}
 */
export class EntitySelectComponent
	extends EntityTableDirective
	implements OnInit
{
	/**
	 * Creates an instance of an EntitySelectComponent. This component
	 * will display a filtered list of entities for navigation.
	 *
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type service used to populate get the type name.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance service used to populate the entity list data.
	 * @param {EntityDefinitionApiService} entityDefinitionApiService
	 * The entity instance service used to populate the entity list data.
	 * @memberof EntitySelectComponent
	 */
	public constructor(
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService)
	{
		super();
	}

	/**
	 * Gets or sets the simple display mode. This simple display
	 * mode does not offer many features of a full search list.
	 *
	 * @type {boolean}
	 * @memberof EntitySelectComponent
	 */
	@Input() public useSimpleDisplayMode: boolean = true;

	/**
	 * Gets or sets the selected entities that are stored in the
	 * component using this select. Selected entities are set with an
	 * id of zero when loaded in the list allowing for selected
	 * business rules.
	 *
	 * @type {IEntityInstance[]}
	 * @memberof EntitySelectComponent
	 */
	@Input() public selectedEntities: IEntityInstance[] = [];

	/**
	 * Gets or sets the search criteria of the entity
	 * list.
	 *
	 * @type {IEntitySearch}
	 * @memberof EntitySearchDirective
	 */
	@Input() public searchCriteria: IEntitySearch;

	/**
	 * Sets the event emitter action to communicate to
	 * implementing components that a row was selected.
	 *
	 * @type {EventEmitter<IEntityInstance>}
	 * @memberof EntitySelectComponent
	 */
	@Output() public rowSelected: EventEmitter<ISelectable> =
		new EventEmitter();

	/**
	 * Sets the event emitter action to communicate to
	 * implementing components that a category was selected.
	 *
	 * @type {EventEmitter<string>}
	 * @memberof EntitySelectComponent
	 */
	@Output() public categorySelected: EventEmitter<string> =
		new EventEmitter();

	/**
	 * Gets or sets the entity search table definitions.
	 *
	 * @type {ICommonTable}
	 * @memberof EntitySelectComponent
	 */
	public entitySelectTableDefinitions: ICommonTable;

	/**
	 * Gets or sets the common table context.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof EntitySelectComponent
	 */
	public commonTableContext:
		IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the category options.
	 *
	 * @type {object[]}
	 * @memberof EntitySelectComponent
	 */
	public categoryOptions: object[] = [];

	/**
	 * Gets or sets the loaded entity type group.
	 *
	 * @type {string}
	 * @memberof EntitySelectComponent
	 */
	public entityTypeGroup: string;

	/**
	 * Gets or sets the category label.
	 *
	 * @type {string}
	 * @memberof EntitySelectComponent
	 */
	public selectedCategory: string;

	/**
	 * Gets or sets the loading entity type flag.
	 *
	 * @type {boolean}
	 * @memberof EntitySelectComponent
	 */
	public loadingEntityType: boolean = true;

	/**
	 * Gets or sets the string array categories.
	 *
	 * @type {string[]}
	 * @memberof EntitySelectComponent
	 */
	public categories: string[];

	/**
	 * Gets or sets the entity sort field.
	 *
	 * @type {string}
	 * @memberof EntitySelectComponent
	 */
	public entitySortField: string;

	/**
	 * Gets or sets the entity sort order.
	 *
	 * @type {number}
	 * @memberof EntitySelectComponent
	 */
	public entitySortOrder: number;

	/**
	 * Gets or sets the summary data path available columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof EntitySelectComponent
	 */
	public summaryDataPathColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the loaded entity definition.
	 *
	 * @type {IEntityDefinition}
	 * @memberof EntitySelectComponent
	 */
	public entityDefinition: IEntityDefinition;

	/**
	 * On initialization event.
	 * Configures this components display based on the sent route
	 * information.
	 *
	 * @async
	 * @memberof EntitySelectComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.setFilterKeywordString(this.searchCriteria.filter);
		await this.setCategories();
		this.setCategoryLabel(this.entityTypeGroup);
		this.summaryDataPathColumns =
			await this.entityService.getDynamicSummaryColumns(
				this.entityTypeGroup);
		this.selectedColumns = this.summaryDataPathColumns;
		this.setupTableDefinitions();
	}

	/**
	 * Sets the categories properties wich defines
	 * the category label and options to be displayed in
	 * the search filter component.
	 *
	 * @async
	 * @memberof EntitySelectComponent
	 */
	public async setCategories(): Promise<void>
	{
		this.categories = this.searchCriteria.category
			.split(AppConstants.characters.comma);

		this.categories.forEach(async (category: string) =>
		{
			this.categoryOptions.push(
				{
					value: category,
					label: StringHelper.beforeCapitalSpaces(
						StringHelper.getLastSplitValue(
							(await this.entityTypeApiService
								.query(
									`Group eq '${category}'`,
									AppConstants.empty))[0]?.name,
							AppConstants.characters.period))
				});
		});

		this.entityTypeGroup = AppConstants.empty;
	}

	/**
	 * Sets the definitions for the entity search common table
	 *
	 * @memberof EntitySelectComponent
	 */
	public setupTableDefinitions(): void
	{
		this.entitySelectTableDefinitions = {
			hideExpanderArrow: true,
			actions: {
				filter: {
					considerFilteringResults: () =>
					{
						document.getElementById(
							AppConstants.commonTableActions.filterInput)
							.focus();
					}
				}
			},
			tableTitle: AppConstants.empty,
			allowSortColumns: true,
			objectSearch: {
				filter: this.searchCriteria.filter,
				orderBy: this.searchCriteria.orderBy,
				offset: 0,
				limit: this.searchCriteria.limit,
				virtualIndex: 0,
				virtualPageSize: this.searchCriteria.virtualPageSize,
				sortField: this.entitySortField,
				sortOrder: this.entitySortOrder
			},
			apiPromise: (objectSearch: IObjectSearch) =>
			{
				this.entityInstanceApiService.entityInstanceTypeGroup =
						this.entityTypeGroup;

				return this.entityInstanceApiService
					.query(
						objectSearch.filter,
						objectSearch.orderBy,
						objectSearch.offset,
						objectSearch.limit);
			},
			availableColumns: this.summaryDataPathColumns,
			selectedColumns: this.selectedColumns,
			columnSelectionMode: this.columnSelectionMode,
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			filterCriteriaChanged: (filterCriteria: string) =>
			{
				// Get the query string filter
				this.searchCriteria.filter =
					(!AnyHelper.isNullOrEmpty(filterCriteria))
						? this.getQueryStringContainsFilter(filterCriteria)
						: AppConstants.empty;

				// Reload the table definitions and query string
				if (AnyHelper.isNullOrEmpty(this.selectedCategory)
					|| this.selectedCategory === AppConstants.undefined)
				{
					return;
				}

				this.loadingEntityType = false;
				this.restoreTableDefinition();
			},
			sortCriteriaChanged: (
				sortCriteria: string,
				sortField: string,
				sortOrder: number) =>
			{
				this.searchCriteria.orderBy = sortCriteria;
				this.entitySortField = sortField;
				this.entitySortOrder = sortOrder;
				this.restoreTableDefinition();
			},
			rowSelection: {
				selectionMode: 'multiple',
				selectedRows: this.selectedEntities,
				selectedRowEvent: (
					event: any,
					selected: boolean) =>
				{
					this.rowSelected.emit(
							<ISelectable>
							{
								data: event.data,
								selected: selected
							});
				}
			},
			rowCountChanged: (rowCount: number) =>
			{
				this.searchCriteria.virtualPageSize = rowCount;
				this.restoreTableDefinition();
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.entitySelectTableDefinitions.selectedColumns =
					selectedColumns;
				this.selectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.entitySelectTableDefinitions.columnSelectionMode =
					columnSelectionMode;
				this.columnSelectionMode = columnSelectionMode;
			}
		};
		this.loadingTableDefinitions = false;
	}

	/**
	 * Handles the category selection change. This will
	 * update the view based on the selected type group.
	 *
	 * @param {string} selectedCategory
	 * The selected category string.
	 * @async
	 * @memberof EntitySelectComponent
	 */
	public async selectedCategoryChanged(
		selectedCategory: string): Promise<void>
	{
		this.selectedCategory = selectedCategory;
		// Restore the table definition loading state
		this.loadingTableDefinitions = true;

		// Set the Entity Group and Name based on the selected category
		this.entityTypeGroup = selectedCategory;

		this.setCategoryLabel(this.entityTypeGroup);

		// Reload the table definitions and query string
		if (AnyHelper.isNullOrEmpty(selectedCategory))
		{
			setTimeout(
				() =>
				{
					this.filterValue = AppConstants.empty;
					this.searchCriteria.filter = AppConstants.empty;
					this.loadingTableDefinitions = false;
					this.loadingEntityType = true;
				});
		}
		else
		{
			this.categorySelected.emit(selectedCategory);
			setTimeout(
				async() =>
				{
					this.summaryDataPathColumns =
						await this.entityService.getDynamicSummaryColumns(
							this.entityTypeGroup);
					this.selectedColumns = this.summaryDataPathColumns;
					this.columnSelectionMode = false;
					this.setupTableDefinitions();
					this.loadingEntityType = false;
				});
		}

		this.entitySelectTableDefinitions
			.objectSearch.filter = this.searchCriteria.filter;
	}
}