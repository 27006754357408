/**
 * @copyright WaterStreet. All rights reserved.
 */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ActivatedRoute,
	Router
} from '@angular/router';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BIReportSettingDirective
} from '@shared/directives/bi-report-setting.directive';
import {
	ClonePowerBiReportComponent
} from '@bi/components/wizard-steps/clone-power-bi-report/clone-power-bi-report.component';
import {
	Component,
	OnInit
} from '@angular/core';
import {
	DisplayComponentDefinitionApiService
} from '@api/services/display-components/display-component-definition.api.service';
import {
	DisplayComponentFactory
} from '@shared/factories/display-component-factory';
import {
	DisplayComponentInstance
} from '@shared/implementations/display-components/display-component-instance';
import {
	DisplayComponentInstanceApiService
} from '@api/services/display-components/display-component-instance.api.service';
import {
	DisplayComponentService
} from '@shared/services/display-component.service';
import {
	DisplayComponentTypeApiService
} from '@api/services/display-components/display-component-type.api.service';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	FormControl
} from '@angular/forms';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	FormlyHelper
} from '@shared/helpers/formly.helper';
import {
	IAwaitableMenuItem
} from '@shared/interfaces/application-objects/awaitable-menu-item.interface';
import {
	IDisplayComponentInstance
} from '@shared/interfaces/display-components/display-component-instance.interface';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IGroupedDropdownOption
} from '@shared/interfaces/application-objects/grouped-dropdown-option.interface';
import {
	IPowerBiReport
} from '@shared/interfaces/reports/power-bi/power-bi-report.interface';
import {
	IPowerBiReportDefinition
} from '@shared/interfaces/reports/power-bi/power-bi-report-definition.interface';
import {
	IPowerBiReportLookup
} from '@shared/interfaces/reports/power-bi/power-bi-report-lookup.interface';
import {
	IReportDataDetails
} from '@shared/interfaces/reports/power-bi/power-bi-report.details.interface';
import {
	ISecurityGroup
} from '@shared/interfaces/security/security-group.interface';
import {
	MenuItem
} from 'primeng/api';
import {
	OptionsFactory
} from '@shared/factories/options-factory';
import {
	PowerBiApiService
} from '@shared/services/power-bi-api.service';
import {
	PowerBiService
} from '@shared/services/power-bi.service';
import {
	SessionService
} from '@shared/services/session.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	User
} from '@shared/implementations/users/user';

/* eslint-enable max-len */

@Component({
	selector: 'app-bi-report-settings',
	templateUrl: './bi-report-settings.component.html',
	styleUrls: [
		'./bi-report-settings.component.scss'
	]
})

/**
 * A component representing Business
 * Inteligence Report Component.
 *
 * @export
 * @class BIReportComponent
 * @extends {BIReportSettingDirective}
 * @implements {OnInit}
 */
export class BIReportComponent
	extends BIReportSettingDirective
	implements OnInit
{
	/**
	 * Initializes a new instance of the Business
	 * Inteligence Report Component.
	 *
	 * @param {PowerBiService} powerBiService
	 * The power Bi service.
	 * @param {PowerBiApiService} powerBiApiService
	 * The power Bi api service.
	 * @param {DisplayComponentService} displayComponentService
	 * The display component service.
	 * @param {DisplayComponentDefinitionApiService}
	 * displayComponentDefinitionApiService
	 * The display component definition api service.
	 * @param {DisplayComponentInstanceApiService}
	 * displayComponentInstanceApiService
	 * The display component instance api service.
	 * @param {DisplayComponentTypeApiService}
	 * displayComponentTypeApiService
	 * The display component type api service.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service.
	 * @param {OptionsFactory} optionsFactory
	 * The options factory service.
	 * @param {SessionService} sessionService
	 * The session service.
	 * @param {ActivityService} activityService
	 * The activity service
	 * @param {Router} router
	 * The router service.
	 * @param {ActivatedRoute} route
	 * The activated route service.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service.
	 * @param {ClonePowerBiReportComponent} clonePowerBiReportComponent
	 * The clone power Bi report component.
	 * @param {DisplayComponentFactory} displayComponentFactory
	 * The display component factory.
	 *
	 * @memberof BIReportComponent
	 */
	public constructor(
		public powerBiService: PowerBiService,
		public powerBiApiService: PowerBiApiService,
		public displayComponentService: DisplayComponentService,
		public displayComponentDefinitionApiService:
			DisplayComponentDefinitionApiService,
		public displayComponentInstanceApiService:
			DisplayComponentInstanceApiService,
		public displayComponentTypeApiService:
			DisplayComponentTypeApiService,
		public siteLayoutService: SiteLayoutService,
		public optionsFactory: OptionsFactory,
		public sessionService: SessionService,
		public activityService: ActivityService,
		public router: Router,
		public route: ActivatedRoute,
		public entityInstanceApiService: EntityInstanceApiService,
		public clonePowerBiReportComponent: ClonePowerBiReportComponent,
		public displayComponentFactory: DisplayComponentFactory)
	{
		super(
			powerBiService,
			powerBiApiService,
			displayComponentDefinitionApiService,
			displayComponentTypeApiService,
			siteLayoutService);
	}

	/**
	 * Gets or sets the is Ownership Allowed
	 * value.
	 *
	 * @type {boolean}
	 * @memberof BIReportComponent
	 */
	public isOwnershipAllowed: boolean = true;

	/**
	 * Gets or sets the loading
	 * value.
	 *
	 * @type {boolean}
	 * @memberof BIReportComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets if there are
	 * pending changes.
	 *
	 * @type {boolean}
	 * @memberof BIReportComponent
	 */
	public pendingChanges: boolean = false;

	/**
	 * Gets or sets the view
	 * mode type.
	 *
	 * @type {boolean}
	 * @memberof BIReportComponent
	 */
	public viewMode: boolean = true;

	/**
	 * Gets or sets if button
	 * save is enabled.
	 *
	 * @type {boolean}
	 * @memberof BIReportComponent
	 */
	public saveEnabled: boolean = true;

	/**
	 * Gets or sets the page
	 * mode type.
	 *
	 * @type {string}
	 * @memberof BIReportComponent
	 */
	public pageMode: string = AppConstants.empty;

	/**
	 * Gets or sets the awaitable
	 * Menu items.
	 *
	 * @type {IAwaitableMenuItem[]}
	 * @memberof BIReportComponent
	 */
	public items?: IAwaitableMenuItem[];

	/**
	 * Gets or sets the user security
	 * groups.
	 * @type {string[]}
	 * @memberof BIReportComponent
	 */
	public userSecurityGroups?: string[];

	/**
	 * Gets or sets the delete return statement.
	 *
	 * @type {() => string | Promise<string>}
	 * @memberof BIReportComponent
	 */
	public deleteStatement?: () => string | Promise<string>;

	/**
	 * Gets or sets the standard Workspace
	 * Type.
	 *
	 * @type {string}
	 * @memberof BIReportComponent
	 */
	public standardWorkspaceType: string = 'Standard';

	/**
	 * Gets or sets the drawer action.
	 *
	 * @type {string}
	 * @memberof BIReportComponent
	 */
	public drawerAction: string = AppConstants.empty;

	/**
	 * Gets or sets if edit button
	 * is enabled.
	 *
	 * @type {boolean}
	 * @memberof BIReportComponent
	*/
	public editEnabled: boolean = false;

	/**
	 * Gets or sets the delete
	 * mode
	 *
	 * @type {boolean}
	 * @memberof BIReportComponent
	*/
	public deleteMode: boolean = false;

	/**
	 * Gets or sets the base page display component instance.
	 *
	 * @type {DisplayComponentInstance}
	 * @memberof BIReportComponent
	 */
	public basePageInstance: DisplayComponentInstance;

	/**
	 * Gets or sets the base page display component instance.
	 *
	 * @type {DisplayComponentInstance}
	 * @memberof BIReportComponent
	 */
	public displayComponentInstance: DisplayComponentInstance;

	/**
	 * Gets or sets if edit action
	 * is in Progress.
	 *
	 * @type {boolean}
	 * @memberof BIReportComponent
	*/
	public editInProgress: boolean = false;

	/**
	 * Gets or sets if display modal
	 * is enabled.
	 * Type.
	 *
	 * @type {boolean}
	 * @memberof BIReportComponent
	 */
	public displayModal: boolean = false;

	/**
	 * Gets or sets the reportData
	 * Info.
	 *
	 * @type {IReportDataDetails}
	 * @memberof BIReportComponent
	 */
	public reportData: IReportDataDetails;

	/**
	 * Gets or sets the reportData
	 * Lookup.
	 *
	 * @type {IPowerBiReportLookup}
	 * @memberof BIReportComponent
	 */
	public powerBiReportLookup: IPowerBiReportLookup;

	/**
	 * Gets or sets the report
	 * button actions.
	 *
	 * @type {MenuItem[]}
	 * @memberof BIReportComponent
	 */
	public reportButtonActions: MenuItem[];

	/**
	 * Gets or sets the allow security
	 * groups.
	 *
	 * @type {number[]}
	 * @memberof BIReportComponent
	 */
	public allowedSecurityGroups: number[];

	/**
	 * Gets or sets the allowed base security
	 * groups.
	 *
	 * @type {number[]}
	 * @memberof BIReportComponent
	 */
	public allowedBaseSecurityGroups: number[];

	/**
	 * Gets or sets the user Entity Instance.
	 *
	 * @type {IEntityInstance}
	 * @memberof BIReportComponent
	 */
	public userEntityInstance: IEntityInstance;

	/**
	 * Gets or sets the security group options.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof BIReportComponent
	 */
	public securityGroupOptions: IDropdownOption[] = [];

	/**
	 * Gets or sets the report Definition.
	 *
	 * @type {IPowerBiReportDefinition}
	 * @memberof BIReportComponent
	 */
	public reportDefinition: IPowerBiReportDefinition;

	/**
	 * icon action id constants.
	 *
	 * @type {{navigateToEditReport: string;
	 *	navigateToViewReport: string;
	 *	deleteReport: string;}}
	 * @memberof BIReportComponent
	 */
	public iconActionIds:
		{
			navigateToEditReport: string;
			navigateToViewReport: string;
			deleteReport: string;
		} = {
			navigateToEditReport: 'NavigateToEditReport',
			navigateToViewReport: 'NavigateToViewReport',
			deleteReport: 'DeleteReport'
		};

	/**
	 * Gets or sets if display report
	 * is enabled.
	 *
	 * @type {boolean}
	 * @memberof BIReportComponent
	 */
	public displayReport: boolean = false;

	/**
	 * Gets or sets the drawer
	 * options.
	 *
	 * @type {any[]}
	 * @memberof BIReportComponent
	 */
	public drawerOptions: any[];

	/**
	 * Gets or sets the report search
	 * url value.
	 *
	 * @type {string}
	 * @memberof BIReportComponent
	 */
	public readonly reportSearchUrl: string =
		'bi/reports';

	/**
	 * Gets or sets the delete confirmation
	 * message text.
	 *
	 * @type {string}
	 * @memberof BIReportComponent
	 */
	public deleteConfirmationMessage: string =
		AppConstants.empty;

	/**
	 * Gets or sets if initial Validation Complete
	 * is enabled.
	 *
	 * @type {boolean}
	 * @memberof BIReportComponent
	 */
	public initialValidationComplete: boolean = false;

	/**
	 * Gets or sets the formly layout used in implementing components.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof BIReportComponent
	 */
	public dynamicFormlyLayout: FormlyFieldConfig[];

	/**
	 * A method that performs the view report action.
	 *
	 * @memberof BIReportComponent
	 */
	public viewReport(): void
	{
		if (this.editInProgress)
		{
			this.navigateToDisplay(
				this.router,
				this.getRowSelectedInfo(
					this.context.source.reportData.displayComponentInstanceName,
					this.iconActionIds.navigateToViewReport));
		}

		if (this.dynamicFormlyLayout !== null)
		{
			FormlyHelper.disableAllFields(this.dynamicFormlyLayout);

			this.viewMode = true;
			this.saveEnabled = true;
		}
	}

	/**
	 * A method that performs the delete report
	 * cofirmation action.
	 *
	 * @memberof BIReportComponent
	 */
	public deleteReportConfirmation(): void
	{
		this.deleteMode = true;
		this.loading = false;
	}

	/**
	 * A method that performs the validation for
	 * edit mode.
	 *
	 * @async
	 * @memberof BIReportComponent
	 */
	public async validateEditMode(): Promise<void>
	{
		if ( this.reportData.reportDefinition.workspaceType !==
			this.standardWorkspaceType)
		{
			this.editEnabled = new User(this.sessionService.user)
				.membershipSecurityGroups
				.some((item: ISecurityGroup) => item.id ===
					this.reportData.ownershipSecurityGroupId);
		}
	}

	/**
	 * A method that performs the edit report
	 * action.
	 *
	 * @async
	 * @memberof BIReportComponent
	 */
	public async editReport(): Promise<void>
	{
		if (this.editEnabled)
		{
			this.navigateToDisplay(
				this.router,
				this.getRowSelectedInfo(
					this.context.source.reportData.displayComponentInstanceName,
					this.iconActionIds.navigateToEditReport));
		}

		if (this.dynamicFormlyLayout !== null)
		{
			this.dynamicFormlyLayout.forEach((definition) =>
			{
				let isDisabled: boolean = false;

				if (definition.id === AppConstants.commonProperties.datasetId
					|| definition.id === AppConstants.commonProperties.createdBy
					|| (this.context.source.reportData.public === false
						&& (definition.id === AppConstants.nestedRouteTypes
							.securityGroups
							|| definition.id === AppConstants.commonProperties
								.ownershipSecurityGroupId)))
				{
					isDisabled = true;
				}

				definition.templateOptions.disabled = isDisabled;
			});

			this.editInProgress = true;
			this.viewMode = false;
			this.saveEnabled = false;
		}
	}

	/**
	 * A method that performs the cancel
	 * action.
	 *
	 * @async
	 * @memberof BIReportComponent
	 */
	public async cancelAction(): Promise<void>
	{
		this.ngOnInit();
	}

	/**
	 * A method that performs the delete report
	 * action.
	 *
	 * @async
	 * @memberof BIReportComponent
	 */
	public async deleteReport(): Promise<void>
	{
		this.loading = true;

		this.displayModal = false;

		const currentData: IReportDataDetails =
			this.reportData;

		for (const securityGroupId of currentData
			.allowedDisplayComponentSecurityGroups)
		{
			await this.displayComponentInstanceApiService
				.deleteSecurityGroupMap(
					currentData.displayComponentInstanceId,
					securityGroupId);
		}

		for (const securityGroupId of currentData.allowedBaseSecurityGroups)
		{
			await this.displayComponentInstanceApiService
				.deleteSecurityGroupMap(
					currentData.basePageInstanceId,
					securityGroupId);
		}

		await this.activityService.handleActivity(
			new Activity(
				new Promise<void>(
					async(resolve) =>
					{
						await this.displayComponentInstanceApiService
							.delete(currentData.displayComponentInstanceId);
						await this.displayComponentInstanceApiService
							.delete(currentData.basePageInstanceId);

						await this.powerBiApiService
							.deleteReport(
								currentData.reportDefinition);

						this.router.navigate([this.reportSearchUrl]);

						resolve();
					}),
				'<strong>Deleting Report</strong>',
				'<strong>Deleted A Report</strong>',
				`${currentData.reportName} was deleted.`,
				`${currentData.reportName} was not deleted.`));

		this.loading = false;
		this.deleteMode = false;
	}

	/**
	 * Handles the on initialization event.
	 * This method will set configurable properties used in the drawer list
	 * directive and this component's view.
	 *
	 *
	 * @async
	 * @memberof BIReportComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		await this.initDrawer();

		this.context.source.reportData = this.reportData;

		this.deleteConfirmationMessage =
			this.getDeleteStatement();

		this.securityGroupOptions =
			this.securityGroupOptions.filter(option =>
				this.userSecurityGroups.includes(option.label));

		this.dynamicFormlyLayout =	this.initLayout();

		this.validateEditMode();

		if (this.pageMode ===
				AppConstants.viewTypes.edit)
		{
			this.editReport();
		}
		else
		{
			this.viewReport();
		}

		this.setDrawerButtonDefinition();

		this.deleteMode = false;
		this.loading = false;
	}

	/**
	 * A method that initialize drawer
	 * variables.
	 *
	 * @async
	 * @memberof BIReportComponent
	 */
	public async initDrawer(): Promise<void>
	{
		this.loading = true;

		this.editInProgress = false;

		this.reportButtonActions =
			[
				{
					label: 'Delete',
					icon: 'pi pi-times',
					command: () =>
					{
						this.deleteReportConfirmation();
					}
				}
			];

		this.userSecurityGroups =
			this.sessionService.user.membershipSecurityGroups.map(
				(item: ISecurityGroup) => item.name);

		this.securityGroupOptions =
			await this.optionsFactory.getSecurityGroupOptions();

		this.displayComponentInstance =
			this.context.source.displayComponentInstance;

		if (AnyHelper.isNull(
			this.context.source.pageContext.data.reportDefinition))
		{
			this.reportDefinition =
				await this.displayComponentFactory.externalReport(
					this.displayComponentInstance,
					null,
					false);
		}
		else
		{
			this.reportDefinition =
				this.context
					.source
					.pageContext
					.data
					.reportDefinition;
		}

		const pageUrl: any =
			AnyHelper.isNullOrEmpty(
				this.context
					.source
					.route
					.url)
				? AppConstants.viewTypes.view
				: this.context
					.source
					.route
					.url;

		this.pageMode =
			pageUrl.value[1].path;

		this.powerBiReportLookup =
			await this.powerBiApiService
				.lookupReportData(this.reportDefinition);

		this.reportDefinition.reportId = this.powerBiReportLookup.reportId;

		this.basePageInstance =
			this.context.source.basePageInstance;

		this.entityInstanceApiService.entityInstanceTypeGroup =
				AppConstants.typeGroups.users;

		this.userEntityInstance =
			await this.entityInstanceApiService
				.get(this.basePageInstance.createdById);

		this.allowedSecurityGroups =
			await this.displayComponentInstanceApiService
				.getSecurityGroups(
					this.displayComponentInstance.id);

		this.allowedBaseSecurityGroups =
			await this.displayComponentInstanceApiService
				.getSecurityGroups(
					this.basePageInstance.id);

		this.reportData =
			{
				reportName: this.reportDefinition.reportName,
				reportDefinition: this.reportDefinition,
				displayComponentInstanceId: this.displayComponentInstance.id,
				displayComponentInstanceName:
					this.displayComponentInstance.name,
				basePageInstanceId: this.basePageInstance.id,
				basePageInstance: this.basePageInstance,
				allowedDisplayComponentSecurityGroups :
					this.allowedSecurityGroups,
				allowedBaseSecurityGroups : this.allowedBaseSecurityGroups,
				securityGroups: this.allowedSecurityGroups,
				ownershipSecurityGroupId:
					this.basePageInstance.ownershipSecurityGroupId,
				datasetId: this.reportDefinition.datasetId,
				public: this.basePageInstance.public,
				createdBy: this.userEntityInstance.data.userName,
				isFavorite: false,
				createdDate: AppConstants.empty,
				lastUpdatedById: AppConstants.empty,
				lastUpdatedDate: AppConstants.empty
			};
	}

	/**
	 * Validates that the report name being selected is unique in power bi
	 * as well as when we create the display component instance.
	 *
	 * @param {FormControl} control
	 * The control to be validated for a unique name value.
	 * @returns {Promise<boolean>}
	 * A value representing the is valid value of this control.
	 * @async
	 * @memberof BIReportComponent
	 */
	public async validateUniqueReportName(
		control: FormControl): Promise<boolean>
	{
		const currentData: any =
			this.context.source.reportData;

		if (currentData.reportDefinition.reportName ===
			control.value.trimRight())
		{
			this.saveEnabled = false;

			return true;
		}

		const newReportDisplayComponentName: string =
			this.getReportDisplayComponentName(
				control.value);
		const newBasePageDisplayComponentName: string =
			`${AppConstants.displayComponentTypes.basePage}.`
				+ `${newReportDisplayComponentName}`;
		const selectedGroupId: string =
			currentData.ownershipSecurityGroupId;

		const matchingReportNames: IPowerBiReport[] =
			await this.getMatchingReportNames(
				this.powerBiApiService,
				control,
				selectedGroupId);

		const matchingDisplayNames: IDisplayComponentInstance[] =
			await this.getmatchingDisplayNames(
				this.displayComponentInstanceApiService,
				newReportDisplayComponentName);

		const matchingBasePageNames: IDisplayComponentInstance[] =
			await this.getmatchingDisplayNames(
				this.displayComponentInstanceApiService,
				newBasePageDisplayComponentName);

		const isValid: boolean =
			(matchingReportNames.length === 0
				&& matchingDisplayNames.length === 0
				&& matchingBasePageNames.length === 0);

		this.saveEnabled = !isValid;

		if (this.initialValidationComplete === false
			&& control.valid === false
			&& isValid === true)
		{
			control.markAsTouched();
			control.updateValueAndValidity();
			this.initialValidationComplete = true;
		}

		return matchingReportNames.length === 0
			&& matchingDisplayNames.length === 0
			&& matchingBasePageNames.length === 0;
	}

	/**
	 * A method that performs the update
	 * security groups action.
	 * @param {number} displayComponentInstanceId
	 * The control to be validated.
	 *
	 * @memberof BIReportComponent
	 */
	public async updateSecurityGroups(
		displayComponentInstanceId: number): Promise<void>
	{
		const currentData: IReportDataDetails =
			this.reportData;

		const deleteSecurityGroups: number[] = currentData
			.allowedDisplayComponentSecurityGroups
			.filter((item: number) =>
				!currentData.securityGroups
					.includes(item));

		const updateSecurityGroups: number[] = currentData
			.securityGroups
			.filter((item: number) => !currentData
				.allowedDisplayComponentSecurityGroups
				.includes(item));

		for (const securityGroupId of deleteSecurityGroups)
		{
			await this.displayComponentInstanceApiService
				.deleteSecurityGroupMap(
					displayComponentInstanceId,
					securityGroupId);
		}

		for (const securityGroupId of updateSecurityGroups)
		{
			await this.displayComponentInstanceApiService
				.createSecurityGroupMap(
					displayComponentInstanceId,
					securityGroupId);
		}
	}

	/**
	 * A method that performs the update
	 * the display component action.
	 * @param {number} displayComponentInstanceId
	 * The display component Instance Id.
	 * @param {string} reportDisplayComponentName
	 * The report display Component Name.
	 * @param {object} reportDisplayJson
	 * The report display Json object.
	 *
	 * @async
	 * @memberof BIReportComponent
	 */
	public async updateDisplayComponent(
		displayComponentInstanceId: number,
		reportDisplayComponentName: string,
		reportDisplayJson: object
	): Promise<void>
	{
		const currentData: IReportDataDetails =
			this.reportData;

		const displayComponentInstance:
			IDisplayComponentInstance =
				<IDisplayComponentInstance>
				await this.displayComponentInstanceApiService
					.get(displayComponentInstanceId);

		this.updateSecurityGroups(displayComponentInstanceId);

		displayComponentInstance.jsonData =
			JSON.stringify(reportDisplayJson);

		displayComponentInstance.name =
			reportDisplayComponentName;

		displayComponentInstance.public =
			currentData.public;

		displayComponentInstance.ownershipSecurityGroupId =
			currentData.ownershipSecurityGroupId;

		await this.displayComponentInstanceApiService
			.update(displayComponentInstanceId,
				displayComponentInstance);
	}

	/**
	 * A method that performs the update
	 * entity action.
	 *
	 * @async
	 * @memberof BIReportComponent
	 */
	public async updateEntity(): Promise<void>
	{
		this.loading = true;

		this.editInProgress = false;

		let reloadPage: boolean = false;

		const initialPromiseArray: Promise<any>[] = [];

		const currentData: IReportDataDetails =
			this.reportData;

		const displayComponentInstance:
			IDisplayComponentInstance =
				<IDisplayComponentInstance>
				await this.displayComponentInstanceApiService
					.get(currentData.displayComponentInstanceId);

		const basePageComponentDefinitionInstance:
			IDisplayComponentInstance =
				<IDisplayComponentInstance>
				await this.displayComponentInstanceApiService
					.get(currentData.basePageInstanceId);

		const basePageJsonData =
			JSON.parse(basePageComponentDefinitionInstance.jsonData);

		const reportDisplayComponentName: string =
			this.getReportDisplayComponentName(
				currentData.reportName);

		if (displayComponentInstance.name !==
			reportDisplayComponentName)
		{
			reloadPage = true;
			await this.renamePowerBIReport(currentData.reportName);
		}

		//	Display Component Update
		const reportDisplayJson: object =
			<object>
			{
				interpolationData:
				{
					datasetGroupId: currentData.reportDefinition.datasetGroupId,
					externalReportType:
						currentData.reportDefinition.externalReportType,
					reportName: currentData.reportName,
					reportType: currentData.reportDefinition.reportType
				}
			};

		initialPromiseArray.push(
			this.updateDisplayComponent(displayComponentInstance.id,
				reportDisplayComponentName,
				reportDisplayJson));

		//	BasePage Update
		const basePageComponentDefinitionName: string =
			AppConstants.displayComponentTypes.basePage
				+ `.${reportDisplayComponentName}`;

		const basePageDisplayJson: object =
			<object>
			{
				interpolationData:
				{
					displayComponent: reportDisplayComponentName,
					pageButtonBar: AppConstants.empty,
					loadingPageTitle: 'Report',
					pageTitleTemplate: '${data.reportDefinition.reportName}',
					pageContextMenu: AppConstants.empty,
					pageUtilityMenu: basePageJsonData.interpolationData
						.pageUtilityMenu,
					pageInformationMenu: AppConstants.empty
				}
			};

		initialPromiseArray.push(
			this.updateDisplayComponent(basePageComponentDefinitionInstance.id,
				basePageComponentDefinitionName,
				basePageDisplayJson));

		await this.activityService.handleActivity(
			new Activity(
				new Promise<void>(
					async(resolve) =>
					{
						this.editInProgress = false;

						await Promise.all(
							initialPromiseArray);

						if (!reloadPage)
						{
							await this.ngOnInit();
						}

						this.loading = false;
						resolve();
					}),
				'<strong>Update Report</strong>',
				'<strong>Update A Report</strong>',
				`${currentData.reportName} was updated.`,
				`${currentData.reportName} was not updated.`));

		if (reloadPage)
		{
			this.navigateToDisplay(
				this.router,
				this.getRowSelectedInfo(
					reportDisplayComponentName,
					this.iconActionIds.navigateToEditReport));
		}
	}

	/**
	 * Initializes the layout.
	 *
	 * @returns {FormlyFieldConfig[]}
	 * @memberof BIReportComponent
	 */
	public initLayout(): FormlyFieldConfig[]
	{
		return	<FormlyFieldConfig[]>
			[
				<FormlyFieldConfig>
				{
					key: 'reportName',
					id: 'reportName',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions:
					{
						label: 'Title',
						tooltip: 'The name of the new report.',
						maxLength: 80,
						required: true,
						gridColumns: 12
					},
					validators:
					{
						noUnderscoreStart:
						{
							expression:
								this.validateNoUnderscoreStart.bind(this),
							message:
								'Report names cannot begin with an underscore.'
						}
					},
					asyncValidators:
					{
						uniqueReportName:
						{
							expression:
							this.validateUniqueReportName.bind(this),
							message: 'Report name already exists.'
						}
					}
				},
				{
					key: AppConstants.nestedRouteTypes.securityGroups,
					id: AppConstants.nestedRouteTypes.securityGroups,
					type: FormlyConstants.customControls.customMultiSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions:
					{
						label: 'Viewer(s)',
						tooltip: 'Set the Security Groups that will be'
							+ ' allowed to view the report.',
						options: this.securityGroupOptions,
						required: true,
						gridColumns: 12
					}
				},
				{
					key: AppConstants.commonProperties.ownershipSecurityGroupId,
					id: AppConstants.commonProperties.ownershipSecurityGroupId,
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions:
					{
						label: 'Owner(s)',
						tooltip: 'Set Additional Report Owner\'s'
							+ ' that will be allowed to edit'
							+ ' the report.',
						placeholder: 'Select an ownership group',
						showClear: true,
						required: true,
						gridColumns: 12,
						options: this.securityGroupOptions
					}
				},
				{
					key: AppConstants.commonProperties.datasetId,
					id: AppConstants.commonProperties.datasetId,
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions:
					{
						label: 'Dataset',
						tooltip: 'The dataset of the current report.',
						options:
							this.groupedSelectionOptions.map(
								(groupedOption: IGroupedDropdownOption) =>
								{
									const groupedIdOptions:
										IDropdownOption[] = [];
									groupedOption.items.forEach(
										(option: IDropdownOption) =>
										{
											groupedIdOptions.push(
												<IDropdownOption>
												{
													label: option.label,
													value: option.value.id
												});
										});
									groupedOption.items = groupedIdOptions;

									return groupedOption;
								}),
						group: true,
						required: true,
						gridColumns: 12
					}
				},
				{
					key: 'public',
					id: 'public',
					type: FormlyConstants.customControls.customInputSwitch,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions:
					{
						label: 'Publish',
						tooltip: 'If Public is enabled, then that means'
							+ ' the report is published and accessible to'
							+ ' the specified Report Viewers. If Public is'
							+ ' disabled, then only the Report Owner\'s will'
							+ ' be able to see the new report.',
						default: true,
						gridColumns: 6,
						change: (field: FormlyFieldConfig) =>
						{
							setTimeout(
								() =>
								{
									if (!this.viewMode
										&& this.editInProgress)
									{
										this.reportData.public =
											field.formControl.value;
										this.editReport();
									}
								});
						}
					}
				},
				{
					key: AppConstants.commonProperties.createdBy,
					id: AppConstants.commonProperties.createdBy,
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions:
					{
						label: 'Created By',
						maxLength: 80,
						required: true,
						gridColumns: 12
					}
				}
			];
	}

	/**
	 * A method that performs the rename Power BI
	 * report action.
	 *
	 * @param {string} newReportName
	 * Provided New Report Name.
	 *
	 * @async
	 * @memberof BIReportComponent
	 */
	public async renamePowerBIReport(
		newReportName: string)
	{
		const currentData: IReportDataDetails =
			this.reportData;

		await this.powerBiApiService
			.cloneExistingReport(
				this.powerBIReportMapping(
					currentData.reportDefinition),
				currentData.reportDefinition.groupId,
				newReportName,
				currentData.reportDefinition.datasetId,
				currentData.reportDefinition.groupId);

		await this.powerBiApiService
			.deleteReport(
				currentData.reportDefinition);
	}

	/**
	 * Returns a formatted string name for the display component based on the
	 * alphanumeric report name.
	 *
	 * @param {string} reportName
	 * Provided report name.
	 * @returns {string}
	 * The display component name suffix that this will be created under.
	 * @memberof BIReportComponent
	 */
	public getReportDisplayComponentName(
		reportName: string): string
	{
		return 'Report.PowerBi.'
			+ StringHelper.getCleanedValue(
				reportName,
				[
					AppConstants.commonRegex.alphaNumericOnly
				]);
	}

	/**
	 * Set the drawer button action.
	 *
	 * @memberof BIReportComponent
	 */
	public setDrawerButtonAction(): void
	{
		if (this.drawerAction ===
			AppConstants.viewTypes.edit)
		{
			this.editReport();
		}
		else
		{
			this.viewReport();
		}

		this.setDrawerButtonDefinition();
	}

	/**
	 * Set drawer button definition.
	 *
	 * @memberof BIReportComponent
	 */
	public setDrawerButtonDefinition(): void
	{
		this.drawerOptions =
			[
				{
					label: 'View',
					value: AppConstants.viewTypes.view,
					icon: 'pi pi-search',
					disabled: this.viewMode
				},
				{
					label: 'Edit',
					value: AppConstants.viewTypes.edit,
					icon: 'pi pi-pencil',
					disabled: !this.viewMode
				}
			];
	}
}