/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	Component,
	Input
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstanceDto
} from '@api/interfaces/entities/entity-instance.dto.interface';
import {
	NoteDirective
} from '@shared/directives/note.directive';

/* eslint-enable max-len */

@Component({
	selector: 'app-note',
	templateUrl: './note.component.html',
	styleUrls: [
		'./note.component.scss'
	]
})

/**
 * A component representing a context level note view.
 *
 * @export
 * @class NoteComponent
 * @extends {NoteDirective}
 * @implements {IDynamicComponent<Component, any>}
 */
export class NoteComponent
	extends NoteDirective
	implements IDynamicComponent<Component, any>
{
	/**
	 * Initializes a new instance of the common list component.
	 *
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance API service, used to get children.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type API service, used to get entity types.
	 * @memberof NoteComponent
	 */
	public constructor(
		public entityInstanceApiService: EntityInstanceApiService,
		public entityTypeApiService: EntityTypeApiService)
	{
		super(entityTypeApiService);
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof NoteCreateComponent
	 */
	@Input() public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the selected item to be displayed within the note view.
	 *
	 * @type {IEntityInstanceDto}
	 * @memberof NoteComponent
	 */
	@Input() public selectedItem: IEntityInstanceDto;

	/**
	 * Gets or sets the type group of the selected item to view.
	 *
	 * @type {string}
	 * @memberof NoteComponent
	 */
	@Input() public typeGroup: string;

	/**
	 * Gets or sets the identifier of the selected item to view.
	 *
	 * @type {number}
	 * @memberof NoteComponent
	 */
	@Input() public id: number;
}