/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 * A class representing static constant and read only values
 * for claim based business logic.
 *
 * @export
 * @class ClaimConstants
 */
export class ClaimConstants
{
	/**
	 * Gets or sets the set of claims
	 * entity types available in this module.
	 *
	 * @type {any}
	 * @memberof ClaimConstants
	 */
	public static readonly claimEntityTypes:
	{
		claim: string;
		noteClaim: string;
		claimPolicy: string;
	} =
	{
		claim: 'Claim',
		noteClaim: 'Note.Claim',
		claimPolicy: 'ClaimPolicy'
	};

	/**
	 * Gets or sets the set of claims
	 * entity type groups available in this module.
	 *
	 * @type {any}
	 * @memberof ClaimConstants
	 */
	public static readonly claimEntityTypeGroups:
	{
		claims: string;
		noteClaims: string;
		claimPolicies: string;
	} =
	{
		claims: 'Claims',
		noteClaims: 'Note.Claims',
		claimPolicies: 'ClaimPolicies'
	};

	/**
	 * Gets or sets the set of claims service provider entity type groups.
	 *
	 * @type {any}
	 * @memberof ClaimConstants
	 */
	public static readonly serviceProviderEntityTypeGroups:
	{
		insuranceCompany: string;
		adjustingCompany: string;
		vendorCompany: string;
	} =
	{
		insuranceCompany: 'Organization.InsuranceCompanies',
		adjustingCompany: 'Organization.IndependentAdjustingCompanies',
		vendorCompany: 'Organization.ClaimsVendors'
	};

	/**
	 * Gets or sets the set of claims financial entity type groups.
	 *
	 * @type {any}
	 * @memberof ClaimConstants
	 */
	public static readonly financialEntityTypeGroups:
	{
		ledgers: string;
		ledgerTransactionClaims: string;
	} =
	{
		ledgers: 'Ledgers',
		ledgerTransactionClaims: 'LedgerTransaction.Claims'
	};

	/**
	 * Gets or sets the available claims specific workflow actions.
	 *
	 * @type {any}
	 * @memberof ClaimConstants
	 */
	public static readonly workflowActions:
	{
		addExposure: string;
		adjustReserves: string;
		createClaim: string;
		verifyCoverage: string;
		manageExposureParties: string;
	} =
	{
		addExposure: 'AddExposure',
		adjustReserves: 'AdjustReserves',
		createClaim: 'CreateClaim',
		verifyCoverage: 'VerifyCoverage',
		manageExposureParties: 'ManageExposureParties'
	};
}