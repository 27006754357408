/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	debounceTime,
	distinctUntilChanged,
	Subject
} from 'rxjs';
import {
	Directive,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit
} from '@angular/core';
import {
	MouseEventConstants
} from '@shared/constants/mouse-event.constants';
import {
	WindowEventConstants
} from '@shared/constants/window-event.constants';

/* eslint-enable max-len */

@Directive({
	selector: '[TooltipDisplay]'
})

/**
 * A directive representing shared logic for a component displaying a set of
 * tooltips.
 *
 * @export
 * @class TooltipDisplayDirective
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
export class TooltipDisplayDirective implements OnInit, OnDestroy
{
	/**
	 * Gets or sets the array of ElementRef tooltip display components.
	 * This should be set in the after view init workflow action.
	 *
	 * @memberof TooltipDisplayDirective
	 */
	public tooltips: ElementRef[] = [];

	/**
	 * Gets or sets the observer of scroll height changes.
	 *
	 * @type {Subject<void>}
	 * @memberof TooltipDisplayDirective
	 */
	public scrollHeightChangedSubject:
		Subject<void> = new Subject<void>();

	/**
	 * Handles the scroll event sent from the window.
	 * This will hide any currently displayed tooltips.
	 *
	 * @memberof TooltipDisplayDirective
	 */
	@HostListener(
		WindowEventConstants.scrollEvent)
	public scroll(): void
	{
		this.scrollHeightChangedSubject.next();
	}

	/**
	 * Handles the on initialization event.
	 * This will set a debounce watcher to find whether or not tooltips
	 * should be hidden.
	 *
	 * @memberof TooltipDisplayDirective
	 */
	public ngOnInit(): void
	{
		this.scrollHeightChangedSubject.pipe(
			debounceTime(AppConstants.time.twentyFiveMilliseconds),
			distinctUntilChanged())
			.subscribe(
				() =>
				{
					this.tooltips.forEach(
						(tooltip: ElementRef) =>
						{
							tooltip?.nativeElement?.dispatchEvent(
								new Event(MouseEventConstants.mouseLeave));
						});
				});
	}

	/**
	 * Handles the on destroy event.
	 * This will complete any current observables.
	 *
	 * @memberof TooltipDisplayDirective
	 */
	public ngOnDestroy(): void
	{
		this.scrollHeightChangedSubject.complete();
	}

	/**
	 * This will handle the tap mobile only event on the tooltip icon
	 * and toggle the display of the tooltip.
	 *
	 * @param {Element} tooltip
	 * The tooltip to toggle.
	 * @memberof TooltipDisplayDirective
	 */
	public mobileTooltipToggle(
		tooltip: Element): void
	{
		tooltip.dispatchEvent(
			new Event(MouseEventConstants.mouseEnter));
	}

	/**
	 * This method will remove the auto focus click event attached to
	 * primeNg tooltips.
	 *
	 * @param {MouseEvent} event
	 * The click event to be captured and halted.
	 * @memberof TooltipDisplayDirective
	 */
	public preventDefault(
		event: MouseEvent): void
	{
		event.preventDefault();
		event.stopImmediatePropagation();
	}
}