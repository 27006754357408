/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CacheService
} from '@shared/services/cache.service';
import {
	catchError,
	concatAll,
	from,
	interval,
	lastValueFrom,
	map,
	mergeMap,
	Observable,
	of,
	Subscription,
	take,
	tap
} from 'rxjs';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	HttpClient,
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpHeaders,
	HttpInterceptor,
	HttpRequest,
	HttpResponse
} from '@angular/common/http';
import {
	Injectable
} from '@angular/core';
import {
	StorageMap
} from '@ngx-pwa/local-storage';

/**
 * A class representing the middleware logic to intercept and modify
 * API requests in regards to the cache and ETag validations.
 *
 * @export
 * @class AppCacheHttpInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class AppCacheHttpInterceptor implements HttpInterceptor
{
	/**
	 * Creates an instance of an AppCacheHttpInterceptor.
	 *
	 * @param {StorageMap} storageMap
	 * The local storage map used for ETag handling.
	 * @param {CacheService} cacheService
	 * The cache service used application wide.
	 * @param {HttpClient} httpClient
	 * The http client used to update the cache post modify.
	 * @memberof AppCacheHttpInterceptor
	 */
	public constructor(
		private readonly storageMap: StorageMap,
		private readonly cacheService: CacheService,
		private readonly httpClient: HttpClient)
	{
	}

	/**
	 * Gets the delay used to show a modified refresh message over
	 * the existing expected error message from the failed activity.
	 *
	 * @Type {number}
	 * @memberof AppCacheHttpInterceptor
	 */
	private readonly displayModifiedRefreshBannerDebounceDelay: number = 250;

	/**
	 * Intercepts and handles all XHR requests.
	 * This logic handles ETag validations and keeping the application
	 * cache in sync sitewide.
	 *
	 * @param {HttpRequest<any>} request
	 * The request to be sent.
	 * @param {HttpHandler} next
	 * The http handler for synchronizing the requests.
	 * @returns {Observable<HttpEvent<any>>}
	 * The return of event of this intercepted http action.
	 * @memberof AppCacheHttpInterceptor
	 */
	public intercept(
		request: HttpRequest<any>,
		next: HttpHandler): Observable<HttpEvent<any>>
	{
		// If caches are not available, insecure, or the controller
		// handles critical data, this interceptor is not used.
		if (this.cacheService.cachesAreNullOrInsecure() === true
			|| request.url.indexOf(
				`/${AppConstants.apiControllers.authenticate}`) !== -1)
		{
			return next.handle(request);
		}

		switch (request.method)
		{
			case AppConstants.httpRequestTypes.get:
			case AppConstants.httpRequestTypes.patch:
			case AppConstants.httpRequestTypes.post:
			case AppConstants.httpRequestTypes.put:
				return this.storageMap.get(request.url)
					.pipe(
						mergeMap((cachedETagValue: string) =>
							request.method ===
								AppConstants.httpRequestTypes.get
								? this.interceptGet(
									request,
									next,
									cachedETagValue)
								: this.interceptModify(
									request,
									next,
									cachedETagValue)));
			case AppConstants.httpRequestTypes.delete:
				return this.interceptDelete(
					request,
					next);
			default:
				return next.handle(request);
		}
	}

	/**
	 * Intercepts and handles all get XHR requests.
	 * This logic handles ETag validations and keeping the application
	 * cache in sync sitewide for a get request.
	 *
	 * @param {HttpRequest<any>} request
	 * The request to be sent.
	 * @param {HttpHandler} next
	 * The http handler for synchronizing the requests.
	 * @param {string} cachedETagValue
	 * The current local stored etag value for this request.
	 * @returns {Observable<HttpEvent<any>>}
	 * The return of event of this intercepted http action.
	 * @memberof AppCacheHttpInterceptor
	 */
	public interceptGet(
		request: HttpRequest<any>,
		next: HttpHandler,
		cachedETagValue: string): Observable<HttpEvent<any>>
	{
		const requestWithETag: HttpRequest<any> =
			this.getRequestWithETag(
				request,
				cachedETagValue,
				AppConstants.webApi.ifNoneMatch);

		return next.handle(requestWithETag)
			.pipe(
				tap(async(response: HttpEvent<any>) =>
				{
					await this.handleGetResponse(
						response,
						requestWithETag,
						cachedETagValue);
				}),
				catchError((error: HttpErrorResponse) =>
					this.handleGetError(
						error,
						requestWithETag,
						next)));
	}

	/**
	 * Intercepts and handles all put, post, and patch XHR requests.
	 * This logic handles ETag validations to ensure a modification or
	 * action is not being performed against an altered object and handles
	 * background syncs.
	 *
	 * @param {HttpRequest<any>} request
	 * The request to be sent.
	 * @param {HttpHandler} next
	 * The http handler for synchronizing the requests.
	 * @param {string} cachedETagValue
	 * The current local stored etag value for this request.
	 * @returns {Observable<HttpEvent<any>>}
	 * The return of event of this intercepted http action.
	 * @memberof AppCacheHttpInterceptor
	 */
	public interceptModify(
		request: HttpRequest<any>,
		next: HttpHandler,
		cachedETagValue: string): Observable<HttpEvent<any>>
	{
		const requestWithETag: HttpRequest<any> =
			this.getRequestWithETag(
				request,
				cachedETagValue,
				AppConstants.webApi.ifMatch);

		return next.handle(requestWithETag)
			.pipe(
				tap(async(response: HttpEvent<any>) =>
				{
					if (!(response instanceof HttpResponse)
						|| !await this.cacheService
							.isCachedRequest(
								requestWithETag))
					{
						return;
					}

					await this.resetAssociatedCaches(
						request);

					const queryOnlyGetHeader: string =
						requestWithETag.headers.get(
							AppConstants.httpHeaders.queryOnlyGet);

					// If there is no matching get by id, do not call
					// the update for this modified item.
					if (!AnyHelper.isNullOrWhitespace(queryOnlyGetHeader)
						&& queryOnlyGetHeader === 'true')
					{
						return;
					}

					await lastValueFrom(
						this.httpClient.get<any>(request.url));
				}),
				catchError((
					error: HttpErrorResponse) =>
					this.handleModifyError(
						error,
						requestWithETag)));
	}

	/**
	 * Intercepts and handles all delete XHR requests.
	 * This logic handles keeping the application cache in sync
	 * sitewide for a delete request.
	 *
	 * @param {HttpRequest<any>} request
	 * The request to be sent.
	 * @param {HttpHandler} next
	 * The http handler for synchronizing the requests.
	 * @returns {Observable<HttpEvent<any>>}
	 * The return of event of this intercepted http action.
	 * @memberof AppCacheHttpInterceptor
	 */
	public interceptDelete(
		request: HttpRequest<any>,
		next: HttpHandler): Observable<HttpEvent<any>>
	{
		return next.handle(request)
			.pipe(
				tap(async(response: HttpEvent<any>) =>
				{
					if (!(response instanceof HttpResponse))
					{
						return;
					}

					await this.resetAssociatedCaches(
						request);
				}));
	}

	/**
	 * This logic handles responses for a get http call and synchronizes
	 * the application cache with this data.
	 * If an ETag alters during a stale while revalidate session call in the
	 * cache service, this will display a banner that the displayed cache
	 * loaded data is not up to date.
	 * If a status alters during a stale while revalidate session call in the
	 * cache service to a 401-unauthorized, this will log out the user.
	 *
	 * @param {HttpEvent<any>} response
	 * The get response found in the get interceptor.
	 * @param {HttpRequest<any>} requestWithETag
	 * The request sent for this get response.
	 * @param {string} cachedETagValue
	 * The ETag value that currently exists in local storage matching this
	 * request.
	 * @memberof AppCacheHttpInterceptor
	 */
	private async handleGetResponse(
		response: HttpEvent<any>,
		requestWithETag: HttpRequest<any>,
		cachedETagValue: string): Promise<void>
	{
		if (!(response instanceof HttpResponse))
		{
			return null;
		}

		const promiseArray: Promise<boolean>[] =
			[
				this.cacheService.isCachedRequest(requestWithETag),
				this.cacheService.isFreshnessRequest(requestWithETag)
			];

		return Promise.all(
			promiseArray)
			.then(([ cachedRequest, freshnessRequest ]) =>
			{
				if (cachedRequest === false
					|| freshnessRequest === false)
				{
					return;
				}

				this.storageMap.set(
					requestWithETag.url,
					response.headers.get(AppConstants.webApi.eTag))
					.subscribe(() => {
						// No implementation.
					});

				const subscription: Subscription = from([2.5, 5, 10, 30])
					.pipe(
						map((intervalTime: number) =>
							interval(intervalTime * 1000)
								.pipe(take(1))),
						concatAll())
					.subscribe(() =>
					{
						this.cacheService.getCachedETag(requestWithETag)
							.then((eTagValue: string) =>
							{
								// If eTagValue is now null, then we
								// are mid-clear due to an inflight crud
								// operation. No refresh is required.
								if (!AnyHelper.isNull(
									eTagValue)
									&& !AnyHelper.isNull(
										cachedETagValue)
									&& cachedETagValue ===
										response.headers.get(
											AppConstants.webApi.eTag)
									&& cachedETagValue !== eTagValue)
								{
									subscription.unsubscribe();

									this.storageMap.set(
										requestWithETag.url,
										eTagValue)
										.subscribe(() =>
										{
											this.displayAlteredData();
										});
								}
							});
					});

				this.cacheService.currentSubscriptions.push(subscription);
			});
	}

	/**
	 * This logic handles error http responses for a get http call and if it
	 * displays as not modified via an etag match, this will load the data from
	 * cache.
	 *
	 * @param {HttpEvent<any>} response
	 * The get error response found in the get interceptor.
	 * @param {HttpRequest<any>} requestWithETag
	 * The request sent for this get response.
	 * @param {HttpHandler} next
	 * The http handler for synchronizing the requests.
	 * @returns {Observable<HttpEvent<any>>}
	 * The observable http event that will either load fresh data
	 * or load from the cache if that data exists on a not modified
	 * return.
	 * @throws {HttpErrorResponse}
	 * The original error will be thrown if it is created other than
	 * from a not modified response.
	 * @memberof AppCacheHttpInterceptor
	 */
	private handleGetError(
		error: HttpErrorResponse,
		requestWithETag: HttpRequest<any>,
		next: HttpHandler): Observable<HttpEvent<any>>
	{
		if (error.status !== AppConstants.httpStatusCodes.notModified)
		{
			throw error;
		}

		return from(this.cacheService
			.getCachedResponse(requestWithETag))
			.pipe(
				mergeMap((cachedResponse: HttpResponse<any>) =>
					AnyHelper.isNull(cachedResponse)
						? this.getRefreshRequest(
							requestWithETag,
							next)
						: of(cachedResponse.clone())));
	}

	/**
	 * This logic handles error http responses for a get http call and if it
	 * displays as not modified via an etag match, this will clear the cache
	 * data for this item to force a reload and display a message to the
	 * user that they need to refresh before performing this modify action.
	 *
	 * @param {HttpEvent<any>} response
	 * The error response found in the put, post, or patch interceptor.
	 * @param {HttpRequest<any>} requestWithETag
	 * The request sent for this modify response.
	 * @throws {HttpErrorResponse}
	 * The original error will be thrown after completing conditional
	 * cache clean up.
	 * @memberof AppCacheHttpInterceptor
	 */
	private handleModifyError(
		error: HttpErrorResponse,
		requestWithETag: HttpRequest<any>): Observable<HttpEvent<any>>
	{
		if (error.status !== AppConstants.httpStatusCodes.preconditionFailed)
		{
			throw error;
		}

		// Ensure we force a reload on refresh.
		this.cacheService.clearExistingResponse(
			requestWithETag);

		setTimeout(
			() =>
			{
				this.displayModifiedData();
			},
			this.displayModifiedRefreshBannerDebounceDelay);

		throw error;
	}

	/**
	 * This logic handles clearing cache items on a modify.
	 * If the request is in the performance set, all caches associated
	 * with that including queries will be cleared.
	 * If the request is in the freshness set, just the altered item will
	 * be cleared.
	 *
	 * @param {HttpRequest<any>} request
	 * The request that requires data in the cache to be cleared.
	 * @memberof AppCacheHttpInterceptor
	 */
	private async resetAssociatedCaches(
		request: HttpRequest<any>): Promise<void>
	{
		const matchingPerformanceCacheIdentifier: string =
			await this.cacheService
				.getRequestConfigurationIdentifier(
					request);

		// If this is a performance modify, then we need to
		// force reloads on all associated queries.
		if (!AnyHelper.isNullOrWhitespace(
			matchingPerformanceCacheIdentifier))
		{
			await this.cacheService
				.clearExistingStartsWithResponses(
					matchingPerformanceCacheIdentifier);
		}
		else
		{
			// Otherwise clear only the existing item and allow
			// freshness requests to handle updated queries.
			await this.cacheService
				.clearExistingResponse(
					request);
		}
	}

	/**
	 * Displays an informational banner when the stale while revalidate
	 * workflow returns an updated entity that does not match what
	 * we have cached and displayed.
	 *
	 * @memberof AppCacheHttpInterceptor
	 */
	private displayAlteredData(): void
	{
		this.cacheService.clearAllSubscriptions();
		EventHelper.dispatchBannerEvent(
			'Displaying outdated data.',
			'Please <a onclick="window.location.reload(true);" '
				+ 'class="text-link banner-text-link standard hover">'
				+ 'refresh</a> to update to the latest.',
			AppConstants.activityStatus.info);
	}

	/**
	 * Displays an informational banner when the stale while revalidate
	 * workflow returns an updated entity that does not match what
	 * we have cached and displayed.
	 *
	 * @memberof AppCacheHttpInterceptor
	 */
	private displayModifiedData(): void
	{
		EventHelper.dispatchBannerEvent(
			'Updating or performing an action on outdated data.',
			'Please <a onclick="window.location.reload(true);" '
				+ 'class="text-link banner-text-link standard-hover">'
				+ 'refresh</a> to update to the latest.',
			AppConstants.activityStatus.info);
	}

	/**
	 * Creates and clones a request with an attached etag value and a
	 * match type depending on the request type.
	 *
	 * @param {HttpRequest<any>} request
	 * The request to be sent.
	 * @param {string} cachedETagValue
	 * The current local stored etag value for this request.
	 * @param {string} eTagMatchIdentifier
	 * The if match or if none match identifier to attach to this request.
	 * @returns {HttpRequest<any>}
	 * A request holding conditional eTag logic for gets or modifies.
	 * @memberof AppCacheHttpInterceptor
	 */
	private getRequestWithETag(
		request: HttpRequest<any>,
		cachedETagValue: string,
		eTagMatchIdentifier: string): HttpRequest<any>
	{
		const headerETagValue: string =
			cachedETagValue || AppConstants.empty;

		const headers: HttpHeaders =
			request
				.headers
				.append(
					AppConstants.webApi.eTag,
					headerETagValue)
				.append(
					eTagMatchIdentifier,
					[headerETagValue]);

		return request.clone(
			{
				headers: headers
			});
	}

	/**
	 * Alters and returns the existing requests without an etag value
	 * to request new data. This is called when cached data is no longer
	 * available due to a service worker cache timeout.
	 *
	 * @param {HttpRequest<any>} currentRequest
	 * The request to be altered and returned.
	 * @param {HttpHandler} next
	 * The http handler for synchronizing the requests.
	 * @returns {Observable<HttpEvent<any>>}
	 * The updated request with the etag idenitifiers stripped forcing a fresh
	 * data load.
	 * @memberof AppCacheHttpInterceptor
	 */
	private getRefreshRequest(
		currentRequest: HttpRequest<any>,
		next: HttpHandler): Observable<HttpEvent<any>>
	{
		const errorHeaders: HttpHeaders =
			currentRequest
				.headers
				.delete(
					AppConstants.webApi.eTag)
				.delete(
					AppConstants.webApi.ifNoneMatch);

		const refreshRequest =
			currentRequest.clone(
				{
					headers: errorHeaders
				});

		// Call again for fresh data
		return next.handle(refreshRequest);
	}
}