/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	DateTimeUnit
} from 'luxon';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	PaymentBatchConstants
} from '@shared/constants/payment-batch-constants';
import {
	Router
} from '@angular/router';
import {
	SessionService
} from '@shared/services/session.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/* eslint-enable max-len */

@Component({
	selector: 'payment-batch-create',
	templateUrl: './payment-batch-create.component.html'
})

/**
 * A component representing a wizard step for creating a payment batch.
 *
 * @export
 * @class PaymentBatchCreateComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class PaymentBatchCreateComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the create payment batch component.
	 *
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes on entity creation.
	 * @param {EntityService} entityService
	 * The entity service used to lookup entity modules upon creation.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service used in this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @param {SessionService} sessionService
	 * The session service used in this component.
	 * @memberof PaymentBatchCreateComponent
	 */
	public constructor(
		public router: Router,
		public activityService: ActivityService,
		public moduleService: ModuleService,
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<
	 * 	DynamicWizardComponent,
	 * 	IWizardContext>}
	 * @memberof PaymentBatchCreateComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the formly layout used in implementing components.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof PaymentBatchCreateComponent
	 */
	public staticFormlyLayout: FormlyFieldConfig[] = [];

	/**
	 * Gets or sets the entity type to be created.
	 *
	 * @type {IEntityType}
	 * @memberof PaymentBatchCreateComponent
	 */
	public entityCreationType: IEntityType;

	/**
	 * Gets or sets a string representing the parent type group.
	 *
	 * @type {string}
	 * @memberof PaymentBatchCreateComponent
	 */
	private parentTypeGroup: string;

	/**
	 * Gets or sets a number representing the parent id.
	 *
	 * @type {number}
	 * @memberof PaymentBatchCreateComponent
	 */
	private parentId: number;

	/**
	 * Gets or sets the context active menu item current data collected on the
	 * dynamic wizard steps.
	 *
	 * @type {any}
	 * @memberof PaymentBatchCreateComponent
	 */
	private currentData: any;

	/**
	 * Gets or sets the payment batch id.
	 *
	 * @type {number}
	 * @memberof PaymentBatchCreateComponent
	 */
	private paymentBatchId: number;

	/**
	 * Implements the on initialization interface.
	 *
	 * @async
	 * @memberof PaymentBatchCreateComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.currentData =
			this.context.source.activeMenuItem.currentData;

		this.setParentEntityInformation();

		this.context.source
			.addToNext(
				this.create.bind(this));

		await this.performPostInitActions();

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * Handles the validity changed event sent from the child dynamic
	 * formly component. This will update the validity of the form for
	 * action buttons.
	 *
	 * @async
	 * @param {boolean} isValid
	 * The validity of the current displayed step data set.
	 * @memberof PaymentBatchCreateComponent
	 */
	public async validityChanged(
		isValid: boolean): Promise<void>
	{
		this.context.source.validStepChanged(isValid);
	}

	/**
	 * This will send the creation event and navigate to the new
	 * entity.
	 *
	 * @async
	 * @memberof PaymentBatchCreateComponent
	 */
	public async create(): Promise<void>
	{
		this.entityCreationType =
			await this.entityTypeApiService
				.getSingleQueryResult(
					`Name eq '${this.currentData.data.entityType}'`,
					AppConstants.empty);

		this.paymentBatchId =
			await this.createEntity();

		await this.navigateToPaymentBatch();
	}

	/**
	 * This will send the entity creation event.
	 *
	 * @async
	 * @returns {Promise<number>}
	 * The id of the newly created entity.
	 * @memberof PaymentBatchCreateComponent
	 */
	private async createEntity(): Promise<number>
	{
		setTimeout(
			() =>
			{
				this.context.source.wizardStepLoading = true;
			});

		const paymentBatch: IEntityInstance =
			<IEntityInstance>
			{
				id: 0,
				entityType: this.entityCreationType.name,
				versionNumber: null,
				data: {}
			};

		const displayName: string =
			StringHelper.beforeCapitalSpaces(
				this.entityCreationType.name
					.replace(
						AppConstants.characters.period,
						AppConstants.empty));

		const newEntityId: number =
			await this.activityService.handleActivity<number>(
				new Activity<number>(
					this.createEntityInstance(
						paymentBatch),
					'<strong>Creating Payment Batch</strong>',
					'<strong>Payment Batch created</strong>',
					`${displayName} has been created.`,
					`${displayName} has not been created.`));

		return newEntityId;
	}

	/**
	 * Creates an entity instance and all entity relationships.
	 *
	 * @async
	 * @param {IEntityInstance} entityInstanceData
	 *  The entity instance data to be saved.
	 * @returns {Promise<number>}
	 * The id of the newly created entity.
	 * @memberof PaymentBatchCreateComponent
	 */
	private async createEntityInstance(
		entityInstanceData: IEntityInstance): Promise<number>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup =
				this.entityCreationType.group;

		const createdEntityId: number =
			await this.entityInstanceApiService
				.createEntityInstance(
					entityInstanceData,
					this.parentTypeGroup,
					this.parentId,
					{
						batchDate:
							this.currentData.data.batchDate,
						countOfChecksControl:
							this.currentData.data.countOfChecksControl,
						sumOfChecksControl:
							this.currentData.data.sumOfChecksControl,
					});

		return createdEntityId;
	}

	/**
	 * This will navigate to the payment batch using the entity id provided.
	 *
	 * @async
	 * @memberof PaymentBatchCreateComponent
	 */
	private async navigateToPaymentBatch(): Promise<void>
	{
		this.moduleService.name =
			await this.entityService.getContextMenuModule(
				this.entityCreationType.name);

		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.context.source.storeData(
			this.currentData);

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				this.entityCreationType.group,
				AppConstants.viewTypes.edit,
				this.paymentBatchId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}

	/**
	 * Sets the parent entity information.
	 *
	 * @memberof PaymentBatchCreateComponent
	 */
	private setParentEntityInformation(): void
	{
		this.parentTypeGroup =
			this.currentData.data.parentEntityType.group;
		this.parentId =
			this.currentData.data.parentId;
	}

	/**
	 * Handles the post initialization action.
	 * This will create the static formly layout.
	 *
	 * @async
	 * @memberof PaymentBatchCreateComponent
	 */
	private async performPostInitActions(): Promise<void>
	{
		this.context.source.addOrUpdateStepData(
			{
				batchDate:
					DateHelper
						.getSystemDateTime()
						.startOf(
							<DateTimeUnit>DateHelper.timeUnits.day),
				countOfChecksControl: 0,
				sumOfChecksControl: 0
			});

		await this.entityService.setStoredVariables();

		const matchingEntityTypes: IDropdownOption[] =
			this.entityService
				.entityTypes
				.filter(
					(entityType: IEntityType) =>
						entityType.name.indexOf(
							PaymentBatchConstants.paymentBatchIdentifiers
								.entityTypePrefix) === 0)
				.map(
					(entityType: IEntityType) =>
						<IDropdownOption>
						{
							label: new EntityType(entityType).displayName,
							value: entityType.name
						});

		this.staticFormlyLayout =
			<FormlyFieldConfig[]>
			[
				{
					key: 'data.entityType',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Type',
						showClear: true,
						required: true,
						placeholder: AppConstants.placeholders.selectAnOption,
						options: matchingEntityTypes
					}
				},
				{
					key: 'data.batchDate',
					type: FormlyConstants.customControls.customCalendar,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Batch Date',
						showClear: true,
						required: true
					}
				},
				{
					key: 'data.countOfChecksControl',
					type: FormlyConstants.customControls.customInputNumber,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Count of Checks Control',
						min: 0,
						required: true
					}
				},
				{
					key: 'data.sumOfChecksControl',
					type: FormlyConstants.customControls.customInputNumber,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Sum of Checks Control',
						min: 0,
						useCurrency: true,
						required: true
					}
				}
			];
	}
}