/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	EntityDefinition
} from '@shared/implementations/entities/entity-definition';
import {
	get,
	has,
	isArray
} from 'lodash-es';
import {
	IDifferenceDefinition
} from '@shared/interfaces/application-objects/difference-definition.interface';
import {
	IMappedDifferenceDefinition
} from '@shared/interfaces/application-objects/mapped-difference-display-definition.interface';
import {
	JsonSchemaHelper
} from '@shared/helpers/json-schema.helper';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-differences-display',
	templateUrl: './differences-display.component.html',
	styleUrls: [
		'./differences-display.component.scss'
	]
})

/**
 * A component representing an instance of the differences display component.
 *
 * @export
 * @class DifferencesDisplayComponent
 * @implements {OnInit}
 */
export class DifferencesDisplayComponent implements OnInit
{
	/** Creates a new instance of the differences display component.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * Gets or sets the site layout service used for layout based displays
	 * in this component.
	 * @memberof DifferencesDisplayComponent
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService)
	{
	}

	/**
	 * Gets or sets the friendly object name that will be defined as the first
	 * level data item.
	 *
	 * @type {string}
	 * @memberof DifferencesDisplayComponent
	 */
	@Input() public objectName: string;

	/**
	 * Gets or sets the initial object used to check for differences.
	 *
	 * @type {any}
	 * @memberof DifferencesDisplayComponent
	 */
	@Input() public initialObject: any;

	/**
	 * Gets or sets the comparison object used to check for differences.
	 *
	 * @type {any}
	 * @memberof DifferencesDisplayComponent
	 */
	@Input() public comparisonObject: any;

	/**
	 * Gets or sets the excluded difference properties. If sent as a partial
	 * all instances that contain this will be removed otherwise exact
	 * property location matches will be excluded.
	 *
	 * @type {string[]}
	 * @memberof DifferencesDisplayComponent
	 */
	@Input() public excludedDifferenceProperties: string[] = [];

	/**
	 * Gets or sets the set of differences to be displayed in this component,
	 * if this value is not sent the differences will be looked up and set
	 * during component initialization based on the sent objects. The allows
	 * for overrides on what the base difference logic will find.
	 *
	 * @type {IDifferenceDefinition[]}
	 * @memberof DifferencesDisplayComponent
	 */
	@Input() public differences: IDifferenceDefinition[];

	/**
	 * Gets or sets the entity definition that is used for schema based display
	 * definitions.
	 *
	 * @type {EntityDefinition}
	 * @memberof DifferencesDisplayComponent
	 */
	 @Input() public entityDefinition: EntityDefinition;

	/**
	 * Gets or sets the set of mapped differences to be displayed in this
	 * component. This value is built from the set of differences and used
	 * as a view model in this component.
	 *
	 * @type {IMappedDifferenceDefinition[]}
	 * @memberof DifferencesDisplayComponent
	 */
	public mappedDifferences: IMappedDifferenceDefinition[];

	/**
	 * Implements the on initialization interface.
	 * This method will create differences if not sent and create the view
	 * model of mapped differences.
	 *
	 * @memberof DifferencesDisplayComponent
	 */
	public ngOnInit(): void
	{
		if (AnyHelper.isNull(this.differences))
		{
			this.differences =
				ObjectHelper.getBusinessLogicDifferences(
					this.objectName,
					this.initialObject,
					this.comparisonObject,
					this.excludedDifferenceProperties);
		}

		this.mapDifferences();
	}

	/**
	 * Given a set of difference definitions, this will split this into a
	 * tree structure that allows display of these differences in this
	 * component.
	 *
	 * @memberof DifferencesDisplayComponent
	 */
	public mapDifferences(): void
	{
		const mappedDifferences: IMappedDifferenceDefinition[] =
			this.differences.map(
				(difference: IDifferenceDefinition) =>
					this.getMappedDifference(
						difference));

		this.mappedDifferences =
			this.mapDifferenceLevel(
				mappedDifferences,
				this.initialObject,
				this.comparisonObject,
				this.entityDefinition.dereferencedDataProperties);
	}

	/**
	 * Given an object and a set of mapped differences, this will split
	 * the mapped differences into a by level tree structure for display
	 * in this component.
	 *
	 * @param {IMappedDifferenceDefinition[]} mappedDifferenceDefinitions
	 * The set of difference definitions that should be mapped at this level.
	 * @param {any} initialObject
	 * The initial object for comparison at this level.
	 * @param {any} comparisonObject
	 * The comparison object for comparison at this level.
	 * @param {any} schemaDefinition
	 * The schema definition that exists for this difference level.
	 * @returns {IMappedDifferenceDefinition[]}
	 * A full set of hierarchy based differences for this mapped difference
	 * level.
	 * @memberof DifferencesDisplayComponent
	 */
	public mapDifferenceLevel(
		mappedDifferenceDefinitions: IMappedDifferenceDefinition[],
		initialObject: any,
		comparisonObject: any,
		schemaDefinition: any = null):
		IMappedDifferenceDefinition[]
	{
		const rootLevelDifferences: IMappedDifferenceDefinition[] =
			mappedDifferenceDefinitions
				.filter(
					(mappedDifference: IMappedDifferenceDefinition) =>
						mappedDifference.length === 1
							&& mappedDifferenceDefinitions.filter(
								(nestedDifference:
									IMappedDifferenceDefinition) =>
									nestedDifference.parts[0] ===
										mappedDifference.parts[0])
								.length === 1);
		const mappedDifferences: IMappedDifferenceDefinition[] =
			mappedDifferenceDefinitions
				.filter(
					(mappedDifference: IMappedDifferenceDefinition) =>
						!rootLevelDifferences.includes(mappedDifference));
		const firstLevelParts: string[] =
			[
				...new Set(
					mappedDifferences
						.map(
							(mappedDifference: IMappedDifferenceDefinition) =>
								mappedDifference.parts[0]))
			];
		const finalDifferences: IMappedDifferenceDefinition[] =
			rootLevelDifferences;

		for (const firstLevelPart of firstLevelParts)
		{
			const firstLevelDifferences: IMappedDifferenceDefinition[] =
				mappedDifferences
					.filter(
						(mappedDifference: IMappedDifferenceDefinition) =>
							mappedDifference.length === 1
								&& mappedDifference.parts[0] ===
									firstLevelPart);
			const firstLevelDifference: IMappedDifferenceDefinition =
				firstLevelDifferences.length > 0
					? firstLevelDifferences[0]
					: null;
			const nestedDifferences: IMappedDifferenceDefinition[] =
				mappedDifferences
					.filter(
						(mappedDifference:
							IMappedDifferenceDefinition) =>
							mappedDifference.parts.length > 1
								&& mappedDifference.parts[0] ===
									firstLevelPart);

			const mappedValues: {
				initialLevelValue: any;
				comparisonLevelValue: any;
			} = this.mapParentValues(
				initialObject,
				comparisonObject,
				firstLevelPart);

			let newModelDisplayDefinition: any = schemaDefinition;
			if (firstLevelPart !== this.objectName)
			{
				newModelDisplayDefinition =
					this.getSchemaDefinition(
						firstLevelPart,
						schemaDefinition,
						mappedValues.comparisonLevelValue);
			}

			finalDifferences.push(
				<IMappedDifferenceDefinition>
				{
					key: firstLevelPart,
					length: 1,
					parts: [firstLevelPart],
					difference:
						firstLevelDifference?.difference,
					originalParentValue:
						mappedValues.initialLevelValue,
					updatedParentValue:
						mappedValues.comparisonLevelValue,
					schemaDefinition:
						newModelDisplayDefinition,
					nestedDifferences:
						this.mapDifferenceLevel(
							nestedDifferences
								.map(
									(mappedDifference:
										IMappedDifferenceDefinition) =>
									{
										mappedDifference.difference.key =
											mappedDifference.key.replace(
												`${firstLevelPart}.`,
												AppConstants.empty);

										const childDifference:
											IMappedDifferenceDefinition =
											this.getMappedDifference(
												mappedDifference.difference,
												mappedDifference);

										const newKey: string =
											childDifference.difference.key;
										if (newKey.split(
											AppConstants.characters.period)
											.length === 1)
										{
											childDifference
												.schemaDefinition =
												this.getSchemaDefinition(
													newKey,
													newModelDisplayDefinition,
													mappedValues
														.comparisonLevelValue);
										}

										return childDifference;
									}),
							mappedValues.initialLevelValue,
							mappedValues.comparisonLevelValue,
							newModelDisplayDefinition)
				});
		}

		return finalDifferences;
	}

	/**
	 * Given a difference definition and if this is a remap, an initial
	 * mapped difference definition, this will create and return a mapped
	 * difference definition to be used for display in this component.
	 *
	 * @param {IDifferenceDefinition} difference
	 * The difference definition to be converted into a mapped difference
	 * definition.
	 * @param {IMappedDifferenceDefinition} initialMappedDifference
	 * If sent this will be the current mapped difference for this difference
	 * definition. This value defaults to null for use in the initial mapped
	 * difference definition creation.
	 * @returns {IMappedDifferenceDefinition}
	 * A mapped difference definition defining the difference definition or
	 * the property level of the differences nested below it.
	 * @memberof DifferencesDisplayComponent
	 */
	 private getMappedDifference(
		difference: IDifferenceDefinition,
		initialMappedDifference: IMappedDifferenceDefinition = null):
		IMappedDifferenceDefinition
	{
		const splitKeys: string[] =
			difference.key.split(
				AppConstants.characters.period);

		// If this is the initial difference creation,
		// Remove structural properties that the user does not see.
		if (AnyHelper.isNull(initialMappedDifference))
		{
			difference.key =
				difference.key
					.replace(
						`${this.objectName}.`,
						AppConstants.empty)
					.replace(
						AppConstants.nestedDataIdentifier,
						AppConstants.empty)
					.replace(
						`${AppConstants.commonProperties.characteristics}.`,
						AppConstants.empty);
		}

		return <IMappedDifferenceDefinition>
			{
				key: difference.key,
				length: splitKeys.length,
				parts: splitKeys,
				difference: difference,
				nestedDifferences: []
			};
	}

	/**
	 * Given a key and current schema definition value, this will find the
	 * schema definition associated to this item. If this is an item type
	 * based definition such as from an any of, this will get the schema
	 * definition matching the sent difference object type.
	 *
	 * @param {string} nestedSchemaKey
	 * The key to find in this sent schema definition.
	 * @param {any} currentSchemaDefinition
	 * The current schema definition to be searched.
	 * @param {any} differenceObject
	 * The current object value that holds this schema key.
	 * @returns {any}
	 * The schema definition matching found via the nested schema key.
	 * @memberof DifferencesDisplayComponent
	 */
	private getSchemaDefinition(
		nestedSchemaKey: string,
		currentSchemaDefinition: any,
		differenceObject: any): any
	{
		// Handle indexed by type lookups.
		if (!isNaN(parseInt(nestedSchemaKey, AppConstants.parseRadix)))
		{
			const matchingArrayItem: any =
				JsonSchemaHelper.getArrayItemDefinition(
					currentSchemaDefinition,
					differenceObject.type);

			return matchingArrayItem;
		}

		// Catch natural level definitions.
		const propertyValue: any =
			JsonSchemaHelper.getSchemaDefinition(
				currentSchemaDefinition,
				nestedSchemaKey);

		return propertyValue;
	}

	/**
	 * Given a key that has been cleaned for display, ensure we can find
	 * the value and return the mapped property.
	 *
	 * @param {any} objectValue
	 * The object value to get a decorated property for.
	 * @param {string} key
	 * The key to get a property value for.
	 * @returns {any}
	 * The matching property value regardles of cleaned key values.
	 * @memberof DifferencesDisplayComponent
	 */
	private getCleanedProperty(
		objectValue: any,
		key: string): any
	{
		switch (true)
		{
			case has(
				objectValue,
				`${AppConstants.commonProperties.characteristics}.${key}`):
				return get(
					objectValue,
					`${AppConstants.commonProperties.characteristics}.${key}`);
			case has(
				objectValue,
				`${AppConstants.nestedDataIdentifier}${key}`):
				return get(
					objectValue,
					`${AppConstants.nestedDataIdentifier}${key}`);
			default:
				return get(objectValue, key);
		}
	}

	/**
	 * Given an initial and comparison object, this will map and return the
	 * parent values based on the sent key part.
	 *
	 * @param {any} initialObject
	 * The initial object to search for a matching key property.
	 * @param {any} comparisonObject
	 * The comparison object to search for a matching key property.
	 * @param {string} keyPart
	 * The key part to map from the initial and comparison objects.
	 * @returns { initialLevelValue: any; comparisonLevelValue: any }
	 * A mapped object holding the initial and comparison level values for the
	 * sent key part.
	 * @memberof DifferencesDisplayComponent
	 */
	private mapParentValues(
		initialObject: any,
		comparisonObject: any,
		keyPart: string): { initialLevelValue: any; comparisonLevelValue: any }
	{
		let initialLevelValue: any = initialObject;
		let comparisonLevelValue: any = comparisonObject;
		if (keyPart !== this.objectName)
		{
			let updatedArrayItem: any;
			if (isArray(comparisonLevelValue))
			{
				// Duplicate the key map logic in the object helper which
				// is based on a side by side array comparisons.
				const sortedComparisonArray: any[] =
					ObjectHelper.sortBusinessLogicArray(
						comparisonLevelValue,
						ObjectHelper.sortBusinessLogicArray(
							initialLevelValue));

				updatedArrayItem =
					this.getCleanedProperty(
						sortedComparisonArray, keyPart);
			}

			if (isNaN(
				parseInt(
					keyPart,
					AppConstants.parseRadix))
				|| AnyHelper.isNull(
					updatedArrayItem?.resourceIdentifier))
			{
				initialLevelValue =
					this.getCleanedProperty(
						initialLevelValue, keyPart);
				comparisonLevelValue =
					this.getCleanedProperty(
						comparisonLevelValue, keyPart);
			}
			else
			{
				const updatedResourceIdentifier =
					updatedArrayItem[
						AppConstants.commonProperties.resourceIdentifier];

				initialLevelValue =
					initialLevelValue.find(
						(item: any) =>
							item.resourceIdentifier ===
								updatedResourceIdentifier);
				comparisonLevelValue =
					comparisonLevelValue.find(
						(item: any) =>
							item.resourceIdentifier ===
								updatedResourceIdentifier);
			}
		}

		return {
			initialLevelValue: initialLevelValue,
			comparisonLevelValue: comparisonLevelValue
		};
	}
}