/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/member-ordering */

import {
	ApiError
} from '@api/errors/api.error';
import {
	AppConfig
} from 'src/app/app.config';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	ElementRef,
	Injectable,
	OnInit,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup
} from '@angular/forms';
import {
	LoggerService
} from '@shared/services/logger.service';
import {
	LoginComponent
} from '@appComponents/login/login.component';
import {
	LoginMessageService
} from '@appComponents/login/services/login-message.service';
import {
	Router
} from '@angular/router';
import {
	SessionService
} from '@shared/services/session.service';
import {
	ShakeAnimation
} from '@shared/app-animations';

@Component({
	selector: 'app-login-dialog',
	templateUrl: './login-dialog.component.html',
	styleUrls: ['./login.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: [
		ShakeAnimation
	]
})

/**
 * A class representing an instance of the login dialog component.
 *
 * @export
 * @class LoginDialogComponent
 * @implements {OnInit}
 */
@Injectable()
export class LoginDialogComponent
implements OnInit
{
	/**
	 * Creates an instance of LoginDialogComponent.
	 *
	 * @param {FormBuilder} formBuilder
	 * The form builder for this component.
	 * @param {SessionService} sessionService
	 * The session service for this component.
	 * @param {Router} router
	 * The router for this component.
	 * @param {LoggerService} logger
	 * The logger service for this component.
	 * @param {LoginComponent} loginComponent
	 * The primary login component.
	 * @param {LoginMessageService} loginMessageService
	 * The login message service for this component.
	 * @memberof LoginDialogComponent
	 */
	public constructor(
		private readonly formBuilder: FormBuilder,
		private readonly sessionService: SessionService,
		private readonly router: Router,
		private readonly logger: LoggerService,
		private readonly loginComponent: LoginComponent,
		private readonly loginMessageService: LoginMessageService)
	{
	}

	/**
	 * Gets or sets the login form group.
	 *
	 * @public
	 * @type {FormGroup}
	 * @memberof LoginDialogComponent
	 */
	public loginForm: FormGroup;

	/**
	 * Gets or sets a value indicating whether a form submit is in progress.
	 *
	 * @public
	 * @type {boolean}
	 * @memberof LoginDialogComponent
	 */
	public submitInProgress: boolean = false;

	/**
	 * Gets the branding name to be displayed on the login dialog.
	 *
	 * @type {string}
	 * @memberof LoginDialogComponent
	 */
	public brandingName: string = AppConfig.settings.branding.name
		?? 'System';

	/**
	 * Gets a value indicating whether the login button is disabled.
	 *
	 * @type {boolean} A combination of status of whether the loginForm is valid
	 * and submitInProgress.
	 * @memberof LoginDialogComponent
	 */
	public get loginIsDisabled(): boolean
	{
		return !this.loginForm.valid || this.submitInProgress;
	}

	/**
	 * Gets the userName element.
	 *
	 * @protected
	 * @type {ElementRef}
	 * @memberof LoginDialogComponent
	 */
	@ViewChild('userName', { static: true })
	protected userNameElement: ElementRef;

	/**
	 * Gets the password element.
	 *
	 * @protected
	 * @type {ElementRef}
	 * @memberof LoginDialogComponent
	 */
	@ViewChild('password')
	protected passwordElement: ElementRef;

	/**
	 * Gets the user name.
	 *
	 * @type {string} The current user name.
	 * @memberof LoginDialogComponent
	 */
	protected get userName(): string
	{
		return this.loginForm.controls.userName.value;
	}

	/**
	 * Sets the user name.
	 *
	 * @type {string} The current user name.
	 * @memberof LoginDialogComponent
	 */
	 protected set userName(
		value: string)
	{
		this.loginForm.controls.userName.setValue(value);
	}

	/**
	 * Gets the password.
	 *
	 * @type {string} The current password.
	 * @memberof LoginDialogComponent
	 */
	protected get password(): string
	{
		return this.loginForm.controls.password.value;
	}

	/**
	 * Sets the password.
	 *
	 * @type {string} The current password.
	 * @memberof LoginDialogComponent
	 */
	protected set password(
		value: string)
	{
		this.loginForm.controls.password.setValue(value);
	}

	/**
	 * On initialization event.
	 * Configures the login dialog.
	 *
	 * @memberof LoginDialogComponent
	 */
	public ngOnInit(): void
	{
		this.loginForm = this.formBuilder.group(
			{
				'userName': new FormControl(''),
				'password': new FormControl('')
			});

		if (this.loginComponent.userName)
		{
			this.userName = this.loginComponent.userName;
		}

		this.userNameElement.nativeElement.focus();
	}

	/**
	 * Delegates to the LoginComponent to display the ResetUserComponent.
	 *
	 * @public
	 * @memberof LoginDialogComponent
	 */
	public displayResetUser(): void
	{
		this.loginComponent.displayResetUser(this.userName);
	}

	/**
	 * Attempts to login via the API.
	 *
	 * @public
	 * @returns {void}
	 * @memberof LoginDialogComponent
	 */
	public async login(): Promise<void>
	{
		try
		{
			this.submitInProgress = true;
			await this.sessionService.login(
				this.userName,
				this.password);

			this.password = '';
			this.loginMessageService.clear();

			if (this.sessionService.isMultiFactorEnabled
				&& !this.sessionService.isValid)
			{
				this.loginComponent.displayVerify();

				return;
			}

			this.router.navigate([AppConstants.route.dashboardPage]);
		}
		catch (exception)
		{
			const apiError = <ApiError>exception;
			this.logger.logWarning(
				`Login failure for user ${this.userName}.${apiError?.message}`);

			if (apiError)
			{
				this.loginComponent.addMessage(
					AppConstants.messageLevel.warn,
					apiError.status ===
						AppConstants.httpStatusCodes.unauthorized
						? AppConstants.loginStatus.invalid
						: AppConstants.loginStatus.failure,
					apiError.message);
			}

			// Reset password and provide focus for the user
			// To re-enter password
			this.password = AppConstants.empty;
			this.passwordElement.nativeElement.focus();

			return;
		}
		finally
		{
			this.submitInProgress = false;
		}
	}
}