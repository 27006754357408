/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	Component
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	FormControl
} from '@angular/forms';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	ISecurityGroup
} from '@shared/interfaces/security/security-group.interface';
import {
	ManageUserComponent
} from '@admin/components/security/users/manage-user-expand/manage-user-expand.component';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SecurityGroupApiService
} from '@api/services/security/security-group.api.service';
import {
	SecuritySessionApiService
} from '@api/services/security/security-session.api.service';
import {
	TableHelper
} from '@shared/helpers/table.helper';

/* eslint-enable max-len */

@Component({
	selector: 'app-manage-users',
	templateUrl: './manage-users.component.html',
	styleUrls: [
		'./manage-users.component.scss'
	]
})

/**
 * A component representing an instance of the security applications
 * component.
 *
 * @export
 * @class ManageUsersComponent
 */
export class ManageUsersComponent
	extends CommonTablePageDirective
{
	/**
	 * Initializes a new instance of the ManageUsersComponent class.
	 *
	 * @param {SecurityApplicationApiService} securityApplicationApiService
	 * The api service used to load security application data.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The api service used to load entity instance data.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof ManageUsersComponent
	 */
	public constructor(
		public securityGroupApiService: SecurityGroupApiService,
		public securitySessionApiService: SecuritySessionApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(resolver);
		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.users;
	}

	/**
	 * Gets or sets the table definitions for the common table view.
	 *
	 * @type {ICommonTable}
	 * @memberof ManageUsersComponent
	 */
	public manageUsersTableDefinitions: ICommonTable;

	/**
	 * Gets or sets the security groups.
	 *
	 * @type {ISecurityGroup[]}
	 * @memberof ManageUsersComponent
	 */
	public securityGroups: ISecurityGroup[];

	/**
	 * Gets or sets the filter criteria.
	 *
	 * @type {string}
	 * @memberof ManageUsersComponent
	 */
	public filterCriteria: string;

	/**
	 * Gets or sets the sort criteria.
	 *
	 * @type {string}
	 * @memberof ManageUsersComponent
	 */
	public sortCriteria: string;

	/**
	 * Gets or sets the sort field.
	 *
	 * @type {string}
	 * @memberof ManageUsersComponent
	 */
	public sortField: string;

	/**
	 * Gets or sets the sort order.
	 *
	 * @type {number}
	 * @memberof ManageUsersComponent
	 */
	public sortOrder: number;

	/**
	 * Sets up variables used in this admin page based table.
	 *
	 * @memberof ManageUsersComponent
	 */
	public setupPageVariables(): void
	{
		let displayOrder: number = 1;
		this.availableColumns =
			[
				{
					dataKey: 'id',
					columnHeader: 'Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'userName',
					columnHeader: 'Username',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'firstName',
					columnHeader: 'First Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'lastName',
					columnHeader: 'Last Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'email',
					columnHeader: 'Email',
					displayOrder: displayOrder
				}
			];
		this.selectedColumns = this.availableColumns;
	}

	/**
	 * Sets up the table definitions for a standard table
	 *
	 * @memberof ManageUsersComponent
	 */
	public setupTableDefinitions(): void
	{
		this.sortCriteria =
			this.sortCriteria
			?? `${AppConstants.commonProperties.userName}`
				+ ` ${AppConstants.sortDirections.ascending}`
				+ `, Id ${AppConstants.sortDirections.descending}`;

		this.sortField = this.sortField
			?? 'userName';
		this.sortOrder = this.sortOrder
			?? 1;

		this.manageUsersTableDefinitions = {
			scrollable: true,
			actions: {
				create: {
					layout: [
						{
							key: 'data.userName',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Username',
								disabled: false,
								required: true
							},
							asyncValidators: {
								uniqueUsername: {
									expression: (control: FormControl) =>
										this.uniqueUserName(control),
									message: 'Username not available.'
								}
							}
						},
						{
							key: 'data.firstName',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'First Name',
								disabled: false,
								required: true
							}
						},
						{
							key: 'data.lastName',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Last Name',
								disabled: false,
								required: true
							}
						},
						{
							key: 'data.email',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Email',
								disabled: false,
								required: true
							},
							validators: {
								validation: ['email'],
							}
						}
					],
					items: [
						{
							label: 'Create',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: () => this.createEntityInstance()
						}]
				},
				view: {
					component: ManageUserComponent,
					layout: [
						{
							key: 'data.userName',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Username',
								disabled: true
							}
						},
						{
							key: 'data.firstName',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'First Name',
								disabled: true
							}
						},
						{
							key: 'data.lastName',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Last Name',
								disabled: true
							}
						},
						{
							key: 'data.email',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Email',
								disabled: true
							},
							validators: {
								validation: ['email']
							}
						}
					],
					items: []
				},
				update: {
					component: ManageUserComponent,
					layout: [
						{
							key: 'data.firstName',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'First Name',
								required: true
							}
						},
						{
							key: 'data.lastName',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Last Name',
								required: true
							}
						},
						{
							key: 'data.email',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: 'Email',
								required: true,
								gridColumns: 12
							},
							validators: {
								validation: ['email']
							}
						}
					],
					definition: null,
					items: [
						{
							label: 'Save',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: () => this.updateEntityInstance()
						}]
				},
				delete: {
					items: [
						{
							label: 'Confirm',
							disabled: false,
							styleClass: AppConstants.cssClasses.pButtonDanger,
							command: () => this.deleteEntityInstance()
						}],
					deleteStatement: () => this.deleteStatement()
				}
			},
			tableTitle: AppConstants.securityGroups.users,
			objectSearch: {
				filter: !AnyHelper.isNullOrEmpty(this.filterCriteria)
					? `Keywords.Contains('${this.filterCriteria}') eq true`
					: AppConstants.empty,
				orderBy: this.sortCriteria,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: this.tableRowCount,
				sortField: this.sortField,
				sortOrder: this.sortOrder
			},
			dataSetup: async() =>
			{
				this.securityGroups = await this.securityGroupApiService
					.query(
						AppConstants.empty,
						AppConstants.empty);
			},
			apiPromise: async (objectSearch: IObjectSearch) =>
			{
				const entityInstances: object[] =
						await this.entityInstanceApiService
							.query(
								objectSearch.filter,
								objectSearch.orderBy,
								objectSearch.offset,
								objectSearch.limit);

				const users: object[] = [];

				entityInstances.forEach((entityInstance: IEntityInstance) =>
				{
					users.push(
						{
							id: entityInstance.id,
							userName: entityInstance.data.userName,
							firstName: entityInstance.data.firstName,
							lastName: entityInstance.data.lastName,
							email: entityInstance.data.email
						});
				});

				return users;
			},
			availableColumns: this.availableColumns,
			selectedColumns: this.selectedColumns,
			columnSelectionMode: this.columnSelectionMode,
			allowSortColumns: true,
			expandTitle: () =>
				TableHelper.getExpandTitle(
					this.commonTableContext,
					'User'),
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			rowCountChanged: (rowCount: number) =>
			{
				this.tableRowCount = rowCount;
				this.restoreTableDefinition();
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.manageUsersTableDefinitions.selectedColumns =
					selectedColumns;
				this.selectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.manageUsersTableDefinitions.columnSelectionMode =
					columnSelectionMode;
				this.columnSelectionMode = columnSelectionMode;
			},
			filterCriteriaChanged: (filterCriteria: string) =>
			{
				this.filterCriteria = filterCriteria;
				this.restoreTableDefinition();
			},
			sortCriteriaChanged:
			(sortCriteria: string,
				sortField: string,
				sortOrder: number) =>
			{
				this.sortCriteria = sortCriteria;
				this.sortField = sortField;
				this.sortOrder = sortOrder;
				this.restoreTableDefinition();
			},
		};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Creates a new Entity Instance.
	 *
	 * @async
	 * @memberof ManageUsersComponent
	 */
	private async createEntityInstance(): Promise<void>
	{
		const createObject: IEntityInstance =
		{
			id: 0,
			versionNumber: 1,
			data: {
				email: this.commonTableContext.source.rowData.email,
				firstName: this.commonTableContext.source.rowData.firstName,
				lastName: this.commonTableContext.source.rowData.lastName,
				userName: this.commonTableContext.source.rowData.userName
			},
			entityType: 'User'
		};

		await this.activityService.handleActivity(
			new Activity(
				this.entityInstanceApiService
					.createEntityInstance(
						createObject,
						AppConstants.typeGroups.systems,
						1),
				'<strong>Creating User</strong>',
				'<strong>Created User</strong>',
				'User was created.',
				'User was not created.'));

		this.restoreTableDefinition();
	}

	/**
	 * Updates an existing Entity Instance.
	 *
	 * @async
	 * @memberof ManageUsersComponent
	 */
	private async updateEntityInstance(): Promise<void>
	{
		const updateUser: Function =
			async () =>
			{
				const entityInstance: IEntityInstance =
					await this.entityInstanceApiService
						.get(this.commonTableContext.source.rowData.id);

				entityInstance.data =
					{
						...entityInstance.data,
						...this.commonTableContext.source.rowData
					};

				delete entityInstance.data.id;

				entityInstance.data.taxId =
					parseInt(
						entityInstance.data.taxId,
						AppConstants.parseRadix);

				entityInstance.data.password = AppConstants.empty;

				const updateInstanceObject: any =
					{
						id: this.commonTableContext.source.rowData.id,
						resourceIdentifier: entityInstance.resourceIdentifier,
						entityType: entityInstance.entityType,
						versionNumber: entityInstance.versionNumber,
						data: entityInstance.data
					};

				const rowData: any = this.commonTableContext.source.rowData;

				delete updateInstanceObject.data.password;

				this.entityInstanceApiService
					.update(
						rowData.id,
						updateInstanceObject);
			};

		await this.activityService.handleActivity(
			new Activity(
				updateUser(),
				'<strong>Updating User</strong>',
				'<strong>Updated User</strong>',
				`User ${this.commonTableContext.source.rowData.id} `
					+ 'was updated.',
				`User ${this.commonTableContext.source.rowData.id} `
					+ 'was not updated.'),
			AppConstants.activityStatus.complete,
			true);

		this.restoreTableDefinition();
	}

	/**
	 * Deletes an existing Entity Instance.
	 *
	 * @async
	 * @memberof ManageUsersComponent
	 */
	private async deleteEntityInstance(): Promise<void>
	{
		const rowData: any = this.commonTableContext.source.rowData;
		await this.activityService.handleActivity(
			new Activity(
				this.entityInstanceApiService
					.delete(this.commonTableContext.source.rowData.id),
				'<strong>Deleting User</strong>',
				'<strong>Deleted User</strong>',
				`User ${rowData.id} was deleted.`,
				`User ${rowData.id} was not deleted.`));

		this.restoreTableDefinition();
	}

	/**
	 * Checks if user name is unique.
	 *
	 * @async
	 * @param {FormControl} control
	 * The form control.
	 * @returns {Promise<boolean>}
	 * The field async validation result.
	 * @memberof ManageUserGroupsComponent
	 */
	private async uniqueUserName(control: FormControl): Promise<boolean>
	{
		const userName: IEntityInstance[] =
			await this.entityInstanceApiService.query(
				`userName eq '${control.value}'`,
				AppConstants.empty);

		return Promise.resolve(
			AnyHelper.isNullOrEmpty(userName[0]));
	}

	/**
	 * Gets the delete statement to be displayed on the
	 * delete expand view.
	 *
	 * @async
	 * @return {Promise<string>}
	 * The delete statement string.
	 * @memberof ManageUsersComponent
	 */
	private async deleteStatement(): Promise<string>
	{
		const securitySession = await this.securitySessionApiService
			.query(
				`entityInstanceId eq ${this.commonTableContext.data.id}`,
				AppConstants.empty);

		if (!AnyHelper.isNullOrEmpty(securitySession[0]))
		{
			this.commonTableContext.source.tableDefinitions.actions
				.delete.items[1].disabled = true;

			return 'Unable to delete user. User has already logged in.';
		}

		this.commonTableContext.source.tableDefinitions.actions
			.delete.items[1].disabled = false;

		return 'Confirm you are about to delete User '
			+ `${this.commonTableContext.data.userName}`;
	}
}