/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	IDescriptionDisplayDefinition
} from '@shared/interfaces/application-objects/description-display-definition.interface';
import {
	IKeyValuePair
} from '@shared/interfaces/application-objects/key-value-pair.interface';
import {
	IMappedDifferenceDefinition
} from '@shared/interfaces/application-objects/mapped-difference-display-definition.interface';
import {
	IModelDisplayDefinition
} from '@shared/interfaces/application-objects/model-display-definition.interface';
import {
	JsonSchemaHelper
} from '@shared/helpers/json-schema.helper';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/* eslint-enable max-len */

@Component({
	selector: 'app-difference-display',
	templateUrl: './difference-display.component.html',
	styleUrls: [
		'./difference-display.component.scss'
	]
})

/**
 * A component representing an instance of the difference display component.
 *
 * @export
 * @class DifferenceDisplayComponent
 */
export class DifferenceDisplayComponent implements OnInit
{
	/**
	 * Initializes a new instance of the difference display component.
	 *
	 * @param {ModuleService} moduleService
	 * The module service which is used to define module specific business
	 * logic for display.
	 * @memberof DifferenceDisplayComponent
	 */
	public constructor(
		public moduleService: ModuleService)
	{
	}

	/**
	 * Gets or sets the mapped difference to be displayed in this component.
	 *
	 * @type {IMappedDifferenceDefinition}
	 * @memberof DifferenceDisplayComponent
	 */
	@Input() public mappedDifference: IMappedDifferenceDefinition;

	/**
	 * Gets or sets the previous sibling to this mapped difference definition.
	 *
	 * @type {IMappedDifferenceDefinition}
	 * @memberof DifferenceDisplayComponent
	 */
	@Input() public previousSibling: IMappedDifferenceDefinition;

	/**
	 * Gets or sets the current data level being displayed in this component.
	 * This value is used for business logic and display definitions based
	 * on data levels.
	 *
	 * @type {number}
	 * @memberof DifferenceDisplayComponent
	 */
	@Input() public dataLevel: number = 0;

	/**
	 * Gets or sets a value that signifies whether or not this difference is
	 * based on an array index item. This value is used when nested array
	 * level items are altered and signifies an updated nested value in this
	 * array item.
	 *
	 * @type {boolean}
	 * @memberof DifferenceDisplayComponent
	 */
	public isNumericKey: boolean;

	/**
	 * Gets or sets a mapped parent value for this mapped difference.
	 *
	 * @type {any}
	 * @memberof DifferenceDisplayComponent
	 */
	public parentValue: any;

	/**
	 * Gets or sets the model display specific to this difference display
	 * based on a key value.
	 *
	 * @type {IModelDisplayDefinition}
	 * @memberof DifferenceDisplayComponent
	 */
	public modelDisplayDefinition: IModelDisplayDefinition;

	/**
	 * Gets or sets a value signifying whether or not the object level
	 * name should be displayed. This is hidden when there are no differences
	 * at the base level.
	 *
	 * @type {boolean}
	 * @memberof DifferenceDisplayComponent
	 */
	public displayObjectName: boolean = true;

	/**
	 * Implements the on initialization interface.
	 * This method will set values explicit to this implementation of the
	 * difference display component at this level, for this difference.
	 *
	 * @memberof DifferenceDisplayComponent
	 */
	public ngOnInit(): void
	{
		this.parentValue =
			this.mappedDifference.updatedParentValue;
		this.modelDisplayDefinition =
			this.mapModelDisplay();
		this.isNumericKey =
			!isNaN(parseInt(
				this.mappedDifference.key,
				AppConstants.parseRadix));

		this.displayObjectName =
			this.dataLevel !== 0
				|| this.mappedDifference.nestedDifferences.some(
					(mappedDifference: IMappedDifferenceDefinition) =>
						!AnyHelper.isNull(mappedDifference.difference)
							&& mappedDifference.difference.differenceType
								=== AppConstants.differenceTypes.property);

		this.dataLevel++;
	}

	/**
	 * Gets a display valu from the description property of the schema
	 * definition. If no value is found this will use the property name
	 * and add spaces before all capital letters and convert the value to
	 * proper case.
	 *
	 * @param {string} value
	 * The camel case value that should be displayed as a friendly named
	 * value if no label value is defined in the model display definition.
	 * @returns {string}
	 * The display value signifying the friendly name of the sent camelcase
	 * value or label value if set.
	 * @memberof DifferenceDisplayComponent
	 */
	public getLabelValue(
		value: string): string
	{
		if (!AnyHelper.isNull(this.modelDisplayDefinition?.label))
		{
			return this.modelDisplayDefinition.label;
		}

		return StringHelper.beforeCapitalSpaces(
			StringHelper.toProperCase(value));
	}

	/**
	 * Gets a display value for a property value to display to the user.
	 * This method will format based on the property schema definition of
	 * output format.
	 *
	 * @param {any} itemValue
	 * The item value that should be cast into a formatted string.
	 * @returns {string}
	 * The formatted display value of the sent property value if set, or the
	 * value unaltered if undefined.
	 * @memberof DifferenceDisplayComponent
	 */
	public getFormattedValue(
		itemValue: any): string
	{
		if (!AnyHelper.isNull(this.modelDisplayDefinition?.format))
		{
			return StringHelper.format(
				itemValue,
				this.modelDisplayDefinition.format);
		}

		return itemValue;
	}

	/**
	 * Gets a display value for an object based on it's type and subType if
	 * these values exist.
	 *
	 * @param {any} itemValue
	 * The item that will be checked for a type and subType based definition.
	 * @returns {string}
	 * If the item holds a type or subtype, this will return the string
	 * value containing those two combined values. If no type value exists
	 * this will return as an empty string.
	 * @memberof DifferenceDisplayComponent
	 */
	 public getObjectDisplayType(
		itemValue: any): string
	{
		const item: any =
			itemValue || this.parentValue;

		if (AnyHelper.isNullOrWhitespace(item.type))
		{
			return AppConstants.empty;
		}

		return this.getLabelValue(
			item.type
				+ (AnyHelper.isNullOrWhitespace(item.subType)
					? AppConstants.empty
					: ` - ${item.subType}`));
	}

	/**
	 * Gets an icon display for an item type from the model display
	 * definition or schema definition.
	 *
	 * @param {any} itemValue
	 * The item that will be checked for a type based icon value.
	 * @returns {string}
	 * If the item holds a type and is defined in the difference display
	 * definition, this will return the mapped icon value.
	 * @memberof DifferenceDisplayComponent
	 */
	 public getObjectDisplayIcon(
		itemValue: any): string
	{
		if (!AnyHelper.isNullOrWhitespace(
			this.modelDisplayDefinition?.icon))
		{
			return `fa fa-fw fa-${this.modelDisplayDefinition?.icon}`;
		}

		const item: any =
			itemValue || this.parentValue;

		if (AnyHelper.isNullOrWhitespace(item.type))
		{
			return AppConstants.empty;
		}

		const itemDefinition: any =
			JsonSchemaHelper.getArrayItemDefinition(
				this.modelDisplayDefinition.schemaDefinition,
				item.type);

		return AnyHelper.isNull(itemDefinition?.icon)
			? AppConstants.empty
			: `fa fa-fw fa-${itemDefinition.icon}`;
	}

	/**
	 * Gets a display value for an object based on the entity definition
	 * schema properties for this object type. If no description property keys
	 * are found or any value in the key is not set this will not display
	 * the item level description. The description property type will always
	 * display if set.
	 *
	 * @param {any} itemValue
	 * The item that should be cast into a friendly description in this view.
	 * @returns {string}
	 * The friendly display value of the sent item as mapped in the module
	 * level configurations. If any value of a description is null or empty,
	 * this will return as an empty string.
	 * @memberof DifferenceDisplayComponent
	 */
	public getObjectDescription(
		itemValue: any = null): string
	{
		const item: any =
			itemValue || this.parentValue;

		let descriptionType: string;
		let propertyKeys:
			IDescriptionDisplayDefinition[];
		let schemaDefinition: any =
			this.modelDisplayDefinition.schemaDefinition;

		if (!AnyHelper.isNullOrWhitespace(
			this.modelDisplayDefinition?.descriptionPropertyType)
			|| AnyHelper.isNull(schemaDefinition.items))
		{
			descriptionType =
				this.modelDisplayDefinition.descriptionPropertyType;
			propertyKeys =
				this.modelDisplayDefinition.descriptionPropertyKeys;
		}
		else
		{
			schemaDefinition =
				JsonSchemaHelper.getArrayItemDefinition(
					schemaDefinition,
					item.type);

			descriptionType =
				schemaDefinition?.descriptionPropertyType;
			propertyKeys =
				schemaDefinition?.descriptionPropertyKeys;
		}

		propertyKeys = propertyKeys || [];
		const schemaProperties: IKeyValuePair[] =
			JsonSchemaHelper.getSchemaProperties(
				schemaDefinition.properties);

		for (const propertyKey of
			propertyKeys.filter(
				(propertyData: IDescriptionDisplayDefinition) =>
					AnyHelper.isNull(propertyData.outputFormat)))
		{
			const schemaProperty: IKeyValuePair =
				schemaProperties.find(
					(schema: any) =>
						schema.key.replace(
							'[]',
							AppConstants.empty) === propertyKey.key);

			propertyKey.outputFormat =
				schemaProperty?.value.outputFormat
					|| AppConstants.formatTypes.none;
		}

		const description: string =
			ObjectHelper.getObjectDescription(
				item,
				propertyKeys);

		if (AnyHelper.isNullOrWhitespace(descriptionType)
			&& AnyHelper.isNullOrWhitespace(description))
		{
			return AppConstants.empty;
		}

		return (AnyHelper.isNullOrWhitespace(descriptionType)
			? AppConstants.empty
			: `${descriptionType}:`)
			+ (AnyHelper.isNullOrWhitespace(description)
				? AppConstants.empty
				: ` ${description}`);
	}

	/**
	 * Gets a mapped model display definition from the existing mapped
	 * difference level schema definition. These values are used
	 * to fine tune outputs displayed in this component.
	 *
	 * @returns {IModelDisplayDefinition}
	 * The model display definition that represents this current mapped
	 * difference schema definition.
	 * @memberof DifferenceDisplayComponent
	 */
	private mapModelDisplay(): IModelDisplayDefinition
	{
		const schemaDefinition: any =
			this.mappedDifference.schemaDefinition;

		return <IModelDisplayDefinition>
			{
				format: schemaDefinition.outputFormat,
				icon: schemaDefinition.icon,
				label: schemaDefinition.description,
				descriptionPropertyType:
					schemaDefinition.descriptionPropertyType,
				descriptionPropertyKeys:
					schemaDefinition.descriptionPropertyKeys,
				schemaDefinition: schemaDefinition
			};
	}
}