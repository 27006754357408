/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AppConfig
} from 'src/app/app.config';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BaseApiService
} from '@api/services/base/base.api.service';
import {
	HttpClient,
	HttpHeaders
} from '@angular/common/http';
import {
	Injectable
} from '@angular/core';
import {
	IUserDto
} from '@api/interfaces/users/user.dto.interface';
import {
	lastValueFrom,
	map
} from 'rxjs';

/**
 * A class representing the logic and services of the authenticate controller.
 *
 * @export
 * @class AuthenticateService
 * @extends {BaseApiService}
 */
@Injectable()
export class AuthenticateApiService
	extends BaseApiService
{
	/**
	 * Creates an instance of AuthenticateService.
	 *
	 * @param {HttpClient} http
	 * @memberof AuthenticateService
	 */
	public constructor(
		private readonly http: HttpClient)
	{
		super();
	}

	/**
	 * Performs an async login request to the api service implementing
	 * the /Authenticate method.
	 *
	 * @async
	 * @param {string} userName
	 * A string representing the user name to login as.
	 * @param {string} password
	 * A string representing the password for the user name.
	 * @returns {Promise<{headers: HttpHeaders, user: IUser}>}
	 * A value indicating whether the login was successful.
	 * @memberof AuthenticateService
	 */
	public async login(
		userName: string,
		password: string,
		applicationToken: string): Promise<{
			headers: HttpHeaders;
			user: IUserDto; }>
	{
		const encodedUsernamePassword: string
			= btoa(userName + ':' + password);

		const url: string
			= this.formUrlParam(
				AppConfig.settings.webApi.rootUrl
					+ AppConfig.settings.webApi.version
					+ AppConstants.apiControllers.authenticate,
				{
					'application': applicationToken
				});

		return lastValueFrom(
			this.http.post(
				url,
				{},
				{
					headers: new HttpHeaders({
						'authorization': 'basic ' + encodedUsernamePassword
					}),
					observe: 'response'
				})
				.pipe(
					map((response) =>
						({
							headers: response.headers,
							user: <IUserDto>response.body
						}))
				));
	}

	/**
	 * Performs an async verification request to the api service implementing
	 * the /Authenticate/Verify method.
	 *
	 * @param {string} token
	 * A string representing the security access token to verify.
	 * @param {string} code
	 * A string representing the verification code.
	 * @memberof AuthenticateService
	 */
	public async verify(
		token: string,
		code: string): Promise<void>
	{
		const url: string
			= this.formUrlParam(
				AppConfig.settings.webApi.rootUrl
					+ AppConfig.settings.webApi.version
					+ AppConstants.apiControllers.authenticate
					+ '/verify',
				{
					verificationCode: code,
					token: encodeURIComponent(token)
				});

		return lastValueFrom(
			this.http.post<void>(
				url,
				{}));
	}

	/**
	 * Performs an async verification request to the api service implementing
	 * the /Authenticate/Reset method.
	 *
	 * @param {string} userName
	 * A string representing the user name to reset.
	 * @memberof AuthenticateService
	 */
	public async reset(
		userName: string): Promise<void>
	{
		const url: string
			= this.formUrlParam(
				AppConfig.settings.webApi.rootUrl
					+ AppConfig.settings.webApi.version
					+ AppConstants.apiControllers.authenticate
					+ '/reset',
				{
					userName: userName
				});

		return lastValueFrom(
			this.http.post<void>(
				url,
				{}));
	}
}