/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonListDirective
} from './common-list-directive';
import {
	Directive,
	EventEmitter,
	Output
} from '@angular/core';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityInstanceDto
} from '@api/interfaces/entities/entity-instance.dto.interface';
import {
	MenuItem
} from 'primeng/api';

/* eslint-enable max-len */

@Directive({
	selector: '[ProductSetting]'
})

/**
 * A directive representing shared logic for a component interacting
 * with product setting.
 *
 * @export
 * @class ProductSettingDirective
 */
export class ProductSettingDirective
	extends CommonListDirective<IEntityInstance, IEntityInstanceDto>
{
	/**
	 * Initializes a new instance of product setting directive.
	 * @memberof ProductSettingDirective
	 */
	public constructor()
	{
		super();
	}

	/**
	 * Gets or sets the change display mode event
	 *
	 * @type {EventEmitter<string>}
	 * @memberof ProductSettingDirective
	 */
	@Output() public changeDisplayMode: EventEmitter<string> =
		new EventEmitter<string>();

	/**
	 * Gets the product Authority Types
	 *
	 * @type {object}
	 * @memberof ProductSettingDirective
	 */
	public readonly productAuthorityTypes: {
		active: string;
		inactive: string;
		serviceExisting: string;
	} =
	{
		active: 'Active',
		inactive: 'Inactive',
		serviceExisting: 'Service Existing'
	};

	/**
	 * Gets the set of available actions for nested displays.
	 *
	 * @type
	 * @memberof ProductSettingDirective
	 */
	public itemActions: MenuItem[] =
		[
			<MenuItem> {
				icon: 'fa fa-info-circle',
				id: AppConstants.displayMode.view,
				command: ((
					event: any,
					item: any) => {
					this.changeSelectedItem.emit(item);
					this.changeDisplayMode.emit(
						AppConstants.displayMode.view);
					event.stopPropagation();
				})
			},
			<MenuItem> {
				icon: 'fa fa-pencil',
				id: AppConstants.displayMode.update,
				command: ((
					event: any,
					item: any) => {
					this.changeSelectedItem.emit(item);
					this.changeDisplayMode.emit(
						AppConstants.displayMode.update);
					event.stopPropagation();
				})
			}
		];
}