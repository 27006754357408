/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	Component
} from '@angular/core';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IOperationGroupRelationship
} from '@operation/interfaces/operation-group-relationship.interface';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	OperationGroupApiService
} from '@api/services/operations/operation-group.api.service';
import {
	OperationGroupCreateChildComponent
} from '@admin/components/user-interface/operations/operation-groups/operation-group-expand/operation-group-create/operation-group-create-child.component';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-operation-group',
	templateUrl: './operation-group.component.html',
	styleUrls: [
		'./operation-group.component.scss'
	]
})

/**
 * A component representing an instance of the
 * operation group expand component.
 *
 * @export
 * @class OperationGroupComponent
 * @extends {CommonTablePageDirective}
 * @implements {IDynamicComponent<CommonTableComponent, any>}
 */
export class OperationGroupComponent
	extends CommonTablePageDirective
	implements IDynamicComponent<CommonTableComponent, any>
{
	/**
	 * Initializes a new instance of the OperationGroupComponent class.
	 *
	 * @param {OperationGroupApiService} operationGroupApiService
	 * The api service used to load operation group data.
	 * @param {OperationDefinitionApiService} operationGroupApiService
	 * The api service used to load operation definition data.
	 * @param {OperationTypeApiService} operationTypeApiService
	 * The api service used to load operation type data.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this component to get its data.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof OperationGroupComponent
	 */
	public constructor(
		public operationGroupApiService: OperationGroupApiService,
		public operationDefinitionApiService: OperationDefinitionApiService,
		public activityService: ActivityService,
		public siteLayoutService: SiteLayoutService,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the context that will be set when implementing this
	 * as a dynamic component.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof OperationGroupComponent
	 */
	public context: IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the table definitions for the
	 * children common  only view.
	 *
	 * @type {ICommonTable}
	 * @memberof OperationGroupComponent
	 */
	public childrenTableDefinitionsView: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof OperationGroupComponent
	 */
	public childViewAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof OperationGroupComponent
	 */
	public childViewSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the column selection mode.
	 *
	 * @type {boolean}
	 * @memberof OperationGroupComponent
	 */
	public childViewColumnSelectionMode?: boolean = false;

	/**
	 * Gets or sets the table definitions for the
	 * children common table update view.
	 *
	 * @type {ICommonTable}
	 * @memberof OperationGroupComponent
	 */
	public childrenTableDefinitionsUpdate: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof OperationGroupComponent
	 */
	public childUpdateAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof OperationGroupComponent
	 */
	public childUpdateSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the column selection mode.
	 *
	 * @type {boolean}
	 * @memberof OperationGroupComponent
	 */
	public childUpdateColumnSelectionMode?: boolean = false;

	/**
	 * Sets the data chunk limit.
	 *
	 * @type {string}
	 * @memberof OperationGroupComponent
	 */
	public dataChunkLimit: number = 100;

	/**
	 * Gets ir Sets the operation query params.
	 *
	 * @type {IObjectSearch}
	 * @memberof OperationGroupComponent
	 */
	public operationQueryParams: IObjectSearch = {
		filter: AppConstants.empty,
		orderBy: `Id ${AppConstants.sortDirections.ascending}`,
		offset: null,
		limit: this.dataChunkLimit
	};

	/**
	 * Sets up variables used in this admin page based table.
	 *
	 * @memberof OperationGroupComponent
	 */
	public setupPageVariables(): void
	{
		let displayOrder: number = 1;
		this.childViewAvailableColumns =
			[
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'type',
					columnHeader: 'Type',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'order',
					columnHeader: 'Order',
					displayOrder: displayOrder++
				}
			];
		this.childViewSelectedColumns = this.childViewAvailableColumns;

		this.childUpdateAvailableColumns =
			[
				...this.childViewAvailableColumns
			];
		this.childUpdateSelectedColumns = this.childUpdateAvailableColumns;
	}

	/**
	 * Sets up the table definitions for a common table
	 *
	 * @async
	 * @memberof OperationGroupComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		this.childrenTableDefinitionsView = {
			hideExpanderArrow: true,
			hideSettings: true,
			tableTitle: 'Children',
			objectSearch: {
				filter: AppConstants.empty,
				orderBy: `order ${AppConstants.sortDirections.ascending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: 5
			},
			apiPromise: async () =>
			{
				const children =
						await this.operationGroupApiService
							.getChildren(this.context.data.data.id);

				const childrenData = [];

				for (const child of children)
				{
					let operationName: string = AppConstants.empty;
					if (child.type === 'OperationDefinition')
					{
						operationName =
							(await this.operationDefinitionApiService
								.get(child.id)).name;
					}
					else if (child.type === 'OperationGroup')
					{
						operationName =
							(await this.operationGroupApiService
								.get(child.id)).name;
					}

					childrenData.push(
						{
							order: child.order,
							name: operationName,
							type: child.type
						}
					);
				}

				EventHelper.dispatchTableExpansionPanelLoadedEvent();

				return childrenData;
			},
			availableColumns: this.childViewAvailableColumns,
			selectedColumns: this.childViewSelectedColumns,
			columnSelectionMode: this.childViewColumnSelectionMode,
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.childrenTableDefinitionsView.selectedColumns =
					selectedColumns;
				this.childViewSelectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.childrenTableDefinitionsView.columnSelectionMode =
					columnSelectionMode;
				this.childViewColumnSelectionMode = columnSelectionMode;
			}
		};

		this.childrenTableDefinitionsUpdate = {
			hideExpanderArrow: true,
			hideSettings: true,
			actions: {
				create: {
					displayCreateRow: false,
					customContext:
						<IDynamicComponentContext<Component, any>>this.context,
					component: OperationGroupCreateChildComponent,
					items: [
						{
							label: 'Save',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: async() => this.createChild()
						}
					]
				},
				updateIndex: [
					{
						id: 'updateIndexUp',
						visible: (
							rowData: IOperationGroupRelationship,
							virtualData: IOperationGroupRelationship[]) =>
							rowData?.order !== virtualData[0]?.order,
						command: async(
							commonTableContext: CommonTableComponent) =>
							this.updateOrderIndex(
								commonTableContext,
								-1)
					},
					{
						id: 'updateIndexDown',
						visible: (
							rowData: IOperationGroupRelationship,
							virtualData: IOperationGroupRelationship[]) =>
							rowData?.order !==
									virtualData[virtualData.length - 1]?.order,
						command: async(
							commonTableContext: CommonTableComponent) =>
							this.updateOrderIndex(
								commonTableContext,
								1)
					}
				],
				delete: {
					deleteStatement: () =>
						'Confirm to remove child '
							+ `${this.commonTableContext.data.name} `
							+ 'from operation group '
							+ `${this.context.data.data.name}.`,
					items: [
						{
							label: 'Remove',
							styleClass: AppConstants.cssClasses.pButtonDanger,
							command: () => this.deleteChild()
						}
					],
				}
			},
			tableTitle: 'Children',
			objectSearch: {
				filter: AppConstants.empty,
				orderBy: `order ${AppConstants.sortDirections.ascending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: 5
			},
			apiPromise: async () =>
			{
				const children =
					await this.operationGroupApiService
						.getChildren(this.context.data.data.id);

				const childrenData = [];
				let baseIdCounter = 1;

				for (const child of children)
				{
					let operationName: string = AppConstants.empty;
					if (child.type === 'OperationDefinition')
					{
						operationName =
							(await this.operationDefinitionApiService
								.get(child.id)).name;
					}
					else if (child.type === 'OperationGroup')
					{
						operationName =
							(await this.operationGroupApiService
								.get(child.id)).name;
					}

					childrenData.push(
						{
							id: baseIdCounter,
							baseId: child.id,
							order: child.order,
							name: operationName,
							type: child.type
						});

					baseIdCounter++;
				}

				EventHelper.dispatchTableExpansionPanelLoadedEvent();

				return childrenData;
			},
			availableColumns: this.childUpdateAvailableColumns,
			selectedColumns: this.childUpdateSelectedColumns,
			columnSelectionMode: this.childUpdateColumnSelectionMode,
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.childrenTableDefinitionsUpdate.selectedColumns =
					selectedColumns;
				this.childUpdateSelectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.childrenTableDefinitionsUpdate.columnSelectionMode =
					columnSelectionMode;
				this.childUpdateColumnSelectionMode = columnSelectionMode;
			}
		};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Updates the order index of the selected row item
	 * up or down based on the indexOperator.
	 *
	 * @param {CommonTableComponent} commonTableContext
	 * The common table context.
	 * @param {number} indexReference
	 * The index reference to add or substract to the current
	 * selected order index.
	 * @async
	 * @memberof OperationGroupComponent
	 */
	private async updateOrderIndex(
		commonTableContext: CommonTableComponent,
		indexReference: number): Promise<void>
	{
		commonTableContext.loadingTableDefinitions = true;

		const orderedChildren =
			(await this.operationGroupApiService
				.getChildren(this.context.data.data.id))
				.sort((a, b) =>
					a.order - b.order);

		const neighborOrderIndex =
			this.findSelectedChildIndex(
				orderedChildren,
				this.commonTableContext.source.rowData) + indexReference;

		if (neighborOrderIndex > -1)
		{
			await this.operationGroupApiService
				.updateChild(
					this.context.data.data.id,
					<IOperationGroupRelationship>
					{
						id: orderedChildren[neighborOrderIndex].id,
						type: orderedChildren[neighborOrderIndex].type,
						order: 1000
					});

			await this.operationGroupApiService
				.updateChild(
					this.context.data.data.id,
					<IOperationGroupRelationship>
					{
						id: this.commonTableContext.source.rowData.baseId,
						type: this.commonTableContext.source.rowData.type,
						order: orderedChildren[neighborOrderIndex].order
					});

			await this.operationGroupApiService
				.updateChild(
					this.context.data.data.id,
					<IOperationGroupRelationship>
					{
						id: orderedChildren[neighborOrderIndex].id,
						type: orderedChildren[neighborOrderIndex].type,
						order: this.commonTableContext.source.rowData.order
					});
		}

		this.restoreTableDefinition();
	}

	/**
	 * Finds the selected child index.
	 *
	 * @param {IOperationGroupRelationship} children
	 * The children object array.
	 * @param {any} selectedChild
	 * The selected child.
	 * @memberof OperationGroupComponent
	 */
	private findSelectedChildIndex(
		children: IOperationGroupRelationship[],
		selectedChild: any): number
	{
		for (let i = 0; i < children.length; i++)
		{
			if (children[i].id === selectedChild.baseId
				&& children[i].type === selectedChild.type
				&& children[i].order === selectedChild.order)
			{
				return i;
			}
		}

		return -1;
	}

	/**
	 * Creates a new child to the operation group.
	 *
	 * @async
	 * @memberof OperationGroupComponent
	 */
	private async createChild(): Promise<void>
	{
		await this.activityService.handleActivity(
			new Activity(
				this.operationGroupApiService
					.createChild(
						this.context.data.data.id,
						{
							id: this.commonTableContext.source.rowData.name,
							type: this.commonTableContext.source.rowData.type,
							order: this.commonTableContext.source.rowData.order
						}),
				'<strong>Creation Child Relationship</strong>',
				'<strong>Created Child Relationship</strong>',
				'Child Relationship was created.',
				'Child Relationship was not created.'));

		this.restoreTableDefinition();
	}

	/**
	 * Deletes an existing child to the operation group.
	 *
	 * @async
	 * @memberof OperationGroupComponent
	 */
	private async deleteChild(): Promise<void>
	{
		await this.activityService.handleActivity(
			new Activity(
				this.operationGroupApiService
					.deleteChild(
						this.context.data.data.id,
						{
							id: this.commonTableContext.source.rowData.baseId,
							type: this.commonTableContext.source.rowData.type
						}),
				'<strong>Deleting Child Relationship</strong>',
				'<strong>Deleted Child Relationship</strong>',
				`Child Relationship ${this.commonTableContext
					.source.rowData.baseId} ` +
					'was deleted.',
				`Child Relationship ${this.commonTableContext
					.source.rowData.baseId} ` +
					'was not deleted.'));

		this.restoreTableDefinition();
	}
}