/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonFormlyFieldConstants
} from '@shared/constants/common-formly-field-constants';
import {
	Component,
	OnInit
} from '@angular/core';
import {
	FormControl
} from '@angular/forms';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	OptionsFactory
} from '@shared/factories/options-factory';

/* eslint-enable max-len */

@Component({
	selector: 'app-display-management-expand',
	templateUrl: './display-management-expand.component.html',
	styleUrls: ['./display-management-expand.component.scss']
})

/**
 * A component representing an instance of the
 * operation definition expand component.
 *
 * @export
 * @class DisplayManagementExpandComponent
 * @implements {IDynamicComponent<any, any>}
 */
export class DisplayManagementExpandComponent
implements IDynamicComponent<any, any>, OnInit
{
	/**
	 * Initializes a new instance of the DisplayManagementExpandComponent.
	 *
	 * @param {OptionsFactory} optionsFactory
	 * The common options factory used to populate dropdown data.
	 * @memberof DisplayManagementExpandComponent
	 */
	public constructor(
		public optionsFactory: OptionsFactory)
	{
	}

	/**
	 * Gets or sets the context that will be set when implementing this
	 * as a dynamic component.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof DisplayManagementExpandComponent
	 */
	public context: IDynamicComponentContext<any, any>;

	/**
	 * Gets or sets the main loading state.
	 *
	 * @type {boolean}
	 * @memberof DisplayManagementExpandComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets the static formly layout.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof DisplayManagementExpandComponent
	 */
	public staticFormlyLayout: FormlyFieldConfig[] ;

	/**
	 * Gets or sets the dynamic formly layout.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof DisplayManagementExpandComponent
	 */
	public dynamicFormlyLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the definition Id.
	 *
	 * @type {number}
	 * @memberof DisplayManagementExpandComponent
	 */
	public definitionId: number;

	/**
	 * Gets or sets the loading dynamic layout state.
	 *
	 * @type {boolean}
	 * @memberof DisplayManagementExpandComponent
	 */
	public loadingDynamicLayout: boolean = true;

	/**
	 * Gets or sets the static formly validity.
	 *
	 * @type {boolean}
	 * @memberof DisplayManagementExpandComponent
	 */
	public staticValidity: boolean;

	/**
	 * Gets or sets the dynamic formly validity.
	 *
	 * @type {boolean}
	 * @memberof DisplayManagementExpandComponent
	 */
	public dynamicValidity: boolean;

	/**
	 * Initializes the component by setting the formly layouts.
	 *
	 * @async
	 * @memberof DisplayManagementExpandComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		await this.setStaticFormlyLayout();
		this.loading = false;
	}

	/**
	 * Checks the two formly forms validities.
	 *
	 * @memberof DisplayManagementExpandComponent
	 */
	public checkValidity(): void
	{
		this.context.source
			.validExpandComponentChanged(
				this.staticValidity === true
					&& this.dynamicValidity === true);
	}

	/**
	 * Sets the static form validity and
	 * checks overall validity.
	 *
	 * @param {boolean} event
	 * The form validity.
	 * @memberof DisplayManagementExpandComponent
	 */
	public staticFormValidity(event: boolean): void
	{
		this.staticValidity = event;
		this.checkValidity();
	}

	/**
	 * Sets the dynamic form validity and
	 * checks overall validity.
	 *
	 * @param {boolean} event
	 * The form validity.
	 * @memberof DisplayManagementExpandComponent
	 */
	public dynamicFormValidity(event: boolean): void
	{
		this.dynamicValidity = event;
		this.checkValidity();
	}

	/**
	 * Sets the static formly layout.
	 *
	 * @async
	 * @memberof DisplayManagementExpandComponent
	 */
	public async setStaticFormlyLayout(): Promise<void>
	{
		const securityGroupOptions: IDropdownOption[] =
			await this.optionsFactory.getSecurityGroupOptions();

		this.staticFormlyLayout = [
			{
				key: 'data.typeId',
				type: FormlyConstants.customControls.customSelect,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					appendTo: FormlyConstants.appendToTargets.body,
					label: 'Type',
					showClear: true,
					placeholder: AppConstants.placeholders.selectAnOption,
					options: this.context.source.customContext.source
						.displayComponentTypeOptions
				}
			},
			{
				key: 'data.definitionId',
				type: FormlyConstants.customControls.customSelect,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					appendTo: FormlyConstants.appendToTargets.body,
					label: 'Definition',
					showClear: true,
					placeholder: AppConstants.placeholders.selectAnOption,
					options: this.context.source.customContext.source
						.displayComponentDefinitionOptions,
					change: (field: FormlyFieldConfig) =>
					{
						if (AnyHelper.isNullOrEmpty(field.formControl.value))
						{
							this.loadingDynamicLayout = true;
							this.definitionId = null;

							return;
						}

						this.definitionId = field.formControl.value;

						this.loadingDynamicLayout = true;
						setTimeout(() =>
						{
							this.setDynamicFormlyLayout();
						});
					}
				}
			},
			{
				key: 'data.name',
				type: FormlyConstants.customControls.input,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					label: 'Name',
					required: true
				}
			},
			{
				key: 'data.additionalRowData.securityGroups',
				type: FormlyConstants.customControls.customMultiSelect,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					appendTo: FormlyConstants.appendToTargets.body,
					label: 'Security Groups',
					showClear: true,
					placeholder: AppConstants.placeholders.selectAnOption,
					options: this.context.source.customContext.source
						.securityGroupOptions
				}
			},
			CommonFormlyFieldConstants.publicField,
			{
				...CommonFormlyFieldConstants
					.ownershipSecurityGroupField,
				templateOptions: {
					...CommonFormlyFieldConstants
						.ownershipSecurityGroupField
						.templateOptions,
					options: securityGroupOptions,
					appendTo: FormlyConstants.appendToTargets.body
				}
			},
			{
				key: 'data.description',
				type: FormlyConstants.customControls.input,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					label: 'Description',
					required: true,
				}
			}
		];
	}

	/**
	 * Sets the dynamic formly layout.
	 *
	 * @async
	 * @memberof DisplayManagementExpandComponent
	 */
	public async setDynamicFormlyLayout(): Promise<void>
	{
		const definitionJsonData: string =
			!AnyHelper.isNullOrEmpty(this.definitionId)
				? (await this.context.source.customContext.source
					.displayComponentDefinitionApiService
					.get(this.definitionId)).jsonData
				: AppConstants.empty;

		const interpolationData: object = {};

		if (AnyHelper.isNull(this.context.data.data.additionalRowData))
		{
			this.context.data.data.additionalRowData =
				await this.context.source.tableDefinitions.actions.update
					.additionalRowData();
		}

		this.context.data.data.additionalRowData.definitionJsonData =
			definitionJsonData;

		if (!AnyHelper.isNullOrEmpty(definitionJsonData))
		{
			const interpolationSchema: string[] =
				JSON.parse(definitionJsonData).interpolationSchema;

			if (interpolationSchema !== undefined)
			{
				interpolationSchema.forEach((interpolationItem) =>
				{
					interpolationData[interpolationItem] = AppConstants.empty;
				});
			}

			const attributes: object =
				{
					interpolationData: interpolationData,
					parameterDefinition: {},
					parameterLayout: [],
					initialParameters: {}
				};

			this.context.data.data.jsonData =
				((this.context.source.dataBackup.definitionId
					=== this.context.data.data.definitionId)
				&& this.context.source.displayCreate !== true)
					? this.context.source.dataBackup.jsonData
					: JSON.stringify(
						attributes,
						undefined,
						AppConstants.jsonTabIndent);
		}

		this.dynamicFormlyLayout = [
			{
				type: FormlyConstants.customControls.customTextDisplay,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					title: 'Definition',
					useCodeBlock: true,
					usePanelDisplay: true,
					codeBlockType: AppConstants.markdownLanguages.json,
					content: AppConstants.empty
				},
				expressionProperties: {
					'templateOptions.content':
						'`${model.data.additionalRowData.definitionJsonData}`'
				}
			},
			{
				key: 'data.jsonData',
				type: FormlyConstants.customControls.customTextArea,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					label: 'Attributes',
					rows: FormlyConstants.textAreaRowSizes.standard
				},
				validators: {
					validAttibute: {
						expression: (
							control: FormControl,
							field: FormlyFieldConfig) =>
							this.attributeValidator(
								control,
								field,
								interpolationData),
						message: AppConstants.empty
					}
				},
			}
		];

		this.loadingDynamicLayout = false;
	}

	/**
	 * Validates if the attribute field meets the
	 * templated criteria.
	 *
	 * @param {FormControl} control
	 * The form control.
	 * @param {FormlyFieldConfig} field
	 * The formly field config
	 * @param {object} interpolationData
	 * The interpolation data.
	 * @returns {boolean}
	 * Whether the entry is valid or not.
	 *
	 * @memberof DisplayManagementExpandComponent
	 */
	public attributeValidator(
		control: FormControl,
		field: FormlyFieldConfig,
		interpolationData: object): boolean
	{
		// Validates if null or empty.
		if (AnyHelper.isNullOrEmpty(control.value))
		{
			field.validators.validAttibute.message =
				'This value is required.';

			return false;
		}

		let isValid: boolean;
		let attributeObject: any;

		// Checks if a valid json.
		try
		{
			attributeObject = JSON.parse(control.value);
		}
		catch
		{
			field.validators.validAttibute.message =
				'Content is not a valid JSON.';

			return false;
		}

		// Validates no extra properties inside interpolationData.
		if (Object.keys(interpolationData)?.length > 0
			&& !AnyHelper.isNullOrEmpty(attributeObject.interpolationData))
		{
			for (const fieldValue of Object.keys(
				attributeObject.interpolationData))
			{
				isValid = false;
				for (const originalValue of Object.keys(interpolationData))
				{
					if (fieldValue === originalValue)
					{
						isValid = true;
					}
				}

				if (isValid !== true)
				{
					field.validators.validAttibute.message =
						fieldValue +
							' is not a valid interpolation data property.';

					return false;
				}
			}
		}

		// Validates interpolationData is required.
		if (attributeObject.interpolationData === undefined)
		{
			field.validators.validAttibute.message =
				'Interpolation Data field is required.';

			return false;
		}

		// Validates each outside property type.
		for (const value of Object.keys(attributeObject))
		{
			switch (value){
				case 'interpolationData':
				{
					let isValidType = this.propertyTypeChecker(
						attributeObject,
						field,
						value);

					if (!AnyHelper.isNullOrEmpty(attributeObject[value])
						&& Object.entries(attributeObject[value]).length === 0)
					{
						field.validators.validAttibute.message =
							`${value} must have at least one definition `
								+ 'property.';

						isValidType = false;
					}

					if (isValidType === false)
					{
						return false;
					}
					break;
				}
				case 'initialParameters':
				case 'parameterDefinition':
				{
					const isValidType = this.propertyTypeChecker(
						attributeObject,
						field,
						value);

					if (isValidType === false)
					{
						return false;
					}
					break;
				}
				case 'parameterLayout':
				{
					const isValidType =
						this.propertyTypeChecker(
							attributeObject,
							field,
							value,
							AppConstants.propertyTypes.array,
							'is not a valid object array.');

					if (isValidType === false)
					{
						return false;
					}
					break;
				}
				// Validates no extra outside properties are allowed.
				default:
				{
					field.validators.validAttibute.message =
						value +
							'is not part of the definition template.';

					return false;
				}
			}
		}

		// Validates if any duplicate key.
		const duplicates: object[] =
			control.value
				.replace(/(\r\n|\n|\r)/gm, AppConstants.empty)
				.match(/\b(\w+)(?=":)\b(?=.*?\b\1\b)/ig);

		if (duplicates?.length > 0)
		{
			field.validators.validAttibute.message =
				`${duplicates[0]} is a duplicate key.`;

			return false;
		}

		return true;
	}

	/**
	 * Checks the property type.
	 *
	 * @param {object} attributeObject
	 * The attribute Object.
	 * @param {FormlyFieldConfig} field
	 * The formly field config
	 * @param {string} value
	 * The string value.
	 * @param {propertyType} string
	 * The property type.
	 * @param {string} message
	 * The message string.
	 * @returns {boolean}
	 * Whether the property is a valid type.
	 *
	 * @memberof DisplayManagementExpandComponent
	 */
	public propertyTypeChecker(
		attributeObject: object,
		field: FormlyFieldConfig,
		value: string,
		propertyType: string = AppConstants.propertyTypes.object,
		message: string = 'is not a valid object.'): boolean
	{
		let isValidType: boolean = true;

		if (propertyType === AppConstants.propertyTypes.object)
		{
			isValidType = typeof attributeObject[value]
				=== AppConstants.propertyTypes.object
				&& attributeObject[value]?.length === undefined;
		}
		else if (propertyType === AppConstants.propertyTypes.array)
		{
			isValidType = typeof attributeObject[value]
				=== AppConstants.propertyTypes.object
				&& attributeObject[value]?.length !== undefined;
		}

		if (isValidType === false)
		{
			field.validators.validAttibute.message =
				`${value} ${message}`;
		}

		return isValidType;
	}
}