/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	TimeUnit
} from 'chart.js';

/**
 * A class representing static constant and read only values
 * for chart based work.
 *
 * @export
 * @class ChartConstants
 */
export class ChartConstants
{
	public static readonly chartClasses:
	{
		canvasElement: string;
	} =
	{
		canvasElement: '.chartjs-render-monitor'
	};

	public static readonly chartTypes:
	{
		bar: string;
		doughnut: string;
		line: string;
		pie: string;
		scatter: string;
	} = {
		bar: 'bar',
		doughnut: 'doughnut',
		line: 'line',
		pie: 'pie',
		scatter: 'scatter'
	};

	public static readonly formats:
	{
		day: string;
		hour: string;
	} = {
		day: 'cccc MMMM d, yyyy',
		hour: 'cccc MMMM d h:mm a'
	};

	public static readonly themeColors:
	{
		amber: string;
		black: string;
		blue: string;
		darkGray: string;
		error: string;
		gray: string;
		green: string;
		indigo: string;
		info: string;
		lightBlue: string;
		lightGray: string;
		lightGreen: string;
		orange: string;
		pink: string;
		primary: string;
		purple: string;
		red: string;
		success: string;
		teal: string;
		warning: string;
		yellow: string;
	} = {
		amber: 'amber',
		black: 'black',
		blue: 'blue',
		darkGray: 'dark-gray',
		error: 'error',
		gray: 'gray',
		green: 'green',
		indigo: 'indigo',
		info: 'info',
		lightBlue: 'light-blue',
		lightGray: 'light-gray',
		lightGreen: 'light-green',
		pink: 'pink',
		primary: 'primary',
		purple: 'purple',
		orange: 'orange',
		red: 'red',
		success: 'success',
		teal: 'teal',
		warning: 'warning',
		yellow: 'yellow'
	};

	public static readonly timeUnits:
	{
		second: TimeUnit;
		minute: TimeUnit;
		hour: TimeUnit;
		day: TimeUnit;
		month: TimeUnit;
		year: TimeUnit;
	} = {
		second: 'second',
		minute: 'minute',
		hour: 'hour',
		day: 'day',
		month: 'month',
		year: 'year'
	};
}