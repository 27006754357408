/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	ViewChild
} from '@angular/core';
import {
	ExtendedCustomControlDirective
} from '@entity/directives/extended-custom-control.directive';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

@Component({
	selector: 'app-custom-input-search',
	templateUrl: './custom-input-search.component.html',
	styleUrls: ['./custom-input-search.component.scss']
})

/**
 * A component representing an instance of the session component.
 *
 * @export
 * @class CustomInputSearchComponent
 * @extends {ExtendedCustomControlDirective}
 */
export class CustomInputSearchComponent
	extends ExtendedCustomControlDirective
{
	/**
	 * Initializes a new instance of the search filter component.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service.
	 * @memberof SiteSettingsComponent
	 */

	public constructor(
		public changeDetector: ChangeDetectorRef,
		public siteLayoutService: SiteLayoutService)
	{
		super(changeDetector);
	}

	/**
	 * Gets or sets the tooltip element reference.
	 *
	 * @type {ElementRef}
	 * @memberof CustomInputSearchComponent
	 */
	@ViewChild('Tooltip')
	public tooltip: ElementRef;

	/** Handles the onClick event sent from the input search icon button.
	 *
	 * @param {any} event
	 * The click event sent from the icon button.
	 * @memberof CustomInputSearchComponent
	 */
	  public onClick(
		event: any): void
	{
		if (!AnyHelper.isNull(this.field.templateOptions.change)
			&& !AnyHelper.isNullOrWhitespace(
				this.field.formControl.value))
		{
			this.field.templateOptions.change(
				this.field,
				event);
		}
	}

	/** Handles the onKeyPress event sent from the input search icon button.
	 *
	 * @param {any} event
	 * The click event sent from the icon button.
	 * @memberof CustomInputSearchComponent
	 */
	public onKeyPress(
		event: any): void
	{
		if (event.key ===
			AppConstants.keyBoardKeyConstants.enter)
		{
			this.onClick(event);
		}
	}
}