/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AfterViewChecked,
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	HostListener,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	each
} from 'lodash-es';
import {
	EntityEventConstants
} from '@entity/shared/entity-event.constants';
import {
	FieldWrapper
} from '@ngx-formly/core';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	from,
	Subscription
} from 'rxjs';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

@Component({
	selector: 'custom-field-wrapper',
	templateUrl: './custom-field-wrapper.component.html',
	styleUrls: [
		'./custom-field-wrapper.component.scss'
	]
})

/**
 * A component representing an instance of a Custom Field Wrapper.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomFieldWrapperComponent
 * @extends {FieldWrapper}
 * @implements {OnInit}
 * @implements {AfterViewInit}
 * @implements {AfterViewChecked}
 */
export class CustomFieldWrapperComponent
	extends FieldWrapper
	implements OnInit, AfterViewInit, AfterViewChecked
{
	/**
	 * Initializes a new instance of the CustomFieldWrapperComponent class.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this component.
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof CustomFieldWrapperComponent
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService,
		public changeDetector: ChangeDetectorRef)
	{
		super();
	}

	/* @ViewChildren('InputLabel')
	public inputLabelChildren: ElementRef[]; */

	@ViewChild('FieldWrapper')
	public fieldWrapper: any;

	/**
	 * Gets or sets a value defining whether or not the field is required based
	 * on presentation rules.
	 *
	 * @type {boolean}
	 * @memberof CustomFieldWrapperComponent
	 */
	public requiredViaRules: boolean = false;

	/**
	 * Gets or sets the data subscription class
	 *
	 * @type {Subscription}
	 * @memberof CustomFieldWrapperComponent
	 */
	public dataSubscription: Subscription;

	/**
	 * Gets or sets the grid columns.
	 *
	 * @type {string}
	 * @memberof CustomFieldWrapperComponent
	 */
	public gridColumns: string;

	/**
	 * Gets or sets the label style width.
	 *
	 * @type {string}
	 * @memberof CustomFieldWrapperComponent
	 */
	public labelStyleWidth: string = AppConstants.cssStyles.maxContent;

	/**
	 * Gets or sets the data subscription class
	 *
	 * @type {Subscription}
	 * @memberof CustomActionMenuDirective
	 */
	public dynamicGridSubscription: Subscription;

	/**
	 * Handles the site layout change event which is called
	 * when the site layout service has altered it's variables
	 * or loading has completed.
	 *
	 * @memberof CommonTableComponent
	 */
	@HostListener(
		AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		//this.dynamicGridColumnPropagation();
	}

	/**
	 * On initialization event.
	 * Checks for the existence of a required rule validator on this field if
	 * it is set via rules.
	 *
	 * @memberof CustomFieldWrapperComponent
	 */
	public ngOnInit(): void
	{
		if (!AnyHelper.isNull(this.field.validators))
		{
			each(
				Object.keys(this.field.validators),
				(key: string) =>
				{
					if (key.indexOf(
						'Required_') === 0)
					{
						this.requiredViaRules = true;
					}
				});
		}
	}

	/**
	 * On after view initialization event.
	 * Triggers any change events that exist in the field on the initial
	 * display. This ensures that loaded values and client entered values
	 * remain consistent with business rules.
	 *
	 * @memberof CustomFieldWrapperComponent
	 */
	public ngAfterViewInit(): void
	{
		if (!AnyHelper.isNullOrEmpty(this.field.templateOptions.change))
		{
			this.field.templateOptions
				.change(this.field);
		}

		//this.dynamicGridColumnPropagation();
	}

	/**
	 * On after view checked event.
	 * This will fire a change detector for validation events outside of the
	 * main client thread.
	 *
	 * @memberof CustomFieldWrapperComponent
	 */
	public ngAfterViewChecked(): void
	{
		this.changeDetector.detectChanges();
	}

	/**
	 * Dynamically propagates the grid columns to avoid layout breaks and
	 * blank spaces when large labels overflow their container.
	 *
	 * @memberof CustomFieldWrapperComponent
	 */
	public dynamicGridColumnPropagation(): Promise<void>
	{
		if (this.dynamicGridSubscription != null)
		{
			this.dynamicGridSubscription.unsubscribe();
		}

		return new Promise<void>(
			(resolve) =>
			{
				this.dynamicGridSubscription =
					from(
						this.handleDynamicGridColumnPropagation())
						.subscribe(
							() =>
							{
								this.setGridColumns();
								this.setLabelStyleWidth();
								resolve();
							});
			});
	}

	/**
	 * Handles the dynamic grid column propagation when applicable.
	 * This will dynamically set the grid columns to 12 of a section
	 * to the same when one or more field label overflows or to 6 when
	 * there is available width.
	 *
	 * @async
	 * @memberof CustomFieldWrapperComponent
	 */
	private async handleDynamicGridColumnPropagation(): Promise<void>
	{
		if (AnyHelper.isNull(this.field.templateOptions.context)
			|| AnyHelper.isNullOrEmpty(this.field.templateOptions.attributes))
		{
			return;
		}

		const fieldWrapperElement: any =
			this.fieldWrapper.nativeElement;
		const labelWidth: number =
			fieldWrapperElement.children[0]
				.getBoundingClientRect()
				.width;
		const fieldWrapperWidth: number =
			fieldWrapperElement
				.getBoundingClientRect()
				.width;

		if (labelWidth > 0
			&& this.field.templateOptions.context.source.activeTabItemIndex
				=== this.field.templateOptions
					.attributes[FormlyConstants.attributeKeys.tabIndex])
		{
			const dynamicGridSizeRelationship: number =
				this.getDynamicGridSizeRelationship(
					this.field.templateOptions.dynamicGridColumns);

			const calculatedAvailableWidth: number =
				(fieldWrapperWidth / dynamicGridSizeRelationship) -
					AppConstants.staticLayoutSizes.standardContentPadding;

			const allowedToPropagateDynamicGridColumns: boolean =
				this.isAllowedToPropagateDynamicGridColumns(
					this.field.templateOptions.dynamicGridColumns,
					labelWidth,
					calculatedAvailableWidth);

			if (allowedToPropagateDynamicGridColumns === false)
			{
				return;
			}

			const dynamicGridColumnsToPropagate: string =
				this.getOppositeDynamicGridColumns(
					this.field.templateOptions.dynamicGridColumns);
			const labelOverflow: boolean =
				this.isLabelOverflow(
					this.field.templateOptions.dynamicGridColumns);

			this.propagateDynamicGridColumns(
				dynamicGridColumnsToPropagate,
				labelOverflow);
		}
	}

	/**
	 * Gets the relationship between the gridColumns and a full grid of 12.
	 *
	 * @param {string} gridColumns
	 * The grid columns.
	 * @returns {number}
	 * The dynamic grid size relationship.
	 * @memberof CustomFieldWrapperComponent
	 */
	private getDynamicGridSizeRelationship(gridColumns: string): number
	{
		if (AnyHelper.isNullOrEmpty(gridColumns))
		{
			return FormlyConstants.gridSizeRelationship.fullGrid;
		}

		const numericFullGrid: number =
			parseInt(
				FormlyConstants.gridColumns.fullGrid,
				AppConstants.parseRadix);

		const numericDynamicGrid: number =
			parseInt(
				gridColumns,
				AppConstants.parseRadix);

		return (numericFullGrid / numericDynamicGrid) ===
			FormlyConstants.gridSizeRelationship.fullGrid
			? FormlyConstants.gridSizeRelationship.halfGrid
			: FormlyConstants.gridSizeRelationship.fullGrid;
	}

	/**
	 * Gets the thruthy if is allowed to propagate the
	 * dynamic grid columns. This is output based on the existing
	 * dynamic grid columns and a calculation between the label width
	 * versus the calculated available width.
	 *
	 * @param {string} dynamicGridColumns
	 * The dynamic grid columns.
	 * @param {number} labelWidth
	 * The label width.
	 * @param {number} calculatedAvailableWidth
	 * The calculated available width.
	 * @returns {boolean}
	 * If allowed to propagate dynamic grid columns.
	 * @memberof CustomFieldWrapperComponent
	 */
	private isAllowedToPropagateDynamicGridColumns(
		dynamicGridColumns: string,
		labelWidth: number,
		calculatedAvailableWidth: number): boolean
	{
		return dynamicGridColumns !== FormlyConstants.gridColumns.fullGrid
			&& labelWidth > calculatedAvailableWidth
			? true
			: this.field.templateOptions.labelOverflow === true
				&& dynamicGridColumns !== FormlyConstants.gridColumns.halfGrid
				&& labelWidth <= calculatedAvailableWidth;
	}

	/**
	 * Gets the opposite dynamic grid columns.
	 * Based on a 12 vs 6 grid, will get the opposite from what is
	 * being sent.
	 *
	 * @param {string} gridColumns
	 * The grid columns.
	 * @returns {string}
	 * The opposite grid columns.
	 * @memberof CustomFieldWrapperComponent
	 */
	private getOppositeDynamicGridColumns(gridColumns: string): string
	{
		return gridColumns !== FormlyConstants.gridColumns.fullGrid
			? FormlyConstants.gridColumns.fullGrid
			: FormlyConstants.gridColumns.halfGrid;
	}

	/**
	 * Determines if the the field grid has been updated dynamically.
	 *
	 * @param {string} gridColumns
	 * The grid columns.
	 * @returns {boolean}
	 * The dynamic grid field thruthy.
	 * @memberof CustomFieldWrapperComponent
	 */
	private isLabelOverflow(gridColumns: string): boolean
	{
		return gridColumns !== FormlyConstants.gridColumns.fullGrid;
	}

	/**
	 * Propagates the dynamic grid columns.
	 * This will set the field to the calculated needed grid columns and
	 * propagate them to the fields in their common section.
	 *
	 * @param {string} dynamicGridColumns
	 * The dynamic grid columns to propagate.
	 * @param {boolean} labelOverflow
	 * The thruthy when label overflow is found.
	 *
	 * @memberof CustomFieldWrapperComponent
	 */
	private propagateDynamicGridColumns(
		dynamicGridColumns: string,
		labelOverflow: boolean): void
	{
		this.field.templateOptions.dynamicGridColumns = dynamicGridColumns;
		this.field.templateOptions.labelOverflow = labelOverflow;

		window.dispatchEvent(
			new CustomEvent(
				EntityEventConstants.calculateEntityGrid,
				{
					detail: {
						gridColumns: dynamicGridColumns,
						sectionIndex:
							this.field.templateOptions.attributes[
								FormlyConstants.attributeKeys.sectionIndex]
					}
				}
			));
	}

	/**
	 * Sets the defined grid columns to be implementad by the ui.
	 * Between the gridColumns and dynamicGridColumns from template option,
	 * if existing, the gridColumns will take priority over
	 * the dynamicGridColumns.
	 *
	 * @memberof CustomFieldWrapperComponent
	 */
	private setGridColumns(): void
	{
		this.gridColumns =
			AnyHelper.isNullOrEmpty(this.field.templateOptions.gridColumns)
				? this.field.templateOptions.dynamicGridColumns
				: this.field.templateOptions.gridColumns;
	}

	/**
	 * Sets the label style width.
	 * This will update the label div style and wrap the label into its own
	 * container when there is no more available width, or allowe it to
	 * overflow to calculate the label width and the dynamic grid columns.
	 *
	 * @memberof CustomFieldWrapperComponent
	 */
	private setLabelStyleWidth(): void
	{
		this.labelStyleWidth =
			this.gridColumns === FormlyConstants.gridColumns.fullGrid
				? AppConstants.cssStyles.fitContent
				: AppConstants.cssStyles.maxContent;
	}
}