/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppCanDeactivateGuard
} from '@shared/guards/app-can-deactivate.guard';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BaseOperationAction
} from '@operation/actions/base/base-operation-action';
import {
	CacheService
} from '@shared/services/cache.service';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	HttpEvent,
	HttpEventType
} from '@angular/common/http';
import {
	Injectable
} from '@angular/core';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	OperationExecutionService
} from '@operation/services/operation-execution.service';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	ResponseType
} from '@shared/constants/enums/response-type.enum';
import {
	SessionService
} from '@shared/services/session.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/**
 * A class representing the action to generate files with the sent
 * parameters.
 *
 * @export
 * @class GenerateFilesAction
 * @extends {BaseOperationAction}
 */
@Injectable()
export class GenerateFilesAction
	extends BaseOperationAction
{
	/**
	 * Creates an instance of a generate document action.
	 *
	 * @param {ActivityService} activityService
	 * The activity service used for this action.
	 * @param {sessionService} SessionService
	 * The session service used for this action.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The activity service used for this action.
	 * @param {CacheService} cacheService
	 * The cache service that is used to reset cached associations on generate.
	 * @param {OperationExecutionService} operationExecutionService
	 * The operation execution service used for this action.
	 * @param {OperationService} operationService
	 * The operation service used for this action.
	 * @param {OperationDefinitionApiService} operationDefinitionApiService
	 * The operation definition api service used for this action.
	 * @param {AppCanDeactivateGuard} appCanDeactivateGuard
	 * The app can deactivate guard.
	 * @memberof GenerateDocumentAction
	 */
	 public constructor(
		public activityService: ActivityService,
		public sessionService: SessionService,
		public entityInstanceApiService: EntityInstanceApiService,
		public cacheService: CacheService,
		protected operationExecutionService: OperationExecutionService,
		protected operationService: OperationService,
		protected operationDefinitionApiService: OperationDefinitionApiService,
		protected appCanDeactivateGuard: AppCanDeactivateGuard)
	{
		super(
			activityService,
			operationExecutionService,
			operationService,
			operationDefinitionApiService,
			appCanDeactivateGuard);
	}

	/**
	 * Gets or sets the operation name.
	 *
	 * @type {string}
	 * @memberof GenerateFilesAction
	 */
	public operationName: string =
		'GenerateFiles';

	/**
	 * Gets or sets the id.
	 *
	 * @type {string}
	 * @memberof GenerateFilesAction
	 */
	public id: string = AppConstants.empty;

	/**
	 * Gets or sets the type group.
	 *
	 * @type {string}
	 * @memberof GenerateFilesAction
	 */
	 public typeGroup: string = AppConstants.empty;

	/**
	 * Gets or sets the force generate parameter.
	 *
	 * @type {boolean}
	 * @memberof GenerateFilesAction
	 */
	public forceGenerate: boolean = false;

	/**
	 * Gets or sets the generator filter.
	 *
	 * @type {string}
	 * @memberof GenerateFilesAction
	 */
	 public generatorFilter: string = AppConstants.empty;

	 /**
	 * Gets or sets the generator filter.
	 *
	 * @type {string}
	 * @memberof GenerateFilesAction
	 */
	  public returnType: string = AppConstants.empty;

	 /**
	 * Gets or sets the generated file's parent id.
	 *
	 * @type {string}
	 * @memberof GenerateFilesAction
	 */
	  public generatedFilesParent: string = AppConstants.empty;

	/**
	 * Gets or sets the view result of this generate action.
	 *
	 * @type {string}
	 * @memberof GenerateFilesAction
	 */
	public view: string = AppConstants.empty;

	/**
	 * Gets or sets the query string.
	 *
	 * @type {string}
	 * @memberof GenerateFilesAction
	 */
	public queryString: string = AppConstants.empty;

	/**
	 * Gets or sets the success message.
	 *
	 * @type {string}
	 * @memberof GenerateFilesAction
	 */
	public successMessage: string = AppConstants.empty;

	/**
	 * Gets or sets the window for opening a print dialogue.
	 *
	 * @type {Window}
	 * @memberof GenerateFilesAction
	 */
	public printWindow: Window;

	/**
	 * Executes the defined action.
	 *
	 * @async
	 * @memberof GenerateFilesAction
	 */
	public async execute(): Promise<void>
	{
		const contextSource: EntityInstanceComponent =
			this.pageContext.source as EntityInstanceComponent;

		this.entityInstanceApiService
			.entityInstanceTypeGroup =
				contextSource.entityTypeGroup;

		await this.setQueryParameters();

		const promise: Promise<void> =
			new Promise<void>(
				(resolve, reject) =>
				{
					this.entityInstanceApiService
						.executeActionAsObservable<HttpEvent<any>>(
							contextSource.id,
							'GenerateFilesBlock',
							null,
							this.queryString,
							ResponseType.Blob)
						.subscribe({
							next:
							(event: any) =>
							{
								if (event.type === HttpEventType.Response)
								{
									if (!event.ok)
									{
										reject();

										return;
									}

									const body: any = event.body;

									if (body instanceof Blob)
									{
										this.HandleBlobResponse(body);
									}
								}
							},
							error: (error: any) => reject(error),
							complete: async() => {
								if ((this.pageContext.source instanceof
									EntityInstanceComponent))
								{
									await this
										.clearCachedEntityRelationships(
											this.pageContext.source);
								}

								resolve();

								if (!AnyHelper.isNull(this.printWindow))
								{
									this.printWindow.print();
								}

							}
						});
				});

		await this.activityService.handleActivity<void>(
			new Activity<void>(
				promise,
				'<strong>Generating New File</strong>.',
				'<strong>Generated New File</strong>.',
				this.successMessage,
				'Error generating file.'));
	}

	/**
	 * Handles a blob response.
	 *
	 * @param {Blob} blob
	 * The entity instance component that will have cleared relationships.
	 * @memberof GenerateFilesAction
	 */
	private HandleBlobResponse(blob: Blob): void
	{
		const fileUrl = window.URL
			.createObjectURL(blob);

		switch (this.view)
		{
			case 'view':
				window.open(
					fileUrl,
					AppConstants
						.windowTargets.blank);
				break;

			case 'open':
				const anchor = document
					.createElement('a');

				document
					.body
					.appendChild(anchor);

				anchor.href = fileUrl;
				anchor.target = AppConstants
					.windowTargets.blank;

				anchor.click();

				document
					.body
					.removeChild(anchor);

				break;

			case 'print':

				this.printWindow = window.open(
					fileUrl,
					AppConstants
						.windowTargets.blank);

				break;

			default:
				return;
		}
	}

	/**
	 * Clears entity id based relationship caches to allow this updated
	 * document to load in the initial query post generate.
	 *
	 * @async
	 * @param {EntityInstanceComponent} entityInstanceComponent
	 * The entity instance component that will have cleared relationships.
	 * @memberof GenerateFilesAction
	 */
	private async clearCachedEntityRelationships(
		entityInstanceComponent: EntityInstanceComponent): Promise<void>
	{
		const baseUrl: string
			= entityInstanceComponent
				.entityInstanceApiService
				.getBaseUrl();

		const entityInstanceUrl: string
			= `${baseUrl}/${entityInstanceComponent.id}/children`;

		await this.cacheService
			.clearExistingStartsWithResponses(entityInstanceUrl);
	}

	/**
	 * Sets the query parameters from the template parameters property and the
	 * sent page context.
	 *
	 * @async
	 * @memberof GenerateFilesAction
	 */
	private async setQueryParameters(): Promise<void>
	{
		this.queryString = '?'
			 + `generatorFilter=${this.generatorFilter}`
			 + `&forceGenerate=${this.forceGenerate}`;

		if (!AnyHelper.isNullOrEmpty(this.returnType))
		{
			this.queryString
				+= `&returnType=${this.returnType}`;
		}

		if (!AnyHelper.isNullOrEmpty(this.generatedFilesParent))
		{
			this.generatedFilesParent
				= await StringHelper.transformToDataPromise(
					StringHelper.interpolate(
						this.generatedFilesParent,
						this.pageContext),
					this.pageContext);

			this.queryString
				+= `&generatedFilesParent=${this.generatedFilesParent}`;
		}
	}
}