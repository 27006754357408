/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	DateTime
} from 'luxon';
import {
	Directive,
	EventEmitter,
	Output
} from '@angular/core';
import {
	EntityDefinition
} from '@shared/implementations/entities/entity-definition';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IEntityTypeDto
} from '@api/interfaces/entities/entity-type.dto.interface';
import {
	ISecurityEntityTypeDefinition
} from '@shared/interfaces/security/security-entity-type-definition.interface';
import {
	MenuItem
} from 'primeng/api';
import {
	SecurityHelper
} from '@shared/helpers/security.helper';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/* eslint-enable max-len */

@Directive({
	selector: '[Note]'
})

/**
 * A directive representing shared logic for a component interacting
 * with notes.
 *
 * @export
 * @class NoteDirective
 */
export class NoteDirective
{
	/**
	 * Initializes a new instance of note directive.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * @memberof NoteDirective
	 */
	public constructor(
		public entityTypeApiService: EntityTypeApiService)
	{
	}

	/**
	 * Gets or sets the navigate event
	 *
	 * @type {EventEmitter<string>}
	 * @memberof NoteDirective
	 */
	@Output() public changeDisplayMode: EventEmitter<string> =
		new EventEmitter<string>();

	/**
	 * Gets the set of available actions for nested displays.
	 *
	 * @type
	 * @memberof NoteDirective
	 */
	public itemActions: MenuItem[] =
		[
			<MenuItem> {
				icon: 'fa-info-circle',
				id: AppConstants.displayMode.view,
				command: (event: any) => {
					this.changeDisplayMode.emit(
						AppConstants.displayMode.view);
					event.stopPropagation();
				}
			},
			<MenuItem> {
				icon: 'fa-trash',
				id: AppConstants.displayMode.delete,
				command: (event: any) => {
					this.changeDisplayMode.emit(
						AppConstants.displayMode.delete);
					event.stopPropagation();
				}
			}
		];

	/**
	 * Gets a datetime from a string or date.
	 *
	 * @param {(Date | string)} date
	 * Input value to convert to a datetime.
	 * @returns {DateTime}
	 * A datetime object.
	 * @memberof NoteDirective
	 */
	public getDateTime(
		date: Date | string): DateTime
	{
		return date instanceof Date
			? DateTime.fromJSDate(date)
			: DateTime.fromISO(date);
	}

	/**
	 * Gets custom field data from the selected item data, not consisting of
	 * the standardized note data elements.
	 *
	 * @param {IEntityInstance}
	 * The item to get property label data for.
	 * @returns {string[]}
	 * An array of custom property names.
	 * @memberof NoteViewComponent
	 */
	public getNoteCustomData(
		selectedItem: IEntityInstance): string[]
	{
		return Object.keys(selectedItem.data)
			.filter((item: string) =>
				item !== 'content'
					&& item !== 'userName'
					&& item !== 'changeDateTime');
	}

	/**
	 * Gets a JSON property's properly cased display value.
	 *
	 * @param {string} input
	 * A string to properly case for display.
	 * @returns {string}
	 * A display friendly string representation of input.
	 * @memberof NoteViewComponent
	 */
	public getPropertyDisplayValue(
		input: string): string
	{
		return StringHelper.toProperCase(
			StringHelper.beforeCapitalSpaces(input));
	}

	/**
	 * Gets a display name for a hierarchial period separated entity type.
	 *
	 * @protected
	 * @param {string} input
	 * A string representing the entity type name.
	 * @returns {string}
	 * A string representing the display value for the input.
	 * @memberof NoteDirective
	 */
	public getDisplayName(
		input: string): string
	{
		return StringHelper.beforeCapitalSpaces(
			StringHelper.getLastSplitValue(
				input,
				AppConstants.characters.period));
	}

	/**
	 * Gets a collection of EntityType objects for the specified entityNames.
	 *
	 * @protected
	 * @param {string[]} entityNames
	 * A collection of string values representing entity type names.
	 * @returns {Promise<EntityType[]>}
	 * A collection of EntityType objects for the requested entityNames.
	 * @memberof NoteDirective
	 */
	protected async getEntityTypesFromNameList(
		entityNames: string[]): Promise<EntityType[]>
	{
		if (entityNames.length === 0)
		{
			return [];
		}

		return this.entityTypeApiService.query(
			`(Name IN ("${entityNames.join('","')}"))`,
			'Id')
			.then((item: IEntityTypeDto[]) =>
				item.map((entityType: IEntityTypeDto) =>
					new EntityType(entityType)));
	}

	/**
	 * Gets a collection of strings representing note types filtered from the
	 * list of input types.
	 *
	 * @protected
	 * @param {number} parentId
	 * The parent id of the note.
	 * @param {sstring} parentTypeGroup
	 * the parent type group.
	 * @param {EntityDefinition} parentEntityDefinition
	 * The parent Entity Definition.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * the entity instance API service.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The Entity Type API service.
	 * @param {string} rightName
	 * The name of the right to check (CRUDE).
	 * @returns {Promise<EntityType[]>}
	 * A collection of suppported and authorized entity types notes.
	 * @memberof NoteDirective
	 */
	protected async getSupportedNoteTypes(
		parentId: number,
		parentTypeGroup: string,
		parentEntityDefinition: EntityDefinition,
		entityInstanceApiService: EntityInstanceApiService,
		entityTypeApiService: EntityTypeApiService,
		rightName: string):
			Promise<EntityType[]>
	{
		const childNotePermissions: ISecurityEntityTypeDefinition[] =
			await SecurityHelper
				.getSupportedChildPermissions(
					parentId,
					parentTypeGroup,
					'Note.*',
					parentEntityDefinition,
					entityInstanceApiService,
					entityTypeApiService);
		try
		{
			return  childNotePermissions
				.filter((permission: ISecurityEntityTypeDefinition) =>
					Object.entries(permission.securityDefinition.rights)
						.find(right => right[0] === rightName)[1])
				.map(
					(permission: ISecurityEntityTypeDefinition) =>
						new EntityType(
							<IEntityType>
							{
								id: permission.entityTypeId,
								group: permission.entityTypeGroup,
								name: permission.entityTypeName,
								ownershipSecurityGroupId:
									permission.OwnershipSecurityGroupId,
								public: permission.public
							}));
		}
		catch
		{
			return [];
		}
	}
}