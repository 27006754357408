/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AccountDirective
} from '@account/directives/account.directive';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	Component
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SessionService
} from '@shared/services/session.service';

@Component({
	selector: 'app-user-profile',
	templateUrl: './user-profile.component.html'
})

/**
 * A component representing the user profile.
 *
 * @export
 * @class UserProfileComponent
 */
export class UserProfileComponent
	extends AccountDirective
{
	/**
	 * Initializes a new instance of the user profile component.
	 *
	 * @param {SessionService} sessionService
	 * The session service use to gather user profile data.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The api service used to get entity instance data.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to get entity type data.
	 * @param {ActivityService} activityService
	 * The activityservice user to handle action notifications.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof UserProfileComponent
	 */
	public constructor(
		public sessionService: SessionService,
		public entityInstanceApiService: EntityInstanceApiService,
		public entityTypeApiService: EntityTypeApiService,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super();
		this.entityInstanceApiService.entityInstanceTypeGroup = 'Users';
	}

	/**
	 * Sets up the page variables.
	 *
	 * @async
	 * @memberof UserProfileComponent
	 */
	public async setupPageVariables(): Promise<void>
	{
		await this.setUserEntityInstance();
		this.updateLastSavedData();
		this.setFormlyDefinitions();
	}

	/**
	 * Sets up the user entity instance data.
	 *
	 * @async
	 * @memberof UserProfileComponent
	 */
	public async setUserEntityInstance(): Promise<void>
	{
		this.userEntityInstance =
			await this.entityInstanceApiService
				.get(this.sessionService.user.id);
	}

	/**
	 * Sets up the formly field definitions.
	 *
	 * @memberof UserProfileComponent
	 */
	public setFormlyDefinitions(): void
	{
		this.formlyLayoutDefinitions =
		[
			{
				key: 'data.firstName',
				type: FormlyConstants.customControls.input,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					label: 'First Name',
					required: true
				}
			},
			{
				key: 'data.lastName',
				type: FormlyConstants.customControls.input,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					label: 'Last Name',
					required: true
				}
			},
			{
				key: 'data.email',
				type: FormlyConstants.customControls.input,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					label: 'Email',
					required: true
				},
				validators: {
					validation: ['email']
				}
			}
		];
	}

	/**
	 * Save activity action.
	 *
	 * @async
	 * @memberof UserProfileComponent
	 */
	public async saveActivityAction(): Promise<void>
	{
		await this.activityService.handleActivity(
			new Activity(
				new Promise(async(resolve: any, reject: any) =>
				{
					try
					{
						await this.entityInstanceApiService.update(
							this.userEntityInstance.id,
							this.userEntityInstance);

						await this.setupPageVariables();

						resolve();
					}
					catch (exception)
					{
						this.saving = false;
						reject(exception);
					}
				}),
				'<strong>Saving</strong> Profile Settings',
				'<strong>Saved</strong> Profile Settings',
				'Profile Settings were saved.',
				'Profile Settings were not saved.'));
	}
}