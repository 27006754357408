/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ApiFilterHelper
} from '@shared/helpers/api-filter.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ChartConstants
} from '@shared/constants/chart-constants';
import {
	ChartFactory
} from '@shared/factories/chart-factory';
import {
	ChartHelper
} from '@shared/helpers/chart.helper';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	Component
} from '@angular/core';
import {
	DateTime
} from 'luxon';
import {
	DynamicComponentLookup
} from '@dynamicComponents/dynamic-component.lookup';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	IAggregate
} from '@shared/interfaces/application-objects/aggregate.interface';
import {
	IChartContext
} from '@shared/interfaces/dynamic-interfaces/chart-context.interface';
import {
	IChartDefinition
} from '@shared/interfaces/application-objects/chart-definition.interface';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IInformationMenuItem
} from '@shared/interfaces/application-objects/information-menu-item.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	ISecuritySession
} from '@shared/interfaces/security/security-session.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SecuritySessionApiService
} from '@api/services/security/security-session.api.service';
import {
	SessionComponent
} from '@admin/components/security/sessions/session-expand/session.component';
import {
	TableHelper
} from '@shared/helpers/table.helper';

/* eslint-enable max-len */

@Component({
	selector: 'app-sessions',
	templateUrl: './sessions.component.html'
})

/**
 * A component representing an instance of the sessions component.
 *
 * @export
 * @class SessionsComponent
 * @extends {CommonTablePageDirective}
 */
export class SessionsComponent
	extends CommonTablePageDirective
{
	/**
	 * Initializes a new instance of the SessionsComponent class.
	 *
	 * @param {SecuritySessionApiService} securitySessionApiService
	 * The api service used to load security session data.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The api service used to work with entity instances.
	 * @param {ChartFactory} chartFactory
	 * The chart factory to use for charted information displays.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof SessionsComponent
	 */
	public constructor(
		public securitySessionApiService: SecuritySessionApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public chartFactory: ChartFactory,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(resolver);
		this.apiService = securitySessionApiService;
	}

	/**
	 * Gets or sets the string used to filter by active sessions. The current
	 * filter that will limit the query to active sessions. Active
	 * is defined as enabled with an expire date greater than right now.
	 *
	 * @type {string}
	 * @memberof SessionsComponent
	 */
	public activeSessionsFilter: string;

	/**
	 * Gets or sets the string used to filter by active users. The current
	 * filter that will limit the query to active users. Active
	 * is defined as enabled with an expire date greater than right now.
	 *
	 * @type {string}
	 * @memberof SessionsComponent
	 */
	public activeUsersCount: Promise<IAggregate[]>;

	/**
	 * Gets or sets the active users filter.
	 *
	 * @type {string}
	 * @memberof SessionsComponent
	 */
	public activeUsersFilter: string;

	/**
	 * Gets or sets the string used to filter to occurrences today.
	 *
	 * @type {string}
	 * @memberof SessionsComponent
	 */
	public startOfDayFilter: string;

	/**
	 * Gets or sets the label array to display in a by hour
	 * chart splitout for today.
	 *
	 * @type {DateTime[]}
	 * @memberof SessionsComponent
	 */
	public todaysHourLabels: DateTime[] = [];

	/**
	 * Gets or sets the string used to filter by the last thirty days.
	 *
	 * @type {string}
	 * @memberof SessionsComponent
	 */
	public lastThirtyDaysFilter: string;

	/**
	 * Gets or sets the label array to display in a last thirty dat
	 * chart splitout.
	 *
	 * @type {DateTime[]}
	 * @memberof SessionsComponent
	 */
	public thirtyDayLabels: DateTime[] = [];

	/**
	 * Gets or sets the array of information menu items.
	 *
	 * @type {IInformationMenuItem<IAggregate[]>[]}
	 * @memberof SessionsComponent
	 */
	public informationMenuItems: IInformationMenuItem<IAggregate[]>[] = [];

	/**
	 * Gets or sets the array of page level charts.
	 *
	 * @type {IChartDefinition<IAggregate[]>[]}
	 * @memberof SessionsComponent
	 */
	public pageCharts: IChartDefinition<IAggregate[]>[] = [];

	/**
	 * Gets or sets the table definitions for the standard table view.
	 *
	 * @type {object}
	 * @memberof SessionComponent
	 */
	public securitySessionsTableDefinitions: ICommonTable;

	/**
	 * Sets the Sessions Last Thirty Days string literal.
	 *
	 * @type {string}
	 * @memberof SessionComponent
	 */
	private readonly sessionsLastThirtyDays: string = 'Sessions Last 30 Days';

	/**
	 * Sets the Users Last Thirty Days string literal.
	 *
	 * @type {string}
	 * @memberof SessionComponent
	 */
	private readonly usersLastThirtyDays: string = 'Users Last 30 Days';

	/**
	 * Sets the Number Of Sessions string literal.
	 *
	 * @type {string}
	 * @memberof SessionComponent
	 */
	 private readonly numberOfSessions: string = 'Number of Sessions';

	 /**
	 * Sets the Number Of Users string literal.
	 *
	 * @type {string}
	 * @memberof SessionComponent
	 */
	 private readonly numberOfUsers: string = 'Number of Users';

	 /**
	 * Sets up variables used in this component.
	 *
	 * @memberof SessionsComponent
	 */
	 public setupPageVariables(): void
	 {
	 	const currentDate: DateTime = DateTime.local();

	 	const currentUtcDateTime: string =
			currentDate.toISO();
	 	this.activeSessionsFilter =
			`Enabled eq true AND ExpireDate > '${currentUtcDateTime}'`;
	 	this.activeUsersFilter =
			`Enabled eq true AND ExpireDate > '${currentUtcDateTime}'`;

	 	this.startOfDayFilter =
			ApiFilterHelper.getStartOfDayFilter(
				'CreateDate',
				currentDate);
	 	this.lastThirtyDaysFilter =
			ApiFilterHelper.getLastNumberOfDaysFilter(
				'CreateDate',
				currentDate,
				AppConstants.days.thirtyDays);
	 	this.todaysHourLabels =
			ChartHelper.getHourLabelsByDay(
				currentDate);
	 	this.thirtyDayLabels =
			ChartHelper.getLastNumberOfDayLabels(
				currentDate,
				AppConstants.days.thirtyDays);

	 	let displayOrder: number = 1;
	 	this.availableColumns =
			[
				{
					dataKey: 'id',
					columnHeader: 'Id',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'entityInstanceId',
					columnHeader: 'User',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'applicationId',
					columnHeader: 'Application',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'createDate',
					dataFormatType: AppConstants.dataFormatTypes.dateTime,
					columnHeader: 'Create Date',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'lastActiveDate',
					dataFormatType: AppConstants.dataFormatTypes.dateTime,
					columnHeader: 'Last Active Date',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'expireDate',
					dataFormatType: AppConstants.dataFormatTypes.dateTime,
					columnHeader: 'Expire Date',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'token',
					columnHeader: 'Token',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'enabled',
					columnHeader: 'Enabled',
					displayOrder: displayOrder
				}
			];
	 	this.selectedColumns = this.availableColumns;
	 }

	 /**
	 * Sets up information menu items displayed in this component.
	 *
	 * @memberof SessionsComponent
	 */
	 public setupInformationMenuItems(): void
	 {
	 	const primaryGroupByValues: string =
			'CreateDate.ConvertToSystemTime().Year, '
				+ 'CreateDate.ConvertToSystemTime().Month, '
				+ 'CreateDate.ConvertToSystemTime().Day';
	 	const hourGroupByValue: string =
			'CreateDate.ConvertToSystemTime().Hour';

	 	this.informationMenuItems =
		<IInformationMenuItem<IAggregate[]>[]>
		[
			{
				chartDefinition:
					<IChartDefinition<IAggregate[]>>
					{
						dataPromise: this.apiService.aggregate(
							AppConstants.aggregateMethods.count,
							null,
							this.lastThirtyDaysFilter,
							primaryGroupByValues),
						chartConfiguration: this.chartFactory.timeLineChart(
							this.sessionsLastThirtyDays,
							this.thirtyDayLabels,
							[],
							this.numberOfSessions,
							ChartConstants.timeUnits.day,
							1,
							ChartConstants.formats.day)
					},
				overlayDynamicComponent:
					DynamicComponentLookup.supportedTypes
						.chartComponent,
				overlayDynamicContext:
					<IDynamicComponentContext<Component,
						IChartContext<IAggregate[]>>>
					{
						data:
							<IChartContext<IAggregate[]>>
							{
								chartDefinition:
									<IChartDefinition<IAggregate[]>>
									{
										dataPromise: this.apiService.aggregate(
											AppConstants.aggregateMethods.count,
											null,
											this.lastThirtyDaysFilter,
											primaryGroupByValues),
										chartConfiguration:
											this.chartFactory.timeLineChart(
												this.sessionsLastThirtyDays,
												this.thirtyDayLabels,
												[],
												this.numberOfSessions,
												ChartConstants.timeUnits.day,
												1,
												ChartConstants.formats.day),
										chartColors: [
											ChartConstants.themeColors.primary
										]
									},
								data: [],
								fillMissingDataSets: true
							},
						source: this
					},
				titleTemplate: this.sessionsLastThirtyDays,
				width: AppConstants.sizeIdentifiers.extraLarge
			},
			{
				chartDefinition:
					<IChartDefinition<IAggregate[]>>
					{
						dataPromise: this.apiService.aggregate(
							AppConstants.aggregateMethods.count,
							null,
							this.lastThirtyDaysFilter,
							primaryGroupByValues + ', EntityInstanceId'),
						groupByCount: true,
						chartConfiguration: this.chartFactory.timeLineChart(
							this.usersLastThirtyDays,
							this.thirtyDayLabels,
							[],
							this.numberOfSessions,
							ChartConstants.timeUnits.day,
							1,
							ChartConstants.formats.day)
					},
				overlayDynamicComponent:
					DynamicComponentLookup.supportedTypes
						.chartComponent,
				overlayDynamicContext:
					<IDynamicComponentContext<Component,
						IChartContext<IAggregate[]>>>
					{
						data:
							<IChartContext<IAggregate[]>>
							{
								chartDefinition:
									<IChartDefinition<IAggregate[]>>
									{
										dataPromise: this.apiService.aggregate(
											AppConstants.aggregateMethods.count,
											null,
											this.lastThirtyDaysFilter,
											primaryGroupByValues
												+ ', EntityInstanceId'),
										groupByCount: true,
										chartConfiguration:
											this.chartFactory.timeLineChart(
												this.usersLastThirtyDays,
												this.thirtyDayLabels,
												[],
												this.numberOfUsers,
												ChartConstants.timeUnits.day,
												1,
												ChartConstants.formats.day)
									},
								data: [],
								fillMissingDataSets: true
							},
						source: this
					},
				titleTemplate: this.usersLastThirtyDays,
				width: AppConstants.sizeIdentifiers.large
			},
			{
				chartDefinition:
					<IChartDefinition<IAggregate[]>>
					{
						dataPromise: this.apiService.aggregate(
							AppConstants.aggregateMethods.count,
							null,
							this.startOfDayFilter,
							primaryGroupByValues
								+ `, ${hourGroupByValue}`),
						chartConfiguration: this.chartFactory.timeLineChart(
							'Today\'s Sessions',
							this.todaysHourLabels,
							[],
							this.numberOfSessions,
							ChartConstants.timeUnits.hour,
							1,
							ChartConstants.formats.hour)
					},
				overlayDynamicComponent:
					DynamicComponentLookup.supportedTypes
						.chartComponent,
				overlayDynamicContext:
					<IDynamicComponentContext<Component,
						IChartContext<IAggregate[]>>>
					{
						data:
							<IChartContext<IAggregate[]>>
							{
								chartDefinition:
									<IChartDefinition<IAggregate[]>>
									{
										dataPromise: this.apiService.aggregate(
											AppConstants.aggregateMethods.count,
											null,
											this.startOfDayFilter,
											primaryGroupByValues
												+ `,${hourGroupByValue}`),
										chartConfiguration:
											this.chartFactory.timeLineChart(
												'Today\'s Sessions',
												this.todaysHourLabels,
												[],
												this.numberOfSessions,
												ChartConstants.timeUnits.hour,
												1,
												ChartConstants.formats.hour)
									},
								data: [],
								fillMissingDataSets: true
							},
						source: this
					},
				titleTemplate: 'Sessions Today',
				width: AppConstants.sizeIdentifiers.medium
			},
			{
				chartDefinition:
					<IChartDefinition<IAggregate[]>>
					{
						dataPromise: this.apiService.aggregate(
							AppConstants.aggregateMethods.count,
							null,
							this.startOfDayFilter,
							primaryGroupByValues
								+ `, ${hourGroupByValue}, `
								+ 'EntityInstanceId'),
						groupByCount: true,
						chartConfiguration: this.chartFactory.timeLineChart(
							'Today\'s Users',
							this.todaysHourLabels,
							[],
							this.numberOfUsers,
							ChartConstants.timeUnits.hour,
							1,
							ChartConstants.formats.hour)
					},
				overlayDynamicComponent:
					DynamicComponentLookup.supportedTypes
						.chartComponent,
				overlayDynamicContext:
					<IDynamicComponentContext<Component,
						IChartContext<IAggregate[]>>>
					{
						data:
							<IChartContext<IAggregate[]>>
							{
								chartDefinition: {
									dataPromise: this.apiService.aggregate(
										AppConstants.aggregateMethods.count,
										null,
										this.startOfDayFilter,
										primaryGroupByValues
											+ `, ${hourGroupByValue}, `
											+ 'EntityInstanceId'),
									groupByCount: true,
									chartConfiguration:
										this.chartFactory.timeLineChart(
											'Today\'s Users',
											this.todaysHourLabels,
											[],
											this.numberOfUsers,
											ChartConstants.timeUnits.hour,
											1,
											ChartConstants.formats.hour)
								},
								data: [],
								fillMissingDataSets: true,

							},
						source: this
					},
				titleTemplate: 'Users Today',
				width: AppConstants.sizeIdentifiers.small
			},
			{
				dataPromise: this.apiService.aggregate(
					AppConstants.aggregateMethods.count,
					null,
					this.activeSessionsFilter,
					null),
				summaryTemplate: '${data[0].value}',
				titleTemplate: 'Total Active Sessions',
				width: AppConstants.sizeIdentifiers.large
			},
			{
				dataPromise: this.apiService.aggregate(
					AppConstants.aggregateMethods.count,
					null,
					null,
					null),
				summaryTemplate: '${data[0].value}',
				titleTemplate: 'Total Sessions',
				width: AppConstants.sizeIdentifiers.medium
			},
			{
				dataPromise: this.apiService.aggregate(
					AppConstants.aggregateMethods.count,
					null,
					this.activeUsersFilter,
					'EntityInstanceId'),
				groupByCount: true,
				summaryTemplate: '${data[0].value}',
				titleTemplate: 'Total Active Users',
				width: AppConstants.sizeIdentifiers.medium
			},
			{
				dataPromise: this.apiService.aggregate(
					AppConstants.aggregateMethods.count,
					null,
					null,
					'EntityInstanceId'),
				groupByCount: true,
				summaryTemplate: '${data[0].value}',
				titleTemplate: 'Total Users',
				width: AppConstants.sizeIdentifiers.small
			}
		];
	 }

	 /**
	 * Sets up the table definitions for a standard table
	 *
	 * @memberof SessionsComponent
	 */
	 public setupTableDefinitions(): void
	 {
	 	this.securitySessionsTableDefinitions = {
	 		actions: {
	 			filter: {
	 				quickFilters:
						[
							{
								label: 'Latest Sessions',
								value: AppConstants.empty
							},
							{
								label: 'Latest Active Sessions',
								value: 'Enabled eq true AND ExpireDate > '
									+ `'${DateTime.local().toISO()}'`
							}
						],
	 				selectedFilterValue: this.tableFilterQuery
	 			},
	 			view: {
	 				component: SessionComponent,
	 				layout: [
	 					{
	 						key: 'data.enabled',
	 						type: FormlyConstants.customControls
	 							.customInputSwitch,
	 						wrappers: [
	 							FormlyConstants.customControls
	 								.customFieldWrapper
	 						],
	 						templateOptions: {
	 							label: 'Enabled',
	 							disabled: true
	 						}
	 					},
	 					{
	 						key: 'data.expireDate',
	 						type: FormlyConstants.customControls
	 							.customCalendar,
	 						wrappers: [
	 							FormlyConstants.customControls
	 								.customFieldWrapper
	 						],
	 						templateOptions: {
	 							label: 'Expire Date',
	 							showTime: true,
	 							disabled: true
	 						}
	 					}
	 				],
	 				definition: {
	 					$id: 'http://json-schema.org/draft-07/schema#',
	 					title: 'Sessions',
	 					type: 'object',
	 					additionalProperties: false,
	 					properties: {
	 						data: {
	 							type: 'object',
	 							additionalProperties: false,
	 							properties: {
	 								entityInstanceId: {
	 									type: 'string',
	 									description: 'User'
	 								},
	 								applicationId: {
	 									type: 'string',
	 									description: 'Application'
	 								},
	 								createDate: {
	 									type: [
	 										'string',
	 										'null'
	 									],
	 									description: 'Create Date',
	 									format: 'date-time',
	 									default: AppConstants.empty
	 								},
	 								expireDate: {
	 									type: [
	 										'string',
	 										'null'
	 									],
	 									description: 'Expire Date',
	 									format: 'date-time',
	 									default: AppConstants.empty
	 								},
	 								lastActiveDate: {
	 									type: [
	 										'string',
	 										'null'
	 									],
	 									description: 'Last Active Date',
	 									format: 'date-time',
	 									default: AppConstants.empty
	 								},
	 								token: {
	 									type: 'string',
	 									description: 'Token'
	 								},
	 								enabled: {
	 									type: 'boolean',
	 									description: 'Enabled'
	 								}
	 							}
	 						}
	 					}
	 				},
	 				items: [],
	 			},
	 			update: {
	 				layout: [
	 					{
	 						key: 'data.enabled',
	 						type: FormlyConstants.customControls
	 							.customInputSwitch,
	 						wrappers: [
	 							FormlyConstants.customControls
	 								.customFieldWrapper
	 						],
	 						templateOptions: {
	 							label: 'Enabled'
	 						}
	 					},
	 					{
	 						key: 'data.expireDate',
	 						type: FormlyConstants.customControls
	 							.customCalendar,
	 						wrappers: [
	 							FormlyConstants.customControls
	 								.customFieldWrapper
	 						],
	 						templateOptions: {
	 							label: 'Expire Date',
	 							showTime: true
	 						}
	 					}
	 				],
	 				definition: {
	 					$id: 'http://json-schema.org/draft-07/schema#',
	 					title: 'Sessions',
	 					type: 'object',
	 					additionalProperties: false,
	 					properties: {
	 						data: {
	 							type: 'object',
	 							additionalProperties: false,
	 							properties: {
	 								entityInstanceId: {
	 									type: 'string',
	 									description: 'User'
	 								},
	 								applicationId: {
	 									type: 'string',
	 									description: 'Application'
	 								},
	 								createDate: {
	 									type: 'string',
	 									description: 'Create Date'
	 								},
	 								expireDate: {
	 									type: 'string',
	 									description: 'Expire Date'
	 								},
	 								lastActiveDate: {
	 									type: 'string',
	 									description: 'Last Active Date'
	 								},
	 								token: {
	 									type: 'string',
	 									description: 'Token'
	 								},
	 								enabled: {
	 									type: 'boolean',
	 									description: 'Enabled'
	 								}
	 							}
	 						}
	 					}
	 				},
	 				items: [
	 					{
	 						label: 'Save',
	 						styleClass: AppConstants.cssClasses.pButtonPrimary,
	 						command: async() =>
	 						{
	 							const rowData: any =
									this.commonTableContext.source.rowData;
	 							rowData.expireDate =
									(rowData.enabled === false
										&& this.commonTableContext.source
											.dataBackup.enabled === true)
										? DateTime.local()
											.toISO()
										: rowData.expireDate;

	 							await this.activityService.handleActivity(
	 								new Activity(
	 									this.securitySessionApiService
	 										.update(
	 											rowData.id,
	 											rowData),
	 									'<strong>Updating Sesssion</strong>',
	 									'<strong>Updated Session</strong>',
	 									`Session ${rowData.id} was updated.`,
	 									`Session ${rowData.id} was not `
											+ 'updated.'),
	 								AppConstants.activityStatus.complete,
	 								true);
	 						}
	 					}
	 				]
	 			}
	 		},
	 		tableTitle: 'Sessions',
	 		objectSearch: {
	 			filter: this.tableFilterQuery,
	 			orderBy: `Id ${AppConstants.sortDirections.descending}`,
	 			offset: 0,
	 			limit: AppConstants.dataLimits.large,
	 			virtualIndex: 0,
	 			virtualPageSize: this.tableRowCount
	 		},
	 		apiPromise: async(objectSearch: IObjectSearch) =>
	 		{
	 			const securitySessions: ISecuritySession[] =
					await this.securitySessionApiService
						.query(
							objectSearch.filter,
							objectSearch.orderBy,
							objectSearch.offset,
							objectSearch.limit);

	 			return securitySessions.map(
	 				(session: ISecuritySession) =>
	 				{
	 					session.token =
							session.token.substring(0, 16)
								+ '...';

	 					return session;
	 				});
	 		},
			 availableColumns: this.availableColumns,
			 selectedColumns: this.selectedColumns,
			 columnSelectionMode: this.columnSelectionMode,
	 		expandTitle: () =>
	 			TableHelper.getExpandTitle(
	 				this.commonTableContext,
	 				'Session'),
	 		commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
	 		{
	 			this.commonTableContext = commonTableContext;
	 		},
	 		filterCriteriaChanged: (filterCriteria: string) =>
	 		{
	 			this.tableFilterQuery = filterCriteria;

	 			this.restoreTableDefinition();
	 		},
	 		rowCountChanged: (rowCount: number) =>
	 		{
	 			this.tableRowCount = rowCount;
	 			this.restoreTableDefinition();
	 		},
	 		selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
	 		{
	 			this.securitySessionsTableDefinitions.selectedColumns =
					selectedColumns;
	 			this.selectedColumns = selectedColumns;
	 		},
	 		columnSelectionModeChanged: (columnSelectionMode: boolean) =>
	 		{
	 			this.securitySessionsTableDefinitions.columnSelectionMode =
					columnSelectionMode;
	 			this.columnSelectionMode = columnSelectionMode;
	 		}
	 	};
	 	this.loadingTableDefinitions = false;
	 }
}