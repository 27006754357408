<app-base-page pageTitle="User Management"
	[pageContext]="pageContext"
	utilityMenuOperationGroupName="">

	<div class="ui-g">
		<div class="search-filter-wrapper">
			<app-search-filter
				[loadedFilterCriteria]="filterCriteria"
				[noCategoryFilter]="true"
				(filterCriteriaChanged)="manageUsersTableDefinitions.filterCriteriaChanged($event)">
			</app-search-filter>
		</div>

		<div *ngIf="loadingTableDefinitions"
			class="text-center ui-g-12">
			<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
			</span>
		</div>

		<div *ngIf="!loadingTableDefinitions"
			class="ui-g-12 table-container">
			<app-common-table
				[tableDefinitions]="manageUsersTableDefinitions">
			</app-common-table>
		</div>

	</div>
</app-base-page>