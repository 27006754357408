/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	HammerGestureConfig
} from '@angular/platform-browser';
import {
	Injectable
} from '@angular/core';

/**
 * The primary hammer config for mobile and touch
 * gesture overrides from default.
 * https://hammerjs.github.io/getting-started/
 *
 * @export
 * @class AppHammerConfig
 * @extends {HammerGestureConfig}
 */
@Injectable()
export class AppHammerConfig
	extends HammerGestureConfig
{
	public overrides: any =
		{
			swipe: {
				velocity: 0.15
			},
			press: {
				time: 5
			}
		};
}