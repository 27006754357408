
<div class="list-item-container ui-g ui-g-12 no-padding"
	*ngIf="context.data?.entityInstance?.id === null">
	<span class="panel-sub-title">
		No Data
	</span>
</div>
<div class="list-item-container ui-g ui-g-12 no-padding"
	*ngIf="context.data?.entityInstance?.id != null">
	<ul>
		<ng-template
			[ngIf]="(data?.length || 0) > 0"
			ngFor
			let-item
			[ngForOf]="data">
			<li *ngIf="item.type != null"
				class="section-container">
				<span class="data-value">
					<p-divider align="left">
						<div>
							<span class="fa fa-home">
							</span>
							<span class="panel-sub-title">
								{{item.type}}
							</span>
						</div>
					</p-divider>
					<span class="data-value">
						<strong>Address:</strong>
						<span *ngIf="item.characteristics.addresses[0] != null">
							<span *ngIf="item.characteristics.addresses[0].address != null
								&& item.characteristics.addresses[0].address != ''">
								{{item.characteristics.addresses[0].address}},
							</span>
							<span *ngIf="item.characteristics.addresses[0].city != null
								&& item.characteristics.addresses[0].city != ''">
								{{item.characteristics.addresses[0].city}},
							</span>
							{{item.characteristics.addresses[0].state}}
							{{item.characteristics.addresses[0].postalCode}}
						</span>
					</span>
				</span>
				<br />
			</li>
		</ng-template>
	</ul>
</div>