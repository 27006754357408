/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	cloneDeep
} from 'lodash-es';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	Component,
	ElementRef,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EntityVersionApiService
} from '@api/services/entities/entity-version.api.service';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityDefinition
} from '@shared/interfaces/entities/entity-definition.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IEntityVersion
} from '@shared/interfaces/entities/entity-version.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	Router
} from '@angular/router';

/* eslint-enable max-len */
@Component({
	selector: 'app-entity-manager-search',
	templateUrl: './entity-manager-search.component.html',
	styleUrls: ['./entity-manager-search.component.scss'],
})

/**
 * A component representing an instance of the system entity manager search
 * component.
 *
 * @export
 * @class EntityManagerSearchComponent
 * @extends {CommonTablePageDirective}
 * @implements {OnInit}
 */
export class EntityManagerSearchComponent
	extends CommonTablePageDirective
	implements OnInit
{
	/**
	 * Initializes a new instance of the EntityManagerSearchComponent class.
	 *
	 * @param {EntityDefinitionApiService} entityDefinitionApiService
	 * The api service used to load emtoty definition data.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to load entity type data.
	 * @param {EntityVersionApiService} entityVersionApiService
	 * The api service used to load entity version data.
	 * @param {Router} router
	 * The service that provides navigation on row clickage.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof EntityManagerSearchComponent
	 */
	public constructor(
		public entityDefinitionApiService: EntityDefinitionApiService,
		public entityTypeApiService: EntityTypeApiService,
		public entityVersionApiService: EntityVersionApiService,
		public router: Router,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the search input element.
	 *
	 * @type {ElementRef}
	 * @memberof EntityManagerSearchComponent
	 */
	@ViewChild('SearchInput')
	public searchInputElement: ElementRef;

	/**
	 * Gets or sets the table definitions.
	 *
	 * @type {ICommonTable}
	 * @memberof EntityManagerSearchComponent
	 */
	public tableDefinitions: ICommonTable;

	/**
	 * Gets or sets the search input value.
	 *
	 * @type {string}
	 * @memberof EntityManagerSearchComponent
	 */
	public searchInputValue: string = AppConstants.empty;

	/**
	 * Gets or sets the table filter.
	 *
	 * @type {boolean}
	 * @memberof EntityManagerSearchComponent
	 */
	public tableFilter: boolean = null;

	/**
	 * Gets or sets the route debounce time.
	 *
	 * @type {number}
	 * @memberof EntityManagerSearchComponent
	 */
	private readonly routeDebounceTime: number =
		AppConstants.time.oneHundredMilliseconds;

	/**
	 * Initializes the entity manager search component by seting up page
	 * variables and table definitions.
	 *
	 * @async
	 * @memberof EntityManagerSearchComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		let displayOrder: number = 1;
		this.availableColumns =
			[
				{
					dataKey: 'name',
					columnHeader: 'Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'group',
					columnHeader: 'Group',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'version',
					columnHeader: 'Version',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'enabled',
					columnHeader: 'Enabled',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'startDate',
					columnHeader: 'Start Date',
					displayOrder: displayOrder++,
					dataFormatType: AppConstants.dataFormatTypes.dateTime
				},
				{
					dataKey: 'endDate',
					columnHeader: 'End Date',
					displayOrder: displayOrder,
					dataFormatType: AppConstants.dataFormatTypes.dateTime
				}
			];
		this.selectedColumns = this.availableColumns;

		this.setupTableDefinitions();
	}

	/**
	 * Handles the Enter key press event to excecution the search accion.
	 *
	 * @param {KeyboardEvent} event
	 * The key press event data object.
	 * @memberof EntityManagerSearchComponent
	 */
	public searchOnEnterKeyPress(event: KeyboardEvent): void
	{
		if (event.key === AppConstants.keyBoardKeyConstants.enter)
		{
			this.searchEntity();
		}
	}

	/**
	 * Applies a filter to search by entity type.
	 * This will reload the common table with the entity type data that contains
	 * the input criteria.
	 *
	 * @memberof EntityManagerSearchComponent
	 */
	public searchEntity(): void
	{
		if (this.searchInputElement.nativeElement.value ===
			this.searchInputValue)
		{
			return;
		}

		this.searchInputValue = this.searchInputElement.nativeElement.value;
		this.restoreTableDefinition();
	}

	/**
	 * Sets up the table definitions for the current admin page.
	 *
	 * @memberof EntityManagerSearchComponent
	 */
	public setupTableDefinitions(): void
	{
		this.tableDefinitions = {
			hideExpanderArrow: true,
			rowSelection: {
				selectionMode: AppConstants.rowSelectionMode.single
			},
			actions: {
				drillInOnRowSelection: true,
				filter: {
					quickFilters:
						[
							{
								label: 'All Entities',
								value: null
							},
							{
								label: 'Enabled Entities',
								value: true
							},
							{
								label: 'Disabled Entities',
								value: false
							}
						],
					selectedFilterValue: this.tableFilter
				},
				view: {
					disabledExpandRow: true,
					items: [
						{
							command: async() =>
							{
								setTimeout(() =>
								{
									this.router.navigate(
										[
											'admin/entity/definition/'
												+ 'overview/view',
											this.commonTableContext.data.id
										]);
								},
								this.routeDebounceTime);
							}
						}
					]
				}
			},
			tableTitle: null,
			objectSearch: {
				filter: AppConstants.empty,
				orderBy: `Id ${AppConstants.sortDirections.descending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: this.tableRowCount
			},
			apiPromise: async (objectSearch: IObjectSearch) =>
			{
				const entityDefinitions: IEntityDefinition[] =
						await this.entityDefinitionApiService
							.query(
								objectSearch.filter,
								objectSearch.orderBy,
								objectSearch.offset,
								objectSearch.limit);

				const definitionsSortedByTypeId =
						cloneDeep(entityDefinitions)
							.sort((
								itemOne: any,
								itemTwo: any) =>
								itemOne.typeId - itemTwo.typeId);

				const entityTypes: IEntityType[] =
						await this.entityTypeApiService
							.query(
								`Id ge ${definitionsSortedByTypeId[0].typeId} `
									+ `and Id le ${definitionsSortedByTypeId[
										definitionsSortedByTypeId.length - 1]
										.typeId}`,
								objectSearch.orderBy,
								null,
								objectSearch.limit);

				const definitionsSortedByVersionId =
						cloneDeep(entityDefinitions)
							.sort((
								itemOne: any,
								itemTwo: any) =>
								itemOne.versionId - itemTwo.versionId);

				const entityVersions: IEntityVersion[] =
						await this.entityVersionApiService
							.query(
								'Id ge '
									+ definitionsSortedByVersionId[0].versionId
									+ AppConstants.characters.space
									+ `and Id le ${definitionsSortedByVersionId[
										definitionsSortedByVersionId.length - 1]
										.versionId}`,
								objectSearch.orderBy,
								null,
								objectSearch.limit);

				const dataPromise: object[] = [];

				for (const entityDefinition of entityDefinitions)
				{
					let typeName: string = AppConstants.empty;
					let typeGroup: string = AppConstants.empty;
					let versionNumber: number = null;
					let versionEnabled: boolean = null;
					let versionStartDate: string = null;
					let versionEndDate: string = null;

					for (const entityType of entityTypes)
					{
						if (entityDefinition.typeId === entityType.id)
						{
							typeName =
								entityType.name;
							typeGroup =
								entityType.group;
						}
					}

					for (const entityVersion of entityVersions)
					{
						if (entityDefinition.versionId === entityVersion.id)
						{
							versionNumber =
								entityVersion.number;
							versionEnabled =
								entityVersion.enabled;
							versionStartDate =
								entityVersion.startDate;
							versionEndDate =
								entityVersion.endDate;
						}
					}

					if ((AnyHelper.isNull(this.tableFilter)
						|| versionEnabled === this.tableFilter)
							&& (AnyHelper.isNullOrEmpty(
								this.searchInputValue)
								|| typeName.toLowerCase()
									.includes(
										this.searchInputValue.toLowerCase())))
					{
						dataPromise.push(
							{
								id: entityDefinition.id,
								name: typeName,
								group: typeGroup,
								version: versionNumber,
								enabled: versionEnabled,
								startDate: versionStartDate,
								endDate: versionEndDate,
							});
					}
				}

				return dataPromise;
			},
			availableColumns: this.availableColumns,
			selectedColumns: this.selectedColumns,
			columnSelectionMode: this.columnSelectionMode,
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			filterCriteriaChanged: (filterCriteria: boolean) =>
			{
				this.tableFilter = filterCriteria;
				this.restoreTableDefinition();
			},
			rowCountChanged: (rowCount: number) =>
			{
				this.tableRowCount = rowCount;
				this.restoreTableDefinition();
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.tableDefinitions.selectedColumns =
					selectedColumns;
				this.selectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.tableDefinitions.columnSelectionMode =
					columnSelectionMode;
				this.columnSelectionMode = columnSelectionMode;
			}
		};

		this.loadingTableDefinitions = false;
	}
}
