/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	_DeepPartialObject
} from 'chart.js/types/utils';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ChartConfiguration,
	Tick,
	TimeUnit
} from 'chart.js';
import {
	ChartConstants
} from '@shared/constants/chart-constants';
import {
	DateTime
} from 'luxon';
import {
	Injectable
} from '@angular/core';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/**
 * A class representing a singleton chart factory. This is used to create
 * common initial chart configurations to be displayed site wide.
 *
 * @export
 * @class ChartFactory
 */
@Injectable({
	providedIn: 'root'
})
export class ChartFactory
{
	/**
	 * Initializes a new instance of a chart factory.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used to define and handle
	 * site layout changes.
	 * @memberof ChartFactory
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService)
	{
	}

	/**
	 * Returns a default line chart configuration.
	 *
	 * @param {string} title
	 * The primary chart title.
	 * @param {string[] | string[][] | number[] | number[][]
		| Date[] | Date[][] | DateTime[] | DateTime[][]} labels
	 * The set of labels to be displayed in the line chart's x axis.
	 * @param {number[] | null | undefined | number[][]} data
	 * The data set to be displayed in this line chart.
	 * @param {string} dataLabel
	 * The label used for defining the y axis value type.
	 * @returns {ChartConfiguration}
	 * A valid initial line chart configuration ready for display.
	 * @memberof ChartFactory
	 */
	public lineChart(
		title: string,
		labels: string[] | string[][]
			| number[] | number[][]
			| Date[] | Date[][]
			| DateTime[] | DateTime[][],
		data: number[] | null | undefined | number[][],
		dataLabel: string): ChartConfiguration
	{
		return <ChartConfiguration>
			{
				type: ChartConstants.chartTypes.line,
				data: {
					labels: labels,
					datasets: [
						{
							data: data,
							label: dataLabel,
							borderWidth: 1,
							fill: true,
							spanGaps: false
						}
					]
				},
				options: {
					elements: {
						point: {
							radius: 2,
							hoverRadius: 3
						},
						line: {
							tension: .4
						}
					},
					grid: {
						display: false
					},
					hover: {
						mode: 'index',
						intersect: false
					},
					layout: {
					},
					maintainAspectRatio: true,
					responsive: true,
					scales: {
						x: {
							display: true,
							grid: {
								display: false
							},
							ticks: {
							}
						},
						y: {
							display: true,
							grid: {
								display: false
							},
							ticks: {
								callback: (
									tickValue: string | number,
									_index: number,
									_ticks: Tick[]) =>
								{
									// Remove decimals
									if (typeof tickValue ===
										AppConstants.propertyTypes.number
											&& Math.floor(<number>tickValue) ===
												tickValue)
									{
										return tickValue;
									}

									return null;
								}
							}
						}
					},
					plugins: {
						title: {
							display: true,
							text: title
						},
						tooltip: {
							enabled: true,
							mode: 'index',
							intersect: false,
							yAlign: 'center',
							xAlign: 'right'
						},
						legend: {
						}
					}
				}
			};
	}

	/**
	 * Returns a default bar chart configuration.
	 *
	 * @param {string} title
	 * The primary chart title.
	 * @param {string[] | string[][] | number[] | number[][]
		| Date[] | Date[][] | DateTime[] | DateTime[][]} labels
	 * The set of labels to be displayed in the line chart's x axis.
	 * @param {number[] | null | undefined | number[][]} data
	 * The data set to be displayed in this line chart.
	 * @param {string} dataLabel
	 * The label used for defining the y axis value type.
	 * @returns {ChartConfiguration}
	 * A valid initial line chart configuration ready for display.
	 * @memberof ChartFactory
	 */
	public barChart(
		title: string,
		labels: string[] | string[][]
				| number[] | number[][]
				| Date[] | Date[][]
				| DateTime[] | DateTime[][],
		data: number[] | null | undefined | number[][] = [],
		dataLabel: string = AppConstants.empty): ChartConfiguration
	{
		return <ChartConfiguration>
			{
				type: ChartConstants.chartTypes.bar,
				data: {
					labels: labels,
					datasets: [
						{
							data: data,
							label: dataLabel
						}
					]
				},
				options: {
					scales: {
						y: {
							beginAtZero: true,
							grid: {
								display: false
							},
						}
					},
					plugins: {
						title: {
							display: true,
							text: title
						},
						legend: {
							display: false
						}
					},
					maxBarThickness: 50
				}
			};
	}

	/**
	 * Returns a default stacked bar chart configuration.
	 *
	 * @param {string} title
	 * The primary chart title.
	 * @param {string[] | string[][] | number[] | number[][]
		| Date[] | Date[][] | DateTime[] | DateTime[][]} labels
	 * The set of labels to be displayed in the line chart's x axis.
	 * @param {number[] | null | undefined | number[][]} data
	 * The data set to be displayed in this line chart.
	 * @param {string} dataLabel
	 * The label used for defining the y axis value type.
	 * @param {DateTime[]} dateRange
	 * The x axis date range.
	 * @param {TimeUnit} timeUnit
	 * The time unit used for the chart data sets.
	 * @param {string} dateFormat
	 * The date format to display the x asis labels and tooltips.
	 * @returns {ChartConfiguration}
	 * A valid initial line chart configuration ready for display.
	 * @memberof ChartFactory
	 */
	public stackedBarChart(
		title: string,
		labels: string[] | string[][]
				| number[] | number[][]
				| Date[] | Date[][]
				| DateTime[] | DateTime[][],
		dateRange: DateTime[],
		timeUnit: TimeUnit,
		dateFormat: string,
		stackedTimeFormat: string,
		dataSetLabels: string[] = null): ChartConfiguration
	{
		const chartConfiguration: ChartConfiguration =
			this.barChart(
				title,
				labels);

		chartConfiguration.options.scales =
			<_DeepPartialObject<{}>>
			{
				x: {
					stacked: true,
					dateRange: dateRange,
					timeUnit: timeUnit,
					stackedTimeFormat: stackedTimeFormat,
					dateFormat: dateFormat,
					dataSetLabels: dataSetLabels
				},
				y: {
					stacked: true,
					grid: {
						display: false
					}
				}
			};

		return chartConfiguration;
	}

	/**
	 * Returns a default pie chart configuration.
	 *
	 * @param {string} title
	 * The primary chart title.
	 * @param {string[] | string[][] | number[] | number[][]
		| Date[] | Date[][] | DateTime[] | DateTime[][]} labels
	 * The splitout of data or 'slices' to be displayed in the pie chart.
	 * @param {number[] | null | undefined | number[][]} data
	 * The data set to be displayed in this pie chart.
	 * @param {string} dataLabel
	 * The label used for defining the pie slice value type.
	 * @returns {ChartConfiguration}
	 * A valid initial pie chart configuration ready for display.
	 * @memberof ChartFactory
	 */
	public pieChart(
		title: string,
		labels: string[] | string[][]
			| number[] | number[][]
			| Date[] | Date[][]
			| DateTime[] | DateTime[][],
		data: number[] | null | undefined | number[][],
		dataLabel: string): ChartConfiguration
	{
		return <ChartConfiguration>
		{
			type: ChartConstants.chartTypes.pie,
			data: {
				datasets: [{
					data: data,
					label: dataLabel,
					borderWidth: 1
				}],
				labels: labels
			},
			options: {
				hover: {
					mode: 'point',
					intersect: true
				},
				layout: {
				},
				maintainAspectRatio: true,
				responsive: true,
				plugins: {
					title: {
						display: true,
						text: title
					},
					tooltip: {
						enabled: true,
						mode: 'point',
						intersect: true,
						yAlign: 'center'
					},
					legend: {
					}
				}
			}
		};
	}

	/**
	 * Returns a default pie doughnut configuration.
	 *
	 * @param {string} title
	 * The primary chart title.
	 * @param {string[] | string[][] | number[] | number[][]
		| Date[] | Date[][] | DateTime[] | DateTime[][]} labels
	 * The splitout of data or 'slices' to be displayed in the pie chart.
	 * @param {number[] | null | undefined | number[][]} data
	 * The data set to be displayed in this pie chart.
	 * @param {string} dataLabel
	 * The label used for defining the pie slice value type.
	 * @returns {ChartConfiguration}
	 * A valid initial pie chart configuration ready for display.
	 * @memberof ChartFactory
	 */
	public doughnutChart(
		title: string,
		labels: string[] | string[][]
			| number[] | number[][]
			| Date[] | Date[][]
			| DateTime[] | DateTime[][],
		data: number[] | null | undefined | number[][],
		dataLabel: string): ChartConfiguration
	{
		return <ChartConfiguration>
		{
			type: ChartConstants.chartTypes.doughnut,
			data: {
				labels: labels,
				datasets: [
					{
						label: dataLabel,
						data: data,
						borderWidth: 1
					}
				]
			},
			options: {
				hover: {
					mode: 'point',
					intersect: true
				},
				layout: {
				},
				maintainAspectRatio: true,
				responsive: true,
				plugins: {
					title: {
						display: true,
						text: title
					},
					tooltip: {
						enabled: true,
						mode: 'point',
						intersect: true,
					},
					legend: {
					}
				}
			}
		};
	}

	/**
	 * Returns a default time based line chart configuration.
	 *
	 * @param {string} title
	 * The primary chart title.
	 * @param {Date[] | Date[][] | DateTime[] | DateTime[][]} labels
	 * The set of labels to be displayed in the time based line chart's x axis.
	 * @param {number[] | null | undefined | number[][]} data
	 * The data set to be displayed in this time based  line chart.
	 * @param {string} dataLabel
	 * The label used for defining the y axis value type.
	 * @param {TimeUnit} timeUnit
	 * The unit of time displayed along the x axis.
	 * @see ChartConstants.timeUnits
	 * @param {number} timeUnitStepSize
	 * The expected x value that the time unit will increase for each data
	 * value. Ie: '1' 'day' or '24' 'hour's.
	 * @param {string} tooltipFormat
	 * A datetime based output of available formats. This will define how the
	 * x or time based data value is displayed in the hover tooltip.
	 * @returns {ChartConfiguration}
	 * A valid initial time based line chart configuration ready for display.
	 * @memberof ChartFactory
	 */
	public timeLineChart(
		title: string,
		labels: Date[] | Date[][]
			| DateTime[] | DateTime[][],
		data: number[] | null | undefined | number[][],
		dataLabel: string,
		timeUnit: TimeUnit,
		timeUnitStepSize: number,
		tooltipFormat: string): ChartConfiguration
	{
		const chartConfiguration: ChartConfiguration =
			this.lineChart(
				title,
				labels,
				data,
				dataLabel);

		chartConfiguration.options.scales.x =
			{
				display: true,
				grid: {
					display: false
				},
				type: 'time',
				ticks: {
					sampleSize: timeUnitStepSize
				},
				time: {
					unit: timeUnit,
					tooltipFormat: tooltipFormat,
					displayFormats: {
						'minute': 'h:mm a',
						'hour': 'h:mm a',
						'day': 'M/d/yy',
						'week': 'MMM dd',
						'month': 'MMM',
						'year': 'yyyy'
					}
				}
			};

		return chartConfiguration;
	}
}