/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	Component,
	OnInit
} from '@angular/core';
import {
	DrawerListDirective
} from '@shared/directives/drawer-list-directive';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IEntityInstanceRuleViolation
} from '@shared/interfaces/entities/entity-instance-rule-violation.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IOwnershipGuardComponent
} from '@shared/interfaces/application-objects/ownership-guard-component';
import {
	Location
} from '@angular/common';
import {
	SessionService
} from '@shared/services/session.service';

@Component({
	selector: 'app-rules',
	templateUrl: './rules.component.html',
	styleUrls: [
		'./rules.component.scss'
	]
})

/**
 * A component representing context level rules.
 *
 * @export
 * @class RulesComponent
 * @extends {DrawerListDirective<IEntityInstanceRuleViolation>}
 * @implements {OnInit}
 * @implements {IDynamicComponent<Component, any>}
 * @implements {IOwnershipGuardComponent}
 */
export class RulesComponent
	extends DrawerListDirective<IEntityInstanceRuleViolation>
	implements OnInit, IDynamicComponent<Component, any>,
	IOwnershipGuardComponent
{
	/**
	 * Initializes a new instance of the rules component.
	 *
	 * @param {EntityService} entityService
	 * The entity service.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service.
	 * @param {SessionService} sessionService
	 * The session service.
	 * @param {Location} location
	 * The location object.
	 * @memberof RulesComponent
	 */
	public constructor(
		public entityService: EntityService,
		public sessionService: SessionService,
		public location: Location)
	{
		super();
	}

	/**
	 * Handles the on initialization event.
	 * This method will set configurable properties used in the drawer list
	 * directive and this component's view.
	 *
	 * @async
	 * @memberof RulesComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		if (!await this.isPageOwnershipAllowed())
		{
			this.accessDeniedUrl = this.location.path();
			this.sessionIdentifier = this.sessionService.sessionId;
			this.resources =
				[
					'Entity.Type',
					'Entity.Version'
				];
			this.clientMessage =
				'Access is required to this entity type and version of '
					+ `'${(<EntityInstanceComponent>this.context.source)
						.entityTypeGroup}'.`;
			this.isOwnershipAllowed = false;
		}
	}

	/**
	 * Implements the ownership guard interface.
	 * This will calculate drawer ownership permissions.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * A value signifying whether or not access is allowed to this drawer.
	 * @memberof RulesComponent
	 */
	public async isPageOwnershipAllowed(): Promise<boolean>
	{
		await this.entityService.setStoredVariables();

		const entityInstanceComponent: EntityInstanceComponent =
			<EntityInstanceComponent>this.context.source;
		const entityType: IEntityType =
			this.entityService
				.entityTypes
				.find(
					(type: IEntityType) =>
						type.group ===
							entityInstanceComponent.entityTypeGroup);

		if (AnyHelper.isNull(entityType))
		{
			return false;
		}

		return this.entityService.verifyEntityTypeAccess(
			entityType);
	}
}