<div class="custom-image-input">
	<div class="p-inputgroup">
		<input class="display-none"
			type="text"
			aria-label="customimageinput"
			pInputText
			[formControl]="formControl"
			[formlyAttributes]="field" />
		<span (click)="displayLogoImage($event)"
			clickOutside
			(clickOutside)="siteLayoutChanged()"
			[contentDisplayed]="activeOverlay"
			*ngIf="logoExists()">
			<p-lightbox
				styleClass="logo-image"
				[images]="images">
			</p-lightbox>
		</span>
		<span
			*ngIf="!to.disabled && logoExists()">
			<p-button
				class="removeButton"
				icon="pi pi-times"
				(click)="removeButtonClicked($event)">
			</p-button>
		</span>
	</div>

	<div *ngIf="to.alwaysDisplayUploader || !logoExists()">
		<div *ngIf="to.disabled; else uploader">
			No image/logo uploaded
		</div>
		<ng-template #uploader>
			<p-fileUpload
				#fileUploader
				[url]="images"
				accept="image/*"
				customUpload="true"
				[auto]="to.autoUpload"
				chooseLabel="Browse"
				maxFileSize="1000000"
				[styleClass]="inputStyleClass"
				(uploadHandler)="imageCustomUploader($event)">
				<ng-template pTemplate="content">
					<div class="drag-and-drop-file-prompt">
						Drag the file here to upload
					</div>
				</ng-template>
			</p-fileUpload>
		</ng-template>
	</div>
</div>

<field-validation-message
	[field]="field">
</field-validation-message>