/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	Component,
	HostListener,
	OnInit
} from '@angular/core';
import {
	DocumentHelper
} from '@shared/helpers/document.helper';
import {
	DrawerListDirective
} from '@shared/directives/drawer-list-directive';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	OperationEventParameterConstants
} from '@operation/shared/operation-event-parameter.constants';
import {
	WorkItemConstants
} from '@shared/constants/work-item-constants';

@Component({
	selector: 'app-product-settings',
	templateUrl: './product-settings.component.html'
})

/**
 * A component representing context level product settings.
 *
 * @export
 * @class ProductSettingsComponent
 * @extends {DrawerListDirective<IEntityInstance>}
 * @implements {OnInit}
 * @implements {IDynamicComponent<Component, any>}
 */
export class ProductSettingsComponent
	extends DrawerListDirective<IEntityInstance>
	implements OnInit, IDynamicComponent<Component, any>
{
	/**
	 * Gets or sets the selected item index.
	 *
	 * @type {number}
	 * @memberof ProductSettingsComponent
	 */
	public selectedItemIndex: number = null;

	/**
	 * Handles the reload product settings event.
	 *
	 * @memberof ProductSettingsComponent
	 */
	@HostListener(
		AppEventConstants.reloadProductSettingsEvent,
		[OperationEventParameterConstants.event])
	public reloadProductSettings(): void
	{
		DocumentHelper.setElementDisplay(
			`${AppConstants.cssClasses.pinnedDrawerOverlay}`
				+ `${AppConstants.cssClasses.productSettingsOverlay}`,
			true);

		this.changeDisplayMode(AppConstants.empty);
		setTimeout(
			() =>
			{
				this.changeDisplayMode(AppConstants.displayMode.list);
			});
	}

	 /**
	 * Handles the on initialization event.
	 * This method will set configurable properties used in the drawer list
	 * directive and this component's view.
	 *
	 * @memberof ProductSettingsComponent
	 */
	 public ngOnInit(): void
	 {
	 	this.initialCreateData =
			{
				type:
					(<EntityInstanceComponent>this.context.source)
						.entityType.name,
				references: [
					{
						type: WorkItemConstants.workItemIdentifiers
							.parentNavigationEntityIdentifer,
						identifier:
							(<EntityInstanceComponent>this.context.source)
								.entityInstance.id
					}
				]
			};

	 	this.context.data =
			{
				...this.context.data,
				drawerComponent: this
			};
	 }
}