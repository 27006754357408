/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	Component, OnInit
} from '@angular/core';
import {
	IAssociatedEntityListContext
} from '@shared/interfaces/dynamic-interfaces/associated-entity-list-context.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/* eslint-enable max-len */

@Component({
	selector: 'transaction-interests',
	templateUrl: './transaction-interests.component.html',
	styleUrls: [
		'./transaction-interests.component.scss'
	]
})

/**
 * A component representing an associated company used
 * in the associated entity list.
 *
 * @export
 * @class TransactionInterestsComponent
 * @implements {IDynamicComponent<Component, {
		entityInstance: IEntityInstance,
		entityListContext: IAssociatedEntityListContext
	}>}
 */
export class TransactionInterestsComponent
implements IDynamicComponent<Component, {
		entityInstance: IEntityInstance;
		entityListContext: IAssociatedEntityListContext;
	}>, OnInit
{
	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, IEntityInstance>}
	 * @memberof TransactionInterestsComponent
	 */
	public context: IDynamicComponentContext<Component, {
			entityInstance: IEntityInstance;
			entityListContext: IAssociatedEntityListContext;
		}>;

	/**
	 * Gets or sets the data to iterate within this component.
	 *
	 * @type {any[]}
	 * @memberof TransactionInterestsComponent
	 */
	public data: any[];

	/**
	 * Initiates the component to get the data object.
	 *
	 * @memberof TransactionInterestsComponent
	 */
	public ngOnInit(): void
	{
		if (AnyHelper.isNullOrEmpty(
			this.context.data.entityInstance)
			|| AnyHelper.isNullOrEmpty(
				this.context.data.entityInstance.data?.interests))
		{
			return;
		}

		this.setData();
	}

	/**
	 * Sets the data object used in this component.
	 *
	 * @memberof TransactionInterestsComponent
	 */
	public setData(): void
	{
		this.data = this.context.data.entityInstance.data.interests;

		this.data.forEach((interest: any) =>
		{
			if (!AnyHelper.isNullOrEmpty(interest.type))
			{
				interest.type =
					StringHelper.beforeCapitalSpaces(interest.type);
			}
		});
	}
}