/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppCanDeactivateGuard
} from '@shared/guards/app-can-deactivate.guard';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BaseOperationAction
} from '@operation/actions/base/base-operation-action';
import {
	CacheService
} from '@shared/services/cache.service';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	HttpEvent,
	HttpEventType
} from '@angular/common/http';
import {
	Injectable
} from '@angular/core';
import {
	OperationDefinitionApiService
} from '@api/services/operations/operation-definition.api.service';
import {
	OperationExecutionService
} from '@operation/services/operation-execution.service';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	ResponseType
} from '@shared/constants/enums/response-type.enum';
import {
	SessionService
} from '@shared/services/session.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/**
 * A class representing the action to print a file with the sent
 * parameters.
 *
 * @export
 * @class PrintFileAction
 * @extends {BaseOperationAction}
 */
@Injectable()
export class PrintFileAction
	extends BaseOperationAction
{
	/**
	 * Creates an instance of a generate document action.
	 *
	 * @param {ActivityService} activityService
	 * The activity service used for this action.
	 * @param {sessionService} SessionService
	 * The session service used for this action.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The activity service used for this action.
	 * @param {CacheService} cacheService
	 * The cache service that is used to reset cached associations on print.
	 * @param {OperationExecutionService} operationExecutionService
	 * The operation execution service used for this action.
	 * @param {OperationService} operationService
	 * The operation service used for this action.
	 * @param {OperationDefinitionApiService} operationDefinitionApiService
	 * The operation definition api service used for this action.
	 * @param {AppCanDeactivateGuard} appCanDeactivateGuard
	 * The app can deactivate guard.
	 * @memberof PrintFileAction
	 */
	public constructor(
		public activityService: ActivityService,
		public sessionService: SessionService,
		public entityInstanceApiService: EntityInstanceApiService,
		public cacheService: CacheService,
		protected operationExecutionService: OperationExecutionService,
		protected operationService: OperationService,
		protected operationDefinitionApiService: OperationDefinitionApiService,
		protected appCanDeactivateGuard: AppCanDeactivateGuard)
	{
		super(
			activityService,
			operationExecutionService,
			operationService,
			operationDefinitionApiService,
			appCanDeactivateGuard);
	}

	/**
	 * Gets or sets the operation name.
	 *
	 * @type {string}
	 * @memberof PrintFileAction
	 */
	public operationName: string =
		'PrintFile';

	/**
	 * Gets or sets the action name.
	 *
	 * @type {string}
	 * @memberof PrintFileAction
	 */
	public actionName: string = AppConstants.empty;

	/**
	 * Gets or sets the body parameter.
	 *
	 * @type {string}
	 * @memberof PrintFileAction
	 */
	public bodyParameter: string = AppConstants.empty;

	/**
	 * Gets or sets the id.
	 *
	 * @type {string}
	 * @memberof PrintFileAction
	 */
	public id: string = AppConstants.empty;

	/**
	 * Gets or sets the query string.
	 *
	 * @type {string}
	 * @memberof PrintFileAction
	 */
	public queryString: string = AppConstants.empty;

	/**
	 * Gets or sets the type group.
	 *
	 * @type {string}
	 * @memberof PrintFileAction
	 */
	public typeGroup: string = AppConstants.empty;

	/**
	 * Gets or sets the success message.
	 *
	 * @type {string}
	 * @memberof PrintFileAction
	 */
	public successMessage: string = AppConstants.empty;

	/**
	 * Gets or sets the view result of this generate action.
	 *
	 * @type {string}
	 * @memberof PrintFileAction
	 */
	public view: string = AppConstants.empty;

	/**
	 * Gets or sets the window for opening a print dialogue.
	 *
	 * @type {Window}
	 * @memberof PrintFileAction
	 */
	public printWindow: Window;

	/**
	 * Executes the defined action.
	 *
	 * @async
	 * @memberof PrintFileAction
	 */
	public async execute(): Promise<void>
	{
		await this.setParameters();

		const contextSource: EntityInstanceComponent =
			this.pageContext.source as EntityInstanceComponent;

		const promise: Promise<void> =
			new Promise<void>(
				(resolve, reject) =>
				{
					this.entityInstanceApiService
						.executeActionAsObservable<HttpEvent<any>>(
							contextSource.id,
							this.actionName,
							null,
							this.queryString,
							ResponseType.Blob)
						.subscribe({
							next:
								(event: any) =>
								{
									if (event.type === HttpEventType.Response)
									{
										if (!event.ok)
										{
											reject();

											return;
										}

										const body: any = event.body;

										if (body instanceof Blob)
										{
											this.HandleBlobResponse(body);
										}
									}
								},
							error: (error: any) => reject(error),
							complete: async () =>
							{
								if ((this.pageContext.source instanceof
									EntityInstanceComponent))
								{
									await this
										.clearCachedEntityRelationships(
											this.pageContext.source);
								}

								resolve();

								if (!AnyHelper.isNull(this.printWindow))
								{
									this.printWindow.print();
								}
							}
						});
				});

		await this.activityService.handleActivity<void>(
			new Activity<void>(
				promise,
				'<strong>Printing File.</strong>.',
				'<strong>Printed File.</strong>.',
				this.successMessage,
				'Error generating file.'));
	}

	/**
	 * Clears entity id based relationship caches to allow this updated
	 * document to load in the initial query post generate.
	 *
	 * @async
	 * @param {EntityInstanceComponent} entityInstanceComponent
	 * The entity instance component that will have cleared relationships.
	 * @memberof PrintFileAction
	 */
	public async clearCachedEntityRelationships(
		entityInstanceComponent: EntityInstanceComponent): Promise<void>
	{
		const baseUrl: string
			= entityInstanceComponent
				.entityInstanceApiService
				.getBaseUrl();

		const entityInstanceUrl: string
			= `${baseUrl}/${entityInstanceComponent.id}/children`;

		await this.cacheService
			.clearExistingStartsWithResponses(entityInstanceUrl);
	}

	/**
	 * Sets the paramters for the action.
	 *
	 * @async
	 * @returns {Promise<void>}
	 * An empty promise.
	 * @memberof PrintFileAction
	 */
	private async setParameters(): Promise<void>
	{
		const contextSource: EntityInstanceComponent =
			this.pageContext.source as EntityInstanceComponent;

		this.entityInstanceApiService
			.entityInstanceTypeGroup = contextSource.entityTypeGroup;

		this.typeGroup = !AnyHelper.isNullOrWhitespace(this.typeGroup)
			? StringHelper.interpolate(
				this.typeGroup,
				this.pageContext)
			: this.typeGroup;

		this.id = !AnyHelper.isNullOrWhitespace(this.id)
			? StringHelper.interpolate(
				this.id,
				this.pageContext)
			: this.id;

		this.bodyParameter = !AnyHelper.isNullOrWhitespace(this.bodyParameter)
			? StringHelper.interpolate(
				this.bodyParameter,
				this.pageContext)
			: this.bodyParameter;

		this.queryString = !AnyHelper.isNullOrWhitespace(this.queryString)
			? await StringHelper.transformToDataPromise(
				StringHelper.interpolate(
					this.queryString,
					this.pageContext),
				this.pageContext)
			: this.queryString;
	}

	/**
	 * Handles a blob response.
	 *
	 * @param {Blob} blob
	 * The entity instance component that will have cleared relationships.
	 * @memberof PrintFileAction
	 */
	private HandleBlobResponse(blob: Blob): void
	{
		const fileUrl = window.URL
			.createObjectURL(blob);

		switch (this.view)
		{
			case 'view':

				window.open(
					fileUrl,
					AppConstants
						.windowTargets.blank);

				break;

			case 'open':
				const anchor = document
					.createElement('a');

				document
					.body
					.appendChild(anchor);

				anchor.href = fileUrl;
				anchor.target = AppConstants
					.windowTargets.blank;

				anchor.click();

				document
					.body
					.removeChild(anchor);

				break;

			case 'print':

				this.printWindow = window.open(
					fileUrl,
					AppConstants
						.windowTargets.blank);

				break;

			default:
				return;
		}
	}
}