/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/**
 * A class containing helper methods
 * for the any type.
 *
 * @export
 * @class JsonSchemaLayout
 */
export class JsonSchemaLayout
{
	/**
	 * Gets or sets the Definition Description.
	 *
	 * @type {string}
	 * @memberof JsonSchemaLayout
	 */
	public definitionDescription: string;

	/**
	 * Gets or sets the Definition Enum.
	 *
	 * @type {string}
	 * @memberof JsonSchemaLayout
	 */
	public definitionEnum: string;

	/**
	 * Gets or sets the Definition Format.
	 *
	 * @type {string}
	 * @memberof JsonSchemaLayout
	 */
	public definitionFormat: string;

	/**
	 * Gets or sets the Definition Required Value.
	 *
	 * @type {string}
	 * @memberof JsonSchemaLayout
	 */
	public definitionRequired: boolean;

	/**
	 * Gets or sets the Definition Items.
	 *
	 * @type {any}
	 * @memberof JsonSchemaLayout
	 */
	public definitionItems: any;

	/**
	 * Gets or sets the Definition Items Enum.
	 *
	 * @type {string}
	 * @memberof JsonSchemaLayout
	 */
	public definitionItemsEnum: string;

	/**
	 * Gets or sets the Default Layout.
	 *
	 * @type {Object[]}
	 * @memberof JsonSchemaLayout
	 */
	public defaultLayout: object[] = [];

	/**
	 * Gets or sets the Definition Properties.
	 *
	 * @type {string}
	 * @memberof JsonSchemaLayout
	 */
	public definitionProperties: object;

	/**
	 * Gets or sets the Definition Type.
	 *
	 * @type {string}
	 * @memberof JsonSchemaLayout
	 */
	public definitionType: string;

	/**
	 * Gets or sets the Disabled Layout.
	 *
	 * @type {boolean}
	 * @memberof JsonSchemaLayout
	 */
	public disabledLayout: boolean;

	/**
	 * Gets or sets the if it is a repeater layout definition.
	 *
	 * @type {boolean}
	 * @memberof JsonSchemaLayout
	 */
	public isRepeater: boolean = false;

	/**
	 * Gets or sets the JSON Key Name.
	 *
	 * @type {string}
	 * @memberof JsonSchemaLayout
	 */
	public jsonKeyName: string;

	/**
	 * Gets or sets the JSON Recursive Key Name.
	 *
	 * @type {string}
	 * @memberof JsonSchemaLayout
	 */
	public jsonRecursiveKeyName: string;

	/**
	 * Gets or sets the Layout Field Array.
	 *
	 * @type {{fieldArray:{fieldGroup:any}}}
	 * @memberof JsonSchemaLayout
	 */
	public layoutfieldArray:
	{
		fieldArray:
		{
			fieldGroup: any;
		};
	};

	/**
	 * Gets or sets the Layout Key.
	 *
	 * @type {Object}
	 * @memberof JsonSchemaLayout
	 */
	public layoutKey: object;

	/**
	 * Gets or sets the Layout Template Options.
	 *
	 * @type {any}
	 * @memberof JsonSchemaLayout
	 */
	public layoutTemplateOptions: any;

	/**
	 * Gets or sets the Layout Type.
	 *
	 * @type {{type: string}}
	 * @memberof JsonSchemaLayout
	 */
	public layoutType:
	{
		type: string;
	};

	/**
	 * Gets or sets the Layout Wrapper.
	 *
	 * @type {Object}
	 * @memberof JsonSchemaLayout
	 */
	public layoutWrapper: object;

	/**
	 * Gets or sets the Layout Template Options.
	 *
	 * @type {Object}
	 * @memberof JsonSchemaLayout
	 */
	public mapperKey: object;

	/**
	 * Gets or sets the Layout Template Options.
	 *
	 * @type {Object}
	 * @memberof JsonSchemaLayout
	 */
	public mapperTemplateOptions: object;

	/**
	 * Gets or sets the Layout Template Options.
	 *
	 * @type {Object}
	 * @memberof JsonSchemaLayout
	 */
	public mapperType: object;

	/**
	 * Gets or sets the object-mapper class.
	 *
	 * @type {any}
	 * @memberof JsonSchemaLayout
	 */
	public objectMapper: any = require('object-mapper');

	/**
	 * Gets or sets the Repeater Layout Type.
	 *
	 * @type {{type: string}}
	 * @memberof JsonSchemaLayout
	 */
	public repeaterLayoutType:
	{
		type: string;
	};

	/**
	 * Gets or sets the required fields by key for each
	 * property in the current layout object.
	 *
	 * @type {any}
	 * @memberof JsonSchemaLayout
	 */
	public requiredFields: any = {};

	/**
	 * Gets or sets the value parsed and dereferenced from the
	 * initial json schema definition.
	 *
	 * @type {any}
	 * @memberof JsonSchemaLayout
	 */
	public dereferencedSchema: any;

	/**
	 * Gets the Layout Object Identifier.
	 *
	 * @type {string}
	 * @memberof JsonSchemaLayout
	 */
	private readonly objectIdentifier: string = 'object';

	/**
	 * Gets the Layout Array Identifier.
	 *
	 * @type {string}
	 * @memberof JsonSchemaLayout
	 */
	private readonly arrayIdentifier: string = 'array';

	/**
	 * Gets the Custom Select placeholder.
	 *
	 * @type {string}
	 * @memberof JsonSchemaLayout
	 */
	private readonly customSelectPlaceHolder: string =
		AppConstants.placeholders.selectAnOption;

	/**
	 * Builds the Default Layout.
	 *
	 * @param {string} jsonKeyName
	 * The entity definition.
	 * @memberof JsonSchemaLayout
	 */
	public defaultLayoutBuilder(jsonKeyName: string): void
	{
		const layoutType =
			StringHelper.betweenNumberSpaces(
				StringHelper.beforeCapitalSpaces(
					StringHelper.toProperCase(jsonKeyName)));

		const layoutBuilder = Object.assign(
			this.layoutKey,
			this.layoutType,
			this.layoutWrapper,
			this.layoutTemplateOptions,
			this.layoutfieldArray);

		layoutBuilder.templateOptions.label = layoutType;
		layoutBuilder.templateOptions.disabled = this.disabledLayout;

		this.defaultLayout.push(layoutBuilder);
	}

	/**
	 * Generates the Default Layout upon any given JSON definition.
	 *
	 * @param {Object} entityDefinition
	 * The JsonData entity definition.
	 * @param {boolean} isDisabled
	 * Defines if the Layout is disabled or not. The default value is true.
	 * @memberof JsonSchemaLayout
	 */
	public generateDefaultLayout(
		entityDefinition: object,
		isDisabled: boolean = true): string
	{
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const jref = require('jref');
		this.dereferencedSchema =
			jref.dereference(entityDefinition);
		const definitions = this.dereferencedSchema
			.properties.data.properties;

		this.requiredFields =
			this.getRequiredFields(
				this.dereferencedSchema
					.properties.data);

		this.disabledLayout = isDisabled;

		for (const definition of Object.keys(definitions))
		{
			this.layoutfieldArray = undefined;
			this.jsonKeyName = definition;
			this.setDefinitionValues(definitions[definition]);

			if (this.definitionType === this.objectIdentifier)
			{
				this.requiredFields =
					this.getRequiredFields(
						this.dereferencedSchema
							.properties.data.properties[this.jsonKeyName]);
				this.recursiveDefinitions(
					this.jsonKeyName,
					this.definitionProperties);
			}
			else
			{
				this.layoutTypeDefinition(definitions[definition]);

				if (this.definitionType === this.arrayIdentifier)
				{
					this.jsonRecursiveKeyName = null;
				}
				this.mappingLogic();

				this.layoutObjectMapper(definitions[definition]);
				this.defaultLayoutBuilder(this.jsonKeyName);
			}
		}

		return JSON.stringify(this.defaultLayout);
	}

	/**
	 * Generates the mapping object for the Key Layout.
	 *
	 * @param {string} jsonKeyName
	 * The JSON Key Name of an object.
	 * @param {string} jsonRecursiveKeyName
	 * The JSON Key Name of a recursive object.
	 * @memberof JsonSchemaLayout
	 */
	public keyMap(
		jsonKeyName: string,
		jsonRecursiveKeyName: string): object
	{
		let keyLayout: object;
		if (this.isRepeater)
		{
			keyLayout =
				{
					'description': [
						{
							key: 'key',
							transform: function()
							{
								return jsonRecursiveKeyName;
							}
						}]
				};

			return keyLayout;
		}

		if (AnyHelper.isNullOrEmpty(jsonRecursiveKeyName))
		{
			keyLayout =
			{
				'description': [
					{
						key: 'key',
						transform: function()
						{
							return `data.${jsonKeyName}`;
						}
					}]
			};

			return keyLayout;
		}

		keyLayout =
		{
			'description': [
				{
					key: 'key',
					transform: function()
					{
						return `data.${jsonKeyName}.${jsonRecursiveKeyName}`;
					}
				}]
		};

		return keyLayout;
	}

	/**
	 * Gets or sets the Layout schema elements by mapping with object-mapper.
	 *
	 * @param {any} definition
	 * The entity definition.
	 * @memberof JsonSchemaLayout
	 */
	public layoutObjectMapper(definition: any): void
	{
		definition.type = this.definitionType;
		this.layoutKey = this.objectMapper.merge(
			definition,
			this.mapperKey);

		this.layoutWrapper =
			(this.layoutType.type !==
				FormlyConstants.customControls.customRepeater
				&& this.definitionType !== this.objectIdentifier
				&& this.layoutType.type !==
					FormlyConstants.customControls.customResourceIdentifier)
				? {
					'wrappers': [
						FormlyConstants.customControls.customFieldWrapper]
				}
				: undefined;

		this.layoutTemplateOptions = this.objectMapper
			.merge(
				definition,
				this.mapperTemplateOptions);

		if (this.layoutType.type ===
				FormlyConstants.customControls.customSelect)
		{
			this.layoutTemplateOptions =
				this.customSelectOptionsSetup(this.layoutTemplateOptions);
		}

		if (this.layoutType.type !==
			FormlyConstants.customControls.customRepeater)
		{
			return;
		}

		this.setRepeater();
	}

	/**
	 * Gets or sets repeater data for each layout repeater type.
	 *
	 * @memberof JsonSchemaLayout
	 */
	public setRepeater(): void
	{
		this.isRepeater = true;
		const repeaterProperties =
			this.definitionItems?.properties ||
			this.definitionItems?.anyOf[0].properties;

		this.layoutfieldArray =
		{
			'fieldArray':
				{
					'fieldGroup': []
				}
		};

		if (AnyHelper.isNullOrEmpty(repeaterProperties))
		{
			return;
		}

		for (const repeaterProperty of Object.keys(repeaterProperties))
		{
			this.jsonRecursiveKeyName = repeaterProperty;
			this.setDefinitionValues(
				repeaterProperties[repeaterProperty]);

			let nestedPropertyArray: any = null;
			if (this.definitionType === this.arrayIdentifier)
			{
				const values =
					repeaterProperties[repeaterProperty].items.properties ||
					repeaterProperties[repeaterProperty]
						.items.anyOf[0].properties;

				nestedPropertyArray =
					this.getNestedRepeater(
						repeaterProperty,
						values);
			}

			this.setDefinitionValues(
				repeaterProperties[repeaterProperty]);
			this.layoutTypeDefinition(repeaterProperties
				[repeaterProperty]);
			this.mappingLogic();

			const repeaterLayoutKey = this.objectMapper.merge(
				repeaterProperties[repeaterProperty],
				this.mapperKey);
			const repeaterLayoutWrapper =
				(this.definitionType !== this.objectIdentifier
					&& this.definitionType !== this.arrayIdentifier
					&& this.repeaterLayoutType.type !==
						FormlyConstants.customControls.customResourceIdentifier)
					? {
						'wrappers': [
							FormlyConstants.customControls.customFieldWrapper]
					}
					: undefined;

			let repeaterLayoutTemplateOptions =
				this.objectMapper.merge(
					repeaterProperties[repeaterProperty],
					this.mapperTemplateOptions);

			const layoutType = StringHelper.toProperCase(
				this.jsonRecursiveKeyName);

			repeaterLayoutTemplateOptions.templateOptions.label =
				layoutType;
			repeaterLayoutTemplateOptions.templateOptions.disabled =
				this.disabledLayout;

			if (this.repeaterLayoutType.type ===
					FormlyConstants.customControls.customSelect)
			{
				repeaterLayoutTemplateOptions =
					this.customSelectOptionsSetup(
						repeaterLayoutTemplateOptions);
			}

			const repeaterBuilder =
				Object.assign(
					repeaterLayoutKey,
					this.repeaterLayoutType,
					repeaterLayoutWrapper,
					repeaterLayoutTemplateOptions,
					nestedPropertyArray);

			this.layoutfieldArray.fieldArray.fieldGroup
				.push(repeaterBuilder);
		}

		this.isRepeater = false;
		this.repeaterLayoutType = undefined;
	}

	/**
	 * Recursively loops through each repeater in a main level
	 * repeater item and adds definitions and properties and
	 * nested repeater displays.
	 *
	 * @returns {any}
	 * The field array for the nested repeater holding definitions
	 * and nested array based repeaters.
	 * @memberof JsonSchemaLayout
	 */
	public getNestedRepeater(
		primaryRepeaterName: string,
		repeaterItemProperties: any[]): any
	{
		const repeaterPropertyArray: any =
			{
				'fieldArray':
					{
						'fieldGroup': []
					}
			};
		let nestedPropertyArray: any = null;

		for (const nestedRepeaterPropertyName of Object.keys(
			repeaterItemProperties))
		{
			this.jsonRecursiveKeyName = nestedRepeaterPropertyName;
			const arrayProperty: any =
				repeaterItemProperties[nestedRepeaterPropertyName];

			this.setDefinitionValues(arrayProperty);

			if (this.definitionType === this.arrayIdentifier)
			{
				nestedPropertyArray =
					this.getNestedRepeater(
						nestedRepeaterPropertyName,
						repeaterItemProperties[nestedRepeaterPropertyName]
							.items.properties);
			}

			this.setDefinitionValues(arrayProperty);
			this.layoutTypeDefinition(arrayProperty);
			this.mappingLogic();

			const repeaterLayoutKey =
				this.objectMapper.merge(
					arrayProperty,
					this.mapperKey);

			const repeaterLayoutWrapper =
				(this.definitionType !== this.objectIdentifier
					&& this.definitionType !== this.arrayIdentifier
					&& this.repeaterLayoutType.type !==
						FormlyConstants.customControls.customResourceIdentifier)
					? {
						'wrappers': [
							FormlyConstants.customControls.customFieldWrapper]
					}
					: undefined;

			let repeaterLayoutTemplateOptions =
				this.objectMapper.merge(
					arrayProperty,
					this.mapperTemplateOptions);

			const layoutType = StringHelper.toProperCase(
				nestedRepeaterPropertyName);
			repeaterLayoutTemplateOptions.templateOptions.label =
				layoutType;
			repeaterLayoutTemplateOptions.templateOptions.disabled =
				this.disabledLayout;

			if (this.repeaterLayoutType.type ===
					FormlyConstants.customControls.customSelect)
			{
				repeaterLayoutTemplateOptions =
					this.customSelectOptionsSetup(
						repeaterLayoutTemplateOptions);
			}

			const repeaterBuilder =
				Object.assign(
					repeaterLayoutKey,
					this.repeaterLayoutType,
					repeaterLayoutWrapper,
					repeaterLayoutTemplateOptions,
					nestedPropertyArray);

			repeaterPropertyArray.fieldArray.fieldGroup.push(
				repeaterBuilder);

			this.jsonRecursiveKeyName = primaryRepeaterName;
		}

		return repeaterPropertyArray;
	}

	/**
	 * Sets up the custom select template options.
	 *
	 * @param {any} selectTemplateOptions
	 * The custom select template options to be formated.
	 * @returns {object}
	 * The templace options for a custom select.
	 * @memberof JsonSchemaLayout
	 */
	public customSelectOptionsSetup(selectTemplateOptions: any): object
	{
		selectTemplateOptions.templateOptions.placeholder =
			this.customSelectPlaceHolder;

		selectTemplateOptions.templateOptions.showClear =
			(AnyHelper
				.isNullOrEmpty(
					selectTemplateOptions.templateOptions.options[0].value));

		selectTemplateOptions.templateOptions.options =
			selectTemplateOptions.templateOptions.options
				.filter((option: any) =>
				{
					if (AnyHelper.isNullOrEmpty(option.value))
					{
						return null;
					}

					return option;
				});

		return selectTemplateOptions;
	}

	/**
	 * Gets or sets the Layout Types.
	 *
	 * @param {any} definition
	 * The entity definition.
	 * @memberof JsonSchemaLayout
	 */
	public layoutTypeDefinition(definition: any): void
	{
		definition.type = this.definitionType;

		this.mapperType = this.typeMap(
			this.definitionEnum,
			this.definitionDescription,
			this.definitionFormat,
			this.definitionItemsEnum,
			this.definitionItems,
			this.disabledLayout);

		if (this.isRepeater === true)
		{
			this.repeaterLayoutType =
				this.objectMapper(
					definition,
					this.mapperType);
		}
		else
		{
			this.layoutType =
				this.objectMapper(
					definition,
					this.mapperType);
		}
	}

	/**
	 * Gets or sets the Mapping Logic for Wrappers, Keys and Template Options.
	 *
	 * @memberof JsonSchemaLayout
	 */
	public mappingLogic(): void
	{
		this.mapperKey = this.keyMap(
			this.jsonKeyName,
			this.jsonRecursiveKeyName);

		this.mapperTemplateOptions = this.templateOptionsMap();
	}

	/**
	 * Gets or sets the recursive definitions.
	 *
	 * @param {string} jsonKeyName
	 * The entity definition.
	 * @memberof JsonSchemaLayout
	 */
	public recursiveDefinitions(
		jsonKeyName: string,
		recursiveProperties: object): void
	{
		const titleName =
			StringHelper.betweenNumberSpaces(
				(StringHelper.beforeCapitalSpaces(
					(StringHelper.toProperCase(jsonKeyName)))));

		const layoutSectionTitle =
			{
				'type': 'custom-section-title',
				'templateOptions':
				{
					'label': titleName
				}
			};
		this.defaultLayout.push(layoutSectionTitle);

		if (AnyHelper.isNullOrEmpty(recursiveProperties))
		{
			return;
		}

		for (const recursiveProperty of
			Object.keys(recursiveProperties))
		{
			this.jsonRecursiveKeyName = recursiveProperty;
			this.setDefinitionValues(
				recursiveProperties[recursiveProperty]);
			this.layoutTypeDefinition(
				recursiveProperties[recursiveProperty]);
			this.mappingLogic();
			this.layoutObjectMapper(
				recursiveProperties[recursiveProperty]);
			this.defaultLayoutBuilder(this.jsonRecursiveKeyName);
			this.jsonRecursiveKeyName = undefined;
		}
	}

	/**
	 * Gets or sets the definition values from entity definition JSON.
	 *
	 * @param {any} definition
	 * The entity definition.
	 * @memberof JsonSchemaLayout
	 */
	public setDefinitionValues(definition: any): void
	{
		this.definitionEnum = definition.enum;
		this.definitionItems = definition.items;
		this.definitionDescription = definition.description;
		this.definitionFormat = definition.format;
		this.definitionProperties = definition.properties;
		this.definitionItemsEnum = null;

		if (!AnyHelper.isNullOrEmpty(this.definitionItems))
		{
			this.definitionItemsEnum = definition.items.enum;
			this.requiredFields =
				AnyHelper.isNullOrEmpty(this.definitionItems?.required)
					? []
					: this.definitionItems.required;
		}

		if (!AnyHelper.isNullOrEmpty(definition.type))
		{
			const tempType = (typeof definition.type !== this.objectIdentifier)
				? definition.type
				: definition.type[0];

			this.definitionType = tempType.toLowerCase();

			return;
		}

		this.definitionType = definition.type;
	}

	/**
	 * Gets or sets the mapping object for the Template Options Layout.
	 *
	 * @memberof JsonSchemaLayout
	 */
	public templateOptionsMap(): object
	{
		if (!AnyHelper.isNullOrEmpty(this.repeaterLayoutType))
		{
			return this.templateOptionsMapBuilder(this.repeaterLayoutType.type);
		}
		else if (!AnyHelper.isNullOrEmpty(this.layoutType))
		{
			return this.templateOptionsMapBuilder(this.layoutType.type);
		}

		return null;
	}

	/**
	 * Generates the mapping object for the Template Options Layout.
	 *
	 * @param {string} layoutType
	 * The layout type.
	 * @memberof JsonSchemaLayout
	 */
	public templateOptionsMapBuilder(
		layoutType: string): object
	{
		const required =
			this.requiredFields.filter(
				(filterKey: string) =>
					filterKey === this.jsonRecursiveKeyName
						|| filterKey === this.jsonKeyName).length > 0;

		let layoutTemplateOptions = {};
		const commonTemplateOptions =
			{
				'type': [
					'templateOptions.label',
					{
						key: 'templateOptions.disabled',
						transform: function()
						{
							return;
						}
					}
				],
				'description': [
					'templateOptions.description'
				],
				'default': [
					'templateOptions.default'
				],
				'required': [
					{
						key : 'templateOptions.required',
						transform: function (
							_value: any,
							definition: any)
						{
							if (definition.type === this.arrayIdentifier
								&& definition.minItems > 0)
							{
								return true;
							}

							return required;
						}.bind(this)
					}
				]
			};
		let specificTemplateOptions: any;

		switch (layoutType)
		{
			case FormlyConstants.customControls.customSelect:
			{
				specificTemplateOptions =
					{
						'enum': [
							{
								key : 'templateOptions.options[]',
								transform: (value: any) =>
									this.getOption(value)
							}]
					};
				break;
			}
			case FormlyConstants.customControls.customMultiSelect:
			{
				specificTemplateOptions =
					{
						'items.enum': [
							{
								key : 'templateOptions.options[]',
								transform: (value: any) =>
									this.getOption(value)
							}]
					};
				break;
			}
			case FormlyConstants.customControls.customCalendar:
			{
				specificTemplateOptions =
					{
						'default':
						{
							key : 'templateOptions.showTime',
							transform: function () {
								return false;
							}
						}
					};
				break;
			}
			case FormlyConstants.customControls.customImageInput:
			{
				specificTemplateOptions =
					{
						'default': [
							'templateOptions.altText'
						]
					};
				break;
			}
			case FormlyConstants.customControls.customRepeater:
			{
				const values =
					this.definitionItems?.properties
					|| this.definitionItems?.anyOf[0].properties;

				if (AnyHelper.isNullOrEmpty(values))
				{
					return null;
				}

				const titleFormat = [];
				for (const value of Object.keys(values))
				{
					if (titleFormat.length < 1
						&& value.indexOf('resourceIdentifier') === -1)
					{
						titleFormat.push({value});
					}
				}

				specificTemplateOptions =
					{
						'default': [
							{
								key: 'templateOptions.singular',
								transform: function()
								{
									return 'Item';
								}
							},
							{
								key: 'templateOptions.titleFormat',
								transform: function()
								{
									return '{' + titleFormat[0].value + '}';
								}
							},
							{
								key: 'templateOptions.minItems',
								transform: function(
									_value: any,
									definition: any)
								{
									return definition.minItems;
								}
							},
							{
								key: 'templateOptions.maxItems',
								transform: function(
									_value: any,
									definition: any)
								{
									return definition.maxItems;
								}
							}
						]
					};
				break;
			}
			case FormlyConstants.customControls.input:
			{
				specificTemplateOptions =
					{
						'default': [
							{
								key: 'templateOptions.pattern',
								transform: function(
									_value: any,
									definition: any)
								{
									return AnyHelper.isNullOrWhitespace(
										definition.pattern)
										? null
										: definition.pattern;
								}
							}
						]
					};
				break;
			}
			case FormlyConstants.customControls.customInputNumber:
			{
				specificTemplateOptions =
					{
						'default': [
							{
								key: 'templateOptions.max',
								transform: function(
									_value: any,
									definition: any)
								{
									return AnyHelper.isNullOrWhitespace(
										definition.maximum)
										? null
										: definition.maximum;
								}
							},
							{
								key: 'templateOptions.min',
								transform: function(
									_value: any,
									definition: any)
								{
									return AnyHelper.isNullOrWhitespace(
										definition.minimum)
										? null
										: definition.minimum;
								}
							},
							{
								key: 'templateOptions.multipleOf',
								transform: function(
									_value: any,
									definition: any)
								{
									return AnyHelper.isNullOrWhitespace(
										definition.multipleOf)
										? 1
										: definition.multipleOf;
								}
							},
							{
								key: 'templateOptions.pattern',
								transform: function(
									_value: any,
									definition: any)
								{
									return AnyHelper.isNullOrWhitespace(
										definition.pattern)
										? null
										: definition.pattern;
								}
							}
						]
					};
				break;
			}
			default:
			{
				break;
			}
		}

		layoutTemplateOptions = Object.assign(
			commonTemplateOptions,
			specificTemplateOptions);

		return layoutTemplateOptions;
	}

	/**
	 * Generates the mapping object for the Type Layout.
	 *
	 * @param {string} definitionEnum
	 * The JSON Key Name of an object.
	 * @param {string} definitionDescription
	 * The JSON Key Name of a recursive object.
	 * @param {string} _definitionFormat
	 * The JSON Key Name of a recursive object.
	 * @param {string} definitionItemsEnum
	 * The JSON Key Name of a recursive object.
	 * @param {string} definitionItems
	 * The JSON Key Name of a recursive object.
	 * @memberof JsonSchemaLayout
	 */
	public typeMap(
		definitionEnum: string,
		definitionDescription: string,
		definitionFormat: string,
		definitionItemsEnum: string,
		definitionItems: string,
		disabledLayout: boolean): object
	{
		return {
			'type': [
				{
					key: 'type',
					transform: function(value: any)
					{
						let tempType: string;
						switch (value)
						{
							case 'string':
							{
								if (!AnyHelper.isNullOrEmpty(definitionEnum))
								{
									tempType = 'custom-select';
								}
								else
								{
									if (definitionDescription ===
									'Resource Identifier')
									{
										tempType = 'custom-resource-identifier';
										break;
									}

									const description = StringHelper
										.normalizeCamelcase(
											definitionDescription);
									const standardDefinitionFormat: string =
										(definitionFormat === 'date'
										|| definitionFormat ===
											'date-time')
											? 'custom-calendar'
											: 'input';

									tempType =
										(description === 'Logo'
											|| description === 'Image')
											? 'custom-image-input'
											: standardDefinitionFormat;
								}
								break;
							}
							case 'array':
							{
								if (!AnyHelper.isNullOrEmpty(
									definitionItemsEnum))
								{
									tempType = (!disabledLayout)
										? 'custom-multi-select'
										: 'input';
								}
								else
								{
									tempType =
									(!AnyHelper.isNullOrEmpty(definitionItems))
										? 'custom-repeater'
										: 'custom-select';
								}
								break;
							}
							case 'object':
							{
								tempType = 'custom-section-title';
								break;
							}
							case 'boolean':
							{
								tempType = 'custom-checkbox';
								break;
							}
							case 'number':
							{
								tempType = 'custom-input-number';
								break;
							}
							default:
							{
								tempType = 'input';
								break;
							}
						}

						return tempType;
					}
				}
			]
		};
	}

	/**
	 * Parses the object and returns the string array of required values
	 * for the current layout object.
	 *
	 * @param {any} object
	 * The current layout object being parsed.
	 * @memberof JsonSchemaLayout
	 */
	private getRequiredFields(
		object: any): string[]
	{
		return AnyHelper.isNull(object?.required)
			? []
			: object.required;
	}

	/**
	 * Parses the value and returns a dropdown option representing the sent
	 * value.
	 *
	 * @param {any} value
	 * The current layout object being mapped.
	 * @returns {IDropdownOption}
	 * A mapped and cleaned dropdown option ready for display.
	 * @memberof JsonSchemaLayout
	 */
	private getOption(value: any): IDropdownOption
	{
		return <IDropdownOption>
			{
				value: value,
				label:
					StringHelper.betweenNumberSpaces(
						StringHelper.beforeCapitalSpaces(
							StringHelper.toProperCase(
								value)))
			};
	}
}