/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ChangeDetectorRef,
	Component
} from '@angular/core';
import {
	ExtendedCustomControlDirective
} from '@entity/directives/extended-custom-control.directive';

@Component({
	selector: 'custom-input-number',
	templateUrl: './custom-input-number.component.html',
	styleUrls: [
		'./custom-input-number.component.scss'
	]
})

/**
 * A component representing an instance of a Custom Input Number.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomInputNumberComponent
 * @extends {ExtendedCustomControlDirective}
 */
export class CustomInputNumberComponent
	extends ExtendedCustomControlDirective
{
	/** Initializes a new instance of the CustomInputNumberComponent.
	 *
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof CustomInputNumberComponent
	 */
	public constructor(
		public changeDetector: ChangeDetectorRef)
	{
		super(changeDetector);
	}

	/** Handles the onInput event sent from the input number control.
	 *
	 * @param {any} event
	 * The event sent from the onInput handler.
	 * @memberof CustomInputNumberComponent
	 */
	public onChange(
		event: any)
	{
		this.field.formControl.setValue(event.value);
		this.validateControl();

		if (!AnyHelper.isNull(this.field.templateOptions.change))
		{
			this.field.templateOptions.change(
				this.field,
				event);
		}
	}

	/** Handles the onBlur event when losing focus
	 * from the input number control.
	 *
	 * @memberof CustomInputNumberComponent
	 */
	public onBlur()
	{
		if (!AnyHelper.isNullOrEmpty(
			this.field.templateOptions?.roundToNearest))
		{
			const currentValue: number =
				this.field.formControl.value;

			const roundToNearest: number =
				this.field.templateOptions.roundToNearest;

			const roundedValue: number =
				Math.round(currentValue / roundToNearest) * roundToNearest;

			this.field.formControl.setValue(roundedValue);
			this.validateControl();
		}
	}
}