/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */

import {
	AccessDeniedComponent
} from '@appComponents/access-denied/access-denied.component';
import {
	ActionDefinitionComponent
} from '@admin/components/workflow-engine/action-definitions/action-definition/action-definition.component';
import {
	ActionDefinitionExpandComponent
} from '@admin/components/workflow-engine/action-definitions/action-definitions/action-definition-expand/action-definition-expand.component';
import {
	ActionDefinitionRouterComponent
} from '@admin/components/workflow-engine/action-definitions/action-definition-router.component';
import {
	ActionDefinitionsComponent
} from '@admin/components/workflow-engine/action-definitions/action-definitions/action-definitions.component';
import {
	ActionExecutionHistoryComponent
} from '@admin/components/workflow-engine/action-execution-history/action-execution-history.component';
import {
	ActionHistoryExpandComponent
} from '@admin/components/workflow-engine/action-execution-history/action-history-expand/action-history-expand.component';
import {
	AdminComponent
} from '@admin/admin.component';
import {
	AppAuthGuard
} from '@shared/guards/app-auth.guard';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ApplicationsComponent
} from '@admin/components/security/applications/applications.component';
import {
	BaseExpandComponent
} from '@shared/components/common-table/base-expand/base-expand.component';
import {
	CommonModule
} from '@angular/common';
import {
	DefaultsComponent
} from '@admin/components/user-interface/defaults/defaults.component';
import {
	DisplayDefinitionsComponent
} from '@admin/components/user-interface/display-components/display-definitions/display-definitions.component';
import {
	DisplayManagementComponent
} from '@admin/components/user-interface/display-components/display-management/display-management.component';
import {
	DisplayManagementExpandComponent
} from '@admin/components/user-interface/display-components/display-management/display-management-expand/display-management-expand.component';
import {
	EntityAccessPoliciesComponent
} from '@admin/components/entity/entity-access-policies/entity-access-policies.component';
import {
	EntityAccessPolicyDefinitionComponent
} from '@admin/components/entity/entity-access-policy-definition/entity-access-policy-definition.component';
import {
	EntityActionDefinitionComponent
} from '@admin/components/entity/entity-action-definition/entity-action-definition.component';
import {
	EntityActionsComponent
} from '@admin/components/entity/entity-actions/entity-actions.component';
import {
	EntityComponent
} from '@entity/entity.component';
import {
	EntityDefinitionsComponent
} from '@admin/components/entity/entity-definitions/entity-definitions.component';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	EntityLayoutDefinitionComponent
} from '@admin/components/entity/entity-layout-definition/entity-layout-definition.component';
import {
	EntityLayoutsComponent
} from '@admin/components/entity/entity-layouts/entity-layouts.component';
import {
	EntityManagerSearchComponent
} from '@admin/components/entity/entity-search/entity-manager-search.component';
import {
	EntityOverviewComponent
} from '@admin/components/entity/entity-overview/entity-overview.component';
import {
	EntityRuleDefinitionComponent
} from '@admin/components/entity/entity-rule-definition/entity-rule-definition.component';
import {
	EntityRulePresentationComponent
} from '@admin/components/entity/entity-rule-presentation/entity-rule-presentation.component';
import {
	EntityRulesComponent
} from '@admin/components/entity/entity-rules/entity-rules.component';
import {
	EntityRuleValidationsComponent
} from '@admin/components/entity/entity-rule-validations/entity-rule-validations.component';
import {
	EntityRuleWorkflowComponent
} from '@admin/components/entity/entity-rule-workflow/entity-rule-workflow.component';
import {
	EntitySearchComponent
} from '@entity/components/entity-search/entity-search.component';
import {
	GenericBasePageComponent
} from '@appComponents/generic-base-page/generic-base-page.component';
import {
	GenericDashboardComponent
} from '@appComponents/generic-dashboard/generic-dashboard.component';
import {
	LogComponent
} from '@admin/components/system/logs/log-expand/log.component';
import {
	LogosComponent
} from '@admin/components/user-interface/logos/logos.component';
import {
	LogsComponent
} from '@admin/components/system/logs/logs.component';
import {
	MaintenanceComponent
} from '@admin/components/system/maintenance/maintenance.component';
import {
	ManageUserComponent
} from '@admin/components/security/users/manage-user-expand/manage-user-expand.component';
import {
	ManageUserGroupComponent
} from '@admin/components/security/users/manage-user-group-expand/manage-user-group.component';
import {
	ManageUserGroupsComponent
} from '@admin/components/security/users/manage-user-groups/manage-user-groups.component';
import {
	ManageUsersComponent
} from '@admin/components/security/users/manage-users/manage-users.component';
import {
	ModuleGuard
} from '@shared/guards/module.guard';
import {
	NgModule
} from '@angular/core';
import {
	OperationDefinitionComponent
} from '@admin/components/user-interface/operations/operation-definitions/operation-expand/operation-definition.component';
import {
	OperationDefinitionsComponent
} from '@admin/components/user-interface/operations/operation-definitions/operation-definitions.component';
import {
	OperationGroupComponent
} from '@admin/components/user-interface/operations/operation-groups/operation-group-expand/operation-group.component';
import {
	OperationGroupCreateChildComponent
} from '@admin/components/user-interface/operations/operation-groups/operation-group-expand/operation-group-create/operation-group-create-child.component';
import {
	OperationGroupsComponent
} from '@admin/components/user-interface/operations/operation-groups/operation-groups.component';
import {
	RouterModule
} from '@angular/router';
import {
	SessionComponent
} from '@admin/components/security/sessions/session-expand/session.component';
import {
	SessionEventsComponent
} from '@admin/components/security/session-events/session-events.component';
import {
	SessionsComponent
} from '@admin/components/security/sessions/sessions.component';
import {
	SharedModule
} from '@shared/shared.module';
import {
	StylesComponent
} from '@admin/components/user-interface/styles/styles.component';
import {
	TestBuilderComponent
} from '@admin/components/system/test-builder/test-builder.component';

/* eslint-enable max-len */

@NgModule({
	declarations: [
		ActionDefinitionComponent,
		ActionDefinitionExpandComponent,
		ActionDefinitionRouterComponent,
		ActionDefinitionsComponent,
		ActionExecutionHistoryComponent,
		ActionHistoryExpandComponent,
		AdminComponent,
		ApplicationsComponent,
		BaseExpandComponent,
		DefaultsComponent,
		DisplayDefinitionsComponent,
		DisplayManagementComponent,
		DisplayManagementExpandComponent,
		EntityAccessPoliciesComponent,
		EntityAccessPolicyDefinitionComponent,
		EntityActionsComponent,
		EntityActionDefinitionComponent,
		EntityDefinitionsComponent,
		EntityLayoutsComponent,
		EntityLayoutDefinitionComponent,
		EntityManagerSearchComponent,
		EntityOverviewComponent,
		EntityRulesComponent,
		EntityRuleDefinitionComponent,
		EntityRulePresentationComponent,
		EntityRuleWorkflowComponent,
		EntityRuleValidationsComponent,
		LogComponent,
		LogosComponent,
		LogsComponent,
		MaintenanceComponent,
		ManageUserGroupsComponent,
		ManageUserGroupComponent,
		ManageUsersComponent,
		ManageUserComponent,
		OperationDefinitionComponent,
		OperationDefinitionsComponent,
		OperationGroupComponent,
		OperationGroupCreateChildComponent,
		OperationGroupsComponent,
		SessionComponent,
		SessionEventsComponent,
		SessionsComponent,
		StylesComponent,
		TestBuilderComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		RouterModule.forChild([
			{
				path: 'admin',
				component: AdminComponent,
				canActivate: [
					AppAuthGuard,
					ModuleGuard
				],
				data:
				{
					roles: [
						AppConstants.securityGroups.adminModuleAccess,
						AppConstants.securityGroups.administrators
					]
				},
				children: [
					{
						path: 'accessDenied',
						component: AccessDeniedComponent
					},
					{
						path: 'dashboard',
						component: GenericDashboardComponent
					},
					{
						path: 'display',
						children: [
							{
								path: ':displayComponent/view',
								component: GenericBasePageComponent
							},
							{
								path: ':displayComponent/edit',
								component: GenericBasePageComponent,
								canActivate: [
									AppAuthGuard
								],
								data:
								{
									roles: [
										AppConstants.securityGroups
											.users,
										AppConstants.securityGroups
											.administrators
									]
								}
							}
						]
					},
					{
						path: 'entities',
						component: EntityComponent,
						children: [
							{
								path: ':entityType/view/:id',
								component: EntityInstanceComponent
							},
							{
								path: ':entityType/edit/:id',
								component: EntityInstanceComponent
							},
							{
								path: 'display',
								children: [
									{
										path: ':displayComponent/create',
										component: GenericBasePageComponent
									}
								]
							},
							{
								path: '',
								component: EntitySearchComponent
							}
						]
					},
					{
						path: 'entity',
						children: [
							{
								path: 'search',
								component: EntityManagerSearchComponent
							},
							{
								path: 'definition/edit/:id',
								component: EntityDefinitionsComponent
							},
							{
								path: 'definition/overview/view/:id',
								component: EntityOverviewComponent,
							},
							{
								path: 'layouts/edit/:id',
								component: EntityLayoutsComponent
							},
							{
								path: 'layoutDefinition/edit/:id',
								component: EntityLayoutDefinitionComponent
							},
							{
								path: 'actions/edit/:id',
								component: EntityActionsComponent
							},
							{
								path: 'actionDefinition/edit/:id',
								component: EntityActionDefinitionComponent
							},
							{
								path: 'accessPolicies/edit/:id',
								component: EntityAccessPoliciesComponent
							},
							{
								path: 'accessPolicyDefinition/edit/:id',
								component: EntityAccessPolicyDefinitionComponent
							},
							{
								path: 'rules/edit/:id',
								component: EntityRulesComponent
							},
							{
								path: 'ruleDefinition/edit/:id',
								component: EntityRuleDefinitionComponent
							},
							{
								path: 'ruleWorkflow/edit/:id',
								component: EntityRuleWorkflowComponent,
							},
							{
								path: 'ruleValidations/edit/:id',
								component: EntityRuleValidationsComponent,
							},
							{
								path: 'rulePresentation/edit/:id',
								component: EntityRulePresentationComponent,
							}
						]
					},
					{
						path: 'security',
						children: [
							{
								path: 'applications',
								component: ApplicationsComponent
							},
							{
								path: 'sessionEvents',
								component: SessionEventsComponent
							},
							{
								path: 'sessions',
								component: SessionsComponent
							},
							{
								path: 'users',
								children: [
									{
										path: 'groups',
										component: ManageUserGroupsComponent
									},
									{
										path: 'manage',
										component: ManageUsersComponent
									}
								]
							}
						]
					},
					{
						path: 'system',
						children: [
							{
								path: 'logs',
								component: LogsComponent
							},
							{
								path: 'maintenance',
								component: MaintenanceComponent
							},
							{
								path: 'testBuilder',
								component: TestBuilderComponent
							}
						]
					},
					{
						path: 'userInterface',
						children: [
							{
								path: 'defaults',
								component: DefaultsComponent
							},
							{
								path: 'logos',
								component: LogosComponent
							},
							{
								path: 'operations',
								children: [
									{
										path: 'definitions',
										component: OperationDefinitionsComponent
									},
									{
										path: 'groups',
										component: OperationGroupsComponent
									}
								]
							},
							{
								path: 'displayComponents',
								children: [
									{
										path: 'definitions',
										component: DisplayDefinitionsComponent
									},
									{
										path: 'management',
										component: DisplayManagementComponent
									}
								]
							},
							{
								path: 'styles',
								component: StylesComponent
							}
						]
					},
					{
						path: 'workflowEngine',
						children: [
							{
								path: 'actions',
								children: [
									{
										path: 'definitions',
										component:
											ActionDefinitionRouterComponent,
										children: [
											{
												path: ':id',
												component:
													ActionDefinitionComponent
											},
											{
												path: '',
												component:
													ActionDefinitionsComponent
											},
											{
												path: '**',
												component:
													ActionDefinitionsComponent
											}
										]
									},
									{
										path: 'executionHistory',
										component:
											ActionExecutionHistoryComponent
									}
								]
							}
						]
					},
					{
						path: '',
						redirectTo: 'dashboard',
						pathMatch: 'full'
					},
					{
						path: '**',
						redirectTo: 'dashboard',
						pathMatch: 'full'
					}
				]
			}])
	]
})

/**
 * A module used to hold admin route specific
 * logic and display resources.
 *
 * @export
 * @class AdminModule
 */
export class AdminModule
{
}