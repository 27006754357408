/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ChangeDetectorRef,
	Component
} from '@angular/core';
import {
	ExtendedCustomControlDirective
} from '@entity/directives/extended-custom-control.directive';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	StringHelper
} from '@shared/helpers/string.helper';

@Component({
	selector: 'custom-table-display',
	templateUrl: './custom-table-display.component.html'
})

/**
 * A component representing an instance of a Custom Table Display.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomTableDisplayComponent
 * @extends {FieldType}
 */
export class CustomTableDisplayComponent
	extends ExtendedCustomControlDirective
{
	/**
	 * Initializes a new instance of the CustomTableDisplayComponent. This
	 * explicit constructor was requested during an upgrade process and
	 * should be left as implemented.
	 *
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof CustomTableDisplayComponent
	 */
	public constructor(
		public changeDetector: ChangeDetectorRef)
	{
		super(changeDetector);
	}

	/**
	 * On component initialization event.
	 * This method is used to extend the update value and validity method
	 * of this form control to include change detection and to match
	 * the expected table interface.
	 * @memberof CustomTableDisplayComponent
	 */
	public ngOnInit(): void
	{
		this.to.tableDefinitions.selectedColumns =
			this.to.tableDefinitions.availableColumns;

		this.to.tableDefinitions.selectedColumnsChanged =
			(_selectedColumns: ICommonTableColumn[]) =>
			{
				// Match the interface.
			};

		this.to.tableDefinitions.columnSelectionModeChanged =
			(_columnSelectionMode: boolean) =>
			{
				// Match the interface.
			};

		super.ngOnInit();
	}

	/**
	 * Handles the row select event promise
	 * when clicking on a displayed table row
	 * which has the selectable properties enabled.
	 *
	 * @param {string} capturedSelectedRowEventPromise
	 * The captured selected row event promise from template options.
	 * @memberof CustomTableDisplayComponent
	 */
	public async rowSelected(
		capturedSelectedRowEventPromise: string): Promise<void>
	{
		if (!AnyHelper.isNullOrWhitespace(
			capturedSelectedRowEventPromise))
		{
			await StringHelper.transformToDataPromise(
				capturedSelectedRowEventPromise,
				this.field.templateOptions.context);
		}
	}
}