<label class="visually-hidden"
	[attr.for]="'AriaInput' + id">
	{{to.label}}
</label>
<ng-template
	[ngIf]="loading"
	[ngIfElse]="Loaded">
	<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
	</i>
</ng-template>
<ng-template #Loaded>
	<p-multiSelect
		[options]="this.dataOptions"
		[formControl]="formControl"
		[formlyAttributes]="field"
		[defaultLabel]="to.placeholder"
		[ariaFilterLabel]="'Filter ' + to.label"
		[inputId]="'AriaInput' + id"
		[appendTo]="to.appendTo"
		[emptyFilterMessage]="to.emptyFilterMessage || 'No results found'"
		filterPlaceHolder="Filter"
		(onFilter)="onFilter($event)"
		(onChange)="change($event)">
	</p-multiSelect>
</ng-template>

<field-validation-message
	[field]="field">
</field-validation-message>