/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	EventEmitter,
	Input,
	Output
} from '@angular/core';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	DateTime
} from 'luxon';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	isEqual
} from 'lodash-es';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';

@Component({
	selector: 'app-display-component-parameter-header',
	templateUrl: './display-component-parameter-header.component.html',
	styleUrls: [
		'./display-component-parameter-header.component.scss'
	],
	animations: [
		ContentAnimation
	]
})

/**
 * A component representing an instance of the display component
 * parameter header component.
 *
 * @export
 * @class DisplayComponentParameterHeaderComponent
 */
export class DisplayComponentParameterHeaderComponent
{
	/**
	 * Creates an instance of a display component parameter header.
	 *
	 * @param {SiteLayoutService} SiteLayoutService
	 * The site layout service used in this component.
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService)
	{
	}

	/**
	 * Gets or sets the value defining if this component
	 * should be displayed.
	 *
	 * @type {boolean}
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	@Input() public display: boolean = false;

	/**
	 * Gets or sets the truthy defining if the edit option
	 * should be displayed.
	 *
	 * @type {boolean}
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	@Input() public displayEditOption: boolean = false;

	/**
	 * Gets or sets the value defining if this component
	 * should display the parameter chips.
	 *
	 * @type {boolean}
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	@Input() public displayParameters: boolean = true;

	/**
	 * Gets or sets the value defining if this component
	 * should include a parameter display action.
	 *
	 * @type {boolean}
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	@Input() public displayParameterOption: boolean = true;

	/**
	 * Gets or sets the value defining if this component
	 * is currently displaying the secondary view.
	 *
	 * @type {boolean}
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	@Input() public displayingSecondary: boolean = false;

	/**
	 * Gets or sets the value defining if this component
	 * should include a secondary display action.
	 *
	 * @type {boolean}
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	@Input() public displaySecondaryOption: boolean = true;

	/**
	 * Gets or sets the value defining if this component
	 * is currently displaying the settings view.
	 *
	 * @type {boolean}
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	@Input() public displayingSettings: boolean = false;

	/**
	 * Gets or sets the parameter layout schema.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	@Input() public parameterLayoutSchema: FormlyFieldConfig[];

	/**
	 * Gets or sets the parameter layout data.
	 *
	 * @type {any}
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	@Input() public parameterLayoutData: any;

	/**
	 * Gets or sets the parents parameter layout data.
	 *
	 * @type {any}
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	@Input() public parentParameterLayoutData: any;

	/**
	 * Gets or sets the use click on display flag.
	 *
	 * @type {boolean}
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	@Input() public useClickOnDisplay: boolean = false;

	/**
	 * Gets or sets the display edit click that will emit
	 * to listening components a display edit action.
	 *
	 * @type {EventEmitter<void>}
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	@Output() public displayEditClick: EventEmitter<void> =
		new EventEmitter<void>();

	/**
	 * Gets or sets the display secondary view click that will emit
	 * to listening components a display secondary view action.
	 *
	 * @type {EventEmitter<void>}
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	@Output() public displaySecondaryClick: EventEmitter<void> =
		new EventEmitter<void>();

	/**
	 * Gets or sets the display settings click that will emit
	 * to listening components a display settings action.
	 *
	 * @type {EventEmitter<void>}
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	@Output() public displaySettingsClick: EventEmitter<void> =
		new EventEmitter<void>();

	/**
	 * Gets or sets the alter data icon tooltip text.
	 *
	 * @type {string}
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	public alterDataTooltip: string = 'Alter Data Inputs';

	/**
	 * Gets or sets the alter data icon tooltip text.
	 *
	 * @type {string}
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	public editDisplayTooltip: string = 'Edit';

	/**
	 * Gets or sets the secondary display icon tooltip text.
	 *
	 * @type {string}
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	public alternateDisplayTooltip: string = 'Switch Display';

	/**
	 * Gets or sets the icon tooltip style class.
	 *
	 * @type {string}
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	public iconStyleClass: string = 'icon-tooltip utility-menu-tooltip';

	/**
	 * Gets or sets the icon tooltip position.
	 *
	 * @type {string}
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	public iconTooltipPosition: string = 'left';

	/**
	 * Gets the count of differences between the current parameter data
	 * set compared and the parent's parameter data set.
	 *
	 * @type {number}
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	public get differenceCount(): number
	{
		if (AnyHelper.isNull(this.parentParameterLayoutData))
		{
			return null;
		}

		return this.getChildParameterDataDifferences();
	}

	/**
	 * Gets the list of formly control types that use a dropdown
	 * option/label dataset.
	 *
	 * @type {string[]}
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	private readonly dropdownTypes: string[] =
		[
			'select',
			FormlyConstants.customControls.customSelect
		];

	/**
	 * Handles the click event sent from the display settings action
	 * icon.
	 *
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	public displaySettingsClicked(): void
	{
		this.displaySettingsClick.emit();
	}

	/**
	 * Handles the click event sent from the display secondary view action
	 * icon.
	 *
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	public displaySecondaryClicked(): void
	{
		this.displaySecondaryClick.emit();
	}

	/**
	 * Handles the click event sent from the display edit view action
	 * icon.
	 *
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	public displayEditClicked(): void
	{
		this.displayEditClick.emit();
	}

	/**
	 * Calculates a display title for the associated set of parameters
	 * to this component.
	 *
	 * @param {FormlyFieldConfig} fieldConfig
	 * The field configuration to get a title for.
	 * @returns {string}
	 * A title ready for display in the parameter based chip set.
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	public getChipTitle(
		fieldConfig: FormlyFieldConfig): string
	{
		return `${fieldConfig.templateOptions.label}: `;
	}

	/**
	 * Calculates a display value for the associated set of parameters
	 * to this component.
	 *
	 * @param {FormlyFieldConfig} fieldConfig
	 * The field configuration to get a value for.
	 * @returns {string}
	 * A value ready for display in the parameter based chip set.
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	public getChipValue(
		fieldConfig: FormlyFieldConfig): string
	{
		let value: any =
			this.parameterLayoutData.data[
				fieldConfig.key.
					toString()
					.replace(
						AppConstants.nestedDataIdentifier,
						AppConstants.empty)];

		if (!AnyHelper.isNullOrWhitespace(value)
			&& DateTime.fromISO(value).isValid)
		{
			value =
				DateTime.fromISO(
					value)
					.toFormat(
						DateHelper.presetFormats.shortDateFormat);
		}

		if (this.dropdownTypes.indexOf(fieldConfig.type) !== -1)
		{
			(<any[]>fieldConfig.templateOptions.options)
				.forEach(
					(selectOption: any) =>
					{
						if (value === selectOption.value)
						{
							value = selectOption.label;
						}
					});
		}

		return value;
	}

	/**
	 * Calculates the differences in data values between this parameter
	 * set and the parent parameter set.
	 *
	 * @returns {number}
	 * A count of differences between the parameter data set
	 * and parent parameter data set.
	 * @memberof DisplayComponentParameterHeaderComponent
	 */
	private getChildParameterDataDifferences(): number
	{
		let parentChangeCount: number = 0;

		this.parameterLayoutSchema
			.forEach((formlyFieldConfig: FormlyFieldConfig) =>
			{
				const cleanedDataKey: string =
					StringHelper.clearNestedDataIdentifier(
						formlyFieldConfig.key
							.toString());

				if (!AnyHelper.isNull(
					this.parentParameterLayoutData.data[
						cleanedDataKey])
						&& !isEqual(
							this.parameterLayoutData.data[
								cleanedDataKey],
							this.parentParameterLayoutData.data[
								cleanedDataKey]))
				{
					parentChangeCount++;
				}
			});

		return parentChangeCount === 0
			? null
			: parentChangeCount;
	}
}
