/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	Directive,
	EventEmitter,
	Input,
	Output
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	MenuItem
} from 'primeng/api';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/* eslint-enable max-len */

@Directive({
	selector: '[DrawerEntityComponentDirective]'
})

export class DrawerEntityComponentDirective
{
	/**
	 * Initializes a new instance of a DrawerEntityComponentDirective. This
	 * directive is used to help with loading and displaying
	 * a drawer entity.
	 *
	 * @param {EntityService} entityService
	 * The entity service for this directive.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service for this directive.
	 * @memberof DrawerEntityComponentDirective
	 */
	public constructor(
		public entityService: EntityService,
		public entityInstanceApiService: EntityInstanceApiService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof DrawerEntityComponentDirective
	 */
	@Input() public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the selected item to be displayed within the drawer view.
	 *
	 * @type {IEntityInstance}
	 * @memberof DrawerEntityComponentDirective
	 */
	 @Input() public selectedItem: IEntityInstance;

	/**
	 * Gets or sets the wildcard child filter used to find available child
	 * types of the context. This is used in a create entity drawer.
	 *
	 * @type {string}
	 * @memberof DrawerEntityComponentDirective
	 */
	@Input() public wildcardChildFilter: string;

	/**
	 * Gets or sets the display name of this drawer entity for client messages.
	 *
	 * @type {string}
	 * @memberof DrawerEntityComponentDirective
	 */
	@Input() public entityDisplayName: string = 'Item';

	/**
	 * Gets or sets the collection of actions specific to this item.
	 *
	 * @type {MenuItem[]}
	 * @memberof DrawerEntityComponentDirective
	 */
	@Input() public itemActions: MenuItem[] = [];

	/**
	 * Gets or sets the type group of the selected item to view.
	 *
	 * @type {string}
	 * @memberof DrawerEntityComponentDirective
	 */
	 @Input() public typeGroup: string;

	 /**
	  * Gets or sets the identifier of the selected item to view.
	  *
	  * @type {number}
	  * @memberof DrawerEntityComponentDirective
	  */
	 @Input() public id: number;

	/**
	 * Gets or sets the navigate event
	 *
	 * @type {EventEmitter<string>}
	 * @memberof DrawerEntityComponentDirective
	 */
	@Output() public changeDisplayMode: EventEmitter<string> =
		new EventEmitter<string>();

	/**
	 * Gets or sets the entity altered event
	 *
	 * @type {EventEmitter<void>}
	 * @memberof DrawerEntityComponentDirective
	 */
	@Output() public entityAltered: EventEmitter<void> =
		new EventEmitter<void>();

	/**
	 * Gets or sets a value indicating whether data is loading.
	 *
	 * @type {boolean}
	 * @memberof DrawerEntityComponentDirective
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets a value indicating whether the component is saving.
	 *
	 * @type {boolean}
	 * @memberof DrawerEntityComponentDirective
	 */
	public saving: boolean = false;

	/**
	 * Gets or sets an EntityType representing the selected entity type to
	 * create.
	 *
	 * @type {EntityType}
	 * @memberof DrawerEntityComponentDirective
	 */
	public selectedEntityType: EntityType;

	 /**
	  * Gets or sets a collection of FormlyFieldConfig representing the
	  * formly entity layout of the selected entity type layout.
	  *
	  * @type {FormlyFieldConfig[]}
	  * @memberof DrawerEntityComponentDirective
	  */
	public formlyEntityLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the current validity of the displayed dynamic form.
	 *
	 * @type {boolean}
	 * @memberof DrawerEntityComponentDirective
	 */
	public isValid: boolean = false;

	/**
	 * This method will load the selected entity and associated data that will
	 * be displayed in this drawer component.
	 *
	 * @async
	 * @memberof DrawerEntityComponentDirective
	 */
	public async selectedEntityDisplaySetup(): Promise<void>
	{
		if (AnyHelper.isNullOrEmpty(this.typeGroup))
		{
			const entityTypes: EntityType[] =
				await this.entityService.getEntityTypesFromNameList(
					[this.selectedItem.entityType]);
			this.selectedEntityType = entityTypes.pop();
			this.typeGroup = this.selectedEntityType?.group;
		}

		this.id = this.id || this.selectedItem.id;
		this.entityInstanceApiService.entityInstanceTypeGroup =
			this.typeGroup;

		this.selectedItem =
			this.selectedItem
				|| await this.entityInstanceApiService.get(this.id);

		this.formlyEntityLayout =
			await this.entityService.getFormlyLayout(
				this.context,
				this.selectedEntityType,
				AppConstants.layoutTypes.drawer,
				this.selectedItem.id);
	}

	/**
	 * This method will calculate a display name based on a period delimited
	 * name of an entity type.
	 *
	 * @async
	 * @memberof DrawerEntityComponentDirective
	 */
	public getDisplayName(
		input: string): string
	{
		let names: string[] =
			input.split(
				AppConstants.characters.period);

		if (names.length > 1)
		{
			names =
				names.reverse();

			names.pop();

			names =
				names.reverse();
		}

		return StringHelper
			.beforeCapitalSpaces(
				names.join(AppConstants.empty));
	}

	/**
	 * Handles the validity changed event of the contained dynamic form.
	 *
	 * @param {boolean} isValid
	 * The sent validity value of the dynamic form.
	 * @memberof DrawerEntityComponentDirective
	 */
	public validDataChanged(
		isValid: boolean): void
	{
		this.isValid = isValid;
	}
}