/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AddRelatedContextMenuAction
} from '@operation/actions/add-related-context-menu-action';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	Directive,
	Input,
	OnInit
} from '@angular/core';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IOperationDefinition
} from '@operation/interfaces/operation-definition.interface';
import {
	IOperationGroup
} from '@operation/interfaces/operation-group.interface';
import {
	IOperationGroupRelationship
} from '@operation/interfaces/operation-group-relationship.interface';
import {
	IOperationTypeParameter
} from '@operation/interfaces/operation-type-parameter.interface';
import {
	LoggerService
} from '@shared/services/logger.service';
import {
	MenuItem
} from 'primeng/api';
import {
	OperationExecutionService
} from '@operation/services/operation-execution.service';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/* eslint-enable max-len */

/**
 * A base class used to handle session based operation
 * group interaction.
 *
 * @export
 * @class BaseSessionUserDirective
 * @implements {OnInit}
 */
@Directive()
export abstract class BaseOperationGroupDirective
implements OnInit
{
	/**
	 * Creates an instance of a BaseOperationGroupDirective.
	 *
	 * @param {LoggerService} loggerService
	 * The logger service to use for exception handling.
	 * @param {OperationService} operationService
	 * The operation service to use for loading operation groups and
	 * definitions.
	 * @param {OperationExecutionService} operationExecutionService
	 * The operation execution service to use for executing operation
	 * definitions.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used .
	 * @memberof BaseOperationGroupDirective
	 */
	public constructor(
		public loggerService: LoggerService,
		public operationService: OperationService,
		public operationExecutionService: OperationExecutionService,
		public siteLayoutService: SiteLayoutService)
	{
	}

	/**
	 * Gets or sets the operation group name
	 * to load and display.
	 *
	 * @type {string}
	 * @memberof BaseOperationGroupDirective
	 */
	@Input() public operationGroupName: string;

	/**
	 * Gets or sets the operation group display name
	 * to display.
	 *
	 * @type {string}
	 * @memberof BaseOperationGroupDirective
	 */
	@Input() public displayName: string;

	/**
	 * Gets or sets an initial set of Menu Items to
	 * be displayed prior to loading.
	 *
	 * @type {MenuItem[]}
	 * @memberof BaseOperationGroupDirective
	 */
	@Input() public initialModel: MenuItem[] = [];

	/**
	 * Gets or sets the page context which will be used in any related
	 * component displays.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof BaseOperationGroupDirective
	 */
	@Input() public pageContext: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the base model used to display the group.
	 *
	 * @type {MenuItem[]}
	 * @memberof BaseOperationGroupDirective
	 */
	public model: MenuItem[];

	/**
	 * Gets the lookup value to use when looking up an associated entity
	 * type in a related context menu configuration.
	 *
	 * @type {string}
	 * @memberof BaseOperationGroupDirective
	 */
	private readonly associatedEntityTypeIdentifier: string =
		'associatedEntityTypeGroup';

	/**
	 * Gets the lookup value to use when looking up a visible parameter.
	 *
	 * @type {string}
	 * @memberof BaseOperationGroupDirective
	 */
	private readonly visibleParameterName: string = 'visible';

	/**
	 * Gets the lookup value to use when looking up an enabled parameter.
	 *
	 * @type {string}
	 * @memberof BaseOperationGroupDirective
	 */
	private readonly enabledParameterName: string = 'enabled';

	/**
	 * Gets the lookup value to use when looking up a disabled message
	 * parameter.
	 *
	 * @type {string}
	 * @memberof BaseOperationGroupDirective
	 */
	private readonly disabledMessageParameterName: string =
		'disabledMessagePromise';

	/**
	 * Implements the onInit interface.
	 * This method is used to populate the user actions
	 * loaded from the primary navigation operation group.
	 *
	 * @memberof BaseOperationGroupDirective
	 */
	public ngOnInit(): void
	{
		if (!AnyHelper.isNullOrWhitespace(this.operationGroupName))
		{
			this.model =
				this.initialModel
					.concat(
						[
							{
								label: `Loading ${this.displayName}`,
								icon: 'fa fa-fw fa-spin fa-spinner'
							}
						]);

			this.operationService
				.populateOperationGroup(
					this.operationGroupName)
				.then(
					async(operationGroup: IOperationGroup) =>
					{
						// handle ownership removed operation groups.
						if (AnyHelper.isNull(operationGroup))
						{
							this.model = [];

							return;
						}

						this.model =
							this.model
								.slice(0, this.model.length - 1)
								.concat(await this.mapChildRelationships(
									operationGroup.childRelationships));
						this.performPostOperationLoadActions();
					})
				.catch(
					(exception: Error) =>
					{
						this.model =
							this.model
								.slice(0, this.model.length - 1);

						const exceptionMessage: string =
							`There is a problem loading ${this.displayName}: `
								+ `${exception.message}.`;

						this.loggerService.logError(
							exceptionMessage);

						EventHelper.dispatchBannerEvent(
							AppConstants.messages.genericErrorMessage,
							exceptionMessage,
							AppConstants.activityStatus.error,
							exception);
					});
		}
		else
		{
			this.model =
				this.initialModel;

			this.performPostOperationLoadActions();
		}
	}

	/**
	 * Gets the child relationships to be mapped for nested
	 * operation groups.
	 *
	 * @param {IOperationGroupRelationship[]} childRelationships
	 * The child relationships to be mapped.
	 * @memberof BaseOperationGroupDirective
	 * @returns {MenuItem[]}
	 * A decorated heirarchy menu representing the operation group.
	 */
	public async mapChildRelationships(
		childRelationships: IOperationGroupRelationship[]): Promise<MenuItem[]>
	{
		const definitions: MenuItem[] = [];

		for (const relationship of childRelationships)
		{
			if (relationship.operationDefinition != null)
			{
				const populatedOperationDefinition: IOperationDefinition =
					await this.operationService
						.populateOperationDefinition(
							relationship.operationDefinition,
							relationship,
							this.pageContext);

				const visible: boolean =
					await this.visible(
						populatedOperationDefinition,
						this.pageContext);
				const enabled: boolean =
					await this.enabled(
						populatedOperationDefinition,
						this.pageContext);

				let disabledMessage: string;
				if (enabled === false)
				{
					disabledMessage =
						await this.resolveStringPromise(
							this.disabledMessageParameterName,
							populatedOperationDefinition,
							this.pageContext);
				}

				if (!AnyHelper.isNullOrWhitespace(
					populatedOperationDefinition.icon)
					&& populatedOperationDefinition.icon.indexOf(
						AppConstants.contextMenuIdentifiers
							.addRelatedContextMenuIdentifier) !== -1)
				{
					if (visible === false)
					{
						continue;
					}

					const operationAction: AddRelatedContextMenuAction =
						<AddRelatedContextMenuAction>
							await this.operationExecutionService
								.executeMappedOperation(
									populatedOperationDefinition);

					const isNested: boolean =
						operationAction.singularEndpoints.indexOf(
							operationAction.endPoint) === -1;

					const contextMenuRelationships: MenuItem[] =
						enabled === false && isNested === true
							? null
							: await operationAction.execute();

					if (isNested === false)
					{
						contextMenuRelationships[0].disabled = !enabled;
					}

					// Do not display the relation if singular and no results.
					if (isNested === true
						|| contextMenuRelationships.length > 0)
					{
						definitions.push(isNested === true
							? <MenuItem>
								{
									label: StringHelper.interpolate(
										populatedOperationDefinition.label,
										this.pageContext),
									icon: enabled === false
										? null
										: populatedOperationDefinition.icon,
									id: operationAction[
										this.associatedEntityTypeIdentifier],
									disabled: !enabled,
									tooltipOptions: {
										tooltipLabel: disabledMessage
									},
									items: contextMenuRelationships
								}
							: contextMenuRelationships[0]);
					}
				}
				else
				{
					const currentMenuItem: MenuItem =
						<MenuItem>
						{
							label: StringHelper.interpolate(
								relationship.operationDefinition.label,
								this.pageContext),
							icon: this.getIcon(
								relationship.operationDefinition.icon),
							id: relationship.operationDefinition
								.operationType.name
								+ AppConstants.characters.leftSquareBracket
								+ relationship.operationDefinition.name
								+ AppConstants.characters.rightSquareBracket,
							visible: visible,
							disabled: !enabled,
							tooltipOptions: {
								tooltipLabel: disabledMessage
							},
							command: async(_event: Event) =>
							{
								setTimeout(
									() => {
										currentMenuItem.disabled = true;
									});

								try
								{
									await this.operationExecutionService
										.executeMappedOperation(
											await this.operationService
												.populateOperationDefinition(
													relationship
														.operationDefinition,
													relationship,
													this.pageContext));

									setTimeout(
										() => {
											currentMenuItem.disabled = !enabled;
										});
								}
								catch (exception)
								{
									setTimeout(
										() => {
											currentMenuItem.disabled = !enabled;
										});

									throw exception;
								}
							},
						};

					definitions
						.push(currentMenuItem);
				}
			}
			else
			{
				const item: IOperationGroup =
					relationship.operationGroup;

				// Recursively populate each child.
				const items: MenuItem[] =
					await this.mapChildRelationships(
						relationship.operationGroup
							.childRelationships);

				if (items.filter(
					(groupItem: MenuItem) =>
						groupItem.visible === true).length === 0)
				{
					continue;
				}

				definitions
					.push(
						<MenuItem>
						{
							label: StringHelper.interpolate(
								item.label,
								this.pageContext),
							icon: this.getIcon(item.icon),
							id: item.name,
							visible: true,
							items: items
						});
			}
		}

		return definitions;
	}

	/**
	 * Calculates and returns the visible value of this operation definition.
	 * If not set, this value defaults to true.
	 *
	 * @async
	 * @param {IOperationDefinition} operationDefinition
	 * The operation definition to check for visibility.
	 * @param {IDynamicComponentContext<Component, any>} pageContext
	 * The dynamic component context to run this visibility check against.
	 * @returns {Promise<boolean>}
	 * An awaitable boolean signifying the visible value of this operation
	 * definition. This value defaults to true if not set.
	 * @memberof BaseOperationGroupDirective
	 */
	public async visible(
		operationDefinition: IOperationDefinition,
		pageContext: IDynamicComponentContext<Component, any>): Promise<boolean>
	{
		return this.resolveBooleanPromise(
			this.visibleParameterName,
			operationDefinition,
			pageContext);
	}

	/**
	 * Calculates and returns the enabled value of this operation definition.
	 * If not set, this value defaults to true.
	 *
	 * @async
	 * @param {IOperationDefinition} operationDefinition
	 * The operation definition to check.
	 * @param {IDynamicComponentContext<Component, any>} pageContext
	 * The dynamic component context to run this enabled check against.
	 * @returns {Promise<boolean>}
	 * An awaitable boolean signifying the enabled value of this operation
	 * definition. This value defaults to true if not set.
	 * @memberof BaseOperationGroupDirective
	 */
	public async enabled(
		operationDefinition: IOperationDefinition,
		pageContext: IDynamicComponentContext<Component, any>): Promise<boolean>
	{
		return this.resolveBooleanPromise(
			this.enabledParameterName,
			operationDefinition,
			pageContext);
	}

	/**
	 * Performs actions post operation group load.
	 *
	 * @memberof BaseOperationGroupDirective
	 */
	public abstract performPostOperationLoadActions(): void;

	/**
	 * Calculates and returns the boolean value of this operation definition
	 * based on a raw boolean promise valued parameter.
	 *
	 * @async
	 * @param {string} promiseParameterName
	 * The boolean valued promise parameter name to check.
	 * @param {IOperationDefinition} operationDefinition
	 * The operation definition to check.
	 * @param {IDynamicComponentContext<Component, any>} pageContext
	 * The dynamic component context to run this enabled check against.
	 * @returns {Promise<boolean>}
	 * An awaitable boolean signifying the boolean value of this operation
	 * definition parameter. This value defaults to true if not set.
	 * @memberof BaseOperationGroupDirective
	 */
	private async resolveBooleanPromise(
		promiseParameterName: string,
		operationDefinition: IOperationDefinition,
		pageContext: IDynamicComponentContext<Component, any>): Promise<boolean>
	{
		const booleanPromise: string =
			operationDefinition.operationTypeParameters?.find(
				(typeParameter: IOperationTypeParameter) =>
					typeParameter.name ===
						promiseParameterName)?.definitionParameterValue;

		if (AnyHelper.isNullOrEmpty(booleanPromise))
		{
			return true;
		}

		return StringHelper.transformAndExecuteBooleanPromise(
			booleanPromise,
			pageContext);
	}

	/**
	 * Calculates and returns a string value of this operation definition
	 * based on a raw string promise valued parameter.
	 *
	 * @async
	 * @param {string} promiseParameterName
	 * The string valued promise parameter name to check.
	 * @param {IOperationDefinition} operationDefinition
	 * The operation definition to check.
	 * @param {IDynamicComponentContext<Component, any>} pageContext
	 * The dynamic component context to run this enabled check against.
	 * @returns {Promise<string>}
	 * An awaitable string signifying the awaitable value of this operation
	 * definition parameter. This value defaults to null if not set.
	 * @memberof BaseOperationGroupDirective
	 */
	private async resolveStringPromise(
		promiseParameterName: string,
		operationDefinition: IOperationDefinition,
		pageContext: IDynamicComponentContext<Component, any>): Promise<string>
	{
		const messagePromise: string =
			operationDefinition.operationTypeParameters?.find(
				(typeParameter: IOperationTypeParameter) =>
					typeParameter.name ===
						promiseParameterName)?.definitionParameterValue;

		if (AnyHelper.isNullOrEmpty(messagePromise))
		{
			return null;
		}

		return StringHelper.transformToDataPromise(
			messagePromise,
			pageContext);
	}

	/**
	 * Gets the decorated icon to be used in navigation
	 * definitions.
	 *
	 * @type {string}
	 * @memberof BaseOperationGroupDirective
	 * @returns {string}
	 * A decorated icon for navigation display.
	 */
	private getIcon(icon: string): string
	{
		return icon == null
			? ''
			: `fa fa-fw fa-${icon}`;
	}
}