/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ActivatedRoute,
	Params,
	Router,
	UrlCreationOptions
} from '@angular/router';
import {
	AfterViewInit,
	Component,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiFilterHelper
} from '@shared/helpers/api-filter.helper';
import {
	ApiHelper
} from '@shared/helpers/api.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppEventParameterConstants
} from '@shared/constants/app-event-parameter.constants';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	DateTime
} from 'luxon';
import {
	DisplayComponentFactory
} from '@shared/factories/display-component-factory';
import {
	DisplayComponentService
} from '@shared/services/display-component.service';
import {
	DynamicComponentLookup
} from '@dynamicComponents/dynamic-component.lookup';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EntityVersionApiService
} from '@api/services/entities/entity-version.api.service';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	get
} from 'lodash-es';
import {
	IActionTableConfiguration
} from '@shared/interfaces/application-objects/actions-table-configuration.interface';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IDynamicLayoutConfiguration
} from '@shared/interfaces/application-objects/dynamic-layout-configuration.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IEntityVersion
} from '@shared/interfaces/entities/entity-version.interface';
import {
	IEventHandler
} from 'service';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IOperationGroup
} from '@operation/interfaces/operation-group.interface';
import {
	IOwnershipGuardComponent
} from '@shared/interfaces/application-objects/ownership-guard-component';
import {
	ITableExpandDefinition
} from '@shared/interfaces/application-objects/table-expand-definition.interface';
import {
	Location
} from '@angular/common';
import {
	MenuItem
} from 'primeng/api';
import {
	MouseEventConstants
} from '@shared/constants/mouse-event.constants';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	OperationGroupApiService
} from '@api/services/operations/operation-group.api.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SessionService
} from '@shared/services/session.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	Subscription
} from 'rxjs';
import {
	TableHelper
} from '@shared/helpers/table.helper';
import {
	WindowEventConstants
} from '@shared/constants/window-event.constants';
import {
	WorkItemConstants
} from '@shared/constants/work-item-constants';
import {
	WorkItemExpandComponent
} from '@workItems/components/workbench/work-item-expand/work-item-expand.component';

@Component({
	selector: 'app-workbench',
	templateUrl: './workbench.component.html',
	styleUrls: [
		'./workbench.component.scss'
	],
	animations: [
		ContentAnimation
	]
})

/**
 * A component representing a work items workbench view.
 *
 * @export
 * @class WorkbenchComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {AfterViewInit}
 * @implements {IOwnershipGuardComponent}
 */
export class WorkbenchComponent
implements OnInit, OnDestroy, AfterViewInit, IOwnershipGuardComponent
{
	/**
	 * Initializes a new instance of the work bench component.
	 *
	 * @param {Router} router
	 * The router used for navigation in this component.
	 * @param {Location} location
	 * The Angular common location service used for url interaction.
	 * @param {ActivatedRoute} route
	 * The route used for url parameters in this component.
	 * @param {ResolverService} resolver
	 * The resolver service.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service.
	 * @param {SessionService} sessionService
	 * The session service.
	 * @param {DisplayComponentService} displayComponentService
	 * The display component service.
	 * @param {DisplayComponentFactory} displayComponentFactory
	 * The display component factory.
	 * @param {EntityService} entityService
	 * The entity service.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type API service.
	 * @param {EntityVersionApiService} entityVersionApiService
	 * The entity version API service.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance API service.
	 * @param {EntityDefinitionApiService} entityDefinitionApiService
	 * The entity definition API service.
	 * @param {OperationGroupApiService} operationGroupApiService
	 * The operation group API service.
	 * @memberof EntityViewComponent
	 */
	public constructor(
		public router: Router,
		public location: Location,
		public route: ActivatedRoute,
		public resolver: ResolverService,
		public sessionService: SessionService,
		public siteLayoutService: SiteLayoutService,
		public displayComponentService: DisplayComponentService,
		public displayComponentFactory: DisplayComponentFactory,
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public entityVersionApiService: EntityVersionApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public entityDefinitionApiService: EntityDefinitionApiService,
		public operationGroupApiService: OperationGroupApiService)
	{
	}

	/**
	 * Gets or sets the scroll panel element reference.
	 *
	 * @type {ElementRef}
	 * @memberof WorkbenchComponent
	 */
	 @ViewChild('ScrollPanel')
	public scrollPanel: ElementRef;

	/**
	 * Gets or sets the loading value of the table definition.
	 *
	 * @type {boolean}
	 * @memberof WorkbenchComponent
	 */
	public loadingTableDefinition: boolean = true;

	/**
	 * Gets or sets the loading value of the table filters.
	 *
	 * @type {boolean}
	 * @memberof WorkbenchComponent
	 */
	public loadingTableFilters: boolean = true;

	/**
	 * Gets or sets the loading value of the page guard.
	 *
	 * @type {boolean}
	 * @memberof WorkbenchComponent
	 */
	public loadingPageGuard: boolean = true;

	/**
	 * Gets or sets the common table definition.
	 *
	 * @type {ICommonTable}
	 * @memberof WorkbenchComponent
	 */
	public workbenchTableDefinition: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof WorkbenchComponent
	 */
	public availableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof WorkbenchComponent
	 */
	public selectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the column selection mode.
	 *
	 * @type {boolean}
	 * @memberof WorkbenchComponent
	 */
	public columnSelectionMode?: boolean = false;

	/**
	 * Gets or sets the menu items for page actions that will be
	 * displayed in the page.
	 *
	 * @type {MenuItem[]}
	 * @memberof WorkbenchComponent
	 */
	public pageActions: MenuItem[] = [];

	/**
	 * Gets or sets the common table context.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof WorkbenchComponent
	 */
	public commonTableContext:
		IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the table row count.
	 *
	 * @type {number}
	 * @memberof WorkbenchComponent
	 */
	public tableRowCount: number = 15;

	/**
	 * Gets or sets the table filter query.
	 *
	 * @type {string}
	 * @memberof WorkbenchComponent
	 */
	public tableFilterQuery: string = AppConstants.empty;

	/**
	 * Gets the available route parameter values.
	 *
	 * @type {object}
	 * @memberof GenericBasePageComponent
	 */
	public routeData:
		{
			data: object;
		} = {
			data: {}
		};

	/**
	 * Gets or sets the active value of the filter panel.
	 *
	 * @type {boolean}
	 * @memberof WorkbenchComponent
	 */
	public filterActive: boolean = false;

	/**
	 * Gets or sets whether or not the validity changed in the filter panel.
	 *
	 * @type {boolean}
	 * @memberof WorkbenchComponent
	 */
	public filterValidityChanged: boolean = false;

	/**
	 * Gets or sets whether or not the values changed in the filter panel.
	 *
	 * @type {boolean}
	 * @memberof WorkbenchComponent
	 */
	public filterValuesChanged: boolean = false;

	/**
	 * Gets or sets the subscriptions used in this component.
	 *
	 * @type {Subscription}
	 * @memberof WorkbenchComponent
	 */
	public subscriptions: Subscription = new Subscription();

	/**
	 * Gets or sets the data displayed in the filter panel.
	 *
	 * @type {{ data: any }}
	 * @memberof WorkbenchComponent
	 */
	public filterLayoutData: { data: any } =
		{ data: { metadata: {} }};

	/**
	 * Gets or sets the initial data displayed in the filter panel.
	 *
	 * @type {{ data: any }}
	 * @memberof WorkbenchComponent
	 */
	public archivedFilterLayoutData: { data: any } =
		{ data: { metadata: {} }};

	/**
	 * Gets or sets the layout displayed in the filter panel.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof WorkbenchComponent
	 */
	public filterLayoutSchema: FormlyFieldConfig[] = [];

	/**
	 * Gets or sets the dynamic layout displayed in the filter panel based on
	 * module selects.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof WorkbenchComponent
	 */
	public dynamicFilterLayoutSchema: FormlyFieldConfig[] = [];

	/**
	 * Gets or sets the allowed modules that the user has access to.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof WorkbenchComponent
	 */
	public moduleOptions: IDropdownOption[] = [];

	/**
	 * Gets or sets the dynamic layout collection associated to each allowed
	 * module in the filter panel.
	 *
	 * @type {{
	 * module: string,
	 * layoutConfiguration: IDynamicLayoutConfiguration
	 * }[]}
	 * @memberof WorkbenchComponent
	 */
	public moduleLayoutConfigurations:
		{
			module: string;
			layoutConfiguration: IDynamicLayoutConfiguration;
		}[] = [];

	/**
	 * Gets or sets the available users which is used to display chip outputs.
	 *
	 * @type {IDropdownOption[]}
	 * @memberof WorkbenchComponent
	 */
	public availableUsers: IDropdownOption[] = [];

	/**
	 * Gets or sets the initial object search displayed in this component's
	 * common table.
	 *
	 * @type {IObjectSearch}
	 * @memberof WorkbenchComponent
	 */
	public objectSearch: IObjectSearch =
		<IObjectSearch>
		{
			filter: AppConstants.empty,
			orderBy: `${AppConstants.commonProperties.id} `
				+ AppConstants.sortDirections.descending,
			offset: 0,
			limit: AppConstants.dataLimits.large,
			virtualIndex: 0,
			virtualPageSize: this.tableRowCount,
			sortField: AppConstants.commonProperties.id,
			sortOrder: -1
		};

	/**
	 * Gets or sets the interval timer reference returned when
	 * applicable dashboard timers are running.
	 *
	 * @type {NodeJS.Timeout}
	 * @memberof WorkbenchComponent
	 */
	public dashboardRefreshTimeout: NodeJS.Timeout;

	/**
	 * Gets or sets loading dashboard state.
	 *
	 * @type {boolean}
	 * @memberof WorkbenchComponent
	 */
	public loadingDashboard: boolean = true;

	/**
	 * Gets the page context sent to associated context utilities
	 * and menus.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof WorkbenchComponent
	 */
	public get pageContext(): IDynamicComponentContext<Component, any>
	{
		return <IDynamicComponentContext<Component, any>> {
			source: this,
			data: {}
		};
	}

	/**
	 * Gets the number of fields that will exist in the filter panel at all
	 * times.
	 *
	 * @type {number}
	 * @memberof WorkbenchComponent
	 */
	private readonly staticLayoutFieldCount: number = 9;

	/**
	 * Gets the identifier used to find an overlay of a workbench filter.
	 *
	 * @type {string}
	 * @memberof WorkbenchComponent
	 */
	private readonly overlayIdentifier: string = 'workbench-filter';

	/**
	 * Gets the table redraw debounce delay.
	 *
	 * @type {number}
	 * @memberof WorkbenchComponent
	 */
	private readonly tableRedrawDebounceDelay: number =
		AppConstants.time.oneHundredMilliseconds;

	/**
	 * Gets the list of formly control types that use a dropdown
	 * option/label dataset.
	 *
	 * @type {string[]}
	 * @memberof WorkbenchComponent
	 */
	private readonly dropdownTypes: string[] =
		[
			FormlyConstants.customControls.customSelect,
			FormlyConstants.customControls.customDataSelect
		];

	/**
	 * Gets the list of formly control types that use a calendar
	 * option/label dataset.
	 *
	 * @type {string[]}
	 * @memberof WorkbenchComponent
	 */
	private readonly calendarTypes: string[] =
		[
			FormlyConstants.customControls.customCalendar
		];

	/**
	 * Handles the site layout change event which is called
	 * when the site layout service has altered it's variables.
	 *
	 * @memberof WorkbenchComponent
	 */
	@HostListener(AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		if (this.filterActive === true)
		{
			setTimeout(
				() =>
				{
					this.cancelFilterChanges();
					this.filterActive = false;
				});
		}
	}

	/**
	 * Handles the hide associated menus event.
	 * This is used to close this setting display when a non bubbled
	 * click event occurs outside of this element.
	 *
	 * @param {string} controlIdentifer
	 * The control identifier sent with this event.
	 * @memberof WorkbenchComponent
	 */
	@HostListener(
		AppEventConstants.hideAssociatedMenusEvent,
		[AppEventParameterConstants.id])
	public hideAssociatedMenus(
		controlIdentifer: string): void
	{
		if (controlIdentifer !== this.overlayIdentifier)
		{
			this.siteLayoutChanged();
		}
	}

	/**
	 * Handles the on initialization event.
	 * This method will setup the table definitions and the filter panel.
	 *
	 * @async
	 * @memberof WorkbenchComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		if (!await this.isPageOwnershipAllowed())
		{
			EventHelper.dispatchNavigateToAccessDeniedEvent(
				this.location.path(),
				[
					'WorkItem.*'
				],
				'Access is required to at least one work item '
					+ 'entity type and version.');

			return;
		}

		this.loadingPageGuard = false;

		let displayOrder: number = 1;
		this.availableColumns =
			[
				{
					dataKey: 'data.status',
					columnHeader: 'Status',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'data.priority',
					columnHeader: 'Priority',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'data.subType',
					columnHeader: 'Subtype',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'data.queue',
					columnHeader: 'Queue',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'data.module',
					columnHeader: 'Module',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'data.type',
					columnHeader: 'Entity Type',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'data.createdDate',
					columnHeader: 'Created Date',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'data.dueDate',
					columnHeader: 'Due Date',
					displayOrder: displayOrder
				}
			];
		this.selectedColumns = this.availableColumns;

		this.subscriptions.add(
			this.route.queryParams.subscribe(
				(parameters: Params) =>
				{
					this.filterLayoutData =
						this.mapRouteDataFromUrlStorage(
							ObjectHelper.mapFromRouteData(
								parameters));
					this.updateRouteData();

					this.setupTableFilters()
						.then(
							async() =>
							{
								await this.handleModuleChange(
									this.filterLayoutData.data.module);
								this.objectSearch.filter =
									this.calculateFilter();

								this.setupTableDefinitions();
							});
				}));

		this.loadingDashboard = false;
	}

	/**
	 * On after view initialization event.
	 * Handles workbench dashboard updates.
	 *
	 * @memberof WorkbenchComponent
	 */
	public ngAfterViewInit(): void
	{
		this.updateDashboard();
	}

	/**
	 * Implements the ownership guard interface.
	 * This will calculate page ownership permissions.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * A value signifying whether or not access is allowed to this page.
	 * @memberof WorkbenchComponent
	 */
	public async isPageOwnershipAllowed(): Promise<boolean>
	{
		const workItemTypes: IEntityType[] =
			await this.entityTypeApiService.query(
				AppConstants.commonProperties.group
					+ '.StartsWith(\'WorkItem.\') eq true',
				AppConstants.empty);

		if (workItemTypes.length === 0)
		{
			return false;
		}

		const workItemTypeIds: string =
			ApiFilterHelper.commaSeparatedStringValues(
				workItemTypes.map(
					(workItemType: IEntityType) =>
						workItemType.id.toString()),
				AppConstants.empty);

		const workItemVersions: IEntityVersion[] =
			await this.entityVersionApiService.query(
				AppConstants.commonProperties.id
					+ ` in (${workItemTypeIds})`,
				AppConstants.empty);

		return workItemVersions.length !== 0;
	}

	/**
	 * Maps route data into a filter layout data object with defaults if
	 * applicable.
	 *
	 * @param {any} routeData
	 * The route data parameter defined in the url.
	 * @returns {{ data: {} }}
	 * A filter layout data object ready for an applied filter.
	 * @memberof WorkbenchComponent
	 */
	public mapRouteDataFromUrlStorage(
		routeData: any): { data: {} }
	{
		if (AnyHelper.isNullOrEmpty(routeData.data))
		{
			routeData.data = {};
		}

		return {
			data: {
				assignedTo:
					!routeData.data.hasOwnProperty('assignedTo')
						? this.sessionService.user.data.userName
						: routeData.data.assignedTo,
				type:
					AnyHelper.isNullOrWhitespace(routeData.data.type)
						? AppConstants.empty
						: routeData.data.type,
				subType:
					AnyHelper.isNullOrWhitespace(routeData.data.subType)
						? AppConstants.empty
						: routeData.data.subType,
				priority:
					AnyHelper.isNull(routeData.data.priority)
						? [
							WorkItemConstants.workItemPriority.high,
							WorkItemConstants.workItemPriority.normal,
							WorkItemConstants.workItemPriority.low
						]
						: routeData.data.priority,
				status:
					AnyHelper.isNull(routeData.data.status)
						? [
							WorkItemConstants.workItemStatus.active,
							WorkItemConstants.workItemStatus.overdue
						]
						: routeData.data.status,
				queue:
					AnyHelper.isNullOrWhitespace(routeData.data.queue)
						? AppConstants.empty
						: routeData.data.queue,
				module:
					AnyHelper.isNullOrWhitespace(routeData.data.module)
						? AppConstants.empty
						: routeData.data.module,
				createdDate:
					AnyHelper.isNullOrWhitespace(routeData.data.createdDate)
						? AppConstants.empty
						: routeData.data.createdDate,
				dueDate:
					AnyHelper.isNullOrWhitespace(routeData.data.dueDate)
						? AppConstants.empty
						: routeData.data.dueDate,
				metadata:
					AnyHelper.isNull(routeData.data.metadata)
						? {}
						: routeData.data.metadata
			}
		};
	}

	/**
	 * Adds the current display component parameter set to the URL
	 * when data changes are applied.
	 *
	 * @memberof WorkbenchComponent
	 */
	public updateRouteData(): void
	{
		this.location
			.replaceState(
				this.router
					.createUrlTree(
						[],
						<UrlCreationOptions>
						{
							relativeTo: this.route,
							replaceUrl: true,
							queryParams: {
								routeData:
									ObjectHelper.mapRouteData(
										this.filterLayoutData)
							}
						})
					.toString());
	}

	/**
	 * Handles the on destroy event.
	 * This method will unsubscribe from any existing subscriptions.
	 *
	 * @memberof WorkbenchComponent
	 */
	public ngOnDestroy(): void
	{
		this.subscriptions.unsubscribe();
		clearTimeout(
			this.dashboardRefreshTimeout);
	}

	/**
	 * Sets up the table definition for the table displayed in this component.
	 *
	 * @async
	 * @memberof WorkbenchComponent
	 */
	public async setupTableDefinitions(): Promise<void>
	{
		this.loadingTableDefinition = true;

		this.workbenchTableDefinition =
			<ICommonTable>
			{
				tableTitle: 'Work Items',
				hideExpanderArrow: true,
				scrollable: true,
				hideSettings: false,
				allowSortColumns: true,
				actions:
					<IActionTableConfiguration>
					{
						view:
							<ITableExpandDefinition>
							{
								component: WorkItemExpandComponent,
								customContext: this.pageContext,
								items: [
									{
										label: 'View Entity',
										styleClass:
											AppConstants.cssClasses
												.pButtonPrimary,
										command:
											() =>
												this.navigateToSelectedEntity()
									}
								]
							}
					},
				objectSearch: this.objectSearch,
				apiPromise:
					async(objectSearch: IObjectSearch) =>
					{
						const workItemTypes: IEntityType[] =
							await this.entityTypeApiService.query(
								AppConstants.commonProperties.group
									+ '.Contains(\'WorkItem.\') eq true',
								`${AppConstants.commonProperties.id} `
									+ AppConstants.sortDirections.descending);

						return this.entityService
							.queryMultipleEntityTypes(
								workItemTypes,
								objectSearch.filter,
								objectSearch.orderBy);
					},
				availableColumns: this.availableColumns,
				selectedColumns: this.selectedColumns,
				columnSelectionMode: this.columnSelectionMode,
				expandTitle: () =>
					TableHelper.getExpandTitle(
						this.commonTableContext,
						this.getTitle()),
				commonTableContext:
					(commonTableContext:
						IDynamicComponentContext<CommonTableComponent, any>) =>
					{
						this.commonTableContext = commonTableContext;
					},
				rowCountChanged:
					(rowCount: number) =>
					{
						this.tableRowCount = rowCount;
						this.restoreTableDefinition();
					},
				filterCriteriaChanged:
					(filterCriteria: string) =>
					{
						this.objectSearch.filter = filterCriteria;
						this.restoreTableDefinition();
					},
				sortCriteriaChanged:
					(sortCriteria: string,
						sortField: string,
						sortOrder: number) =>
					{
						this.objectSearch.orderBy = sortCriteria;
						this.objectSearch.sortField = sortField;
						this.objectSearch.sortOrder = sortOrder;
						this.restoreTableDefinition();
					},
				selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
				{
					this.workbenchTableDefinition.selectedColumns =
						selectedColumns;
					this.selectedColumns = selectedColumns;
				},
				columnSelectionModeChanged: (columnSelectionMode: boolean) =>
				{
					this.workbenchTableDefinition.columnSelectionMode =
						columnSelectionMode;
					this.columnSelectionMode = columnSelectionMode;
				}
			};

		this.loadingTableDefinition = false;
	}

	/**
	 * Sets up the table filter display in the filter panel.
	 *
	 * @async
	 * @memberof WorkbenchComponent
	 */
	public async setupTableFilters(): Promise<void>
	{
		this.moduleOptions =
			await this.loadAllowedModules();

		const userPromise: string =
			'return async function() { '
				+ 'const entityInstanceApiService = '
				+ 'this.source.resolver.resolveApiService(\"EntityInstanceApiService\"); '
				+ 'entityInstanceApiService.entityInstanceTypeGroup = \"Users\"; '
				+ 'let filter = \"\"; '
				+ 'let filterValue = this.data.dataSelectOptions?.filterValue; '
				+ 'if (filterValue || \"\" !== \"\") '
				+ '{ filter = "firstName.Contains(\'\" + filterValue + \"\') eq true '
				+ 'or lastName.Contains(\'\" + filterValue + \"\') eq true\"; }; '
				+ 'return entityInstanceApiService.query(filter, \"FirstName\"); }';

		this.archivedFilterLayoutData.data =
			{...this.filterLayoutData.data};
		this.archivedFilterLayoutData.data.metadata =
			{...this.filterLayoutData.data.metadata};

		this.filterLayoutSchema =
			<FormlyFieldConfig[]>
			[
				{
					key: 'data.status',
					type: FormlyConstants.customControls.customMultiSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Status',
						placeholder: 'Select a status',
						showClear: true,
						options:
							<IDropdownOption[]>
							[
								{
									value: WorkItemConstants
										.workItemStatus.active,
									label: WorkItemConstants
										.workItemStatus.active,
								},
								{
									value: WorkItemConstants
										.workItemStatus.done,
									label: WorkItemConstants
										.workItemStatus.done,
								},
								{
									value: WorkItemConstants
										.workItemStatus.ignored,
									label: WorkItemConstants
										.workItemStatus.ignored,
								},
								{
									value: WorkItemConstants
										.workItemStatus.overdue,
									label: WorkItemConstants
										.workItemStatus.overdue
								}
							],
						appendTo: FormlyConstants.appendToTargets.body,
					}
				},
				{
					key: 'data.priority',
					type: FormlyConstants.customControls.customMultiSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Priority',
						placeholder: 'Select a priority',
						showClear: true,
						options:
							<IDropdownOption[]>
							[
								{
									value: WorkItemConstants
										.workItemPriority.high,
									label: WorkItemConstants
										.workItemPriority.high,
								},
								{
									value: WorkItemConstants
										.workItemPriority.normal,
									label: WorkItemConstants
										.workItemPriority.normal
								},
								{
									value: WorkItemConstants
										.workItemPriority.low,
									label: WorkItemConstants
										.workItemPriority.low
								}
							],
						appendTo: FormlyConstants.appendToTargets.body,
					}
				},
				{
					key: 'data.assignedTo',
					type: FormlyConstants.customControls.customDataSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Assigned To',
						placeholder: 'Select a user',
						showClear: true,
						dataPromise: userPromise,
						labelTemplate:
							'${item.data.firstName} ${item.data.lastName}',
						valueTemplate:
							'${item.data.userName}',
						useApiFilter: true,
						appendTo: FormlyConstants.appendToTargets.body,
					}
				},
				{
					key: 'data.queue',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Queue',
					}
				},
				{
					key: 'data.type',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Entity Type',
						placeholder: 'Select an Entity Type',
						showClear: true,
						options: await this.loadFilterEntityTypes(),
						appendTo: FormlyConstants.appendToTargets.body
					}
				},
				{
					key: 'data.subType',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Subtype'
					}
				},
				{
					key: 'data.module',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Module',
						placeholder: 'Select a module for additional filters',
						showClear: true,
						options: this.moduleOptions,
						appendTo: FormlyConstants.appendToTargets.body,
						change:
							(field: FormlyFieldConfig, event: any) =>
							{
								this.handleModuleChange(
									field.formControl?.value,
									!AnyHelper.isNull(event));
							}
					}
				},
				{
					key: 'data.createdDate',
					type: FormlyConstants.customControls.customCalendar,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Created Date',
						required: false,
						showClear: true
					}
				},
				{
					key: 'data.dueDate',
					type: FormlyConstants.customControls.customCalendar,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Due Date',
						required: false,
						showClear: true
					}
				}
			];

		this.loadingTableFilters = false;

		// Allow Formly to implement defaults and display values.
		setTimeout(
			() =>
			{
				this.archivedFilterLayoutData.data =
					{...this.filterLayoutData.data};
				this.archivedFilterLayoutData.data.metadata =
					{...this.filterLayoutData.data.metadata};
			},
			AppConstants.time.quarterSecond);
	}

	/**
	 * Toggles the active filter value which defines if the filter panel
	 * is displayed.
	 *
	 * @param {boolean} active
	 * If sent this will set the filter display as the active value. This
	 * value defaults to null and will instead toggle the active value.
	 * @memberof WorkbenchComponent
	 */
	 public toggleFilterDisplay(
		active: boolean = null)
	{
		this.filterActive =
			!AnyHelper.isNull(active)
				? active
				: !this.filterActive;

		if (this.filterActive === true)
		{
			setTimeout(
				() =>
				{
					this.setScrollActions();
				},
				AppConstants.time.quarterSecond);
		}
	}

	/**
	 * Handles the validity changed event sent from the child dynamic
	 * formly component. This will update the validity of the form for
	 * action buttons.
	 *
	 * @param {boolean} isValid
	 * The validity of the current displayed filter set.
	 * @memberof WorkbenchComponent
	 */
	 public filterValidityChange(
		isValid: boolean): void
	{
		this.filterValidityChanged = isValid;
	}

	/**
	 * Handles the filter changed event sent from the child dynamic
	 * formly component. This will notify the component of changes to
	 * data in the formly display.
	 *
	 * @param {boolean} changed
	 * The changed truthy of the current displayed filter set.
	 * @memberof WorkbenchComponent
	 */
	public filterValuesChange(
		changed: boolean): void
	{
		this.filterValuesChanged = changed;
	}

	/**
	 * Applies values from the filter panel into a filter string that will
	 * then be applied on the table results.
	 *
	 * @param {any} event
	 * The event that sent this apply filters call.
	 * @memberof WorkbenchComponent
	 */
	public applyFilters(
		event: any = null): void
	{
		const filter: string =
			this.calculateFilter();
		this.workbenchTableDefinition
			.filterCriteriaChanged(
				filter);

		// If successful, update the archive data.
		this.archivedFilterLayoutData.data =
			{...this.filterLayoutData?.data};
		this.archivedFilterLayoutData.data.metadata =
			{...this.filterLayoutData.data.metadata};

		this.updateRouteData();
		this.filterActive = false;

		event?.preventDefault();
		event?.stopPropagation();

		EventHelper.dispatchHideAssociatedMenusEvent(
			this.overlayIdentifier);
	}

	/**
	 * Clears all values in the filter panel.
	 *
	 * @param {any} event
	 * The event that sent this clear filters call.
	 * @memberof WorkbenchComponent
	 */
	public clearFilters(
		event: any = null): void
	{
		for (const field of this.filterLayoutSchema)
		{
			if (field.templateOptions.disabled === true)
			{
				continue;
			}

			// Set values.
			field.formControl?.setValue(
				(this.dropdownTypes.indexOf(field?.type) !== - 1)
					? null
					: AppConstants.empty);
			field.formControl?.updateValueAndValidity();

			if (this.calendarTypes.indexOf(field?.type) !== - 1)
			{
				field.templateOptions?.setDisplayDate(null);
			}

			// Handle remnant clear icons on now empty dropdowns.
			const dropdownClearIcons: Element[] =
				this.scrollPanel.nativeElement.querySelectorAll(
					'.p-dropdown-clear-icon');
			for (const dropdownClearIcon of dropdownClearIcons)
			{
				dropdownClearIcon.dispatchEvent(
					new MouseEvent(
						MouseEventConstants.click));
			}

			// Fire the change event if applicable.
			if (AnyHelper.isFunction(field.templateOptions.change))
			{
				field.templateOptions.change(field);
			}
		}

		event?.preventDefault();
		event?.stopPropagation();
	}

	/**
	 * Cancels changes in the filter panel by resetting to the previously called
	 * filter set.
	 *
	 * @param {any} event
	 * The event that sent this cancel filter changes call.
	 * @memberof WorkbenchComponent
	 */
	public cancelFilterChanges(
		event: any = null): void
	{
		// Revert to the archive data.
		this.filterLayoutData.data =
			{...this.archivedFilterLayoutData.data};
		this.filterLayoutData.data.metadata =
			{...this.archivedFilterLayoutData.data.metadata};

		this.filterActive = false;

		event?.preventDefault();
		event?.stopPropagation();

		EventHelper.dispatchHideAssociatedMenusEvent(
			this.overlayIdentifier);
	}

	/**
	 * Calculates a display value for the associated set of filters
	 * to this component.
	 *
	 * @param {FormlyFieldConfig} fieldConfig
	 * The field configuration to get a value for.
	 * @returns {string}
	 * A value ready for display in the parameter based chip set.
	 * @memberof WorkbenchComponent
	 */
	public getChipValue(
		fieldConfig: FormlyFieldConfig): string
	{
		let value: any =
			get(
				this.filterLayoutData,
				fieldConfig.key.toString());

		if (DateTime.fromISO(value).isValid)
		{
			value = DateHelper.formatDate(
				DateTime.fromISO(value));
		}

		if (this.dropdownTypes.indexOf(fieldConfig.type) !== -1)
		{
			const availableOptions: IDropdownOption[] =
				(<any[]>fieldConfig.templateOptions.options);

			// Handle the data select of assigned to as a one off.
			if (AnyHelper.isNull(availableOptions)
				|| availableOptions.length === 0)
			{
				value =
					`${this.sessionService.user.data.firstName} `
						+ this.sessionService.user.data.lastName;
			}
			else
			{
				value =
					availableOptions?.find(
						(selectOption: IDropdownOption) =>
							value === selectOption.value)
						?.label;
			}
		}

		return value;
	}

	/**
	 * Handles the on load event of the worbench dashboard.
	 *
	 * @returns {IEventHandler<void>}
	 * A handled event response.
	 * @memberof WorkbenchComponent
	 */
	 public updateDashboard(): IEventHandler<void>
	 {
		// Clear any existing timers.
		this.ngOnDestroy();

		// On expiration, refresh the dashboard.
		this.dashboardRefreshTimeout =
			setTimeout(
				async() =>
				{
					this.loadingDashboard = true;
					setTimeout(() =>
					{
						this.loadingDashboard = false;
					},
					AppConstants.time.twentyFiveMilliseconds);
					this.updateDashboard();
				},
				AppConstants.time.fiveMinutes);

		return null;
	 }

	/**
	 * Handles a change in the filter panel module control.
	 *
	 * @async
	 * @param {selectedModule} selectedModule
	 * The module currently selected or null if no values are selected.
	 * @param {boolean} scrollToNewLayout
	 * If sent and false this will not scroll to the new layout. This value
	 * defaults to false and will scroll to the new layout.
	 * @memberof WorkbenchComponent
	 */
	private async handleModuleChange(
		selectedModule: string,
		scrollToNewLayout: boolean = true): Promise<void>
	{
		return new Promise(
			(resolve: any) =>
			{
				if (AnyHelper.isNullOrWhitespace(selectedModule))
				{
					const staticLayoutFields: FormlyFieldConfig[] =
						this.filterLayoutSchema.slice(
							0,
							this.staticLayoutFieldCount);
					const dynamicLayoutFields: FormlyFieldConfig[] =
						this.filterLayoutSchema.slice(
							this.staticLayoutFieldCount);

					for (const dynamicField of dynamicLayoutFields)
					{
						dynamicField.formControl?.setValue(null);
						dynamicField.formControl?.updateValueAndValidity();
					}

					setTimeout(
						() =>
						{
							this.filterLayoutSchema =
								[
									...staticLayoutFields
								];
							this.filterLayoutData.data.metadata = {};

							this.scrollFilterPanel(0);
							resolve();
						},
						AppConstants.time.oneHundredMilliseconds);

					return;
				}

				const additionalLayoutSchema: FormlyFieldConfig[] =
					this.moduleLayoutConfigurations.find(
						(configuration: any) =>
							configuration.module ===
								selectedModule)?.layoutConfiguration.layout || [];

				setTimeout(
					() =>
					{
						this.filterLayoutSchema =
							[
								...this.filterLayoutSchema.slice(
									0,
									this.staticLayoutFieldCount),
								...additionalLayoutSchema
							];

						if (scrollToNewLayout === true)
						{
							this.scrollFilterPanel();
						}

						resolve();
					},
					AppConstants.time.oneHundredMilliseconds);
			});
	}

	/**
	 * Scrolls the filter panel to the desired scroll location.
	 *
	 * @param {number} scrollTo
	 * If sent this value will scroll to the sent location. If not sent, this
	 * will scroll to the bottom of the filter panel.
	 * @memberof WorkbenchComponent
	 */
	private scrollFilterPanel(
		scrollTo?: number): void
	{
		setTimeout(
			() =>
			{
				const scrollpanelContent: Element =
					this.scrollPanel?.nativeElement.querySelector(
						`.${AppConstants.cssClasses.scrollPanelContent}`);

				if (!AnyHelper.isNull(scrollpanelContent))
				{
					// If this is removing the scroll panel, remove the bar.
					if (scrollTo === 0
						&& scrollpanelContent.scrollTop === 0)
					{
						const verticalScrollBar: Element =
							this.scrollPanel?.nativeElement.querySelector(
								`.${AppConstants.cssClasses
									.scrollPanelVerticalScrollBar}`);

						if (!AnyHelper.isNull(verticalScrollBar))
						{
							verticalScrollBar.classList.add(
								AppConstants.cssClasses.scrollPanelHidden);
						}
					}

					scrollpanelContent.scroll(
						{
							top: !AnyHelper.isNull(scrollTo)
								? scrollTo
								: scrollpanelContent.scrollHeight,
							behavior: 'smooth'
						});
				}

			},
			AppConstants.time.oneHundredMilliseconds);
	}

	/**
	 * Calculates and returns a filter value representing the currently
	 * displayed filter panel data.
	 *
	 * @returns {string}
	 * A calculated filter value that can be used in further table api calls.
	 * @memberof WorkbenchComponent
	 */
	private calculateFilter(): string
	{
		let filter: string = this.calculateModuleFilter();

		for (const field of this.filterLayoutSchema)
		{
			const fieldValue: any =
				field.formControl?.value
					|| get(
						this.filterLayoutData,
						field.key.toString());

			if (AnyHelper.isNullOrWhitespace(fieldValue)
				|| AnyHelper.isNullOrEmptyArray(fieldValue))
			{
				continue;
			}

			if (!AnyHelper.isNullOrWhitespace(filter))
			{
				filter += ' and ';
			}

			const fieldKey: string =
				(<string>field.key).replace(
					AppConstants.nestedDataIdentifier,
					AppConstants.empty);

			switch (field.type)
			{
				case FormlyConstants.customControls.customDataSelect:
				case FormlyConstants.customControls.customSelect:
					filter +=
						`(${fieldKey} eq `
							+ `'${fieldValue}')`;
					break;
				case FormlyConstants.customControls.customCalendar:
					filter +=
						`(${fieldKey} >= `
							+ `'${fieldValue}')`;
					break;
				case FormlyConstants.customControls.customMultiSelect:
					const multiSelectFilter: string =
						this.calculateArrayFilter(
							fieldKey,
							fieldValue);

					filter += `(${multiSelectFilter})`;
					break;
				default:
					filter +=
						`(${fieldKey}.Contains(`
							+ `'${fieldValue}') eq true)`;
			}
		}

		return filter;
	}

	/**
	 * Calculates and returns an initial module filter matching the allowed
	 * modules.
	 *
	 * @returns {string}
	 * A calculated module level filter value that can be used in further table
	 * api calls.
	 * @memberof WorkbenchComponent
	 */
	private calculateModuleFilter(): string
	{
		if (this.moduleOptions.length === 0)
		{
			return AppConstants.empty;
		}

		let filter = AppConstants.characters.leftParantheses;
		for (const dropdownOption of
			this.moduleOptions.filter(
				(moduleOption: IDropdownOption) =>
					AnyHelper.isNullOrWhitespace(
						this.filterLayoutData.data.module)
						|| moduleOption.value ===
							this.filterLayoutData.data.module))
		{
			if (filter !== AppConstants.characters.leftParantheses)
			{
				filter += ' or ';
			}

			filter +=
				`module eq '${dropdownOption.value}'`;
		}
		filter += AppConstants.characters.rightParantheses;

		return filter;
	}

	/**
	 * Calculates a filter that will match a multi select data array.
	 *
	 * @param {string} fieldKey
	 * A key used to identify the multi select key.
	 * @param {string[]} fieldValue
	 * A string array representing a multi select array value.
	 * @returns {string}
	 * A calculated multi select level filter value that can be used in
	 * further table api calls.
	 * @memberof WorkbenchComponent
	 */
	 private calculateArrayFilter(
		fieldKey: string,
		fieldValue: string[]): string
	{
		let multiSelectFilter: string = AppConstants.empty;
		for (const selectedValue of fieldValue)
		{
			if (!AnyHelper.isNullOrWhitespace(multiSelectFilter))
			{
				multiSelectFilter += ' or ';
			}

			multiSelectFilter +=
				`(${fieldKey} eq `
					+ `'${selectedValue}')`;
		}

		return multiSelectFilter;
	}

	/**
	 * Restores table definitions on demand.
	 * This is used to update the table and load it with changed
	 * definitions in the ui.
	 *
	 * @memberof WorkbenchComponent
	 */
	private restoreTableDefinition(): void
	{
		this.loadingTableDefinition = true;

		setTimeout(
			() =>
			{
				this.setupTableDefinitions();
			},
			this.tableRedrawDebounceDelay);
	}

	/**
	 * Attaches a scroll event to scrollable form content to allow currently
	 * appended form control overlays to close on scroll.
	 *
	 * @memberof WorkbenchComponent
	 */
	private setScrollActions(): void
	{
		 function runOnce()
		 {
			const toggleElement: Element =
				document.querySelector(
					'.filter-container-title');

			toggleElement.dispatchEvent(
				new Event(
					WindowEventConstants.click,
					{
						bubbles: true
					}));

			this.getScrollElement()
				.removeEventListener(
					WindowEventConstants.scroll,
					runOnce);
		}

		this.getScrollElement()
			.addEventListener(
				WindowEventConstants.scroll,
				runOnce.bind(this));
	}

	/**
	 * Finds and returns the current html element representing the ui
	 * scroll panel content container.
	 *
	 * @returns {Element}
	 * The scroll panel element used to display scrollable form content.
	 * @memberof WorkbenchComponent
	 */
	private getScrollElement(): Element
	{
		return this.scrollPanel.nativeElement
			.querySelector(`.${AppConstants.cssClasses.scrollPanelContent}`);
	}

	/**
	 * Navigates to a selected item in the displayed table.
	 *
	 * @async
	 * @memberof WorkbenchComponent
	 */
	private async navigateToSelectedEntity(): Promise<void>
	{
		const navigationEntityType: string = this.commonTableContext.data.data.references.filter(
			(reference: {type: string; identifier: string}) =>
				reference.type ===
					WorkItemConstants.workItemIdentifiers
						.parentNavigationEntityType)
			[0].identifier;

		const navigationId: string = this.commonTableContext.data.data.references.filter(
			(reference: {type: string; identifier: string}) =>
				reference.type ===
					WorkItemConstants.workItemIdentifiers
						.parentNavigationEntityIdentifer)
			[0].identifier;

		const entityType: IEntityType =
			await this.entityTypeApiService.getSingleQueryResult(
				`Name eq '${navigationEntityType}'`,
				AppConstants.empty);

		const navigationModule: string =
			await this.entityService.getContextMenuModule(
				entityType.name);

		const activeDrawerItemDescription: string =
			`|${WorkItemConstants.workItemIdentifiers.workItemIdentifier}:`
				+ `${this.commonTableContext.data.id}|`;

		this.router.navigate(
			[
				`${navigationModule}/entities`,
				entityType.group,
				AppConstants.viewTypes.edit,
				navigationId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full,
								activeDrawerComponent:
									DynamicComponentLookup
										.supportedTypes.workItemsComponent,
								activeDrawerItemDescription:
									activeDrawerItemDescription
							})
				}
			});
	}

	/**
	 * Loads the set of entity types that will be displayed in the filter panel
	 * layout.
	 *
	 * @async
	 * @returns {Promise<IDropdownOption[]>}
	 * An awaitable promise that will resolve to the set of entity
	 * types available for filter selection.
	 * @memberof WorkbenchComponent
	 */
	private async loadFilterEntityTypes(): Promise<IDropdownOption[]>
	{
		const orderBy: string =
			`${AppConstants.commonProperties.name} `
				+ AppConstants.sortDirections.descending;

		const entityTypes: IEntityType[] =
			await ApiHelper.getFullDataSet<IEntityType>(
				this.entityTypeApiService,
				AppConstants.empty,
				orderBy);

		const options: IDropdownOption[] = [];
		for (const entityType of
			entityTypes
				.map((type: IEntityType) =>
					new EntityType(type)))
		{
			options.push(
				<IDropdownOption>
				{
					label: entityType.displayName,
					value: entityType.name
				});
		}

		return options;
	}

	/**
	 * Loads the set of allowed modules that the user has access to.
	 *
	 * @async
	 * @returns {Promise<IDropdownOption[]>}
	 * An awaitable promise that will resolve to the set of allowed
	 * modules for filter selection.
	 * @memberof WorkbenchComponent
	 */
	private async loadAllowedModules(): Promise<IDropdownOption[]>
	{
		const filter: string =
			`${AppConstants.commonProperties.name}.Contains('`
				+ `${AppConstants.OperationIdentifiers.contextMenuModule}')`;
		const orderBy: string =
			`${AppConstants.commonProperties.name} `
				+ AppConstants.sortDirections.descending;

		// Allow permissions to remove modules they are not allowed access to.
		const modules: IOperationGroup[] =
			(<IOperationGroup[]>
			await this.operationGroupApiService
				.query(
					filter,
					orderBy))
				.map(
					(operationGroup: IOperationGroup) =>
					{
						operationGroup.name =
							operationGroup.name.replace(
								AppConstants.OperationIdentifiers
									.contextMenuModule,
								AppConstants.empty);

						return operationGroup;
					});

		// Load layouts and remove modules without a filter layout.
		const promiseArray: Promise<IDynamicLayoutConfiguration>[] = [];
		for (const module of modules)
		{
			promiseArray.push(
				Promise.resolve<IDynamicLayoutConfiguration>(
					await this.displayComponentFactory
						.dynamicLayout(
							await this.displayComponentService
								.populateDisplayComponentInstance(
									this.getModuleFilterLayoutName(
										module.name),
									this.pageContext),
							this.pageContext)));
		}
		const allowedLayouts: IDynamicLayoutConfiguration[] =
			await Promise.all(promiseArray);

		const layoutConfigurations: IDynamicLayoutConfiguration[] = [];
		for (const layoutConfiguration of allowedLayouts)
		{
			layoutConfigurations.push(layoutConfiguration);
		}

		// Initialize options and layout configurations.
		const options: IDropdownOption[] = [];
		for (let index: number = 0; index < modules.length; index++)
		{
			if (!AnyHelper.isNull(layoutConfigurations[index]))
			{
				const moduleName: string = modules[index].name;

				options.push(
					<IDropdownOption>
					{
						label: moduleName,
						value: moduleName
					});
			}
		}

		this.moduleLayoutConfigurations =
			layoutConfigurations
				.map(
					(layoutConfiguration: IDynamicLayoutConfiguration,
						index: number) =>
					{
						if (!AnyHelper.isNull(layoutConfiguration))
						{
							for (const field of layoutConfiguration.layout)
							{
								field.templateOptions.context =
									this.pageContext;
							}
						}

						return <any>
							{
								module: modules[index].name,
								layoutConfiguration: layoutConfiguration
							};
					})
				.filter(
					(mappedItem: any) =>
						!AnyHelper.isNull(mappedItem.layoutConfiguration));

		if (options.length === 1)
		{
			this.filterLayoutData.data.module = options[0].value;
		}

		return options;
	}

	/**
	 * Returns a mapped identifier for a display component of type layout
	 * that will be used for a dynamic module based layout in the filter
	 * panel.
	 *
	 * @param {string} moduleName
	 * The module name to get a filter layout for.
	 * @returns {string}
	 * The mapped filter layout name that will be used to load dynamic modules.
	 * @memberof WorkbenchComponent
	 */
	private getModuleFilterLayoutName(
		moduleName: string): string
	{
		return `${moduleName}ModuleFilterLayout`;
	}

	/**
	 * gets the title.
	 *
	 * @returns {string}
	 * the title.
	 * @memberof WorkbenchComponent
	 */
	private getTitle(): string
	{
		return StringHelper.getEntityTypeTitle(
			this.commonTableContext.data.entityType,
			this.commonTableContext.data.data.subType);
	}
}