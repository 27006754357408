/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ActivatedRoute,
	Params
} from '@angular/router';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
} from '@angular/core';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityManagerDirective
} from '@admin/directives/entity-manager.directive';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	FormControl
} from '@angular/forms';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IRuleDefinitionDto
} from '@api/interfaces/rules/rule-definition.dto.interface';
import {
	IRuleValidationDefinition
} from '@shared/interfaces/rules/rule-validation-definition.interface';
import {
	IRuleValidatorDefinition
} from '@shared/interfaces/rules/rule-validator-definition.interface';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	RuleDefinitionApiService
} from '@api/services/rules/rule-definition.api.service';
import {
	RuleValidationDefinitionApiService
} from '@api/services/rules/rule-validation-definition.api.service';
import {
	RuleValidatorDefinitionApiService
} from '@api/services/rules/rule-validator-definition.api.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

@Component({
	selector: 'app-entity-rule-validations',
	templateUrl: './entity-rule-validations.component.html',
	styleUrls: [
		'./entity-rule-validations.component.scss'
	]
})

/**
 * A component representing an instance of the entity rule validations
 * component.
 *
 * @export
 * @class EntityRuleValidationsComponent
 * @extends {EntityManagerDirective}
 */
export class EntityRuleValidationsComponent
	extends EntityManagerDirective
{
	/**
	 * Creates an instance of an EntityRuleValidationsComponent.
	 *
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to get the entity type data.
	 * @param {securityAccessPolicyDefinitionApiService}
	 * securityAccessPolicyDefinitionApiService
	 * The api service used to get security access policy definition data.
	 * @param {securityAccessPolicyApiService}
	 * SecurityAccessPolicyDefinitionApiService
	 * The api service used to get security access policy data.
	 * @param {SiteLayoutService} siteLayoutService
	 * The service used to get the site layout data.
	 * @param {ActivatedRoute} route
	 * The activated route that opened this component.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof EntityRuleValidationsComponent
	 */
	public constructor(
		public entityTypeApiService: EntityTypeApiService,
		public entityDefinitionApiService: EntityDefinitionApiService,
		public ruleDefinitionApiService: RuleDefinitionApiService,
		public ruleValidatorDefinitionApiService:
			RuleValidatorDefinitionApiService,
		public ruleValidationDefinitionApiService:
			RuleValidationDefinitionApiService,
		public siteLayoutService: SiteLayoutService,
		public route: ActivatedRoute,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super(
			route,
			activityService,
			resolver);
	}

	/**
	 * Gets or sets the rule definition.
	 *
	 * @type {IRuleDefinitionDto}
	 * @memberof EntityRuleValidationsComponent
	 */
	public ruleDefinition: IRuleDefinitionDto;

	/**
	 * Gets or sets the rule validation definition.
	 *
	 * @type {IRuleValidationDefinition}
	 * @memberof EntityRuleValidationsComponent
	 */
	public ruleValidationDefinition: IRuleValidationDefinition;

	/**
	 * Gets or sets the rule validation definition id.
	 *
	 * @type {number}
	 * @memberof EntityRuleValidationsComponent
	 */
	public ruleValidationDefinitionId: number;

	/**
	 * Sets the rule definition id query parameter.
	 *
	 * @type {string}
	 * @memberof EntityRuleValidationsComponent
	 */
	private readonly ruleValidationDefinitionIdQueryParameter: string =
		AppConstants.commonProperties.ruleValidationDefinitionId;

	/**
	 * Sets the context data required for this component.
	 *
	 * @async
	 * @memberof EntityRuleValidationsComponent
	 */
	public async setContextData(): Promise<void>
	{
		this.subscriptions.add(
			this.route.queryParams.subscribe((parameters: Params) =>
			{
				const mappedRouteData: any =
						ObjectHelper.mapFromRouteData(
							parameters);

				this.ruleValidationDefinitionId =
						mappedRouteData[
							this.ruleValidationDefinitionIdQueryParameter];
			}));

		this.entityDefinitionId = this.route.snapshot.paramMap.get(
			AppConstants.commonProperties.id) as unknown as number;

		this.entityDefinition =
			await this.entityDefinitionApiService
				.get(this.entityDefinitionId);

		this.entityType =
			await this.entityTypeApiService
				.get(this.entityDefinition.typeId);

		this.ruleValidationDefinition =
			await this.ruleValidationDefinitionApiService
				.get(this.ruleValidationDefinitionId);

		this.ruleDefinition =
			await this.ruleDefinitionApiService
				.get(this.ruleValidationDefinition.definitionId);

		const validator: IRuleValidatorDefinition =
			await this.ruleValidatorDefinitionApiService
				.get(this.ruleValidationDefinition.validatorDefinitionId);
		this.contextData = {
			data: {
				id: this.ruleValidationDefinition.id,
				validatorName: validator.name,
				definition: this.ruleValidationDefinition.jsonData
			}
		};

		this.saveTitle = 'Rule Validation';
		this.saveContent = `${this.ruleDefinition.name} Rule Validation`;
	}

	/**
	 * Excecutes the save action.
	 *
	 * @async
	 * @memberof EntityRuleValidationsComponent
	 */
	public async saveAction(): Promise<void>
	{
		const ruleValidationDataObject: IRuleValidationDefinition =
			<IRuleValidationDefinition>
			{
				id: this.ruleValidationDefinition.id,
				definitionId: this.ruleValidationDefinition.definitionId,
				validatorDefinitionId:
					this.ruleValidationDefinition.validatorDefinitionId,
				jsonData: this.contextData.data.definition,
			};

		await this.ruleValidationDefinitionApiService
			.update(
				this.ruleValidationDefinition.id,
				ruleValidationDataObject);
	}

	/**
	 * Sets the formly layout fields.
	 *
	 * @async
	 * @memberof EntityRuleValidationsComponent
	 */
	public async setLayoutFields(): Promise<void>
	{
		this.layoutFields =
			<FormlyFieldConfig[]>
			[
				{
					key: 'data.validatorName',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Name',
						disabled: true
					}
				},
				{
					key: 'data.definition',
					type: FormlyConstants.customControls.customTextArea,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Definition',
						required: true,
						rows: FormlyConstants.textAreaRowSizes.large
					},
					validators: {
						validDefinition: {
							expression: ((
								control: FormControl,
								field: FormlyFieldConfig) =>
								this.definitionValidator(
									control,
									field)),
							message: AppConstants.empty
						}
					}
				}
			];
	}

	/**
	 * Validates the definition is a correct input.
	 *
	 * @param {FormControl} control
	 * The form control to get the input value.
	 * @param {FormlyFieldConfig} field
	 * The formly field configuration.
	 * @returns {boolean}
	 * The validation passed or failed.
	 * @memberof EntityLayoutDefinitionComponent
	 */
	private definitionValidator(
		control: FormControl,
		field: FormlyFieldConfig): boolean
	{
		// Checks if entry is an object type.
		try
		{
			JSON.parse(control.value);
		}
		catch
		{
			field.validators.validDefinition.message =
				'Not a valid Definition Object.';

			return false;
		}

		return true;
	}
}