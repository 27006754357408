/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ActivatedRoute,
	ActivatedRouteSnapshot,
	Params,
	Router,
	UrlCreationOptions
} from '@angular/router';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityLayoutTypeApiService
} from '@api/services/entities/entity-layout-type.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	FormControl
} from '@angular/forms';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	get
} from 'lodash-es';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityLayoutType
} from '@shared/interfaces/entities/entity-layout-type.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IKeyValuePair
} from '@shared/interfaces/application-objects/key-value-pair.interface';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IOwnershipGuardComponent
} from '@shared/interfaces/application-objects/ownership-guard-component';
import {
	Location
} from '@angular/common';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/* eslint-enable max-len */

@Component({
	selector: 'insurance-policy-search',
	templateUrl: './insurance-policy-search.component.html',
	styleUrls: [
		'./insurance-policy-search.component.scss'
	],
	animations: [
		ContentAnimation
	]
})

/**
 * A component representing an insurance level policy search.
 *
 * @export
 * @extends {CommonTablePageDirective}
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {IOwnershipGuardComponent}
 * @class InsurancePolicySearchComponent
 */
export class InsurancePolicySearchComponent
	extends CommonTablePageDirective
	implements OnInit, OnDestroy, IOwnershipGuardComponent
{
	/**
	 * Creates an instance of the insurance policy search component.
	 *
	 * @param {Router} router
	 * The router to use for naviation.
	 * @param {Location} location
	 * The location service used to create and populate the url tree.
	 * @param {ActivatedRoute} route
	 * The activated route that opened this component.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @param {EntityService} entityService
	 * The entity service used for data lookups.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used for display.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to load the entity instance data.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The api service used to load the entity instance data.
	 * @param {EntityLayoutTypeApiService} entityLayoutTypeApiService
	 * The entity layout type api service used for layout lookups.
	 * @memberof InsurancePolicySearchComponent
	 */
	 public constructor(
		public router: Router,
		public location: Location,
		public route: ActivatedRoute,
		public resolver: ResolverService,
		public entityService: EntityService,
		public siteLayoutService: SiteLayoutService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public entityLayoutTypeApiService: EntityLayoutTypeApiService)
	{
		super(resolver);

		this.existingRouteReuseStrategy =
			this.router.routeReuseStrategy.shouldReuseRoute;
		this.router.routeReuseStrategy.shouldReuseRoute =
			(_future: ActivatedRouteSnapshot,
				_curr: ActivatedRouteSnapshot): boolean =>
				false;
	}

	/**
	 * Gets or sets the tooltip element reference.
	 *
	 * @type {ElementRef}
	 * @memberof InsurancePolicySearchComponent
	 */
	@ViewChild('Tooltip')
	public tooltip: ElementRef;

	/**
	 * Gets or sets the table row count.
	 *
	 * @type {number}
	 * @memberof InsurancePolicySearchComponent
	 */
	public tableRowCount: number = 15;

	/**
	 * Gets or sets the list of all transaction entity types.
	 *
	 * @type {IEntityType[]}
	 * @memberof InsurancePolicySearchComponent
	 */
	public availableTransactionEntityTypes: IEntityType[];

	/**
	 * Gets or sets the loading value of transaction entity types.
	 *
	 * @type {boolean}
	 * @memberof InsurancePolicySearchComponent
	 */
	public loadingTransactionTypes: boolean = true;

	/**
	 * Gets or sets the sort order by.
	 *
	 * @type {string}
	 * @memberof InsurancePolicySearchComponent
	 */
	public orderBy: string = AppConstants.empty;

	/**
	 * Gets or sets the full length sort order by.
	 *
	 * @type {string}
	 * @memberof InsurancePolicySearchComponent
	 */
	public exactPropertyOrderBy: string = AppConstants.empty;

	/**
	 * Gets or sets the sort field.
	 *
	 * @type {string}
	 * @memberof InsurancePolicySearchComponent
	 */
	public sortField: string = AppConstants.commonProperties.effectiveDate;

	/**
	 * Gets or sets the sort order.
	 *
	 * @type {number}
	 * @memberof InsurancePolicySearchComponent
	 */
	public sortOrder: number = 1;

	/**
	 * Gets or sets the table definitions for the standard table view.
	 *
	 * @type {ICommonTable}
	 * @memberof InsurancePolicySearchComponent
	 */
	public policySearchTableDefinitions: ICommonTable;

	/**
	 * Gets or sets the formly definition for the policy search.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof InsurancePolicySearchComponent
	 */
	public dynamicFormly: FormlyFieldConfig[] = [];

	/**
	 * Gets or sets the validity of the form.
	 *
	 * @type {boolean}
	 * @memberof InsurancePolicySearchComponent
	 */
	public validForm: boolean = true;

	/**
	 * Gets or sets the latest transaction set by product.
	 *
	 * @type {IKeyValuePair[]}
	 * @memberof InsurancePolicySearchComponent
	 */
	public latestTransactionsByProduct: IKeyValuePair[] = [];

	/**
	 * Gets or sets the formly definition for the policy search data values.
	 *
	 * @type {ICommonTable}
	 * @memberof InsurancePolicySearchComponent
	 */
	public dataSet: { data: any } =
		<{ data: any }>
		{
			data: {
				selectedTransactionEntityTypes: [],
				keywordFilter: AppConstants.empty
			}
		};

	/**
	 * Gets or sets the available url parameters.
	 *
	 * @type {any}
	 * @memberof InsurancePolicySearchComponent
	 */
	private readonly parameters:
	{
		selectedTransactionEntityTypes: string;
		keywordFilter: string;
		orderBy: string;
		exactPropertyOrderBy: string;
	} = {
		selectedTransactionEntityTypes: 'selectedTransactionEntityTypes',
		keywordFilter: 'keywordFilter',
		orderBy: 'orderBy',
		exactPropertyOrderBy: 'exactPropertyOrderBy'
	};

	/**
	 * Gets or sets the route reuse strategy for the router on initial
	 * load. This is used to reset the route reuse strategy to it's
	 * original value on destroy, but force a component refresh on route changes
	 * to this component.
	 *
	 * @type {(
		future: ActivatedRouteSnapshot,
		curr: ActivatedRouteSnapshot) => boolean}
	* @memberof InsurancePolicySearchComponent
	*/
	private readonly existingRouteReuseStrategy:
		(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot)
			=> boolean;

	/**
	 * Handles the on initialization event.
	 * This will setup the table definitions and parameter handling
	 * for the policy search page.
	 *
	 * @async
	 * @memberof InsurancePolicySearchComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		if (!await this.isPageOwnershipAllowed())
		{
			EventHelper.dispatchNavigateToAccessDeniedEvent(
				this.location.path(),
				[
					'Entity.Type',
					'Entity.Version',
					'Entity.Layout'
				],
				'Access is required to at least one policy entity type '
					+ 'and version.');

			return;
		}

		this.route.queryParams.subscribe(async(parameters: Params) =>
		{
			const mappedRouteData: any =
				ObjectHelper.mapFromRouteData(
					parameters);

			this.dataSet.data.selectedTransactionEntityTypes =
				!AnyHelper.isNullOrEmpty(
					mappedRouteData[
						this.parameters.selectedTransactionEntityTypes])
					? mappedRouteData[
						this.parameters.selectedTransactionEntityTypes]
					: [];
			this.dataSet.data.keywordFilter =
				!AnyHelper.isNullOrEmpty(
					mappedRouteData[this.parameters.keywordFilter])
					? mappedRouteData[this.parameters.keywordFilter]
					: AppConstants.empty;
			this.orderBy =
				!AnyHelper.isNullOrEmpty(
					mappedRouteData[this.parameters.orderBy])
					? mappedRouteData[this.parameters.orderBy]
					: `${AppConstants.commonProperties.effectiveDate} `
						+ `${AppConstants.sortDirections.descending}, `
						+ `${AppConstants.commonProperties.id} `
						+ `${AppConstants.sortDirections.descending}`;
			this.exactPropertyOrderBy =
				!AnyHelper.isNullOrEmpty(
					mappedRouteData[this.parameters.exactPropertyOrderBy])
					? mappedRouteData[this.parameters.exactPropertyOrderBy]
					: `${AppConstants.commonProperties.effectiveDate} `
						+ `${AppConstants.sortDirections.descending}, `
						+ `${AppConstants.commonProperties.id} `
						+ `${AppConstants.sortDirections.descending}`;
		});

		this.sortField =
			this.orderBy.split(
				AppConstants.characters.space)[0];
		this.sortOrder =
			this.orderBy.split(
				AppConstants.characters.space)[1] ===
					AppConstants.sortDirections.ascending
				? 1
				: -1;

		await this.setupPageVariables();
		this.setupTableDefinitions();
		this.updateUrlQuery();
	}

	/**
	 * On destroy event.
	 * Unsubscribes from any current subscriptions on component destroy.
	 *
	 * @memberof InsurancePolicySearchComponent
	 */
	public ngOnDestroy(): void
	{
		this.router.routeReuseStrategy.shouldReuseRoute =
			<(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot)
				=> boolean>
			this.existingRouteReuseStrategy;
	}

	/**
	 * Implements the ownership guard interface.
	 * This will calculate page ownership permissions.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * A value signifying whether or not access is allowed to this page.
	 * @memberof InsurancePolicySearchComponent
	 */
	public async isPageOwnershipAllowed(): Promise<boolean>
	{
		const fullEntityLayoutType: IEntityLayoutType =
			await this.entityLayoutTypeApiService.getSingleQueryResult(
				AppConstants.commonProperties.name
					+ ` eq '${AppConstants.layoutTypes.full}'`,
				AppConstants.empty,
				true);

		if (AnyHelper.isNull(fullEntityLayoutType))
		{
			return false;
		}

		return this.entityService.verifyWildcardEntityTypeAccess(
			AppConstants.commonProperties.group
				+ '.StartsWith(\''
				+ InsuranceConstants.policyTermTransactionPrefix
				+ '\') eq true',
			fullEntityLayoutType);
	}

	/**
	 * Captures validity changes sent from the dynamic formly component.
	 *
	 * @param {boolean} valid
	 * Whether or not the current form is valid.
	 * @memberof InsurancePolicySearchComponent
	 */
	 public formValidityChange(
		valid: boolean): void
	{
		this.validForm = valid;
	}

	/**
	 * Handles a click of the search action.
	 *
	 * @memberof InsurancePolicySearchComponent
	 */
	public search(): void
	{
		const filter: string =
			this.compilePrimaryFilter();

		this.policySearchTableDefinitions
			.filterCriteriaChanged(filter);
	}

	/**
	 * Sets the page level variables.
	 *
	 * @async
	 * @memberof InsurancePolicySearchComponent
	 */
	public async setupPageVariables(): Promise<void>
	{
		const assetCharacteristics: string =
			'data.assets[0].characteristics';
		const interestCharacteristics: string =
			'data.interests[0].characteristics';

		this.availableTransactionEntityTypes =
			await this.entityTypeApiService
				.query(
					'name.StartsWith('
						+ `\'${InsuranceConstants
							.policyTermTransactionPrefix}\')`,
					AppConstants.empty,
					0,
					AppConstants.dataLimits.large);

		this.dynamicFormly =
			<FormlyFieldConfig[]>
			[
				{
					key: 'data.selectedTransactionEntityTypes',
					type: FormlyConstants.customControls.customMultiSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Products',
						placeholder: 'Select product(s)',
						required: true,
						options:
							this.availableTransactionEntityTypes
								.map(
									(entityType: IEntityType) =>
									{
										const splitEntitType: string[] =
											entityType.name.split(
												AppConstants.characters.period);

										return <IDropdownOption>
											{
												label: splitEntitType[
													splitEntitType.length - 1],
												value: entityType.group
											};
									})
					},
					validators: {
						validProductSelection: {
							expression: ((
								control: FormControl,
								_field: FormlyFieldConfig) =>
								control.value.length > 0),
							message:
								AppConstants.empty
						}
					}
				},
				{
					key: 'data.keywordFilter',
					type: FormlyConstants.customControls.input,
					id: AppConstants.commonTableActions.filterInput,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Search',
						placeholder: 'Search insured or policy data',
						attributes: {
							title: AppConstants.commonTableActions.filterInput
						},
						keypress:
							(_field: FormlyFieldConfig,
								event: KeyboardEvent) =>
							{
								if (event.key ===
									AppConstants.keyBoardKeyConstants.enter)
								{
									this.search();
								}
							}
					}
				}
			];

		for (const transactionEntityType of
			this.availableTransactionEntityTypes)
		{
			this.latestTransactionsByProduct.push(
				<IKeyValuePair>
				{
					key: transactionEntityType.group,
					value: null
				});
		}

		// If this is the first load, select all.
		if (this.dataSet.data.selectedTransactionEntityTypes.length === 0)
		{
			this.dataSet.data.selectedTransactionEntityTypes =
				this.availableTransactionEntityTypes.map(
					(transactionEntityType: IEntityType) =>
						transactionEntityType.group);
		}

		this.tableFilterQuery = this.compilePrimaryFilter();

		this.loadingTransactionTypes = false;

		let displayOrder: number = 1;
		this.availableColumns =
			<ICommonTableColumn[]>
			[
				{
					dataKey: 'data.type',
					columnHeader: 'Type',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'data.status',
					columnHeader: 'Status',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'data.productName',
					columnHeader: 'Product',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'data.policyNumber',
					columnHeader: 'Policy Number',
					displayOrder: displayOrder++
				},
				{
					dataKey:
						interestCharacteristics
							+ '.name.firstName',
					columnHeader: 'First Name',
					displayOrder: displayOrder++
				},
				{
					dataKey:
						interestCharacteristics
							+ '.name.lastName',
					columnHeader: 'Last Name',
					displayOrder: displayOrder++
				},
				{
					dataKey:
						assetCharacteristics
							+ '.addresses[0].address',
					columnHeader: 'Address',
					displayOrder: displayOrder++
				},
				{
					dataKey:
						assetCharacteristics
							+ '.addresses[0].city',
					columnHeader: 'City',
					displayOrder: displayOrder++
				},
				{
					dataKey:
						assetCharacteristics
							+ '.addresses[0].state',
					columnHeader: 'State',
					displayOrder: displayOrder++
				},
				{
					dataKey:
						interestCharacteristics
							+ '.phones[0].number',
					columnHeader: 'Mobile Phone',
					displayOrder: displayOrder++
				},
				{
					dataKey:
						interestCharacteristics
							+ '.emails[0].address',
					columnHeader: 'Email',
					displayOrder: displayOrder++
				}
			];

		this.selectedColumns =
			<ICommonTableColumn[]>
			[
				{
					dataKey: 'data.policyNumber',
					columnHeader: 'Policy Number',
					displayOrder: displayOrder++
				},
				{
					dataKey:
						interestCharacteristics
							+ '.name.firstName',
					columnHeader: 'First Name',
					displayOrder: displayOrder++
				},
				{
					dataKey:
						interestCharacteristics
							+ '.name.lastName',
					columnHeader: 'Last Name',
					displayOrder: displayOrder++
				},
				{
					dataKey:
						assetCharacteristics
							+ '.addresses[0].address',
					columnHeader: 'Address',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'data.productName',
					columnHeader: 'Product',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'data.type',
					columnHeader: 'Type',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'data.status',
					columnHeader: 'Status',
					displayOrder: displayOrder++
				}
			];
	}

	/**
	 * Sets up the list column definitions for the current
	 * operation definition object list.
	 *
	 * @memberof InsurancePolicySearchComponent
	 */
	public setupTableDefinitions(): void
	{
		this.columnSelectionMode = true;
		this.policySearchTableDefinitions =
			<ICommonTable>
			{
				tableTitle: 'Policies',
				allowSortColumns: false,
				hideExpanderArrow: true,
				objectSearch: {
					filter: this.tableFilterQuery,
					orderBy: this.orderBy,
					offset: 0,
					limit: AppConstants.dataLimits.medium,
					virtualIndex: 0,
					virtualPageSize: this.tableRowCount,
					sortField: this.sortField,
					sortOrder: this.sortOrder
				},
				apiPromise:
					async(objectSearch: IObjectSearch) =>
						this.apiPromise.bind(this)(objectSearch),
				availableColumns: this.availableColumns,
				selectedColumns: this.selectedColumns,
				columnSelectionMode: this.columnSelectionMode,
				commonTableContext: (commonTableContext:
					IDynamicComponentContext<CommonTableComponent, any>) =>
				{
					this.commonTableContext = commonTableContext;
				},
				filterCriteriaChanged: (filterCriteria: string) =>
				{
					this.tableFilterQuery = filterCriteria;

					this.resetTrackingData();
					this.updateUrlQuery();
					this.restoreTableDefinition();
				},
				sortCriteriaChanged: (
					orderBy: string,
					sortField: string,
					sortOrder: number) =>
				{
					this.orderBy = orderBy;
					this.sortField = sortField;
					this.sortOrder = sortOrder;

					this.exactPropertyOrderBy =
						orderBy.replace(
							AppConstants.nestedDataIdentifier,
							AppConstants.empty);

					this.resetTrackingData();
					this.updateUrlQuery();
					this.restoreTableDefinition();
				},
				rowCountChanged: (rowCount: number) =>
				{
					this.tableRowCount = rowCount;

					this.resetTrackingData();
					this.restoreTableDefinition();
				},
				selectedColumnsChanged: (
					selectedColumns: ICommonTableColumn[]) =>
				{
					this.policySearchTableDefinitions.selectedColumns =
						selectedColumns;
					this.selectedColumns = selectedColumns;
				},
				columnSelectionModeChanged: (columnSelectionMode: boolean) =>
				{
					this.policySearchTableDefinitions.columnSelectionMode =
						columnSelectionMode;
					this.columnSelectionMode = columnSelectionMode;
				},
				actions: {
					filter: {
						considerFilteringResults: () =>
						{
							document.getElementById(
								AppConstants.commonTableActions.filterInput)
								.focus();
						}
					},
					view: {
						disabledExpandRow: true,
						items: [
							{
								command: () =>
									this.navigateToParent()
							}
						]
					}
				}
			};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Gathers accurate paged and sorted data matching the selected search
	 * filters.
	 *
	 * @async
	 * @param {IObjectSearch} objectSearch
	 * The object search to gather data for.
	 * @returns {IEntityInstance[]}
	 * An awaitable array of policy level data rows matching the current
	 * object search.
	 * @memberof InsurancePolicySearchComponent
	 */
	private async apiPromise(
		objectSearch: IObjectSearch): Promise<IEntityInstance[]>
	{
		const selectedTransactionEntityTypes: string[] =
			this.dataSet.data.selectedTransactionEntityTypes;
		let overallTransactionInstances: IEntityInstance[] = [];
		const existingPolicies: IEntityInstance[] =
			AnyHelper.isNull(this.commonTableContext)
				? []
				: this.commonTableContext.source.listData;
		const existingPolicyNumbers: string[] =
			existingPolicies.map(
				(listItem: IEntityInstance) =>
					listItem.data.policyNumber);

		for (const selectedEntityType of
			selectedTransactionEntityTypes)
		{
			const splitGroups: string[] =
				selectedEntityType
					.split(AppConstants.characters.period);
			const productName =
				splitGroups[splitGroups.length - 1];

			const latestTransactionFilter: string =
				this.compileLatestTransactionFilter(
					selectedEntityType,
					existingPolicies);

			let transactionInstances: IEntityInstance[] =
				await this.loadRecursiveUniqueValues(
					selectedEntityType,
					existingPolicyNumbers,
					objectSearch.filter
						+ latestTransactionFilter,
					this.exactPropertyOrderBy,
					0,
					objectSearch.limit,
					objectSearch.limit);
			transactionInstances =
				transactionInstances.slice(
					0,
					objectSearch.limit);
			transactionInstances
				.forEach(
					(transaction: IEntityInstance) =>
					{
						transaction.data.productName =
							productName;
						transaction.data.entityTypeGroup =
							productName;
					});

			overallTransactionInstances =
				overallTransactionInstances.concat(
					transactionInstances);
		}

		overallTransactionInstances =
			ObjectHelper.handleOrderBySort(
				overallTransactionInstances,
				this.exactPropertyOrderBy)
				.slice(
					0,
					objectSearch.limit);

		for (const selectedEntityType of
			selectedTransactionEntityTypes)
		{
			this.updateLatestTransactionsByProduct(
				selectedEntityType,
				overallTransactionInstances.filter(
					(entityInstance: IEntityInstance) =>
						entityInstance.entityType ===
							selectedEntityType));
		}

		return overallTransactionInstances;
	}

	/**
	 * Recursively queries for values matching the object search specific
	 * against the sent entity type. This will load values recursively until
	 * the full limit worth of values are both found and unique.
	 *
	 * @async
	 * @param {string} selectedEntityType
	 * The entity type to gather object search filtered values for.
	 * @param {string[]} existingPolicyNumbers
	 * The currently loaded and displayed unique policy numbers.
	 * @param {string} filter
	 * The filter to limit possible returned data results.
	 * @param {string} orderBy
	 * The order by used for returning these paged data results.
	 * @param {string} offset
	 * The offset to start from when returning data values.
	 * @param {string} limit
	 * The limit to gather data for.
	 * @param {string} originalLimit
	 * The original limit, or intended full result set length to return.
	 * @param {IEntityInstance[]} existingPolicies
	 * If sent, this will be the current recursive set of unique policy number
	 * based data rows. This should only be sent inside of this method.
	 * @returns {IEntityInstance[]}
	 * An awaitable array of policy level data rows matching the current
	 * object search, unique by policy number.
	 * @memberof InsurancePolicySearchComponent
	 */
	private async loadRecursiveUniqueValues(
		selectedEntityType: string,
		existingPolicyNumbers: string[],
		filter: string,
		orderBy: string,
		offset: number,
		limit: number,
		originalLimit: number,
		existingPolicies: IEntityInstance[] = []): Promise<IEntityInstance[]>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup = selectedEntityType;
		const policies: IEntityInstance[] =
			await this.entityInstanceApiService
				.query(
					filter,
					orderBy,
					offset,
					limit);

		const uniquePolicies: IEntityInstance[] =
			policies
				.filter(
					(item: IEntityInstance) =>
					{
						const policyNumber: string =
							item.data.policyNumber;
						const firstInstance: boolean =
							existingPolicyNumbers.indexOf(
								policyNumber) === -1;

						if (firstInstance === true)
						{
							existingPolicyNumbers.push(
								policyNumber);
						}

						return firstInstance;
					});

		// Handle a partial load or a fully loaded original limit request.
		if (policies.length < limit
			|| existingPolicies.concat(
				uniquePolicies).length >= originalLimit)
		{
			return existingPolicies
				.concat(uniquePolicies);
		}

		uniquePolicies.forEach(
			(item: IEntityInstance) =>
			{
				existingPolicyNumbers.push(
					item.data.policyNumber);
			});

		// Load twice as many items as needed to try to complete the
		// unique limit based request.
		return await this.loadRecursiveUniqueValues(
			selectedEntityType,
			existingPolicyNumbers,
			filter,
			orderBy,
			policies.length,
			(policies.length - uniquePolicies.length) * 2,
			originalLimit,
			existingPolicies
				.concat(uniquePolicies));
	}

	/**
	 * Returns the matching latest transaction by product key value pair.
	 *
	 * @param {string} entityType
	 * The entity type to find the latest transaction by product for.
	 * @returns {IKeyValuePair}
	 * The existing latest transaction by product key value pair matching the
	 * sent entity type.
	 * @memberof InsurancePolicySearchComponent
	 */
	private getLatestTransactionByProduct(
		entityType: string): IKeyValuePair
	{
		return this.latestTransactionsByProduct.filter(
			(item: IKeyValuePair) =>
				item.key === entityType)[0];
	}

	/**
	 * Resets any storage based data for this common table which is used when
	 * we change the order by, filter set, or selected transaction
	 * entity types.
	 *
	 * @memberof InsurancePolicySearchComponent
	 */
	private resetTrackingData(): void
	{
		if (!AnyHelper.isNull(this.commonTableContext))
		{
			this.commonTableContext.source
				.listData = [];
		}

		this.latestTransactionsByProduct.forEach(
			(item: IKeyValuePair) =>
				item.value = null);
	}

	/**
	 * Resets any storage based data for this common table which is used when
	 * we change the order by, filter set, or selected transaction
	 * entity types.
	 *
	 * @param {string} entityType
	 * The entity type to update the latest transaction by product for.
	 * @param {IEntityInstance[]} transactionInstances
	 * The set of existing transaction instances that match this sent entity
	 * type.
	 * @memberof InsurancePolicySearchComponent
	 */
	private updateLatestTransactionsByProduct(
		entityType: string,
		transactionInstances: IEntityInstance[]): void
	{
		const latestTransactionByProduct: IKeyValuePair =
			this.getLatestTransactionByProduct(
				entityType);

		if (transactionInstances.length > 0)
		{
			latestTransactionByProduct.value =
				transactionInstances[
					transactionInstances.length - 1].id;

			return;
		}

		latestTransactionByProduct.value = null;
	}

	/**
	 * Compiles and returns the primary filter for the policy search.
	 *
	 * @returns {string}
	 * The primary search filter matching current selection values.
	 * @memberof InsurancePolicySearchComponent
	 */
	private compilePrimaryFilter(): string
	{
		const keywordsFilter: string =
			this.compileKeywordsFilter();

		return `${this.compileStatusFilter()} `
			+ (AnyHelper.isNullOrWhitespace(keywordsFilter)
				? AppConstants.empty
				: ` and ${keywordsFilter}`);
	}

	/**
	 * Compiles and returns the status filter for the policy search.
	 *
	 * @returns {string}
	 * The status search filter matching current selection values.
	 * @memberof InsurancePolicySearchComponent
	 */
	private compileStatusFilter(): string
	{
		const statusTypes: any =
			InsuranceConstants.transactionStatusTypes;

		return `(status ne \"${statusTypes.archived}\" and `
			+ `status ne \"${statusTypes.obsolete}\")`;
	}

	/**
	 * Compiles and returns the keywords filter for the policy search.
	 *
	 * @returns {string}
	 * The keywords search filter matching current selection values.
	 * @memberof InsurancePolicySearchComponent
	 */
	private compileKeywordsFilter(): string
	{
		const keywordFilter: string =
			this.dataSet.data.keywordFilter;

		if (AnyHelper.isNullOrWhitespace(keywordFilter))
		{
			return AppConstants.empty;
		}

		let searchArray: string[] =
			keywordFilter.split(
				AppConstants.characters.space);
		searchArray =
			searchArray.map(
				(word: string) =>
					`keywords.Contains('${word}')`);

		return `(${searchArray.join(' and ')})`;
	}

	/**
	 * Compiles and returns the latest transaction filter for the policy search.
	 *
	 * @param {string} entityType
	 * The entity type to compile a latest transaction filter for.
	 * @param {IEntityInstance[]} existingPolicies
	 * The set of existing policies used to ensure we filter for latest.
	 * @returns {string}
	 * The latest transaction search filter matching current selection values.
	 * @memberof InsurancePolicySearchComponent
	 */
	private compileLatestTransactionFilter(
		entityType: string,
		existingPolicies: IEntityInstance[]): string
	{
		const latestTransactionByProduct: IKeyValuePair =
			this.getLatestTransactionByProduct(
				entityType);

		if (AnyHelper.isNull(latestTransactionByProduct.value))
		{
			return AppConstants.empty;
		}

		if (this.sortField !== AppConstants.commonProperties.id)
		{
			const apiSortField: string =
				this.sortField.replace(
					AppConstants.nestedDataIdentifier,
					AppConstants.empty);
			const latestPolicyTransaction: IEntityInstance =
				existingPolicies.find(
					(item: IEntityInstance) =>
						item.id === latestTransactionByProduct.value);

			const sortValue: string =
				get(
					latestPolicyTransaction,
					this.sortField);
			const sortComparator: string =
				this.sortOrder === -1
					? AppConstants.filterQueryOperators.lessThan
					: AppConstants.filterQueryOperators.greaterThan;

			return ` and ((${apiSortField} eq "${sortValue}" `
				+ `and id lt ${latestTransactionByProduct.value}) or `
				+ `(${apiSortField} ${sortComparator} "${sortValue}"))`;
		}

		return ` and (id lt ${latestTransactionByProduct.value})`;
	}

	/**
	 * Updates the url query string to store currently selected transaction
	 * entity types, the keyword filter, and order by values.
	 *
	 * @memberof InsurancePolicySearchComponent
	 */
	private updateUrlQuery(): void
	{
		const queryParameters: any =
			<any>
			{
				selectedTransactionEntityTypes:
					this.dataSet.data.selectedTransactionEntityTypes,
				keywordFilter:
					this.dataSet.data.keywordFilter,
				orderBy:
					this.orderBy,
				exactPropertyOrderBy:
					this.exactPropertyOrderBy
			};

		this.location
			.replaceState(
				this.router
					.createUrlTree(
						[],
						<UrlCreationOptions>
						{
							replaceUrl: true,
							queryParams: {
								routeData:
									ObjectHelper.mapRouteData(queryParameters)
							}
						})
					.toString());
	}

	/**
	 * Navigates to the selected data row policy term page.
	 *
	 * @async
	 * @memberof InsurancePolicySearchComponent
	 */
	private async navigateToParent(): Promise<void>
	{
		const transactionInstance: IEntityInstance =
			this.commonTableContext.source.rowData;
		this.entityInstanceApiService
			.entityInstanceTypeGroup =
				transactionInstance.entityType;
		const policyTermParents: IEntityInstance[] =
			await this.entityInstanceApiService
				.getParents(
					transactionInstance.id,
					AppConstants.empty,
					AppConstants.empty,
					0,
					1,
					InsuranceConstants
						.insuranceEntityTypeGroups
						.policyTerms);

		this.router.navigate(
			[
				AppConstants.moduleNames.policy
					+ '/entities',
				InsuranceConstants
					.insuranceEntityTypeGroups
					.policyTerms,
				AppConstants.viewTypes.edit,
				policyTermParents[0].id
			],
			{
				queryParams:
				{
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants
										.layoutTypes
										.full
							})
				}
			});
	}
}