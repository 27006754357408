<span *ngIf="!loadingLayout">
	<app-dynamic-formly
		[dataSet]="context.data"
		[layoutSchema]="createFormlylayout"
		(validityChanged)="context.source.validExpandComponentChanged($event)">
	</app-dynamic-formly>
</span>

<div class="spinner-section"
	*ngIf="loadingLayout"
	[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}">
	<span class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
	</span>
</div>

<div class="ui-g-12">
	<operation-button-bar
		[initialModel]="context.source.expandActions"
		(loadingOperations)="context.source.operationButtonClicked($event)">
	</operation-button-bar>
</div>