/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable max-len */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	EntitySecurity
} from './entity-security';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	FormlyHelper
} from '@shared/helpers/formly.helper';
import {
	IEntityLayout
} from '@shared/interfaces/entities/entity-layout.interface';
import {
	ISecurityItemDto
} from '@api/interfaces/security/security-item.dto.interface';
import {
	MenuItem
} from 'primeng/api';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/* eslint-enable max-len */

/**
 * A class representing the available methods (business logic) for an
 * entity layout.
 *
 * @export
 * @class EntityLayout
 * @implements {IEntityLayout}
 */
export class EntityLayout
implements IEntityLayout
{
	/**
	 * Creates an instance of an EntityLayout.
	 *
	 * @param {IEntityLayout} iEntityLayout
	 * The entity layout interface to create this new
	 * object from.
	 * @memberof EntityLayout
	 */
	public constructor(
		public iEntityLayout: IEntityLayout)
	{
		Object.assign(this, iEntityLayout);
	}

	/**
	 * Gets or sets the id.
	 *
	 * @type {number}
	 * @memberof EntityDefinition
	 */
	public id: number;

	/**
	 * Gets or sets the typeId.
	 *
	 * @type {number}
	 * @memberof EntityLayout
	 */
	public typeId: number;

	/**
	 * Gets or sets the layoutTypeId.
	 *
	 * @type {number}
	 * @memberof EntityLayout
	 */
	public layoutTypeId: number;

	/**
	 * Gets or sets the versionId.
	 *
	 * @type {number}
	 * @memberof EntityLayout
	 */
	public versionId: number;

	/**
	 * Gets or sets the jsonData.
	 *
	 * @type {string}
	 * @memberof EntityLayout
	 */
	public jsonData: string;

	/**
	 * Gets or sets the createDate.
	 *
	 * @type {string}
	 * @memberof EntityLayout
	 */
	public createDate: string;

	/**
	 * Gets or sets the startDate.
	 *
	 * @type {string}
	 * @memberof EntityLayout
	 */
	public startDate: string;

	/**
	 * Gets or sets the endDate.
	 *
	 * @type {string}
	 * @memberof EntityLayout
	 */
	public endDate: string;

	/**
	 * Returns the parsed json object from the entity layout
	 * json data.
	 *
	 * @type {any}
	 * The json parsed json data object.
	 * @memberof EntityLayout
	 */
	public get jsonEntityLayout(): any
	{
		return JSON.parse(this.jsonData);
	}

	/**
	 * Returns the parsed json object from the entity layout
	 * json data as Formly consumable layout fields. This is used
	 * for translations from the valid JSON in the database
	 * into a format the Formly expects.
	 *
	 * @param {object} context
	 * The context for the formly layout. Primarily the component
	 * displaying the formly form, allowing component interactions from
	 * database entered json functions.
	 * @memberof EntityLayout
	 */
	public getFormlyEntityLayout(
		context: any,
		securityPermissions: ISecurityItemDto[] = null): FormlyFieldConfig[]
	{
		const formlyLayout =
			FormlyHelper.getFormlyLayout(
				this.jsonEntityLayout,
				context);

		return new EntitySecurity()
			.getScrubbedFormlyLayout(
				formlyLayout,
				securityPermissions);
	}

	/**
	 * Returns the data tabs that are used for tab based navigation.
	 * This currently includes a custom-tab-content layout field.
	 *
	 * @returns {MenuItem[]}
	 * The tabs to be displayed in this entity layout.
	 * @memberof EntityLayout
	 */
	public getDataTabs(entityLayout: FormlyFieldConfig[]): MenuItem[]
	{
		return entityLayout
			.filter(
				(field: FormlyFieldConfig) =>
					FormlyHelper.isTabWrapper(field))
			.map(
				(field: FormlyFieldConfig) =>
				{
					const nestedSections: MenuItem[] =
						this.mapDataSections(
							field.fieldGroup);

					return <MenuItem>
						{
							label: field.templateOptions.label,
							id: StringHelper.getCleanedValue(
								field.templateOptions.label),
							items: nestedSections.length > 1
								? nestedSections
								: []
						};
				});
	}

	/**
	 * Returns the data sections that are used for section based scroll to
	 * navigation.
	 * This currently includes custom-repeaters and custom-section-title
	 * layout elements.
	 *
	 * @returns {MenuItem[]}
	 * The sections for horizontal scroll functionality in this entity layout.
	 * @memberof EntityLayout
	 */
	public getDataSections(entityLayout: any): MenuItem[]
	{
		return this.mapDataSections(
			entityLayout);
	}

	/**
	 * Maps a field groups data sections that are used for section based scroll
	 * to navigation.
	 * This currently includes custom-repeaters and custom-section-title
	 * layout elements.
	 *
	 * @param {FormlyFieldConfig[]} fieldGroup
	 * The field group to have mapped data sections, the can be the entire
	 * layout or a field group defined via a tab content wrapper.
	 * @returns {MenuItem[]}
	 * The mapped sections for horizontal scroll functionality in this entity
	 * layout.
	 * @memberof EntityLayout
	 */
	private mapDataSections(
		fieldGroup: FormlyFieldConfig[]): MenuItem[]
	{
		const dataSectionTypes = [
			FormlyConstants .customControls.customSectionTitle,
			FormlyConstants .customControls.customRepeater
		];

		const mappedSections: MenuItem[] =
			fieldGroup
				.filter(
					(field: FormlyFieldConfig) =>
						dataSectionTypes.includes(field.type))
				.map(
					(field: FormlyFieldConfig) =>
						<MenuItem>
						{
							label: field.templateOptions.label,
							id: StringHelper.getCleanedValue(
								field.templateOptions.label)
						});

		if (mappedSections.length > 1
			&& fieldGroup.length > 0
			&& !dataSectionTypes.includes(fieldGroup[0].type))
		{
			mappedSections.unshift(
				<MenuItem>
				{
					label: AppConstants.basePageSections.topIdentifier,
					id: AppConstants.basePageSections.topIdentifier
				});
		}

		return mappedSections;
	}
}