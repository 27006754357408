<div class="iframe-container ui-g-12"
	[ngClass]="{'ui-md-6':iframeSize !=='large'}">
	<ng-template [ngIf]="to.usePanelDisplay"
		[ngIfElse]= "useDiv">
		<p-panel>
			<p-header>
				<div class="iframe-label">
					<strong>
						{{to.label}}
					</strong>
					<ng-container
						*ngIf="to.tooltip != null && to.tooltip.length > 0">
						<app-common-icon-tooltip
							[content]="to.tooltip"
							[field]="field">
						</app-common-icon-tooltip>
					</ng-container>
					<ng-container *ngIf="to.allowFullScreen">
						<a
							(click)="fullScreen($event)">
							<i class="pi pi-window-maximize icon text-link"
								title="Full Screen">
							</i>
						</a>
					</ng-container>
				</div>
			</p-header>
			<div class="iframe-container"
				[ngStyle]="{'height': iframeHeight}">
				<iframe title="iframePanel"
					[src]="iframeTargetUrl">
				</iframe>
			</div>
		</p-panel>
	</ng-template>
	<ng-template #useDiv>
		<div class="iframe-label">
			<strong>
				{{to.label}}
			</strong>
			<ng-container
				*ngIf="to.tooltip != null && to.tooltip.length > 0">
				<app-common-icon-tooltip
					[content]="to.tooltip"
					[field]="field">
				</app-common-icon-tooltip>
			</ng-container>
			<ng-container *ngIf="to.allowFullScreen">
				<a
					(click)="fullScreen($event)">
					<i class="pi pi-window-maximize icon text-link"
						title="Full Screen">
					</i>
				</a>
			</ng-container>
		</div>
		<div [ngStyle]="{'height': iframeHeight}">
			<iframe title="iframeDiv"
				[src]="iframeTargetUrl">
			</iframe>
		</div>
	</ng-template>
	<p-sidebar [(visible)]="isFullScreen"
		[fullScreen]="true"
		blockScroll="true"
		baseZIndex="1000000001">
			<p-header>
				<strong>
					{{to.label}}
				</strong>
				<ng-container
					*ngIf="to.tooltip != null && to.tooltip.length > 0">
					<app-common-icon-tooltip
						[content]="to.tooltip"
						[field]="field">
					</app-common-icon-tooltip>
				</ng-container>
			</p-header>
			<iframe title="iframeSidebar"
				[src]="iframeTargetUrl">
			</iframe>
	</p-sidebar>
</div>